import {
  FetchFoobotInventory,
  FetchFoobotInventoryFailed,
  FetchFoobotInventorySuccess,
  FoobotItem,
} from './foobot/types';
import { GPSItem } from './gps/types';
import { EspAqItem } from './esp_aq/types';
import { PumpItem } from './pumps/types';
import {
  FetchPumpInventory,
  FetchPumpInventoryFailed,
  FetchPumpInventorySuccess,
} from './pumps/types';
import {
  FetchEspAqInventorySuccess,
  FetchEspAqInventory,
  FetchEspAqInventoryFailed,
} from './esp_aq/types';
import { FetchGPSInventorySuccess, FetchGPSInventory, FetchGPSInventoryFailed } from './gps/types';
import {
  FetchIpadInventory,
  FetchIpadInventorySuccess,
  FetchIpadInventoryFailed,
  IpadItem,
} from './ipad/types';
import {
  FetchSimcardsInventory,
  FetchSimcardsInventoryFailed,
  FetchSimcardsInventorySuccess,
  SimcardItem,
} from './simcards/types';

export enum InventoryType {
  IPAD = 'IPAD',
  ESP_AQ = 'ESP_AQ',
  FOOBOT = 'foobot',
  GPS = 'geolocation_GY-NEO6MV2',
  GPS_FMT100 = 'TELTONIKA_FMT100',
  PUMP = 'pumps',
  SIM_CARDS = 'simcards',
}

export enum InventoryStatus {
  NEEDS_ORDER = 0,
  ORDERED = 1,
  IN_STORAGE = 2,
  READY = 3,
  ASSIGNED = 4,
  RETRIEVE = 5,
  CONFIG = 6,
  CONFIG_MOBILE = 7,
  CONFIG_FIRM = 8,
  ISSUE = 9,
  NIELS = 10,
  SHIPOUT = 11,
}
export const InventoryStatusMap = {
  [InventoryStatus.NEEDS_ORDER]: 'Skal bestilles hjem',
  [InventoryStatus.ORDERED]: 'Bestilt',
  [InventoryStatus.IN_STORAGE]: 'På lager',
  [InventoryStatus.CONFIG]: 'Skal configureres',
  [InventoryStatus.CONFIG_MOBILE]: 'Skal configureres Mobil',
  [InventoryStatus.CONFIG_FIRM]: 'Skal configureres Firmware',
  [InventoryStatus.ISSUE]: 'Har problemer',
  [InventoryStatus.NIELS]: 'Hos Niels',
  [InventoryStatus.SHIPOUT]: 'Skal sendes ud',
  [InventoryStatus.READY]: 'Klargjort',
  [InventoryStatus.ASSIGNED]: 'Afsat til kunde',
  [InventoryStatus.RETRIEVE]: 'Skal hentes hjem',
};

export interface InventoryItem {
  id: string;
  createTime: string;
  updateTime: Date;
  name: string;
  status: InventoryStatus;
  note?: string;
}

export type If<T, Type, Then, Otherwise> = T extends Type ? Then : Otherwise;

export type InventoryContextForType<Type extends InventoryType> = If<
  Type,
  InventoryType.IPAD,
  IpadItem,
  If<
    Type,
    InventoryType.GPS,
    GPSItem,
    If<
      Type,
      InventoryType.FOOBOT,
      FoobotItem,
      If<
        Type,
        InventoryType.ESP_AQ,
        EspAqItem,
        If<
          Type,
          InventoryType.PUMP,
          PumpItem,
          If<Type, InventoryType.SIM_CARDS, SimcardItem, never>
        >
      >
    >
  >
>;

export interface InventoryState {
  loading: boolean;
  ipadIds: string[];
  gpsIds: string[];
  foobotIds: string[];
  espAqIds: string[];
  pumpIds: string[];
  simcardIds: string[];
  inventoryMap: { [id: string]: InventoryContextForType<InventoryType> };
}

export type InventoryActionTypes =
  | FetchIpadInventory
  | FetchIpadInventorySuccess
  | FetchIpadInventoryFailed
  //
  | FetchGPSInventorySuccess
  | FetchGPSInventory
  | FetchGPSInventoryFailed
  //
  | FetchFoobotInventorySuccess
  | FetchFoobotInventory
  | FetchFoobotInventoryFailed
  //
  | FetchEspAqInventorySuccess
  | FetchEspAqInventory
  | FetchEspAqInventoryFailed
  //
  | FetchPumpInventory
  | FetchPumpInventoryFailed
  | FetchPumpInventorySuccess
  //
  | FetchSimcardsInventory
  | FetchSimcardsInventorySuccess
  | FetchSimcardsInventoryFailed;
