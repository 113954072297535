import { Popconfirm, Layout, Row, Col } from 'antd';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import TableList from './Table';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import GroupsDrawer from './Drawer/GroupsDrawer';
import { DBGroupShape } from '../../modules/groups/types';
import AddGroup from './AddGroup';

const { Content } = Layout;

export function DeleteCompany({ deleteOrg }: { deleteOrg: () => void }) {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={t('home.org.delete.title')}
      onConfirm={deleteOrg}
      okText={t('home.org.delete.confirm')}
      cancelText={t('home.org.delete.cancel')}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
}

interface IHomeViewProps {
  groups: DBGroupShape[];
}

function GroupView({ groups }: IHomeViewProps) {
  const sortedGroups = groups.sort((a, b) => a.title.localeCompare(b.title));
  return (
    <>
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '24px 24px 0' }}>
          <Row>
            <Col span={24} style={{ marginBottom: 24 }}>
              <div style={{ float: 'right' }}>
                <AddGroup key="add-group-form" />
              </div>
            </Col>
          </Row>
          <TableList data={sortedGroups} />
          <GroupsDrawer />
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}

export default GroupView;
