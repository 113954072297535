import uniqBy from 'lodash.uniqby';

type Pagination = {
  start: number;
  limit: number;
  more_items_in_collection: boolean;
  next_start: number;
};

interface SetLoadingAction {
  type: 'SET_LOADING';
  payload: boolean;
}

interface SetSearchTermAction {
  type: 'SET_SEARCH_TERM';
  payload: string;
}
interface SetIdAction {
  type: 'SET_ID';
  payload: number | null;
}
interface SetSearchResultsAction {
  type: 'SET_SEARCH_RESULTS';
  payload: { value: number; label: string }[];
}

interface SetPaginationAction {
  type: 'SET_DATA';
  payload: { data: any[]; pagination: Pagination; stages: any[]; pipelines: any };
}
interface SetDataMoreAction {
  type: 'SET_DATA_MORE';
  payload: { data: any[]; pagination: Pagination };
}

type Action =
  | SetLoadingAction
  | SetSearchResultsAction
  | SetPaginationAction
  | SetDataMoreAction
  | SetIdAction
  | SetSearchTermAction;

interface State {
  loading: boolean;
  id: number | null;
  searchTerm: string;
  searchResults: { value: number; label: string }[];
  data: any[]; // replace any with the type of your data
  pagination: Pagination;
  stages: any[]; // replace any with the type of your data
  pipelines: any; // replace any with the type of your data
}

export const initialState = {
  loading: false,
  searchTerm: '',
  id: null,
  searchResults: [],
  data: [],
  pagination: { start: 0, limit: 10, more_items_in_collection: true, next_start: 10 },
  stages: [],
  pipelines: {},
};

export default function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload };

    case 'SET_SEARCH_RESULTS':
      return { ...state, searchResults: action.payload };

    case 'SET_SEARCH_TERM':
      return { ...state, searchTerm: action.payload };

    case 'SET_ID':
      return { ...state, id: action.payload };

    case 'SET_DATA':
      return {
        ...state,
        data: action.payload.data,
        pagination: action.payload.pagination,
        stages: action.payload.stages,
        pipelines: action.payload.pipelines,
      };
    case 'SET_DATA_MORE':
      return {
        ...state,
        data: uniqBy([...state.data, ...action.payload.data], 'id'),
        pagination: action.payload.pagination,
      };

    default:
      return state;
  }
}
