import { format } from 'date-fns';

export const result = [
  {
    auditId: 'PToDahk9MSD57zDBlk9S',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704361287',
    dueDate: '1704297600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'fMiXHjIz50UeUDef5gXw',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704363045',
    dueDate: '1704297600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'oHC9HZQH3zuAMB8KdiTe',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712900185',
    dueDate: '1704384000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'btXmTmQOm4O9MtNPznjj',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1705908789',
    dueDate: '1704384000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'tcUsFXt3MPbrgwoJPD0T',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1705646399',
    dueDate: '1704384000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'CTPp5eF8bP2yERBf2jXi',
    templateTitle: 'Eftersyn af stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706091875',
    dueDate: '1704477600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'd5WT1FMh4nl2Ebeeyeqx',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704700501',
    dueDate: '1704481200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'Khx1ssSdYYcyS1N4iHYQ',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704700488',
    dueDate: '1704481200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'rEyaYKPM97Q73mgS5qbc',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704797553',
    dueDate: '1704650400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'QZEsCQlpuJwjy0G2Dt51',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704797508',
    dueDate: '1704650400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'Ct6jhN0oYkjUkmhXfENF',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1705077652',
    dueDate: '1705075200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'dWp70lQuPnep48zVSvVJ',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1704879898',
    dueDate: '1705255200',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'vEDDnee5CLRuQiyy5F60',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708944705',
    dueDate: '1705946400',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'twmZaHFlv84Qg2rTMiRQ',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr & brandslukkere',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'true',
    hasOnHoldTasks: 'true',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707480187',
    dueDate: '1706198400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'dpJArjhu74CfGxY2Pi1g',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708414612',
    dueDate: '1706205600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '9nu3T7K5PH8HYAgWum4Q',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712656346',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'Cpg6oW2W3bFUBtqf29Bp',
    templateTitle: 'Minimering af dieselforbrug - årlig',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706251486',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'iDBwvomKplAySqbIFhns',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708937611',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Miljømål',
    orgId: '1n7UGbvhzUFRVlAd0X2d',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'tJnLzbdIzjpKyI61CQWN',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707744734',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
    orgId: '45iGajxZXyYuuMbcI9rc',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'hEzKNxP5cQKshuufPdQk',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708937310',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Miljømål',
    orgId: '1n7UGbvhzUFRVlAd0X2d',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'sKiQAkceUm6xAR7Skowp',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706685853',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
    orgId: 'b8lQZcH3bkHeNddxHZVd',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'yy1onEgAqf7QvM7MnrTZ',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706092246',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
    orgId: '45iGajxZXyYuuMbcI9rc',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '3HS0xUT36SPkLCSDjAWh',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725874026',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
    orgId: 'md32SWSvJjcO4sq7oQWr',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'JzChdpTfv4RMJDmotcAz',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706607083',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
    orgId: '45iGajxZXyYuuMbcI9rc',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '6i6CNho4UnE6LQGBH57A',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725874076',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
    orgId: 'md32SWSvJjcO4sq7oQWr',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'OTjAbMW57zzeLLkKeGgF',
    templateTitle: 'Minimering af perkolatudledning årligt',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706524356',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'cC0fMgXg4edH3TzhT18t',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725874081',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
    orgId: 'md32SWSvJjcO4sq7oQWr',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'Qj7S6BHbOVYC2yj2dZRg',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718028040',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
    orgId: 'b8lQZcH3bkHeNddxHZVd',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: '2EN99F0tFS2vmkNITjiF',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824664',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'gwL73veDCeNu60Mhq55t',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706251761',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'wCzgYjs7cnrraZAYhVR5',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706251851',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'BvP23TQvXJ1MdSaj5G0k',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708428466',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Miljømål',
    orgId: 'IOeedHDwQTpxahtnvRbl',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'SSa8VvEZ6WQLnuYA9akT',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707118001',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Miljømål',
    orgId: 'IOeedHDwQTpxahtnvRbl',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'fROMVGrsozao51ga5822',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706249958',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Miljømål',
    orgId: '1n7UGbvhzUFRVlAd0X2d',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: '9f1gfaERnjvvyw391jz3',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707117253',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Miljømål',
    orgId: 'IOeedHDwQTpxahtnvRbl',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'BgRbCiRpRiNLWnEqdD14',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718027984',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
    orgId: 'b8lQZcH3bkHeNddxHZVd',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'jOWWRU47HLsG9MGfekdd',
    templateTitle: 'Minimering af perkolatudledning - årlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824681',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'CVSh8HRBRBtdkdeMcrFQ',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712586378',
    dueDate: '1706284800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'NZwFFYCcbsZ1ttRHGJSw',
    templateTitle: 'Eftersyn af Brandslukkere - Falck',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706530250',
    dueDate: '1706547600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'w4UgklFEeFUYauLVCsxW',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706528702',
    dueDate: '1706547600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'C0j0LcNAPZBPb8Jdkogw',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707139681',
    dueDate: '1706716800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'Z7Ro9zoGblViuNMr76fK',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707140184',
    dueDate: '1706716800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '5B7OhTNsreKEJLx0Qvdw',
    templateTitle: 'Olieudskiller ulti. jan.',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707730944',
    dueDate: '1706716800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'g0gjIJd2IyfddTtsb8AD',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706865696',
    dueDate: '1706716800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'cKBpPcsIWJGnUsW5qmuv',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712646993',
    dueDate: '1706716800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'GROfWP4RVl4726e4ibPG',
    templateTitle: 'Eftersyn af Stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729083405',
    dueDate: '1706720400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '1KKlIqjcFWYw7yORuD41',
    templateTitle: 'Sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708944958',
    dueDate: '1706724000',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Sikkerhed',
    orgId: 'MVaRjg2Zi8kCWqjUm7Nx',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'Hzolr1gmyCUpuMV1PlAR',
    templateTitle: 'Kompressor, varmepumper og køle',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707746523',
    dueDate: '1706727600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '9txRsaUV7aNIlKspuOfc',
    templateTitle: 'Maskiner',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714653921',
    dueDate: '1706727600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'EkqWHDySQDXoT5XrdMiM',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712901086',
    dueDate: '1706810400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'B7rJ6QUC87fnHhHHN5HG',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720070496',
    dueDate: '1706810400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '0rtt1eM9gDGCNeSFuiZc',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712899285',
    dueDate: '1706817600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'iCZbEul65qxmZWl9PIeo',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707119642',
    dueDate: '1706976000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'tOtV7ulBegEf12T5zWwu',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1706771040',
    dueDate: '1706976000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: '73UJlE9XvoH0EV2E6ZEw',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707730423',
    dueDate: '1707062400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'K1fv0JE8JEJ6wJRpdDmr',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707375989',
    dueDate: '1707062400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'wWPxp6ZttFoqScGEEqik',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707375807',
    dueDate: '1707062400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'nx2Kd4GQPsbKwpP78w36',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707300888',
    dueDate: '1707159600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'hDUz10o6tPvwgclfCjtV',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1707729596',
    dueDate: '1707159600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'gt5PF2iIgKrLGKrfPiGq',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708410241',
    dueDate: '1707328800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'XSyZopFT4CD9nhgdK1Wn',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708410220',
    dueDate: '1707328800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'bYk1eVczELrdE4yVdRta',
    templateTitle: 'Brønde, drænbrønde og sandfang - halvårlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708410263',
    dueDate: '1707328800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '5HJk0QUmosexiTYSiasI',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712584739',
    dueDate: '1707588000',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'yjCXVZaHlwQEYlDcGwwm',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824647',
    dueDate: '1707753600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'eANRQGo7vrDz0ZMvHwgr',
    templateTitle: 'Eftersyn af Løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712656672',
    dueDate: '1708617600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'loHTP6mRBQQIaoRI8BgR',
    templateTitle: 'Kontrol af betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712912661',
    dueDate: '1708624800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'SfGUFMQEsqL1Vd9qZths',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708510989',
    dueDate: '1709136000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'X4n3TeSlgpE9HDfiULpD',
    templateTitle: 'Eftersyn af Stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709300387',
    dueDate: '1709136000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'KmH1uJwTWlDQJSBd01PF',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712656585',
    dueDate: '1709222400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'O5PLrDIY1Fm6kPHd5rK7',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709624111',
    dueDate: '1709222400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'biwBWIUiuNSNPrRaFtex',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709624271',
    dueDate: '1709222400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'bHSusKhM3rBM42IzIX1s',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709624182',
    dueDate: '1709222400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'gHhohU1Nyv5ZmYNgtOaf',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708933452',
    dueDate: '1709229600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: '2qI4hPb5mAYHc4VCiEVj',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1708937865',
    dueDate: '1709229600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'OJqguZI8BNLz5no7nGrJ',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709019883',
    dueDate: '1709229600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'jCJWmeobnzQ4ytqX5pIt',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712900952',
    dueDate: '1709316000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'uOep7CDMFSR7osw0IZKE',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1713265125',
    dueDate: '1709316000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'wFYDG078XKQPwhMT5I3O',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712899081',
    dueDate: '1709323200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'Rq1wqiJrsLbq27j4J2GY',
    templateTitle: 'Olietanke - hovedtank',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730971979',
    dueDate: '1709402400',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'KScEcRljYAzGtStG7hm6',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709294575',
    dueDate: '1709481600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'hy61sa0umzoUoVy1B5Ay',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709641255',
    dueDate: '1709481600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: '2fswvEFeMwrKQVBs3H2X',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712575668',
    dueDate: '1709568000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'La5vNnjcFz6AQEsQg7Dc',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1710753587',
    dueDate: '1709568000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'Xn9IJLskEGVDjPQddnfy',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709896350',
    dueDate: '1709568000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'Uxg8sMn9A574GcL7GVyC',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709721442',
    dueDate: '1709665200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'pLI4QdbZbzcBcQNZ6VCM',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709721426',
    dueDate: '1709665200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'etKusHp8Qqjkkx3wqWrW',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709879020',
    dueDate: '1709834400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'HHBwTwWaPTs3Lds5BuFd',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1709878994',
    dueDate: '1709834400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'zed4fhHkjUhGERH90TsV',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824622',
    dueDate: '1710259200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: '3pVJ016QzuPdWLaR9GHn',
    templateTitle: 'Porte (manuelle og automatiske) - Nassau porte',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712064196',
    dueDate: '1710950400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'eurNZBOxJxhAUMiL921Q',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712656319',
    dueDate: '1711123200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'zsMgQcglablHTAwXQ4O9',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1710504745',
    dueDate: '1711123200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: '7kzX04zVeNeyEFe2i5ZA',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712898563',
    dueDate: '1711209600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'UIym4XvQQEDAuZq6T2J4',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733138692',
    dueDate: '1711645200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'X3bNWz8w9Cu0olJORLjv',
    templateTitle: 'Analyser af perkolat',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712559314',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'Hy1PVoIk81KoRGECo8UJ',
    templateTitle: 'Perkolattank/bassin',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734077169',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'zdC4V2JU4cyDSzlufEck',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714374163',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'wLeFO6gB9CGZYrvQvHNJ',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712574484',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'eUOpYcBUVjWgxzDfRwB5',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734080081',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'Z0eABlSXwkb97wadzUZW',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1713343373',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'mor70W2BhUscK1OAWLle',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712574038',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'u46WWPRXabpw6wccqtVT',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733919176',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'y2L72qnAJjoPDhb0wBq3',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712660633',
    dueDate: '1711814400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'uaOVpChT6ZSuKppO9ubd',
    templateTitle: 'Eftersyn af løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728568459',
    dueDate: '1711897200',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'bUt06WIM5Iip595w3aGR',
    templateTitle: 'Kompressor, varmepumper og køle',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712657102',
    dueDate: '1711897200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'PPlnKSakf0GaE34ZdpXP',
    templateTitle: 'Olieudskiller - kvartal',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712657015',
    dueDate: '1711897200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'NwuJKjUT4vDj1oO49yrE',
    templateTitle: 'Kompressor, varmepumper og køle - ulti. mar. årlig',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712576321',
    dueDate: '1711897200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'feKakqAspXWF3a9QtUUN',
    templateTitle: 'Eftersyn af brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725865937',
    dueDate: '1711897200',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'Xmm3lvYOa1iDSb9GB8zv',
    templateTitle: 'Eftersyn af førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718794347',
    dueDate: '1711897200',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'eIMNLab3fmfwec2NYA0z',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712049440',
    dueDate: '1711900800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'CWc2fiMbdbz2pMElUmfN',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712049369',
    dueDate: '1711900800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '69JDW55pOS108wo16Dqk',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712657060',
    dueDate: '1711900800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: '7TWJNNyUHgtXiFb812hy',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712049326',
    dueDate: '1711900800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'epFUY1SSMkHSKY1fQMXj',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712582517',
    dueDate: '1711904400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'b4HyrHCeukv9VQFkXdKP',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712662309',
    dueDate: '1712160000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: '3xHDZ3FsfZdKvuWXVguk',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712050760',
    dueDate: '1712160000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'FBd7VCu3BGXxvKR5zJSo',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712582398',
    dueDate: '1712246400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'gEQnrDztxYSthTX0DmNa',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712574847',
    dueDate: '1712246400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'g3IQ1ROPrXo3RMKryDmG',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712575495',
    dueDate: '1712246400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'a3qoyo5kQuokSB4TTm0X',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714454623',
    dueDate: '1712343600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'g89AceOmH5yvVDEu2qZW',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714454600',
    dueDate: '1712343600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'KowZmUx1mQZvnXUGAk7Q',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712554901',
    dueDate: '1712512800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'JnRqFotbSDBob6YNOJsz',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1712512800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'I6IPwvD14xCMDfRGah5J',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712554855',
    dueDate: '1712512800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'kzPgnOAwZOJSt7DcxJrN',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824595',
    dueDate: '1712937600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'joQscReoSlJ1mrmIKfkI',
    templateTitle: 'Kompressor, varmepumper og køle  - apr årlig',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1712900544',
    dueDate: '1713106800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'hUpiNuGbzdH1JcCsaVuw',
    templateTitle: 'Kompressor, varmepumper og køle - ultimo apr',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714374200',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'cOPPd9zBN8X371kPGkDy',
    templateTitle: 'Eftersyn af Brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714020014',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'sSlsNSSMqTwIc8khimXp',
    templateTitle: 'Eftersyn af El-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726823272',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'Iv8xP4cMkQuhnoByHl13',
    templateTitle: 'Eftersyn af El-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732882898',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'qsfgikHtOnhukbJPfw3O',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726028702',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'u8fbfZ1HHUoEURHqLLc2',
    templateTitle: 'Eftersyn af Brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714733261',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'yPPJqqT01wD7y5WTlqIJ',
    templateTitle: 'Analyser af perkolat',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733920181',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'BS68e2dt2TDlu1hvun9N',
    templateTitle: 'Eftersyn af El-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733916926',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'avf2TEVLcdw1aeUbjQYh',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714017737',
    dueDate: '1714489200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'zv9wb9ZHbqCwA6DTNhUB',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714647758',
    dueDate: '1714492800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'TwNs9EUUc23ZHM2rG6g3',
    templateTitle: 'Perkolat analyser',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727877577',
    dueDate: '1714492800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'InTQsZPD4bz6085PyaV6',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714647940',
    dueDate: '1714492800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'DhmZ85BSHnGjYMlqLZfy',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167756',
    dueDate: '1714492800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'OuxZGZcsWtRPpxa4YROn',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714647876',
    dueDate: '1714492800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'EPbnDjhuGk1vl2s7YCh1',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714454648',
    dueDate: '1714496400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'DhP8LXZcFDF11AiPJWy7',
    templateTitle: 'Kompressor, varmepumper og køle',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717420867',
    dueDate: '1714496400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'TzzplMXeVUn739dkT2ka',
    templateTitle: 'Eftersyn af Brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714454659',
    dueDate: '1714500000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '919lM9ywNP0ANwqP8vOS',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1714582800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'B6flahUcUWZoRMEpSiZ8',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714657266',
    dueDate: '1714752000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'KGKtJRgl0R1wSh4EEFx5',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714391599',
    dueDate: '1714752000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'u0rT05R9wVNSPqd41uk4',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1715754756',
    dueDate: '1714838400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'WF9EkXXZ7fznwyK72ge8',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725869267',
    dueDate: '1714838400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'DziJSi5ebfCqN02G9yVK',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1715850328',
    dueDate: '1714838400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'ULXOVFxNzUKW6HGEHRaB',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714994186',
    dueDate: '1714845600',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'HCMg3AWHzL33eldIA0rD',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1716539956',
    dueDate: '1714935600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'qcZMer0MJGb95jNoFZyc',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1716541106',
    dueDate: '1714935600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'KqwyEVFRozwH09fOPUBK',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1715835601',
    dueDate: '1715104800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'LastMecn4arb8vnl0Dqi',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1715835622',
    dueDate: '1715104800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'RWQRfnEfSgN7tDAFSeZG',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824574',
    dueDate: '1715529600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'B0neRP8QZdlAmuSd6QEB',
    templateTitle: 'Kontrol af betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734073763',
    dueDate: '1716400800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'Kdx4jfQvJ73Ub440SpJN',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1716546747',
    dueDate: '1716566400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '8eGfqSMVBA1Q3Pbt3lhX',
    templateTitle: 'Alarmer',
    auditTemplateId: 'RGS_5c05b284-5573-4f40-9c74-84939a6a20b4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1716542464',
    dueDate: '1716566400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'ZkogzXWBu3T69fNKYK1n',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733918525',
    dueDate: '1717005600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'L5owsiFz8ZHpYui8RsQk',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733902880',
    dueDate: '1717005600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'Z3a6Fv77PWmcrjgxxEyb',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733912372',
    dueDate: '1717005600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'pCFIKX0l7X190YvHzxl8',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717050511',
    dueDate: '1717092000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'iuU8FOdDPY5NXtp72jWN',
    templateTitle: 'Eftersyn af Brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718170139',
    dueDate: '1717167600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: '1tFERzzOCLTmthqpVcCO',
    templateTitle: 'Analyser af perkolat',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167858',
    dueDate: '1717167600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'J3pg4XvHiIarmSevRIcd',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718615182',
    dueDate: '1717167600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'FwDOwQNzYSlYysT0pmxa',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1717167600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '767QvNKHgzzfvVUWMMsR',
    templateTitle: 'Alarmer',
    auditTemplateId: 'RGS_5c05b284-5573-4f40-9c74-84939a6a20b4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733910549',
    dueDate: '1717167600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'vCBwBG5rzcI6t58mZo5p',
    templateTitle: 'Eftersyn af El-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730271110',
    dueDate: '1717171200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'ClldTzObIgC54B46nYHv',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1714647840',
    dueDate: '1717171200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'IpYlB4aVLm1c4MhxvhE5',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717413488',
    dueDate: '1717171200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'YzZoDb2VSCBDAbqH7TXO',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717414174',
    dueDate: '1717171200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'W57Lyp3dkDsBIEy7M3Dm',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167774',
    dueDate: '1717171200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'XyershJREzyxuEpntOtH',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718027897',
    dueDate: '1717174800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'AFOn4irm3vjetmJZuCf5',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718168673',
    dueDate: '1717430400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: '3sZucLQFPBQ57tbUb7Ul',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717478253',
    dueDate: '1717430400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'KfTLADV6jjpPjs04eY3u',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718791884',
    dueDate: '1717516800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'ZIBf6CAukGHRxDcropww',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725885093',
    dueDate: '1717516800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'tUHClak4FSqlH0i46a9c',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718790255',
    dueDate: '1717516800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'KLiSJHKlNz1Ey7q6gJg0',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718345166',
    dueDate: '1717606800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'xlosS9P8MnM7X25peJyM',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718630747',
    dueDate: '1717614000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'QvNYePFQwqrle7p469bO',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718629916',
    dueDate: '1717614000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'rHJi4ozL80Yjfd2nWDFd',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717649808',
    dueDate: '1717783200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'Tf0EBeLBUlJQIrdycOVo',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719306862',
    dueDate: '1717783200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'iqy4HxOBUTnA6tm8APRc',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717131921',
    dueDate: '1718208000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'DdcEapp4tMoV21RgVgSe',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167817',
    dueDate: '1718377200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: '8o8AkcQX5HoDZomd4XU8',
    templateTitle: 'Perkolattank/bassin',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726142152',
    dueDate: '1718377200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'VUO1neQLRzewjkJhZgxv',
    templateTitle: 'Tæthedsprøvning',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726142246',
    dueDate: '1718384400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '9nW6mgwKazaQe0Y9dPTJ',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726141998',
    dueDate: '1718388000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'xzkegGIpmtoP58RS8xwg',
    templateTitle: 'Perkolattank/bassin',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1718001400',
    dueDate: '1718982000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'J5cWQlfMD6hT8Iuhr6CS',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722329228',
    dueDate: '1718989200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'iErpF0wbGolvdCDClwAj',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730964267',
    dueDate: '1718992800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'wckyempKFyW7D59xJW4T',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729867522',
    dueDate: '1719586800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'Xj4nDUxhYjYLxEJQe3Jp',
    templateTitle: 'Tæthedsprøvning',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'true',
    hasOnHoldTasks: 'true',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1719594000',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'O6ntKL5c7n1YIpii9y4V',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729867545',
    dueDate: '1719594000',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'Fxh4dBqQ4UsQ11UdLnL6',
    templateTitle: 'Olieudskiller - kvartal',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167675',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'SUPOM3cMtpyyrEfhKB9x',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734517907',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'qi6z2B9UVsCzRZvgLYKP',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733917095',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'v0GZrOsKV4pjrLWiLtZi',
    templateTitle: 'Alarmer',
    auditTemplateId: 'RGS_5c05b284-5573-4f40-9c74-84939a6a20b4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726141116',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '1vznQO2GXDPSnjIXnrLb',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824193',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'hl7WtnJ1avbmx21GrBsT',
    templateTitle: 'Eftersyn af stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726823285',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'Oyp7xprVhIDjIu51PcVM',
    templateTitle: 'Maskiner med tilbehør - ultimo juni',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719295816',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'FpNSr9vJZXgsbhTByXRv',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733919618',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: '6WP5IW9TRXd9g94vmvun',
    templateTitle: 'Eftersyn af stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734518475',
    dueDate: '1719759600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'mqs19UcANWIx0fs1WbDk',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167788',
    dueDate: '1719763200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'lSbrGDkKeGLsqrYXLuWD',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719837494',
    dueDate: '1719763200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'nXGyIFcqBlXzvgwe7658',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719822639',
    dueDate: '1719763200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'GYNuyadMOMkcAtVwBKVX',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719828071',
    dueDate: '1719763200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'ka1CzAMweyyXK0gnY2SJ',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720527644',
    dueDate: '1719766800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'S7XGnfOVBoKG4JVINWZH',
    templateTitle: 'Alarmer',
    auditTemplateId: 'RGS_5c05b284-5573-4f40-9c74-84939a6a20b4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719984375',
    dueDate: '1719766800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'S8s18d8l6rjrzFWR0nd2',
    templateTitle: 'Eftersyn af Løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1723543885',
    dueDate: '1719770400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'sxQZ8wMaoTZqCModaLGp',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1719770400',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'tfnwE66cnJk4pRqCbQ5V',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720071069',
    dueDate: '1719849600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '2WM81BSvErejLLXQKgXt',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720070878',
    dueDate: '1719849600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'NgPY2zG6liY2G9CSJYXe',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720070605',
    dueDate: '1719849600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '8c2zH6jEVhxDT1QimcCQ',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720083674',
    dueDate: '1720022400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: '1nJBhAuU4laiJE4KvaOt',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719826855',
    dueDate: '1720022400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'Aivb3NHcGouO0VN4nYMj',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725886036',
    dueDate: '1720108800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'QnW1lzZiOl5VoWPe4G2t',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1717577301',
    dueDate: '1720108800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'ubvX9iO730DyL6cPrUfS',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720502327',
    dueDate: '1720108800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'HSC0ZtrbWJg9bddE10nL',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720168145',
    dueDate: '1720206000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'KGxzkwqqw5KnIZwzmnhy',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720167905',
    dueDate: '1720206000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'sDwLPdTK4UBoLHEonGW1',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720069743',
    dueDate: '1720375200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'idW5rQoEjdnBZHpPn87U',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1720069764',
    dueDate: '1720375200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'SI3oyut6bzQpxkrLxSjv',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824549',
    dueDate: '1720800000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'zcXa4Q1P7q50yKYTyK6X',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727786796',
    dueDate: '1720803600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'n0VuuDIp1Xt1WLpkyigi',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167712',
    dueDate: '1721401200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'kN6TkxtHJfMFSG2xIVVs',
    templateTitle: 'Perkolattank/bassin',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722327997',
    dueDate: '1721412000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'DE2d20vUOgAi5dR6Jmkd',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729867491',
    dueDate: '1722103200',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: '5OOhAo3VVWHwPKz3uCSb',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824376',
    dueDate: '1722351600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'iG3CrHnv4knZbo1hZdMf',
    templateTitle: 'Minimering af perkolatudledning årligt',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719896779',
    dueDate: '1722438000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'nGdeU5XC1KWN1gyKQMJy',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726059971',
    dueDate: '1722438000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'Mq3fP65Owmhb0miH60Su',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719896684',
    dueDate: '1722441600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'cro1vH7F9cL4eFvC0GI5',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719896726',
    dueDate: '1722441600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'ezwd4AGz6PUaK9ThTWxn',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1719896761',
    dueDate: '1722441600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '1MtDnPt0z4jG0BSUZdAp',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167734',
    dueDate: '1722441600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'z8CTCkglRUNVUS5fhoPv',
    templateTitle: 'Belægningkontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722334322',
    dueDate: '1722445200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'LzVskGR3Ts5xu28SJIYy',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722244490',
    dueDate: '1722445200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'bxgMFTZkSKRCwEEj6MNj',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722244436',
    dueDate: '1722445200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'Z992Z6VdkpHxpBnRkSCx',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1723008293',
    dueDate: '1722528000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'y6d8ePe1T3uNXRdvKPvX',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726142994',
    dueDate: '1722528000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'nJ936i94iAaBqhQF9LSp',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1723008968',
    dueDate: '1722528000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '6SY1lvFv1mPzfJ7GFekl',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722505911',
    dueDate: '1722700800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'kVVjTHb0PR3xDZzC1Aec',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1723114011',
    dueDate: '1722700800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'MOuI6k4Ued295feQs11S',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722917579',
    dueDate: '1722787200',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'dUdlEvQIMUAfxy9ZkkXA',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725867409',
    dueDate: '1722787200',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'mnMzuBoNgkO3qd721elo',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725885963',
    dueDate: '1722787200',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'vV1qwv1nYiLwWO0RTrRo',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722328897',
    dueDate: '1722884400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '6POis8Z21RnBB3PsNYBI',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722328021',
    dueDate: '1722884400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'qJSpGpq4WQvkHVFELQPV',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725626061',
    dueDate: '1723053600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'KXQiZiSB5a1dY4CwZq5Q',
    templateTitle: 'Brønde, drænbrønde og sandfang - halvårlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726466915',
    dueDate: '1723053600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'YKBc0DxgvJeHnPJOx5eu',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725626116',
    dueDate: '1723053600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'Dr4kXwB0S0lfDCE3NTnJ',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729147907',
    dueDate: '1723129200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '90yQrImjoygNXIIQojB3',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726465016',
    dueDate: '1723136400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'let2KJNs3lwd6ZdqAbvV',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824520',
    dueDate: '1723478400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'qe6YapXp9oQ9hO1bRMhh',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722328045',
    dueDate: '1723820400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'B6t5NEYR3CVtIma6QXdb',
    templateTitle: 'Sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1723827600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'vlyRxFi7kyFuTVSJ2m0A',
    templateTitle: 'Kontrol af betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734073780',
    dueDate: '1724349600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'Z5cQThksGgPJTpxf1JMi',
    templateTitle: 'Olieudskiller - årlig',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727167636',
    dueDate: '1724425200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'TX6w5fNFKiIY3yNOPzj1',
    templateTitle: 'Eftersyn af stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722590134',
    dueDate: '1724425200',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'aXJ0XYvDjbreLlddMY2Y',
    templateTitle: 'For-bassin og rensebassin kontrol årligt',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729152354',
    dueDate: '1724428800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'nCEgnaTJXTc71bTYSGO6',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733902082',
    dueDate: '1724954400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'i1aOj3O99VgiKbBC2TI1',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733912494',
    dueDate: '1724954400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'C8SWpfFbrwiUCFsIVEup',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733918878',
    dueDate: '1724954400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'w3Sx8OdqvIZXLnxzL8Jf',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728898494',
    dueDate: '1725033600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'MN2WY5OGyt1L1mGTsYJu',
    templateTitle: 'Perkolattank/bassin',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729245928',
    dueDate: '1725033600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'UDAA6tcue2trkpTkscuA',
    templateTitle: 'Eftersyn af Løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1724751660',
    dueDate: '1725116400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'CEWAdDqWClQrLIPzdUxE',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824843',
    dueDate: '1725116400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'LbHkYTIPeXG0AbQttRMa',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824717',
    dueDate: '1725120000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'ZmWEfpNpH8uyiYEO3Cc9',
    templateTitle: 'Tæthedsprøvning',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1725120000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'hZoAc97llhk4HkUseKIc',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722491884',
    dueDate: '1725120000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'BeNlnzmb7veJR464MmFa',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722490501',
    dueDate: '1725120000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'XvRsrPxexhbiIQSfowrl',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1722492065',
    dueDate: '1725120000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '0f26Uq8PJSUU9Q2AMkVB',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726573433',
    dueDate: '1725127200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'O3KmLH1r47DAvRjfWAGE',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726143049',
    dueDate: '1725206400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'VSflhmdHNo8iOtMFKlE9',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726141384',
    dueDate: '1725206400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '1OSfmT09YjFOEUp0Y2xZ',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726141787',
    dueDate: '1725206400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'vUphIbMJjVRQ67ORPNFJ',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1723113903',
    dueDate: '1725379200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'fTDVfdzK8HDov4I34lzJ',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725254254',
    dueDate: '1725379200',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: '3MrQRwCi1opwlNaO0doo',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725885520',
    dueDate: '1725465600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'Cz8mh7PpBIDbkbcAzPeb',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728544917',
    dueDate: '1725465600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'GumVXNtNiL3Sj3uKVTZG',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728627438',
    dueDate: '1725465600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '33KrzndiJ2iDgY7EKc4z',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726571753',
    dueDate: '1725562800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '6LNeXNB7mGD6RTq2lPm5',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726572601',
    dueDate: '1725562800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '4Cqn8lCyQHuoZCVw05nu',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725625983',
    dueDate: '1725732000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'RQIFSsTGdrg8USKJIdTO',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726140830',
    dueDate: '1725732000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'ANnglZ7iaPacz2NCsxgi',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726464975',
    dueDate: '1725814800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '16HoRGpC4zenuBabYnot',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726824495',
    dueDate: '1726156800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'BWnRYIDmdQ50hKFoaiXR',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727254626',
    dueDate: '1726851600',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Sikkerhed',
    orgId: 'MVaRjg2Zi8kCWqjUm7Nx',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: '6bqPc109aGujGzVZ2ta2',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1724757787',
    dueDate: '1727020800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'ISeBujGqAD6ra4ivoA3k',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1727028000',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: '5zU0zAsTBOyrGvAdcj19',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733901276',
    dueDate: '1727107200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'N7zf4F6W1g06674bO0d2',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730717515',
    dueDate: '1727456400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '6Q64yTubrcQxd46H4LCU',
    templateTitle: 'Kompressor, varmepumper og køle',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1727456400',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Sikkerhed',
    orgId: 'MVaRjg2Zi8kCWqjUm7Nx',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'u0Zpb9s0ZkI0tUojAUgf',
    templateTitle: 'Eftersyn af Løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733917811',
    dueDate: '1727708400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'xezWi8K626nrLpJqeyjY',
    templateTitle: 'Olieudskiller - kvartal',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728017248',
    dueDate: '1727708400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'GPl3hTVUUuCMRDkgix09',
    templateTitle: 'Eftersyn af Løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734072221',
    dueDate: '1727708400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'T31aUYRePy7GHmujVPMr',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727771446',
    dueDate: '1727708400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'Xc9Ou3SrCNcHfnMJLcEx',
    templateTitle: 'Eftersyn af Brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729146505',
    dueDate: '1727708400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'S83yW9qsIAKka69bPOVQ',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728017306',
    dueDate: '1727712000',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'VQl6olN3f3uJhH7CPTNJ',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725254815',
    dueDate: '1727712000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'XfH2hEY7ZehacD4RcTXE',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725259030',
    dueDate: '1727712000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'TWbONPjW9NXWGWC1Iv9h',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1725255442',
    dueDate: '1727712000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'nIfFNNlFgA09lOxy0Bs0',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1731051571',
    dueDate: '1727715600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'aPk2mSqANkUwF9zh1SEd',
    templateTitle: 'Kompressor, varmepumper og køle',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730972559',
    dueDate: '1727715600',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Sikkerhed',
    orgId: 'GXhxBi8aegpCXuONM6vP',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'cegW6QcBl3GRQjbwwA7v',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728986501',
    dueDate: '1727798400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '4RPIWSeERFUktuV7Pgw4',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728986412',
    dueDate: '1727798400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'KbCdFHTNQu7c9YlYi2PJ',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728986776',
    dueDate: '1727798400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'ZT0ELf5eED2GgfCB5FMV',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726481287',
    dueDate: '1727971200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'llgDcTtgJ1NcLGtgCnYZ',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727760565',
    dueDate: '1727971200',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'gwo4M6WgRIUUOeMUpI4I',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728627230',
    dueDate: '1728057600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'gPRC7GawfwU3Zk2VxJhv',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728627498',
    dueDate: '1728057600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'c9KyUjKkaxbYlfWUaP3w',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728628240',
    dueDate: '1728057600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'cPQfdOs9QISgfgrlkaUX',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1726571711',
    dueDate: '1728154800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'gXlLy9b8ezwnEsSiwoEg',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728890673',
    dueDate: '1728154800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'O5gsLs9JTVcB6XC1zAC9',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728382256',
    dueDate: '1728324000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'jgZVrwvtqzUWJtFfE9LF',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728382301',
    dueDate: '1728324000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'IaoVMQNgkBFBySJoRC9V',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727772505',
    dueDate: '1728406800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'xbOXndNXCW0VnNNGFlmw',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730360217',
    dueDate: '1728748800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'uFpJEGVR13vf8e0ZfM6w',
    templateTitle: 'Eftersyn af stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1729267200',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Sikkerhed',
    orgId: 'GXhxBi8aegpCXuONM6vP',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'Ccu4t1mJxcP2xfz4l2my',
    templateTitle: 'Kompressor, varmepumper og køle - hvert 2. år',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733994097',
    dueDate: '1729270800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'elI448e4eH8yxCgtEaGS',
    templateTitle: 'Kompressor, varmepumper og køle - årlig',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1729270800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'UCrcAqzEtc6uoxPpyHLe',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729867673',
    dueDate: '1729360800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'Qr5u1LY0Qn1dAvPxImUs',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729593023',
    dueDate: '1729872000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'yFHgK5BdIPaYO3B3ncKn',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733137497',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'njQrzjVJ1QRsWBcDG4xO',
    templateTitle: 'Kompressor, varmepumper og køle - ulti. okt. 2. år',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733904849',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'XXwX7hNZndxFdS6FVm9w',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729053262',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: '8803aAH259cvmDA4kxnf',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727765090',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'DAESjIpEYayiGejTZ9HU',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732702468',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'N0MxrmRo3yLNBRDxocYS',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727764929',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'eEgf5c1zHB3VG9ctjGka',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1727763109',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '6vQvQO9MUTRAyaYfs03F',
    templateTitle: 'Alarmer',
    auditTemplateId: 'RGS_5c05b284-5573-4f40-9c74-84939a6a20b4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730209706',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'E2BNH0Zj9fchBuHG7Xtv',
    templateTitle: 'Maskiner med tilbehør - ultimo okt',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730981735',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: '5ezFc38Pn2e2sCw3A2FT',
    templateTitle: 'Kompressor, varmepumper og køle - ultimo okt hver 2. år',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733923157',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'pjUdLEJzCJtUnmWhAYu5',
    templateTitle: 'Kompressor, varmepumper og køle - ultimo okt årlig',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733922370',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'e97ljsGmMa3DtLKG9kzC',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730206119',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'R8VfI25mEYckG1rSLaF3',
    templateTitle: 'Kompressor, varmepumper og køleanlæg',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730804172',
    dueDate: '1730390400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'cbRUOgqPB9Yj2eowv8rE',
    templateTitle: 'Eftersyn af Brandudstyr',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729867823',
    dueDate: '1730394000',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Sikkerhed',
    orgId: 'MVaRjg2Zi8kCWqjUm7Nx',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: '9UBSdQQzTbwehdV12vFP',
    templateTitle: 'Eftersyn af El-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730208043',
    dueDate: '1730394000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'TRFvQBO6REGPcBWIxx0s',
    templateTitle: 'Analyser af perkolat',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729057138',
    dueDate: '1730394000',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'kxllMBhixUP3ctwX4tsN',
    templateTitle: 'Eftersyn af brandslukkere',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733741460',
    dueDate: '1730394000',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Sikkerhed',
    orgId: 'GXhxBi8aegpCXuONM6vP',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: '4ybOih2T8bLUhCKwn5TX',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729867354',
    dueDate: '1730394000',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Sikkerhed',
    orgId: 'MVaRjg2Zi8kCWqjUm7Nx',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'ptOaePyzocXc7v1P0TWy',
    templateTitle: 'Analyser af perkolat',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734092196',
    dueDate: '1730401200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'lv585sPEhsla9athUBWb',
    templateTitle: 'Eftersyn af Førstehjælpsudstyr',
    auditTemplateId: 'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733741839',
    dueDate: '1730401200',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Sikkerhed',
    orgId: 'GXhxBi8aegpCXuONM6vP',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'f3AfLrxrfC60Meydeu1h',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730976229',
    dueDate: '1730476800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'ucgxu5qqZVkZ3uRTY2Fp',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730977012',
    dueDate: '1730476800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'klZqoJgSh0Sdmqk73KqV',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733996604',
    dueDate: '1730476800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'IBzhY1RwzPIXjgZgGO8n',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728044036',
    dueDate: '1730649600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'qwzYXX4HLj25CeYbEG1y',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730379986',
    dueDate: '1730649600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'aHBvMzzIhY25mSe6JgNn',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1730736000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'lObrZ8DUAALOaZ3j1PF6',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1731396592',
    dueDate: '1730736000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'FqbfYsQkoWJBaw505VLF',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1731396664',
    dueDate: '1730736000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '5ibrwvTgH7Fam2T3YbUd',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728898516',
    dueDate: '1730833200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '9MP75NYnVBb1U430jktc',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1728898530',
    dueDate: '1730833200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'wYfM4vpP7VTgJ7JzUhkh',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730961986',
    dueDate: '1731002400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '1PcAnr5ZymlNae0DEI4R',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730961958',
    dueDate: '1731002400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'wOLBYKZoJIk3qfNCRTku',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729146406',
    dueDate: '1731085200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'qC8LPC7okWTKnSdbI4bE',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1731427200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'WukRKqMaWdNpowKauYkK',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730208266',
    dueDate: '1731686400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'gzX5R4dyQYt3BoDorA2q',
    templateTitle: 'Eftersyn af Løftegrej - Fyns kran udstyr A/S',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1729072517',
    dueDate: '1731693600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'DySiLaKr79SHmd4MlvU4',
    templateTitle: 'Eftersyn af Løftegrej - Rahbek montage',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733810449',
    dueDate: '1731693600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'cyAJ3ImXqOjGEkj7nnwq',
    templateTitle: 'Porte (manuelle og automatiske) - Otteson porte',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'true',
    hasOnHoldTasks: 'true',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732118400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '8lJF43LfUgbLPIWmPnX7',
    templateTitle: 'Porte (manuelle og automatiske) - Nassau porte',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732122000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'nQsGk51CU3mmoPzlJTDd',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732797248',
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'KDl5J7HLcyLG1m4sS7hO',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'KGHlIY66xmKXn88URa1O',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733989075',
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Sikkerhed',
    orgId: 'ewuwc9F74qFlhASC9HUm',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 's5QsKk5T6ZXS3ZVejEvD',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Sikkerhed',
    orgId: 'Nf6uwt5bdbMJm5z2j9Oj',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'gGpNYn3n0bV4ocGcJGnj',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733738494',
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Sikkerhed',
    orgId: 'GXhxBi8aegpCXuONM6vP',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'ObbE7OqC4619hoHB9tm9',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'BNVYDAEeIIS23QTbIddX',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732882865',
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Sikkerhed',
    orgId: '6XUw649qRDOgMvBqpZBp',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'IEkPcvIqnTnC171TKCEz',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734433281',
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Sikkerhed',
    orgId: 'zdiQ2AlWFSlp78EEjJW7',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: '9P23QDsxnzHPeOVdqzq5',
    templateTitle: 'Dokumentation til kemikalier',
    auditTemplateId: 'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733903929',
    dueDate: '1732294800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Sikkerhed',
    orgId: 'MVaRjg2Zi8kCWqjUm7Nx',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'OoVXLtpvMxxPkBqnPyjS',
    templateTitle: 'Kontrol af betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734073804',
    dueDate: '1732298400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'xwlg8P2a2IPqejvVkS5s',
    templateTitle: 'Eftersyn af Brandslukkere - JMM Rønnede',
    auditTemplateId: 'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730983090',
    dueDate: '1732554000',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: '0GhHaQFCI8gWsKcGmL5H',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733737826',
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'pCXVUjWlQG4UmNQChSTS',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'bbRqLaJvlJ2vNxc6lUHb',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733823403',
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'YQeNTH94Msa67wUsmQor',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732620273',
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'xdhWNWOKtdMfm4jkGABE',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'XH5Qv5GRUpCnRpXYInLv',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732700102',
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'HU8twEwz8wd1eESDiwAA',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734087720',
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'dnFNd8uYEBf6XKGWsw1w',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732798516',
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '5jRLqpx6cpUacXsgXsMX',
    templateTitle: 'Betonelementer',
    auditTemplateId: 'RGS_cdf5e221-aa55-452a-b640-319584a41eae',
    needsResolution: null,
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732899600',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Kontrolplan',
    orgId: 'RFEH9zFya2Yd3HEvy7b4',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'LXHjE2bOx94tbjN1KvfQ',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733918742',
    dueDate: '1732903200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'Ub6EEakhoUE1VzfOfUmA',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733913754',
    dueDate: '1732903200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'DRHijW6fYNeotW91BlKi',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733902549',
    dueDate: '1732903200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Kontrolplan',
    orgId: 'aCFz8LzSxaKKvDo1pjMM',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'nNOkUu4lV4SyOpaj5mWV',
    templateTitle: 'Maskiner med tilbehør - ultimo nov',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733231610',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'vRQMapUnVbYfFaHWuRl2',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732616106',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Sikkerhed',
    orgId: 'pGNgugHtx3V2EXrrUL9d',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'JfzRkktqq9b6iPQzRqF7',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733319543',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '26JLPnWrxLgRrRlidWcP',
    templateTitle: 'Maskiner med tilbehør',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1732701879',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'dG0iZDnYSZUohBmjG2cc',
    templateTitle: 'Olieudskiller ulti. nov.',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'GG2DjJGfhAROw1jwYYCg',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730442043',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'QskX4rBNsKyytNyAdt6R',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730442766',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'bf4oFy2FHZOxTtdCC9zk',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'rN9OQshq6BLyLAaLcsc6',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730442956',
    dueDate: '1732982400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'EBahYy4xkfaMPTdo7PMA',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733812980',
    dueDate: '1733068800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'WtL415HP1RtKUtZSdp7u',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733813110',
    dueDate: '1733068800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'id3tJvt3P5ThBqu7jFeY',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733996505',
    dueDate: '1733068800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
    orgId: 'NRiWvZz6lBLjvvPpR5vY',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'WTb6TLdPBkdyAG8Q4AWG',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733121793',
    dueDate: '1733241600',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'BJyqJKvDG8qNBqoB0CNd',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733906661',
    dueDate: '1733241600',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Kontrolplan',
    orgId: 'XgmFoJawgUnjV7758p1g',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: '6HEXJHFPnL5SmSdEPJ0x',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730894380',
    dueDate: '1733328000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'AHsmP19jvfiud0tMBgzS',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1730970502',
    dueDate: '1733328000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'JaknL5X35X566mRDEFMh',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1733328000',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
    orgId: 'QZDH2TKXFkJBNHsUMWLX',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '4r3LO0gqkn1WLeyTPrur',
    templateTitle: 'Tæthedsprøvning - Månedlig',
    auditTemplateId: 'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734432737',
    dueDate: '1733425200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'sAJ29NU7SgkFuweIR6oe',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1733425200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Kontrolplan',
    orgId: 'dnmJP20zzOORzcn9Rvf5',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'C2oIXFha0qMODmwWVseM',
    templateTitle: 'Perkolattank -bassin',
    auditTemplateId: 'RGS_19657e7c-7973-494d-95c5-152bdc109891',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733133702',
    dueDate: '1733500800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: '7DQ4R73jdZmXfohx6VL0',
    templateTitle: 'Haller/opbevaringscontainer',
    auditTemplateId: 'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733133551',
    dueDate: '1733500800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'z0coPUE9Tz6bzNV3OX0Q',
    templateTitle: 'Olieudskiller - 3x årligt',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733132721',
    dueDate: '1733504400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'UM1wL2brpmCA9qLvrGVK',
    templateTitle: 'Eftersyn af Stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1733508000',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'XVu0DB6gQmnGO5ldayjP',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733909208',
    dueDate: '1733594400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'gCjdaRNTr4DcqdH6uliv',
    templateTitle: 'Brønde, drænbrønde og sandfang - månedlig',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733909151',
    dueDate: '1733594400',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'GRmupropupfEeN5JUcS8',
    templateTitle: 'Olieudskiller',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733126669',
    dueDate: '1733677200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'u3geirUv8DOhYu9rV3Dn',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734019200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'MtFB2gxJT4M7Eyd13ob8',
    templateTitle: 'Beredskabplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733815169',
    dueDate: '1734105600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: '8qj4HGjGtQ7ejx0wz8iq',
    templateTitle: 'Beredskabsplan',
    auditTemplateId: 'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734109200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'jQVFzzoYedAVslZpoAiq',
    templateTitle: 'Porte (manuelle og automatiske)',
    auditTemplateId: 'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734112800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '1HAptmmya45Z0iFLBXFj',
    templateTitle: 'Eftersyn af El-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734112800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Sikkerhed',
    orgId: 'vacP9C8PMGUNLFNRSEj3',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: '3g7iVKXbVE8Wc9dcv3m7',
    templateTitle: 'Maskiner',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734112800',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'hiH34Hzelav5aTaxJUNY',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734285600',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'bKJnHZD5KMWn9XSLSfju',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733134164',
    dueDate: '1734710400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'ZiRyo0ffqMmiIDXmy6Pw',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733135031',
    dueDate: '1734710400',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Kontrolplan',
    orgId: 'MKk4NF11jHpUuxpBgnU0',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'O7cw1GC5JdpLTkzcjbNQ',
    templateTitle: 'Maskiner med tilbehør',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734710400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'IFQbckTE0oBdZIiVWS6K',
    templateTitle: 'Eftersyn af stiger',
    auditTemplateId: 'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734710400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'TWUxKxXuoqiB62QmQwjd',
    templateTitle: 'Omlægning af maskinpark til el',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734717600',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'HO5kjs6KIq9eNKa0nXTR',
    templateTitle: 'Olietanke',
    auditTemplateId: 'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1734721200',
    orgTitle: 'RGS Nordic A/S - Øst - Kalundborg - Kontrolplan',
    orgId: 'gewxiI3RplesacThYX3H',
    subGroup: 'RGS Nordic A/S - Øst - Kalundborg',
  },
  {
    auditId: 'UYrV9rBcQAJEHNsqIjGw',
    templateTitle: 'Eftersyn af Løftegrej',
    auditTemplateId: 'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734527420',
    dueDate: '1734721200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Sikkerhed',
    orgId: 'TbKy3dxDGQmrOKEKZT4K',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'CXTosTA58VENTQlOHas2',
    templateTitle: 'Maskiner med tilbehør',
    auditTemplateId: 'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00',
    needsResolution: 'true',
    hasOnHoldTasks: 'true',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733910167',
    dueDate: '1734721200',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'O9Wy7tHHfQOt5az2kqAz',
    templateTitle: 'Olieudskiller - kvartal',
    auditTemplateId: 'RGS_7955dd62-403f-47d4-8722-eb9729af9399',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735657200',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'DsmlEsdpOacmS6VBcW1M',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733125521',
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'M8EHKjKVHXZ3MyGmOLxN',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733125316',
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'VmjwaMVkBIKPSfJaW7Hd',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
    orgId: 'b8lQZcH3bkHeNddxHZVd',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: '0J3LwaccIAgGyqh20yxX',
    templateTitle: 'Minimering af perkolatudledning - månedlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1733124183',
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Miljømål',
    orgId: 'YXdtSTiaTIPfEy5Mt8do',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'qtAW6wCYKmb21r2Z0SYA',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
    orgId: 'b8lQZcH3bkHeNddxHZVd',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'zyoyBxmHIfy7E3OPFyVT',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
    orgId: 'b8lQZcH3bkHeNddxHZVd',
    subGroup: 'RGS Nordic A/S - Øst - Gadstrup',
  },
  {
    auditId: 'BrhzmSP5cBOhDs4cbFKq',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'XLT8Z49n88IC9kl8WdwM',
    templateTitle: 'Minimering af dieselforbrug - årlig',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'eGuoylMi6i5N8DDa8CW9',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
    orgId: 'md32SWSvJjcO4sq7oQWr',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'W8JAT9MxfgtViSg1Hifn',
    templateTitle: 'Eftersyn af el-værktøj og kabler',
    auditTemplateId: 'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '3H4dlGOqvJgQZkKBzV9a',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Miljømål',
    orgId: '1n7UGbvhzUFRVlAd0X2d',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: '5e2SnqzOofbw6gak4Lex',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
    orgId: 'md32SWSvJjcO4sq7oQWr',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'gqVA4MOZ0Ci4inHsXawV',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
    orgId: 'md32SWSvJjcO4sq7oQWr',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
  {
    auditId: 'GPQsvghjxw25lofJeIxz',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'M2aJxx7CEcQplCGyT7EP',
    templateTitle: 'Minimering af perkolatudledning - årlig',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'VFLsy4wehQLb8fopEJe4',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Miljømål',
    orgId: 'zK8FBFB8aGxIqXf40kYD',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'iWyKkPJGcEypffbPXqMZ',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3 - Miljømål',
    orgId: '1n7UGbvhzUFRVlAd0X2d',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 3',
  },
  {
    auditId: 'KS2FpjjKFOOtltwW6dSx',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734092996',
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
    orgId: '45iGajxZXyYuuMbcI9rc',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'Q8kV21njix9PVbaWUU03',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734098394',
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
    orgId: '45iGajxZXyYuuMbcI9rc',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'HEYXcFQKd5v7Qp28dIAB',
    templateTitle: 'Minimering af perkolatudledning',
    auditTemplateId: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Miljømål',
    orgId: 'IOeedHDwQTpxahtnvRbl',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'QjhuKWMwO6m57HRGrqCy',
    templateTitle: 'Kompressor, varmepumper og køleanlæg',
    auditTemplateId: 'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'RiLtKmNDbcZXxqVcMqEZ',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Miljømål',
    orgId: 'IOeedHDwQTpxahtnvRbl',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'LDHzbWv0Linss8cU2NOK',
    templateTitle: 'Pumper',
    auditTemplateId: 'RGS_808d208a-8799-4979-ab01-a7d2d2470672',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7 - Kontrolplan',
    orgId: 'vU3yXTyfPPnu8O7gZtcv',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 4,6,7',
  },
  {
    auditId: 'qHWt0DasqAN6vuw9IIzi',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5 - Miljømål',
    orgId: 'IOeedHDwQTpxahtnvRbl',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Selinevej port 5',
  },
  {
    auditId: 'xWuzWL5kS4nDuosxhqG8',
    templateTitle: 'Minimering af dieselforbrug',
    auditTemplateId: 'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
    orgId: '45iGajxZXyYuuMbcI9rc',
    subGroup: 'RGS Nordic A/S - Øst - Kbh Nordsøvej',
  },
  {
    auditId: 'r5pP0gAPlav34FTKvu4h',
    templateTitle: 'Intern sikkerhedsrundering',
    auditTemplateId: 'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Sikkerhed',
    orgId: 'M0plHprYi0RWQTA3vF0u',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: '8rAWOSXAI8KfG8Iq44pZ',
    templateTitle: 'Minimering af elforbrug',
    auditTemplateId: 'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735660800',
    orgTitle: 'RGS Nordic A/S - Øst - Sanderødgård - Miljømål',
    orgId: 'RNDTM0RYhw8YLgHxP0Sf',
    subGroup: 'RGS Nordic A/S - Øst - Sanderødgård',
  },
  {
    auditId: 'O9VZLk5GRf8Pi4hZF90X',
    templateTitle: 'Analyser af perkolat',
    auditTemplateId: 'RGS_554528db-3333-441a-b6c0-bad17557d370',
    needsResolution: 'false',
    hasOnHoldTasks: 'false',
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'true',
    completeTime: '1734527180',
    dueDate: '1735664400',
    orgTitle: 'RGS Nordic A/S - Øst - Rødby - Kontrolplan',
    orgId: '0mNnCsjlW5RzBUO13IzY',
    subGroup: 'RGS Nordic A/S - Øst - Rødby',
  },
  {
    auditId: 'IEZtehEs3rdkEySRUB1k',
    templateTitle: 'Belægningskontrol',
    auditTemplateId: 'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735664400',
    orgTitle: 'RGS Nordic A/S - Øst - Vemmelev - Kontrolplan',
    orgId: '7LzJMrKQp2bSg46pVLoa',
    subGroup: 'RGS Nordic A/S - Øst - Vemmelev',
  },
  {
    auditId: 'EJnRHaC7krnaSNrWMBVU',
    templateTitle: 'Brønde, drænbrønde og sandfang ',
    auditTemplateId: 'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6',
    needsResolution: 'true',
    hasOnHoldTasks: null,
    groupId: 'RuXfdQ77eGHqAsQSHK5m',
    isCompleted: 'false',
    completeTime: null,
    dueDate: '1735668000',
    orgTitle: 'RGS Nordic A/S - Øst - Herlufmagle - Kontrolplan',
    orgId: 'BCrB2H2whlIn8z01ASGu',
    subGroup: 'RGS Nordic A/S - Øst - Herlufmagle',
  },
];

function groupAndCountByMonth(data: any) {
  // Step 1: Group by `auditTemplateId`
  const groupedByTemplate = data.reduce((acc: any, item: any) => {
    const { auditTemplateId, dueDate } = item;

    // Convert the timestamp (in seconds) to a Date object
    const date = new Date(parseInt(dueDate) * 1000);
    const yearMonth = format(date, 'MMMM');

    // Initialize group if not exists
    if (!acc[auditTemplateId]) {
      acc[auditTemplateId] = {};
    }

    // Initialize month count if not exists
    if (!acc[auditTemplateId][yearMonth]) {
      acc[auditTemplateId][yearMonth] = 0;
    }

    // Increment the count for the specific month
    acc[auditTemplateId][yearMonth] += 1;

    return acc;
  }, {});

  return groupedByTemplate;
}

export const dataSummery = groupAndCountByMonth(result);

export const mapData = {
  'RGS_5c05b284-5573-4f40-9c74-84939a6a20b4': 'Alarmer',
  'RGS_0ecf1bc3-c3c1-43c4-a3d4-8f32ee94e598': 'Belægningkontrol',
  'RGS_39efaf42-bfce-4d02-973b-822c962a8fe6': 'Beredskabplan',
  'RGS_cdf5e221-aa55-452a-b640-319584a41eae': 'Betonelementer',
  'RGS_8e28be37-4f66-4f18-b48f-df43b75ae5e5': 'Brandudstyr',
  'RGS_b3c26e6e-0266-49a8-a3a1-999fa81587b6': 'Brønde, drænbrønde og sandfang',
  'RGS_d527adac-3e1a-4f6e-bfdb-17ad20010cd6': 'Dieselforbrug',
  'RGS_4df9d75b-8d1e-4544-a362-ed223da7d260': 'Elforbrug',
  'RGS_fd48f2a2-5f58-474d-851d-3d3b9b2c9f04': 'Elværktøj & kabler',
  'RGS_6308f5fb-7f11-417f-be29-46cd50cefa8e': 'Førstehjælpsudstyr',
  'RGS_cd3ea01d-3be3-4b6a-b482-097cabcf83f4': 'Haller',
  'RGS_f35ed2c1-2018-4df0-a0d1-bbe678ad5f02': 'Intern sikkerhedsrundering',
  'RGS_5f73bfe1-36fd-4cb6-a7cb-83d787161a62': 'Information og oplæring',
  'RGS_b6227bd5-4a15-403c-bd9b-5cd6ebf1c259': 'Kompressorer, varmepumper og køleanlæg',
  'RGS_fbd863d8-b2a3-407a-b2f5-476cdfd53489': 'Kemikalier',
  'RGS_0505d56b-e5b7-419d-89c3-08e47bd3c03a': 'Løftegrej',
  'RGS_8f81b2fe-3722-4aeb-9c52-c0f9d41cef00': 'Maskiner vedligehold',
  'RGS_f7dee9cc-7b3d-4950-b241-8f4cd086d121': 'Olietanke',
  'RGS_7955dd62-403f-47d4-8722-eb9729af9399': 'Olieudskiller',
  'RGS_554528db-3333-441a-b6c0-bad17557d370': 'Perkolat analyser',
  'RGS_19657e7c-7973-494d-95c5-152bdc109891': 'Perkolat-tank/bassin',
  'RGS_c84cb65a-9230-4982-8d64-c03351aa793f': 'Perkolatforbrug',
  'RGS_bacb90dc-7e4a-4277-b566-591e55bb4844': 'Porte',
  'RGS_808d208a-8799-4979-ab01-a7d2d2470672': 'Pumper',
  'RGS_c6ba76b5-6277-47d7-be0a-aae5e2e566e4': 'Stiger',
  'RGS_3ebe4f5b-1c1c-4c0f-86d8-287882291291': 'Tæthedsprøvning',
};
