import React from 'react';
import ClickEditTextArea from 'components/TextArea/ClickEditTextArea';
import firebase from 'firebase/compat/app';
import { useParams } from 'react-router';
import Message, { Type } from 'components/Notification/Message';
import { useAppSelector } from '../../hooks';

const messageKey = 'AuditNote';

export default function AuditNote() {
  const note = useAppSelector((state) => state.auditSchedules.currentAuditSchedule?.note);

  const { orgId, auditId } = useParams<{
    orgId: string;
    auditId: string;
  }>();
  const handleSave = async (note: string) => {
    try {
      Message({
        type: Type.LOADING,
        key: messageKey,
        message: `Loading...`,
        // description: `${payload.title} blev oprettet og kan nu søges frem i listen her`,
      });
      if (!orgId || !auditId) {
        throw new Error('orgId or auditId is undefined');
      }

      await firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('auditSchedules')
        .doc(auditId)
        .update({ note });
      Message({
        key: messageKey,
        message: `Noten blev gemt`,
      });
    } catch (e) {
      console.error(e);
      Message({
        key: messageKey,
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Noten blev ikke oprettet, prøv venligst igen`,
      });
    }
  };
  return <ClickEditTextArea text={note || ''} onSave={handleSave} />;
}
