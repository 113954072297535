import { useEffect } from 'react';
import { Checkbox } from 'components/ui/checkbox';
import { useAudit } from './audit-context';
import { Card } from 'components/ui/card';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { Badge } from 'components/ui/badge';
import { getLatestAudit } from './PreviousAuditCard';
import { getStatusColor, getStatusIcon } from '../InternalAuditOverview/components/AuditMatrix';
import { getDisplayStatus } from '../InternalAuditDetail/audit-summary';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';

export function TaskSelection({ orgId }: { orgId: string }) {
  const { audits, selectedTasks, masterTaskList, setSelectedTasks, setStep, saveToLocalStorage } =
    useAudit();

  const audit = getLatestAudit(audits, orgId);

  useEffect(() => {
    saveToLocalStorage();
  }, [selectedTasks, saveToLocalStorage]);

  const handleTaskToggle = (taskId: string) => {
    setSelectedTasks(
      selectedTasks.includes(taskId)
        ? selectedTasks.filter((id) => id !== taskId)
        : [...selectedTasks, taskId]
    );
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="space-y-2">
        {masterTaskList
          .sort((a, b) => a.label.localeCompare(b.label))
          .map((task, index) => {
            const hasDeviationPreviously =
              audit &&
              Object.entries(audit?.subTasksMap).some(
                //@ts-ignore
                ([_, value]) => task.id === value.taskId && value.status === 'deviation'
              );

            const hasObservationPreviously =
              audit &&
              Object.entries(audit?.subTasksMap).some(
                //@ts-ignore
                ([_, value]) => task.id === value.taskId && value.status === 'observation'
              );

            return (
              <Card key={task.id} className="flex w-[500px] px-4 items-center space-x-2">
                <Accordion type="multiple" className="w-full">
                  <AccordionItem className="border-none" value={`item-${index}`} key={index}>
                    <div className="flex items-center justify-between ">
                      <div className="flex w-full items-center space-x-2">
                        <Checkbox
                          id={task.id}
                          checked={selectedTasks.includes(task.id)}
                          onCheckedChange={() => handleTaskToggle(task.id)}
                        />
                        <label
                          htmlFor={task.id}
                          className="flex grow text-sm cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          {task.label}
                        </label>
                        {hasDeviationPreviously && (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <Badge className={getStatusColor('deviation')}>
                                  {getStatusIcon('deviation')}
                                </Badge>
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className="text-sm">
                                Seneste audit havde afvigelser på denne opgave
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        )}
                        {hasObservationPreviously && (
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <Badge className={getStatusColor('observation')}>
                                  {getStatusIcon('observation')}
                                </Badge>
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className="text-sm">
                                Seneste audit havde observationer på denne opgave
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        )}
                        {/* <div className="" /> */}
                        <Badge variant="secondary">{task.category}</Badge>
                      </div>
                      <AccordionTrigger></AccordionTrigger>
                    </div>
                    <AccordionContent>
                      <ul>
                        {task.subTasks.map((subTask) => (
                          <li className="flex items-center space-x-2">
                            <p className="text-sm">{subTask.label}</p>
                          </li>
                        ))}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </Card>
            );
          })}
      </div>
      {/* <Button onClick={() => setStep('execution')} disabled={selectedTasks.length === 0}>
        Forsæt
      </Button> */}
    </div>
  );
}
