import type React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import api, { MasterList } from './api';
import firebase from 'firebase/compat/app';
import { useAppSelector } from 'hooks';

type AuditStep = 'initial' | 'location' | 'tasks' | 'execution' | 'summary';

interface FollowUpTask {
  id: number;
  taskId: string;
  task: string;
  responsible: string;
  completed: boolean;
}

interface FinishedAudit {
  id: number;
  location: string;
  tasks: string[];
  remarks: { [key: string]: string };
  followUpTasks: FollowUpTask[];
  completedAt: string;
}

interface AuditContextType {
  step: AuditStep;
  setStep: (step: AuditStep) => void;
  audits: any[];
  selectedAuditDate: Date;
  setSelectedAuditDate: (date: Date) => void;
  locations: { id: string; title: string }[];
  selectedLocation: string;
  setSelectedLocation: (location: string) => void;
  selectedType: string;
  setSelectedType: (type: string) => void;
  selectedTasks: string[];
  setSelectedTasks: (tasks: string[]) => void;
  masterTaskList: MasterList;
  remarks: { [key: string]: string };
  setRemarks: (remarks: { [key: string]: string }) => void;
  followUpTasks: FollowUpTask[];
  setFollowUpTasks: (tasks: FollowUpTask[]) => void;
  currentTaskIndex: number;
  setCurrentTaskIndex: (index: number) => void;
  saveToLocalStorage: () => void;
  submitAudit: () => void;
  finishedAudits: FinishedAudit[];
  setFinishedAudits: (audits: FinishedAudit[]) => void;
}

const AuditContext = createContext<AuditContextType | undefined>(undefined);

export function useAudit() {
  const context = useContext(AuditContext);
  if (context === undefined) {
    throw new Error('useAudit must be used within an AuditProvider');
  }
  return context;
}

export function AuditProvider({ children }: { children: React.ReactNode }) {
  const userId = useAppSelector((state) => state.user.details.uid);
  const [step, setStep] = useState<AuditStep>('initial');
  const [locations, setLocations] = useState<{ id: string; title: string }[]>([]);
  const [audits, setAudits] = useState<any[]>([]);
  const [selectedAuditDate, setSelectedAuditDate] = useState(new Date());
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [masterTaskList, setMasterTaskList] = useState<MasterList>([]);
  const [remarks, setRemarks] = useState<{ [key: string]: string }>({});
  const [followUpTasks, setFollowUpTasks] = useState<FollowUpTask[]>([]);
  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
  const [finishedAudits, setFinishedAudits] = useState<FinishedAudit[]>([]);

  useEffect(() => {
    const getData = async () => {
      const { masterList, locations: lData } = await api.getRequiredData();
      setMasterTaskList(masterList);
      setSelectedTasks(masterList.map((task) => task.id));
      setLocations(lData);
    };
    getData();
  }, []);
  useEffect(() => {
    console.log({ userId });

    const unsubscribe = firebase
      .firestore()
      .collection(`internalAudits`)
      .where('auditor.userId', '==', userId)
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log({ list });

          setAudits(list);
        }
      }, console.error);
    return () => unsubscribe?.();
  }, [userId]);

  useEffect(() => {
    // const savedAudit = localStorage.getItem('currentAudit');
    // if (savedAudit) {
    //   const { step, selectedLocation, selectedTasks, remarks, followUpTasks, currentTaskIndex } =
    //     JSON.parse(savedAudit);
    //   setStep(step);
    //   setSelectedLocation(selectedLocation);
    //   setSelectedTasks(selectedTasks);
    //   setRemarks(remarks);
    //   setFollowUpTasks(followUpTasks);
    //   setCurrentTaskIndex(currentTaskIndex);
    // }
    // const savedFinishedAudits = localStorage.getItem('finishedAudits');
    // if (savedFinishedAudits) {
    //   setFinishedAudits(JSON.parse(savedFinishedAudits));
    // }
  }, []);

  const saveToLocalStorage = () => {
    localStorage.setItem(
      'currentAudit',
      JSON.stringify({
        step,
        selectedLocation,
        selectedTasks,
        remarks,
        followUpTasks,
        currentTaskIndex,
      })
    );
    localStorage.setItem('finishedAudits', JSON.stringify(finishedAudits));
  };

  const submitAudit = () => {
    const newFinishedAudit: FinishedAudit = {
      id: Date.now(),
      location: selectedLocation,
      tasks: selectedTasks,
      remarks,
      followUpTasks,
      completedAt: new Date().toISOString(),
    };
    setFinishedAudits([...finishedAudits, newFinishedAudit]);

    // Clear current audit data
    localStorage.removeItem('currentAudit');
    setStep('initial');
    setSelectedLocation('');
    setSelectedTasks(masterTaskList.map((task) => task.id));
    setRemarks({});
    setFollowUpTasks([]);
    setCurrentTaskIndex(0);

    // Save updated finished audits
    localStorage.setItem('finishedAudits', JSON.stringify([...finishedAudits, newFinishedAudit]));
  };

  return (
    <AuditContext.Provider
      value={{
        step,
        setStep,
        audits,
        selectedAuditDate,
        setSelectedAuditDate,
        locations,
        selectedLocation,
        setSelectedLocation,
        selectedType,
        setSelectedType,
        selectedTasks,
        setSelectedTasks,
        masterTaskList,
        remarks,
        setRemarks,
        followUpTasks,
        setFollowUpTasks,
        currentTaskIndex,
        setCurrentTaskIndex,
        saveToLocalStorage,
        submitAudit,
        finishedAudits,
        setFinishedAudits,
      }}
    >
      {children}
    </AuditContext.Provider>
  );
}
