import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import keyBy from 'lodash.keyby';
import { InventoryType } from 'modules/inventory/types';
import { useParams } from 'react-router';

export default function useIssueActivity() {
  const { orgId, issueId } = useParams<{ orgId: string; issueId: string }>();
  const [activity, setActivity] = useState<any>([]);
  const [isLoading, setisLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!orgId || !issueId) return;
    const query = firebase
      .firestore()
      .collection('orgs')
      .doc(orgId)
      .collection('activity')
      .where('context.issueId', '==', issueId)
      .orderBy('createTime', 'desc');

    const sub = query.onSnapshot(
      (snap) => {
        if (!snap.empty) {
          const data = snap.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
            createTime: doc.data()?.createTime?.toDate(),
          }));
          setActivity(data);
        }
      },
      (error) => {
        console.error('Error fetching activity', error);
      },
      () => {
        setisLoading(false);
      }
    );

    return () => {
      sub?.();
    };
  }, []);

  return { activity, isLoading };
}
