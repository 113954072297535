import { FeaturesState, FeaturesActionTypes, DBFeaturesShape } from './types';
import keyBy from 'lodash.keyby';
import { FEATURES_FETCH_REQUESTED, FEATURES_FETCH_SUCCEEDED, FEATURES_FETCH_FAILED } from './types';

const initialState: FeaturesState = {
  features: [],
  featuresMap: {},
  loading: false,
};

export default function featureControl(
  state = initialState,
  action: FeaturesActionTypes
): FeaturesState {
  switch (action.type) {
    case FEATURES_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FEATURES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    case FEATURES_FETCH_SUCCEEDED:
      return {
        ...state,
        features: action.payload.data,
        featuresMap: keyBy(action.payload.data, 'id') as {
          [id: string]: DBFeaturesShape;
        },
        loading: false,
      };

    default:
      return state;
  }
}
