import React, { Component, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AuditTaskItem from './AuditTaskItem';
import { ArrowsAltOutlined, HolderOutlined, SwapOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

// // fake data generator
// const getItems = (count: number) =>
//   Array.from({ length: count }, (v, k) => k).map((k) => ({
//     id: `item-${k}`,
//     content: `Question ${k} blablbabla`,
//   }));

// a little function to help us with reordering the result
const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 4;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  // padding: grid * 2,
  display: 'flex',
  alignItems: 'center',
  margin: `0 0 ${grid}px 0`,
  width: '100%',
  opacity: isDragging ? 0.5 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (_: any) => ({
  // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  // padding: grid,
  // width: 250,
});

export default function AuditTaskList(props: any) {
  const [tasks, setTasks] = useState(props.tasks);
  // constructor(props: any) {
  //     super(props);
  //     this.state = {
  //         items: props.initialItems,
  //     };
  //     this.onDragEnd = this.onDragEnd.bind(this);
  // }

  useEffect(() => {
    setTasks(props.tasks);
  }, [props.tasks]);

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(tasks, result.source.index, result.destination.index);

    setTasks(items);
    props.onChange(items);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {tasks.map((item: any, index: number) => (
              <Draggable
                isDragDisabled={props.isReadOnly}
                key={item.id}
                draggableId={item.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                    // style={{ display: 'flex' }}
                  >
                    <div
                      {...provided.dragHandleProps}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {!props.isReadOnly && (
                        <Tooltip title="Træk for at ændre rækkefølgen" placement="left">
                          <ArrowsAltOutlined
                            style={{
                              fontSize: 18,
                              transform: 'rotate(-45deg)',
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div style={{ width: '100%' }}>
                      <AuditTaskItem id={item.id} description={item.description} />
                    </div>
                    {/* {item.content} */}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
