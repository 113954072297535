import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from 'date-fns';
type DateType = 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year';

export function getDateInterval(dateInterval: [string, string], dateType: DateType) {
  const [start, end] = dateInterval;
  if (dateType === 'week') {
    return [
      format(startOfWeek(new Date(start)), 'yyyy-MM-dd'),
      format(endOfWeek(new Date(end)), 'yyyy-MM-dd'),
    ];
  }
  if (dateType === 'month') {
    return [
      format(startOfMonth(new Date(start)), 'yyyy-MM-dd'),
      format(endOfMonth(new Date(end)), 'yyyy-MM-dd'),
    ];
  }
  if (dateType === 'quarter') {
    return [
      format(startOfQuarter(new Date(start)), 'yyyy-MM-dd'),
      format(endOfQuarter(new Date(end)), 'yyyy-MM-dd'),
    ];
  }
  if (dateType === 'year') {
    return [
      format(startOfYear(new Date(start)), 'yyyy-MM-dd'),
      format(endOfYear(new Date(end)), 'yyyy-MM-dd'),
    ];
  }
  return [
    format(startOfDay(new Date(start)), 'yyyy-MM-dd'),
    format(endOfDay(new Date(end)), 'yyyy-MM-dd'),
  ];
}
