import React from 'react';
import LogsCard from '../GsmCustom/LogsCard';
import firebase from 'firebase/compat/app';
import Message, { Type } from 'components/Notification/Message';
import { useTranslation } from 'react-i18next';
import { useLatestGsmPlantAlerts } from './useLatestGsmPlantLog';
import AlertsCard from './AlertsCard';

export default function AlertLogsContainer({ deviceId }: { deviceId: string }) {
  const { t } = useTranslation();
  const { isLoading, data } = useLatestGsmPlantAlerts({ deviceId });

  console.log({ data });

  const updateResolveTime = async (logId: string) => {
    let messageKey = 'updateResolveTime';

    try {
      Message({
        key: messageKey,
        type: Type.LOADING,
        message: t('general.saveLoading'),
      });
      await firebase.firestore().collection('gsmPlantLogs').doc(logId).update({
        resolveTime: new Date(),
      });
      Message({
        key: messageKey,
        type: Type.SUCCESS,
        message: t('general.saveSuccess'),
      });
    } catch (error) {
      Message({
        key: messageKey,
        type: Type.ERROR,
        message: t('general.saveError'),
      });
      console.error(error);
    }
  };
  return (
    <AlertsCard
      cardTitle="gsm.alert.card_title_alert_logs"
      isLoading={isLoading}
      data={data}
      markResolved={updateResolveTime}
    />
  );
}
