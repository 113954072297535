import ReportModal from 'components/ReportModal';
import { isDev } from 'config';
import { useAppSelector } from 'hooks';

export default function RapportContainer({ buttonType }: { buttonType?: 'default' | 'link' }) {
  const groupIds = isDev
    ? ['98gXbL6kxlaDmMMGjBdw']
    : [
        'RuXfdQ77eGHqAsQSHK5m',
        'szGrXKI9MAya8vPFG3wT',
        'Gua3ROcmn9CBLIGSpOVn',
        '46zYSYgrJQ68tjYqMhSe',
      ];
  const orgs = useAppSelector((state) =>
    state.orgs.orgs.filter((org) => org.groupId && groupIds.includes(org.groupId))
  );

  const rgsOnly = orgs.some((org) => org.groupId && groupIds.includes(org.groupId));

  if (!rgsOnly) return null;
  return (
    <ReportModal
      orgs={orgs}
      groupIds={groupIds}
      groupTitle="RGS Nordic A/S"
      buttonType={buttonType}
    />
  );
}
