import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export default function useDeviceStates() {
  const [devices, setDevices] = useState<any>({});
  const [isLoading, setisLoading] = useState<boolean>(true);

  useEffect(() => {
    const sub = firebase
      .firestore()
      .collection('sensorHeartbeat')
      .doc('devices')
      .onSnapshot((snap) => {
        if (snap.exists) {
          const data = snap.data();
          setDevices(data as any);
          setisLoading(false);
        }
      });

    return () => {
      sub?.();
    };
  }, []);

  return {
    updateTime: devices?.updateTime || null,
    states: devices?.states || {},
    isLoading,
  };
}
