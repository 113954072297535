import { AuditSystem } from './audit-system';
import { AuditProvider } from './audit-context';
import { Layout } from 'antd';
import HeaderBar from 'Layout/HeaderBar';
import FooterBar from 'Layout/FooterBar';

const { Content } = Layout;

function OverviewContainer() {
  return (
    <Layout>
      <HeaderBar />
      <Content
        style={{
          margin: '24px 24px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <AuditProvider>
          <AuditSystem />
        </AuditProvider>
      </Content>
      <FooterBar />
    </Layout>
  );
}

export default OverviewContainer;
