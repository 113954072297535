import { useState } from 'react';
import firebase from 'firebase/compat/app';
import { Button } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Message, { Type } from 'components/Notification/Message';

export default function useDuplicateTemplateTask({
  groupId,
  templateId,
  taskId,
}: {
  groupId: string;
  templateId: string;
  taskId: string;
}) {
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const handleDuplicateTemplate = async () => {
    try {
      if (!groupId || !templateId || !taskId) {
        throw new Error(
          `[AUDIT_TEMPLATE_DUPLICATE]: Missing groupId=${groupId} or templateId=${templateId} or taskId=${taskId}`
        );
      }
      setloading(true);
      const taskDoc = await firebase
        .firestore()
        .collection(`groups/${groupId}/groupAuditTemplates/${templateId}/tasks`)
        .doc(taskId)
        .get();
      const task = taskDoc.data();
      if (!task) {
        throw new Error(
          `Task not found for groupId=${groupId} templateId=${templateId} id=${taskId}`
        );
      }
      await firebase
        .firestore()
        .collection(`groups/${groupId}/groupAuditTemplates/${templateId}/tasks`)
        .add({
          ...task,
          createTime: new Date(),
          description: `${task.description} (copy)`,
        });
    } catch (error) {
      console.error(error);
      Message({
        key: 'deleteAuditTemplateTask',
        type: Type.ERROR,
        message: `Der skete en fejl`,
      });
    } finally {
      setloading(false);
    }
  };
  return {
    loading,
    handleDuplicateTemplate,
  };
}
