import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

export default function useGenerateSensorReport(sensorId: string) {
  const [config, setConfig] = useState<any>({});
  const [loading, setloading] = useState(true);

  useEffect(() => {
    let unsubscribe: any = null;
    if (sensorId) {
      unsubscribe = firebase
        .firestore()
        .collection(`generateReportConfig`)
        .where('sensorId', '==', sensorId)
        .limit(1)
        .onSnapshot(
          (query) => {
            if (!query.empty) {
              setConfig(query.docs[0].data());
            } else {
              console.warn('No generateReportConfig found for sensorId: ', sensorId);
            }
            setloading(false);
          },
          (error) => {
            console.error(error);
            setloading(false);
          }
        );
    }
    return unsubscribe;
  }, [sensorId]);

  return { loading, config };
}
