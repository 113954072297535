import firebase from '../../firebase';
import { useEffect, useState } from 'react';

export default function useUnfinishedIssues(orgId: string) {
  const [issues, setIssues] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    let unsubscribe: any = null;
    unsubscribe = firebase
      .firestore()
      .collection(`orgs`)
      .doc(orgId)
      .collection('issues')
      .where('isCompleted', '==', false)
      .orderBy('createTime', 'desc')
      .limit(100)
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setIssues(list);
        }
        setisLoading(false);
      });
    return unsubscribe;
  }, [orgId]);

  return { issues, isLoading };
}
