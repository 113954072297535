import { FOOBOT_INVENTORY_FETCH_REQUESTED, FOOBOT_INVENTORY_FETCH_SUCCEEDED } from './types';
import { InventoryContextForType, InventoryType } from '../types';

export function fetchInventoryFoobot() {
  return {
    type: FOOBOT_INVENTORY_FETCH_REQUESTED,
    payload: {},
  };
}

export function fetchInventoryFoobotSuccess(
  // @ts-ignore
  productsList: firebase.firestore.QuerySnapshot
): {
  type: string;
  payload: InventoryContextForType<InventoryType.FOOBOT>[];
} {
  const foobot = productsList.docs.map(
    // @ts-ignore
    (doc: firebase.firestore.DocumentData) => {
      const data = doc.data();
      return {
        ...data,
        orgId: data?.org?.id,
        orgTitle: data?.org?.title,
        id: doc.id,
        updateTime: data?.updateTime?.toDate(),
      };
    }
  );
  return {
    type: FOOBOT_INVENTORY_FETCH_SUCCEEDED,
    payload: foobot,
  };
}
