import { CalendarMode } from 'antd/lib/calendar/generateCalendar';
import {
  AUDIT_SCHEDULES_FETCH_REQUESTED,
  AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  AUDIT_SCHEDULE_FETCH_REQUESTED,
  AUDIT_SCHEDULE_FETCH_SUCCEEDED,
  AUDIT_SCHEDULE_UPDATE_REQUESTED,
  AUDIT_SCHEDULE_UPDATE_SUCCEEDED,
  AUDIT_SCHEDULE_ADD_REQUESTED,
  AUDIT_SCHEDULE_DELETE_REQUESTED,
  AUDIT_SCHEDULE_TASK_UPDATE_REQUESTED,
  AUDIT_SCHEDULE_TASK_UPDATE_SUCCEEDED,
  AuditSchedule,
  INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  AUDIT_CALENDAR_CHANGE,
  OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
} from './types';

import { DBAuditSchedule, AuditScheduleTask } from './types';

function formatAudits(auditSchedule: any) {
  return auditSchedule.docs.map((task: any) => ({
    ...task.data(),
    id: task.id,
  })) as AuditSchedule[];
}

export function fetchAuditSchedules({
  orgId,
  fromDate,
  toDate,
}: {
  orgId: string;
  fromDate: Date;
  toDate: Date;
}) {
  return {
    type: AUDIT_SCHEDULES_FETCH_REQUESTED,
    payload: { orgId, fromDate, toDate },
  };
}

export function fetchAuditSchedulesSuccess(auditSchedule: any) {
  const auditSchedules = formatAudits(auditSchedule);
  return {
    type: AUDIT_SCHEDULES_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedules,
    },
  };
}
export function fetchInCompleteAuditSchedules({ orgId }: { orgId: string }) {
  return {
    type: INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
    payload: { orgId },
  };
}
export function fetchInCompleteAuditSchedulesSuccess(auditSchedule: any) {
  const auditSchedules = formatAudits(auditSchedule);
  return {
    type: INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedules,
    },
  };
}
export function fetchOnHoldAuditSchedulesSuccess(auditSchedule: any) {
  const auditSchedules = formatAudits(auditSchedule);
  return {
    type: ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedules,
    },
  };
}
export function fetchNeedsResolutionAuditSchedulesSuccess(auditSchedule: any) {
  const auditSchedules = formatAudits(auditSchedule).filter((audit) => !audit.hasOnHoldTasks);
  return {
    type: NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedules,
    },
  };
}

export function fetchOverdueAuditSchedules({ orgId }: { orgId: string }) {
  return {
    type: OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED,
    payload: { orgId },
  };
}

export function fetchOverdueAuditSchedulesSuccess(auditSchedule: any) {
  const auditSchedules = formatAudits(auditSchedule);
  return {
    type: OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedules,
    },
  };
}

export function fetchCompleteAuditSchedules({ orgId, limit }: { orgId: string; limit: number }) {
  return {
    type: COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
    payload: { orgId, limit },
  };
}
export function fetchCompleteAuditSchedulesSuccess(auditSchedule: any) {
  const auditSchedules = formatAudits(auditSchedule);
  return {
    type: COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedules,
    },
  };
}

interface AuditScheduleParams {
  orgId: string;
  auditScheduleId: string;
}

export function fetchAuditSchedule({ orgId, auditScheduleId }: AuditScheduleParams) {
  return {
    type: AUDIT_SCHEDULE_FETCH_REQUESTED,
    payload: { orgId, auditScheduleId },
  };
}

export function fetchAuditScheduleSuccess(
  // @ts-ignore
  auditScheduleDoc: firebase.firestore.QueryDocumentSnapshot
) {
  const auditSchedule = { id: auditScheduleDoc.id, ...auditScheduleDoc.data() };

  return {
    type: AUDIT_SCHEDULE_FETCH_SUCCEEDED,
    payload: {
      data: auditSchedule,
    },
  };
}

export function addAuditSchedule({
  groupId,
  orgId,
  data,
  auditId,
}: {
  groupId?: string; // used if it is a group audit template
  orgId: string;
  data: DBAuditSchedule & { taskTitle?: string }; // QUICK TASK};
  auditId?: string;
}) {
  return {
    type: AUDIT_SCHEDULE_ADD_REQUESTED,
    payload: {
      groupId,
      orgId,
      data,
      auditId,
    },
  };
}

export function deleteAuditSchedule({ orgId, auditScheduleId }: AuditScheduleParams) {
  return {
    type: AUDIT_SCHEDULE_DELETE_REQUESTED,
    payload: {
      orgId,
      auditScheduleId,
    },
  };
}

interface UpdateAuditScheduleTasksParams extends AuditScheduleParams {
  tasks: Partial<AuditScheduleTask>;
}

export function updateAuditScheduleTask({
  orgId,
  auditScheduleId,
  tasks,
}: UpdateAuditScheduleTasksParams) {
  return {
    type: AUDIT_SCHEDULE_TASK_UPDATE_REQUESTED,
    payload: { orgId, auditScheduleId, tasks },
  };
}

export function updateAuditScheduleTaskSuccess() {
  return {
    type: AUDIT_SCHEDULE_TASK_UPDATE_SUCCEEDED,
    payload: {},
  };
}

interface UpdateAuditScheduleParams extends AuditScheduleParams {
  data: Partial<DBAuditSchedule>;
}

export function updateAuditSchedule({ orgId, auditScheduleId, data }: UpdateAuditScheduleParams) {
  return {
    type: AUDIT_SCHEDULE_UPDATE_REQUESTED,
    payload: { orgId, auditScheduleId, data },
  };
}

export function updateAuditScheduleSuccess() {
  return {
    type: AUDIT_SCHEDULE_UPDATE_SUCCEEDED,
    payload: {},
  };
}

export function setAuditCalendar({
  dateStart,
  dateEnd,
  mode,
}: {
  dateStart: Date;
  dateEnd: Date;
  mode: 'dayGridMonth' | 'multiMonthYear';
}) {
  return {
    type: AUDIT_CALENDAR_CHANGE,
    payload: { dateStart, dateEnd, mode },
  };
}
