import React from 'react';
import { Card, List, Space, Typography } from 'antd';
import { useParams } from 'react-router';
import useUnfinishedIssues from '../../modules/issues/useUnfinishedIssues';
import { isThisWeek } from 'date-fns';
import { Colors } from '../../Layout/Colors';
import { CheckCircleTwoTone, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AuditItem from './AuditItem';
import IssueItem from './IssueItem';

export function getColor(num: number) {
  if (num === 0) return Colors.done;
  if (num < 3) return Colors.warning;
  return Colors.danger;
}

export default function IssuesUnresolvedWidget() {
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  const { issues, isLoading } = useUnfinishedIssues(orgId);
  if (isLoading) {
    return (
      <Card
        title={t('dashboard.issues.title')}
        headStyle={{}}
        style={{
          width: '100%',
          height: 370,
          backgroundColor: '#fff',
          color: '#001628',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '264px',
          }}
        >
          <LoadingOutlined style={{ fontSize: 50 }} />
        </div>
      </Card>
    );
  }

  const list = issues.map((audit) => ({
    id: audit.id,
    title: audit.description,
    time: t('general.dateToNow', { date: audit.createTime.toDate() }),
    dueDate: audit.createTime,
    file: audit?.files < 1 ? null : audit.files[0],
    tags: audit.displayTags,
  }));

  return (
    <Card
      title={
        <Space
          style={{
            display: 'flex',
            paddingLeft: 24,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <CloseCircleOutlined style={{ display: 'flex', fontSize: 24, color: Colors.danger }} />
          {t(`dashboard.issuesUnresolved.title`)}
        </Space>
      }
      headStyle={{ padding: 0, color: '#001628' }}
      style={{
        width: '100%',

        backgroundColor: '#fff',
      }}
      bodyStyle={{
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
        height: 310,
        overflowY: 'scroll',
      }}
    >
      <div style={{ color: '#001628' }}>
        <List
          itemLayout="horizontal"
          dataSource={list}
          locale={{
            emptyText: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 48,
                }}
              >
                <CheckCircleTwoTone twoToneColor={Colors.done} style={{ fontSize: 64 }} />
                <Typography.Title level={5}>
                  {t('dashboard.issuesUnresolved.empty')}
                </Typography.Title>
              </div>
            ),
          }}
          renderItem={(item) => {
            const dueDate = item.dueDate?.toDate();
            return (
              <IssueItem
                key={item.id}
                file={item.file}
                orgId={orgId}
                tags={item.tags}
                // taskCount={0}
                color={Colors.danger}
                linkPath={`/orgs/${orgId}/issues/${item.id}`}
                title={item.title}
                description={
                  <>
                    {t('dashboard.issuesUnresolved.deadlineFormat', {
                      dayOfWeek: dueDate,
                      dayOfMonth: dueDate,
                      month: dueDate,
                      time: dueDate,
                      relativeTime: dueDate,
                    })}
                  </>
                }
              />
            );
          }}
        />
      </div>
    </Card>
  );
}
