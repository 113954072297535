import {
  InvoiceState,
  DBInvoiceShape,
  INVOICE_FETCH_REQUESTED,
  INVOICE_FETCH_SUCCEEDED,
  INVOICE_FETCH_FAILED,
} from './types';
import keyBy from 'lodash.keyby';
import {
  INVOICES_FETCH_REQUESTED,
  INVOICES_FETCH_SUCCEEDED,
  INVOICES_FETCH_FAILED,
  InvoiceActionTypes,
} from './types';

const initialState: {
  invoiceIds: string[];
  invoiceMap: { [id: string]: DBInvoiceShape };
  loading: boolean;
} = {
  invoiceIds: [],
  invoiceMap: {},
  loading: true,
};

export default function invoices(state = initialState, action: InvoiceActionTypes): InvoiceState {
  switch (action.type) {
    case INVOICES_FETCH_REQUESTED:
    case INVOICE_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case INVOICES_FETCH_SUCCEEDED:
      return {
        ...state,
        invoiceIds: action.payload.map(({ id }) => id),
        invoiceMap: { ...state.invoiceMap, ...keyBy(action.payload, 'id') },
        loading: false,
      };
    case INVOICE_FETCH_SUCCEEDED:
      return {
        ...state,
        // invoiceIds: Array.from(
        //     new Set([...state.invoiceIds, ...action.payload.map(({ id }) => id)]),
        // ),
        invoiceMap: { ...state.invoiceMap, ...keyBy(action.payload, 'id') },
        loading: false,
      };
    case INVOICES_FETCH_FAILED:
    case INVOICE_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
