import { EditOutlined } from '@ant-design/icons';
import { Button, Select, Tag, Tooltip } from 'antd';
import { useAppSelector } from 'hooks';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleGuide } from 'styles/StyleGuide';

const { Option } = Select;

interface ICategories {
  id: string;
  title: string;
}

export default function IssueTags({
  tags,
  updateIssue,
}: {
  tags: string[];
  updateIssue: (data: any) => void;
}) {
  const { t } = useTranslation();
  const [tmpTags, setTmpTags] = useState<string[] | null>(null);
  const categories: ICategories[] = useAppSelector((state) =>
    (state.categories.list || []).sort((a, b) =>
      a.title.localeCompare(b.title, 'da', { sensitivity: 'base', numeric: true })
    )
  );
  const handleTagChange = (value: any) => {
    setTmpTags(value);
  };

  const isEditing = tmpTags !== null;

  const onSave = () => {
    console.log({ tags, categories, tmpTags });

    if (tmpTags === null) return;
    updateIssue({
      tags: tmpTags,
      displayTags: categories.filter((obj) => tmpTags?.includes(obj.id)).map((obj) => obj.title),
    });
    setTmpTags(null);
  };

  return (
    <>
      {isEditing && (
        <>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder={t('issues.new.categoriesplaceholder')}
            defaultValue={tmpTags}
            onChange={handleTagChange}
          >
            {categories.map((tag: any) => (
              <Option value={tag.id}>{tag.title}</Option>
            ))}
          </Select>
          <div style={{ position: 'absolute', right: 16, top: 8 }}>
            <Button onClick={onSave}>Gem</Button>
          </div>
        </>
      )}
      {!isEditing && (
        <div>
          {tags?.length < 1 && (
            <div style={{ color: StyleGuide.palette.grey600 }}>
              {t('issues.edit.categoriesempty')}
            </div>
          )}
          {categories
            ?.filter((c) => tags?.includes(c.id))
            .map((tag) => (
              <Tag color="blue" key={tag.id}>
                {tag.title}
              </Tag>
            ))}
          <div style={{ position: 'absolute', right: 16, top: 8 }}>
            <Tooltip title="Ret opgave kategorier">
              <Button onClick={() => setTmpTags(tags)}>
                <EditOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
}
