import {
  CATEGORIES_FETCH_REQUESTED,
  CATEGORIES_FETCH_SUCCEEDED,
  CATEGORIES_CREATE_REQUESTED,
  CATEGORIES_CREATE_SUCCEEDED,
  CATEGORIES_DELETE_REQUESTED,
  CATEGORIES_DELETE_SUCCEEDED,
} from './types';

export function fetchCategories({ orgId }: { orgId: string }) {
  return {
    type: CATEGORIES_FETCH_REQUESTED,
    payload: {
      orgId,
    },
  };
}
// @ts-ignore
export function fetchCategoriesSuccess(categoriesList: firebase.firestore.QuerySnapshot) {
  const categories = categoriesList.docs.map((category: any) => ({
    id: category.id,
    ...category.data(),
  }));
  return {
    type: CATEGORIES_FETCH_SUCCEEDED,
    payload: {
      categories,
    },
  };
}

export function addCategories(orgId: string, title: string) {
  return {
    type: CATEGORIES_CREATE_REQUESTED,
    payload: {
      orgId,
      title,
    },
  };
}

export function addCategoriesSuccess() {
  return {
    type: CATEGORIES_CREATE_SUCCEEDED,
    payload: {},
  };
}

export function deleteCategory(orgId: string, categoryId: string) {
  return {
    type: CATEGORIES_DELETE_REQUESTED,
    payload: {
      orgId,
      categoryId,
    },
  };
}

export function deleteCategorySuccess() {
  return {
    type: CATEGORIES_DELETE_SUCCEEDED,
    payload: {},
  };
}
