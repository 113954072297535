import { call, put, fork, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../firebase';
import { fetchCleanLogsSuccess } from './actions';
import { CleaningLog } from './types';
import {
  CLEANLOG_CREATE_REQUESTED,
  CLEANLOG_CREATE_FAILED,
  CLEANLOG_CREATE_SUCCEEDED,
  CLEANLOGS_FETCH_REQUESTED,
  CLEANLOGS_FETCH_FAILED,
  CLEANLOG_UPDATE_REQUESTED,
  CLEANLOG_UPDATE_FAILED,
  CLEANLOG_UPDATE_SUCCEEDED,
} from './types';

function* fetchCleanLogs({ payload }: { payload: { orgId: string } }) {
  const { orgId } = payload;
  try {
    yield fork(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`orgs/${orgId}/cleanLogs`).orderBy('createTime', 'desc'),
      {
        successActionCreator: fetchCleanLogsSuccess,
      }
    );
  } catch (e: any) {
    yield put({ type: CLEANLOGS_FETCH_FAILED, message: e.message });
  }
}

export function* fetchCleanLogsSaga() {
  // @ts-ignore
  yield takeLatest(CLEANLOGS_FETCH_REQUESTED, fetchCleanLogs);
}

function* addCleanLog({
  payload,
}: {
  payload: {
    orgId: string;
    initials: string;
    amount: number;
    productId: string;
    optionId?: string;
  };
}) {
  const { orgId, initials, amount, productId, optionId } = payload;
  try {
    yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/cleanLogs`, {
      initials,
      amount,
      product: {
        id: productId,
      },
      optionId: optionId || null,
      createTime: new Date(),
    });
    yield put({ type: CLEANLOG_CREATE_SUCCEEDED });
  } catch (e: any) {
    yield put({ type: CLEANLOG_CREATE_FAILED, message: e.message });
  }
}

export function* addCleanLogSaga() {
  // @ts-ignore
  yield takeLatest(CLEANLOG_CREATE_REQUESTED, addCleanLog);
}

function* updateCleanLog({
  payload,
}: {
  payload: { orgId: string; logId: string; data: CleaningLog };
}) {
  const { orgId, logId, data } = payload;
  try {
    // @ts-ignore
    const orgs = yield call(
      reduxSagaFirebase.firestore.updateDocument,
      `orgs/${orgId}/cleanLogs/${logId}`,
      {
        ...data,
      }
    );
    yield put({ type: CLEANLOG_UPDATE_SUCCEEDED });
  } catch (e: any) {
    yield put({ type: CLEANLOG_UPDATE_FAILED, message: e.message });
  }
}

export function* updateCleanLogSaga() {
  // @ts-ignore
  yield takeLatest(CLEANLOG_UPDATE_REQUESTED, updateCleanLog);
}
