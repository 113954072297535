import React, { useState } from 'react';
import { useOfferDrawerContext } from '../ContextDrawer/OfferContext';
import { useAppSelector } from 'hooks';
import useInvoicesByOrg, { Invoice } from './useInvoices';
import { Button, Card, Timeline, Tooltip } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { StyleGuide } from 'styles/StyleGuide';
import { priceFormat } from '../Offers/OffersTable';

const MIN_DISPLAY_LIMIT = 3;

export default function Invoices({
  orgId,
  hallerupCustomerId,
}: {
  orgId: string | null;
  hallerupCustomerId?: string;
}) {
  // const drawerCxt = useOfferDrawerContext();

  const { loadingInvoices, invoices } = useInvoicesByOrg(hallerupCustomerId);
  const [displayLimit, setDisplayLimit] = useState(MIN_DISPLAY_LIMIT);

  if (!orgId) {
    return (
      <div>Tilbudet er ikke forbundet til en afdeling endnu (sker når tilbuddet accepteres)</div>
    );
  }
  if (!hallerupCustomerId) {
    return (
      <div>
        Tilbudet er ikke forbundet til regnskabssystemet endnu (Niels sætter det op ved ny kunde)
      </div>
    );
  }

  if (loadingInvoices) {
    return <div>Loading...</div>;
  }
  if (invoices.length < 1) {
    return <div>Der er ingen fakturaer</div>;
  }

  const totalNetAmount = invoices.reduce((acc, i) => acc + i.netAmount, 0);
  const isCollapsed = displayLimit === MIN_DISPLAY_LIMIT;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h4>Fakturaer</h4>
        <div>
          <span style={{ color: StyleGuide.palette.grey700 }}>Total netto beløb:</span>{' '}
          {priceFormat.format(totalNetAmount)}
        </div>
      </div>
      <Timeline
        items={invoices.slice(0, displayLimit).map((i) => {
          if (i.paidInFull) {
            return {
              dot: (
                <Tooltip title="Betalt">
                  {i.paidInFull ? (
                    <CheckCircleOutlined />
                  ) : (
                    <ClockCircleOutlined className={'timeline-clock-icon'} />
                  )}
                </Tooltip>
              ),
              color: i.paidInFull ? 'green' : 'red',
              children: <InvoiceItem invoice={i} />,
            };
          }
          return {
            dot: (
              <Tooltip title="Ikke betalt endnu">
                <ClockCircleOutlined className={'timeline-clock-icon'} />
              </Tooltip>
            ),
            color: 'red',
            children: <InvoiceItem invoice={i} />,
          };
        })}
      />
      {invoices.length > MIN_DISPLAY_LIMIT && (
        <Button
          block
          icon={isCollapsed ? <CaretDownOutlined /> : <CaretUpOutlined />}
          onClick={() => setDisplayLimit(isCollapsed ? 100 : MIN_DISPLAY_LIMIT)}
        >
          {isCollapsed ? 'Se flere' : 'Se færre'}
        </Button>
      )}
    </div>
  );
}

function InvoiceItem({ invoice }: { invoice: Invoice }) {
  const i = invoice;
  return (
    <Card size="small">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex', gap: 8 }}>
          <div>
            <span
              style={{
                textAlign: 'right',
                color: StyleGuide.palette.grey700,
                fontSize: 12,
              }}
            >
              Kunde kontakt
            </span>
            <div>{i.customerRef}</div>
          </div>
          <div>
            <span
              style={{
                textAlign: 'right',
                color: StyleGuide.palette.grey700,
                fontSize: 12,
              }}
            >
              aks2tal kontakt
            </span>
            <div>{i.ownRef}</div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 8,
          }}
        >
          <div>
            <span
              style={{
                textAlign: 'right',
                color: StyleGuide.palette.grey700,
                fontSize: 12,
              }}
            >
              Netto beløb
            </span>
            <div style={{ fontSize: 14 }}>{priceFormat.format(i.netAmount)}</div>
          </div>
          <div>
            <span
              style={{
                textAlign: 'right',
                color: StyleGuide.palette.grey700,
                fontSize: 12,
              }}
            >
              Fakturadato
            </span>
            <div style={{ fontSize: 14 }}>{format(i.invoiceDate, 'dd/MM/yyyy')}</div>
          </div>
          <div>
            <span
              style={{
                textAlign: 'right',
                color: StyleGuide.palette.grey700,
                fontSize: 12,
              }}
            >
              Betalingsdato
            </span>
            <div>{format(i.paymentDate, 'dd/MM/yyyy')}</div>
          </div>
        </div>
      </div>
    </Card>
  );
}
