import { InventoryItem, InventoryType } from '../types';

export const ESP_AQ_INVENTORY_FETCH_REQUESTED = 'ESP_AQ_INVENTORY_FETCH_REQUESTED';
export const ESP_AQ_INVENTORY_FETCH_SUCCEEDED = 'ESP_AQ_INVENTORY_FETCH_SUCCEEDED';
export const ESP_AQ_INVENTORY_FETCH_FAILED = 'ESP_AQ_INVENTORY_FETCH_FAILED';

export interface FetchEspAqInventorySuccess {
  type: typeof ESP_AQ_INVENTORY_FETCH_SUCCEEDED;
  payload: EspAqItem[];
}
export interface FetchEspAqInventory {
  type: typeof ESP_AQ_INVENTORY_FETCH_REQUESTED;
  payload: {};
}
export interface FetchEspAqInventoryFailed {
  type: typeof ESP_AQ_INVENTORY_FETCH_FAILED;
  payload: {};
}

export interface EspAqItem extends InventoryItem {
  deviceId: string;
  type: InventoryType.ESP_AQ;
  orgId?: string;
  orgTitle?: string;
  sim: string | null;
}
