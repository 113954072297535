import Message, { Type } from 'components/Notification/Message';
import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../firebase';
import { fetchCompletedIssuesSuccess, fetchIssuesSuccess, fetchIssueSuccess } from './actions';
import {
  ISSUES_FETCH_FAILED,
  ISSUES_FETCH_REQUESTED,
  ISSUE_FETCH_FAILED,
  ISSUE_FETCH_REQUESTED,
  ISSUE_UPDATE_FAILED,
  ISSUE_UPDATE_REQUESTED,
  ISSUES_COMPLETED_FETCH_FAILED,
  ISSUES_COMPLETED_FETCH_REQUESTED,
  ISSUE_DELETE_REQUESTED,
} from './types';
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchIssues({ payload }: { payload: { orgId: string } }) {
  const { orgId } = payload;
  try {
    yield call(
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`orgs/${orgId}/issues`).orderBy('createTime', 'desc'),
      { successActionCreator: fetchIssuesSuccess }
    );
  } catch (e: any) {
    yield put({ type: ISSUES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchIssuesSaga() {
  // @ts-ignore
  yield takeLatest(ISSUES_FETCH_REQUESTED, fetchIssues);
}
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchCompletedIssues({
  payload,
}: {
  payload: { orgId: string; fromDate: Date; toDate: Date };
}) {
  const { orgId, fromDate, toDate } = payload;
  try {
    yield call(
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/issues`)
        .where('isCompleted', '==', true)
        .where('completeTime', '>=', fromDate)
        .where('completeTime', '<=', toDate)
        .orderBy('completeTime', 'desc'),
      { successActionCreator: fetchCompletedIssuesSuccess }
    );
  } catch (e: any) {
    yield put({ type: ISSUES_COMPLETED_FETCH_FAILED, message: e.message });
  }
}

export function* fetchCompletedIssuesSaga() {
  // @ts-ignore
  yield takeLatest(ISSUES_COMPLETED_FETCH_REQUESTED, fetchCompletedIssues);
}

// FETCH ISSUE
function* fetchIssue({ payload }: { payload: { orgId: string; issueId: string } }) {
  const { orgId, issueId } = payload;
  try {
    yield call(reduxSagaFirebase.firestore.syncDocument, `orgs/${orgId}/issues/${issueId}`, {
      successActionCreator: fetchIssueSuccess,
    });
  } catch (e: any) {
    yield put({ type: ISSUE_FETCH_FAILED, message: e.message });
  }
}

export function* fetchIssueSaga() {
  // @ts-ignore
  yield takeLatest(ISSUE_FETCH_REQUESTED, fetchIssue);
}
function* updateIssue({ payload }: { payload: { orgId: string; issueId: string; data: any } }) {
  const { orgId, issueId, data } = payload;
  try {
    Message({
      key: 'updateIssue',
      message: 'Loading...',
      // description: "Sensoren blev tilføjet korrekt",
      type: Type.LOADING,
    });
    yield call(reduxSagaFirebase.firestore.setDocument, `orgs/${orgId}/issues/${issueId}`, data, {
      merge: true,
    });
    if (data.isCompleted) {
      Message({
        key: 'updateIssue',
        message: 'Fejl rettet',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.SUCCESS,
      });
    } else {
      Message({
        key: 'updateIssue',
        message: 'Fejl opdateret',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.SUCCESS,
      });
    }
  } catch (e: any) {
    yield put({ type: ISSUE_UPDATE_FAILED, message: e.message });
  }
}

export function* updateIssueSaga() {
  // @ts-ignore
  yield takeLatest(ISSUE_UPDATE_REQUESTED, updateIssue);
}

// TODO: Add issues functions
// function* addOrg(action) {
//   try {
//     console.log('addOrg', action.payload)
//     console.log('reduxSagaFirebase', reduxSagaFirebase)
//     const orgs = yield call(reduxSagaFirebase.firestore.addDocument, 'orgs', {
//       title: action.payload,
//       isDeleted: false,
//       createTime: new Date(),
//     });
//     console.log('orgs', orgs);
//     yield put({ type: ISSUE_CREATE_SUCCEEDED });
//   } catch (e) {
//     yield put({ type: ISSUE_CREATE_FAILED, message: e.message });
//   }
// }

// export function* addOrgSaga() {
//   yield takeLatest(ISSUE_CREATE_REQUESTED, addOrg);
// }
