import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import {
  format as formatDate,
  formatRelative,
  formatDistance,
  isDate,
  formatDistanceToNow,
  formatDistanceToNowStrict,
} from 'date-fns';
import daLocales from './da.json';
import enLocales from './en.json';
import deLocales from './de.json';
import svLocales from './se.json';

import { enUS, da, de, sv } from 'date-fns/locale';

// ----------------------------------------------------------------------
export const locales = { enUS, da, de, sv };

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      da: { translations: daLocales },
      en: { translations: enLocales },
      de: { translations: deLocales },
      sv: { translations: svLocales },
    },
    lng: localStorage.getItem('i18nextLng') || 'da',
    fallbackLng: 'da',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (isDate(value)) {
          // @ts-ignore
          const locale = locales[lng];

          if (format === 'dateTime') return formatDate(value, 'dd MMM yyyy p', { locale });
          if (format === 'dateHHmm') return formatDate(value, 'do MMM HH:mm', { locale });
          if (format === 'dateTimeYYYY')
            return formatDate(value, 'do MMM yyyy - HH:mm', { locale });
          if (format === 'time') {
            return formatDate(value, 'HH:mm', { locale });
          }
          if (format === 'long') return formatDate(value, 'PPPP', { locale });
          if (format === 'relative') return formatRelative(value, new Date(), { locale });
          if (format === 'relativeTime') {
            return formatDistanceToNowStrict(value, {
              locale,
              addSuffix: true,
            });
          }
          if (format === 'fromNow')
            return formatDistance(value, new Date(), {
              locale,
              addSuffix: true,
            });
          if (format === 'dateToNow')
            return formatDistanceToNowStrict(value, {
              addSuffix: true,
              locale,
            });
          if (format === 'getDate') {
            if (!value) return '';
            return formatDate(value, 'dd-MM-yyyy', { locale });
          }
          if (format === 'getDayOfWeek') {
            if (!value) return '';
            return formatDate(value, 'EEEE', { locale });
          }
          if (format === 'dayOfMonth') {
            if (!value) return '';
            return formatDate(value, 'do', { locale });
          }
          if (format === 'getMonth') {
            if (!value) return '';
            return formatDate(value, 'MMMM', { locale });
          }
          if (format === 'getYear') {
            if (!value) return '';
            return formatDate(value, 'yyyy', { locale });
          }
          if (format === 'getDayOfWeekDate') {
            if (!value) return '';
            return formatDate(value, 'EEEE, do MMM', { locale });
          }
          // @ts-ignore
          return formatDate(value, format, { locale });
        }

        return value;
      },
    },
  });

export default i18n;
