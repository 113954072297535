import React, { useState } from 'react';
import { AuditScheduleTask } from '../../../modules/auditSchedule/types';
import { List, Button, Input, Card, Space, Tooltip, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../hooks';
import { useParams } from 'react-router';
import { updateAuditTemplateTask } from '../../../modules/auditTemplates/actions';
import Options from './Options';
import { updateGroupAuditTemplateTask } from 'modules/groupAuditTemplates/actions';

interface InputTitleProps {
  value: string;
  onSave: (value: string) => void;
  onCancel: () => void;
}
function InputTitle(props: InputTitleProps) {
  const [value, setValue] = useState(props.value);
  const { t } = useTranslation();
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.onSave(value);
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Input
        autoFocus
        defaultValue={props.value}
        onChange={(e) => setValue(e.target.value)}
        style={{ marginRight: 8, marginLeft: -7, paddingLeft: 6 }}
      />

      <Button type="primary" htmlType="submit">
        {t('audit.templates.edit.save')}
      </Button>
      <div style={{ marginRight: 8 }} />
      <Button onClick={props.onCancel}>{t('audit.templates.edit.cancel')}</Button>
    </form>
  );
}
interface State {
  visible: boolean;
  title: string;
  templateTitle: string;
  isEditing: false | string;
  isEditingTitle: boolean;
}
export default function AuditTaskItem(props: Pick<AuditScheduleTask, 'id' | 'description'>) {
  const { groupId } = useParams<{ groupId: string }>();
  const { auditTemplateId } = useParams<{ auditTemplateId: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState<State>({
    visible: false,
    title: '',
    templateTitle: '',
    isEditing: false,
    isEditingTitle: false,
  });

  const dispatchUpdateAuditTemplateTask = (taskId: string, title: string) => {
    dispatch(updateGroupAuditTemplateTask({ groupId, auditTemplateId, taskId, title }));
  };

  // const itemActions = state.isEditing
  //     ? []
  //     : [
  //           <Button
  //               type="text"
  //               size="small"
  //               icon={<EditOutlined />}
  //               onClick={() =>
  //                   setState({
  //                       ...state,
  //                       isEditing: props.id,
  //                   })
  //               }>
  //               {t("audit.templates.edit.edit")}
  //           </Button>,
  //           <DeleteTemplateTaskButton taskId={props.id} />,
  //       ];

  const handleSave = (value: string) => {
    dispatchUpdateAuditTemplateTask(props.id, value);
    setState({
      ...state,
      isEditing: false,
    });
  };
  const handleCancel = () =>
    setState({
      ...state,
      isEditing: false,
    });

  return (
    <Card
      style={{
        minHeight: '40px',
        backgroundColor: '#fff',
        padding: 0,
      }}
      bodyStyle={{
        padding: 8,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          textAlign: 'left',
          alignItems: 'center',
        }}
      >
        {/* <BarsOutlined /> */}
        <div style={{ marginRight: 6 }} />
        {state.isEditing === props.id ? (
          <InputTitle value={props.description} onSave={handleSave} onCancel={handleCancel} />
        ) : (
          <span
            style={{ cursor: 'text', width: '100%' }}
            onClick={() =>
              setState({
                ...state,
                isEditing: props.id,
              })
            }
          >
            {props.description}
          </span>
        )}
      </div>
      <div>
        {
          state.isEditing !== props.id && (
            <Space>
              <Options groupId={groupId} templateId={auditTemplateId} taskId={props.id} />
            </Space>
          )

          // itemActions
        }
      </div>
    </Card>
  );
}
