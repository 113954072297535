import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import { Intl } from '../../general/enums';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import Icon from '@ant-design/icons';
import GbFlag from '../../assets/flags/gb';
import DaFlag from '../../assets/flags/da';
import SeFlag from '../../assets/flags/se';
import DeFlag from '../../assets/flags/de';

const { Option } = Select;

function Language() {
  const { t } = useTranslation();
  const intl = useSelector((state: AppState) => state.user.userDoc.intl);
  const userId = useSelector((state: AppState) => state.user.details.uid);
  console.log({ intl });

  async function handleChange(value: string) {
    if (userId) {
      await firebase.firestore().collection('users').doc(userId).set(
        {
          intl: value,
        },
        { merge: true }
      );
    }
  }
  return (
    <>
      <h3>{t('profile.language')}</h3>
      <div style={{ padding: 8 }} />
      <LanguagePicker value={intl} handleChange={handleChange} />
    </>
  );
}
export default Language;

export function LanguagePicker({
  value,
  handleChange,
}: {
  value: Intl;
  handleChange: (value: Intl) => Promise<void>;
}) {
  const { t } = useTranslation();
  return (
    <Select defaultValue={value} style={{ width: 120 }} onChange={handleChange}>
      <Option value={Intl.da_DK}>
        <Icon component={DaFlag} /> {t('profile.danish')}
      </Option>
      <Option value={Intl.en_US}>
        <Icon component={GbFlag} /> {t('profile.english')}
      </Option>
      <Option value={Intl.se_SE}>
        <Icon component={SeFlag} /> {t('profile.swedish')}
      </Option>
      <Option value={Intl.de_DE}>
        <Icon component={DeFlag} /> {t('profile.german')}
      </Option>
    </Select>
  );
}
