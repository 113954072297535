'use client';

import { useState } from 'react';
import type { InventoryItem } from '../types';
import EditItemDialog from './edit-item-dialog';
import ItemDetailsDialog from './item-details-dialog';
import CheckOutDialog from './check-out-dialog';
import CheckInDialog from './check-in-dialog';
import ScheduleMaintenanceDialog from './schedule-maintenance-dialog';
import QRCodeModal from './qr-code-modal';
import { DataTable } from 'components/ui/data-table';
import { getInventoryColumns } from './inventory-table-columns';

interface InventoryListProps {
  items: InventoryItem[];
  onDelete: (id: string) => void;
  onUpdate: (item: InventoryItem) => void;
}

export default function InventoryList({ items, onDelete, onUpdate }: InventoryListProps) {
  const [editingItem, setEditingItem] = useState<InventoryItem | null>(null);
  const [viewingItem, setViewingItem] = useState<InventoryItem | null>(null);
  const [checkingOutItem, setCheckingOutItem] = useState<InventoryItem | null>(null);
  const [checkingInItem, setCheckingInItem] = useState<InventoryItem | null>(null);
  const [schedulingMaintenance, setSchedulingMaintenance] = useState<InventoryItem | null>(null);
  const [qrCodeItem, setQrCodeItem] = useState<string | null>(null);

  // @ts-ignore
  const locations = [...new Set(items.map((item) => item.location).filter(Boolean))];

  const columns = getInventoryColumns({
    onView: (item) => setViewingItem(item),
    onEdit: (item) => setEditingItem(item),
    onDelete: (id) => onDelete(id),
    onCheckOut: (item) => setCheckingOutItem(item),
    onCheckIn: (item) => setCheckingInItem(item),
    onScheduleMaintenance: (item) => setSchedulingMaintenance(item),
    onQrCodeClick: (id) => setQrCodeItem(id),
  });

  return (
    <>
      {items.length === 0 ? (
        <div className="text-center py-10">
          <p className="text-muted-foreground">
            No items found. Add some equipment to your catalog.
          </p>
        </div>
      ) : (
        <DataTable
          columns={columns}
          data={items}
          searchColumn="name"
          searchPlaceholder="Søg efter navn..."
        />
      )}

      {editingItem && (
        <EditItemDialog
          item={editingItem}
          open={!!editingItem}
          onOpenChange={(open) => !open && setEditingItem(null)}
          onUpdate={(updatedItem) => {
            onUpdate(updatedItem);
            setEditingItem(null);
          }}
          // @ts-ignore
          existingCategories={[...new Set(items.map((item) => item.category))]}
          // @ts-ignore
          existingLocations={locations}
        />
      )}

      {viewingItem && (
        <ItemDetailsDialog
          item={viewingItem}
          open={!!viewingItem}
          onOpenChange={(open) => !open && setViewingItem(null)}
        />
      )}

      {checkingOutItem && (
        <CheckOutDialog
          item={checkingOutItem}
          open={!!checkingOutItem}
          onOpenChange={(open) => !open && setCheckingOutItem(null)}
          onCheckOut={(updatedItem) => {
            onUpdate(updatedItem);
            setCheckingOutItem(null);
          }}
        />
      )}

      {checkingInItem && (
        <CheckInDialog
          item={checkingInItem}
          open={!!checkingInItem}
          onOpenChange={(open) => !open && setCheckingInItem(null)}
          onCheckIn={(updatedItem) => {
            onUpdate(updatedItem);
            setCheckingInItem(null);
          }}
        />
      )}

      {/* {schedulingMaintenance && (
        <ScheduleMaintenanceDialog
          item={schedulingMaintenance}
          open={!!schedulingMaintenance}
          onOpenChange={(open) => !open && setSchedulingMaintenance(null)}
          onScheduleMaintenance={(updatedItem) => {
            onUpdate(updatedItem);
            setSchedulingMaintenance(null);
          }}
        />
      )} */}

      {qrCodeItem && (
        <QRCodeModal
          id={qrCodeItem}
          open={!!qrCodeItem}
          onOpenChange={(open) => !open && setQrCodeItem(null)}
        />
      )}
    </>
  );
}
