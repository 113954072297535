'use client';

import { useState } from 'react';
import { ScrollArea } from 'components/ui/scroll-area';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { useAudit } from './audit-context';
import { LocationSelection } from './location-selection';
import { Label } from 'components/ui/label';
import { TaskSelection } from './task-selection';
import { useHistory } from 'react-router';
import PreviousAuditCard from './PreviousAuditCard';
import { RequestState } from 'interfaces/request';
import api from './api';
import { toast } from 'sonner';
import { AuditTypeSelection } from './audit-type-selection';

export default function SplitScreenModal() {
  const history = useHistory();
  const [request, setRequest] = useState(RequestState.INITIAL);
  const {
    step,
    locations,
    masterTaskList,
    selectedAuditDate,
    selectedLocation,
    selectedTasks,
    setStep,
  } = useAudit();
  const open = step === 'location';

  const isDisabled = !selectedLocation || selectedTasks.length < 1;

  const setOpen = (isOpen: boolean) => {
    if (!isOpen) {
      setStep('initial');
    }
  };

  const createInternalAudit = async () => {
    setRequest(RequestState.PENDING);
    try {
      // if (!selectedAuditDate) {
      //   toast.error('Vælg en dato');
      //   return;
      // }
      if (!selectedLocation) {
        toast.error('Vælg et site');
        return;
      }
      if (selectedTasks.length < 1) {
        toast.error('Vælg mindst én opgave');
        return;
      }
      const { id } = await api.createInternalAudit({
        // auditDate: selectedAuditDate,
        locationId: selectedLocation,
        taskIds: selectedTasks,
      });
      setRequest(RequestState.RESOLVED);
      history.push(`/internal-audit/${id}`);
      toast.success('Auditering oprettet');
    } catch (error) {
      console.error(error);
      toast.error('Der skete en fejl. Prøv igen senere.');
      setRequest(RequestState.REJECTED);
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center">
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-[900px] p-0 overflow-hidden">
          <div className="flex flex-col sm:flex-row h-[80vh] sm:h-[600px]">
            {/* Left side - fixed content */}
            <div className="w-full sm:w-[300px] bg-muted p-6 flex flex-col">
              <DialogHeader>
                <DialogTitle>Vælg site og opgaver</DialogTitle>
                <DialogDescription>Vælg et site og de opgaver der skal auditeres</DialogDescription>
              </DialogHeader>
              <div className="mt-6 flex-1 flex flex-col gap-4">
                {/* <Label>Vælg audit dato</Label>
                <AuditDatePicker /> */}
                <Label>Vælg type</Label>
                <AuditTypeSelection />
                <Label>Vælg site</Label>
                <LocationSelection />
                <PreviousAuditCard orgId={selectedLocation} />
                <div className="flex flex-grow" />
                {/* <div className="rounded-md bg-primary/10 p-4"> */}
                {/* <h3 className="font-medium">Actions</h3> */}
                <div className="mt-4 space-y-2">
                  <Button
                    size="sm"
                    className="w-full"
                    disabled={isDisabled}
                    loading={request === RequestState.PENDING}
                    onClick={createInternalAudit}
                  >
                    Opret auditering
                  </Button>
                  {/* <Button size="sm" variant="outline" className="w-full">
                      Secondary Action
                    </Button> */}
                </div>
                {/* </div> */}
              </div>
            </div>

            {/* Right side - scrollable content */}
            <ScrollArea className="flex-1 border-l">
              <div className="p-6">
                <h2 className="text-xl font-semibold">Vælg opgaver</h2>
                <div className="text-sm text-muted-foreground mb-4">
                  Du kan kan altid springe opgaver over og markere dem som "ikke auditeret"
                </div>
                <TaskSelection orgId={selectedLocation} />
              </div>
            </ScrollArea>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
