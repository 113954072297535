import { Space, Table, Tooltip } from 'antd';
import styled from 'styled-components';
import { StyleGuide } from 'styles/StyleGuide';

import useAuditTemplateCategories from '../../modules/auditTemplateCategories/useAuditTemplateCategories';
import { t } from 'i18next';
import SummaryChart, { AvoidSvgOverflow } from './SummaryChart';
import { IOrg } from 'modules/orgs/interfaces';
import { IrrelevantGroupCategoryMap } from 'hooks/useIrrelevantGroupCategoryMap';

function getStatusClass(value: any) {
  if (value.hasOnHoldTasks && value.isCompleted) {
    return 'onhold';
  }
  if (value.needsResolution && value.isCompleted) {
    return 'needsresolution';
  }
  if (value.isCompleted) {
    return 'completed';
  }
  if (new Date(value.dueDate) < new Date()) {
    return 'incomplete';
  }
  if (new Date(value.dueDate) > new Date()) {
    return 'pending';
  }
  return 'disabled';
}

const noneBgStyle = `repeating-linear-gradient(-45deg, ${StyleGuide.palette.grey300}, ${StyleGuide.palette.grey300} 1px, #ffffff 2px, #ffffff 20px)`;

function renderStatus(
  items: any[],
  key: string,
  irrelevantGroupCategoryMap: IrrelevantGroupCategoryMap,
  subGroupId: string = ''
) {
  const hasNone = items?.length < 1 && irrelevantGroupCategoryMap[key]?.includes(subGroupId);

  if (hasNone) {
    return (
      <div
        style={{
          height: 100,
          background: noneBgStyle,
        }}
      />
    );
  }
  return (
    <div
      style={{
        wordWrap: 'break-word',
        wordBreak: 'break-word',
        height: '100%',
        minWidth: 200,
        // gridTemplateRows: "repeat(auto-fit, minmax(100px, 1fr))",
      }}
    >
      {items
        ?.sort((a, b) => {
          return Number(a.dueDate) - Number(b.dueDate);
        })
        .map((value) => {
          const dueDate = new Date(value.dueDate);
          const completeTime = value.completeTime ? new Date(value.completeTime) : null;
          const dateString = t('date.exactFormatDateYear', {
            dayOfWeek: dueDate,
            dayOfMonth: dueDate,
            month: dueDate,
            year: dueDate,
          });
          const dateStringComplete = completeTime
            ? t('date.exactFormatDateYear', {
                dayOfWeek: completeTime,
                dayOfMonth: completeTime,
                month: completeTime,
                year: completeTime,
              })
            : '';
          return (
            <Tooltip
              title={
                <div>
                  <div>{value.templateTitle}</div>
                  <div>Deadline: {dateString}</div>
                  {value?.isCompleted && dateStringComplete && (
                    <div>Afsluttet: {dateStringComplete}</div>
                  )}
                </div>
              }
            >
              <div
                onClick={() =>
                  window.open(`/orgs/${value.orgId}/audit/audits/${value.auditId}`, '_blank')
                }
                style={{
                  display: 'flex',
                  height: `calc(100px / ${items.length})`,
                  width: '100%',
                  borderBottom: `1px solid ${StyleGuide.palette.grey300}`,
                }}
                className={getStatusClass(value)}
              >
                {/* {value.templateTitle} - {value.auditTemplateId} */}
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
}

function getStats(data: any) {
  return data.reduce(
    (acc: any, item: any) => {
      if (item.hasOnHoldTasks && item.isCompleted) {
        return {
          ...acc,
          onHold: acc.onHold + 1,
        };
      }
      if (item.needsResolution && item.isCompleted) {
        return {
          ...acc,
          needsResolution: acc.needsResolution + 1,
        };
      }
      if (item.isCompleted) {
        return {
          ...acc,
          completed: acc.completed + 1,
        };
      }
      if (new Date(item.dueDate) < new Date()) {
        return {
          ...acc,
          overdue: acc.overdue + 1,
        };
      }
      if (new Date(item.dueDate) > new Date()) {
        return {
          ...acc,
          awating: acc.awating + 1,
        };
      }
      return acc;
    },
    { completed: 0, overdue: 0, awating: 0, onHold: 0, needsResolution: 0 }
  );
}

export default function AuditTable({
  groupIds,
  audits = [],
  subGroups = [],
  irrelevantGroupCategoryMap,
  selectedCategories,
}: {
  groupIds: string[];
  audits: any[];
  subGroups: IOrg['subGroup'][];
  irrelevantGroupCategoryMap: IrrelevantGroupCategoryMap;
  selectedCategories: string[];
}) {
  const { loading, auditTemplateCategories } = useAuditTemplateCategories({
    groupIds,
    orgId: '_',
  });
  const categoryMap = auditTemplateCategories?.reduce((acc, category) => {
    // @ts-ignore
    acc[category.id] = category.name;
    return acc;
  }, {}) as any;
  const dataSource = transformData(
    audits,
    categoryMap,
    subGroups.map((s) => s?.title) as string[],
    selectedCategories
  ).sort((a, b) => a.name?.localeCompare(b.name));

  // const dataSource = [
  //     {
  //         key: "1",
  //         name: "Eftersyn af stiger",
  //         "RGS Vemmelev": true,
  //         "RGS Gadstrup": null,
  //         "RGS Herlufmagle": false,
  //         "RGS Kbh Selinevej port 3": false,
  //         "RGS Kbh Selinevej port 4,6,7": false,
  //         "Kbh Selinevej port 5": false,
  //         "Kbh Rødby": false,
  //         "RGS Kbh Nordsøvej": false,
  //         "RGS Kalundborg": false,
  //         "RGS Sanderødgård": false,
  //         "RGS Stigsnæs": false,
  //         "RGS Vindmøllevej": false,
  //     },
  //     {
  //         key: "2",
  //         name: "John",
  //         age: 42,
  //         address: "10 Downing Street",
  //     },
  // ];

  if (dataSource?.length < 1) return null;

  const columns = [
    {
      title: 'Opgave',
      dataIndex: 'name',
      key: 'name',
      fixed: true,
      width: 200,
      render: (text: string, _: any) => (
        // <Tooltip title={r.key}>
        <div style={{ padding: '8px 16px' }}>{text || 'Ikke kategoriseret'}</div>
        // </Tooltip>
      ),
    },
    ...Object.keys(dataSource[0])
      .filter((key) => key !== 'key' && key !== 'name')
      .sort((a, b) => a.localeCompare(b))
      .map((key) => ({
        title: () => key,
        dataIndex: key,
        key,
        // width: 400,
        render: (items: any, r: any) => {
          const subGroupId = subGroups.find((s) => s?.title === key)?.id;
          return renderStatus(items, r.key, irrelevantGroupCategoryMap, subGroupId);
        },
      })),
  ];

  const stats = getStats(audits);

  return (
    <TableWrapper>
      <div
        style={{
          marginBottom: 40,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <Space>
          <Legend color={StyleGuide.palette.statusErrorDark} label="Ikke afsluttet" />
          <Legend color={StyleGuide.palette.statusWarningDark} label="På hold" />
          <Legend color={StyleGuide.palette.statusHardWarningDark} label="Kræver handling" />
          <Legend color={StyleGuide.palette.statusProcessingLight} label="Afventer udførsel" />
          <Legend color={StyleGuide.palette.statusSuccessDark} label="Afsluttet" />
          <Legend
            color={StyleGuide.palette.grey0}
            label="Ingen opgave"
            border={`1px solid ${StyleGuide.palette.grey300}`}
          />
          <Legend
            color={noneBgStyle}
            label="Ikke relevant"
            border={`1px solid ${StyleGuide.palette.grey300}`}
          />
        </Space>
        <AvoidSvgOverflow
          style={{
            position: 'absolute',
            width: 200,
            height: 50,
            right: 24,
            bottom: -24,
          }}
        >
          <SummaryChart stats={stats} />
        </AvoidSvgOverflow>
      </div>
      <Table
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content', y: 480 }}
      />
    </TableWrapper>
  );
}

function Legend({ color, label, border }: { color: string; label: string; border?: string }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <div
        style={{
          border,
          borderRadius: 8,
          padding: '8px 16px',
          background: color,
        }}
      >
        <span>{label}</span>
      </div>
    </div>
  );
}

const TableWrapper = styled.div`
  .ant-table-row .ant-table-cell {
    padding: 0px !important;
  }
  // .ant-table-cell:has(.completed) {
  //     padding: 0px !important;
  //     background-color: ${StyleGuide.palette.successLight} !important;
  // }
  // .ant-table-cell:has(.incomplete) {
  //     padding: 0px !important;
  //     background-color: ${StyleGuide.palette.redishLight} !important;
  // }
  // .ant-table-cell:has(.disabled) {
  //     padding: 0px !important;
  //     background-color: ${StyleGuide.palette.grey100} !important;
  // }
  .completed {
    background-color: ${StyleGuide.palette.statusSuccessDark};
  }
  .incomplete {
    background-color: ${StyleGuide.palette.statusErrorDark};
  }
  .onhold {
    background-color: ${StyleGuide.palette.statusWarningDark};
  }
  .needsresolution {
    background-color: ${StyleGuide.palette.statusHardWarningDark};
  }
  .pending {
    background-color: ${StyleGuide.palette.statusProcessingLight};
  }
  .disabled {
    background-color: ${StyleGuide.palette.grey100};
  }
  // .ant-table-body::after {
  //     content: "";
  //     position: absolute;
  //     bottom: 0;
  //     left: 0;
  //     right: 0;
  //     height: 30px; /* Adjust as needed */
  //     background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #d9d9d95c);
  //     pointer-events: none;
  //     z-index: 10;
  // }
`;

type InputData = {
  auditId: string;
  auditTemplateId: string;
  isCompleted: boolean;
  dueDate: string;
  orgTitle: string;
  orgId: string;
  subGroup: string;
  templateTitle: string;
  needsResolution: boolean | null;
  hasOnHoldTasks: boolean | null;
  completeTime: string | null;
};

type OutputData = {
  key: string;
  name: string;
  [key: string]: any;
};

function transformData(
  inputData: InputData[],
  categoryMap: any,
  subGroups: string[],
  selectedCategories: string[] = []
): OutputData[] {
  if (inputData?.length < 1) return [];
  const output: { [key: string]: OutputData } = {};
  const allSubGroups = Array.from(
    new Set([...subGroups, ...inputData.map((item) => item.subGroup)])
  ); // Get unique subGroups

  //  Initialize all possible categoryMap in output
  Object.entries(categoryMap).forEach(([key, value]) => {
    if (selectedCategories.length < 1 || selectedCategories.includes(key)) {
      output[key] = {
        key,
        name: value as string,
      };
      allSubGroups.forEach((group) => {
        output[key][group] = [];
      });
    }
  });

  // Initialize all auditTemplateIds with empty subGroups
  inputData.forEach((item) => {
    const { auditTemplateId } = item;

    if (!output[auditTemplateId]) {
      output[auditTemplateId] = {
        key: auditTemplateId,
        name: categoryMap[auditTemplateId],
      };

      // Initialize all possible subGroups with empty arrays
      allSubGroups.forEach((group) => {
        output[auditTemplateId][group] = [
          // // init empty field or has NONE
          // { key: group, name: group, dueDate: new Date(), state: "EMPTY" },
        ];
      });
    }
  });
  // console.log({ output });

  inputData.forEach((item) => {
    const {
      auditTemplateId,
      auditId,
      orgId,
      isCompleted,
      templateTitle,
      dueDate,
      subGroup,
      needsResolution,
      hasOnHoldTasks,
      completeTime,
    } = item;

    if (!output[auditTemplateId]) {
      output[auditTemplateId] = {
        key: auditTemplateId,
        name: categoryMap[auditTemplateId],
      };
    }

    output[auditTemplateId][subGroup].push({
      auditId,
      orgId,
      isCompleted,
      templateTitle,
      dueDate,
      auditTemplateId,
      needsResolution,
      hasOnHoldTasks,
      completeTime,
    });
  });

  // console.log({ output });

  return Object.values(output);
}
