import {
  SENSORLOGS_FETCH_REQUESTED,
  SENSORLOGS_FETCH_SUCCEEDED,
  SENSORS_FETCH_REQUESTED,
  SENSORS_FETCH_SUCCEEDED,
  SENSOR_FETCH_REQUESTED,
  SENSOR_FETCH_SUCCEEDED,
  SENSOR_CREATE_REQUESTED,
  SENSOR_CREATE_SUCCEEDED,
  SENSORMEASURES_FETCH_REQUESTED,
  SENSORMEASURES_FETCH_SUCCEEDED,
  GPS_SENSORLOGS_FETCH,
} from './types';

export function setDate({ date }) {
  return {
    type: 'SET_DATE',
    payload: {
      date,
    },
  };
}

export function fetchSensorLogs({ orgId, sensorId, selectedDate }) {
  return {
    type: SENSORLOGS_FETCH_REQUESTED,
    payload: {
      orgId,
      sensorId,
      selectedDate,
    },
  };
}

export function fetchSensorLogsSuccess(sensorLogs) {
  const logs = sensorLogs.docs.map((log) => ({
    ...log.data(),
    id: log.id,
  }));
  return {
    type: SENSORLOGS_FETCH_SUCCEEDED,
    payload: {
      data: logs,
    },
  };
}
export function fetchGpsLogsRequest(sensorLogs) {
  return {
    type: GPS_SENSORLOGS_FETCH,
    payload: {
      data: sensorLogs,
    },
  };
}
export function fetchGpsLogsSuccess(sensorLogs) {
  return {
    type: SENSORLOGS_FETCH_SUCCEEDED,
    payload: {
      data: sensorLogs,
    },
  };
}

export function fetchSensors({ orgId }) {
  return {
    type: SENSORS_FETCH_REQUESTED,
    payload: {
      orgId,
    },
  };
}

export function fetchSensorsSuccess(sensorsList) {
  const sensors = sensorsList.docs.map((sensor) => ({
    ...sensor.data(),
    id: sensor.id,
  }));
  return {
    type: SENSORS_FETCH_SUCCEEDED,
    payload: {
      data: sensors,
    },
  };
}

export function fetchSensor({ orgId, sensorId }) {
  return {
    type: SENSOR_FETCH_REQUESTED,
    payload: {
      orgId,
      sensorId,
    },
  };
}

export function fetchSensorSuccess(sensorDoc) {
  const sensor = { id: sensorDoc.id, ...sensorDoc.data() };

  return {
    type: SENSOR_FETCH_SUCCEEDED,
    payload: {
      data: sensor,
    },
  };
}

export function addSensor(orgId, sensorType, sensorTitle, latitude, longitude) {
  return {
    type: SENSOR_CREATE_REQUESTED,
    payload: {
      orgId,
      sensorType,
      sensorTitle,
      latitude,
      longitude,
    },
  };
}

export function addSensorSuccess() {
  return {
    type: SENSOR_CREATE_SUCCEEDED,
    payload: {},
  };
}

export function fetchSensorMeasures({ orgId, sensorId, selectedDate }) {
  return {
    type: SENSORMEASURES_FETCH_REQUESTED,
    payload: {
      orgId,
      sensorId,
      selectedDate,
    },
  };
}

export function fetchSensorMeasuresSuccess(sensorMeasures) {
  const logs = sensorMeasures.docs.map((log) => ({
    ...log.data(),
    id: log.id,
  }));
  return {
    type: SENSORMEASURES_FETCH_SUCCEEDED,
    payload: {
      data: logs,
    },
  };
}
