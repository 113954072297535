import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import BlurOverlay from './BlurOverlay';
import { FeatureIds } from 'modules/featureControl/featuresConfig';

export default function BlurOverlayContainer({
  featureKey,
  children,
}: {
  featureKey: FeatureIds;
  children: React.ReactElement;
}) {
  const feature = useSelector((state: AppState) => state.features.featuresMap[featureKey]);
  if (feature?.isEnabled === false) {
    return (
      <>
        <BlurOverlay />
        {children}
      </>
    );
  }
  return children;
}
