import { useState, useEffect } from 'react';
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarProvider,
  SidebarTrigger,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarInset,
  SidebarRail,
  useSidebar,
} from 'components/ui/sidebar';
import { Badge } from 'components/ui/badge';
import { Input } from 'components/ui/input';
import { Search, PanelLeftClose, PanelLeftOpen } from 'lucide-react';
import { Button } from 'components/ui/button';
import InventoryDashboard from './inventory-dashboard';
import type { InventoryItem } from '../types';
import { getCategoryColor } from './inventory-table-columns';

// Custom sidebar toggle button with expanded/collapsed state indicator
function SidebarToggle() {
  const { open, toggleSidebar } = useSidebar();

  return (
    <Button variant="outline" size="sm" onClick={toggleSidebar} className="flex items-center gap-2">
      {open ? (
        <>
          <PanelLeftClose className="h-4 w-4" />
          <span className="hidden sm:inline">Collapse Sidebar</span>
        </>
      ) : (
        <>
          <PanelLeftOpen className="h-4 w-4" />
          <span className="hidden sm:inline">Expand Sidebar</span>
        </>
      )}
    </Button>
  );
}

interface InventoryLayoutProps {
  initialItems: InventoryItem[];
}

export default function InventoryLayout({ initialItems }: InventoryLayoutProps) {
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');

  // Extract unique categories from items
  // @ts-ignore
  const categories = ['all', ...new Set(initialItems.map((item) => item.category))].sort();

  // Count items per category
  const categoryCounts = categories.reduce((acc, category) => {
    if (category === 'all') {
      acc[category] = initialItems.length;
    } else {
      acc[category] = initialItems.filter((item) => item.category === category).length;
    }
    return acc;
  }, {} as Record<string, number>);

  // Read sidebar state from cookie on initial load
  const [defaultOpen, setDefaultOpen] = useState(true);
  useEffect(() => {
    const sidebarState = document.cookie
      .split('; ')
      .find((row) => row.startsWith('sidebar:state='))
      ?.split('=')[1];

    if (sidebarState) {
      setDefaultOpen(sidebarState === 'true');
    }
  }, []);

  return (
    <SidebarProvider defaultOpen={defaultOpen}>
      <div className="flex w-full min-h-screen">
        <Sidebar variant="inset" collapsible="icon">
          <SidebarContent>
            <SidebarGroup>
              <SidebarGroupContent>
                <div className="mt-16 p-2">
                  <div className="relative">
                    <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                    <Input
                      type="search"
                      placeholder="Search categories..."
                      className="pl-8 w-full"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </SidebarGroupContent>
            </SidebarGroup>
            <SidebarGroup>
              <SidebarGroupLabel>Categories</SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {categories
                    .filter((category) =>
                      category.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .map((category) => (
                      <SidebarMenuItem key={category}>
                        <SidebarMenuButton
                          isActive={selectedCategory === category}
                          onClick={() => setSelectedCategory(category)}
                          tooltip={category}
                        >
                          {category === 'all' ? (
                            <>
                              <span>Alle kategorier</span>
                              <span className="ml-auto bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-full px-2 py-0.5 text-xs">
                                {categoryCounts[category]}
                              </span>
                            </>
                          ) : (
                            <>
                              <Badge
                                className={`${getCategoryColor(
                                  category
                                )} h-4 w-4 px-0 rounded-full mr-2`}
                              >
                                <span className="sr-only">{category}</span>
                              </Badge>
                              <span>{category}</span>
                              <span className="ml-auto bg-gray-200 dark:bg-gray-800 text-gray-700 dark:text-gray-300 rounded-full px-2 py-0.5 text-xs">
                                {categoryCounts[category]}
                              </span>
                            </>
                          )}
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          </SidebarContent>
          {/* Add the rail for easier resizing */}
          <SidebarRail />
        </Sidebar>
        <SidebarInset>
          <div className="w-full p-4">
            {/* <div className="flex items-center mb-4 gap-2">
              <SidebarTrigger className="md:hidden" />
              <SidebarToggle />
              <h1 className="text-2xl font-bold ml-2">Equipment Inventory</h1>
            </div> */}
            <InventoryDashboard selectedCategory={selectedCategory} />
          </div>
        </SidebarInset>
      </div>
    </SidebarProvider>
  );
}
