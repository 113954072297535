import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import IssueList from './IssueList';
import { fetchIssues, setCurrentTab } from '../../modules/issues/actions';
import { useAppSelector } from '../../hooks';
import { TAB_STATE } from '../../modules/issues/types';
import { fetchCategories } from 'modules/categories/actions';

function IssueListContainer() {
  const dispatch = useDispatch();
  const { orgId } = useParams<{ orgId: string }>();
  const { issues, loading, currentTab } = useAppSelector((state) => ({
    // TODO: add Issue type
    issues: state.issues.issues.filter((issue: any) =>
      state.issues.currentTab === 'completed' ? issue.isCompleted : !issue.isCompleted
    ),
    loading: state.issues.loading,
    currentTab: state.issues.currentTab,
  }));

  useEffect(() => {
    dispatch(fetchIssues({ orgId }));
    dispatch(fetchCategories({ orgId }));

    return () => {};
  }, [orgId, dispatch]);

  const dispatchSetCurrentTab = (tab: { key: TAB_STATE }) => dispatch(setCurrentTab(tab));
  return (
    <IssueList
      currentTab={currentTab}
      orgId={orgId}
      loading={loading}
      issues={issues}
      setCurrentTab={dispatchSetCurrentTab}
    />
  );
}

export default IssueListContainer;
