'use client';

import { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Button } from 'components/ui/button';
import { PlusCircle, Eye, Edit } from 'lucide-react';
import { Badge } from 'components/ui/badge';
import { OrderDialog } from './order-dialog';
import type { Order } from '../lib/types';
import { CreateOrderDialog } from './create-order-dialog';
import { useTranslation } from 'react-i18next';
import { getStatusColor, translateStatus } from '../lib/utils';
import { productUnitsMap } from '../lib/productUnits';

export function OrdersTable({ orders, orgId }: { orders: Order[]; orgId?: string }) {
  const { t } = useTranslation();
  // const { orders, loading } = useOrders();

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [isViewEditDialogOpen, setIsViewEditDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [mode, setMode] = useState<'view' | 'edit'>('view');

  const handleCreateOrder = () => {
    setIsCreateDialogOpen(true);
  };

  const handleViewOrder = (order: Order) => {
    setSelectedOrder(order);
    setMode('view');
    setIsViewEditDialogOpen(true);
  };

  const handleEditOrder = (order: Order) => {
    setSelectedOrder(order);
    setMode('edit');
    setIsViewEditDialogOpen(true);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Ordre</h2>
        <Button onClick={handleCreateOrder}>
          <PlusCircle className="mr-2 h-4 w-4" />
          Ny ordre
        </Button>
      </div>

      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Order ID</TableHead>
              {!orgId && <TableHead>Firma</TableHead>}
              <TableHead>Produker</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Oprettet</TableHead>
              <TableHead>Oprettet af</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {orders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} className="text-center py-4 text-muted-foreground">
                  Ingen ordrer fundet. Opret en ny ordre ved at klikke på "Ny ordre" knappen.
                </TableCell>
              </TableRow>
            ) : (
              orders.map((order) => {
                return (
                  <TableRow key={order.id}>
                    <TableCell className="font-medium">{order.id}</TableCell>
                    {!orgId && <TableCell>{order.companyName}</TableCell>}
                    <TableCell>
                      {order.products?.map((product) => {
                        // @ts-ignore
                        const unit = productUnitsMap[product.id]?.find(
                          (p: any) => p.value == product.unit
                        );
                        return (
                          <div key={product.id} className="flex space-x-1">
                            <div>
                              {
                                // @ts-ignore
                                order.isDeprecatedOrder
                                  ? product.quantity
                                  : product.quantity * unit?.value
                              }{' '}
                              liter -
                            </div>
                            <div>{product.name}</div>
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      <Badge className={getStatusColor(order.status)}>
                        {t(translateStatus(order.status))}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      {t('general.getDate', { date: order.createTime })}{' '}
                      {t('general.getMonth', { date: order.createTime })},{' '}
                      {t('general.getYear', { date: order.createTime })}
                    </TableCell>
                    <TableCell>{order.createdBy?.displayName}</TableCell>
                    <TableCell>
                      {
                        // @ts-ignore
                        !order.isDeprecatedOrder && (
                          <div className="flex space-x-2">
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleViewOrder(order)}
                            >
                              <Eye className="h-4 w-4" />
                            </Button>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => handleEditOrder(order)}
                            >
                              <Edit className="h-4 w-4" />
                            </Button>
                          </div>
                        )
                      }
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>

      {/* Use separate dialogs for create vs view/edit */}
      <CreateOrderDialog
        open={isCreateDialogOpen}
        onOpenChange={setIsCreateDialogOpen}
        orgId={orgId}
      />

      <OrderDialog
        open={isViewEditDialogOpen}
        onOpenChange={setIsViewEditDialogOpen}
        order={selectedOrder}
        mode={mode}
      />
    </div>
  );
}
