import { Divider, Drawer } from 'antd';
import { useOfferDrawerContext } from '../ContextDrawer/OfferContext';
import { AppOffer } from 'hooks/useAppOffers';
import Invoices from './Invoices';
import { useAppSelector } from 'hooks';
import { OrdersTable } from 'Views/Orders/components/orders-table';
import { useOrdersById } from 'Views/Orders/hooks/use-orders-byId';
import Loader from 'components/Loader';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from 'components/ui/sheet';

export default function DrawerOffer({ offers }: { offers: AppOffer[] }) {
  const { state, dispatch } = useOfferDrawerContext();

  const onClose = () => {
    if (open) {
      dispatch({ type: 'CLOSE_DRAWER' });
    }
  };
  const open = Boolean(state.offerId);

  const offer = offers.find((offer) => offer.id === state.offerId);

  return (
    <Sheet open={Boolean(open)} onOpenChange={onClose}>
      <SheetContent className="w-[90%] sm:max-w-[90%]">
        <SheetHeader>
          <SheetTitle>{`Tilbud - ${offer?.title} - ${offer?.contact.att}`}</SheetTitle>
          <SheetDescription>
            <DrawerContent offer={offer} offerId={state.offerId} orgId={state.orgId} />
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
}

function DrawerContent({
  offer,
  offerId,
  orgId,
}: {
  offer: AppOffer | undefined;
  offerId: string | null;
  orgId: string | null;
}) {
  const org = useAppSelector((state) => (orgId ? state.orgs.orgMap[orgId] : null));

  if (!offerId || !offer) {
    return null;
  }

  return (
    <div>
      <h4>Tilbud forbundet til: {org?.title}</h4>
      <Divider />
      <Invoices orgId={orgId} hallerupCustomerId={org?.hallerupCustomerId} />
      <Divider />
      <OrdersContainer orgId={orgId} />
      {/* <Orders orgId={orgId} /> */}
    </div>
  );
}

function OrdersContainer({ orgId }: { orgId: string | null }) {
  const { orders, loading } = useOrdersById(orgId);
  if (loading) {
    return (
      <div className="flex flex-col h-full justify-center items-center gap-2">
        <Loader size={48} />
        <p className="text-lg ml-4">Loader ordre...</p>
      </div>
    );
  }
  if (!orgId) {
    return <div>Tilbudet er ikke forbundet til en afdeling endnu</div>;
  }

  return <OrdersTable orders={orders} orgId={orgId} />;
}
