import { Product } from '../products/types';
import { Member } from './interfaces';
import {
  ORGS_FETCH_REQUESTED,
  ORGS_FETCH_SUCCEEDED,
  ORG_CREATE_REQUESTED,
  ORG_DELETE_REQUESTED,
  ORG_FETCH_REQUESTED,
  ORG_FETCH_SUCCEEDED,
  MEMBERS_UPDATE_REQUESTED,
  MEMBER_ADD_REQUESTED,
  MEMBER_DELETE_REQUESTED,
  ORG_UPDATE_REQUESTED,
} from './types';

export function fetchOrgs(uid: string, groupId?: string) {
  return {
    type: ORGS_FETCH_REQUESTED,
    payload: { uid, groupId },
  };
}

export function fetchOrgsSuccess(companyDocs: any) {
  const companies = companyDocs.docs.map((company: any) => ({
    ...company.data(),
    id: company.id,
  }));
  return {
    type: ORGS_FETCH_SUCCEEDED,
    payload: {
      data: companies,
    },
  };
}

export function addOrg({
  uid,
  email,
  title,
  products,
  area,
  storageAlert,
  unit,
  groupId,
}: {
  uid: string;
  email: string;
  title: string;
  products: Product[];
  area: string;
  storageAlert: string;
  unit: string;
  groupId: string | null;
}) {
  return {
    type: ORG_CREATE_REQUESTED,
    payload: { uid, email, title, products, area, storageAlert, unit, groupId },
  };
}

export function deleteOrg(id: string) {
  return {
    type: ORG_DELETE_REQUESTED,
    payload: id,
  };
}

export function fetchOrg({ orgId }: { orgId: string }) {
  return {
    type: ORG_FETCH_REQUESTED,
    payload: { orgId },
  };
}

export function fetchOrgSuccess(company: any) {
  const data = {
    ...company.data(),
    id: company.id,
  };
  return {
    type: ORG_FETCH_SUCCEEDED,
    payload: {
      data,
    },
  };
}

export function addMember({
  orgId,
  email,
  password,
  role,
  name,
}: {
  orgId: string;
  email: string;
  password: string;
  role: string;
  name: string;
}) {
  return {
    type: MEMBER_ADD_REQUESTED,
    payload: {
      orgId,
      email,
      password,
      role,
      name,
    },
  };
}

export function updateMembers({ orgId, member }: { orgId: string; member: Partial<Member> }) {
  return {
    type: MEMBERS_UPDATE_REQUESTED,
    payload: { orgId, member },
  };
}

export function removeMember({
  orgId,
  members,
  memberId,
}: {
  orgId: string;
  members: Member;
  memberId: string;
}) {
  return {
    type: MEMBER_DELETE_REQUESTED,
    payload: { orgId, members, memberId },
  };
}

export function updateOrg({ orgId, data }: { orgId: string; data: any }) {
  return {
    type: ORG_UPDATE_REQUESTED,
    payload: { orgId, data },
  };
}
