import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import { useAppSelector } from '../../../hooks';
import { useTranslation } from 'react-i18next';
import { updateIssue } from '../../../modules/issues/actions';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  CloseCircleOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { StyleGuide } from 'styles/StyleGuide';
import { prefixSelector } from 'Views/Members/AddMemberForm';
import firebase from 'firebase/compat/app';

const { Option } = Select;
const FormItem = Form.Item;

export default function IssueStatusSection() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  const [addExternal, setAddExternal] = useState(false);
  const { orgId, issueId } = useParams<{ orgId: string; issueId: string }>();
  const isCompleted = useAppSelector((state) => state.issues.currentIssue.completed);
  const assignee = useAppSelector((state) => state.issues.currentIssue.assignee);
  const user = useAppSelector(
    (state) => state.orgs?.currentOrg?.members?.[state.user.details?.uid]
  );
  const members = useAppSelector((state) => state.orgs?.currentOrg?.members);
  const subGroup = useAppSelector((state) => state.orgs?.currentOrg?.subGroup);
  const orgTitle = useAppSelector((state) => state.orgs?.currentOrg?.title);

  const group = useAppSelector(
    (state) => state.groups.groupsMap[state.orgs?.currentOrg?.groupId as string]
  );
  const dispatch = useDispatch();

  const dispatchUpdateIssue = (data: any) => {
    dispatch(updateIssue({ orgId, issueId, data }));
  };
  const onSetAssignee = () => {
    if (!user) return alert('Something went wrong');
    dispatchUpdateIssue({
      assignee: {
        id: user.uid,
        name: user.name || user.email,
        assignTime: new Date(),
      },
    });
  };
  const onChangeAssignee = (id: string) => {
    if (id === 'newExternal') {
      console.log('newExternal');
      setAddExternal(true);
      return;
    }

    const newAssignee = members[id];

    if (!newAssignee) return alert('Something went wrong');
    dispatchUpdateIssue({
      assignee: {
        id: newAssignee.uid,
        name: newAssignee.name || newAssignee.email,
        assignTime: new Date(),
      },
    });
    setEditing(false);
  };

  const handleSubmit = async (values: any) => {
    const { email, name, phone, groupBox, subGroupBox, company } = values;
    console.log(values);
    const ids = [
      orgId,
      groupBox ? group.id : undefined,
      subGroupBox ? subGroup?.id : undefined,
    ].filter(Boolean);
    const data = {
      email,
      name,
      phone,
      company,
      org: { id: orgId, title: orgTitle },
      group: groupBox ? { id: group.id, title: group?.title } : null,
      subgroup: subGroupBox
        ? {
            id: subGroup?.id,
            title: subGroup?.title,
          }
        : null,
      ids,
    };
    console.log({ data });

    try {
      await firebase.firestore().collection(`externalCollaborators`).add(data);

      form.resetFields();
      setAddExternal(false);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {/* <h2 style={{ marginTop: "20px" }}>{}:</h2> */}
      <div>
        {!assignee ? (
          <Button disabled={isCompleted} type="primary" onClick={onSetAssignee}>
            Tildel opgaven til mig
          </Button>
        ) : (
          <div>
            {editing ? (
              <>
                <Select
                  // defaultValue="lucy"
                  value={assignee.id}
                  style={{ width: '100%' }}
                  onChange={onChangeAssignee}
                >
                  <Select.OptGroup key={'internal'} label={'Interne'}>
                    {Object.keys(members).map((id) => (
                      <Option value={id}>{members[id]?.name || members[id]?.email}</Option>
                    ))}
                  </Select.OptGroup>
                  {/* <Select.OptGroup
                                        key={"newExternal"}
                                        label={"Tilføj ekstern"}> */}
                  <Select.Option key={'newExternal'} value={'newExternal'}>
                    <PlusCircleOutlined style={{ color: StyleGuide.palette.info }} />{' '}
                    <span
                      style={{
                        color: StyleGuide.palette.info,
                      }}
                    >
                      Tilføj ny ekstern
                      {/* {t("audit.schedule.task.quickTaskOption")} */}
                    </span>
                  </Select.Option>
                  {/* </Select.OptGroup> */}
                </Select>{' '}
                <div style={{ position: 'absolute', top: 8, right: 16 }}>
                  <Tooltip title="Fortryd">
                    <Button
                      // type="primary"
                      // shape="circle"
                      icon={<CloseCircleOutlined />}
                      onClick={() => setEditing(false)}
                    />
                  </Tooltip>
                </div>
              </>
            ) : (
              <>
                {assignee?.name}
                <div
                  style={{
                    fontSize: 12,
                    color: StyleGuide.palette.grey600,
                    lineHeight: '18px',
                  }}
                >
                  {t('date.exactFormat', {
                    relativeTime: assignee?.assignTime?.toDate(),
                    dayOfWeek: assignee?.assignTime?.toDate(),
                    dayOfMonth: assignee?.assignTime?.toDate(),
                    month: assignee?.assignTime?.toDate(),
                    time: assignee?.assignTime?.toDate(),
                  })}
                </div>
                <div style={{ position: 'absolute', top: 8, right: 16 }}>
                  <Tooltip title="Ret hvem der er tildelt opgaven">
                    <Button
                      // type="primary"
                      // shape="circle"
                      icon={<EditOutlined />}
                      onClick={() => setEditing(true)}
                    />
                  </Tooltip>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <Modal
        className="schedule-modal"
        width={'50%'}
        style={{
          position: 'relative',
        }}
        styles={{
          body: {
            padding: 0,
          },
        }}
        open={addExternal}
        onCancel={() => setAddExternal(false)}
        footer={[]}
      >
        <Row>
          <Col span={24} style={{ padding: 24 }}>
            <h1 style={{ margin: 0 }}>
              {/* {t("audit.schedule.headline")} */}
              Tilføj ny ekstern
            </h1>
            <Col md={24}>
              <div style={{ color: StyleGuide.palette.grey700 }}>
                Den eksterne gemmes automatisk til afdelingen til hurtigere tildeling i fremtiden
              </div>
            </Col>
            <Form
              form={form}
              layout="inline"
              initialValues={{
                email: 'test@gmail.com',
                name: 'bob',
                company: 'company',
                phone: '12345678',

                // password: getPassword(),
                // intl: Intl.da_DK,
                phoneCode: '45',
                // guides: false,
              }}
              onFinish={handleSubmit}
            >
              <Row style={{ width: '100%', marginBottom: 16 }}>
                <Col md={24}>
                  <div style={{ marginBottom: 16 }} />
                  <FormItem
                    name="email"
                    // {...formItemLayout}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Skriv e-mail på ekstern',
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      autoFocus
                      // prefix={
                      //     <UserOutlined
                      //         style={{
                      //             color: "rgba(0,0,0,.25)",
                      //         }}
                      //     />
                      // }
                      size="large"
                      placeholder="Email"
                    />
                  </FormItem>
                </Col>
                <Col md={24}>
                  <div style={{ marginBottom: 8 }} />
                  <FormItem
                    name="name"
                    // {...formItemLayout}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Skriv navn på ekstern',
                      },
                    ]}
                  >
                    <Input autoFocus size="large" placeholder="Navn" />
                  </FormItem>
                </Col>

                <Col md={24}>
                  <div style={{ marginBottom: 8 }} />
                  <FormItem
                    name="company"
                    // {...formItemLayout}
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Skriv firma for ekstern',
                      },
                    ]}
                  >
                    <Input autoFocus size="large" placeholder="Firma" />
                  </FormItem>
                </Col>
                <Col md={24}>
                  <div style={{ marginBottom: 8 }} />
                  <FormItem
                    name="phone"
                    style={{ width: '100%' }}
                    rules={[
                      {
                        required: true,
                        message: 'Skriv telefonnummeret på ekstern',
                      },
                    ]}
                  >
                    <Input
                      addonBefore={prefixSelector}
                      style={{ width: '100%' }}
                      type="tel"
                      size="large"
                      maxLength={12}
                      pattern="[0-9]{8}|[0-9]{9}|[0-9]{10}|[0-9]{11}|[0-9]{12}"
                      placeholder={t('access.add.phone_placeholder')}
                    />
                  </FormItem>
                </Col>
                {group?.id && (
                  <Col md={24}>
                    <div style={{ marginBottom: 24 }} />
                    <Tooltip
                      title={`Dette betyder at alle i som er en del af ${group.title} kan søge denne eksterne frem`}
                    >
                      <FormItem name="groupBox" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldValue('groupBox', e.target.checked);
                          }}
                        >
                          Gem også ekstern til alle som er en del af{' '}
                          <span style={{ fontWeight: 'bold' }}>{group.title}</span>
                          {/* {t("audit.schedule.multidayaudit")} */}
                        </Checkbox>
                        <InfoCircleOutlined />
                      </FormItem>
                    </Tooltip>
                  </Col>
                )}
                {subGroup?.id && (
                  <Col md={24}>
                    <Tooltip
                      title={`Dette betyder at alle i som er en del af ${subGroup?.title} kan søge denne eksterne frem`}
                    >
                      {/* <div> */}
                      <FormItem name="subGroupBox" valuePropName="checked">
                        <Checkbox
                          onChange={(e) => {
                            form.setFieldValue('subGroupBox', e.target.checked);
                          }}
                        >
                          Gem også ekstern til alle som er en del af{' '}
                          <span style={{ fontWeight: 'bold' }}>{subGroup?.title}</span>
                          {/* {t("audit.schedule.multidayaudit")} */}
                        </Checkbox>
                        <InfoCircleOutlined />
                      </FormItem>
                      {/* </div> */}
                    </Tooltip>
                  </Col>
                )}
              </Row>
              <FormItem style={{ width: '100%' }}>
                <Button type="primary" htmlType="submit" block style={{ width: '100%' }}>
                  {t('audit.schedule.submit')}
                </Button>
              </FormItem>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
}
