import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message, Tag } from 'antd';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { post } from '../../../general/api';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import {
  InventoryItem,
  InventoryStatus,
  InventoryStatusMap,
  InventoryContextForType,
  InventoryType,
} from '../../../modules/inventory/types';
import Message, { Type } from '../../../components/Notification/Message';
import TextArea from 'antd/lib/input/TextArea';
import { InventoryTagsMap } from 'Views/SensorOverview/useInventoryTags';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  wrapperCol: { span: 24 },
};

export default function EditForm({
  id,
  onClose,
  inventoryTagsMap,
}: {
  id: string;
  onClose: () => void;
  inventoryTagsMap: InventoryTagsMap;
}) {
  const [form] = Form.useForm();
  const ipad = useSelector((state: AppState) => state.inventory.inventoryMap[id]);
  const orgs = useSelector((state: AppState) =>
    state.orgs.orgs
      .map(({ id, title }: { id: string; title: string }) => ({ id, title }))
      .sort((a: any, b: any) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1))
  );
  const orgMap = useSelector((state: AppState) => state.orgs.orgMap);

  const initialValues = ipad;
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const updateInventoryItem = async (
    values: Partial<InventoryContextForType<InventoryType.IPAD>>
  ) => {
    // remove key as it will not be store like this

    try {
      Message({
        key: 'editInventory',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      await firebase
        .firestore()
        .collection('inventory')
        .doc(id)
        .set(
          {
            ...values,
            updateTime: new Date(),
          },
          { merge: true }
        );
      await firebase
        .firestore()
        .collection('inventory')
        .doc(id)
        .collection('history')
        .add({
          ...values,
          updateTime: new Date(),
        });
      onClose();
      Message({
        key: 'editInventory',
        message: `Alt blev opdateret korrekt`,
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'editInventory',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke opdatere Simkort, prøv venligst igen`,
      });
    }
  };

  const options = Object.entries(inventoryTagsMap).map(([id, { title, color }]) => ({
    label: title,
    value: id,
    color,
  }));

  return (
    <>
      <Form
        form={form}
        onFinish={(values) => updateInventoryItem(values)}
        initialValues={initialValues}
      >
        <FormItem
          {...formItemLayout}
          name="iccid"
          rules={[{ required: true, message: 'Please input device id!' }]}
        >
          <Input type="text" placeholder={t('inventory.ipad.serial')} disabled />
        </FormItem>

        <FormItem
          {...formItemLayout}
          style={{ minWidth: '150px' }}
          name="tags"
          rules={[{ required: false, message: 'Please select tags!' }]}
        >
          <Select placeholder={t('Tags')} mode="multiple">
            {options.map((item) => (
              <Option style={{ color: item.color }} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </FormItem>

        <FormItem
          {...formItemLayout}
          name="note"
          rules={[{ required: false, message: 'Please input device id!' }]}
        >
          <TextArea rows={4} placeholder={t('Note')} />
        </FormItem>
        <FormItem>
          <Button type="primary" block htmlType="submit" loading={false}>
            {'Gem'}
          </Button>
        </FormItem>
      </Form>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </>
  );
}
