import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export default function useIssueThumbnailImage(orgId: string, file?: string) {
  const [image, setImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  useEffect(() => {
    if (!file || !orgId) return;

    setLoadingImage(true);
    firebase
      .storage()

      .ref(`orgs/${orgId}/issues/thumb_${file}`)
      // .child("images/stars.jpg")
      .getDownloadURL()
      .then((url) => {
        console.log({ url });
        setImage(url);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoadingImage(false));
  }, [orgId, file]);
  return { image, loadingImage };
}
