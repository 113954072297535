import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message, Tag } from 'antd';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { post } from '../../../general/api';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import {
  InventoryItem,
  InventoryStatus,
  InventoryStatusMap,
  InventoryContextForType,
  InventoryType,
} from '../../../modules/inventory/types';
import Message, { Type } from '../../../components/Notification/Message';
import TextArea from 'antd/lib/input/TextArea';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  wrapperCol: { span: 24 },
};

export default function AddForm() {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const orgs = useSelector((state: AppState) =>
    state.orgs.orgs
      .map(({ id, title }: { id: string; title: string }) => ({ id, title }))
      .sort((a: any, b: any) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1))
  );
  const orgMap = useSelector((state: AppState) => state.orgs.orgMap);

  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const addInventoryItem = async (
    values: Partial<InventoryContextForType<InventoryType.FOOBOT>>
  ) => {
    // remove key as it will not be store like this
    const orgId = values.orgId;
    if (!orgId) return alert('something went wrong');
    delete values.orgId;
    try {
      Message({
        key: 'addInventory',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      await firebase
        .firestore()
        .collection('inventory')
        .add({
          ...values,
          org: {
            id: orgId,
            // @ts-ignore
            title: orgMap[orgId]?.title,
          },
          type: InventoryType.FOOBOT,
          createTime: new Date(),
          updateTime: new Date(),
        });
      onClose();
      form.resetFields();
      Message({
        key: 'addInventory',
        message: `Alt blev tilføjet korrekt`,
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'addInventory',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke oprette Foobot, prøv venligst igen`,
      });
    }
  };

  const onClose = () => setVisible(false);

  return (
    <>
      <Button size="large" type="primary" onClick={() => setVisible(true)}>
        {
          // TODO: maybe translate?
          'Tilføj Foobot'
        }
      </Button>
      <Modal
        title="Tilføj Foobot"
        open={!!visible}
        confirmLoading={false}
        onCancel={onClose}
        footer={[]}
      >
        <Form form={form} onFinish={addInventoryItem}>
          <FormItem
            {...formItemLayout}
            name="deviceId"
            rules={[{ required: true, message: 'Please input serial!' }]}
          >
            <Input type="text" placeholder={t('inventory.ipad.serial')} />
          </FormItem>
          <FormItem
            {...formItemLayout}
            name="name"
            rules={[{ required: true, message: 'Please input name!' }]}
          >
            <Input type="text" placeholder={t('inventory.ipad.name')} />
          </FormItem>

          <FormItem
            {...formItemLayout}
            style={{ minWidth: '150px' }}
            name="status"
            rules={[{ required: true, message: 'Please select status!' }]}
          >
            <Select placeholder="Select status">
              <Option value={InventoryStatus.NEEDS_ORDER}>
                <Tag color="red">{InventoryStatusMap[InventoryStatus.NEEDS_ORDER]}</Tag>
              </Option>
              <Option value={InventoryStatus.ORDERED}>
                <Tag color="gold">{InventoryStatusMap[InventoryStatus.ORDERED]}</Tag>
              </Option>
              <Option value={InventoryStatus.IN_STORAGE}>
                <Tag color="orange">{InventoryStatusMap[InventoryStatus.IN_STORAGE]}</Tag>
              </Option>
              <Option value={InventoryStatus.READY}>
                <Tag color="blue">{InventoryStatusMap[InventoryStatus.READY]}</Tag>
              </Option>
              <Option value={InventoryStatus.ASSIGNED}>
                <Tag color="green">{InventoryStatusMap[InventoryStatus.ASSIGNED]}</Tag>
              </Option>
            </Select>
          </FormItem>
          <FormItem
            {...formItemLayout}
            style={{ minWidth: '150px' }}
            name="orgId"
            rules={[{ required: true, message: 'Please select company!' }]}
          >
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Select a company"
              optionFilterProp="children"
              filterOption={(input, option) =>
                // @ts-ignore
                option?.children
                  // @ts-ignore
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {orgs.map((org: { id: string; title: string }) => (
                <Option value={org.id}>{org.title}</Option>
              ))}
            </Select>
          </FormItem>
          <FormItem
            {...formItemLayout}
            name="note"
            rules={[{ required: false, message: 'Please input device id!' }]}
          >
            <TextArea rows={4} placeholder={t('Note')} />
          </FormItem>
          <FormItem>
            <Button type="primary" block htmlType="submit" loading={false}>
              {'Tilføj'}
            </Button>
          </FormItem>
        </Form>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </Modal>
    </>
  );
}
