import { Icon } from '@iconify/react';
import { Button, Modal, Space, Tooltip } from 'antd';

import { useAppSelector } from 'hooks';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleGuide } from 'styles/StyleGuide';
import firebase from 'firebase/compat/app';
import Message, { Type } from 'components/Notification/Message';
import { IOrg, MemberValues } from 'modules/orgs/interfaces';
import TextArea from 'antd/es/input/TextArea';
import { AuditTemplateTask } from 'modules/auditTemplates/types';
import { DiscardType } from 'modules/audits/types';

type AuditTemplateProps = {
  orgId: string;
  auditTemplateId: string;
  taskId: string;
  auditId?: never;
};

type Props = AuditTemplateProps;

export async function discardAuditTemplateTask({
  user,
  org,
  taskId,
  orgId,
  auditTemplateId,
  auditTemplateTitle,
  task,
  discardNote,
  auditTemplateCategory,
}: {
  user: MemberValues;
  org: IOrg;
  taskId: string;
  orgId: string;
  auditTemplateId: string;
  auditTemplateTitle: string;
  task: AuditTemplateTask;
  discardNote: string;
  auditTemplateCategory?: string | null;
}) {
  const payload = {
    discardType: DiscardType.AUDIT_TEMPLATE,
    isDiscarded: true,
    discardNote,
    discardTime: new Date(),
    discardedBy: {
      uid: user.uid,
      name: user.name || user.email,
    },
    org: {
      id: orgId,
      title: org.title,
    },
    subGroup: org.subGroup?.id
      ? {
          id: org.subGroup.id,
          title: org?.subGroup.title,
        }
      : null,
    audit: null,
    auditTemplate: {
      id: auditTemplateId,
      title: auditTemplateTitle,
      auditTemplateCategoryId: auditTemplateCategory || null,
    },
    group: org.groupId
      ? {
          id: org.groupId,
        }
      : null,
    task: {
      id: taskId,
      description: task.description,
    },
  };
  if (!payload.auditTemplate.id || !payload.task.id || !payload.org.id) {
    throw new Error('Missing required data');
  }

  const batch = firebase.firestore().batch();

  const auditTemplateRef = firebase
    .firestore()
    .collection('orgs')
    .doc(orgId)
    .collection('auditTemplates')
    .doc(auditTemplateId)
    .collection('tasks')
    .doc(taskId);

  const discardAuditTasksRef = firebase.firestore().collection('discardedAuditTasks').doc();

  batch.delete(auditTemplateRef);

  batch.set(discardAuditTasksRef, payload);

  batch.commit();
}

export default function AuditTemplateTaskDiscardAction({ orgId, auditTemplateId, taskId }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [discardNote, setDiscardNote] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const task = useAppSelector((state) =>
    state.auditTemplates.currentAuditTemplateTasks.find((t) => t.id === taskId)
  );
  const auditTemplate = useAppSelector((state) => state.auditTemplates.currentAuditTemplate);
  const org = useAppSelector((state) => state.orgs.orgMap[orgId]);
  const user = useAppSelector((state) => state.orgs.currentOrg.members[state.user.details.uid]);
  // const [isOnHold, setisOnHold] = useState(false);
  // const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    try {
      if (!task) {
        throw new Error('Task not found');
      }
      Message({
        key: 'discardTask',
        message: 'Loading...',
        type: Type.LOADING,
      });
      if (auditTemplateId) {
        await discardAuditTemplateTask({
          user,
          org,
          taskId,
          orgId,
          auditTemplateId,
          auditTemplateTitle: auditTemplate.title,
          task,
          discardNote,
          auditTemplateCategory: auditTemplate.auditTemplateCategory,
        });
      }

      setOpen(false);
      Message({
        key: 'discardTask',
        message: 'Opgaven blev kasseret',
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'discardTask',
        message: 'Der skete en fejl',
        type: Type.ERROR,
      });
    }
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <>
      <Button
        type="text"
        block
        danger
        style={{
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
          color: StyleGuide.palette.redish,
        }}
        onClick={showModal}
        icon={
          <span role="img" aria-label="pause-circle" className="anticon anticon-pause-circle">
            <Icon
              color={StyleGuide.palette.redish}
              fontSize={16}
              icon="heroicons:archive-box-x-mark" /*style={{ color: Colors.warning }}*/
            />
          </span>
        }
      >
        {t('Kassér')}
      </Button>

      <Modal
        destroyOnClose
        title={t(`Kassér opgaven?`)}
        open={open}
        okText={t('Kassér')}
        cancelText={t('Fortryd')}
        onOk={handleOk}
        okButtonProps={{ disabled: !discardNote }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <>
          <ol style={{ paddingInlineStart: 16 }}>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t('<strong>{{taskTitle}}</strong> vil blive markeret som kasseret', {
                  taskTitle: task?.description,
                })}
              />
            </li>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t('<strong>{{taskTitle}}</strong> flyttes til kasserede listen', {
                  taskTitle: task?.description,
                })}
              />
            </li>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t('Fremtidige opgaver vil ikke inkludere <strong>{{taskTitle}}</strong>', {
                  taskTitle: task?.description,
                })}
              />
            </li>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t(
                  'Skabelonen vil blive opdateret og <strong>{{taskTitle}}</strong> vil ikke længere fremgå',
                  {
                    taskTitle: task?.description,
                  }
                )}
              />
            </li>
          </ol>
          <TextArea
            placeholder={t('Notér hvorfor opgaven kasseres')}
            value={discardNote}
            onChange={(e) => setDiscardNote(e.target.value)}
          />
        </>
      </Modal>
    </>
  );
}
