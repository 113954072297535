import React, { useState } from 'react';
import { Space, Avatar, Tooltip, Button } from 'antd';
import { getInitials } from '../../../general/initials';
import { DBGroupShape } from '../../../modules/groups/types';
import { UserAddOutlined, ShopOutlined } from '@ant-design/icons';
import GroupAccessModal from './GroupAccessModal';
import GroupOrdersModal from './GroupOrdersModal';
import { getColor } from '../../Home/Table';

export default function HeaderActions({ group }: { group: DBGroupShape }) {
  const [openAccessModal, setopenAccessModal] = useState(false);
  const [openOrdersModal, setopenOrdersModal] = useState(false);
  return (
    <>
      <Space>
        {group?.members && (
          <Avatar.Group maxCount={2} maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
            {Object.entries(group?.members).map(([key, value], index) => {
              const initial = getInitials(value?.name || '') || getInitials(value?.email);

              console.log({ initial, value });

              return (
                <Tooltip title={value?.name || value?.email} key={key}>
                  <Avatar
                    style={{
                      textTransform: 'capitalize',
                      backgroundColor: getColor(index),
                    }}
                  >
                    {initial}
                  </Avatar>
                </Tooltip>
              );
            })}
          </Avatar.Group>
        )}
        <Tooltip title="Tilføj adgang">
          <Button onClick={() => setopenAccessModal(true)} icon={<UserAddOutlined />} />
        </Tooltip>
        <Tooltip title="Tilføj bestilling">
          <Button onClick={() => setopenOrdersModal(true)} icon={<ShopOutlined />} type="primary" />
        </Tooltip>
      </Space>
      <GroupAccessModal
        groupId={group.id}
        title={group.title}
        open={openAccessModal}
        handleClose={() => {
          setopenAccessModal(false);
        }}
      />
      <GroupOrdersModal
        groupId={group.id}
        title={group.title}
        open={openOrdersModal}
        handleClose={() => {
          setopenOrdersModal(false);
        }}
      />
    </>
  );
}
