import React from 'react';
import { Row, Col, Card, Popconfirm, message, Layout, Typography, Button } from 'antd';
import {
  ArrowRightOutlined,
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
} from '@ant-design/icons';
import gps from '../../assets/images/icons/gps-icon.png';
import gpsPlant from '../../assets/images/icons/gps-plant.png';
import aqi from '../../assets/images/icons/dust.png';
import gsmplant from '../../assets/images/icons/gsm-plant.png';
import level from '../../assets/images/level.webp';
import AddCard from './components/AddCard';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { format } from 'date-fns';
import { DeviceType, SensorType } from '../../general/enums';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';
import BlurOverlayContainer from '../../components/Overlay/BlurOverlayContainer';
import AdminOnly, { isAdmin } from 'components/AdminOnly';
import { useAppSelector } from 'hooks';
import { firestore } from '../../firebase';
import confirmModal from 'general/confirmModal';

const { Meta } = Card;
const { Content } = Layout;

const placeholderImage = {
  [SensorType.GPS_GY_NEO]: gps,
  [SensorType.DUST_OLD]: gps,
  [SensorType.FOOBOT]: aqi,
  [SensorType.ESP_AQ]: aqi,
  [SensorType.ESP_AQ_NEW]: aqi,
  [SensorType.GSM_PLANT]: gsmplant,
  [SensorType.GSM_CUSTOM]: gsmplant,
  [SensorType.GPS_SIMTRACKING]: gps,
  [DeviceType.MOBILE_PLANT]: gpsPlant,
  [DeviceType.LEVEL_SENSOR_INTERNAL]: level,
};

const routeMap = {
  [SensorType.GPS_GY_NEO]: null,
  [SensorType.GPS_SIMTRACKING]: '/gps-simtracking',
  [SensorType.DUST_OLD]: null,
  [SensorType.FOOBOT]: '/aq',
  [SensorType.ESP_AQ]: '/air-quality',
  [SensorType.ESP_AQ_NEW]: '/air-quality',
  [SensorType.GSM_PLANT]: '/gsm-plant',
  [SensorType.GSM_CUSTOM]: '/gsm-custom',
  [SensorType.LEVEL_SENSOR_INTERNAL]: '/level-sensor',
};

interface SensorProps {
  id: string;
  deviceId: string;
  orgId: string;
  title: string;
  createTime: { toDate: () => Date };
  type: SensorType;
  deviceType?: DeviceType;
  identifier?: string;
}

function Sensor({
  id,
  orgId,
  deviceId,
  identifier,
  title,
  createTime,
  type,
  deviceType,
}: SensorProps) {
  const dateString = format(createTime.toDate(), 'dd/MM/yyyy - HH:mm');
  const icon = {
    height: '148px',
    background: `url(${placeholderImage[deviceType || type]}) no-repeat center center`,
    backgroundSize: '70%',
  };
  const userId = useAppSelector((state) => state.user.details.uid);
  const displayDelete = isAdmin(userId);

  const confirmDelete = () => {
    confirmModal({
      title: 'Er du sikker på du vil slette sensoren?',
      onOk: () => {
        handleDelete();
      },
    });
  };

  const handleDelete = async () => {
    // deleteSensor(id);
    try {
      if (!orgId || !id) {
        throw new Error('Invalid orgId or id');
      }
      const sensorRef = firestore.collection('sensors').doc(id);
      const orgSensorRef = firestore.collection('orgs').doc(orgId).collection('sensors').doc(id);
      const batch = firestore.batch();

      batch.delete(sensorRef);
      batch.delete(orgSensorRef);
      await batch.commit();
      message.success('Sensor deleted');
    } catch (error) {
      console.error('Failed to delete sensor', error);
      message.error('Failed to delete sensor');
    }
  };
  return (
    <Col md={6} xs={24}>
      <Card
        hoverable
        cover={<div style={icon} />}
        actions={
          displayDelete
            ? [
                <Link to={`/orgs/${orgId}/sensors/${id}${routeMap[type] || ''}`}>
                  <ArrowRightOutlined />
                </Link>,
                <Button onClick={confirmDelete} type="text" icon={<DeleteOutlined />} />,
              ]
            : [
                <Link to={`/orgs/${orgId}/sensors/${id}${routeMap[type] || ''}`}>
                  <ArrowRightOutlined />
                </Link>,
              ]
        }
      >
        <AdminOnly>
          <Typography.Text type="secondary">{deviceId || identifier}</Typography.Text>
        </AdminOnly>
        <Meta title={title} description={dateString} />
      </Card>
    </Col>
  );
}

interface Props {
  sensors: any[];
  loading: boolean;
  orgId: string;
}

function SensorList(props: Props) {
  console.log('SensorList', props);
  const sensors = props.sensors.map((sensor) => (
    <Sensor
      key={sensor.id}
      id={sensor.id}
      deviceId={sensor.deviceId}
      orgId={props.orgId}
      title={sensor.title}
      createTime={sensor.createTime}
      type={sensor.type}
      identifier={sensor.identifier}
      deviceType={sensor.deviceType}
    />
  ));
  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.SENSORS}>
          <Content style={{ margin: '50px 24px 0' }}>
            {props.loading ? (
              <Loader />
            ) : (
              <>
                <Row>
                  <Col md={6} xs={24}>
                    <AddCard />
                  </Col>
                </Row>
                <Row gutter={16}>{sensors}</Row>
              </>
            )}
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default SensorList;
