import { RequestState } from 'interfaces/request';
import { Intl } from '../../general/enums';
export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE';

export const SIGNIN_REQUESTED = 'SIGNIN_REQUESTED';
export const SIGNIN_FAILED = 'SIGNIN_FAILED';
export const SIGNIN_SUCCEEDED = 'SIGNIN_SUCCEEDED';

export const SIGNOUT_REQUESTED = 'SIGNOUT_REQUESTED';
export const SIGNOUT_SUCCEEDED = 'SIGNOUT_SUCCEEDED';
export const SIGNOUT_FAILED = 'SIGNOUT_FAILED';

export const FORGOT_PASSWORD_REQUESTED = 'FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_SUCCEEDED = 'FORGOT_PASSWORD_SUCCEEDED';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_PERMISSIONS = 'SET_CURRENT_USER_PERMISSIONS';

export const GET_USER = 'GET_USER';
export const SET_ONBOARDING = 'SET_ONBOARDING';
export const SET_ONBOARDING_STATE = 'SET_ONBOARDING_STATE';

export const SET_SEARCH_KEYS_REQUESTED = 'SET_SEARCH_KEYS_REQUESTED';
export const SET_SEARCH_KEYS_SUCCEEDED = 'SET_SEARCH_KEYS_SUCCEEDED';
export const SET_SEARCH_KEYS_FAILED = 'SET_SEARCH_KEYS_FAILED';
interface Preference {
  language: 'da';
}

interface BaseKey {
  key: string;
  expireTime: number;
  state: 'valid' | 'invalid';
}

interface OrgSpecificKey extends BaseKey {
  orgId: string;
}

export enum SearchKeyNames {
  ORGS = 'orgs',
  ORG_ISSUES = 'orgIssues',
}

export type SearchKeys = {
  [SearchKeyNames.ORGS]?: BaseKey;
  [SearchKeyNames.ORG_ISSUES]?: OrgSpecificKey;
};
export interface UserState {
  preferences: Preference;
  details: any;
  loading: boolean;
  loadingForgotPassword: boolean;
  errorForgotPassword: string | null;
  error: string | null;
  success: string | null;
  permissions: any;
  userDoc: {
    displayName?: string;
    email?: string;
    dailyDigestEmail?: boolean;
    intl: Intl;
  };
  onboardingRequestState: RequestState;
  onboarding: any;
  searchKeys: SearchKeys;
  searchKeyRequestState: RequestState;
}

interface SignIn {
  type: typeof SIGNIN_REQUESTED;
}

interface SignInSuccess {
  type: typeof SIGNIN_SUCCEEDED;
}

interface SignInFailed {
  type: typeof SIGNIN_FAILED;
  payload: { error: string };
}

interface PasswordReset {
  type: typeof FORGOT_PASSWORD_REQUESTED;
}

interface PasswordResetSuccess {
  type: typeof FORGOT_PASSWORD_SUCCEEDED;
  payload: { success: string };
}

interface PasswordResetFailed {
  type: typeof FORGOT_PASSWORD_FAILED;
  payload: { error: string };
}

interface SetCurrentUser {
  type: typeof SET_CURRENT_USER;
  user: any;
}
interface GetUser {
  type: typeof GET_USER;
  payload: { user: any };
}

interface GetOnboarding {
  type: typeof SET_ONBOARDING;
  payload: any;
}
interface SetOnboardingState {
  type: typeof SET_ONBOARDING_STATE;
  payload: RequestState;
}
interface SetSearchKeys {
  type: typeof SET_SEARCH_KEYS_SUCCEEDED;
  payload: SearchKeys;
}
interface SetSearchKeysRequestState {
  type: typeof SET_SEARCH_KEYS_REQUESTED | typeof SET_SEARCH_KEYS_FAILED;
}

export type UserActionTypes =
  | GetUser
  | SignIn
  | SignInSuccess
  | SignInFailed
  | PasswordReset
  | PasswordResetSuccess
  | PasswordResetFailed
  | SetCurrentUser
  | GetOnboarding
  | SetOnboardingState
  | SetSearchKeys
  | SetSearchKeysRequestState;
