import React from 'react';
import { Popconfirm, Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

interface Props {
  record: any;
  editingKey: string;
  isEditing: (record: any) => boolean;
  save: (key: string, amount: number, record?: any) => void;
  edit: (record: any) => void;
  cancel: () => void;
  deleteAction?: (key: string) => void;
}

export default function TableEditCancel({
  record,
  editingKey,
  isEditing,
  save,
  edit,
  cancel,
  deleteAction,
}: Props) {
  const { t } = useTranslation();
  const editable = isEditing(record);
  const canDelete = useSelector(
    (state: AppState) =>
      state.orgs.currentOrg?.members?.[state.user.details.uid]?.role !== 'cleaner'
  );

  return editable ? (
    <span>
      <a
        href="javascript:;"
        onClick={() => save(record.key, record.amount, record)}
        style={{
          marginRight: 8,
        }}
      >
        {t('orders.save')}
      </a>
      {/* <Popconfirm title={t("orders.popover_title")} onConfirm={cancel}> */}
      <a href="javascript:;" onClick={cancel}>
        {t('orders.cancel')}
      </a>
      {/* </Popconfirm> */}
    </span>
  ) : (
    <>
      {canDelete && (
        <Button
          block
          icon={<EditOutlined />}
          //@ts-ignore
          disabled={editingKey !== ''}
          onClick={() => edit(record)}
        >
          {t('orders.edit')}
        </Button>
      )}
      <div style={{ width: 50 }} />
      {canDelete && deleteAction && (
        <Popconfirm
          title={t('orders.popover_title')}
          okText={t('orders.popover_confirm')}
          cancelText={t('orders.popover_cancel')}
          onConfirm={() => deleteAction(record.key)}
        >
          <Button danger block icon={<DeleteOutlined />}>
            {t('orders.delete')}
          </Button>
        </Popconfirm>
      )}
    </>
  );
}
