import React, { useState } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import Table from './components/Table';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import Loader from '../../components/Loader';
import AddHourLogForm from './AddHourTrackerForm';
import moment from 'moment';
import { parseAmountToFloat } from 'general/helpers';
import { isToday } from 'date-fns';
import { useIsMobile } from '../../hooks/useIsMobile';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';
import BlurOverlayContainer from '../../components/Overlay/BlurOverlayContainer';
import { useTranslation } from 'react-i18next';
import { Product } from '../../modules/products/types';
import AddLogForm from './AddLogForm';
import Message, { Type } from 'components/Notification/Message';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import firebase from 'firebase/compat/app';

const { Content } = Layout;

function HourLogsList(props: any) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
  const [isShowingHistory, setisShowingHistory] = useState(!isMobile);
  const data = props.cleanLogs
    .filter((log: any) => (isShowingHistory ? log : isToday(log.createTime.toDate())))
    .map((log: any) => {
      return {
        ...log,
        key: log.id,
        createTime: moment(log.createTime.toDate()).format('Do MMM. YYYY - HH:mm'),
      };
    });

  const updateOrgProductFn = (diffAmount: number, product: Product) =>
    props.updateOrgProduct({
      productId: product.id,
      data: {
        storage: parseAmountToFloat(product.storage) - parseAmountToFloat(diffAmount),
      },
    });
  console.log({ hourTrackers: props.hourTrackers });

  const handleResetCount = async (hourTrackerId: string) => {
    try {
      if (!orgId || !hourTrackerId) throw new Error('Invalid orgId');
      Message({
        key: 'resetHourTracker',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      await firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('hourTrackers')
        .doc(hourTrackerId)
        .set({ currentCount: 0 }, { merge: true });
      Message({
        key: 'resetHourTracker',
        message: 'Timetæller nulstillet',
      });
    } catch (error) {
      console.error(error);

      Message({
        key: 'resetHourTracker',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke nulstille timetæller, prøv venligst igen`,
      });
    }
  };

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.HOUR_TRACKER}>
          <Content style={{ margin: '50px 24px 0' }}>
            <Row>
              <Col span={24} style={{ marginBottom: 24 }}>
                {props.loading ? (
                  <Loader />
                ) : (
                  <React.Fragment>
                    <div style={{ textAlign: 'right' }}>
                      <AddHourLogForm />
                    </div>
                    {props.hourTrackers.map((product: any) => {
                      const logs = data.filter((log: any) => log.hourTrackerId === product.id);
                      return (
                        <Row key={product.id} style={{ marginBottom: 40 }}>
                          <Col
                            md={24}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <h2
                              style={{
                                margin: 0,
                                marginRight: 4,
                              }}
                            >
                              {product.title}
                            </h2>
                            <div>
                              - {t('hourLogs.table.hourCount')}: {product.currentCount}
                            </div>
                          </Col>
                          <Col md={24} style={{ width: '100%' }}>
                            <div
                              style={{
                                textAlign: 'right',
                              }}
                            >
                              <Button
                                type="link"
                                onClick={() => setisShowingHistory(!isShowingHistory)}
                              >
                                {isShowingHistory
                                  ? t('hourLogs.table.history.hide')
                                  : t('hourLogs.table.history.show')}
                              </Button>
                            </div>
                            <Table
                              loading={props.loading}
                              scroll={{ x: true }}
                              data={logs}
                              isShowingHistory={isShowingHistory}
                              removeMember={props.removeMember}
                              update={({ logId, data, diffAmount }: any) =>
                                console.log({
                                  logId,
                                  data,
                                  diffAmount,
                                })
                              }
                              updateOrgProduct={(diffAmount: number) =>
                                updateOrgProductFn(diffAmount, product)
                              }
                            />
                          </Col>
                          <Col md={24} style={{ width: '100%' }}>
                            <AddLogForm
                              hourTrackerId={product.id}
                              currentCount={product.currentCount}
                              totalCount={product.totalCount}
                            />
                          </Col>
                        </Row>
                      );
                    })}
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default HourLogsList;

HourLogsList.defaultProps = {
  logs: [],
};
