import { AuditSystem } from './audit-system';
import { AuditProvider } from './audit-context';

function InternalAuditDetail() {
  return (
    <AuditProvider>
      <AuditSystem />
    </AuditProvider>
  );
}

export default InternalAuditDetail;
