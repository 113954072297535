import React from 'react';
import { Card, Layout } from 'antd';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import SettingsForm from './SettingsForm';
import Loader from '../../components/Loader';
import BlurOverlayContainer from '../../components/Overlay/BlurOverlayContainer';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';

const { Meta } = Card;
const { Content } = Layout;

function Settings(props: any) {
  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.SETTINGS}>
          <Content style={{ margin: '50px 24px 0' }}>
            {props.loading ? <Loader /> : <SettingsForm {...props} />}
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default Settings;

Settings.defaultProps = {};
