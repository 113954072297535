import React, { useState } from 'react';
import { Card, List, Space, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import { CalendarOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Layout/Colors';
import AuditItem from '../Dashboard/AuditItem';
import { Member } from 'modules/orgs/interfaces';
import useUpcomingAuditsBySubGroup from 'modules/auditSchedule/useUpcomingAuditsBySubGroup';

export default function AuditWidgetUpcoming({
  orgMap,
  orgIds,
}: {
  orgMap: { [id: string]: { title: string; members: Member } };
  orgIds: string[];
}) {
  const history = useHistory();
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  const { audits, isLoading } = useUpcomingAuditsBySubGroup(orgIds);
  const [activeTab, setactiveTab] = useState('tab1');

  if (isLoading) {
    return (
      <Card
        title={t('dashboard.auditsWithIssues.title')}
        headStyle={{}}
        style={{
          width: '100%',
          height: 467,
          backgroundColor: '#fff',
          color: '#001628',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '370px',
          }}
        >
          <LoadingOutlined style={{ fontSize: 50 }} />
        </div>
      </Card>
    );
  }
  const tabList = [
    {
      key: 'tab1',
      tab: `Kommende måned`,
    },
  ];
  const list = audits.map((audit) => ({
    id: audit.id,
    title: audit.templateTitle,
    dueDate: audit.dueDate,
    orgId: audit.orgId,
  }));

  return (
    <Card
      title={
        <Space
          style={{
            display: 'flex',
            paddingLeft: 24,
            paddingRight: 16,
            paddingTop: 0,
            paddingBottom: 16,
          }}
        >
          <CalendarOutlined
            style={{
              display: 'flex',
              fontSize: 24,
              color: Colors.processing,
            }}
          />
          {t('dashboard.auditsTodo.title')}
        </Space>
      }
      tabList={tabList}
      tabProps={{
        size: 'small',
        style: { paddingLeft: 24, borderTop: '1px solid #f0f0f0' },
      }}
      onTabChange={setactiveTab}
      activeTabKey={activeTab}
      headStyle={{ padding: 0, color: '#001628' }}
      style={{
        width: '100%',
        backgroundColor: '#fff',
      }}
      bodyStyle={{
        height: 370,
        overflowY: 'scroll',
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div style={{ color: Colors.processing }}>
        <List
          itemLayout="horizontal"
          dataSource={list}
          locale={{
            emptyText: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 48,
                }}
              >
                <Typography.Title level={5}>{t('dashboard.auditsTodo.empty')}</Typography.Title>
              </div>
            ),
          }}
          renderItem={(item) => {
            const dueDate = item.dueDate?.toDate();
            return (
              <AuditItem
                key={item.id}
                // taskCount={0}
                color={Colors.processing}
                linkPath={`/orgs/${item.orgId}/audit/audits/${item.id}`}
                title={item.title}
                subTitle={orgMap?.[item.orgId]?.title}
                description={
                  <>
                    {t('dashboard.auditsTodo.deadlineFormat', {
                      dayOfWeek: dueDate,
                      dayOfMonth: dueDate,
                      month: dueDate,
                      time: dueDate,
                      relativeTime: dueDate,
                    })}
                  </>
                }
              />
            );
          }}
        />
      </div>
    </Card>
  );
}
