import { InventoryItem, InventoryType } from '../types';

export const GPS_INVENTORY_FETCH_REQUESTED = 'GPS_INVENTORY_FETCH_REQUESTED';
export const GPS_INVENTORY_FETCH_SUCCEEDED = 'GPS_INVENTORY_FETCH_SUCCEEDED';
export const GPS_INVENTORY_FETCH_FAILED = 'GPS_INVENTORY_FETCH_FAILED';

export interface FetchGPSInventorySuccess {
  type: typeof GPS_INVENTORY_FETCH_SUCCEEDED;
  payload: GPSItem[];
}
export interface FetchGPSInventory {
  type: typeof GPS_INVENTORY_FETCH_REQUESTED;
  payload: {};
}
export interface FetchGPSInventoryFailed {
  type: typeof GPS_INVENTORY_FETCH_FAILED;
  payload: {};
}

export interface GPSItem extends InventoryItem {
  identifier: string;
  type: InventoryType.GPS;
  orgId?: string;
  orgTitle?: string;
  sim: string | null;
  tags: string[];
}
