import React, { useEffect, useState } from 'react';
import { List, Button, Tooltip, Typography } from 'antd';
import { IOrg } from '../../../modules/orgs/interfaces';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'lodash';
import { AlertOutlined, PlusCircleOutlined } from '@ant-design/icons';
import useOrgProducts from './useOrgProducts';
import OrderModal from './OrderModal';
import useInCompleteOrgOrders from './useInCompleteOrgOrders';
import { Link } from 'react-router-dom';

export default function OrgCollapse({ orgs }: { orgs: IOrg[] }) {
  return <List dataSource={orgs} renderItem={(item) => <OrgPanel key={item.id} org={item} />} />;
}

function OrgPanel({ org }: { org: IOrg }) {
  const { t } = useTranslation();
  const { products, loadingProducts } = useOrgProducts(org.id);
  const { orders, loadingOrders } = useInCompleteOrgOrders(org.id);
  const unit = upperCase(org?.unit || '');
  const hasAlert = products.some((p) => p.storage <= org.storageAlert);
  const hasOrdersToConfirm = orders.length > 0;

  const [orgId, setOrgId] = useState<string | null>(null);
  const handleOpen = (id: string) => {
    setOrgId(id);
  };

  const handleClose = () => {
    console.log('Clicked cancel button');
    setOrgId(null);
  };

  return (
    <>
      <List
        style={{ marginBottom: 8 }}
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link style={{ fontWeight: 700 }} to={`/orgs/${org.id}/clean-logs`}>
              {org.title}
            </Link>
            <div style={{ display: 'flex' }}>
              {hasOrdersToConfirm && (
                <Button type="text" size="small" onClick={() => handleOpen(org.id)}>
                  <Typography.Text type="secondary">
                    (Bestilling afventer godkendelse)
                  </Typography.Text>
                </Button>
              )}
              {hasAlert && (
                <Tooltip title="Lav beholdning">
                  <AlertOutlined style={{ color: 'red', fontSize: 18 }} />
                </Tooltip>
              )}
            </div>
          </div>
        }
        loading={loadingProducts || loadingOrders}
        bordered
        locale={{ emptyText: t('Ingen produkter') }}
        dataSource={products}
        renderItem={(item) => (
          <List.Item
            key={item.id}
            actions={[
              <div
                style={{
                  color: item.storage <= org.storageAlert ? 'red' : 'inherit',
                }}
              >
                {item.storage} {unit}
              </div>,
              <div>
                <Tooltip title="Se bestillinger">
                  <Button
                    type="text"
                    size="small"
                    shape="circle"
                    icon={<PlusCircleOutlined style={{}} />}
                    onClick={() => handleOpen(org.id)}
                  />
                </Tooltip>
              </div>,
            ]}
          >
            {item.title}
          </List.Item>
        )}
      />
      {orgId && <OrderModal orgId={orgId} title={org.title} handleClose={handleClose} />}
    </>
  );
}
