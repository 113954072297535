export function getUniqueItemsBySubGroupId(data: any[]) {
  const uniqueItems = new Map();

  data.forEach((item) => {
    if (item?.subGroup?.id) {
      uniqueItems.set(item.subGroup.id, item);
    }
  });

  return Array.from(uniqueItems.values());
}

export function getUniqueItemsByGroupId(data: any[]) {
  const uniqueItems = new Map();

  data.forEach((item) => {
    if (item.groupId) {
      uniqueItems.set(item.groupId, item);
    }
  });

  return Array.from(uniqueItems.values());
}
