import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import AuditTemplateDetail from './AuditTemplateDetail';
import { useAppSelector } from '../../../hooks';
import {
  fetchAuditTemplate,
  fetchAuditTemplateTasks,
  updateAuditTemplate,
  addAuditTemplateTask,
  removeAuditTemplateFile,
} from '../../../modules/auditTemplates/actions';

function AuditTemplateDetailContainer() {
  const dispatch = useDispatch();

  const { orgId, auditTemplateId } = useParams<{
    orgId: string;
    auditTemplateId: string;
  }>();
  const { currentAuditTemplate, loading } = useAppSelector((state) => ({
    currentAuditTemplate: state.auditTemplates.currentAuditTemplate,
    loading: state.auditTemplates.loading,
  }));
  const currentAuditTemplateTasks = useAppSelector(
    (state) => state.auditTemplates.currentAuditTemplateTasks
  );
  useEffect(() => {
    dispatch(fetchAuditTemplate({ orgId, auditTemplateId }));
    dispatch(fetchAuditTemplateTasks({ orgId, auditTemplateId }));
  }, [orgId, auditTemplateId, dispatch]);

  const dispatchAddAuditTemplateTask = (title: string, order: number) => {
    dispatch(addAuditTemplateTask({ orgId, auditTemplateId, title, order }));
  };

  const dispatchUpdateAuditTemplate = (title: string) => {
    console.log({ title });

    dispatch(updateAuditTemplate({ orgId, auditTemplateId, title }));
  };
  const dispatchRemoveAuditTemplateFile = () => {
    dispatch(removeAuditTemplateFile({ orgId, auditTemplateId, guide: null }));
  };

  return (
    <AuditTemplateDetail
      id={currentAuditTemplate.id}
      templateTitle={currentAuditTemplate.title}
      guide={currentAuditTemplate.guide}
      note={currentAuditTemplate.note}
      createTime={currentAuditTemplate.createTime}
      auditTemplateCategory={currentAuditTemplate?.auditTemplateCategory ?? null}
      auditTemplateType={currentAuditTemplate?.auditTemplateType ?? null}
      tasks={currentAuditTemplateTasks}
      loading={loading}
      orgId={orgId}
      addAuditTemplateTask={dispatchAddAuditTemplateTask}
      updateAuditTemplate={dispatchUpdateAuditTemplate}
      removeAuditTemplateFile={dispatchRemoveAuditTemplateFile}
    />
  );
}

export default AuditTemplateDetailContainer;
