import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';
import * as Sentry from '@sentry/react';

const createHistory = require('history').createBrowserHistory;
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export type AppState = ReturnType<typeof rootReducer>;

export const history = createHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});
const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
  sentryReduxEnhancer
);

const store = createStore(rootReducer, initialState, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
