import moment from 'moment';
import keyBy from 'lodash.keyby';
import { TASKS_FETCH_REQUESTED, TASKS_FETCH_SUCCEEDED, TASKS_FETCH_FAILED } from './types';

const initialState = {
  tasks: [],
  loading: true,
};

export default function tasks(state = initialState, action) {
  switch (action.type) {
    case TASKS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case TASKS_FETCH_SUCCEEDED:
      return {
        ...state,
        tasks: action.payload.data,
        loading: false,
      };
    case TASKS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
