import { useEffect } from 'react';
import { useParams } from 'react-router';
import InventoryView from './index';
import Loader from '../../../components/Loader';

import { IOrg } from '../../../modules/orgs/interfaces';
import useInventoryIpads from './useInventoryIpads';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store';
import { fetchOrgs } from '../../../modules/orgs/actions';

interface IProps {
  loading: boolean;
  uid: string;
  email: string;
  orgs: IOrg[];
  addOrg: (uid: string, email: string, title: string) => void;
  updateOrg: ({ orgId, data }: { orgId: string; data: { isDeleted: boolean } }) => void;
}

function IPADInventoryContainer() {
  const disptach = useDispatch();
  const loading = false;
  const { ipads } = useInventoryIpads();
  const uid = useSelector((state: AppState) => state.user.details.uid);
  const hasOrgs = useSelector((state: AppState) => state.orgs.orgs?.length > 0);
  // useEffect(() => {
  //     if (!hasOrgs) {
  //         disptach(fetchOrgs(uid));
  //     }
  //     return () => {};
  // }, [uid]);

  if (loading) {
    return <Loader />;
  }
  return <InventoryView ipads={ipads} />;
}

export default IPADInventoryContainer;
