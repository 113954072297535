import { CaretRightFilled } from '@ant-design/icons';
import React from 'react';
import { getCompletionColor, getSummed } from './CategoryBlock';
import { decimalFormat } from './utils';

export default function OrgBlock({
  title,
  allCompleted,
  isPartialCompleted,
  moreSummed,
  children,
}: {
  title: string;
  allCompleted: boolean;
  isPartialCompleted: boolean;
  moreSummed: { [unit: string]: number }[];
  children: React.ReactNode;
}) {
  const [expanded, setExpanded] = React.useState(false);

  const summedForCategoryKeys = getSummed(moreSummed);

  const values = Object.entries(summedForCategoryKeys)?.map(([unit, value]: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          {decimalFormat.format(value)} {unit}
        </div>
      </div>
    );
  });
  return (
    <div style={{ paddingLeft: 16 }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          backgroundColor: getCompletionColor(isPartialCompleted, allCompleted),
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              transition: 'all 0.3s',
              transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
              transformOrigin: 'center',
            }}
          >
            <CaretRightFilled />
          </div>
          <div>{title}</div>
        </div>
        <div>{values}</div>
      </div>
      <div>{expanded && children}</div>
    </div>
  );
}
