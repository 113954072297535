export enum OrderStatus {
  awaiting = 'awaiting',

  processing = 'processing',

  confirmed = 'confirmed',

  completed = 'completed',
  voided = 'voided',
}
