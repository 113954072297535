import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import CleanLogList from './CleanLogList';
import { fetchCleanLogs, addCleanLog, updateCleanLog } from '../../modules/cleanLogs/actions';
import { fetchOrgProducts, updateOrgProduct } from '../../modules/products/actions';
import { useAppSelector } from '../../hooks';
import useCleanLogsOptions from '../../hooks/useCleanLogOptions';
import { Intl } from '../../general/enums';
import { mapIntlToi18n } from '../../general/intl';

function CleanLogListContainer() {
  const { loading: loadingOptions, options } = useCleanLogsOptions();
  const dispatch = useDispatch();
  const { orgId } = useParams<{ orgId: string }>();
  const { cleanLogs, loading, products, locale } = useAppSelector((state) => ({
    cleanLogs: state.cleanLogs.logs,
    loading: state.cleanLogs.loading,
    products: state.products.orgProducts,
    locale: mapIntlToi18n(state.user?.userDoc?.intl || Intl.da_DK),
  }));
  useEffect(() => {
    dispatch(fetchCleanLogs({ orgId }));
    dispatch(fetchOrgProducts(orgId));
  }, [orgId, dispatch]);

  return (
    <CleanLogList
      cleanLogs={cleanLogs}
      products={products}
      orgId={orgId}
      loading={loading || loadingOptions}
      cleanLogOptions={options}
      locale={locale}
      // addCleanLog={({ initials, amount, productId }: any) =>
      //     dispatch(addCleanLog({ orgId, initials, amount, productId }))
      // }
      updateCleanLog={({ logId, data }: any) => dispatch(updateCleanLog({ orgId, logId, data }))}
      updateOrgProduct={({ productId, data }: any) =>
        dispatch(updateOrgProduct({ orgId, productId, data }))
      }
    />
  );
}

export default CleanLogListContainer;
