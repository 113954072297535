import { DocumentData } from 'firebase/firestore';
import {
  AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED,
  AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED,
  COMPLETE_AUDIT_LIMIT,
  DBAuditShape,
  FieldConfig,
} from './types';
import {
  AUDIT_FETCH_REQUESTED,
  AUDIT_FETCH_SUCCEEDED,
  AUDIT_UPDATE_REQUESTED,
  AUDIT_UPDATE_SUCCEEDED,
  AUDIT_CREATE_REQUESTED,
  AUDIT_DELETE_REQUESTED,
  SET_CURRENT_TAB,
} from './types';

export function fetchAudit({ orgId, auditId }: { orgId: string; auditId: string }) {
  return {
    type: AUDIT_FETCH_REQUESTED,
    payload: { orgId, auditId },
  };
}

// @ts-ignore
export function fetchAuditSuccess(auditDoc: DocumentData) {
  const audit: DBAuditShape = { id: auditDoc.id, ...auditDoc.data() };

  return {
    type: AUDIT_FETCH_SUCCEEDED,
    payload: {
      data: audit,
    },
  };
}

export function addAudit(title: string) {
  return {
    type: AUDIT_CREATE_REQUESTED,
    payload: title,
  };
}

export function deleteAudit(id: string) {
  return {
    type: AUDIT_DELETE_REQUESTED,
    payload: id,
  };
}

export function updateAudit({
  orgId,
  auditId,
  data,
}: {
  orgId: string;
  auditId: string;
  data: any;
}) {
  return {
    type: AUDIT_UPDATE_REQUESTED,
    payload: { orgId, auditId, data },
  };
}

export function updateAuditSuccess() {
  return {
    type: AUDIT_UPDATE_SUCCEEDED,
    payload: {},
  };
}

export function setCurrentTab({ key }: { key: string }) {
  return {
    type: SET_CURRENT_TAB,
    payload: { key },
  };
}
export function setCompleteAuditLimit({ limit }: { limit: number }) {
  return {
    type: COMPLETE_AUDIT_LIMIT,
    payload: { limit },
  };
}

export function fetchAuditBaseCategories() {
  return {
    type: AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED,
    payload: {},
  };
}

export function fetchAuditBaseCategoriesSuccess(config: DocumentData) {
  const data: FieldConfig = config.data();
  return {
    type: AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED,
    payload: data,
  };
}
