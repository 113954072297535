import { SignInParams } from '../../hooks/useSignin';
import { PasswordResetParams } from '../../hooks/usePasswordReset';
import {
  GET_USER,
  SET_ONBOARDING,
  SET_ONBOARDING_STATE,
  SET_SEARCH_KEYS_FAILED,
  SET_SEARCH_KEYS_REQUESTED,
  SET_SEARCH_KEYS_SUCCEEDED,
  SearchKeys,
} from './types';
import {
  SIGNIN_REQUESTED,
  SIGNIN_FAILED,
  SIGNIN_SUCCEEDED,
  SIGNOUT_REQUESTED,
  SIGNOUT_SUCCEEDED,
  SIGNOUT_FAILED,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_SUCCEEDED,
  FORGOT_PASSWORD_FAILED,
  // SET_CURRENT_USER_PERMISSIONS,
} from './types';
import { RequestState } from 'interfaces/request';

export function login({ email, password }: SignInParams) {
  return {
    type: SIGNIN_REQUESTED,
    payload: {
      email,
      password,
    },
  };
}

export function loginSuccess() {
  return {
    type: SIGNIN_SUCCEEDED,
    payload: {},
  };
}

export function loginFailed({ message }: { message: string }) {
  return {
    type: SIGNIN_FAILED,
    payload: {
      error: message,
    },
  };
}

// SIGN OUT
export function signOut() {
  return {
    type: SIGNOUT_REQUESTED,
    payload: {},
  };
}

export function signOutSuccess() {
  return {
    type: SIGNOUT_SUCCEEDED,
    payload: {},
  };
}
export function signOutFailed({ message }: { message: string }) {
  return {
    type: SIGNOUT_FAILED,
    payload: {
      error: message,
    },
  };
}

// export function setPermissions(permissions) {
//     return {
//         type: SET_CURRENT_USER_PERMISSIONS,
//         payload: { ...permissions },
//     };
// }

// FORGOT PASSWORD
export function sendPasswordResetEmail({ email }: PasswordResetParams) {
  return {
    type: FORGOT_PASSWORD_REQUESTED,
    payload: { email },
  };
}

export function sendPasswordResetEmailSuccess() {
  return {
    type: FORGOT_PASSWORD_SUCCEEDED,
    payload: {
      success: 'Du burde nu modtage en email snart til at ændre dit password',
    },
  };
}
export function sendPasswordResetEmailFailure({ message }: { message: string }) {
  return {
    type: FORGOT_PASSWORD_FAILED,
    payload: {
      error: message,
    },
  };
}

export function getUser(user: any) {
  return {
    type: GET_USER,
    payload: {
      user,
    },
  };
}

export function setOnboarding(onboarding: { type: string } & any) {
  return {
    type: SET_ONBOARDING,
    payload: onboarding,
  };
}
export function setOnboardingState(requestState: RequestState) {
  return {
    type: SET_ONBOARDING_STATE,
    payload: requestState,
  };
}
export function setSearchKey(searchKeys: SearchKeys) {
  return {
    type: SET_SEARCH_KEYS_SUCCEEDED,
    payload: searchKeys,
  };
}
export function setSearchKeyRequestState(
  type: typeof SET_SEARCH_KEYS_REQUESTED | typeof SET_SEARCH_KEYS_FAILED
) {
  return { type };
}
