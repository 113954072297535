import firebase from '../../firebase';
import { useEffect, useState } from 'react';

export default function useOverdueAudits(orgId: string) {
  const [audits, setIssues] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    let unsubscribe: any = null;
    unsubscribe = firebase
      .firestore()
      .collection(`orgs`)
      .doc(orgId)
      .collection('auditSchedules')
      .where('isCompleted', '==', false)
      .where('dueDate', '<', new Date())
      .orderBy('dueDate', 'desc')
      .limit(100)
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setIssues(list);
        } else {
          setIssues([]);
        }
        setisLoading(false);
      });
    return unsubscribe;
  }, [orgId]);

  return { audits, isLoading };
}
