export const productUnitsMap = {
  '2JEu4U11Zg5ryT5pDL4q': [{ value: 1, unit: 'l', label: '1 liter' }],

  '50wpq535YdzqEUMjAXvs': [{ value: 5, unit: 'l', label: '5 liter' }],

  '88MaibBYhZbiNHbTiCnV': [
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  DdUfiOCUz8ivDb2hHAS4: [
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  EFHtddVRvlEGGXZvZtxe: [
    { value: 1, unit: 'l', label: '1 liter' },
    { value: 5, unit: 'l', label: '5 liter' },
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  FTYRAnmuERiWl2Zdnt7u: [
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  JVMhBRZktrE1SMBzMQsG: [
    { value: 1, unit: 'l', label: '1 liter' },
    { value: 5, unit: 'l', label: '5 liter' },
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  Obmt9nemJV0H9we37kRX: [{ value: 1000, unit: 'l', label: '1000 liter' }],

  R3zPzU7plaRC7EpwQgPN: [
    { value: 1, unit: 'l', label: '1 liter' },
    { value: 5, unit: 'l', label: '5 liter' },
  ],

  fU7z9iCuYzTgwRinLNXw: [
    { value: 1, unit: 'l', label: '1 liter' },
    { value: 5, unit: 'l', label: '5 liter' },
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  hUkSwgyT0YKynkv5YvXC: [
    { value: 1, unit: 'l', label: '1 liter' },
    { value: 5, unit: 'l', label: '5 liter' },
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  p2kUOLhR2s4HTubagy8U: [
    { value: 220, unit: 'l', label: '220 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],

  pdwMwqar2s4YtPt7TaBj: [
    { value: 25, unit: 'l', label: '25 liter' },
    { value: 100, unit: 'l', label: '100 liter' },
    { value: 1000, unit: 'l', label: '1000 liter' },
  ],
};
