import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Image, Tag, Tooltip } from 'antd';
import { Issue } from 'modules/issues/types';
import { StyleGuide } from 'styles/StyleGuide';
import { TagOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

export default function Item({ id, issue, orgId }: { issue: Issue; id: string; orgId: string }) {
  const history = useHistory();
  const [image, setImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  useEffect(() => {
    if (!issue?.files || issue?.files.length <= 0) return;

    setLoadingImage(true);
    firebase
      .storage()
      // @ts-ignore
      .ref(`orgs/${orgId}/issues/thumb_${issue.files[0]}`)
      // .child("images/stars.jpg")
      .getDownloadURL()
      .then((url) => {
        setImage(url);
      })
      .catch((error) => {
        // Handle any errors
        console.warn(error);
        if (error?.code === 'storage/object-not-found') {
          firebase
            .storage()
            // @ts-ignore
            .ref(`thumb_${issue.files[0]}`)
            // .child("images/stars.jpg")
            .getDownloadURL()
            .then((url) => {
              setImage(url);
            })
            .catch((error) => {
              // Handle any errors
              console.warn(error);
            });
        }
      })
      .finally(() => setLoadingImage(false));
  }, [id, orgId]);
  return (
    <Tooltip title={issue.description} placement="right">
      <div
        onClick={() => history.push(`/orgs/${orgId}/issues/${id}`)}
        style={{
          height: 24,
          bottom: 0,
          left: 0,
          width: '100%',
          paddingBottom: 14,
          marginBottom: 4,
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            position: 'relative',
            height: 24,
            display: 'flex',
            borderRadius: 4,
            justifyContent: 'space-between',
            width: '100%',
            overflow: 'hidden',
            backgroundColor: StyleGuide.palette.successLight,
            border: `1px solid ${StyleGuide.palette.successLight}`,
            verticalAlign: 'middle',
          }}
        >
          <div style={{ display: 'flex', flexGrow: 1, width: '90%' }}>
            <div>
              {image && <Image width={24} height={24} style={{ objectFit: 'cover' }} src={image} />}
            </div>

            {issue.description && (
              <div
                style={{
                  paddingTop: 3,
                  paddingLeft: 6,
                  color: StyleGuide.palette.successDark,
                  fontSize: 12,
                  width: '100%',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {issue.description}
              </div>
            )}
          </div>
          {issue?.displayTags?.length > 0 && (
            <div style={{ width: '10%' }}>
              <Tooltip
                color={StyleGuide.palette.grey0}
                title={issue.displayTags.map((tag) => (
                  <Tag color="blue">{tag}</Tag>
                ))}
              >
                <TagOutlined style={{ color: StyleGuide.palette.successDark }} />
                {/* {issue.displayTags.map(tag => (
                                <Tag>{tag}</Tag>
                            ))} */}
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
}
