import React, { ClipboardEventHandler, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, Select } from 'antd';
import Icon, { LockOutlined, UserOutlined } from '@ant-design/icons';
import { SignInParams } from '../../hooks/useSignin';
import GbFlag from '../../assets/flags/gb';
import { useParams } from 'react-router';
import { useQuery } from './useQuery';
const FormItem = Form.Item;
const { Option } = Select;

interface Props {
  loading: boolean;
  error: any;
  login: (params: SignInParams) => void;
}

function NormalLoginForm(props: Props) {
  const [form] = Form.useForm();
  const { email, password } = useQuery();
  const handleSubmit = ({ email, password }: SignInParams) => {
    props.login({ email: email.trim(), password });
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      email: e.target.value.trim(),
    });
  };

  return (
    <div className="login-form-container">
      <Form
        initialValues={{ email, password }}
        form={form}
        onFinish={handleSubmit}
        className="login-form"
      >
        <FormItem
          name="email"
          rules={[
            { type: 'email', message: 'Indtast en gyldig e-mail' },
            { required: true, message: 'Udfyld e-mail!' },
          ]}
        >
          <Input
            onChange={handleChangeEmail}
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="E-mail"
          />
        </FormItem>
        <FormItem
          name="password"
          style={{ marginBottom: 0 }}
          rules={[{ required: true, message: 'Udfyld password!' }]}
        >
          <Input
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </FormItem>
        <FormItem style={{ margin: 0 }}>
          <Link to="/forgot-password" className="login-form-forgot">
            Glemt password?
          </Link>
        </FormItem>
        <FormItem name="remember" valuePropName="checked">
          <Checkbox>Husk mig</Checkbox>
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="login-form-button"
            loading={props.loading}
          >
            Log ind
          </Button>
        </FormItem>
        {props.error && <div style={{ color: 'red', lineHeight: '18px' }}>{props.error}</div>}
      </Form>
    </div>
  );
}

export default NormalLoginForm;
