import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message, Tag } from 'antd';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { post } from '../../../general/api';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import {
  InventoryItem,
  InventoryStatus,
  InventoryStatusMap,
  InventoryContextForType,
  InventoryType,
} from '../../../modules/inventory/types';
import Message, { Type } from '../../../components/Notification/Message';
import TextArea from 'antd/es/input/TextArea';
import { GPSItem } from 'modules/inventory/gps/types';
import confirmModal from 'general/confirmModal';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  wrapperCol: { span: 24 },
};
interface Props {
  id: string;
  onClose: () => void;
  inventoryTagsMap: { [id: string]: { id: string; title: string } };
  simcards: InventoryContextForType<InventoryType.SIM_CARDS>[];
}

export default function EditForm({ id, onClose, inventoryTagsMap, simcards }: Props) {
  const [form] = Form.useForm();
  const ipad = useSelector((state: AppState) => state.inventory.inventoryMap[id]) as GPSItem;
  const orgs = useSelector((state: AppState) =>
    state.orgs.orgs
      .map(({ id, title, groupId }: { id: string; title: string; groupId?: string | null }) => ({
        id,
        title,
        groupId,
      }))
      .sort((a: any, b: any) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1))
  );
  const orgMap = useSelector((state: AppState) => state.orgs.orgMap);
  const groupMap = useSelector((state: AppState) => state.groups.groupsMap);

  const initialValues = ipad;
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const updateInventoryItem = async (
    values: Partial<InventoryContextForType<InventoryType.GPS>>
  ) => {
    // remove key as it will not be store like this
    const orgId = values.orgId;
    if (!orgId || !id) return alert('something went wrong');
    delete values.orgId;
    try {
      Message({
        key: 'editInventory',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      const sim = simcards.find((sim) => sim.id === values.sim);

      // @ts-ignore
      // if (sim?.connection?.deviceId !== id) {
      // confirmModal({
      //     title: "Simcard is already connected to another device?",
      //     onOk: async () => {
      const batch = firebase.firestore().batch();
      const inventoryRef = firebase.firestore().collection('inventory').doc(id);
      batch.set(
        inventoryRef,
        {
          ...values,
          org: {
            id: orgId,
            // @ts-ignore
            title: orgMap[orgId]?.title,
          },
          sim: values.sim || null,
          simcard: {
            id: values.sim || null,
            iccid: sim?.iccid || null,
          },
          tags: values.tags || [],
          note: values.note || '',
          updateTime: new Date(),
        },
        { merge: true }
      );
      if (values.sim) {
        const simRef = firebase.firestore().collection('inventory').doc(values.sim);
        batch.set(
          simRef,
          {
            connection: {
              deviceId: id,
              title: values.identifier,
              type: InventoryType.GPS_FMT100,
            },
            updateTime: new Date(),
          },
          { merge: true }
        );
      }
      await batch.commit();
      onClose();
      Message({
        key: 'editInventory',
        message: `Alt blev opdateret korrekt`,
      });
      //     },
      // });
      // }
    } catch (error) {
      console.error(error);
      Message({
        key: 'editInventory',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke opdatere GPS, prøv venligst igen`,
      });
    }
  };

  const options = simcards.map(({ id, iccid, connection }) => {
    const title = connection?.title;
    const type = connection?.type;
    return {
      label: `${iccid} - (${title}) - (${type})`,
      value: id,
    };
  });

  return (
    <>
      <Form
        form={form}
        onFinish={(values) => updateInventoryItem(values)}
        initialValues={initialValues}
      >
        <FormItem
          {...formItemLayout}
          name="identifier"
          rules={[
            {
              required: true,
              message: 'Please input serial!',
            },
          ]}
        >
          <Input type="text" placeholder={t('inventory.ipad.serial')} disabled />
        </FormItem>
        <FormItem
          {...formItemLayout}
          name="name"
          rules={[{ required: true, message: 'Please input name!' }]}
        >
          <Input type="text" placeholder={t('inventory.ipad.name')} />
        </FormItem>

        <FormItem
          {...formItemLayout}
          name="sim"
          rules={[{ required: false, message: 'Please input name!' }]}
        >
          <Select
            showSearch
            allowClear
            placeholder={t('inventory.ipad.sim')}
            optionFilterProp="children"
            filterOption={(input, option) =>
              // @ts-ignore
              (option?.label ?? '').includes(input)
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                // @ts-ignore
                .toLowerCase()
                // @ts-ignore
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={options}
          />
          {/* {options.map(({ label, value }) => (
                            <Option value={value}>{label}</Option>
                        ))}
                    </Select> */}
        </FormItem>

        <FormItem
          {...formItemLayout}
          style={{ minWidth: '150px' }}
          name="status"
          rules={[{ required: true, message: 'Please select status!' }]}
        >
          <Select placeholder={t('access.add.role_placeholder')}>
            <Option value={InventoryStatus.NEEDS_ORDER}>
              <Tag color="red">{InventoryStatusMap[InventoryStatus.NEEDS_ORDER]}</Tag>
            </Option>
            <Option value={InventoryStatus.ORDERED}>
              <Tag color="gold">{InventoryStatusMap[InventoryStatus.ORDERED]}</Tag>
            </Option>
            <Option value={InventoryStatus.IN_STORAGE}>
              <Tag color="orange">{InventoryStatusMap[InventoryStatus.IN_STORAGE]}</Tag>
            </Option>
            <Option value={InventoryStatus.READY}>
              <Tag color="blue">{InventoryStatusMap[InventoryStatus.READY]}</Tag>
            </Option>
            <Option value={InventoryStatus.ASSIGNED}>
              <Tag color="green">{InventoryStatusMap[InventoryStatus.ASSIGNED]}</Tag>
            </Option>
          </Select>
        </FormItem>
        <FormItem
          {...formItemLayout}
          style={{ minWidth: '150px' }}
          name="tags"
          rules={[{ required: false, message: 'Please select status!' }]}
        >
          <Select placeholder={t('Tags')} mode="multiple">
            {Object.entries(inventoryTagsMap).map(([key, value]) => (
              <Option value={key}>{value.title}</Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          {...formItemLayout}
          style={{ minWidth: '150px' }}
          name="orgId"
          rules={[{ required: true, message: 'Please select company!' }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Vælg firma"
            optionFilterProp="children"
            filterOption={(input, option) =>
              // @ts-ignore
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {orgs.map((org: { id: string; title: string; groupId?: string | null }) => (
              <Option value={org.id}>
                {`${org.title} ${org?.groupId ? `(${groupMap[org.groupId]?.title})` : ''}`}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem {...formItemLayout} name="note" rules={[{ required: false }]}>
          <TextArea placeholder={t('Note')} />
        </FormItem>
        <FormItem>
          <Button type="primary" block htmlType="submit" loading={false}>
            {'Gem'}
          </Button>
        </FormItem>
      </Form>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </>
  );
}
