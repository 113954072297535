import { call, put, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
// import fb from "firebase";
import firebase, { reduxSagaFirebase } from '../../firebase';
import { GeoPoint } from 'firebase/firestore';

import {
  fetchSensorLogsSuccess,
  fetchSensorsSuccess,
  fetchSensorSuccess,
  fetchSensorMeasuresSuccess,
} from './actions';
import {
  SENSORLOGS_FETCH_SUCCEEDED,
  SENSORLOGS_FETCH_FAILED,
  SENSORLOGS_FETCH_REQUESTED,
  SENSORS_FETCH_SUCCEEDED,
  SENSORS_FETCH_FAILED,
  SENSORS_FETCH_REQUESTED,
  SENSOR_FETCH_SUCCEEDED,
  SENSOR_FETCH_FAILED,
  SENSOR_FETCH_REQUESTED,
  SENSOR_CREATE_SUCCEEDED,
  SENSOR_CREATE_FAILED,
  SENSOR_CREATE_REQUESTED,
  SENSORMEASURES_FETCH_SUCCEEDED,
  SENSORMEASURES_FETCH_FAILED,
  SENSORMEASURES_FETCH_REQUESTED,
} from './types';

function* fetchSensorLogs({ payload }) {
  const { orgId, sensorId, selectedDate } = payload;

  const today = new Date(selectedDate).setHours(0, 0, 0, 0);
  const tomorrow = moment
    .utc(selectedDate)
    .add(1, 'days')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toISOString();

  try {
    const sensorLogs = yield call(
      reduxSagaFirebase.firestore.getCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/sensors/${sensorId}/logs`)
        .where('logTime', '>', new Date(today))
        .where('logTime', '<', new Date(tomorrow))
        .orderBy('logTime')
    );

    yield put(fetchSensorLogsSuccess(sensorLogs));
  } catch (e) {
    yield put({ type: SENSORLOGS_FETCH_FAILED, message: e.message });
  }
}

export function* fetchSensorLogsSaga() {
  yield takeLatest(SENSORLOGS_FETCH_REQUESTED, fetchSensorLogs);
}

function* fetchSensors({ payload }) {
  const { orgId } = payload;
  try {
    const sensors = yield call(
      reduxSagaFirebase.firestore.syncCollection,
      `orgs/${orgId}/sensors`,
      { successActionCreator: fetchSensorsSuccess }
    );
  } catch (e) {
    yield put({ type: SENSORS_FETCH_FAILED, message: e.message });
  }
}

export function* fetchSensorsSaga() {
  yield takeLatest(SENSORS_FETCH_REQUESTED, fetchSensors);
}

function* fetchSensor({ payload }) {
  const { orgId, sensorId } = payload;
  try {
    const sensors = yield call(
      reduxSagaFirebase.firestore.syncDocument,
      `orgs/${orgId}/sensors/${sensorId}`,
      { successActionCreator: fetchSensorSuccess }
    );
  } catch (e) {
    yield put({ type: SENSOR_FETCH_FAILED, message: e.message });
  }
}

export function* fetchSensorSaga() {
  yield takeLatest(SENSOR_FETCH_REQUESTED, fetchSensor);
}

function* addSensor(action) {
  const { orgId, sensorType, sensorTitle, latitude, longitude } = action.payload;
  try {
    const sensor = yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/sensors`, {
      title: sensorTitle,
      type: sensorType,
      location: GeoPoint(latitude, longitude),
      createTime: new Date(),
    });
    yield put({ type: SENSOR_CREATE_SUCCEEDED });
  } catch (e) {
    yield put({ type: SENSOR_CREATE_FAILED, message: e.message });
  }
}

export function* addSensorSaga() {
  yield takeLatest(SENSOR_CREATE_REQUESTED, addSensor);
}

function* fetchSensorMeasures({ payload }) {
  const { orgId, sensorId } = payload;
  try {
    const sensorMeasures = yield call(
      reduxSagaFirebase.firestore.getCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/sensors/${sensorId}/measures`)
        .where('createTime', '>', new Date('2019-01-01'))
        .orderBy('createTime', 'desc')
        .limit(100)
    );

    yield put(fetchSensorMeasuresSuccess(sensorMeasures));
  } catch (e) {
    console.error(e);
    yield put({ type: SENSORMEASURES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchSensorMeasuresSaga() {
  yield takeLatest(SENSORMEASURES_FETCH_REQUESTED, fetchSensorMeasures);
}
