import React, { useEffect } from 'react';
import { DBDirectoryShape } from '../../modules/directories/types';
import { Col, Card, Popconfirm, message, Dropdown, Menu, Typography, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useSelector } from 'react-redux';
import { updateReport } from '../../modules/reports/actions';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  DownloadOutlined,
  DeleteOutlined,
  FilePdfOutlined,
  FileImageOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
} from '@ant-design/icons';
import { fromNow } from '../../general/dates';
import { AppState } from '../../store';
import { useDispatch } from 'react-redux';
import confirmModal from '../../general/confirmModal';
import { DownloadReport, OpenFileInBrowser } from './ListItems';
import { MenuProps } from 'antd/lib';
import useGetDownloadUrl from 'hooks/useGetDownloadUrl';
import Loader from 'components/Loader';
import { StyleGuide } from 'styles/StyleGuide';
import { ContentType } from 'general/enums';

const { Paragraph } = Typography;

function ImageStorage({ filePath, style }: { filePath: string; style: any }) {
  const { loading, fileUrl } = useGetDownloadUrl(filePath);
  if (loading) return <Loader />;
  console.log(fileUrl);
  return (
    <div
      style={{
        ...style,
        background: `url(${fileUrl}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    />
  );
}

export const getContentTypeIconMap = (type: string, path: string, style: {}) => {
  switch (type) {
    case 'application/pdf':
      return (
        <FilePdfOutlined
          style={{
            ...style,
            alignSelf: 'center',
            justifySelf: 'center',
            color: StyleGuide.palette.redish,
          }}
        />
      );

    case 'image/jpeg':
    case 'image/jpg':
    case 'image/png':
      return <ImageStorage filePath={path} style={style} />;

    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return <FileWordOutlined style={{ ...style, color: StyleGuide.palette.info }} />;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return <FileExcelOutlined style={{ ...style, color: StyleGuide.palette.green }} />;

    default:
      return <FileExclamationOutlined style={style} />;
  }
};

// const MenuDirectories = ({
//     directories,
//     updateReport,
//     orgId,
//     reportId,
//     path,
//     title,
//     fileName,
//     contentType,
// }: {
//     directories: DBDirectoryShape[];
//     updateReport: any;
//     orgId: string;
//     reportId: string;
//     path: string;
//     title: string;
//     fileName: string;
//     contentType: string;
// }) => {
//     const { t } = useTranslation();
//     console.log({ directories });
//     const confirmDelete = () => {
//         confirmModal({
//             title: "Er du sikker på du vil slette denne fil?",
//             onOk: () => {
//                 updateReport({
//                     orgId,
//                     reportId,
//                     data: { isDeleted: true },
//                 });
//             },
//         });
//     };
//     return (
//         <Menu>
//             {directories.map(directory => (
//                 <Menu.Item
//                     key={directory.id}
//                     onClick={() =>
//                         updateReport({
//                             orgId,
//                             reportId,
//                             data: { directoryId: directory.id },
//                         })
//                     }>
//                     <div>
//                         {t("reports.file_menu_move")}: <b>{directory.title}</b>
//                     </div>
//                 </Menu.Item>
//             ))}

//             <DownloadReport
//                 path={filePath}
//                 title={title}
//                 fileName={fileName}
//                 contentType={contentType}
//             />

//             <Menu.Item key={`delete-${reportId}`} onClick={confirmDelete}>
//                 <div style={{ color: "red" }}>
//                     <DeleteOutlined /> {t("reports.file_menu_delete")}
//                 </div>
//             </Menu.Item>
//         </Menu>
//     );
// };

function DeleteReport({ onDelete }: any) {
  return (
    <Popconfirm
      title="Er du sikker på du vil slette denne report?"
      onConfirm={() => {
        onDelete();
        message.success('Report slettet');
      }}
      okText="Ja"
      cancelText="Nej"
    >
      <DeleteOutlined />
    </Popconfirm>
  );
}

interface IReportProps {
  id: string;
  orgId: string;
  title: string;
  fileName: string;
  filePath: string;
  // @ts-ignore
  createTime: firebase.firestore.Timestamp;
  path: string;
  contentType: ContentType;
  directories: DBDirectoryShape[];
  updateReport: ({
    orgId,
    reportId,
    data: { title },
  }: {
    orgId: string;
    reportId: string;
    data: { title?: string; isDeleted?: boolean };
  }) => void;
}

function Report({
  id,
  orgId,
  title,
  fileName,
  filePath,
  createTime,
  contentType,
  directories,
}: IReportProps) {
  console.log({ filePath });
  const { t } = useTranslation();
  const intl = useSelector((state: AppState) => state.user.userDoc.intl);
  const dispatch = useDispatch();
  const onUpdateReport = (p: {
    orgId: string;
    reportId: string;
    data: { directoryId?: string; title?: string; isDeleted?: boolean };
  }) => dispatch(updateReport(p));
  console.log({ contentType });

  const confirmDelete = () => {
    confirmModal({
      title: 'Er du sikker på du vil slette denne fil?',
      onOk: () => {
        onUpdateReport({
          orgId,
          reportId: id,
          data: { isDeleted: true },
        });
      },
    });
  };
  const items: MenuProps['items'] = [
    {
      key: '2',
      label: t('reports.file_menu_move'),
      // @ts-ignore
      children: directories.map((directory) => ({
        key: directory.id,
        label: (
          <div
            key={directory.id}
            onClick={() =>
              onUpdateReport({
                orgId,
                reportId: id,
                data: { directoryId: directory.id },
              })
            }
          >
            <div>
              {t('reports.file_menu_move')}: <b>{directory.title}</b>
            </div>
          </div>
        ),
      })),
    },
    {
      key: 'download',
      label: (
        <DownloadReport
          path={filePath}
          title={title}
          fileName={fileName}
          contentType={contentType}
        />
      ),
    },
    {
      key: 'open',
      label: (
        <OpenFileInBrowser
          path={filePath}
          title={title}
          fileName={fileName}
          contentType={contentType}
        />
      ),
    },
    {
      key: 'delete',
      label: (
        <div style={{ color: 'red' }} onClick={confirmDelete}>
          <DeleteOutlined /> {t('reports.file_menu_delete')}
        </div>
      ),
    },
  ];

  return (
    <Col key={id} md={6} xs={24}>
      <Card
        hoverable
        cover={
          getContentTypeIconMap(contentType, filePath, {
            marginTop: 20,
            fontSize: 100,
          })
          // <Icon
          //     style={}
          //     // @ts-ignore
          //     type={ || "file-exclamation"}
          //     theme="twoTone"
          // />
        }
        // actions={[
        //     <DeleteReport
        //         onDelete={() => {
        //             updateReport({
        //                 orgId,
        //                 reportId: id,
        //                 data: { isDeleted: true },
        //             });
        //         }}
        //     />,
        //     <DownloadReport path={path} />,
        // ]}
        extra={
          <Dropdown menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}>
              {t('reports.folder_menu')} <MenuOutlined />
            </a>
          </Dropdown>
        }
      >
        <Meta
          title={
            <Paragraph
              ellipsis
              // @ts-ignore
              editable={{
                onChange: (string: string) => {
                  onUpdateReport({
                    orgId,
                    reportId: id,
                    data: { title: string },
                  });
                },
              }}
            >
              {title}
            </Paragraph>
          }
          description={`${t('reports.file_createtime')}: ${fromNow(createTime.toDate(), intl)} ${t(
            'reports.file_createtimeend'
          )}`}
        />
      </Card>
    </Col>
  );
}

export default Report;
