import React from 'react';
import { Modal } from 'antd';
import AddGroupOrderForm from './AddGroupOrderForm';
import GroupOrdersTable from './GroupOrderTable';

export default function GroupOrdersModal({
  groupId,
  title,
  open,
  handleClose,
}: {
  groupId: string;
  title: string;
  open: boolean;
  handleClose: () => void;
}) {
  const handleCancel = () => {
    console.log('Clicked cancel button');
    handleClose();
  };
  return (
    <Modal
      title={`Bestillinger til ${title}`}
      open={open}
      footer={[]}
      // onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width="100%"
    >
      <div>
        <GroupOrdersTable groupId={groupId} />
        <div style={{ margin: 8 }} />
        <AddGroupOrderForm groupId={groupId} />
      </div>
    </Modal>
  );
}
