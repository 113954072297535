import { Button } from 'antd';
import { useLocation, useParams } from 'react-router';
import firebase from 'firebase/compat/app';
import { RequestState } from 'interfaces/request';
import Message, { Type } from 'components/Notification/Message';
import { useAppSelector } from 'hooks';
import keyBy from 'lodash.keyby';
import confirmModal from 'general/confirmModal';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import useIsReadonlyPath from 'hooks/useIsReadonlyPath';

const messageKey = 'SyncAuditTemplate';

export default function SyncAuditTemplate() {
  const isReadOnly = useIsReadonlyPath();
  const { t } = useTranslation();
  const { orgId, auditTemplateId } = useParams<{
    orgId: string;
    auditTemplateId: string;
  }>();
  const currentAuditTemplate = useAppSelector((state) => state.auditTemplates.currentAuditTemplate);
  const currentAuditTemplateTasks = useAppSelector((state) =>
    keyBy(state.auditTemplates.currentAuditTemplateTasks, 'id')
  );

  const isUpdating = useAppSelector((state) => {
    if (state.auditTemplates.auditTemplateSync?.status === RequestState.PENDING) {
      return true;
    }
    return false;
  });

  const onSyncConfirm = () => {
    confirmModal({
      title: t('audit.templates.sync.confirm.title'),
      content: (
        <>
          <div>{t('audit.templates.sync.confirm.content')}</div>
          <ol style={{ paddingLeft: 16 }}>
            <li>{t('audit.templates.sync.confirm.item1')}</li>
            <li>{t('audit.templates.sync.confirm.item2')}</li>
            <li>{t('audit.templates.sync.confirm.item3')}</li>
          </ol>
        </>
      ),
      onOk: () => {
        onSyncTemplate();
      },
    });
  };

  const onSyncTemplate = async () => {
    try {
      if (!orgId || !auditTemplateId) {
        throw new Error('orgId or auditTemplateId is undefined');
      }
      if (currentAuditTemplate.id !== auditTemplateId) {
        throw new Error('currentAuditTemplate.id !== auditTemplateId');
      }
      Message({
        key: messageKey,
        type: Type.LOADING,
        message: 'Syncing template',
      });
      const docId = `${orgId}_${auditTemplateId}`;
      await firebase
        .firestore()
        .collection('auditTemplateSync')
        .doc(docId)
        .set({
          createTime: new Date(),
          orgId,
          auditTemplateId,
          dataToSync: {
            guide: currentAuditTemplate.guide ?? null,
            templateTitle: currentAuditTemplate.title,
            templateNote: currentAuditTemplate.note || '',
            tasks: currentAuditTemplateTasks,
            // has to convert to a date on backend
            // notifyDaysAfterDueDate:
            //     currentAuditTemplate.notifyDaysAfterDueDate ?? null,
            auditTemplateCategory: currentAuditTemplate.auditTemplateCategory ?? null,
            auditTemplateType: currentAuditTemplate.auditTemplateType ?? null,
          },
          status: RequestState.PENDING,
        });
      Message({
        key: messageKey,
        type: Type.SUCCESS,
        message: t('audit.templates.sync.success'),
      });
    } catch (e) {
      console.error(e);
      Message({
        key: messageKey,
        type: Type.ERROR,
        message: 'Error syncing template',
      });
    }
  };
  return (
    // <AdminOnly>
    <Button
      disabled={isReadOnly || isUpdating}
      icon={<SyncOutlined />}
      block
      onClick={onSyncConfirm}
      type="primary"
    >
      {t('audit.templates.sync.button')}
    </Button>
    // </AdminOnly>
  );
}
