import type { MasterTask, Location, TaskMatrix } from '../types';

export const locations: Location[] = [
  {
    id: '6idUMtJzc2QvLXLxGoN1',
    name: 'RGS Øst - Vemmelev - Sikkerhed',
    latestAuditDate: new Date('2025-07-01'),
  },
  {
    id: '7iY45LhOYDPAkGzr8GC7',
    name: 'RGS Øst - Vemmelev - maskiner',
    latestAuditDate: new Date('2025-07-01'),
  },
  // { id: 'loc2', name: 'RGS - Gadstrup', latestAuditDate: new Date('2023-07-15') },
  // { id: 'loc3', name: 'RGS - Rødby', latestAuditDate: new Date('2023-07-20') },
  // { id: 'loc4', name: 'RGS - Sanderødgård', latestAuditDate: new Date('2023-07-25') },
  // { id: 'loc5', name: 'RGS - Vemmelev', latestAuditDate: new Date('2023-07-30') },
];

export const masterTaskList: MasterTask[] = [
  {
    id: 'task1',
    label: 'Ledelsessystem',
    subTasks: [{ id: '1', label: 'Er der kendskab til ledelsessystem?', description: '' }],
    category: 'Information',
  },
  {
    id: 'task2',
    label: 'Registereringer',
    subTasks: [
      { id: '2', label: 'Er der kendskab til registreringer' },
      { id: '3', label: 'Er der åbne registreringer? (Bortset fra førnævnte)' },
    ],
    category: 'Information',
  },
  {
    id: 'task3',
    label: 'Kortlægning',
    subTasks: [{ id: '4', label: 'Hvor findes den? Og er miljøkortlægningen opdateret?' }],
    category: 'Miljømål?',
  },
  {
    id: 'task4',
    label: 'Oplæring/instruktion',
    subTasks: [
      { id: '5', label: 'Oplæring af nye medarbejdere (fastansatte/timelønnet)' },
      {
        id: '6',
        label:
          'Har medarbejdere den fornødne uddannelse? Tjekkes Site Manager er forpligtet til at afhjælpe afvigelser og observationer inden aftalt deadline. Som udgangspunkt 1 måned efter dato for auditrapport fx kørekort?',
      },
      { id: '7', label: 'Adgang til brugsanvisning til alle maskiner?' },
    ],
    category: 'Information',
  },
  {
    id: 'task5',
    label: 'Indkøb kemi (Würth)',
    subTasks: [{ id: '8', label: 'Er der kendskab til liste over kemiprodukter "Bruttoliste"?' }],
    category: 'Kemikalier',
  },
  {
    id: 'task6',
    label: 'Værnemidler',
    subTasks: [{ id: '9', label: 'Kendes procedure 1.17? Personlige værnemidler' }],
    category: 'Beredskab',
  },
  {
    id: 'task7',
    label: 'SDS+APV',
    subTasks: [
      { id: '10', label: 'Findes de nødvendige værnemidler?' },
      { id: '11', label: 'Er der adgang til sikkerhedsdatablade?' },
      { id: '12', label: 'Er der adgang til kemisk APV?' },
    ],
    category: 'Arbejdsmiljø',
  },
  {
    id: 'task8',
    label: 'APV',
    subTasks: [
      { id: '13', label: 'Kan APV for medarbejdere findes?' },
      { id: '14', label: 'Findes der handleplaner som der ikke er fulgt op på?' },
    ],
    category: 'Arbejdsmiljø',
  },
  {
    id: 'task9',
    label: 'Beredskabsplan',
    subTasks: [
      { id: '15', label: 'Er beredskabsplan opdateret?' },
      { id: '16', label: 'Er brandslukkere efterset?' },
      {
        id: '17',
        label:
          'Er sikkerhedsoversigt (kort over brandslukkere, mødested osv.) opdateret? Findes skilt over mødested?',
      },
    ],
    category: 'Beredskab',
  },
  {
    id: 'task10',
    label: 'Eftersyn af maskiner og tilbehør',
    subTasks: [{ id: '18', label: 'Er maskiner og andet kørende materiel efterset?' }],
    category: 'Maskiner og tekniske hjælpemidler',
  },
  {
    id: 'task11',
    label: 'Miljømål',
    subTasks: [
      { id: '19', label: 'Er der kendskab til de 4 miljømål?' },
      { id: '20', label: 'Er der opmærksomhed på at nyttiggøre vand' },
      { id: '21', label: 'Er der tiltag indenfor besparelse af el/diesel?' },
      { id: '22', label: 'Er der maskiner som med fordel kan omskiftes til el?' },
    ],
    category: 'Miljømål?',
  },
].sort((a, b) => a.label.localeCompare(b.label));

export const taskMatrix: TaskMatrix = {
  task1: {
    '6idUMtJzc2QvLXLxGoN1': { status: 'passed', followUpTasks: [] },
  },
  task2: {},
  task3: {},
  task4: {},
  task5: {},
  task6: {},
  task7: {},
  task8: {},
  task9: {},
  task10: {
    '6idUMtJzc2QvLXLxGoN1': { status: 'passed', followUpTasks: [] },
  },
  task11: {
    '6idUMtJzc2QvLXLxGoN1': { status: 'passed', followUpTasks: [] },
  },
};
