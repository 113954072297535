import React, { useState, useEffect } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';

import { SET_CURRENT_USER } from './modules/user/types';
import Routes from './routes/index';
import app from './firebase';
import firebase from 'firebase/compat/app';
import { useDispatch } from 'react-redux';
import useGroups from './modules/groups/useGroups';
import { useAppSelector } from './hooks';
import { useFeatures } from './modules/featureControl/hooks';
import { fetchProducts } from './modules/products/actions';
import { fetchOrgs } from './modules/orgs/actions';
import useOnboarding from 'hooks/useOnboarding';
import ModalContainer from 'components/Modal';
import { fetchAuditBaseCategories } from 'modules/audits/actions';
import useSearchKeys from 'hooks/useUserSearchKeys';
import PageLoader from 'components/Loader/PageLoader';
// import { TourRefsProvider } from "context/Guide";
// import WeatherAppComp from "components/Weather";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        {/* <TourRefsProvider> */}
        <Initialise render={() => <Routes />} />
        <ModalContainer />
        {/* </TourRefsProvider> */}

        {/* <WeatherAppComp /> */}
      </Provider>
    </div>
  );
}

export default App;

function Initialise({ render }: { render: () => JSX.Element }) {
  const { isLoadingGroups } = useGroups();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userId = useAppSelector((state) => state.user.details.uid);
  useFeatures();
  useOnboarding();
  useSearchKeys();
  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchAuditBaseCategories());
  }, [dispatch]);
  useEffect(() => {
    if (userId) {
      dispatch(fetchOrgs(userId));
    }
    return () => {};
  }, [userId, dispatch]);

  useEffect(() => {
    app.auth().onAuthStateChanged((user: firebase.User | null) => {
      if (user) {
        console.log({ user });

        dispatch({ type: SET_CURRENT_USER, user });
        // setUser(user);
        setIsLoading(false);
      } else {
        // setUser(null);
        setIsLoading(false);
      }
    });

    return () => {};
  }, []);

  // useEffect(() => {
  //     console.log(window.location.pathname);
  //     if (userId && !isLoadingGroups) {
  //         console.log("hello");
  //         // console.log(groups?.length);
  //         if (
  //             window.location.pathname === "/sign-in" ||
  //             window.location.pathname === "/"
  //         ) {
  //             console.log({ hasGroups });
  //             if (hasGroups) {
  //                 window.location.href = "/groups";
  //             } else {
  //                 window.location.href = "/depts";
  //             }
  //         }
  //     }
  // }, [isLoadingGroups, userId, hasGroups]);
  // (!user && isLoading) || (user && isLoadingGroups)
  /**
   * If the user is not set yet and still loading the user auth show the loader
   * If the user is set and still loading the groups show the loader
   */
  if ((!userId && isLoading) || (userId && isLoadingGroups)) {
    return <PageLoader />;
  }
  return render();
}
