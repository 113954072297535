import { useState, useCallback, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { OrderStatusEnum } from '../lib/types';
import { functions } from '../../../firebase';

interface QRStatusRequest {
  requestType: 'LOAD_ORDER_STATUS';
  orderId: string;

  // add more parameters if needed
}

interface QRStatusResponse {
  status: string;
  orderId: string;
  availableStatuses: OrderStatusEnum[];
  // add more fields as needed
}

interface UseQRStatusReturn {
  data: QRStatusResponse | null;
  loading: boolean;
  error: Error | null;
}

export const useQRStatus = (orderId: string): UseQRStatusReturn => {
  const [data, setData] = useState<QRStatusResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  async function getStatus(orderId: string) {
    try {
      if (!orderId) {
        throw new Error('Order ID is required');
      }
      const qrStatusCallable = httpsCallable<QRStatusRequest, QRStatusResponse>(
        functions,
        'publicSettings'
      );
      const result = await qrStatusCallable({ requestType: 'LOAD_ORDER_STATUS', orderId });
      console.log({ result });

      // @ts-ignore
      if (result.data?.httpErrorCode?.status) {
        throw new Error('No data found');
      }
      setData(result.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    setError(null);
    getStatus(orderId);
  }, [orderId]);

  return { data, loading, error };
};

interface QRStatusUpdateRequest {
  requestType: 'UPDATE_ORDER_STATUS';
  orderId: string;
  status: OrderStatusEnum;
  // add more fields if needed
}

interface QRStatusUpdateResponse {
  orderId: string;
  status: OrderStatusEnum;
  updateTime: number;
  // add more fields if needed
}

interface UseQRStatusUpdateReturn {
  response: QRStatusUpdateResponse | null;
  loading: boolean;
  error: Error | null;
  updateQRStatus: (params: Omit<QRStatusUpdateRequest, 'requestType'>) => Promise<void>;
}

export const useQRStatusUpdate = (): UseQRStatusUpdateReturn => {
  const [response, setResponse] = useState<QRStatusUpdateResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const updateQRStatus = useCallback(async (params: Omit<QRStatusUpdateRequest, 'requestType'>) => {
    setLoading(true);
    setError(null);
    try {
      const updateQRStatusCallable = httpsCallable<QRStatusUpdateRequest, QRStatusUpdateResponse>(
        functions,
        'publicSettings'
      );
      const result = await updateQRStatusCallable({
        requestType: 'UPDATE_ORDER_STATUS',
        orderId: params.orderId,
        status: params.status,
      });
      // @ts-ignore
      if (result.data?.httpErrorCode?.status) {
        throw new Error('Noget gik galt, ordre ikke opdateret');
      }
      setResponse(result.data);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  return { response, loading, error, updateQRStatus };
};
