import { useEffect, useState } from 'react';
import { functions } from '../../firebase';
import useQueryParams from 'hooks/useQueryParams';
import { FilePdfOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import './page.css';
import { Button } from 'antd';
import AuditTable from './AuditTable';
import { getDateInterval } from './helpers';
import AdminOnly from 'components/AdminOnly';
import useIrrelevantGroupCategoryMap from 'hooks/useIrrelevantGroupCategoryMap';
import { useAppSelector } from 'hooks';
import Message, { Type } from 'components/Notification/Message';
// Base64 decoding function
function base64Decode(data: any) {
  return decodeURIComponent(atob(data));
}

const displayRGSLogo = (groupIds: string[]) =>
  ['RuXfdQ77eGHqAsQSHK5m', 'Gua3ROcmn9CBLIGSpOVn'].some((id) => groupIds.includes(id));

export default function AuditReports() {
  const { t } = useTranslation();
  const query = useQueryParams();
  const [loading, setLoading] = useState(false);
  const [audits, setAudits] = useState<any[]>([]);
  const q = query.get('q') as string;
  const decodedData = base64Decode(q);
  const [error, setError] = useState<number | null>(null);

  const parsedData = JSON.parse(decodedData) as {
    groupIds: string[];
    groupTitle: string;
    categories: string[];
    orgIds: string[];
    dateInterval: [string, string];
    completedTasks: boolean;
    incompleteTasks: boolean;
    // useSubGroup: boolean;
    dateType: 'time' | 'date' | 'week' | 'month' | 'quarter' | 'year';
  };

  const { irrelevantGroupCategoryMap } = useIrrelevantGroupCategoryMap();
  /*        {
        groupIds: parsedData.groupId,
    }*/

  const [startDate, endDate] = getDateInterval(parsedData.dateInterval, parsedData.dateType);

  const displayRGS = displayRGSLogo(parsedData.groupIds);

  const orgs = useAppSelector((state) => state.orgs.orgMap);

  const subGroups = parsedData.orgIds.map((orgId) => orgs[orgId]?.subGroup).filter(Boolean);

  const filtered = subGroups?.map((subGroup) => subGroup?.title).filter((s) => s !== undefined);

  const title = filtered?.join(', ');

  // const subGroups = parsedData.useSubGroup
  //     ? (subGroups.filter(Boolean) as string[])
  //     : [];

  useEffect(() => {
    if (error === 403) {
      Message({
        key: 'error',
        type: Type.ERROR,
        message: 'Du har ikke adgang til denne rapport',
        description:
          'Du har mangler adgang til en eller flere af de valgte afdelinger for at kunne trække denne rapport',
      });
    }
    if (error === 404) {
      Message({
        key: 'error',
        type: Type.ERROR,
        message: 'Rapporten kunne ikke findes',
        description:
          'Rapporten kunne ikke findes. Den er muligvis blevet slettet eller har udløbet',
      });
    }
    if (error === 500) {
      Message({
        key: 'error',
        type: Type.ERROR,
        message: 'Der opstod en fejl',
        description: 'Der opstod en fejl under generering af rapporten. Prøv igen senere',
      });
    }
  }, [error]);

  // useEffect(() => {
  //     const getDeals = async () => {
  //         try {
  //             setLoading(true);
  //             // const fakeAPICall = (): Promise<void> => {
  //             //     return new Promise(resolve => {
  //             //         setTimeout(() => {
  //             //             resolve();
  //             //         }, 2000);
  //             //     });
  //             // };

  //             const functionRef = functions.httpsCallable("generateAuditReport");
  //             const response: any = await functionRef({
  //                 ...parsedData,
  //             });

  //             console.log({ response });

  //             const errorCode = response?.data?.httpErrorCode?.status;
  //             if (errorCode) {
  //                 console.log({ errorCode });
  //             }
  //             setData(groupByMonth(response.data));
  //             setSums(sumCustomFields(response.data));
  //         } catch (error) {
  //             console.error(error);
  //         } finally {
  //             setLoading(false);
  //         }
  //     };

  //     getDeals();

  //     return () => {};
  // }, []);
  useEffect(() => {
    const getAudits = async () => {
      try {
        setLoading(true);
        setError(null);

        const functionRef = functions.httpsCallable('generateAuditSchema');
        const response: any = await functionRef({
          ...parsedData,
        });

        const errorCode = response?.data?.httpErrorCode?.status;
        if (errorCode) {
          setError(errorCode as number);
          throw new Error(errorCode);
        }
        setAudits(
          response.data
            .filter((e: any) => !e.auditTemplateCategory)
            .map((entry: any) => ({
              ...entry,
              dueDate: Number(entry.dueDate) * 1000,
              completeTime: entry.completeTime ? Number(entry.completeTime) * 1000 : null,
              isCompleted: entry.isCompleted === 'true',
              hasOnHoldTasks: entry.hasOnHoldTasks === 'true',
              needsResolution: entry.needsResolution === 'true',
            }))
        );
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getAudits();

    return () => {};
  }, []);

  return (
    <div className="report-page">
      <AdminOnly>
        <div className="print-hide" style={{ position: 'absolute', left: 24, top: 24 }}>
          <Button icon={<FilePdfOutlined />} onClick={() => window.print()}>
            Gem som PDF
          </Button>
        </div>
      </AdminOnly>

      {displayRGS && (
        <div style={{ textAlign: 'right' }}>
          <img width="300" src="https://rgsnordic.com/wp-content/uploads/Logo-Wide.png" />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <h1>Rapport {parsedData.groupTitle || title}</h1>
          <div style={{ paddingBottom: 24 }}>
            {t('date.exactFormatDateYear', {
              dayOfWeek: new Date(startDate),
              dayOfMonth: new Date(startDate),
              month: new Date(startDate),
              year: new Date(startDate),
            })}{' '}
            -{' '}
            {t('date.exactFormatDateYear', {
              dayOfWeek: new Date(endDate),
              dayOfMonth: new Date(endDate),
              month: new Date(endDate),
              year: new Date(endDate),
            })}
          </div>
          {/* <div>{parsedData.categories.map(c => categoryMap[c]).join(", ")}</div> */}
        </div>
      </div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <AuditTable
          groupIds={parsedData.groupIds}
          subGroups={subGroups}
          audits={audits}
          irrelevantGroupCategoryMap={irrelevantGroupCategoryMap}
          selectedCategories={parsedData.categories}
        />
      )}
      {/* <div>
                <h4>Summerede registereringer</h4>
                <div>
                    {Object.keys(sums).map(key => {
                        return (
                            <div
                                key={key}
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}>
                                <h3>{categoryMap[key]}</h3>
                                <div>
                                    {Object.keys(sums[key]).map(typeUnit => {
                                        return (
                                            <div>
                                                Total:{" "}
                                                <span style={{ fontWeight: 700 }}>
                                                    {sums[key][typeUnit]}
                                                </span>{" "}
                                                {t(
                                                    `audit_base_category_option.${typeUnit}`,
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div
                    style={{
                        height: 1,
                        width: "100%",
                        backgroundColor: StyleGuide.palette.grey300,
                    }}
                />
            </div> */}
      {/* {Object.keys(data).map(key => {
                return (
                    <div key={key}>
                        <h4 style={{ textTransform: "capitalize" }}>
                            {t("general.getMonth", { date: new Date(key) })}{" "}
                            {t("general.getYear", { date: new Date(key) })}
                        </h4>
                        {data[key].map((item: any) => {
                            console.log({ item });

                            return (
                                <>
                                    <div
                                        key={item.orgTitle}
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            paddingBottom: 8,
                                        }}>
                                        <div style={{ marginRight: 16, paddingTop: 16 }}>
                                            {item.audit.isCompleted ? (
                                                <CheckSquareOutlined
                                                    style={{
                                                        fontSize: 24,
                                                        color: StyleGuide.palette.success,
                                                    }}
                                                />
                                            ) : (
                                                <CloseSquareOutlined
                                                    style={{
                                                        fontSize: 24,
                                                        color: StyleGuide.palette.redish,
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div style={{ width: "100%" }}>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}>
                                                <Link
                                                    to={`/orgs/${item.audit.orgId}/audit/audits/${item.auditId}`}
                                                    target="_blank">
                                                    <h3 style={{ marginBottom: 4 }}>
                                                        {item.audit.templateTitle}
                                                    </h3>
                                                </Link>
                                                <div
                                                    style={{
                                                        color: StyleGuide.palette.grey600,
                                                    }}>
                                                    {item.orgTitle}
                                                </div>
                                            </div>
                                            {Object.keys(item.audit.tasks).map(
                                                taskKey => {
                                                    return (
                                                        <div
                                                            key={taskKey}
                                                            style={{
                                                                // textIndent: 16,
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}>
                                                            <div
                                                                style={{
                                                                    paddingBottom: 8,
                                                                }}>
                                                                <div
                                                                    style={{
                                                                        fontWeight: 700,
                                                                        paddingBottom: 4,
                                                                    }}>
                                                                    {
                                                                        item.audit.tasks[
                                                                            taskKey
                                                                        ].description
                                                                    }
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        color: StyleGuide
                                                                            .palette
                                                                            .grey700,
                                                                    }}>
                                                                    {item.audit.note}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        color: StyleGuide
                                                                            .palette
                                                                            .grey700,
                                                                    }}>
                                                                    {
                                                                        item.audit.tasks[
                                                                            taskKey
                                                                        ].issueDescription
                                                                    }
                                                                </div>
                                                                {item.audit.auditor
                                                                    ?.id && (
                                                                    <div
                                                                        style={{
                                                                            color: StyleGuide
                                                                                .palette
                                                                                .grey700,
                                                                            paddingTop: 4,
                                                                        }}>
                                                                        {t(
                                                                            "audit.list.completeby",
                                                                        )}
                                                                        {": "}
                                                                        {
                                                                            item.audit
                                                                                .auditor
                                                                                ?.displayName
                                                                        }
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                <p>
                                                                    {item.audit.tasks[
                                                                        taskKey
                                                                    ]?.customFields?.map(
                                                                        (cf: any) => {
                                                                            if (
                                                                                cf.value ===
                                                                                    undefined ||
                                                                                null
                                                                            ) {
                                                                                return (
                                                                                    <span
                                                                                        style={{
                                                                                            color: StyleGuide
                                                                                                .palette
                                                                                                .grey700,
                                                                                            fontStyle:
                                                                                                "italic",
                                                                                        }}>
                                                                                        Ingen
                                                                                        registering
                                                                                    </span>
                                                                                );
                                                                            }
                                                                            return (
                                                                                <span
                                                                                    key={
                                                                                        cf.description
                                                                                    }>
                                                                                    {
                                                                                        cf.description
                                                                                    }
                                                                                    :{" "}
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight: 700,
                                                                                        }}>
                                                                                        {
                                                                                            cf.value
                                                                                        }
                                                                                    </span>{" "}
                                                                                    {t(
                                                                                        `audit_base_category_option.${cf.type}.${cf.unit}`,
                                                                                    )}
                                                                                </span>
                                                                            );
                                                                        },
                                                                    )}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                },
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            height: 1,
                                            width: "100%",
                                            backgroundColor: StyleGuide.palette.grey300,
                                        }}
                                    />
                                </>
                            );
                        })}
                    </div>
                );
            })} */}
    </div>
  );
}

const fakeData = [
  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: '4Eb7TvMYxZe7HhXJZhGTVhZxa3b2',
      completeTime: { _seconds: 1705646399, _nanoseconds: 480000000 },
      createTime: { _seconds: 1703865405, _nanoseconds: 757000000 },
      dueDate: { _seconds: 1704384000, _nanoseconds: 733000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      notifyDateAfterDueDate: null,
      orgId: 'NRiWvZz6lBLjvvPpR5vY',
      recurringId: '7ffa3333-78dc-4b6e-b6e0-92ab567853ed',
      recurringOption: 'NEVER',
      startDate: { _seconds: 1704384000, _nanoseconds: 733000000 },
      startDaysBeforeDueDate: null,
      tasks: {
        '8EoftIdtdfLhEgoWu8N7': {
          completeTime: { _seconds: 1705646392, _nanoseconds: 457000000 },
          createTime: { _seconds: 1703865352, _nanoseconds: 523000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 38695.9,
            },
          ],
          description: 'Perkolatbassin A - 1 (NAV hent mængder i vejeprogram.)',
          id: '8EoftIdtdfLhEgoWu8N7',
          isCompleted: true,
          issueDescription: 'Udkørt i 2023, 38695,9 tons vand til rensningsanlæg.',
          order: 0,
        },
      },
      templateId: 'Yr4Vgp2AR1vcGEA752gm',
      templateNote: 'Udføres af Steffen - Primo hver måned\n',
      templateTitle: 'Minimering af perkolatudledning',
    },
    org_title: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
  },
  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      createTime: { _seconds: 1705668208, _nanoseconds: 264000000 },
      dueDate: { _seconds: 1706284800, _nanoseconds: 261000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: false,
      lastRecurring: false,
      needsResolution: true,
      notifyDateAfterDueDate: null,
      orgId: 'md32SWSvJjcO4sq7oQWr',
      recurringId: '20d51042-b39b-417d-9ba8-5af26626d661',
      recurringOption: 'NEVER',
      startDate: { _seconds: 1705939200, _nanoseconds: 261000000 },
      startDaysBeforeDueDate: 4,
      tasks: {
        pobSJzaDFoS5pNP3rC4h: {
          createTime: '2024-01-19T12:43:14.431Z',
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
            },
          ],
          description: 'Perkolat aflæsning',
          id: 'pobSJzaDFoS5pNP3rC4h',
          order: 0,
        },
      },
      templateId: 'ieqjOqEEEfAwS9ODYE6Z',
      templateNote:
        'Perkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
    },
    org_title: 'RGS Nordic A/S - Øst - Herlufmagle - Miljømål',
  },
  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: 'fGS1cW5QK3OAzc9nJ6J1xvGe5fm1',
      completeTime: { _seconds: 1706685853, _nanoseconds: 582000000 },
      createTime: { _seconds: 1705667983, _nanoseconds: 460000000 },
      dueDate: { _seconds: 1706284800, _nanoseconds: 675000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      notifyDateAfterDueDate: null,
      orgId: 'b8lQZcH3bkHeNddxHZVd',
      recurringId: '23f33a7f-a902-4282-90ef-4cc1da05e62e',
      recurringOption: 'NEVER',
      startDate: { _seconds: 1705939200, _nanoseconds: 675000000 },
      startDaysBeforeDueDate: 4,
      tasks: {
        g10pw7Cfcb1hx9Z7hV35: {
          completeTime: { _seconds: 1706685851, _nanoseconds: 194000000 },
          createTime: '2024-01-19T12:38:28.204Z',
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 3020,
            },
          ],
          description: 'Perkolat aflæsning',
          id: 'g10pw7Cfcb1hx9Z7hV35',
          isCompleted: true,
          issueDescription: 'Vil har intet kørt væk men udledt 3020 m3 1/12-23 til 9/1-24',
          order: 0,
        },
      },
      templateId: 'u9Q4QnLI63voW2r6N1b4',
      templateNote:
        'Perkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
    },
    org_title: 'RGS Nordic A/S - Øst - Gadstrup - Miljømål',
  },
  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: 'o0NOi5JKbKXbabHyZ21zhUSPZdg2',
      completeTime: { _seconds: 1706092246, _nanoseconds: 667000000 },
      createTime: { _seconds: 1705668474, _nanoseconds: 994000000 },
      dueDate: { _seconds: 1706284800, _nanoseconds: 254000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      notifyDateAfterDueDate: null,
      orgId: '45iGajxZXyYuuMbcI9rc',
      recurringId: '968fbaa3-8f53-42ce-906c-763fa02423d2',
      recurringOption: 'NEVER',
      startDate: { _seconds: 1705939200, _nanoseconds: 254000000 },
      startDaysBeforeDueDate: 4,
      tasks: {
        '13KVF2x9lBY6VNeMKzDb': {
          completeTime: { _seconds: 1706092245, _nanoseconds: 883000000 },
          createTime: '2023-12-29T23:18:13.643Z',
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 839.9,
            },
          ],
          description: 'Aflæsning af perkolat',
          id: '13KVF2x9lBY6VNeMKzDb',
          isCompleted: true,
          issueDescription: 'Der er kørt 839,900 liter ud til lynetten i 2023',
          order: 0,
        },
      },
      templateId: 'RzrxENlrullqMSs0q8Qy',
      templateNote:
        'Perkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
    },
    org_title: 'RGS Nordic A/S - Øst - Kbh Nordsøvej - Miljømål',
  },

  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: 'NVdfwTyAdZOfH3M2KC2HFlwCfcG2',
      completeTime: { _seconds: 1707730423, _nanoseconds: 803000000 },
      createTime: { _seconds: 1703950212, _nanoseconds: 396000000 },
      dueDate: { _seconds: 1707062400, _nanoseconds: 917000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      notifyDateAfterDueDate: null,
      orgId: 'QZDH2TKXFkJBNHsUMWLX',
      recurringId: '62935133-ec44-47a6-81db-079b3944ffe6',
      recurringOption: 'DATE_MONTHLY',
      startDate: { _seconds: 1706803200, _nanoseconds: 917000000 },
      startDaysBeforeDueDate: 3,
      tasks: {
        a6ps2Ft1Hn0GbeOSXLUr: {
          completeTime: { _seconds: 1707730421, _nanoseconds: 417000000 },
          createTime: { _seconds: 1703950095, _nanoseconds: 561000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 0,
            },
          ],
          description: 'Perkolatbassin 2 - plads 4',
          id: 'a6ps2Ft1Hn0GbeOSXLUr',
          isCompleted: true,
          issueDescription: 'Ikke kørt noget',
          order: 1,
        },
        zgMSsJBVdQdbrx3tvQW9: {
          completeTime: { _seconds: 1707472515, _nanoseconds: 306000000 },
          createTime: { _seconds: 1703950090, _nanoseconds: 652000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 1522.86,
            },
          ],
          description: 'Perkolatbassin 1 - plads 1',
          id: 'zgMSsJBVdQdbrx3tvQW9',
          isCompleted: true,
          issueDescription: '1522,86 m³ kørt til Stigsnæs - Vejesag 249279',
          order: 0,
        },
      },
      templateId: '5t4a6RuM2o8nSa4wA2Pk',
      templateNote:
        'Primo hver måned\nPerkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
    },
    org_title: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
  },

  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: '4Eb7TvMYxZe7HhXJZhGTVhZxa3b2',
      completeTime: { _seconds: 1712899081, _nanoseconds: 699000000 },
      createTime: { _seconds: 1703865389, _nanoseconds: 792000000 },
      dueDate: { _seconds: 1709323200, _nanoseconds: 733000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      hasOnHoldTasks: false,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      note: '777,6 tons for Marts 2024',
      notifyDateAfterDueDate: null,
      orgId: 'NRiWvZz6lBLjvvPpR5vY',
      recurringId: '21e9fa4a-4996-41ca-bbda-4617492e3ff2',
      recurringOption: 'PRIMO_MONTH',
      startDate: { _seconds: 1709323200, _nanoseconds: 733000000 },
      startDaysBeforeDueDate: null,
      tasks: {
        '8EoftIdtdfLhEgoWu8N7': {
          completeTime: { _seconds: 1712899047, _nanoseconds: 1000000 },
          createTime: { _seconds: 1703865352, _nanoseconds: 523000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 777.6,
            },
          ],
          description: 'Perkolatbassin A - 1 (NAV hent mængder i vejeprogram.)',
          id: '8EoftIdtdfLhEgoWu8N7',
          isCompleted: true,
          order: 0,
        },
      },
      templateId: 'Yr4Vgp2AR1vcGEA752gm',
      templateNote:
        'Udføres af Steffen - Primo hver måned\nPerkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
      timeState: 'PAST_DUE',
    },
    org_title: 'RGS Nordic A/S - Øst - Kalundborg - Miljømål',
  },
  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: 'NVdfwTyAdZOfH3M2KC2HFlwCfcG2',
      completeTime: { _seconds: 1712575668, _nanoseconds: 220000000 },
      createTime: { _seconds: 1703950212, _nanoseconds: 397000000 },
      dueDate: { _seconds: 1709568000, _nanoseconds: 917000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      hasOnHoldTasks: false,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      notifyDateAfterDueDate: null,
      orgId: 'QZDH2TKXFkJBNHsUMWLX',
      recurringId: '62935133-ec44-47a6-81db-079b3944ffe6',
      recurringOption: 'DATE_MONTHLY',
      startDate: { _seconds: 1709308800, _nanoseconds: 917000000 },
      startDaysBeforeDueDate: 3,
      tasks: {
        a6ps2Ft1Hn0GbeOSXLUr: {
          completeTime: { _seconds: 1712575666, _nanoseconds: 704000000 },
          createTime: { _seconds: 1703950095, _nanoseconds: 561000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 0,
            },
          ],
          description: 'Perkolatbassin 2 - plads 4',
          id: 'a6ps2Ft1Hn0GbeOSXLUr',
          isCompleted: true,
          order: 1,
        },
        zgMSsJBVdQdbrx3tvQW9: {
          completeTime: { _seconds: 1712575666, _nanoseconds: 167000000 },
          createTime: { _seconds: 1703950090, _nanoseconds: 652000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 428.96,
            },
          ],
          description: 'Perkolatbassin 1 - plads 1',
          id: 'zgMSsJBVdQdbrx3tvQW9',
          isCompleted: true,
          issueDescription: '428,96 m³ kørt til Stigsnæs - Vejesag 249279',
          order: 0,
        },
      },
      templateId: '5t4a6RuM2o8nSa4wA2Pk',
      templateNote:
        'Primo hver måned\nPerkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
      timeState: 'PAST_DUE',
    },
    org_title: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
  },

  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      auditor: 'NVdfwTyAdZOfH3M2KC2HFlwCfcG2',
      completeTime: { _seconds: 1712575495, _nanoseconds: 110000000 },
      createTime: { _seconds: 1703950212, _nanoseconds: 398000000 },
      dueDate: { _seconds: 1712246400, _nanoseconds: 917000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      hasOnHoldTasks: false,
      isCompleted: true,
      lastRecurring: false,
      needsResolution: false,
      notifyDateAfterDueDate: null,
      orgId: 'QZDH2TKXFkJBNHsUMWLX',
      recurringId: '62935133-ec44-47a6-81db-079b3944ffe6',
      recurringOption: 'DATE_MONTHLY',
      startDate: { _seconds: 1711987200, _nanoseconds: 917000000 },
      startDaysBeforeDueDate: 3,
      tasks: {
        a6ps2Ft1Hn0GbeOSXLUr: {
          completeTime: { _seconds: 1712575494, _nanoseconds: 37000000 },
          createTime: { _seconds: 1703950095, _nanoseconds: 561000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 0,
            },
          ],
          description: 'Perkolatbassin 2 - plads 4',
          id: 'a6ps2Ft1Hn0GbeOSXLUr',
          isCompleted: true,
          issueDescription: '',
          order: 1,
        },
        zgMSsJBVdQdbrx3tvQW9: {
          completeTime: { _seconds: 1712575493, _nanoseconds: 247000000 },
          createTime: { _seconds: 1703950090, _nanoseconds: 652000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
              value: 48,
            },
          ],
          description: 'Perkolatbassin 1 - plads 1',
          id: 'zgMSsJBVdQdbrx3tvQW9',
          isCompleted: true,
          issueDescription: '48m³ brugt på plads 1+2',
          order: 0,
        },
      },
      templateId: '5t4a6RuM2o8nSa4wA2Pk',
      templateNote:
        'Primo hver måned\nPerkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
      timeState: 'PAST_DUE',
    },
    org_title: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
  },

  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      createTime: { _seconds: 1703950212, _nanoseconds: 400000000 },
      dueDate: { _seconds: 1714838400, _nanoseconds: 917000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: false,
      lastRecurring: false,
      needsResolution: true,
      notifyDateAfterDueDate: null,
      orgId: 'QZDH2TKXFkJBNHsUMWLX',
      recurringId: '62935133-ec44-47a6-81db-079b3944ffe6',
      recurringOption: 'DATE_MONTHLY',
      startDate: { _seconds: 1714579200, _nanoseconds: 917000000 },
      startDaysBeforeDueDate: 3,
      tasks: {
        a6ps2Ft1Hn0GbeOSXLUr: {
          createTime: { _seconds: 1703950095, _nanoseconds: 561000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
            },
          ],
          description: 'Perkolatbassin 2 - plads 4',
          id: 'a6ps2Ft1Hn0GbeOSXLUr',
          order: 1,
        },
        zgMSsJBVdQdbrx3tvQW9: {
          createTime: { _seconds: 1703950090, _nanoseconds: 652000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
            },
          ],
          description: 'Perkolatbassin 1 - plads 1',
          id: 'zgMSsJBVdQdbrx3tvQW9',
          order: 0,
        },
      },
      templateId: '5t4a6RuM2o8nSa4wA2Pk',
      templateNote:
        'Primo hver måned\nPerkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
      timeState: 'PAST_DUE',
    },
    org_title: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
  },

  {
    audit: {
      auditTemplateCategory: 'RGS_c84cb65a-9230-4982-8d64-c03351aa793f',
      createTime: { _seconds: 1703950212, _nanoseconds: 401000000 },
      dueDate: { _seconds: 1717516800, _nanoseconds: 917000000 },
      groupId: 'RuXfdQ77eGHqAsQSHK5m',
      guide: null,
      isCompleted: false,
      lastRecurring: false,
      needsResolution: true,
      notifyDateAfterDueDate: null,
      orgId: 'QZDH2TKXFkJBNHsUMWLX',
      recurringId: '62935133-ec44-47a6-81db-079b3944ffe6',
      recurringOption: 'DATE_MONTHLY',
      startDate: { _seconds: 1717257600, _nanoseconds: 917000000 },
      startDaysBeforeDueDate: 3,
      tasks: {
        a6ps2Ft1Hn0GbeOSXLUr: {
          createTime: { _seconds: 1703950095, _nanoseconds: 561000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
            },
          ],
          description: 'Perkolatbassin 2 - plads 4',
          id: 'a6ps2Ft1Hn0GbeOSXLUr',
          order: 1,
        },
        zgMSsJBVdQdbrx3tvQW9: {
          createTime: { _seconds: 1703950090, _nanoseconds: 652000000 },
          customFields: [
            {
              description: 'Perkolat m3',
              fieldType: 'number',
              required: true,
              type: 'volume',
              unit: 'cubic_meters',
            },
          ],
          description: 'Perkolatbassin 1 - plads 1',
          id: 'zgMSsJBVdQdbrx3tvQW9',
          order: 0,
        },
      },
      templateId: '5t4a6RuM2o8nSa4wA2Pk',
      templateNote:
        'Primo hver måned\nPerkolatvand til godkendt modtager, her skal der noteres hvor meget der er kørt væk for året',
      templateTitle: 'Minimering af perkolatudledning',
      timeState: 'PAST_DUE',
    },
    org_title: 'RGS Nordic A/S - Øst - Vemmelev - Miljømål',
  },
];

function groupByMonth(data: any[]) {
  const result = {};

  data.forEach((item) => {
    const dueDateSeconds = item.audit.dueDate._seconds;
    const dueDate = new Date(dueDateSeconds * 1000);
    const month = dueDate.getFullYear() + '-' + String(dueDate.getMonth() + 1).padStart(2, '0');
    // @ts-ignore
    if (!result[month]) {
      // @ts-ignore
      result[month] = [];
    }

    // Check for duplicate entries
    if (
      // @ts-ignore
      !result[month].some((entry) => JSON.stringify(entry) === JSON.stringify(item))
    ) {
      // @ts-ignore
      result[month].push({
        audit: item.audit,
        orgTitle: item.orgTitle,
        auditId: item.auditId,
      });
    }
  });

  return result;
}

function sumCustomFields(data: any[]) {
  const groupedSums = {};

  data.forEach((item) => {
    const audit = item.audit;
    const category = audit.auditTemplateCategory;

    Object.keys(audit.tasks).forEach((taskKey) => {
      const task = audit.tasks[taskKey];
      // @ts-ignore
      task.customFields?.forEach((field) => {
        const type = field.type;
        const unit = field.unit;
        const combined = `${type}.${unit}`;
        const value = field.value || 0;

        // @ts-ignore
        if (!groupedSums[category]) {
          // @ts-ignore
          groupedSums[category] = {};
        }
        // @ts-ignore
        if (!groupedSums[category][combined]) {
          // @ts-ignore
          groupedSums[category][combined] = 0;
        }
        // @ts-ignore
        groupedSums[category][combined] += value;
      });
    });
  });

  return groupedSums;
}
