import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export interface CustomLog {
  id: string;
  receiver: number;
  senttime: number;
  message: string;
  status: string;
  resolveTime?: Date;
}
// TODO: figure out if we should filter identical logs
export default function useLatestCustomLog({ deviceId }: { deviceId: string }) {
  const [state, setstate] = useState<CustomLog[]>([]);
  const [isLoading, setisLoading] = useState(true);
  console.log({ state });

  useEffect(() => {
    if (!deviceId) return;
    let unsub: () => void;
    try {
      unsub = firebase
        .firestore()
        .collection('industrySMSTest')
        .where('from.msisdn', '==', Number(deviceId))
        .orderBy('from.senttime', 'desc')
        .onSnapshot((query) => {
          const uniqueLogsSet = new Set<string>();
          if (!query.empty) {
            const data = query.docs
              .map((doc) => {
                const data = doc.data();
                return {
                  ...data.from,
                  id: doc.id,
                  message: data.formattedMessage || data.from.message,
                  messageId: data.from.id,
                  resolveTime: data.resolveTime?.toDate(),
                };
              })
              .filter((log) => {
                const uniqueKey = log.messageId;
                if (!uniqueLogsSet.has(uniqueKey)) {
                  uniqueLogsSet.add(uniqueKey);
                  return true;
                }
                return false;
              });
            setstate(data);
          }
          setisLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
    return () => unsub?.();
  }, [deviceId]);
  return { data: state, isLoading };
}
