import { CopyOutlined, DeleteOutlined, MoreOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge, Button, Divider, Dropdown, Menu, Space, Tooltip, Typography, theme } from 'antd';
import { MenuProps } from 'antd/lib';
import React from 'react';
// import DeleteTemplate from "./DeleteTemplate";
// import useDeleteTemplate from "./DeleteTemplate";
// import useDuplicateTemplate from "./DuplicateTemplate";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useDeleteTemplateTask from './useDeleteTemplateTask';
import useDuplicateTemplateTask from './useDuplicateTemplateTask';
import { openModal } from 'modules/modal/actions';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ModalType } from 'modules/modal/reducer';
import { StyleGuide } from 'styles/StyleGuide';
import AuditTemplateTaskDiscardAction from 'Views/Audits/Actions/AuditTemplateTaskDiscardAction';

const { useToken } = theme;

export default function Options({
  orgId,
  templateId,
  taskId,
  isReadOnly = false,
}: // template,
{
  orgId: string;
  templateId: string;
  taskId: string;
  isReadOnly?: boolean;
  // template: AuditTemplate;
}) {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { token } = useToken();
  console.log({ taskId });
  const categoryMap = useAppSelector((state) => state.audits.auditBaseCategories.fields);
  const customFields = useAppSelector(
    (state) =>
      state.auditTemplates.currentAuditTemplateTasks?.find((t) => t.id === taskId)?.customFields
  );
  const { loading, confirmDeleteItem } = useDeleteTemplateTask({
    orgId,
    templateId,
    taskId,
  });
  const { loading: loadingDuplicate, handleDuplicateTemplate } = useDuplicateTemplateTask({
    orgId,
    templateId,
    taskId,
  });
  // const handleMenuClick: MenuProps["onClick"] = e => {
  //     // message.info("Click on menu item.");
  //     console.log("click", e);
  // };

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  // const customFieldsContent = customFields && customFields?.length > 0 && (
  //     <div style={{ padding: 8 }}>
  //         <Typography.Title level={5} style={{ margin: 0 }}>
  //             Custom fields
  //         </Typography.Title>
  //         {customFields.map(field => {
  //             const options = categoryMap?.[field.type]?.options || [];
  //             const pickedOption = options.find(o => o === field.unit);
  //             return (
  //                 <Space
  //                     style={{
  //                         display: "flex",
  //                         flexDirection: "row",
  //                         padding: 8,
  //                     }}>
  //                     <Typography.Text>{field.description}</Typography.Text>
  //                     <Typography style={{ color: StyleGuide.palette.grey600 }}>
  //                         (
  //                         {pickedOption &&
  //                             t(
  //                                 `audit_base_category_option.${field.type}.${pickedOption}`,
  //                             )}
  //                         )
  //                     </Typography>
  //                     <Divider />
  //                 </Space>
  //             );
  //         })}
  //     </div>
  // );

  return (
    <Tooltip title={customFields ? `${customFields?.length} registerings felter` : ''}>
      <Dropdown.Button
        disabled={isReadOnly}
        placement="bottom"
        icon={<MoreOutlined />}
        onClick={() => {
          dispatch(
            openModal({
              type: ModalType.ADVANCED_TASK_SETTINGS,
              context: {
                orgId,
                templateId,
                taskId,
              },
            })
          );
        }}
        dropdownRender={(_) => {
          return (
            <Menu>
              <Menu.Item key="duplicate" style={{ padding: 0 }}>
                <Button type="text" block icon={<CopyOutlined />} onClick={handleDuplicateTemplate}>
                  {t('audit.templates.duplicate')}
                </Button>
              </Menu.Item>
              <Menu.Item key="discard" style={{ padding: 0 }}>
                <AuditTemplateTaskDiscardAction
                  orgId={orgId}
                  auditTemplateId={templateId}
                  taskId={taskId}
                />
              </Menu.Item>
              <Menu.Item key="delete" style={{ padding: 0 }}>
                <Button
                  type="text"
                  block
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    color: StyleGuide.palette.redish,
                  }}
                  danger
                  icon={<DeleteOutlined />}
                  onClick={confirmDeleteItem}
                >
                  {t('audit.templates.delete')}
                </Button>
              </Menu.Item>
              {/* <div style={contentStyle}> */}
              {/* <DeleteTemplate orgId="orgId" templateId="templateId" /> */}
              {/* {React.cloneElement(menu as React.ReactElement, {
                                style: menuStyle,
                                })} */}
              {/* <Divider style={{ margin: 0 }} />
                        <Space style={{ padding: 8 }}>
                        <Button type="primary">Click me!</Button>
                        </Space> */}

              {/* </div> */}
            </Menu>
          );
        }}
      >
        <Badge
          style={{ top: -6, right: -4 }}
          count={customFields?.length}
          color={StyleGuide.palette.infoDark}
        >
          <SettingOutlined />
        </Badge>
      </Dropdown.Button>
    </Tooltip>
  );
}
