import firebase, { firestore } from '../../firebase';
import { useEffect, useState } from 'react';
import { AuditSchedule } from './types';

/**
 * Filtering out if it is on hold
 * @param groupId
 * @returns
 */

export default function useNeedsResolutionAuditsBySubGroup(orgIds: string[]) {
  const [audits, setAudits] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    let unsubscribe: any = null;
    if (orgIds.length < 1) return;
    try {
      let query = firestore.collectionGroup('auditSchedules');
      query = query
        .where('isCompleted', '==', true)
        .where('needsResolution', '==', true)
        .where('orgId', 'in', orgIds)
        .orderBy('dueDate', 'desc')
        .limit(100);
      unsubscribe = query.onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs
            .map(
              (doc) =>
                ({
                  id: doc.id,
                  orgId: doc.ref.parent.parent?.id,
                  ...doc.data(),
                } as AuditSchedule & { orgId: string })
            )
            .filter((audit) => !audit.hasOnHoldTasks);
          setAudits(list);
        } else {
          setAudits([]);
        }
        setisLoading(false);
      });
    } catch (error) {
      console.error(error);
    }
    return unsubscribe;
  }, [orgIds]);

  return { audits, isLoading };
}
