import React, { useState } from 'react';
import { Table, Form } from 'antd';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { parseAmountToFloat } from '../../../general/helpers';

import Message, { Type } from '../../../components/Notification/Message';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks';
import firebase from 'firebase/compat/app';
import columns from '../../OrgOrders/components/columns';
import { EditableCell } from '../../OrgOrders/components/TableEditCell';
import useGroupOrders from './useGroupOrders';
import { Unit } from '../../../components/SelectUnit';

const GroupOrdersTable = ({ groupId }: { groupId: string }) => {
  const { t } = useTranslation();
  const groupStorage = useAppSelector((state) => state.groups.groupsMap?.[groupId]);
  const { orders, loadingOrders } = useGroupOrders(groupId);
  const data = useAppSelector((state) =>
    orders.map((order) => ({
      ...order,
      orderProductTitle: state.products.productsMap[order.productId]?.title,
      createTime: t('general.dateTime', {
        date: order.createTime?.toDate(),
      }),
    }))
  );
  const [form] = Form.useForm();
  const isMobile = useIsMobile();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: any) => {
    console.log({ record });
    return record.id === editingKey;
  };
  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const save = async (_: any, newAmount: any, record: any) => {
    try {
      Message({
        key: 'successOrder',
        type: Type.LOADING,
        message: `Opdaterer ordre`,
      });
      const batch = firebase.firestore().batch();
      const groupRef = firebase.firestore().collection('groups').doc(groupStorage.id);
      const orderRef = firebase.firestore().collection('orders').doc(record.id);

      const row = await form.validateFields();
      const storage = groupStorage?.storage?.[record.productId]?.storage;
      let amount = storage;

      const becomesIncomplete = row.status !== 'completed' && record.status === 'completed';
      if (becomesIncomplete) {
        const newStorage = parseAmountToFloat(storage) - parseAmountToFloat(newAmount);
        if (newStorage < 0) {
          return Message({
            key: 'errorOrder',
            type: Type.ERROR,
            message: `Der skete en fejl`,
            description: `Mængden på hovedlageret vil gå under 0 og er lige nu ${storage}, prøv venligst igen`,
          });
        }
        amount = newStorage;

        batch.set(
          groupRef,
          {
            storage: {
              [record.productId]: {
                storage: newStorage,
              },
            },
          },
          { merge: true }
        );
      }
      const becomesComplete = row.status === 'completed' && record.status !== 'completed';
      if (becomesComplete) {
        const newStorage = parseAmountToFloat(storage) + parseAmountToFloat(newAmount);
        amount = newStorage;
        batch.set(
          groupRef,
          {
            storage: {
              [record.productId]: {
                storage: newStorage,
              },
            },
          },
          { merge: true }
        );
      }

      batch.set(orderRef, { status: row.status }, { merge: true });
      await batch.commit();
      setEditingKey('');
      return Message({
        key: 'successOrder',
        type: Type.SUCCESS,
        message: `Ordre opdateret`,
        description: `Beholdingen er nu ${amount || 'opdateret'}`,
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
      return Message({
        key: 'successOrder',
        type: Type.ERROR,
        message: `Noget gik galt prøv venligst igen`,
      });
    }
  };
  const titles = {
    orderTime: t('orders.order_time'),
    orderAmount: t('orders.order_amount'),
    orderProduct: t('orders.order_product'),
    orderStatus: t('orders.order_status'),
  };

  const columnsToRender = columns(editingKey, isEditing, save, edit, cancel, titles, Unit.LITER);

  const mergedColumns = columnsToRender
    .filter((log) => (isMobile && log.dataIndex !== 'createTime') || log)
    .map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: any) => ({
          record,
          inputType: col.dataIndex === 'status' ? 'select' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  return (
    <Form form={form} component={false}>
      {data.length > 0 ? (
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          locale={{
            emptyText: 'Ingen bestillinger',
          }}
          loading={loadingOrders}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      ) : (
        <div
          style={{
            fontSize: 20,
            textAlign: 'center',
            color: '#c0c0c0',
            padding: 12,
          }}
        >
          {'Ingen bestillinger'}
        </div>
      )}
    </Form>
  );
};

export default GroupOrdersTable;
