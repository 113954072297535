import { GPS_INVENTORY_FETCH_REQUESTED, GPS_INVENTORY_FETCH_SUCCEEDED } from './types';
import { InventoryContextForType, InventoryType } from '../types';

export function fetchInventoryGPS() {
  return {
    type: GPS_INVENTORY_FETCH_REQUESTED,
    payload: {},
  };
}

export function fetchInventoryGPSSuccess(
  // @ts-ignore
  productsList: firebase.firestore.QuerySnapshot
): {
  type: string;
  payload: InventoryContextForType<InventoryType.GPS>[];
} {
  const gps = productsList.docs.map(
    // @ts-ignore
    (doc: firebase.firestore.DocumentData) => {
      const data = doc.data();
      return {
        ...data,
        orgId: data?.org?.id,
        orgTitle: data?.org?.title,
        id: doc.id,
        updateTime: data?.updateTime?.toDate(),
      };
    }
  );
  return {
    type: GPS_INVENTORY_FETCH_SUCCEEDED,
    payload: gps,
  };
}
