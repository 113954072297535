import { Badge } from 'components/ui/badge';
import { useAudit } from './audit-context';
import { getStatusColor, getStatusIcon } from '../InternalAuditOverview/components/AuditMatrix';
import { getCounts, getDisplayStatus } from '../InternalAuditDetail/audit-summary';
import { useTranslation } from 'react-i18next';

export default function PreviousAuditCard({ orgId }: { orgId?: string }) {
  const { t } = useTranslation();
  const { audits } = useAudit();

  const audit = getLatestAudit(audits, orgId);
  if (!audit) {
    return null;
  }

  const { deviations, observations, passed } = getCounts(audit.subTasksMap);

  return (
    <div className="rounded-md bg-primary/5 p-4">
      <h3 className="font-medium">Seneste auditering</h3>
      <div className="text-sm text-muted-foreground mt-2 space-y-1">
        {/* show latest audit summary */}
        <div className="flex justify-between">
          <Badge className={'flex items-center gap-1 ' + getStatusColor('deviation')}>
            {getStatusIcon('deviation')}
            {getDisplayStatus('deviation')}
          </Badge>
          <span>{deviations}</span>
        </div>
        <div className="flex justify-between">
          <Badge className={'flex items-center gap-1 ' + getStatusColor('observation')}>
            {getStatusIcon('observation')}
            {getDisplayStatus('observation')}
          </Badge>
          <span>{observations}</span>
        </div>
        <div className="flex justify-between">
          <Badge className={'flex items-center gap-1 ' + getStatusColor('passed')}>
            {getStatusIcon('passed')}
            {getDisplayStatus('passed')}
          </Badge>
          <span>{passed}</span>
        </div>
        {/* date */}
        <div className="flex justify-between">
          <span>Seneste audit</span>
          <span className="text-right">
            {t('general.getDate', { date: audit.completeTime.toDate() })}{' '}
            {t('general.getMonth', { date: audit.completeTime.toDate() })}{' '}
            {t('general.getYear', { date: audit.completeTime.toDate() })}
          </span>
        </div>
        {/* auditor */}
        <div className="flex justify-between">
          <span>Auditør</span>
          <span>{audit?.auditor.displayName}</span>
        </div>
      </div>
    </div>
  );
}

export function getLatestAudit(audits: any[], orgId?: string) {
  return audits
    .filter((a) => a.state === 'COMPLETED')
    .sort((a, b) => b.createTime.toDate().getTime() - a.createTime.toDate().getTime())
    .find((a) => a.location.id === orgId);
}
