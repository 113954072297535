import { Drawer, Col, Row, Form, Select, Button, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { closeOrgMemberDrawer } from '../../modules/drawer/actions';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import { Member, Roles } from 'modules/orgs/interfaces';
import { removeMember, updateMembers } from 'modules/orgs/actions';
import { DeleteOutlined } from '@ant-design/icons';
import confirmModal from 'general/confirmModal';

const { Option } = Select;

function MemberDrawer() {
  const context = useAppSelector((state) => state.drawer.orgMember);
  const orgId = useAppSelector((state) => state.orgs.currentOrg.id);
  const members = useAppSelector((state) => state.orgs.currentOrg.members);
  const member = useAppSelector((state) =>
    context?.userId ? state.orgs.currentOrg.members[context?.userId] : null
  );
  const { t } = useTranslation();
  console.log({ context });

  const dispatch = useDispatch();

  const dispatchRemoveMember = () => {
    if (!context?.userId || !members) return;
    dispatch(
      removeMember({
        orgId,
        members: members || {},
        memberId: context?.userId,
      })
    );
    closeProfile();
  };

  const closeProfile = () => {
    dispatch(closeOrgMemberDrawer());
  };
  const dispatchUpdateMembers = (member: Partial<Member>) => {
    if (!member.uid) return;
    // @ts-ignore
    dispatch(updateMembers({ orgId, member: { [member.uid]: member } }));
  };

  const onFinish = (values: any) => {
    if (values.role === Roles.ADMIN) {
      delete values.role;
    }
    delete values.email;
    delete values.prefix;
    delete values.phone;

    dispatchUpdateMembers({ ...member, ...values });

    console.log('Success:', values);
  };

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="31">+31</Option>
        <Option value="45">+45</Option>
        <Option value="46">+46</Option>
        <Option value="47">+47</Option>
        <Option value="49">+49</Option>
      </Select>
    </Form.Item>
  );

  return (
    <>
      <Drawer
        key={`${context?.orgId}-${context?.userId}`}
        title={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>{member?.name || member?.email}</div>
            <Button
              danger
              onClick={() => {
                confirmModal({
                  title: `Er du sikker på du vil fjerne ${member?.name || member?.email}?`,
                  onOk: () => dispatchRemoveMember(),
                });
              }}
              icon={<DeleteOutlined />}
            />
          </div>
        }
        width={720}
        onClose={closeProfile}
        open={Boolean(context?.userId)}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form
              key={`${context?.orgId}-${context?.userId}`}
              name="wrap"
              labelCol={{ flex: '140px' }}
              labelAlign="left"
              labelWrap
              onFinish={onFinish}
              // wrapperCol={{ flex: 1 }}
              colon={false}
              initialValues={{
                name: member?.name,
                email: member?.email,
                role: member?.role,
                phone: member?.phone,
              }}
            >
              <Form.Item label={t('access.table_email')} name="email">
                <Input disabled />
              </Form.Item>
              <Form.Item label={t('access.table_phone')} name="phone">
                <Input disabled addonBefore={prefixSelector} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item label={t('access.table_name')} name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item label={t('access.table_role')} name="role" rules={[{ required: true }]}>
                <Select placeholder={t('access.add.role_placeholder')}>
                  <Option value="admin" disabled>
                    {t('access.role_admin')}
                  </Option>
                  <Option value="cleaner">{t('access.role_cleaner')}</Option>
                  <Option value="departmentExecutive">{t('access.role_executive')}</Option>
                  <Option value="sales">{t('access.role_sales')}</Option>
                </Select>
              </Form.Item>

              <Form.Item label=" ">
                <Button type="primary" htmlType="submit">
                  {t('access.save')}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Drawer>
    </>
  );
}
export default MemberDrawer;
