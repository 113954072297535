import React, { useState } from 'react';
import QrReader from 'react-qr-reader';
import { addCheckin } from '../../modules/checkin/actions';
import { connect } from 'react-redux';
import { AppState } from '../../store';
import { CHECKIN_TYPES } from '../../modules/checkin/types';

function QrScanner({ addCheckinLog, orgId, uid, displayName, type, coordindates }: any) {
  const [isLoading, setisLoading] = useState(false);

  const [result, setResult] = useState('');
  const handleScan = async (data: any) => {
    if (data) {
      setResult(data);
      setisLoading(true);
      await addCheckinLog({
        orgId,
        uid,
        type,
        displayName,
        lat: coordindates.latitude,
        lng: coordindates.longitude,
        accuracy: coordindates.accuracy,
      });
      setisLoading(false);
    }
  };
  const handleError = (err: Error) => {
    console.error(err);
  };
  if (isLoading) {
    return <div>Loading....</div>;
  }
  console.log({ displayName });

  return (
    <div>
      <QrReader delay={500} onError={handleError} onScan={handleScan} style={{ width: '100%' }} />
      <p>{result}</p>
    </div>
  );
}

function mapStateToProps(state: AppState) {
  return {
    // @ts-ignore
    uid: state.user.details.uid,
    orgId: state.orgs.currentOrg.id,
    // @ts-ignore
    displayName: state.user.details.displayName,
  };
}

const mapActionsToProps = {
  addCheckinLog: addCheckin,
};

export default connect(mapStateToProps, mapActionsToProps)(QrScanner);
