import { useState } from 'react';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { toast } from 'sonner';
import uuid from 'uuid';

export function useMultiFileUpload() {
  const [uploadProgress, setUploadProgress] = useState<Record<string, number>>({});
  const [downloadURLs, setDownloadURLs] = useState<any[]>([]);
  const [error, setError] = useState<Error | null>(null);

  const uploadFiles = async (files: File[] /* FileList */, uploadPath: string) => {
    setUploadProgress({});
    setDownloadURLs([]);
    setError(null);

    const uploads = Array.from(files).map((file) => {
      const fileId = uuid();
      return new Promise<any>((resolve, reject) => {
        const storageRef = ref(storage, `${uploadPath}/${fileId}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        const toastId = toast(
          <div className="flex w-full flex-col gap-1">
            <div className="flex items-center justify-between">
              <p className="font-medium">{file.name}</p>
              <span className="text-xs text-muted-foreground">0%</span>
            </div>
            <div className="h-2 w-full overflow-hidden rounded-full bg-secondary">
              <div
                className="h-full bg-primary transition-all duration-300"
                style={{ width: '0%' }}
              />
            </div>
          </div>,
          {
            duration: Number.POSITIVE_INFINITY,
            description: 'Uploading file...',
          }
        );
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress((prev) => ({ ...prev, [file.name]: progress }));
            toast(
              <div className="flex w-full flex-col gap-1">
                <div className="flex items-center justify-between">
                  <p className="font-medium">{file.name}</p>
                  <span className="text-xs text-muted-foreground">{progress}%</span>
                </div>
                <div className="h-2 w-full overflow-hidden rounded-full bg-secondary">
                  <div
                    className="h-full bg-primary transition-all duration-300"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              </div>,
              {
                id: toastId,
                duration: Number.POSITIVE_INFINITY,
                description: 'Uploading file...',
              }
            );
          },
          (err) => {
            setError(err);
            reject(err);
          },
          async () => {
            try {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
              //   setDownloadURLs((prev) => [
              //     ...prev,

              //   ]);
              // Dismiss progress toast
              toast.dismiss(toastId);
              //   );

              // Show success toast
              toast.success(`${file.name} uploaded successfully`, {
                description: 'File is now available',
                action: {
                  label: 'View',
                  onClick: () => window.open(downloadURL, '_blank'),
                },
              });
              //   throw new Error('Test error');
              //   return
              resolve({
                pathRef: uploadPath,
                fileName: file.name,
                type: file.type,
                fileId,
                download: downloadURL,
              });
            } catch (err) {
              setError(err as Error);
              reject({ error: err });
            }
          }
        );
      });
    });

    const result = await Promise.all(uploads);
    return result;
  };

  return { uploadFiles, uploadProgress, downloadURLs, error };
}
