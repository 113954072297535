import { useState, useEffect } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { useAudit } from './audit-context';

const auditTypes = [
  {
    id: 'internal',
    title: 'Intern audit',
  },
  {
    id: 'external',
    title: 'Ekstern audit',
  },
  {
    id: 'internal-inspection',
    title: 'Intern Tilsyn',
  },
  {
    id: 'external-inspection',
    title: 'Ekstern Tilsyn',
  },
  {
    id: 'random-inspection',
    title: 'Pladsbesøg',
  },
  // {
  //   id: 'other',
  //   title: 'Andet',
  // },
];

export function AuditTypeSelection() {
  const { selectedType, setSelectedType } = useAudit();
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-col items-center space-y-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="truncate">
              {selectedType
                ? auditTypes.find((type) => type.id === selectedType)?.title
                : 'Vælg type...'}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command>
            <CommandInput placeholder="Søg efter type..." />
            <CommandList>
              <CommandEmpty>Ingen match</CommandEmpty>
              <CommandGroup>
                {auditTypes.map((type) => (
                  <CommandItem
                    key={type.id}
                    value={type.id}
                    onSelect={(currentValue) => {
                      setSelectedType(currentValue === selectedType ? '' : currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedType === type.id ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {type.title}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {/* <Button onClick={() => setStep('tasks')} disabled={!selectedLocation}>
        Næste
      </Button> */}
    </div>
  );
}
