'use client';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Card, CardContent } from 'components/ui/card';
import { Loader2, MailCheck } from 'lucide-react';
import { ScrollArea } from 'components/ui/scroll-area';

import { useTranslation } from 'react-i18next';
import { useOrderHistory } from '../hooks/use-order-history';
import { Badge } from 'components/ui/badge';
import { getStatusColor, translateStatus } from '../lib/utils';

interface OrderHistoryDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  orderId: string;
}

export function OrderHistoryDialog({ open, onOpenChange, orderId }: OrderHistoryDialogProps) {
  const { t } = useTranslation();
  const { history, loading } = useOrderHistory(orderId);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-hidden flex flex-col">
        <DialogHeader>
          <DialogTitle>
            {t('orderHistory')} - #{orderId}
          </DialogTitle>
        </DialogHeader>

        <div className="flex-1 overflow-hidden">
          <ScrollArea className="h-[60vh]">
            {loading ? (
              <div className="flex flex-col items-center justify-center h-40 gap-2">
                <Loader2 className="h-8 w-8 animate-spin text-primary" />
                <p className="text-sm text-muted-foreground">{t('loading')}</p>
              </div>
            ) : history.length === 0 ? (
              <div className="text-center py-8 text-muted-foreground">
                {t('noHistoryAvailable')}
              </div>
            ) : (
              <div className="space-y-3 p-4">
                {history.map((entry, index) => (
                  <Card key={index} className={index === 0 ? 'border-primary' : ''}>
                    <CardContent className="p-4">
                      <div className="flex justify-between items-center">
                        <div className="space-y-2">
                          {entry.change.type === 'status' && (
                            <div className="flex gap-2">
                              {/* <div className="font-medium">{entry.id}</div> */}
                              <Badge
                                className={
                                  getStatusColor(entry.change?.from) + ' line-through opacity-50'
                                }
                              >
                                {t(translateStatus(entry.change?.from))}
                              </Badge>
                              <Badge className={getStatusColor(entry.change?.to)}>
                                {t(translateStatus(entry.change?.to))}
                              </Badge>
                            </div>
                          )}
                          {entry.change.type === 'orderEmail' && (
                            <div className="flex items-center gap-2">
                              {/* <div className="font-medium">{entry.id}</div>  */}
                              <MailCheck className="h-4 w-4 text-green-600" />
                              <p className="text-sm">Ordre email sendt til aks2tal</p>
                              <Badge className={getStatusColor(entry.change?.to)}>
                                {t(translateStatus(entry.change?.to))}
                              </Badge>
                            </div>
                          )}
                          <div className="text-xs">Opdateret af: {entry.createdBy.displayName}</div>
                        </div>
                        <div className="text-xs text-muted-foreground">
                          <div className="text-xs text-right">
                            {t('general.time', { date: entry.updateTime })}
                          </div>
                          {t('general.getDate', { date: entry.updateTime })}{' '}
                          {t('general.getMonth', { date: entry.updateTime })},{' '}
                          {t('general.getYear', { date: entry.updateTime })}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            )}
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  );
}
