import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { StyleGuide } from 'styles/StyleGuide';
import { Tooltip as AntTooltip } from 'antd';
import { sampleData } from './sampleData';
import { result } from './bubbleSample';
import { Input } from './StackedBar';

type AuditData = {
  subGroup: string;
  isCompleted: string;
};

type SubGroupCounts = {
  name: string;
  incomplete: number;
  completed: number;
};

function getSubGroupCountsWithPrefixRemoved(data: Input[]): SubGroupCounts[] {
  if (data.length === 0) return [];

  // Extract all orgTitles to find the common prefix
  const orgTitles = data.map((d) => d.subGroup);

  // Helper function to find and remove the common prefix
  function removeFlexiblePrefix(arr: string[]): string[] {
    const splitStrings = arr.map((str) => str.split(' '));
    let prefixLength = 0;
    const minLength = Math.min(...splitStrings.map((words) => words.length));

    for (let i = 0; i < minLength; i++) {
      const word = splitStrings[0][i];
      if (splitStrings.every((words) => words[i] === word)) {
        prefixLength += word.length + 1; // +1 for spaces
      } else {
        break;
      }
    }

    return arr.map((str) => str.slice(prefixLength).trim());
  }

  // Remove common prefix from each orgTitle
  const cleanedTitles = removeFlexiblePrefix(orgTitles);

  // Aggregate counts using cleaned titles
  const resultMap = new Map<string, SubGroupCounts>();

  data.forEach((entry, index) => {
    const title = cleanedTitles[index];
    if (!resultMap.has(title)) {
      resultMap.set(title, {
        name: title,
        incomplete: 0,
        completed: 0,
      });
    }

    const group = resultMap.get(title);
    if (group) {
      if (entry.isCompleted === 'true') {
        group.completed += 1;
      } else {
        group.incomplete += 1;
      }
    }
  });

  return Array.from(resultMap.values()).sort((a, b) => a.name.localeCompare(b.name));
}

// count items subGroup
// const data = getSubGroupCountsWithPrefixRemoved(result);
// console.log({ data });

// const data = [
//     {
//         name: "Alarmer",
//         incomplete: 1,
//         completed: 4,
//     },
//     {
//         name: "Belægningkontrol",
//         incomplete: 5,
//         completed: 22,
//     },
//     {
//         name: "Beredskabplan",
//         incomplete: 1,
//         completed: 7,
//     },
//     {
//         name: "Betonelementer",
//         incomplete: 1,
//         completed: 2,
//     },
//     {
//         name: "Brandudstyr",
//         incomplete: 1,
//         completed: 8,
//     },
//     {
//         name: "Brønde, drænbrønde og sandfang",
//         incomplete: 2,
//         completed: 16,
//     },
//     {
//         name: "Dieselforbrug",
//         incomplete: 2,
//         completed: 46,
//     },
//     {
//         name: "Elforbrug",
//         incomplete: 1,
//         completed: 23,
//     },
//     {
//         name: "Elværktøj & kabler",
//         incomplete: 2,
//         completed: 3,
//     },
//     {
//         name: "Førstehjælpsudstyr",
//         incomplete: 1,
//         completed: 8,
//     },
//     {
//         name: "Haller",
//         incomplete: 1,
//         completed: 5,
//     },
//     // {
//     //     name: "Information og oplæring",
//     //     incomplete: 3490,
//     //     completed: 4300,
//     // },
//     {
//         name: "Intern sikkerhedsrundering",
//         incomplete: 3,
//         completed: 4,
//     },
//     {
//         name: "Kemikalier",
//         incomplete: 0,
//         completed: 1,
//     },
//     {
//         name: "Kompressorer, varmepumper og køleanlæg",
//         incomplete: 7,
//         completed: 7,
//     },
//     {
//         name: "Løftegrej",
//         incomplete: 2,
//         completed: 4,
//     },
//     {
//         name: "Maskiner vedligehold",
//         incomplete: 1,
//         completed: 2,
//     },
//     {
//         name: "Olietanke",
//         incomplete: 2,
//         completed: 5,
//     },
//     {
//         name: "Olieudskiller",
//         incomplete: 3,
//         completed: 13,
//     },
//     {
//         name: "Perkolat analyser",
//         incomplete: 2,
//         completed: 3,
//     },
//     {
//         name: "Perkolat-tank/bassin",
//         incomplete: 1,
//         completed: 5,
//     },
//     {
//         name: "Perkolatforbrug",
//         incomplete: 1,
//         completed: 33,
//     },
//     {
//         name: "Porte",
//         incomplete: 1,
//         completed: 7,
//     },
//     {
//         name: "Pumper",
//         incomplete: 5,
//         completed: 34,
//     },
//     {
//         name: "Stiger",
//         incomplete: 2,
//         completed: 5,
//     },
//     {
//         name: "Tæthedsprøvning",
//         incomplete: 2,
//         completed: 12,
//     },
// ];

export default function OrgStackedChart({ audits }: { audits: Input[] }) {
  const data = getSubGroupCountsWithPrefixRemoved(audits);
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={data}
        // layout="vertical"
        barCategoryGap={'50%'} // Space between groups of bars
        barGap={2}
        margin={{
          top: 20,
          right: 30,
          left: -100,
          bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="category"
          dataKey="name"
          interval={0}
          angle={-20}
          tick={{ fontSize: 10, textAnchor: 'end' }}
        />
        <YAxis
          type="number"
          // dataKey="completed"

          width={150}
          // tick={<CustomYAxisTick />}
        />
        <Tooltip />
        {/* <Legend /> */}
        <Bar
          barSize={40}
          name="Afsluttede opgaver"
          dataKey="completed"
          stackId="a"
          fill={StyleGuide.palette.statusSuccessDark}
        />
        <Bar
          name="Overskredne opgaver"
          dataKey="incomplete"
          stackId="a"
          fill={StyleGuide.palette.statusErrorDark}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
