import React from 'react';

export default function BlurOverlay() {
  const topOffset = 72;
  const leftOffset = 200;
  return (
    <div
      style={{
        position: 'fixed',
        top: topOffset,
        zIndex: 1000,
        width: `calc(100vw - ${leftOffset}px)`,
        height: `calc(100vh - ${topOffset}px)`,
        backgroundColor: 'red',
        background: 'rgba(255,255,255,0.1)',
        backdropFilter: 'blur(4px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ fontSize: 50 }}>Denne feature er ikke aktiveret</div>
    </div>
  );
}
