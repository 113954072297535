import { Layout } from 'antd';
import InventoryLayout from './components/inventory-layout';
import { inventoryItems } from './data';
import HeaderBar from 'Layout/HeaderBar';
import FooterBar from 'Layout/FooterBar';

const { Content } = Layout;
// This would typically come from a database

export default function Container() {
  return (
    <Layout>
      <HeaderBar />
      <Content
        style={{
          margin: '24px 24px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <InventoryLayout initialItems={inventoryItems} />
      </Content>
      <FooterBar />
    </Layout>
  );
}
