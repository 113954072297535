import {
  Button,
  Checkbox,
  Dropdown,
  InputNumber,
  List,
  Menu,
  Select,
  Tooltip,
  message,
} from 'antd';
import { useAppSelector } from '../../hooks';
import { sortOnOrderAndCreateTime } from '../../hooks/useAuditTasks';
import { AuditScheduleTask } from '../../modules/auditSchedule/types';
import AuditTaskImages from './Actions/AuditTaskImages';
import AuditTaskNoteAction from './Actions/AuditTaskNoteAction';
import AuditTaskOnHoldAction from './Actions/AuditTaskOnHoldAction';
import confirmModal from 'general/confirmModal';
import { useTranslation } from 'react-i18next';
import AuditTaskCustomFieldsAction from './Actions/AuditTaskCustomFieldsAction';
import AuditTaskDiscardAction from './Actions/AuditTaskDiscardAction';
import { MoreOutlined } from '@ant-design/icons';
import { AuditCompleteState } from '@aks2tal/shared';
import { RenderFiles } from '../InternalAuditDetail/audit-task-upload';

interface Props {
  updateAuditScheduleTask: (data: Partial<AuditScheduleTask>, id: string) => void;
  deleteFile: (fileId: string) => void;
  orgId: string;
  auditId: string;
}

export default function AuditTaskList(props: Props) {
  const { t } = useTranslation();
  const isVoided = useAppSelector(
    (state) => state.auditSchedules.currentAuditSchedule?.state === AuditCompleteState.VOIDED
  );

  const { currentAuditTasks } = useAppSelector((state) => ({
    currentAuditTasks: sortOnOrderAndCreateTime(
      Object.keys(state.auditSchedules.currentAuditSchedule.tasks).map((taskId) => ({
        ...state.auditSchedules.currentAuditSchedule.tasks[taskId as any],
        id: taskId,
      }))
    ),
  }));

  const renderListItem = (task: AuditScheduleTask, _: number) => {
    console.log({ task });
    const hasIncompleteRequiredCustomFields =
      task?.customFields &&
      task?.customFields?.some(
        (cf) => cf.required && (cf.value === null || cf.value === undefined || cf.value === '')
      ); // value is required and not filled out

    const handleToggleCompleteAuditTask = () => {
      if (task.isCompleted) {
        return confirmModal({
          title: 'Er du sikker på du sætte opgaven som ikke godkendt?',
          onOk: () => {
            props.updateAuditScheduleTask(
              {
                isCompleted: !task.isCompleted,
              },
              task.id
            );
          },
        });
      }
      if (!task.isCompleted && task.isOnHold) {
        return confirmModal({
          title: 'OBS: Opgaven er på hold',
          content:
            'Hvis du markere opgaven som godkendt, vil den automatisk ikke længere være på hold.',
          cancelText: 'Annuller',
          okText: 'Markér godkendt',
          okType: 'primary',
          onOk: () => {
            props.updateAuditScheduleTask(
              {
                isCompleted: !task.isCompleted,
                isOnHold: false,
              },
              task.id
            );
          },
        });
      }
      return props.updateAuditScheduleTask(
        {
          isCompleted: !task.isCompleted,
          completeTime: new Date(),
        },
        task.id
      );
    };

    return (
      <List.Item
        className="custom-audit-task-list-item"
        actions={[
          <AuditTaskCustomFieldsAction
            orgId={props.orgId}
            auditId={props.auditId}
            taskId={task.id}
            disabled={isVoided}
          />,
          <AuditTaskImages
            disabled={isVoided}
            orgId={props.orgId}
            contextId={props.auditId}
            subContextId={task.id}
            context="AUDIT_DOCUMENTATION"
            deleteFile={props.deleteFile}
          />,
          <AuditTaskNoteAction
            disabled={isVoided}
            orgId={props.orgId}
            auditId={props.auditId}
            taskId={task.id}
            updateAuditScheduleTask={props.updateAuditScheduleTask}
          />,
          <Dropdown
            disabled={isVoided}
            dropdownRender={() => (
              <Menu>
                <Menu.Item style={{ padding: 0 }}>
                  <AuditTaskOnHoldAction
                    taskId={task.id}
                    updateAuditScheduleTask={props.updateAuditScheduleTask}
                  />
                </Menu.Item>
                <Menu.Item style={{ padding: 0 }}>
                  <AuditTaskDiscardAction
                    orgId={props.orgId}
                    auditId={props.auditId}
                    taskId={task.id}
                  />
                </Menu.Item>
              </Menu>
            )}
            placement="bottomRight"
          >
            <Button icon={<MoreOutlined />}>{t('Flere muligheder')}</Button>
          </Dropdown>,
        ]}
      >
        <List.Item.Meta
          avatar={
            <Tooltip
              title={
                hasIncompleteRequiredCustomFields
                  ? 'Udfyld registeringer for at kunne godkende opgaven'
                  : ''
              }
            >
              <Checkbox
                disabled={isVoided || hasIncompleteRequiredCustomFields}
                className="custom-audit-task-list-item-checkbox"
                checked={task.isCompleted}
                onChange={handleToggleCompleteAuditTask}
              />
            </Tooltip>
          }
          title={<p className="font-semibold">{task.description}</p>}
          description={
            task.attachments && (
              <div className="flex gap-1 items-center">
                <RenderFiles size="sm" attachments={task.attachments} disabled />
              </div>
            )
          }

          // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
        />
      </List.Item>
    );
  };

  if (currentAuditTasks.length > 0) {
    return (
      <List
        style={{ backgroundColor: 'white' }}
        header={<div>Opgave liste</div>}
        bordered
        itemLayout="horizontal"
        dataSource={
          currentAuditTasks.map((i) => ({
            ...i,
            key: i.id,
          })) as AuditScheduleTask[]
        }
        renderItem={renderListItem}
      />
    );
  }
  return null;
}
