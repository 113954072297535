import { Drawer, Col, Row, Input } from 'antd';
import React from 'react';
import { AppState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { closeProfileDrawer } from '../../modules/drawer/actions';
import useProfile from './useProfile';
import Notifications from './Notifications';
import Language from './Language';
import { useTranslation } from 'react-i18next';

function Profile() {
  const isVisible = useSelector((state: AppState) => state.drawer.profile);
  const userDetails = useSelector((state: AppState) => state.user.details);
  useProfile();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeProfile = () => {
    dispatch(closeProfileDrawer());
  };

  return (
    <>
      <Drawer
        title={t('profile.header')}
        width={720}
        onClose={closeProfile}
        open={isVisible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <h3 style={{ paddingBottom: 8 }}>{t('profile.name')}</h3>
            <Input
              placeholder={t('profile.nameplaceholder')}
              value={userDetails.displayName}
              disabled
            />
          </Col>
          <Col span={12}>
            <h3 style={{ paddingBottom: 8 }}>{t('profile.email')}</h3>
            <Input placeholder={t('profile.emailplaceholder')} value={userDetails.email} disabled />
          </Col>
        </Row>
        <div style={{ padding: 24 }} />
        <div style={{ padding: 24, border: '1px solid #d9d9d9' }}>
          <Row gutter={16}>
            <Col span={24}>
              <h3>{t('profile.notifications')}</h3>
              <div style={{ padding: 8 }} />
              <Notifications />
            </Col>
          </Row>
        </div>
        <div style={{ padding: 24 }} />
        <div style={{ padding: 24, border: '1px solid #d9d9d9' }}>
          <Row gutter={16}>
            <Col span={24}>
              <Language />
            </Col>
          </Row>
        </div>
        <div style={{ padding: 24 }} />
        {/* <div style={{ padding: 24, border: "1px solid #d9d9d9" }}>
                <h3>{t("profile.newpassword")}</h3>
                <div style={{ padding: 8 }} />
                <Row gutter={16}>
                    <Col span={24}>
                        <div style={{ paddingBottom: 8 }}>
                            {t("profile.newpasswordlabel")}
                        </div>
                        <Input
                            placeholder={t(
                                "profile.newpasswordplaceholder",
                            )}
                        />
                    </Col>
                </Row>
                <div style={{ padding: 8 }} />
                <Row gutter={16}>
                    <Col span={24}>
                        <div style={{ paddingBottom: 8 }}>
                            {t("profile.confirmpasswordlabel")}
                        </div>
                        <Input
                            placeholder={t(
                                "profile.confirmpasswordplaceholder",
                            )}
                        />
                    </Col>
                    <div style={{ padding: 8 }} />
                    <Col span={24} style={{ textAlign: "right" }}>
                        <Button onClick={() => {}} type="primary">
                            {t("profile.newpasswordsave")}
                        </Button>
                    </Col>
                </Row>
            </div> */}
      </Drawer>
    </>
  );
}
export default Profile;
