import {
  FileText,
  Image,
  Video,
  FileSpreadsheet,
  FileCode,
  File,
  Archive,
  FileJson,
  FileIcon as FilePdf,
  FileType,
  FileAudio,
  Presentation,
  Folder,
  type LucideIcon,
  FileVideo,
} from 'lucide-react';

/**
 * Returns the appropriate icon component based on the file's MIME type or extension
 * @param fileType - The MIME type (e.g., 'image/jpeg') or file extension (e.g., '.pdf')
 * @param defaultIcon - Optional default icon to use if no match is found
 * @returns A Lucide React icon component
 */
export function getFileIcon(
  fileType: string,
  defaultIcon: LucideIcon = File
): { text?: string; icon: LucideIcon } | null {
  // Normalize the input to lowercase
  const type = fileType.toLowerCase();

  // Image files
  if (
    type.startsWith('image/') ||
    ['.jpg', '.jpeg', '.png', '.gif', '.svg', '.webp', '.bmp', '.tiff'].some((ext) =>
      type.endsWith(ext)
    )
  ) {
    return null;
  }

  // Video files
  if (
    type.startsWith('video/') ||
    ['.mp4', '.webm', '.mov', '.avi', '.mkv', '.flv', '.wmv'].some((ext) => type.endsWith(ext))
  ) {
    return { text: 'video', icon: FileVideo };
  }

  // Audio files
  if (
    type.startsWith('audio/') ||
    ['.mp3', '.wav', '.ogg', '.flac', '.aac', '.m4a'].some((ext) => type.endsWith(ext))
  ) {
    return { text: 'Lyd', icon: FileAudio };
  }

  // Document files
  if (type.includes('pdf') || type.endsWith('.pdf')) {
    return { text: 'PDF', icon: FilePdf };
  }

  // Text files
  if (
    type.startsWith('text/') ||
    ['.txt', '.rtf', '.md', '.markdown'].some((ext) => type.endsWith(ext))
  ) {
    return { text: 'text', icon: FileText };
  }

  // Spreadsheet files
  if (
    type.includes('spreadsheet') ||
    type.includes('excel') ||
    ['.xlsx', '.xls', '.csv', '.ods', '.numbers'].some((ext) => type.endsWith(ext))
  ) {
    return { text: 'Excel', icon: FileSpreadsheet };
  }

  // Presentation files
  if (
    type.includes('presentation') ||
    ['.ppt', '.pptx', '.key', '.odp'].some((ext) => type.endsWith(ext))
  ) {
    return { text: 'PPT', icon: Presentation };
  }

  // Code files
  if (
    type.includes('javascript') ||
    type.includes('typescript') ||
    type.includes('html') ||
    type.includes('css') ||
    [
      '.js',
      '.ts',
      '.jsx',
      '.tsx',
      '.html',
      '.css',
      '.scss',
      '.sass',
      '.less',
      '.php',
      '.py',
      '.rb',
      '.java',
      '.c',
      '.cpp',
      '.h',
      '.cs',
      '.go',
    ].some((ext) => type.endsWith(ext))
  ) {
    return { icon: FileCode };
  }

  // JSON files
  if (type.includes('json') || type.endsWith('.json')) {
    return { icon: FileJson };
  }

  // Archive files
  if (
    type.includes('zip') ||
    type.includes('compressed') ||
    type.includes('archive') ||
    ['.zip', '.rar', '.7z', '.tar', '.gz', '.bz2'].some((ext) => type.endsWith(ext))
  ) {
    return { icon: Archive };
  }

  // Folder
  if (type.includes('folder') || type.includes('directory')) {
    return { icon: Folder };
  }

  // Font files
  if (
    type.includes('font') ||
    ['.ttf', '.otf', '.woff', '.woff2', '.eot'].some((ext) => type.endsWith(ext))
  ) {
    return { icon: FileType };
  }

  // Return the default icon if no match is found
  return { icon: defaultIcon };
}

/**
 * Returns the color class for the file icon based on file type
 * @param fileType - The MIME type or file extension
 * @returns A Tailwind CSS color class
 */
export function getFileIconColor(fileType: string): string {
  const type = fileType.toLowerCase();

  // Images - blue
  if (type.startsWith('image/')) {
    return 'text-blue-500';
  }

  // Videos - red
  if (type.startsWith('video/')) {
    return 'text-red-500';
  }

  // Audio - purple
  if (type.startsWith('audio/')) {
    return 'text-purple-500';
  }

  // PPTX - orange
  if (type.includes('.ppt') || type.includes('.pptx') || type.includes('presentation')) {
    return 'text-orange-500';
  }

  // Documents - orange
  if (type.includes('pdf') || type.includes('document')) {
    return 'text-red-500';
  }

  // Spreadsheets - green
  if (type.includes('spreadsheet') || type.includes('excel') || type.includes('csv')) {
    return 'text-green-500';
  }

  // Code - cyan
  if (type.includes('code') || type.includes('javascript') || type.includes('html')) {
    return 'text-cyan-500';
  }

  // Archives - amber
  if (type.includes('zip') || type.includes('archive')) {
    return 'text-amber-500';
  }

  // Default - gray
  return 'text-gray-500';
}
