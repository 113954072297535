import { call, put, takeLatest, fork } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../firebase';
import { fetchInvoicesSuccess, fetchInvoiceSuccess } from './actions';
import { INVOICE_FETCH_REQUESTED } from './types';
import { INVOICES_FETCH_FAILED, INVOICES_FETCH_REQUESTED, INVOICE_FETCH_FAILED } from './types';

function* fetchInvoices() {
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`invoices`).orderBy('createTime', 'desc'),
      { successActionCreator: fetchInvoicesSuccess }
    );
  } catch (e: any) {
    yield put({ type: INVOICES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchInvoicesSaga() {
  // @ts-ignore
  yield takeLatest(INVOICES_FETCH_REQUESTED, fetchInvoices);
}

function* fetchInvoice({ payload }: { payload: { invoiceId: string } }) {
  const id = payload.invoiceId;

  try {
    yield fork(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncDocument,
      firebase.firestore().collection(`invoices`).doc(id),
      { successActionCreator: fetchInvoiceSuccess }
    );
  } catch (e: any) {
    yield put({ type: INVOICE_FETCH_FAILED, message: e.message });
  }
}

export function* fetchInvoiceSaga() {
  // @ts-ignore
  yield takeLatest(INVOICE_FETCH_REQUESTED, fetchInvoice);
}
