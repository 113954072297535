import { AuditType } from '@aks2tal/shared';
import { RequestState } from 'interfaces/request';
import { CustomField, CustomFieldType } from 'modules/audits/types';
export const AUDIT_TEMPLATES_FETCH_SUCCEEDED = 'AUDIT_TEMPLATES_FETCH_SUCCEEDED';
export const AUDIT_TEMPLATES_FETCH_FAILED = 'AUDIT_TEMPLATES_FETCH_FAILED';
export const AUDIT_TEMPLATES_FETCH_REQUESTED = 'AUDIT_TEMPLATES_FETCH_REQUESTED';

export const AUDIT_TEMPLATE_FETCH_SUCCEEDED = 'AUDIT_TEMPLATE_FETCH_SUCCEEDED';
export const AUDIT_TEMPLATE_FETCH_FAILED = 'AUDIT_TEMPLATE_FETCH_FAILED';
export const AUDIT_TEMPLATE_FETCH_REQUESTED = 'AUDIT_TEMPLATE_FETCH_REQUESTED';

export const AUDIT_TEMPLATE_UPDATE_SUCCEEDED = 'AUDIT_TEMPLATE_UPDATE_SUCCEEDED';
export const AUDIT_TEMPLATE_UPDATE_FAILED = 'AUDIT_TEMPLATE_UPDATE_FAILED';
export const AUDIT_TEMPLATE_UPDATE_REQUESTED = 'AUDIT_TEMPLATE_UPDATE_REQUESTED';

export const AUDIT_TEMPLATE_CREATE_SUCCEEDED = 'AUDIT_TEMPLATE_CREATE_SUCCEEDED';
export const AUDIT_TEMPLATE_CREATE_FAILED = 'AUDIT_TEMPLATE_CREATE_FAILED';
export const AUDIT_TEMPLATE_CREATE_REQUESTED = 'AUDIT_TEMPLATE_CREATE_REQUESTED';

export const AUDIT_TEMPLATE_DELETE_SUCCEEDED = 'AUDIT_TEMPLATE_DELETE_SUCCEEDED';
export const AUDIT_TEMPLATE_DELETE_FAILED = 'AUDIT_TEMPLATE_DELETE_FAILED';
export const AUDIT_TEMPLATE_DELETE_REQUESTED = 'AUDIT_TEMPLATE_DELETE_REQUESTED';

export const AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED = 'AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED';
export const AUDIT_TEMPLATE_TASKS_FETCH_FAILED = 'AUDIT_TEMPLATE_TASKS_FETCH_FAILED';
export const AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED = 'AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED';

export const AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED = 'AUDIT_TEMPLATE_TASK_ADD_SUCCEEDED';
export const AUDIT_TEMPLATE_TASK_ADD_FAILED = 'AUDIT_TEMPLATE_TASK_ADD_FAILED';
export const AUDIT_TEMPLATE_TASK_ADD_REQUESTED = 'AUDIT_TEMPLATE_TASK_ADD_REQUESTED';

export const AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED = 'AUDIT_TEMPLATE_TASK_DELETE_SUCCEEDED';
export const AUDIT_TEMPLATE_TASK_DELETE_FAILED = 'AUDIT_TEMPLATE_TASK_DELETE_FAILED';
export const AUDIT_TEMPLATE_TASK_DELETE_REQUESTED = 'AUDIT_TEMPLATE_TASK_DELETE_REQUESTED';

export const AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED = 'AUDIT_TEMPLATE_TASK_UPDATE_SUCCEEDED';
export const AUDIT_TEMPLATE_TASK_UPDATE_FAILED = 'AUDIT_TEMPLATE_TASK_UPDATE_FAILED';
export const AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED = 'AUDIT_TEMPLATE_TASK_UPDATE_REQUESTED';

export const AUDIT_TEMPLATE_ADD_SUCCEEDED = 'AUDIT_TEMPLATE_ADD_SUCCEEDED';
export const AUDIT_TEMPLATE_ADD_FAILED = 'AUDIT_TEMPLATE_ADD_FAILED';
export const AUDIT_TEMPLATE_ADD_REQUESTED = 'AUDIT_TEMPLATE_ADD_REQUESTED';

export const AUDIT_TEMPLATE_FILE_DELETE_REQUESTED = 'AUDIT_TEMPLATE_FILE_DELETE_REQUESTED';
export const AUDIT_TEMPLATE_FILE_DELETE_FAILED = 'AUDIT_TEMPLATE_FILE_DELETE_FAILED';

export const AUDIT_TEMPLATE_SYNC_SUCCEEDED = 'AUDIT_TEMPLATE_SYNC_SUCCEEDED';

export const AUDIT_TEMPLATE_CATEGORIES = 'AUDIT_TEMPLATE_CATEGORIES';

export interface AuditTemplateTask {
  id: string;
  createTime: { toDate: () => Date };
  description: string;
  order?: number;
  customFields?: CustomField[];
}

export interface AuditGuide {
  filePath: string;
  contentType: string;
  fileName: string;
  isDeleted: boolean;
  path: string;
}

export interface AuditTemplate {
  guide: AuditGuide | null;
  // isMultiDayAudit?: boolean;
  // notifyDaysAfterDueDate?: number | null;
  title: string;
  note?: string;
  createTime: Date;
  id: string;
  auditTemplateCategory?: string | null;
  auditTemplateType?: AuditType | null;
}

export type AuditTemplateCategoryMap = {
  [id: string]: AuditTemplateCategory;
};

// from group level categories
export interface AuditTemplateCategory {
  name: string;
  id: string;
}
export interface AuditTemplateSync {
  auditTemplateId: string;
  createTime: Date;
  dataToSync: Partial<AuditTemplate>;
  orgId: string;
  status: RequestState;
  syncTime: Date;
}

export interface AuditTemplateState {
  auditTemplates: AuditTemplate[];
  currentAuditTemplate: AuditTemplate;
  currentAuditTemplateTasks: AuditTemplateTask[];
  auditTemplateSync: AuditTemplateSync | null;
  loading: boolean;
  auditTemplateCategoriesMap: AuditTemplateCategoryMap;
}

interface FetchAuditTemplateLoading {
  type:
    | typeof AUDIT_TEMPLATES_FETCH_REQUESTED
    | typeof AUDIT_TEMPLATE_FETCH_REQUESTED
    | typeof AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED;
  payload: {};
}
interface FetchAuditTemplateFailed {
  type: typeof AUDIT_TEMPLATES_FETCH_FAILED | typeof AUDIT_TEMPLATE_FETCH_FAILED;
  payload: {};
}

interface FetchAuditTemplatesSuccess {
  type: typeof AUDIT_TEMPLATES_FETCH_SUCCEEDED;
  payload: {
    data: any;
  };
}
interface FetchAuditTemplateSuccess {
  type: typeof AUDIT_TEMPLATE_FETCH_SUCCEEDED;
  payload: {
    data: any;
  };
}
interface FetchAuditTemplateTasksSuccess {
  type: typeof AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED;
  payload: {
    data: any[];
  };
}
interface SetAuditTemplateSyncSuccess {
  type: typeof AUDIT_TEMPLATE_SYNC_SUCCEEDED;
  payload: AuditTemplateSync | null;
}
interface SetAuditTemplateCategoriesSuccess {
  type: typeof AUDIT_TEMPLATE_CATEGORIES;
  payload: AuditTemplateCategoryMap;
}

export type AuditTemplateActionTypes =
  | FetchAuditTemplateLoading
  | FetchAuditTemplateFailed
  | FetchAuditTemplatesSuccess
  | FetchAuditTemplateSuccess
  | FetchAuditTemplateTasksSuccess
  | SetAuditTemplateSyncSuccess
  | SetAuditTemplateCategoriesSuccess;
