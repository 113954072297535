import React from 'react';

export default function SeFlag() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="flag-icons-se"
      style={{ width: 20 }}
      viewBox="0 0 640 480"
    >
      <path fill="#066aa7" d="M0 0h640v480H0z" />
      <path fill="#fecc00" d="M0 192h640v96H0z" />
      <path fill="#fecc00" d="M176 0h96v480h-96z" />
    </svg>
  );
}
