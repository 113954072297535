'use client';

import { useEffect, useState } from 'react';
import { locations, masterTaskList, taskMatrix } from '../mockData/auditData';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'components/ui/dialog';
import { Card, CardContent } from 'components/ui/card';
import { Calendar, AlertCircle, CheckCircle2, MinusCircle, TriangleAlert } from 'lucide-react';
import { format } from 'date-fns';
import { Badge } from 'components/ui/badge';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import firebase from 'firebase/compat/app';
import { useAppSelector } from 'hooks';
import { Checkbox } from 'components/ui/checkbox';

export type AuditSubTaskAttachements = {
  download: string;
  fileId: string;
  fileName: string;
  pathRef: string;
  type: string;
};

export type SubTask = {
  id: string;
  label: string;
  description?: string;
  status: 'not-audited' | 'passed' | 'observation' | 'deviation' | 'deviation-resolved';
  taskId: string;
  attachments?: AuditSubTaskAttachements[];
  responsible?: {
    id: string;
    displayName: string;
  };
  dueDate?: firebase.firestore.Timestamp;
};

export type Task = {
  id: string;
  label: string;
  subTaskIds: string[];
  category: string;
  order: number;
};

export interface InternalAudit {
  id: string;
  auditor: {
    userId: string;
    displayName: string;
  };
  createTime: firebase.firestore.Timestamp;
  completeTime?: firebase.firestore.Timestamp;
  groupId: string | null;
  location: {
    id: string;
    title: string;
  };
  state: 'DRAFT' | 'COMPLETED';
  subGroup: {
    id: string;
    title: string;
  } | null;
  subTasksMap: {
    [taskId: string]: SubTask;
  };
  tasksMap: {
    [taskId: string]: Task;
  };
}
export const getStatusFollowupColor = (status: string) => {
  switch (status) {
    case 'passed':
      return 'bg-green-50 text-green-600';
    case 'observation':
      return 'bg-orange-50 text-orange-600';
    case 'deviation':
      return 'bg-red-50 text-red-600';
    default:
      return 'bg-gray-100 text-gray-600';
  }
};
export const getStatusColor = (status: string) => {
  switch (status) {
    case 'passed':
      return 'bg-green-100 hover:bg-green-200 text-green-600';
    case 'observation':
      return 'bg-orange-100 hover:bg-orange-200 text-orange-600';
    case 'deviation':
      return 'bg-red-100 hover:bg-red-200 text-red-600';
    case 'deviation-resolved':
      return 'bg-green-300 hover:bg-green-200 text-green-800';
    case 'not-audited':
      return 'bg-gray-100 hover:bg-gray-100 text-gray-600';
    default:
      return 'bg-gray-100 hover:bg-gray-100 text-gray-600';
  }
};

const fileObj = {
  type: 'image/png',
  download: 'https://placehold.co/400x400/png',
};

export const getStatusIcon = (status: string) => {
  console.log({ status });

  switch (status) {
    case 'passed':
      return <CheckCircle2 className="w-4 h-4 text-green-600" />;
    case 'observation':
      return <TriangleAlert className="w-4 h-4 text-orange-600" />;
    case 'deviation':
      return <AlertCircle className="w-4 h-4 text-red-600" />;
    case 'deviation-resolved':
      return <AlertCircle className="w-4 h-4 text-green-800" />;
    case 'not-audited':
      return <MinusCircle className="w-4 h-4 text-gray-600" />;
    default:
      return <MinusCircle className="w-4 h-4 text-gray-600" />;
  }
};

export function AuditMatrix() {
  const userId = useAppSelector((state) => state.user.details.uid);
  const [audits, setAudits] = useState<any[]>([]);
  const [selectedCell, setSelectedCell] = useState<{
    taskId: string;
    locationId: string;
  } | null>(null);

  const [selecteAuditItem, setSelectedAuditItem] = useState<any | null>(null);
  const currentAudit = audits.find((audit) => audit.id === selecteAuditItem);
  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`internalAudits`)
      // .orderBy('createTime', 'desc')
      .where('auditor.userId', '==', userId)
      .where('state', '==', 'COMPLETED')
      .orderBy('completeTime', 'asc')
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          console.log({ list });

          setAudits(list);
        }
      }, console.error);
    return () => unsubscribe?.();
  }, [userId]);
  const matrix = buildLatestTaskMatrix(audits, masterTaskList);

  return (
    <div className="overflow-x-auto">
      <div className="min-w-max">
        {/* Header row with location names */}
        <div className="grid grid-cols-[250px_repeat(5,180px)] gap-1 mb-1">
          <div className="font-bold p-2">Opgave</div>
          {locations.map((location) => (
            <div className="flex flex-col">
              <div key={location.id} className="font-bold px-2 text-sm">
                {location.name}
              </div>
              <div className="px-2 text-xs text-neutral-500">
                {format(location.latestAuditDate, 'dd/MM/yyyy')}
              </div>
            </div>
          ))}
        </div>

        {/* Task rows */}
        {masterTaskList.map((task) => (
          <div key={task.id} className="grid grid-cols-[250px_repeat(5,180px)] gap-1 mb-1">
            <div className="p-2 bg-gray-50">
              {task.label}
              <div className="text-xs text-neutral-500">({task.category})</div>
            </div>
            {locations.map((location) => {
              const cellData = matrix[task.id]?.[location.id];
              const status = getStatusByImportance(cellData?.status);
              const statusColor = getStatusColor(status || 'not-audited');
              const hasCompleted = false;
              // cellData?.followUpTasks?.length > 0 &&
              // cellData?.followUpTasks.every((t) => t.completed);

              const hasStatuses = cellData?.status?.filter(
                (status) => status !== 'not-audited' || !status
              );

              const noStatus = !hasStatuses || hasStatuses.length < 1;

              const getIcons = !noStatus
                ? hasStatuses.map((status) => getStatusIcon(status))
                : getStatusIcon('not-audited');

              return (
                <Dialog key={location.id}>
                  <DialogTrigger asChild disabled={noStatus}>
                    <button
                      className={`p-4 h-16 flex items-center justify-center transition-colors ${
                        noStatus ? 'cursor-not-allowed' : ''
                      } ${statusColor} ${
                        hasCompleted &&
                        `${getStatusColor('deviation-resolved')} hover:!bg-green-200`
                      }`}
                      onClick={() => setSelectedAuditItem(cellData.auditId)}
                    >
                      <div className="flex gap-1">{getIcons}</div>
                    </button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>{location.name}</DialogTitle>
                    </DialogHeader>
                    <AuditContent audit={currentAudit} taskId={task.id} />
                  </DialogContent>
                </Dialog>
              );
            })}
          </div>
        ))}
      </div>
      {/* <div style={{ position: 'fixed', top: 0, right: 0 }}>
        <div>Alarmer</div>
        <div>Belægningskontrol</div>
        <div>Beredskabsplan</div>
        <div>Betonelementer</div>
        <div>Brandudstyr</div>
        <div>
          Brønde, drænbrønde <br />
          og sandfang
        </div>
        <div>Dieselforbrug</div>
        <div>Elforbrug</div>
        <div>Elværktøj & kabler</div>
        <div>Haller</div>
        <div>Intern sikkerhedsrundering</div>
        <div>Kemikalier</div>
        <div>
          Kompressorer, varmepumper <br />
          og køleanlæg
        </div>
        <div>Løftegrej</div>
        <div>Maskiner vedligehold</div>
        <div>Olietanke</div>
        <div>Olieudskiller</div>
        <div>Perkolat analyser</div>
        <div>Perkolat-tank/bassin</div>
        <div>Perkolatforbrug</div>
        <div>Porte</div>
        <div>Pumper</div>
        <div>Stiger</div>
        <div>Tæthedsprøvning</div>
        <div>Årsforbrug af El</div>
        <div>Årsforbrug af diesel</div>
      </div> */}
    </div>
  );
}

function AuditContent({ audit, taskId }: { audit: InternalAudit; taskId: string }) {
  const tmpId = taskId.replace('task', '');
  const task = audit.tasksMap[taskId];
  const subTasks = Object.entries(audit.subTasksMap)
    .filter(([_, subTask]) => subTask.taskId === taskId)
    .map(([_, subTask]) => subTask);
  console.log({ audit, tmpId, taskId, subTasks });

  return (
    <div className="space-y-4">
      <h3>{task.label}</h3>
      {subTasks.map((subTask) => {
        // @ts-ignore
        const hasCompleted = audit?.deviationState?.[subTask.id]?.isCompleted;

        const status = hasCompleted ? 'deviation-resolved' : subTask.status;

        return (
          <Card className={`shadow-none ${getStatusColor(status)}`}>
            <CardContent className="pt-6">
              <div className="flex flex-col gap-2">
                {subTask.label}
                {status === 'observation' && (
                  <div className="flex items-center space-x-2">
                    <Checkbox id="terms" />
                    <label
                      htmlFor="terms"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Kvitter for denne er set
                    </label>
                  </div>
                )}
                {status === 'deviation' && (
                  <div className="text-sm">Afvigelse oprettet til udbedring</div>
                )}

                {status === 'deviation-resolved' && (
                  <div className="text-sm">
                    Afvigelse udberedet
                    <div className="flex items-center space-x-2 pt-2">
                      <Checkbox id="terms" />
                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        Kvitter for godkendelse af udbedring
                      </label>
                    </div>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>
        );
      })}
      {/* <Accordion type="multiple" className="w-full">
                        {task.subTasks.map((subTask) => {
                          const followUp = cellData?.followUpTasks?.filter(
                            (f) => f.subTaskId === subTask.id
                          );
                          return (
                            <AccordionItem
                              className="border-none"
                              value={`item-${subTask.id}`}
                              key={subTask.id}
                            >
                              <div className="flex items-center justify-between ">
                                <div className="flex w-full items-center space-x-2">
                                  <label
                                    htmlFor={subTask.id}
                                    className="flex grow text-sm cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                                  >
                                    {subTask.label}
                                  </label>

                                  {followUp?.length > 0 && (
                                    <Badge
                                      className={
                                        statusColor +
                                        ' ' +
                                        `${
                                          hasCompleted &&
                                          'bg-green-200 text-green-500 hover:bg-green-200'
                                        }`
                                      }
                                    >
                                      {followUp?.length}
                                    </Badge>
                                  )}
                                </div>
                                <AccordionTrigger></AccordionTrigger>
                              </div>
                              <AccordionContent>
                                {followUp?.length > 0 && (
                                  <div className="space-y-2">
                                    {followUp.map((followUp) => {
                                      const isObservation = cellData.status === 'observation';

                                      const hasCompleted = followUp.completed;
                                      return (
                                        <Card
                                          key={followUp.id}
                                          className={
                                            getStatusFollowupColor(cellData.status) +
                                            ' shadow-none ' +
                                            `${hasCompleted && 'bg-green-50 text-black '}`
                                          }
                                        >
                                          <CardContent className="p-4 space-y-2">
                                            <p>{followUp.description}</p>
                                            {!isObservation && (
                                              <div className="flex items-center gap-4 text-sm text-muted-foreground">
                                                <Badge variant="secondary">
                                                  {followUp.responsible}
                                                </Badge>
                                                <span className="flex items-center gap-1">
                                                  <Calendar className="w-4 h-4" />
                                                  {format(followUp.deadline, 'dd/MM/yyyy')}
                                                </span>
                                              </div>
                                            )}
                                          </CardContent>
                                        </Card>
                                      );
                                    })}
                                  </div>
                                )}
                              </AccordionContent>
                            </AccordionItem>
                          );
                        })}
                      </Accordion> */}
    </div>
  );
}

function getStatusByImportance(statuses: string[] = []): string {
  if (statuses.includes('deviation-resolved')) return 'deviation-resolved';
  if (statuses.includes('deviation')) return 'deviation';
  if (statuses.includes('observation')) return 'observation';
  if (statuses.includes('passed')) return 'passed';
  return 'not-audited';
}

type TaskMatrix = {
  [taskId: string]: {
    [locationId: string]: {
      auditId: string;
      completeTime?: Date;
      status: any[];
      followUpTasks: string[];
    };
  };
};

export function buildLatestTaskMatrix(
  audits: InternalAudit[],
  masterTasks: typeof masterTaskList
): TaskMatrix {
  const latestAudits: { [locationId: string]: InternalAudit } = {};

  audits.forEach((audit) => {
    if (!audit.completeTime) return;
    const locId = audit.location.id;
    if (
      !latestAudits[locId] ||
      audit.completeTime.toMillis() > latestAudits[locId].completeTime!.toMillis()
    ) {
      latestAudits[locId] = audit;
    }
  });

  const matrix: TaskMatrix = {};
  masterTasks.forEach((mt) => {
    matrix[mt.id] = {};
  });

  Object.values(latestAudits).forEach((audit) => {
    const locationId = audit.location.id;
    Object.values(audit.subTasksMap).forEach((subTask) => {
      const taskId = subTask.taskId;
      if (!matrix[taskId]) matrix[taskId] = {};
      if (!matrix[taskId][locationId]) {
        matrix[taskId][locationId] = {
          auditId: audit.id,

          completeTime: audit.completeTime?.toDate(),
          status: [
            // @ts-ignore
            audit?.deviationState?.[subTask.id]?.isCompleted
              ? 'deviation-resolved'
              : subTask.status,
          ],
          followUpTasks: [],
        };
      } else {
        matrix[taskId][locationId].status.push(subTask.status);
      }
    });
  });

  return matrix;
}
