import { useEffect } from 'react';
import keyBy from 'lodash.keyby';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import AuditList from './AuditList';
import { setCurrentTab } from '../../modules/audits/actions';
import {
  fetchCompleteAuditSchedules,
  fetchInCompleteAuditSchedules,
  fetchOverdueAuditSchedules,
} from '../../modules/auditSchedule/actions';
import { fetchAuditTemplates } from '../../modules/auditTemplates/actions';
import { useAppSelector } from '../../hooks';
import { AuditSchedule } from 'modules/auditSchedule/types';
import { getISOWeek, getWeek, subDays } from 'date-fns';
import { sortOnStartAndDueDate } from 'general/sort';
import useHasOnHoldAudits from 'modules/auditSchedule/useHasOnHoldAudits';
import useNeedsResolutionAudits from 'modules/auditSchedule/useNeedsResolutionAudits';

function getAuditTasks(audits: AuditSchedule[]) {
  return sortOnStartAndDueDate(
    audits.map((audit: any) => ({
      ...audit,
      startDate: audit.startDaysBeforeDueDate
        ? subDays(audit.dueDate.toDate(), audit.startDaysBeforeDueDate)
        : null,
      tasks: Object.keys(audit.tasks).map((taskId) => ({
        id: taskId,
        ...audit.tasks[taskId],
      })),
    }))
  );
}
function AuditListContainer() {
  const dispatch = useDispatch();

  const {
    audits,
    overDueAuditTasks,
    inCompleteAuditTasks,
    onHoldAuditTasks,
    needsResoltionAuditTasks,
    completeAuditTasks,
    auditTemplatesByKey,
    noInCompleteTasks,
    loading,
    currentTab,
    members,
    limit,
    userId,
  } = useAppSelector((state) => ({
    limit: state.audits.completeAuditLimit,
    audits:
      state.audits.currentTab === 'completed'
        ? state.auditSchedules.completeAuditSchedules
        : state.auditSchedules.inCompleteAuditSchedules,
    overDueAuditTasks: getAuditTasks(state.auditSchedules.overdueAuditSchedules),
    inCompleteAuditTasks: groupTasksByWeekNumber(
      getAuditTasks(state.auditSchedules.inCompleteAuditSchedules)
    ),
    onHoldAuditTasks: state.auditSchedules.onHoldAuditSchedules,
    needsResoltionAuditTasks: state.auditSchedules.needsResolutionAuditSchedules,
    completeAuditTasks: getAuditTasks(state.auditSchedules.completeAuditSchedules),
    auditTemplatesByKey: keyBy(state.auditTemplates.auditTemplates, (template) => template.id),
    userId: state.user.details.uid,
    loading: state.auditSchedules.loading,
    currentTab: state.audits.currentTab,
    members: state.orgs.currentOrg?.members,
    noInCompleteTasks:
      state.auditSchedules.inCompleteAuditSchedules.length === 0 &&
      state.auditSchedules.overdueAuditSchedules.length === 0,
  }));
  const { orgId } = useParams<{ orgId: string }>();
  useHasOnHoldAudits(orgId);
  useNeedsResolutionAudits(orgId);
  useEffect(() => {
    dispatch(fetchInCompleteAuditSchedules({ orgId }));
    dispatch(fetchOverdueAuditSchedules({ orgId }));
    dispatch(fetchAuditTemplates({ orgId }));
  }, [orgId, dispatch]);
  useEffect(() => {
    dispatch(fetchCompleteAuditSchedules({ orgId, limit }));
  }, [orgId, dispatch, limit]);
  console.log({ inCompleteAuditTasks });
  return (
    <AuditList
      setCurrentTab={({ key }: { key: string }) => dispatch(setCurrentTab({ key }))}
      overDueAuditTasks={overDueAuditTasks}
      inCompleteAuditTasks={inCompleteAuditTasks}
      onHoldAuditTasks={onHoldAuditTasks}
      needsResoltionAuditTasks={needsResoltionAuditTasks}
      completeAuditTasks={completeAuditTasks}
      auditTemplatesByKey={auditTemplatesByKey}
      currentTab={currentTab}
      loading={loading}
      members={members}
      orgId={orgId}
      audits={audits}
      currentWeek={getISOWeek(new Date())}
      noInCompleteTasks={noInCompleteTasks}
      userId={userId}
    />
  );
}

export default AuditListContainer;

const groupTasksByWeekNumber = (tasks: AuditSchedule[]): Record<string, AuditSchedule[]> => {
  const groupedTasks: Record<string, AuditSchedule[]> = {};

  tasks.forEach((task) => {
    const weekNumber = getISOWeek(task.dueDate.toDate()).toString();

    if (groupedTasks[weekNumber]) {
      groupedTasks[weekNumber].push(task);
    } else {
      groupedTasks[weekNumber] = [task];
    }
  });

  return groupedTasks;
};
