import { useEffect, useState } from 'react';
import {
  collectionGroup,
  query,
  where,
  getDocs,
  FirestoreError,
  collection,
  getCountFromServer,
} from 'firebase/firestore';
import { firestore } from '../firebase';

interface AuditSchedule {
  // Define the structure of your auditSchedule data
  id: string;
  //... other properties
}

export function getFutureAuditScheduleCollectionSameTemplateIdRecurringId({
  orgId,
  templateId,
  recurringId,
  currentDate,
}: {
  orgId: string;
  templateId: string;
  recurringId?: string;
  currentDate: Date;
}) {
  return query(
    collection(firestore, 'orgs', orgId, 'auditSchedules'),
    where('templateId', '==', templateId),
    where('recurringId', '==', recurringId),
    where('dueDate', '>', currentDate)
  );
}

export const useFutureAuditScheduleSameTemplateIdRecurringIdCount = ({
  orgId,
  templateId,
  recurringId,
  currentDate,
}: {
  orgId: string;
  templateId?: string;
  recurringId?: string;
  currentDate?: Date;
}) => {
  // State to store the query results
  const [auditCount, setAuditCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<FirestoreError | null>(null);

  useEffect(() => {
    const getCount = async () => {
      try {
        if (!orgId || !templateId || !currentDate) return;
        const coll = query(
          collection(firestore, 'orgs', orgId, 'auditSchedules'),
          where('templateId', '==', templateId),
          where('recurringId', '==', recurringId),
          where('dueDate', '>', currentDate)
        );
        const snapshot = await getCountFromServer(coll);
        setAuditCount(snapshot.data().count);
      } catch (err: any) {
        setError(err);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getCount();

    return () => {};
  }, []);

  return { auditCount, loading, error };
};
