import { RenderTags } from 'Views/Issues/IssueItem';
import { Avatar, Badge, List, Spin } from 'antd';
import useIssueThumbnailImage from 'hooks/useIssueThumbnailImage';
import React from 'react';
import { useHistory } from 'react-router';

type Props = {
  taskCount?: number;
  color: string;
  linkPath: string;
  title: string;
  subTitle?: string;
  description: React.ReactNode;
  orgId: string;
  file?: string;
  tags?: string[];
};

export default function IssueItem({
  tags,
  color,
  linkPath,
  title,
  subTitle,
  description,
  orgId,
  file,
}: Props) {
  const history = useHistory();
  const { image, loadingImage } = useIssueThumbnailImage(orgId, file);
  return (
    <List.Item
      className="dashboard-list-item"
      style={{
        cursor: 'pointer',
        paddingLeft: 24,
        paddingRight: 24,
      }}
      actions={[<RenderTags tags={tags || []} />]}
      onClick={() => {
        history.push(linkPath);
      }}
    >
      <List.Item.Meta
        avatar={
          file ? (
            loadingImage ? (
              <Avatar shape="square">
                <Spin />
              </Avatar>
            ) : (
              <Avatar shape="square" src={image} />
            )
          ) : undefined
        }
        title={
          <div>
            <div style={{ color, whiteSpace: 'pre-line' }}>{title}</div>
            <div
              style={{
                color: 'grey',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {subTitle}
            </div>
          </div>
        }
        description={
          <div>
            <div
              style={{
                fontSize: 12,
                lineHeight: 1.2,
                whiteSpace: 'pre-line',
              }}
            >
              {description}
            </div>
          </div>
        }
      />
    </List.Item>
  );
}
