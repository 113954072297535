'use client';

import { useState } from 'react';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Input } from 'components/ui/input';
import { Badge } from 'components/ui/badge';
import {
  AlertCircle,
  ChevronDown,
  ChevronUp,
  Edit,
  Eye,
  Filter,
  Search,
  SlidersHorizontal,
} from 'lucide-react';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { useAudit } from './audit-context';
import { getCounts } from '../InternalAuditDetail/audit-summary';
import { useTranslation } from 'react-i18next';
import { getStatusColor, getStatusIcon } from '../InternalAuditOverview/components/AuditMatrix';

// Sample data structure
interface Audit {
  id: string;
  createTime: Date;
  locationName: string;
  isDraft: boolean;
  conductor: string;
  counts: {
    deviations: number;
    observations: number;
    passed: number;
  };
}

// Sample data
// const sampleAudits: Audit[] = [
//   {
//     id: '1',
//     createdAt: new Date('2023-11-15T09:30:00'),
//     locationName: 'RGS Gadstrup',
//     isDraft: false,
//     conductor: 'Louise Jensen',
//     counts: {
//       deviations: 3,
//       observations: 2,
//       passed: 45,
//     },
//   },
//   {
//     id: '2',
//     createdAt: new Date('2023-11-14T14:15:00'),
//     locationName: 'RGS Herlufmagle',
//     isDraft: true,
//     conductor: 'Louise Jensen',
//     counts: {
//       deviations: 0,
//       observations: 1,
//       passed: 12,
//     },
//   },
//   {
//     id: '3',
//     createdAt: new Date('2023-11-13T11:45:00'),
//     locationName: 'RGS Vemmelev',
//     isDraft: false,
//     conductor: 'Louise Jensen',
//     counts: {
//       deviations: 5,
//       observations: 3,
//       passed: 32,
//     },
//   },
//   {
//     id: '4',
//     createdAt: new Date('2023-11-12T16:20:00'),
//     locationName: 'RGS Gadstrup',
//     isDraft: false,
//     conductor: 'Louise Jensen',
//     counts: {
//       deviations: 1,
//       observations: 4,
//       passed: 40,
//     },
//   },
//   {
//     id: '5',
//     createdAt: new Date('2023-11-11T10:00:00'),
//     locationName: 'RGS Gadstrup',
//     isDraft: true,
//     conductor: 'Jens Nord',
//     counts: {
//       deviations: 0,
//       observations: 0,
//       passed: 8,
//     },
//   },
// ];

export default function AuditTable() {
  const { t } = useTranslation();
  const history = useHistory();
  const { audits } = useAudit();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<keyof Audit | ''>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  // Filter audits based on search term
  const filteredAudits = audits.filter(
    (audit) =>
      audit.subGroup?.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      audit.auditor?.displayName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort audits
  const sortedAudits = [...filteredAudits].sort((a, b) => {
    if (sortField === '') {
      // Default sort by date
      return sortDirection === 'desc'
        ? b.createTime.toDate().getTime() - a.createTime.toDate().getTime()
        : a.createTime.toDate().getTime() - b.createTime.toDate().getTime();
    }

    if (sortField === 'createTime') {
      return sortDirection === 'desc'
        ? b.createTime.toDate().getTime() - a.createTime.toDate().getTime()
        : a.createTime.toDate().getTime() - b.createTime.toDate().getTime();
    }

    // if (sortField === 'location.title' || sortField === 'conductor') {
    //   const aValue = a[sortField].toLowerCase();
    //   const bValue = b[sortField].toLowerCase();
    //   return sortDirection === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
    // }

    return 0;
  });

  // Handle sort
  const handleSort = (field: keyof Audit) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  // Render sort indicator
  const renderSortIndicator = (field: keyof Audit) => {
    if (sortField !== field) return null;
    return sortDirection === 'asc' ? (
      <ChevronUp className="ml-1 h-4 w-4" />
    ) : (
      <ChevronDown className="ml-1 h-4 w-4" />
    );
  };

  return (
    <>
      <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2 mt-4">
        <div className="relative flex-1">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder="Søg efter site..."
            className="pl-8"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        {/* <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="flex items-center gap-1">
              <Filter className="h-4 w-4 mr-1" />
              <span className="hidden sm:inline">Filter</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem>Show All</DropdownMenuItem>
            <DropdownMenuItem>Drafts Only</DropdownMenuItem>
            <DropdownMenuItem>Completed Only</DropdownMenuItem>
            <DropdownMenuItem>With Deviations</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Button variant="outline" className="flex items-center gap-1">
          <SlidersHorizontal className="h-4 w-4 mr-1" />
          <span className="hidden sm:inline">Advanced</span>
        </Button> */}
      </div>

      <div className="rounded-md border overflow-hidden">
        <div className="overflow-x-auto">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead
                  className="w-[180px] cursor-pointer"
                  onClick={() => handleSort('createTime')}
                >
                  <div className="flex items-center">
                    Dato/Tid
                    {renderSortIndicator('createTime')}
                  </div>
                </TableHead>
                <TableHead className="cursor-pointer" onClick={() => handleSort('locationName')}>
                  <div className="flex items-center">
                    Site
                    {renderSortIndicator('locationName')}
                  </div>
                </TableHead>
                {/* <TableHead>Status</TableHead> */}
                <TableHead>Statistik</TableHead>
                <TableHead className="cursor-pointer" onClick={() => handleSort('conductor')}>
                  <div className="flex items-center">
                    Auditeret af
                    {renderSortIndicator('conductor')}
                  </div>
                </TableHead>
                <TableHead className="text-right"></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sortedAudits.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} className="h-24 text-center">
                    No audits found.
                  </TableCell>
                </TableRow>
              ) : (
                sortedAudits.map((audit) => {
                  const { deviations, observations, passed } = getCounts(audit.subTasksMap);
                  const isDraft = audit.state === 'DRAFT';
                  return (
                    <TableRow key={audit.id}>
                      <TableCell className="font-medium">
                        {t('general.getDate', { date: audit.createTime.toDate() })}{' '}
                        {t('general.getMonth', { date: audit.createTime.toDate() })},{' '}
                        {t('general.getYear', { date: audit.createTime.toDate() })}
                        <div className="text-xs text-muted-foreground">
                          {t('general.time', { date: audit.createTime.toDate() })}
                        </div>
                      </TableCell>
                      <TableCell>
                        {audit.subGroup?.title}{' '}
                        {isDraft && <Badge variant={'outline'}>Kladde</Badge>}
                      </TableCell>
                      {/* <TableCell></TableCell> */}
                      <TableCell>
                        <div className="flex flex-wrap gap-1.5">
                          {deviations > 0 && (
                            <Badge
                              variant="destructive"
                              className={`flex items-center gap-1 ${getStatusColor('deviation')}`}
                            >
                              {getStatusIcon('deviation')}
                              <span>Afvigelser</span>
                              <span className="ml-1 rounded-full bg-destructive-foreground text-destructive w-5 h-5 flex items-center justify-center text-xs">
                                {deviations}
                              </span>
                            </Badge>
                          )}
                          {observations > 0 && (
                            <Badge
                              //   variant="warning"
                              className={`flex items-center gap-1 ${getStatusColor('observation')}`}
                            >
                              {getStatusIcon('observation')} <span>Observationer</span>
                              <span className="ml-1 rounded-full bg-white  w-5 h-5 flex items-center justify-center text-xs">
                                {observations}
                              </span>
                            </Badge>
                          )}
                          {passed > 0 && (
                            <Badge
                              // variant="secondary"
                              className={`flex items-center gap-1 ${getStatusColor('passed')}`}
                            >
                              {getStatusIcon('passed')}
                              <span>Godkendt</span>
                              <span className="ml-1 rounded-full bg-white  w-5 h-5 flex items-center justify-center text-xs">
                                {passed}
                              </span>
                            </Badge>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{audit.auditor.displayName}</TableCell>
                      <TableCell className="flex justify-end">
                        <Button
                          variant={isDraft ? 'outline' : 'default'}
                          size="sm"
                          className="flex items-center gap-1"
                          onClick={() =>
                            history.push(
                              `/internal-audit/${audit.id}${!isDraft ? '?state=summary' : ''}`
                            )
                          }
                        >
                          {isDraft ? (
                            <>
                              <Edit className="h-3.5 w-3.5" />
                              <span>Fortsæt</span>
                            </>
                          ) : (
                            <>
                              <Eye className="h-3.5 w-3.5" />
                              <span>Se audit</span>
                            </>
                          )}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}
