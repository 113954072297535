import { Intl } from '../../general/enums';
import {
  SET_ONBOARDING_STATE,
  SET_SEARCH_KEYS_FAILED,
  SET_SEARCH_KEYS_REQUESTED,
  SET_SEARCH_KEYS_SUCCEEDED,
  UserState,
} from './types';
import keyBy from 'lodash.keyby';
import { RequestState } from '../../interfaces/request';
import {
  // UPDATE_PREFERENCE,
  SIGNIN_FAILED,
  SIGNIN_REQUESTED,
  SIGNIN_SUCCEEDED,
  FORGOT_PASSWORD_REQUESTED,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_SUCCEEDED,
  SET_CURRENT_USER,
  GET_USER,
  SET_ONBOARDING,
  // SET_CURRENT_USER_PERMISSIONS,
  UserActionTypes,
} from './types';

const initialState: UserState = {
  preferences: {
    language: 'da',
  },
  userDoc: {
    intl: Intl.da_DK,
  },
  details: {},
  loading: false,
  loadingForgotPassword: false,
  errorForgotPassword: null,
  error: null,
  success: '',
  permissions: {},
  onboardingRequestState: RequestState.INITIAL,
  onboarding: {},
  searchKeys: {},
  searchKeyRequestState: RequestState.INITIAL,
};

export default function user(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    // case UPDATE_PREFERENCE:
    //     return {
    //         ...state,
    //     };
    case SET_CURRENT_USER:
      return {
        ...state,
        details: action.user,
      };
    // case SET_CURRENT_USER_PERMISSIONS:
    //     return {
    //         ...state,
    //         permissions: action.permissions,
    //     };
    case SIGNIN_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SIGNIN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SIGNIN_SUCCEEDED:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        loadingForgotPassword: true,
        errorForgotPassword: null,
        success: null,
      };
    case FORGOT_PASSWORD_FAILED:
      return {
        ...state,
        loadingForgotPassword: false,
        errorForgotPassword: action.payload.error,
        success: null,
      };
    case FORGOT_PASSWORD_SUCCEEDED:
      return {
        ...state,
        loadingForgotPassword: false,
        errorForgotPassword: null,
        success: action.payload.success,
      };
    case GET_USER:
      return {
        ...state,
        userDoc: { ...state.userDoc, ...action.payload.user },
      };
    case SET_ONBOARDING:
      return {
        ...state,
        onboardingRequestState: RequestState.RESOLVED,
        onboarding: action.payload,
      };
    case SET_ONBOARDING_STATE:
      return {
        ...state,
        onboardingRequestState: action.payload,
      };
    case SET_SEARCH_KEYS_REQUESTED:
      return {
        ...state,
        searchKeyRequestState: RequestState.PENDING,
      };
    case SET_SEARCH_KEYS_SUCCEEDED:
      return {
        ...state,
        searchKeys: action.payload,
        searchKeyRequestState: RequestState.RESOLVED,
      };
    case SET_SEARCH_KEYS_FAILED:
      return {
        ...state,
        searchKeyRequestState: RequestState.REJECTED,
      };
    default:
      return state;
  }
}
