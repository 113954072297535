import type React from 'react';
import { createContext, useContext, useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import { useParams } from 'react-router';
import useQueryParams from 'hooks/useQueryParams';

type AuditStep = 'initial' | 'location' | 'tasks' | 'execution' | 'summary';

interface FollowUpTask {
  id: number;
  taskId: string;
  task: string;
  responsible: string;
  completed: boolean;
}

export type Attachment = {
  ref: string;
  fileName: string;
  download: string;
  type: string;
};

export type TasksMap = {
  [key: string]: {
    id: string;
    label: string;
    category: string;
    subTaskIds: string[];
    order: number;
  };
};
export type SubTasksMap = {
  [key: string]: {
    id: string;
    label: string;
    taskId: string;
    status: string;
    description: string;
    attachments: Attachment[];
    responsible?: {
      id: string;
      displayName: string;
    };
    dueDate?: Date;
  };
};

interface AuditContextType {
  step: AuditStep;
  setStep: (step: AuditStep) => void;
  audit: any;
  tasksMap: TasksMap | null;
  subTasksMap: SubTasksMap | null;
  currentTaskIndex: number;
  setCurrentTaskIndex: (index: number) => void;
  submitAudit: () => void;
}

const AuditContext = createContext<AuditContextType | undefined>(undefined);

export function useAudit() {
  const context = useContext(AuditContext);
  if (context === undefined) {
    throw new Error('useAudit must be used within an AuditProvider');
  }
  return context;
}

export function AuditProvider({ children }: { children: React.ReactNode }) {
  const { auditId } = useParams<{ auditId: string }>();
  const query = useQueryParams();
  const state = query.get('state');
  console.log({ state });

  const [step, setStep] = useState<AuditStep>(state ? 'summary' : 'initial');

  const [audit, setAudit] = useState<any>(null);
  const [tasksMap, setTasksMap] = useState<TasksMap | null>(null);
  const [subTasksMap, setSubTasksMap] = useState<SubTasksMap | null>(null);

  const [currentTaskIndex, setCurrentTaskIndex] = useState(0);

  useEffect(() => {
    let unsubscribe: any = null;
    console.log({ auditId });

    if (!auditId) return;
    unsubscribe = firebase
      .firestore()
      .collection('internalAudits')
      .doc(auditId)
      .onSnapshot(
        (query) => {
          // @ts-ignore
          const { tasksMap, subTasksMap, ...audit } = { id: query.id, ...query.data() };
          setAudit(audit);
          setTasksMap(tasksMap);
          setSubTasksMap(subTasksMap);
          // setSelectedTasks(masterList.map((task) => task.id));
        },
        (error) => {
          console.error(error);
        }
      );
    return () => {
      unsubscribe?.();
    };
  }, [auditId]);

  useEffect(() => {
    // const savedAudit = localStorage.getItem('currentAudit');
    // if (savedAudit) {
    //   const { step, selectedLocation, selectedTasks, remarks, followUpTasks, currentTaskIndex } =
    //     JSON.parse(savedAudit);
    //   setStep(step);
    //   setSelectedLocation(selectedLocation);
    //   setSelectedTasks(selectedTasks);
    //   setRemarks(remarks);
    //   setFollowUpTasks(followUpTasks);
    //   setCurrentTaskIndex(currentTaskIndex);
    // }
    // const savedFinishedAudits = localStorage.getItem('finishedAudits');
    // if (savedFinishedAudits) {
    //   setFinishedAudits(JSON.parse(savedFinishedAudits));
    // }
  }, []);

  const submitAudit = () => {
    // Clear current audit data
    // setStep('initial');
    // setCurrentTaskIndex(0);
  };

  return (
    <AuditContext.Provider
      value={{
        step,
        setStep,
        audit,
        tasksMap,
        subTasksMap,
        currentTaskIndex,
        setCurrentTaskIndex,
        submitAudit,
      }}
    >
      {children}
    </AuditContext.Provider>
  );
}
