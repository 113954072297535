import type React from 'react';

import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Badge } from 'components/ui/badge';
import LocationCombobox from './location-combobox';
import type { InventoryItem } from '../types';

interface AddItemDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onAdd: (item: Omit<InventoryItem, 'id' | 'serialNumber'>) => void;
  existingCategories: string[];
  existingLocations: string[];
}

const getCategoryColor = (category: string) => {
  const colors = {
    Sikkerhedsudstyr: 'bg-red-500 hover:bg-red-600',
    Elværktøj: 'bg-yellow-500 hover:bg-yellow-600',
    Håndværktøj: 'bg-green-500 hover:bg-green-600',
    Stiger: 'bg-blue-500 hover:bg-blue-600',
    // Add more categories and colors as needed
  };
  // @ts-ignore
  return colors[category] || 'bg-gray-500 hover:bg-gray-600';
};

export default function AddItemDialog({
  open,
  onOpenChange,
  onAdd,
  existingCategories,
  existingLocations,
}: AddItemDialogProps) {
  const [newCategory, setNewCategory] = useState('');
  const [formData, setFormData] = useState<Omit<InventoryItem, 'id' | 'serialNumber'>>({
    name: '',
    category: '',
    status: 'Available',
    description: '',
    dateAdded: new Date().toISOString().split('T')[0],
    lastMaintenance: null,
    nextMaintenance: null,
    location: '',
    manufacturer: '',
    purchaseDate: new Date().toISOString().split('T')[0],
    image: '/placeholder.svg?height=100&width=100',
    checkedOutTo: null,
    checkOutDate: null,
    expectedReturnDate: null,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const finalCategory = formData.category === 'new' ? newCategory : formData.category;
    onAdd({ ...formData, category: finalCategory });
    setFormData({
      name: '',
      category: '',
      status: 'Available',
      description: '',
      dateAdded: new Date().toISOString().split('T')[0],
      lastMaintenance: null,
      nextMaintenance: null,
      location: '',
      manufacturer: '',
      purchaseDate: new Date().toISOString().split('T')[0],
      image: '/placeholder.svg?height=100&width=100',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    });
    setNewCategory('');
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <form onSubmit={handleSubmit}>
          <DialogHeader>
            <DialogTitle>Add New Equipment</DialogTitle>
            <DialogDescription>
              Add a new item to your equipment catalog. A unique ID and serial number will be
              generated automatically.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Name
              </Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="col-span-3"
                required
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label className="text-right">Category</Label>
              <div className="col-span-3 flex gap-2">
                <Select
                  value={formData.category}
                  onValueChange={(value) => setFormData((prev) => ({ ...prev, category: value }))}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select a category" />
                  </SelectTrigger>
                  <SelectContent>
                    {existingCategories.map((category) => (
                      <SelectItem key={category} value={category}>
                        <Badge className={getCategoryColor(category)}>{category}</Badge>
                      </SelectItem>
                    ))}
                    <SelectItem value="new">+ Add new category</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>

            {formData.category === 'new' && (
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="newCategory" className="text-right">
                  New Category
                </Label>
                <Input
                  id="newCategory"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                  className="col-span-3"
                  required={formData.category === 'new'}
                />
              </div>
            )}

            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="status" className="text-right">
                Status
              </Label>
              <Select
                value={formData.status}
                onValueChange={(value) => setFormData((prev) => ({ ...prev, status: value }))}
              >
                <SelectTrigger className="col-span-3 w-full">
                  <SelectValue placeholder="Select status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Available">Available</SelectItem>
                  <SelectItem value="In Use">In Use</SelectItem>
                  <SelectItem value="Maintenance">Maintenance</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="location" className="text-right">
                Location
              </Label>
              <div className="col-span-3">
                <LocationCombobox
                  value={formData.location}
                  onChange={(value) => setFormData((prev) => ({ ...prev, location: value }))}
                  existingLocations={existingLocations}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="manufacturer" className="text-right">
                Manufacturer
              </Label>
              <Input
                id="manufacturer"
                name="manufacturer"
                value={formData.manufacturer}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="purchaseDate" className="text-right">
                Purchase Date
              </Label>
              <Input
                id="purchaseDate"
                name="purchaseDate"
                type="date"
                value={formData.purchaseDate}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="description" className="text-right">
                Description
              </Label>
              <Textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={handleChange}
                className="col-span-3"
                rows={3}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="lastMaintenance" className="text-right">
                Last Maintenance
              </Label>
              <Input
                id="lastMaintenance"
                name="lastMaintenance"
                type="date"
                value={formData.lastMaintenance || ''}
                onChange={handleChange}
                className="col-span-3"
              />
            </div>
          </div>
          <DialogFooter>
            <Button type="submit">Add Item</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
