import keyBy from 'lodash.keyby';
import { RequestState } from '../../interfaces/request';
import {
  ExternalCollaboratorState,
  DBExternalCollaboratorShape,
  ExternalCollaboratorActionTypes,
  FETCH_EXTERNAL_COLLABORATORS_REQUESTED,
  FETCH_EXTERNAL_COLLABORATORS_SUCCEEDED,
  FETCH_EXTERNAL_COLLABORATORS_FAILED,
} from './types';

const initialState: {
  externalCollaborators: DBExternalCollaboratorShape[];
  externalCollaboratorsMap: { [id: string]: DBExternalCollaboratorShape };
  state: RequestState;
} = {
  externalCollaborators: [],
  externalCollaboratorsMap: {},
  state: RequestState.INITIAL,
};

export default function externalCollaborators(
  state = initialState,
  action: ExternalCollaboratorActionTypes
): ExternalCollaboratorState {
  switch (action.type) {
    case FETCH_EXTERNAL_COLLABORATORS_REQUESTED:
      return {
        ...state,
        state: RequestState.PENDING,
      };
    case FETCH_EXTERNAL_COLLABORATORS_SUCCEEDED:
      return {
        ...state,
        externalCollaborators: action.payload,
        externalCollaboratorsMap: keyBy(action.payload, 'id'),
        state: RequestState.RESOLVED,
      };
    case FETCH_EXTERNAL_COLLABORATORS_FAILED:
      return {
        ...state,
        state: RequestState.REJECTED,
      };
    default:
      return state;
  }
}
