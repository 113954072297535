import React, { useEffect, useState } from 'react';
import { functions } from '../../../firebase';

export default function usePipedriveOrg(id: number | null) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    const getOrgs = async () => {
      try {
        setLoading(true);
        if (!id) {
          return;
        }
        const functionRef = functions.httpsCallable('getPipedriveOrg');
        const response: any = await functionRef({ id });

        const errorCode = response?.data?.httpErrorCode?.status;
        if (errorCode) {
          console.error({ errorCode });
        }
        setData({
          name: response?.data?.data?.name,
          ownerName: response?.data?.data?.owner_id?.name,
          openDeals: response?.data?.data?.open_deals_count,
          address: response?.data?.data?.address,
          lastActivity: response?.data?.data?.last_activity
            ? {
                subject: response?.data?.data?.last_activity?.subject,
                dueDate: response?.data?.data?.last_activity?.due_date,
                dueTime: response?.data?.data?.last_activity?.due_time,
              }
            : null,
          nextActivity: response?.data?.data?.next_activity
            ? {
                subject: response?.data?.data?.next_activity?.subject,
                dueDate: response?.data?.data?.next_activity?.due_date,
                dueTime: response?.data?.data?.next_activity?.due_time,
              }
            : null,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getOrgs();

    return () => {};
  }, [id]);
  return { loadingOrg: loading, data };
}
