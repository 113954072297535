'use client';

import { useAppSelector } from 'hooks';
import { useState, useEffect } from 'react';
import React from 'react';

export interface Company {
  id: string;
  title: string;
  delivery: {
    att: string;
    address: string;
  };
  invoice: {
    att: string;
    email: string;
  };
  contact: {
    name: string;
    email: string;
    phone: string;
  };
}

// Mock company data
// const companyData: Company[] = [
//   {
//     id: 'COMP-123',
//     title: 'Acme Inc',
//     delivery: {
//       address: '123 Main St, Anytown, USA',
//       att: 'John Doe',
//     },
//     invoice: {
//       att: 'Jane Doe',
//       email: 'Jane@acme.com',
//     },
//     contact: {
//       name: 'John Smith',
//       email: 'john@acme.com',
//       phone: '555-1234',
//     },
//     // email: 'john@acme.com',
//     // phone: '555-1234',
//   },
// ];

export function useCompanies() {
  // const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState(true);

  const orgs = useAppSelector((state) => state.orgs.orgs);

  useEffect(() => {
    // In a real app, this would be an API call
    const loadCompanies = async () => {
      setLoading(true);
      // Simulate API delay
      await new Promise((resolve) => setTimeout(resolve, 500));
      // setCompanies(companyData);
      setLoading(false);
    };

    loadCompanies();
  }, []); // Empty dependency array ensures this only runs once

  // Memoize the getCompanyById function to prevent unnecessary re-renders
  const getCompanyById = React.useCallback(
    (id: string) => {
      return orgs.find((company) => company.id === id) || null;
    },
    [orgs]
  );

  return {
    companies: orgs,
    loading,
    getCompanyById,
  };
}
