import type React from 'react';
import { useState, useEffect } from 'react';
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import { useAudit } from './audit-context';
import { ProgressBar } from './audit-progress';
import { ImageUpload } from './audit-task-upload';
import { Badge } from 'components/ui/badge';
import { StatusPicker } from './status-picker';
import firebase, { storage } from '../../firebase';
import { toast } from 'sonner';
import { useMultiFileUpload } from 'hooks/useMultiFileUpload';
import firebaseClass from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import PageLoader from 'components/Loader/PageLoader';

const responsiblePersons = [
  { value: 'john-doe', label: 'Chris Bagger' },
  { value: 'jane-smith', label: 'Jens Nissen' },
  { value: 'bob-johnson', label: 'Leif Sørensen' },
];

export function AuditExecution() {
  const { t } = useTranslation();
  const { audit, tasksMap, subTasksMap, currentTaskIndex, setCurrentTaskIndex, setStep } =
    useAudit();

  if (!audit || !tasksMap) return <PageLoader />;
  const id = Object.keys(tasksMap).find((id) => tasksMap[id]?.order === currentTaskIndex);
  // @ts-ignore
  const currentTask = tasksMap?.[id];

  const tasksLength = Object.keys(tasksMap)?.length;

  const handleNextTask = () => {
    if (currentTaskIndex < tasksLength - 1) {
      setCurrentTaskIndex(currentTaskIndex + 1);
    } else {
      setStep('summary');
    }
  };

  const handlePreviousTask = () => {
    if (currentTaskIndex > 0) {
      setCurrentTaskIndex(currentTaskIndex - 1);
    }
  };

  const location = audit.location.title;

  return (
    <div className="flex flex-col items-center space-y-4">
      <h2 className="text-xl font-semibold">Intern Audit </h2>
      <div className="text-muted-foreground">{location}</div>
      <div className="w-full max-w-lg space-y-8">
        <div className="flex justify-between">
          <h3 className="text-lg font-medium">{currentTask?.label}</h3>
          <Badge variant="secondary">{currentTask?.category}</Badge>
        </div>

        {currentTask?.subTaskIds.map((id: string) => {
          return <SubTask key={id} auditId={audit.id} taskId={id} />;
        })}

        <div className="flex justify-end">
          {/* <div>
            <Button variant="outline" onClick={() => alert('SAVE')}>
              Gem Kladde
            </Button>
          </div> */}
          <div className="flex justify-end space-x-2">
            <Button
              variant="outline"
              onClick={handlePreviousTask}
              disabled={currentTaskIndex === 0}
            >
              Tilbage
            </Button>
            <Button onClick={handleNextTask}>
              {currentTaskIndex < tasksLength - 1 ? 'Næste' : 'Se opsummering'}
            </Button>
          </div>
        </div>
        <div className="flex w-full items-center justify-between">
          <ProgressBar progress={((currentTaskIndex + 1) / tasksLength) * 100} />
          <div className="w-[40px] ml-4">
            {currentTaskIndex + 1} / {tasksLength}
          </div>
        </div>
        {audit.completeTime && (
          <div className="flex justify-center text-muted-foreground">
            Afsluttet: {t('general.dateTimeYYYY', { date: audit.completeTime?.toDate() })}
          </div>
        )}
      </div>
    </div>
  );
}

function SubTask({ auditId, taskId }: { auditId: string; taskId: string }) {
  const { audit, subTasksMap } = useAudit();
  const { uploadFiles } = useMultiFileUpload();
  const subTask = subTasksMap?.[taskId];
  const [text, setText] = useState(subTask?.description || '');

  const isDisabled = audit.state === 'COMPLETED';

  useEffect(() => {
    if (!subTask?.description) return;
    setText(subTask?.description);
  }, [subTask]);

  if (!subTask) return null;

  const handleUpdateSubTaskDescription = async (text: string) => {
    try {
      if (!auditId || !taskId) {
        throw new Error(`Missing auditId=${auditId} or taskId=${taskId}`);
      }
      await firebase
        .firestore()
        .collection('internalAudits')
        .doc(auditId)
        .update({
          [`subTasksMap.${taskId}.description`]: text,
        });
      toast.success('Beskrivelse opdateret');
    } catch (error) {
      toast.error('Fejl ved opdatering af beskrivelse');
      console.error(error);
    }
  };
  const handleUpdateSubTaskStatus = async (status: string) => {
    try {
      if (!auditId || !taskId) {
        throw new Error(`Missing auditId=${auditId} or taskId=${taskId}`);
      }
      await firebase
        .firestore()
        .collection('internalAudits')
        .doc(auditId)
        .update({
          [`subTasksMap.${taskId}.status`]: status,
        });
      toast.success('Status opdateret');
    } catch (error) {
      toast.error('Fejl ved opdatering af status');
      console.error(error);
    }
  };

  const handleUploadAttachments = async (attachments: File[]) => {
    try {
      const files = await uploadFiles(
        attachments,
        `internal-audits/${auditId}/sub-tasks/${taskId}`
      );

      console.log({ files });

      if (!files || files?.some((f) => !f.download)) {
        toast.error('Fejl ved upload af filer');
        return;
      }
      // if (error) {
      //   toast.error('Fejl ved upload af filer');
      //   return;
      // }

      await firebase
        .firestore()
        .collection('internalAudits')
        .doc(auditId)
        .update({
          [`subTasksMap.${taskId}.attachments`]: firebaseClass.firestore.FieldValue.arrayUnion(
            ...files
          ),
        });
    } catch (error) {
      console.error(error);
      toast.error('Fejl ved upload af filer');
    }
  };

  const handleRemoveAttachment = async (attachment: any) => {
    console.log({ attachment });

    try {
      await firebase
        .firestore()
        .collection('internalAudits')
        .doc(auditId)
        .update({
          [`subTasksMap.${taskId}.attachments`]:
            firebaseClass.firestore.FieldValue.arrayRemove(attachment),
        });

      // Delete the file from Firebase Storage
      const fileRef = storage.ref(`${attachment.pathRef}/${attachment.fileId}`);
      await fileRef.delete();
      if (attachment.type.startsWith('image')) {
        const thumbRef = storage.ref(`${attachment.pathRef}/thumb_${attachment.fileId}`);
        await thumbRef.delete();
      }
      toast.success('Fil slettet');
    } catch (error) {
      console.error(error);
      toast.error('Fejl ved sletning af fil');
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-2 gap-4">
        <div className="">{subTask.label}</div>
        <div>
          <StatusPicker
            disabled={isDisabled}
            status={subTask.status}
            onChange={(status) => handleUpdateSubTaskStatus(status)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <Textarea
          disabled={isDisabled}
          placeholder="Noter bemærkninger..."
          value={text}
          onChange={(e) => setText(e.target.value)}
          onBlur={() => handleUpdateSubTaskDescription(text)}
        />
        <div>
          <ImageUpload
            disabled={isDisabled}
            attachments={subTask.attachments}
            onUploadAttachment={handleUploadAttachments}
            onRemoveAttachment={handleRemoveAttachment}
          />
        </div>
      </div>
    </div>
  );
}

// function FollowUpDrawer({
//   onAddFollowUp,
//   // followUpTasks,
//   // onRemoveTask,
//   // onToggleCompletion,
//   currentTaskLabel,
// }: {
//   onAddFollowUp: (task: { task: string; responsible: string; deadline: Date }) => void;
//   followUpTasks: {
//     id: number;
//     task: string;
//     responsible: string;
//     completed: boolean;
//     taskId: string;
//   }[];
//   onRemoveTask: (id: number) => void;
//   onToggleCompletion: (id: number) => void;
//   currentTaskLabel: string;
// }) {
//   const [followUpTask, setFollowUpTask] = useState('');
//   const [open, setOpen] = useState(false);
//   const [deadline, setDeadline] = useState<Date>();
//   const [responsible, setResponsible] = useState('');

//   const handleSubmit = (e: React.FormEvent) => {
//     e.preventDefault();
//     if (followUpTask.trim() && responsible && deadline) {
//       onAddFollowUp({
//         task: followUpTask,
//         responsible: responsiblePersons.find((p) => p.value === responsible)?.label || '',
//         deadline: deadline,
//       });
//       setFollowUpTask('');
//       setResponsible('');
//       setDeadline(undefined);
//     }
//   };

//   return (
//     <SheetHeader>
//       <SheetTitle>Opfølgning på {currentTaskLabel}</SheetTitle>
//       {/* <SheetDescription>Opret opfølgningsopgaver.</SheetDescription> */}
//       <div className="space-y-4 mt-4">
//         <form onSubmit={handleSubmit} className="space-y-4">
//           <div className="space-y-2">
//             <Label htmlFor="followUpTask">Opgave beskrivelse</Label>
//             <Textarea
//               id="followUpTask"
//               value={followUpTask}
//               onChange={(e) => setFollowUpTask(e.target.value)}
//               placeholder="Beskriv opgaven..."
//             />
//           </div>
//           <div className="space-y-2">
//             <Label htmlFor="responsible">Ansvarlig</Label>
//             <Popover open={open} onOpenChange={setOpen}>
//               <PopoverTrigger asChild>
//                 <Button
//                   variant="outline"
//                   role="combobox"
//                   aria-expanded={open}
//                   className="w-full justify-between"
//                 >
//                   {responsible
//                     ? responsiblePersons.find((person) => person.value === responsible)?.label
//                     : 'Vælg ansvarlig...'}
//                   <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
//                 </Button>
//               </PopoverTrigger>
//               <PopoverContent className="w-full min-w-[300px] p-0">
//                 <Command>
//                   <CommandInput placeholder="Søg efter person..." />
//                   <CommandList>
//                     <CommandEmpty>Ingen match</CommandEmpty>
//                     <CommandGroup>
//                       {responsiblePersons.map((person) => (
//                         <CommandItem
//                           key={person.value}
//                           value={person.value}
//                           onSelect={(currentValue) => {
//                             setResponsible(currentValue === responsible ? '' : currentValue);
//                             setOpen(false);
//                           }}
//                         >
//                           <Check
//                             className={cn(
//                               'mr-2 h-4 w-4',
//                               responsible === person.value ? 'opacity-100' : 'opacity-0'
//                             )}
//                           />
//                           {person.label}
//                         </CommandItem>
//                       ))}
//                     </CommandGroup>
//                   </CommandList>
//                 </Command>
//               </PopoverContent>
//             </Popover>
//           </div>
//           <div className="space-y-2">
//             <Label htmlFor="deadline">Deadline</Label>
//             <Popover>
//               <PopoverTrigger asChild>
//                 <Button
//                   variant={'outline'}
//                   className={cn(
//                     'w-full justify-start text-left font-normal',
//                     !deadline && 'text-muted-foreground'
//                   )}
//                 >
//                   <Calendar className="mr-2 h-4 w-4" />
//                   {deadline ? format(deadline, 'PPP', { locale }) : <span>Vælg dato</span>}
//                 </Button>
//               </PopoverTrigger>
//               <PopoverContent className="w-auto p-0" align="start">
//                 <CalendarComponent
//                   mode="single"
//                   selected={deadline}
//                   onSelect={setDeadline}
//                   locale={locale}
//                   initialFocus
//                 />
//               </PopoverContent>
//             </Popover>
//           </div>
//           <Button type="submit">Tilføj opfølgningsopgave</Button>
//         </form>
//       </div>
//     </SheetHeader>
//   );
// }
