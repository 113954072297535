import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../../firebase';
import * as actionType from './types';
import { InventoryType } from '../types';
import { fetchInventoryFoobotSuccess } from './actions';

// FOOBOT
function* fetchInventoryFoobot() {
  try {
    yield call(
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`inventory`).where('type', '==', InventoryType.FOOBOT),
      { successActionCreator: fetchInventoryFoobotSuccess }
    );
  } catch (e: any) {
    yield put({ type: actionType.FOOBOT_INVENTORY_FETCH_FAILED, message: e.message });
  }
}

export function* fetchInventoryFoobotSaga() {
  // @ts-ignore
  yield takeLatest(actionType.FOOBOT_INVENTORY_FETCH_REQUESTED, fetchInventoryFoobot);
}
