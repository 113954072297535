import { Row, Col, Layout, Tabs, TabsProps } from 'antd';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import SearchOrgs from './Orgs/SearchOrgs';
import SearchDeals from './Deals/SearchDeals';

const { Content } = Layout;

interface Props {}

function SensorList(_: Props) {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Organizations',
      children: <SearchOrgs />,
    },
    {
      key: '2',
      label: 'Deals',
      children: <SearchDeals />,
    },
  ];
  return (
    <>
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '50px 24px 0' }}>
          <Row gutter={8}>
            <Col md={24}>
              <Tabs defaultActiveKey="2" items={items} />

              {/* <SearchOrgs /> */}
            </Col>
          </Row>
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}

export default SensorList;
