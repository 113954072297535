import React, { useState } from 'react';
import { Popconfirm, Layout, Row, Col, Modal } from 'antd';
import HeaderBar from '../../../Layout/HeaderBar';
import FooterBar from '../../../Layout/FooterBar';
import { IOrg } from '../../../modules/orgs/interfaces';

import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AddOrg from '../../Home/AddOrg';
import TableList from './Table';
import InventoryNav from '../InventoryNav';
import {
  InventoryContextForType,
  InventoryItem,
  InventoryType,
} from '../../../modules/inventory/types';
import EditForm from './EditForm';
import AddForm from './AddForm';
import { InventoryStatus } from '../../../modules/inventory/types';
import Total from './Total';
import keyBy from 'lodash.keyby';

const { Content } = Layout;

interface IHomeViewProps {
  gps: InventoryContextForType<InventoryType.GPS>[];
  inventoryTagsMap: { [id: string]: { id: string; title: string } };
  simcards: InventoryContextForType<InventoryType.SIM_CARDS>[];
}

function InventoryGPSView({ gps, simcards, inventoryTagsMap }: IHomeViewProps) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<string | null>(null);
  const onClose = () => setVisible(null);
  const simcardMap = keyBy(simcards, 'id');
  return (
    <>
      <InventoryNav />
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '24px 24px 0' }}>
          <Row>
            <Col span={24} style={{ marginBottom: 24 }}>
              <div style={{ float: 'right' }}>
                <AddForm simcards={simcards} inventoryTagsMap={inventoryTagsMap} />
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ marginBottom: 24 }}>
              <div style={{ float: 'left' }}>
                <Total />
              </div>
            </Col>
          </Row>

          <TableList data={gps} setVisible={setVisible} simcardMap={simcardMap} />
        </Content>
        <FooterBar />
      </Layout>
      <Modal
        title="Ret GPS"
        open={!!visible}
        // onOk={e => handleOk(e, email)}
        confirmLoading={false}
        onCancel={onClose}
        footer={[]}
      >
        {visible && (
          <EditForm
            key={visible}
            id={visible}
            onClose={onClose}
            inventoryTagsMap={inventoryTagsMap}
            simcards={simcards}
          />
        )}
      </Modal>
    </>
  );
}

export default InventoryGPSView;
