import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import AuditDetail from './AuditDetail';
import {
  fetchAuditSchedule,
  updateAuditSchedule,
  updateAuditScheduleTask,
} from '../../modules/auditSchedule/actions';
import { fetchAuditTemplates } from '../../modules/auditTemplates/actions';
import { fetchAuditFiles, deleteAuditFile } from '../../modules/files/actions';
import { omitUndefined } from '../../general/helpers';
import { useAppSelector } from '../../hooks';

function AuditDetailContainer() {
  const dispatch = useDispatch();
  const { orgId, auditId } = useParams<{ orgId: string; auditId: string }>();
  const { currentAudit, isLoadingCurrent, uid } = useAppSelector((state) => ({
    currentAudit: state.auditSchedules.currentAuditSchedule,
    isLoadingCurrent: state.auditSchedules.isLoadingCurrent,
    uid: state.user.details.uid,
  }));
  useEffect(() => {
    dispatch(fetchAuditSchedule({ orgId, auditScheduleId: auditId }));
    dispatch(fetchAuditTemplates({ orgId }));
    dispatch(fetchAuditFiles({ orgId, auditId }));
  }, [orgId, auditId, dispatch]);

  const updateAuditScheduleTaskFunc = (data: any, taskId: string) => {
    const newData = omitUndefined(data);
    const newTasks = {
      ...currentAudit.tasks,
      [taskId]: {
        // @ts-ignore
        ...currentAudit.tasks[taskId],
        ...newData,
      },
    };
    dispatch(
      updateAuditScheduleTask({
        orgId,
        auditScheduleId: auditId,
        // @ts-ignore
        tasks: newTasks,
      })
    );
  };
  const updateAuditScheduleFunc = (data: any) => {
    console.log({ data, uid });

    // const { orgId, auditId } = match.params;
    // if (data.isCompleted) {
    //     updateAuditSchedule({
    //         orgId,
    //         auditScheduleId: auditId,
    //         data: { ...data, auditor: uid },
    //     });
    // }
    dispatch(
      updateAuditSchedule({
        orgId,
        auditScheduleId: auditId,
        data: { ...data, auditor: uid },
      })
    );
  };

  if (isLoadingCurrent) {
    return null;
  }
  return (
    <AuditDetail
      orgId={orgId}
      auditId={auditId}
      updateAuditScheduleTask={updateAuditScheduleTaskFunc}
      updateAuditSchedule={updateAuditScheduleFunc}
      deleteFile={(fileId: string) => dispatch(deleteAuditFile({ orgId, auditId, fileId }))}
    />
  );
}

// function mapStateToProps(state: AppState) {
//     return {

//     };
// }

export default AuditDetailContainer;
