import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { functions } from '../../../firebase';
import { AutoComplete, Avatar, Button, Input, List, Tooltip } from 'antd';
import { getInitials } from '../../../general/initials';
import reducer, { initialState } from '../pipedriveOrgsReducer';
import debounce from 'lodash.debounce';
import { StyleGuide } from '../../../styles/StyleGuide';
import PipeModal from './Modal';
import { getOwner } from '../helpers';

export default function SearchOrgs() {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const getOrgs = async () => {
      try {
        setLoading(true);
        const functionRef = functions.httpsCallable('getPipedriveOrgs');
        const response: any = await functionRef({
          start: 0,
          limit: 100,
        });

        const errorCode = response?.data?.httpErrorCode?.status;
        if (errorCode) {
          console.log({ errorCode });
        }
        dispatch({
          type: 'SET_DATA',
          payload: {
            data:
              response?.data?.data?.map((d: any) => ({
                ...d,
                ownerId: d.owner_id.id,
              })) || [],
            pagination: response.data.additional_data.pagination,
          },
        }); //(response?.data?.data?.items || []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getOrgs();

    return () => {};
  }, []);

  const onSearch = async (searchTerm: string) => {
    try {
      console.log({ onSearch: searchTerm });

      setLoading(true);
      const functionRef = functions.httpsCallable('searchPipedriveOrgs');
      const response: any = await functionRef({ searchTerm });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        console.log({ errorCode });
      }
      dispatch({
        type: 'SET_SEARCH_RESULTS',
        payload:
          response?.data?.data?.items.map((i: any) => ({
            label: i?.item?.name,
            value: i?.item?.id,
          })) || [],
      });

      console.log({ response });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const debounceSearch = useMemo(() => {
    return debounce(onSearch, 500);
  }, []);

  const onLoadMore = async ({ limit, start }: { limit: number; start: number }) => {
    try {
      setLoading(true);
      const functionRef = functions.httpsCallable('getPipedriveOrgs');
      const response: any = await functionRef({
        start,
        limit,
      });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        console.log({ errorCode });
      }
      dispatch({
        type: 'SET_DATA_MORE',
        payload: {
          data:
            response?.data?.data?.map((d: any) => ({
              ...d,
              ownerId: d.owner_id.id,
            })) || [],
          pagination: response.data.additional_data.pagination,
        },
      }); //(response?.data?.data?.items || []);
      console.log({ response });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleSearch = (value: string) => {
  //     dispatch({ type: "SET_SEARCH_TERM", payload: value });
  // };

  const onSelect = (value: any) => {
    console.log('onSelect', value);
    dispatch({ type: 'SET_ID', payload: value });
  };

  const loadMore =
    state.pagination.more_items_in_collection && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button
          onClick={() =>
            onLoadMore({
              start: state.pagination.next_start,
              limit: state.pagination.limit,
            })
          }
        >
          Indlæs flere
        </Button>
      </div>
    ) : null;
  return (
    <>
      <AutoComplete
        // popupMatchSelectWidth={252}
        style={{ width: '100%' }}
        options={state.searchResults}
        onSelect={onSelect}
        onSearch={debounceSearch}
        size="large"
      >
        <Input.Search size="large" placeholder="Søg efter salgsemner" enterButton />
      </AutoComplete>
      {/* <Search
                placeholder="Søg efter salgskunder"
                enterButton="Søg"
                size="large"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onSearch={onSearch}
                loading={loading}
            /> */}
      <div style={{ marginTop: 16 }} />
      <List
        // size="small"
        bordered
        loading={loading}
        loadMore={loadMore}
        dataSource={state.data}
        renderItem={(item) => {
          const owner = getOwner(item.ownerId);
          return (
            <List.Item
              actions={[
                <Tooltip placement={'left'} title={owner.name}>
                  <Avatar
                    // size={24}
                    style={{
                      backgroundColor: owner.bgColor,
                      color: owner.color,
                    }}
                  >
                    {getInitials(owner.name)}
                  </Avatar>
                </Tooltip>,
              ]}
            >
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => dispatch({ type: 'SET_ID', payload: item.id })}
              >
                {item?.name}
              </span>{' '}
              {item.address && (
                <span style={{ color: StyleGuide.palette.grey600 }}>- {item.address}</span>
              )}
            </List.Item>
          );
        }}
      />
      <PipeModal id={state.id} onClose={() => dispatch({ type: 'SET_ID', payload: null })} />
    </>
  );
}
