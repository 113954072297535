import { compose } from 'redux';
import { withRouter, useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
import IPADInventoryContainer from './iPad/Container';
import GPSInventoryContainer from './GPS/Container';
import { fetchOrgs, addOrg, updateOrg } from '../../modules/orgs/actions';
import { AppState } from '../../store';
import { InventoryType } from '../../modules/inventory/types';
import EspAqInventoryContainer from './ESP_AQ/Container';
import FoobotInventoryContainer from './Foobot/Container';
import PumpsInventoryContainer from './Pumps/Container';
import SimcardInventoryContainer from './Simcards/Container';

function InventoryTypeContainer() {
  const { inventoryType } = useParams<{ inventoryType: InventoryType }>();
  switch (inventoryType) {
    case InventoryType.IPAD:
      return <IPADInventoryContainer />;
    case InventoryType.GPS:
      return <GPSInventoryContainer />;
    case InventoryType.FOOBOT:
      return <FoobotInventoryContainer />;
    case InventoryType.ESP_AQ:
      return <EspAqInventoryContainer />;
    case InventoryType.PUMP:
      return <PumpsInventoryContainer />;
    case InventoryType.SIM_CARDS:
      return <SimcardInventoryContainer />;

    default:
      return <div>not supported type</div>;
  }
}

function mapStateToProps(state: AppState) {
  return {
    loading: false,
    orgs: state.orgs.orgs,
    // @ts-ignore
    uid: state.user.details.uid,
    // @ts-ignore
    email: state.user.details.email,
  };
}

const mapActionsToProps = {
  fetchOrgs,
  addOrg,
  updateOrg,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(InventoryTypeContainer);
