import Loader from '../../components/Loader';
import GroupView from './index';
import { useAppSelector } from '../../hooks';
import { RequestState } from '../../interfaces/request';

function GroupContainer() {
  // const { groups, isLoading } = useGroups();
  const isLoading = useAppSelector(
    (state) =>
      state.groups.state === RequestState.INITIAL || state.groups.state === RequestState.PENDING
  );
  const groups = useAppSelector((state) => state.groups.groups);

  if (isLoading) {
    return <Loader />;
  }
  return <GroupView groups={groups} />;
}

export default GroupContainer;
