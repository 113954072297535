import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../../firebase';
import { fetchInventoryGPSSuccess } from './actions';
import * as actionType from './types';
import { InventoryType } from '../types';

// GPS
function* fetchInventoryGPS() {
  try {
    yield call(
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`inventory`).where('type', '==', InventoryType.GPS),
      { successActionCreator: fetchInventoryGPSSuccess }
    );
  } catch (e: any) {
    yield put({ type: actionType.GPS_INVENTORY_FETCH_FAILED, message: e.message });
  }
}

export function* fetchInventoryGPSSaga() {
  // @ts-ignore
  yield takeLatest(actionType.GPS_INVENTORY_FETCH_REQUESTED, fetchInventoryGPS);
}
