import { CleaningLogsActionTypes, CleaningLogState } from './types';
import {
  CLEANLOGS_FETCH_REQUESTED,
  CLEANLOGS_FETCH_SUCCEEDED,
  CLEANLOGS_FETCH_FAILED,
} from './types';

const initialState: CleaningLogState = {
  logs: [],
  loading: false,
};

export default function cleanLogs(
  state = initialState,
  action: CleaningLogsActionTypes
): CleaningLogState {
  switch (action.type) {
    case CLEANLOGS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CLEANLOGS_FETCH_SUCCEEDED:
      return {
        ...state,
        logs: action.payload.data,
        loading: false,
      };
    case CLEANLOGS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
