import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function Loader({ size = 24 }: { size?: number }) {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;
  return <Spin indicator={antIcon} />;
}

export default Loader;
