import { Card, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import { getDaysInYear } from 'date-fns';
import React, { useState } from 'react';
import { getLiters, useOfferContext } from '../Agreements/Context/OfferContext';

export default function Estimated() {
  const { state } = useOfferContext();
  const { litersPrYear } = getLiters(state.m2);
  const optionsWithDisabled = [
    { label: '1 Liter', value: 1 },
    { label: '5 Liter', value: 5 },
    { label: '220 Liter', value: 220 },
    { label: '1000 Liter', value: 1000 },
  ].filter((option) => option.value <= litersPrYear);
  const [container, setContainer] = useState(220);
  const { containersPerYear, calendarDaysToUseContainer } = daysToUseVolume({
    litersPrYear,
    containerSize: container,
    workDaysPerYear: 260,
  });

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setContainer(value);
  };

  const containersPerYearValues = getContainersPerYear(litersPrYear);

  const selectedContainer = optionsWithDisabled.find((option) => option.value === container);

  return (
    <Card
      title="Estimeret forbrug"
      extra={
        <Radio.Group
          options={optionsWithDisabled}
          onChange={onChange}
          value={container}
          optionType="button"
          buttonStyle="solid"
        />
      }
    >
      <h4 style={{ margin: 0 }}>Støvbinder gulvask</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Forbrug pr. år:</div>
        <div>
          <div>
            {new Intl.NumberFormat('da-DK', {
              style: 'unit',
              unit: 'liter',
              maximumFractionDigits: 0,
            }).format(containersPerYearValues[1])}{' '}
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Antal {selectedContainer?.label} pr. år:</div>
        <div>{containersPerYear}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Estimeret forbrug af {selectedContainer?.label}:</div>
        <div>{calendarDaysToUseContainer} dage</div>
      </div>
      <div style={{ marginTop: 16 }} />
      <h4 style={{ margin: 0 }}>Støvbinder fejegranulat</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>Forbrug pr. år:</div>
        <div>
          <div>
            {new Intl.NumberFormat('da-DK', {
              style: 'unit',
              unit: 'liter',
              maximumFractionDigits: 0,
            }).format(state.sweepingGranules)}{' '}
          </div>
        </div>
      </div>
    </Card>
  );
}

// helper functions:
function daysToUseVolume({
  litersPrYear,
  containerSize = 220,
  workDaysPerYear = 260,
}: {
  litersPrYear: number;
  containerSize: number;
  workDaysPerYear: number;
}) {
  const totalDaysPerYear = getDaysInYear(new Date());

  // Daily usage in liters per workday
  const dailyUsage = litersPrYear / workDaysPerYear;

  // Calculate the number of 220-liter containers used per year (including decimals)
  const containersPerYear = litersPrYear / containerSize;

  // Calculate the remaining liters that don't fit into a 220-liter container
  const remainingLiters = litersPrYear % containerSize;

  // Days needed to use one 220-liter container
  const workDaysToUseContainer = containerSize / dailyUsage;

  // Ratio of total days to workdays
  const ratio = totalDaysPerYear / workDaysPerYear;

  // Calendar days needed to use one 220-liter container
  const calendarDaysToUseContainer = workDaysToUseContainer * ratio;

  return {
    dailyUsage: dailyUsage.toFixed(2),
    containersPerYear: containersPerYear.toFixed(2),
    remainingLiters: remainingLiters.toFixed(2),
    workDaysToUseContainer: workDaysToUseContainer.toFixed(2),
    calendarDaysToUseContainer: calendarDaysToUseContainer.toFixed(0),
  };
}

export function getContainersPerYear(litersPrYear: number) {
  return {
    1: litersPrYear / 1,
    5: litersPrYear / 5,
    220: litersPrYear / 220,
    1000: litersPrYear / 1000,
  };
}
