import type React from 'react';

import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import type { InventoryItem } from '../types';

interface CheckOutDialogProps {
  item: InventoryItem;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCheckOut: (updatedItem: InventoryItem) => void;
}

export default function CheckOutDialog({
  item,
  open,
  onOpenChange,
  onCheckOut,
}: CheckOutDialogProps) {
  const [checkedOutTo, setCheckedOutTo] = useState('');
  const [expectedReturnDate, setExpectedReturnDate] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const updatedItem: InventoryItem = {
      ...item,
      status: 'In Use',
      checkedOutTo,
      checkOutDate: new Date().toISOString(),
      expectedReturnDate,
    };
    onCheckOut(updatedItem);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Check Out Equipment</DialogTitle>
          <DialogDescription>Enter the details to check out this equipment.</DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="checkedOutTo">Checked Out To</Label>
            <Input
              id="checkedOutTo"
              value={checkedOutTo}
              onChange={(e) => setCheckedOutTo(e.target.value)}
              required
            />
          </div>
          <div>
            <Label htmlFor="expectedReturnDate">Expected Return Date</Label>
            <Input
              id="expectedReturnDate"
              type="date"
              value={expectedReturnDate}
              onChange={(e) => setExpectedReturnDate(e.target.value)}
              required
            />
          </div>
          <Button type="submit">Check Out</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
