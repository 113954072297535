import React from 'react';
import { Select } from 'antd';
import firebase from 'firebase/compat/app';
import { DBGroupShape } from '../../modules/groups/types';

const { Option } = Select;

const db = firebase.firestore();

export default function GroupSelect({
  orgId,
  groupId,
  groups,
}: {
  orgId: string;
  groupId?: string;
  groups: DBGroupShape[];
}) {
  const handleChange = async (value: string) => {
    try {
      console.log(`selected ${value} - orgId=${orgId} - groupId=${groupId}`);
      if (!orgId) return alert('Invalid orgId');
      const batch = db.batch();
      const orgRef = db.collection('orgs').doc(orgId);
      batch.set(orgRef, { groupId: value }, { merge: true });
      if (groupId) {
        const currentGroupRef = db.collection('groups').doc(groupId);
        const snapCurrent = await db.collection('groups').doc(groupId).get();
        const currentCount = snapCurrent.data()?.count;
        batch.set(currentGroupRef, { count: currentCount - 1 }, { merge: true });
      }
      const newGroupRef = db.collection('groups').doc(value);
      const snap = await db.collection('groups').doc(value).get();
      const newCount = snap.data()?.count;
      batch.set(newGroupRef, { count: (newCount || 0) + 1 }, { merge: true });
      await batch.commit();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Select defaultValue={groupId} style={{ width: '100%' }} onChange={handleChange}>
      {groups
        ?.sort((a, b) =>
          a.title?.localeCompare(b.title, 'da', {
            sensitivity: 'base',
            numeric: true,
          })
        )
        .map(({ id, title }) => (
          <Option value={id}>{title}</Option>
        ))}
    </Select>
  );
}
