import { functions } from '../../../firebase';
import React, { useEffect, useState } from 'react';

type GPSVelocity = {
  groundSpeed: number;
  heading: number;
};

type GPSVariables = {
  harshAccelerationDigital: boolean;
  harshBrakingDigital: boolean;
  harshCorneringDigital: boolean;
  satelliteCount: number;
  ignition: true;
  movement: true;
  gsmSignalStrength: number;
  cellID: number;
  hdop: number;
  externalVoltage: number;
  batteryVoltage: number;
  batteryCurrent: number;
};

type Track = {
  logTime: string; // date
  gpsId: string;
  velocity: GPSVelocity;
  variables: GPSVariables;
  serverTime: string; // date
  altitude: number;
  lat: number;
  lng: number;
  orgId: string;
};

export default function useTracks({
  orgId,
  sensorId,
  date,
}: {
  orgId: string;
  sensorId: string;
  date: string;
}) {
  const [loading, setLoading] = useState(true);
  const [tracks, setTracks] = useState<Track[]>([]);
  useEffect(() => {
    const getTracks = async () => {
      try {
        if (!loading) {
          setLoading(true);
        }
        const functionRef = functions.httpsCallable('getGpsGateTracks');
        const response: any = await functionRef({
          sensorId,
          orgId,
          date,
        });

        console.log({ response });

        const errorCode = response?.data?.httpErrorCode?.status;
        if (errorCode) {
          console.log({ errorCode });
        }
        setTracks(response?.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (orgId && sensorId && date) {
      getTracks();
    }

    return () => {};
  }, [orgId, sensorId, date]);
  console.log({ tracks });

  return { tracks, isTrackLoading: loading };
}

const dummyData = [
  {
    logTime: '2024-09-02T04:05:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0.2777779996395111,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0.2777779996395111,
    },
    serverTime: '2024-09-02T04:05:39Z',
    altitude: 9,
    lat: 55.8841283,
    lng: 9.7554633,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:07:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 187,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.08,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T04:07:25Z',
    altitude: 17,
    lat: 55.8839933,
    lng: 9.7555166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:07:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 173,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T04:07:26Z',
    altitude: 18,
    lat: 55.8839833,
    lng: 9.7554883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:09:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 267,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T04:09:14Z',
    altitude: 29,
    lat: 55.884035,
    lng: 9.754635,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:10:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 28.25795103253638,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T04:10:04Z',
    altitude: 25,
    lat: 55.88411000000502,
    lng: 9.754706874966669,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:50:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 28.25795103253638,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T04:50:19Z',
    altitude: 25,
    lat: 55.88411000000502,
    lng: 9.754706874966669,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:50:51Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 255,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T04:50:51Z',
    altitude: 25,
    lat: 55.88411000000502,
    lng: 9.754706874966669,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:58:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 24,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T04:58:22Z',
    altitude: 25,
    lat: 55.88411000000502,
    lng: 9.754706874966669,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:58:22.01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 24,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T04:58:22.01Z',
    altitude: 28,
    lat: 55.8839983,
    lng: 9.7547966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:58:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 223,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T04:58:42Z',
    altitude: 29,
    lat: 55.8841033,
    lng: 9.754555,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T04:59:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 351,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T04:59:52Z',
    altitude: 22,
    lat: 55.8845583,
    lng: 9.7539116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:00:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 152,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:00:01Z',
    altitude: 20,
    lat: 55.8844733,
    lng: 9.7539233,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:00:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 146,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:00:11Z',
    altitude: 20,
    lat: 55.8843283,
    lng: 9.7539716,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:00:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 304,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.23,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:00:15Z',
    altitude: 20,
    lat: 55.8842683,
    lng: 9.7538116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:00:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 357,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T05:00:28Z',
    altitude: 19,
    lat: 55.8844383,
    lng: 9.7537283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:00:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 12,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T05:00:29Z',
    altitude: 19,
    lat: 55.8844916,
    lng: 9.7537516,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:00:38Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 16,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:00:38Z',
    altitude: 17,
    lat: 55.884485,
    lng: 9.753605,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:01:34Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 181,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: false,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:01:34Z',
    altitude: 24,
    lat: 55.88428,
    lng: 9.7532748,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:18:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:18:08Z',
    altitude: 24,
    lat: 55.88428,
    lng: 9.7532748,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:18:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 325,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:18:09Z',
    altitude: 33,
    lat: 55.8845683,
    lng: 9.75313,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:18:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 43,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T05:18:21Z',
    altitude: 33,
    lat: 55.8846833,
    lng: 9.7532833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:18:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 50,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:18:26Z',
    altitude: 33,
    lat: 55.8847133,
    lng: 9.7533049,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:18:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 180,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:18:46Z',
    altitude: 34,
    lat: 55.884385,
    lng: 9.7532883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:19:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 126,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:19:02Z',
    altitude: 33,
    lat: 55.884145,
    lng: 9.7534983,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:19:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 71,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:19:19Z',
    altitude: 33,
    lat: 55.88405,
    lng: 9.753965,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:19:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 119,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:19:48Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:19:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 119,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:19:48Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:19:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 130,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 11.7,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:19:50Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:19:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 130,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 11.7,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:19:50Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:20:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 338,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:20:04Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:20:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 338,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:20:04Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:20:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 328,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:20:06Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:20:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 328,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:20:06Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:20:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 327,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:20:12Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:20:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 327,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:20:12Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 77,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:13Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 77,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:13Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 56,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:14Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 56,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:14Z',
    altitude: 14.65,
    lat: 55.88389903999999,
    lng: 9.754916715,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 36,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:21:15Z',
    altitude: 8,
    lat: 55.88391,
    lng: 9.7551183,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 186,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:21:28Z',
    altitude: 22,
    lat: 55.8836666,
    lng: 9.7548883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 186,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:21:28Z',
    altitude: 22,
    lat: 55.8836666,
    lng: 9.7548883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 213,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:21:29Z',
    altitude: 39,
    lat: 55.8835316,
    lng: 9.7547949,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 213,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:21:29Z',
    altitude: 39,
    lat: 55.8835316,
    lng: 9.7547949,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 266,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 0.7,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:50Z',
    altitude: 26.666666666666668,
    lat: 55.883793833333335,
    lng: 9.753466633333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 266,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 0.7,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:50Z',
    altitude: 26.666666666666668,
    lat: 55.883793833333335,
    lng: 9.753466633333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:51Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 281,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:51Z',
    altitude: 26.666666666666668,
    lat: 55.883793833333335,
    lng: 9.753466633333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:51Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 281,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:51Z',
    altitude: 26.666666666666668,
    lat: 55.883793833333335,
    lng: 9.753466633333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:54Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 302,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.8,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:54Z',
    altitude: 26.666666666666668,
    lat: 55.883793833333335,
    lng: 9.753466633333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:54Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 302,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.8,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:21:54Z',
    altitude: 26.666666666666668,
    lat: 55.883793833333335,
    lng: 9.753466633333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 5.277780055999756,
      heading: 333,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 5.277780055999756,
    },
    serverTime: '2024-09-02T05:21:55Z',
    altitude: 26,
    lat: 55.8836583,
    lng: 9.75384,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 5.277780055999756,
      heading: 333,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 5.277780055999756,
    },
    serverTime: '2024-09-02T05:21:55Z',
    altitude: 26,
    lat: 55.8836583,
    lng: 9.75384,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:21:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 5,
      heading: 354,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 5,
    },
    serverTime: '2024-09-02T05:21:56Z',
    altitude: 26,
    lat: 55.883655,
    lng: 9.7540482,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 354,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:00Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 354,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:00Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 34,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:03Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 34,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:03Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 17,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:04Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 17,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:04Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 169,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:10Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 169,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:22:10Z',
    altitude: 25.5,
    lat: 55.883517475,
    lng: 9.75453325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 137,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:22:28Z',
    altitude: 21,
    lat: 55.8833716,
    lng: 9.7546915,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:22:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 198,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:22:32Z',
    altitude: 18,
    lat: 55.8832766,
    lng: 9.7546483,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:23:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 185,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T05:23:22Z',
    altitude: 70,
    lat: 55.88281,
    lng: 9.754945,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:23:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 102,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T05:23:32Z',
    altitude: 23,
    lat: 55.8834266,
    lng: 9.7550083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:23:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 20,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:23:37Z',
    altitude: 23,
    lat: 55.883725,
    lng: 9.7548133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:23:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 323,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.08,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:23:59Z',
    altitude: 25,
    lat: 55.8842283,
    lng: 9.7548533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:24:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.611109972000122,
      heading: 313,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.611109972000122,
    },
    serverTime: '2024-09-02T05:24:01Z',
    altitude: 25,
    lat: 55.8842866,
    lng: 9.7547982,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:24:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 4.444439888000488,
      heading: 339,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 4.444439888000488,
    },
    serverTime: '2024-09-02T05:24:03Z',
    altitude: 25,
    lat: 55.8843516,
    lng: 9.754805,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:24:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 345,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:24:07Z',
    altitude: 22,
    lat: 55.8843916,
    lng: 9.7548083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 219,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:25:08Z',
    altitude: 14,
    lat: 55.8853533,
    lng: 9.7551016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 7,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:25:18Z',
    altitude: 18,
    lat: 55.8854583,
    lng: 9.7551016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 352,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T05:25:19Z',
    altitude: 18,
    lat: 55.885465,
    lng: 9.7551,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 338,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T05:25:26Z',
    altitude: 17,
    lat: 55.8855966,
    lng: 9.7550215,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:30Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 7,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:25:30Z',
    altitude: 14,
    lat: 55.8855666,
    lng: 9.7550533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:43Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 173,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:25:43Z',
    altitude: 12,
    lat: 55.8853399,
    lng: 9.7549999,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:25:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 206,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:25:46Z',
    altitude: 12,
    lat: 55.8852333,
    lng: 9.75494,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:26:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 287,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:26:13Z',
    altitude: 10,
    lat: 55.8851766,
    lng: 9.7549033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:26:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 50,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T05:26:15Z',
    altitude: 9,
    lat: 55.8851066,
    lng: 9.7549466,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:26:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 235,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:26:52Z',
    altitude: 173,
    lat: 55.8856633,
    lng: 9.7556483,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:28:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 87,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:28:33Z',
    altitude: 12,
    lat: 55.8844666,
    lng: 9.7554866,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:28:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 5,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:28:36Z',
    altitude: 11,
    lat: 55.8847616,
    lng: 9.755105,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:28:45Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 142,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:28:45Z',
    altitude: 11,
    lat: 55.8845183,
    lng: 9.7550483,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:28:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 132,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T05:28:46Z',
    altitude: 11,
    lat: 55.8844816,
    lng: 9.7551116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:28:51Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 104,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:28:51Z',
    altitude: 12,
    lat: 55.8844616,
    lng: 9.7553066,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:29:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 194,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T05:29:39Z',
    altitude: -7,
    lat: 55.885445,
    lng: 9.754825,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:29:41Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 184,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.8,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:29:41Z',
    altitude: -11,
    lat: 55.8854933,
    lng: 9.754825,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:29:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 200,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.8,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:29:42Z',
    altitude: -12,
    lat: 55.885485,
    lng: 9.7548216,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:29:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 183,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T05:29:46Z',
    altitude: -13,
    lat: 55.885325,
    lng: 9.7549016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:29:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 161,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:29:55Z',
    altitude: 5,
    lat: 55.885215,
    lng: 9.7550549,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:29:58Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 17,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:29:58Z',
    altitude: 12,
    lat: 55.885145,
    lng: 9.75508,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:30:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 11,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.18,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:30:05Z',
    altitude: 23,
    lat: 55.8852383,
    lng: 9.7550133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:30:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 157,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:30:18Z',
    altitude: 25,
    lat: 55.88528,
    lng: 9.75505,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:33:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.611109972000122,
      heading: 25,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.3,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.611109972000122,
    },
    serverTime: '2024-09-02T05:33:19Z',
    altitude: 78,
    lat: 55.8855766,
    lng: 9.7550366,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:33:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 4.444439888000488,
      heading: 13,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 3.2,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 4.444439888000488,
    },
    serverTime: '2024-09-02T05:33:21Z',
    altitude: 79,
    lat: 55.8856633,
    lng: 9.7551033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:33:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 21,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:33:27Z',
    altitude: 77,
    lat: 55.88562,
    lng: 9.7551283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:33:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 150,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:33:42Z',
    altitude: 69,
    lat: 55.8854666,
    lng: 9.7552565,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:34:40Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0.18755514997812428,
      heading: 274.7938534466174,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0.18755514997812428,
    },
    serverTime: '2024-09-02T05:34:40Z',
    altitude: 69,
    lat: 55.88547106673976,
    lng: 9.75516153335519,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:35:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 315,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:35:17Z',
    altitude: 59,
    lat: 55.88548,
    lng: 9.7549716,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:35:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 299,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T05:35:26Z',
    altitude: 55,
    lat: 55.88557,
    lng: 9.7545966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:35:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 284,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:35:28Z',
    altitude: 55,
    lat: 55.8855733,
    lng: 9.7545383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:35:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 252,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T05:35:29Z',
    altitude: 55,
    lat: 55.8855566,
    lng: 9.7544883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:35:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 268,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.2,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:35:33Z',
    altitude: 49,
    lat: 55.8854216,
    lng: 9.75435,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:37:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 52,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:37:26Z',
    altitude: 33,
    lat: 55.88561,
    lng: 9.7539583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:37:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 73,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:37:55Z',
    altitude: 32,
    lat: 55.8857149,
    lng: 9.7546883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 121,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:38:21Z',
    altitude: 29,
    lat: 55.8855133,
    lng: 9.7555583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 279,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:38:32Z',
    altitude: 28,
    lat: 55.8854066,
    lng: 9.755805,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 266,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.17,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:38:35Z',
    altitude: 28,
    lat: 55.8853416,
    lng: 9.7558216,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 295,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:38:36Z',
    altitude: 27,
    lat: 55.88535,
    lng: 9.7558083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:40Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 217,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:38:40Z',
    altitude: 26,
    lat: 55.8852233,
    lng: 9.755775,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 237,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T05:38:46Z',
    altitude: 24,
    lat: 55.8851716,
    lng: 9.7557016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:38:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 250,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:38:50Z',
    altitude: 22,
    lat: 55.8851783,
    lng: 9.7556049,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 280,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:39:00Z',
    altitude: 16,
    lat: 55.8853316,
    lng: 9.7553166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 269,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:39:01Z',
    altitude: 15,
    lat: 55.8853399,
    lng: 9.7552883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 330,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:39:05Z',
    altitude: 12,
    lat: 55.885395,
    lng: 9.75522,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 311,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:39:08Z',
    altitude: 11,
    lat: 55.885415,
    lng: 9.7551766,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 332,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:39:09Z',
    altitude: 10,
    lat: 55.885425,
    lng: 9.7551566,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 299,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:39:16Z',
    altitude: 4,
    lat: 55.8854716,
    lng: 9.75501,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 122,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:39:32Z',
    altitude: -6,
    lat: 55.8854183,
    lng: 9.75479,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:39:45Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 236,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:39:45Z',
    altitude: -12,
    lat: 55.88546,
    lng: 9.7541233,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:40:20Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 104,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:40:20Z',
    altitude: -14,
    lat: 55.8855833,
    lng: 9.7535583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:41:20Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 104,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:41:20Z',
    altitude: -5,
    lat: 55.8854,
    lng: 9.7551316,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:41:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 160,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:41:24Z',
    altitude: -5,
    lat: 55.8853216,
    lng: 9.75529,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:41:45Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 282,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:41:45Z',
    altitude: -4,
    lat: 55.8853183,
    lng: 9.7554416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:41:54Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 273,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:41:54Z',
    altitude: -2,
    lat: 55.8854283,
    lng: 9.7552749,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:42:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 275,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.25,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:42:00Z',
    altitude: 0,
    lat: 55.88548,
    lng: 9.7551333,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:42:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 279,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:42:05Z',
    altitude: 1,
    lat: 55.8855483,
    lng: 9.754965,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:42:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 307,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:42:12Z',
    altitude: 2,
    lat: 55.8855716,
    lng: 9.7547816,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:42:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 259,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:42:16Z',
    altitude: 2,
    lat: 55.8855516,
    lng: 9.7546799,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:42:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 273,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:42:18Z',
    altitude: 2,
    lat: 55.8855316,
    lng: 9.7546049,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:42:58Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 151,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:42:58Z',
    altitude: 5,
    lat: 55.8855083,
    lng: 9.75351,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:43:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 132,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:43:26Z',
    altitude: 9,
    lat: 55.88545,
    lng: 9.753705,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:44:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 152,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:44:29Z',
    altitude: 11,
    lat: 55.8854666,
    lng: 9.7554082,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:44:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 270,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:44:36Z',
    altitude: 10,
    lat: 55.8854283,
    lng: 9.755425,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:44:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 315,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:44:50Z',
    altitude: 8,
    lat: 55.8853883,
    lng: 9.7552749,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:45:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 225,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:45:26Z',
    altitude: 6,
    lat: 55.885405,
    lng: 9.7543199,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:45:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 321,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:45:35Z',
    altitude: 7,
    lat: 55.8855216,
    lng: 9.7540116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:45:38Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 283,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:45:38Z',
    altitude: 8,
    lat: 55.8855233,
    lng: 9.75381,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:48:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 178,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:48:37Z',
    altitude: 13,
    lat: 55.885405,
    lng: 9.7530816,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:49:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 22,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:49:33Z',
    altitude: 3,
    lat: 55.8852566,
    lng: 9.753265,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:49:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 20,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.19,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:49:39Z',
    altitude: 3,
    lat: 55.8853766,
    lng: 9.7532583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:49:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 33,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:49:42Z',
    altitude: 3,
    lat: 55.8854316,
    lng: 9.7532783,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:50:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 183,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:50:05Z',
    altitude: -1,
    lat: 55.8854016,
    lng: 9.7531816,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:50:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 193,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T05:50:07Z',
    altitude: -1,
    lat: 55.88536,
    lng: 9.7531666,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:50:30Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 4,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:50:30Z',
    altitude: -4,
    lat: 55.8852366,
    lng: 9.7531266,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:50:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 351,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T05:50:36Z',
    altitude: -4,
    lat: 55.885375,
    lng: 9.7531116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:50:41Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 6,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:50:41Z',
    altitude: -4,
    lat: 55.8855116,
    lng: 9.7531266,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:50:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 175,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:50:56Z',
    altitude: -5,
    lat: 55.88553,
    lng: 9.7531733,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:51:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 194,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T05:51:02Z',
    altitude: -3,
    lat: 55.8854166,
    lng: 9.7531366,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:51:30Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 34,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:51:30Z',
    altitude: 2,
    lat: 55.8853216,
    lng: 9.7531566,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:51:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 108,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:51:52Z',
    altitude: 6,
    lat: 55.885305,
    lng: 9.7534033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:52:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 15,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:52:04Z',
    altitude: 6,
    lat: 55.8853366,
    lng: 9.7533283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:57:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 18,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T05:57:05Z',
    altitude: 6,
    lat: 55.8853366,
    lng: 9.7533283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:57:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 48,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T05:57:15Z',
    altitude: 22,
    lat: 55.8853066,
    lng: 9.7536933,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:57:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 183,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:57:27Z',
    altitude: 21,
    lat: 55.8852416,
    lng: 9.75382,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:57:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 123,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:57:52Z',
    altitude: 18,
    lat: 55.885205,
    lng: 9.7540365,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T05:59:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 9,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T05:59:18Z',
    altitude: 18,
    lat: 55.88523,
    lng: 9.7539883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:00:34Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 211,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:00:34Z',
    altitude: 18,
    lat: 55.885235,
    lng: 9.7537065,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:00:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 13,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:00:56Z',
    altitude: 19,
    lat: 55.88524,
    lng: 9.7536483,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:01:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 139,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:01:12Z',
    altitude: 18,
    lat: 55.8851633,
    lng: 9.7537766,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:03:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 40,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:03:00Z',
    altitude: 20,
    lat: 55.8853416,
    lng: 9.7539666,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:03:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 12,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:03:14Z',
    altitude: 20,
    lat: 55.8853633,
    lng: 9.7541166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:05:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 321,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T06:05:22Z',
    altitude: 14,
    lat: 55.88532,
    lng: 9.7543183,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:05:30Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 126,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:05:30Z',
    altitude: 13,
    lat: 55.8853533,
    lng: 9.7544583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:05:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 180,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:05:57Z',
    altitude: 11,
    lat: 55.885285,
    lng: 9.7545249,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:07:40Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 4.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:07:40Z',
    altitude: 0,
    lat: 55.885235,
    lng: 9.7543033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:07:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 282,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:07:55Z',
    altitude: 9,
    lat: 55.88515,
    lng: 9.7541233,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:08:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 234,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:08:05Z',
    altitude: 9,
    lat: 55.8852133,
    lng: 9.7541666,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:08:38Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 95,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:08:38Z',
    altitude: 11,
    lat: 55.88524,
    lng: 9.7545083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:09:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 204,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:09:32Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:10:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 162,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:10:10Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:10:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 12.249074067223892,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:10:11Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:10:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 12.249074067223892,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:10:25Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:10:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 160,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:10:55Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:11:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 341,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:11:12Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:16:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 5,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:16:12Z',
    altitude: 19.583333333333332,
    lat: 55.88520731458335,
    lng: 9.754315483333135,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:16:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 51,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:16:31Z',
    altitude: 32,
    lat: 55.8853283,
    lng: 9.753685,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:16:51Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 187,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:16:51Z',
    altitude: 29,
    lat: 55.8851066,
    lng: 9.7536733,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:17:45Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 42,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:17:45Z',
    altitude: 38,
    lat: 55.8852766,
    lng: 9.7535665,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:19:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 128,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:19:48Z',
    altitude: 33,
    lat: 55.88561,
    lng: 9.7541,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:20:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 90,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:20:21Z',
    altitude: 36,
    lat: 55.8857433,
    lng: 9.7546649,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:21:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 148,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:21:01Z',
    altitude: 38,
    lat: 55.88557,
    lng: 9.75548,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:23:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.388890027999878,
      heading: 6,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.388890027999878,
    },
    serverTime: '2024-09-02T06:23:26Z',
    altitude: 28,
    lat: 55.88568,
    lng: 9.7539049,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:23:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 312,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:23:37Z',
    altitude: 29,
    lat: 55.8856983,
    lng: 9.7537333,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:24:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 106,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:24:02Z',
    altitude: 29,
    lat: 55.8857383,
    lng: 9.7534983,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:24:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 84,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:24:27Z',
    altitude: 29,
    lat: 55.8856883,
    lng: 9.7538866,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:24:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 102,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:24:33Z',
    altitude: 30,
    lat: 55.8856666,
    lng: 9.7542449,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:24:44Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 116,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:24:44Z',
    altitude: 30,
    lat: 55.8856066,
    lng: 9.754655,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:25:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 87,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:25:02Z',
    altitude: 30,
    lat: 55.8855766,
    lng: 9.7551266,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:25:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 275,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:25:56Z',
    altitude: 27,
    lat: 55.8856766,
    lng: 9.754565,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:28:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 185,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:28:19Z',
    altitude: 28,
    lat: 55.8852766,
    lng: 9.753265,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:30:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 205,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:30:05Z',
    altitude: 24,
    lat: 55.8855916,
    lng: 9.7534916,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:31:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 177,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:31:36Z',
    altitude: 17,
    lat: 55.8853783,
    lng: 9.75371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:31:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 303,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:31:56Z',
    altitude: 18,
    lat: 55.88554,
    lng: 9.7533999,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:32:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 18,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:32:36Z',
    altitude: 18,
    lat: 55.88555,
    lng: 9.7538132,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:32:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 248,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:32:50Z',
    altitude: 21,
    lat: 55.885595,
    lng: 9.7536466,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:32:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 225,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:32:59Z',
    altitude: 21,
    lat: 55.885595,
    lng: 9.75365,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:33:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 174,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:33:24Z',
    altitude: 26.4,
    lat: 55.88553152000011,
    lng: 9.753839286665286,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:35:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 16.187683317965252,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:35:14Z',
    altitude: 26.4,
    lat: 55.88553152000011,
    lng: 9.753839286665286,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:35:34Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 72,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:35:34Z',
    altitude: 26.4,
    lat: 55.88553152000011,
    lng: 9.753839286665286,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:35:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 132,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:35:39Z',
    altitude: 26.4,
    lat: 55.88553152000011,
    lng: 9.753839286665286,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:36:23Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 125,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:36:23Z',
    altitude: 26.4,
    lat: 55.88553152000011,
    lng: 9.753839286665286,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:37:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 23,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:37:32Z',
    altitude: 34,
    lat: 55.8856083,
    lng: 9.7540133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:39:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 221,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:39:16Z',
    altitude: 32,
    lat: 55.8855316,
    lng: 9.7540549,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:40:54Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 308,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:40:54Z',
    altitude: 36,
    lat: 55.8856233,
    lng: 9.7542449,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:41:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 293,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:41:04Z',
    altitude: 36,
    lat: 55.8858116,
    lng: 9.753795,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:41:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:41:52Z',
    altitude: 0,
    lat: 55.8857583,
    lng: 9.75406,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:43:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 192,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:43:50Z',
    altitude: 43,
    lat: 55.8851566,
    lng: 9.754265,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:44:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 192,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:44:55Z',
    altitude: 44,
    lat: 55.8854316,
    lng: 9.75424,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:45:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 192,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:45:21Z',
    altitude: 45,
    lat: 55.885605,
    lng: 9.7541999,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:46:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 176,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:46:24Z',
    altitude: 48,
    lat: 55.8855183,
    lng: 9.7542216,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:49:34Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0.8333330154418945,
      heading: 176,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0.8333330154418945,
    },
    serverTime: '2024-09-02T06:49:34Z',
    altitude: 42,
    lat: 55.8855883,
    lng: 9.754235,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:52:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 269,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:52:39Z',
    altitude: 37,
    lat: 55.8854633,
    lng: 9.7542183,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:52:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 78,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:52:55Z',
    altitude: 36,
    lat: 55.8854233,
    lng: 9.7542783,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:52:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 101,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:52:57Z',
    altitude: 36,
    lat: 55.8854166,
    lng: 9.7543016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:53:20Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 201,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:53:20Z',
    altitude: 34,
    lat: 55.8853466,
    lng: 9.754085,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:53:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 285,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:53:33Z',
    altitude: 35,
    lat: 55.8853399,
    lng: 9.753995,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:54:45Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 197,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:54:45Z',
    altitude: 36,
    lat: 55.8854866,
    lng: 9.75447,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:55:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 359,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:55:27Z',
    altitude: 37,
    lat: 55.8855416,
    lng: 9.7545133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:55:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 180,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T06:55:48Z',
    altitude: 36,
    lat: 55.8855266,
    lng: 9.7544115,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:57:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 95,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T06:57:00Z',
    altitude: 24,
    lat: 55.8844333,
    lng: 9.7546533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:57:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 191,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T06:57:14Z',
    altitude: 26,
    lat: 55.8843566,
    lng: 9.7544916,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:57:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 151,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:57:17Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:57:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 136,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.22,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:57:19Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:57:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:57:35Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T06:57:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T06:57:39Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:05Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:06Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:20Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:20Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:23Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.26,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:23Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:25Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:28Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:33:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 199.72128060906465,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:33:50Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 117,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:34:11Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 98,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:34:16Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 65,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:34:17Z',
    altitude: 27.964285714285715,
    lat: 55.88414925292578,
    lng: 9.754507894125897,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.611109972000122,
      heading: 308,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.611109972000122,
    },
    serverTime: '2024-09-02T07:34:36Z',
    altitude: 28,
    lat: 55.884385,
    lng: 9.7544948,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 333,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:34:50Z',
    altitude: 28,
    lat: 55.884645,
    lng: 9.7544083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:51Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 318,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T07:34:51Z',
    altitude: 28,
    lat: 55.884725,
    lng: 9.754325,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:34:58Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 2,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:34:58Z',
    altitude: 28,
    lat: 55.88494,
    lng: 9.7543916,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:35:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T07:35:01Z',
    altitude: 28,
    lat: 55.8850566,
    lng: 9.7547716,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:35:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 331,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:35:05Z',
    altitude: 27,
    lat: 55.8851416,
    lng: 9.754935,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:35:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 121,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:35:19Z',
    altitude: 26,
    lat: 55.8851533,
    lng: 9.7549783,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:35:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 81,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:35:24Z',
    altitude: 25,
    lat: 55.8851683,
    lng: 9.755085,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:35:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 132,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:35:48Z',
    altitude: 24,
    lat: 55.8852266,
    lng: 9.7551066,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:38:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 82,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:38:37Z',
    altitude: 29,
    lat: 55.8851883,
    lng: 9.7548383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:38:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 322,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:38:47Z',
    altitude: 30,
    lat: 55.8852833,
    lng: 9.7547699,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 308,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:39:11Z',
    altitude: 29,
    lat: 55.8855466,
    lng: 9.7544616,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 320,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:39:12Z',
    altitude: 29,
    lat: 55.8855666,
    lng: 9.754405,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 307,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.9,
      externalVoltage: 12.26,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:39:14Z',
    altitude: 29,
    lat: 55.88559,
    lng: 9.7543199,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 133,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:39:21Z',
    altitude: 30,
    lat: 55.8854749,
    lng: 9.7545449,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 231,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:39:24Z',
    altitude: 30,
    lat: 55.8854466,
    lng: 9.75456,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 174,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:39:32Z',
    altitude: 30,
    lat: 55.885395,
    lng: 9.7545933,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 163,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:39:33Z',
    altitude: 30,
    lat: 55.88536,
    lng: 9.7546316,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:39:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 151,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:39:56Z',
    altitude: 29,
    lat: 55.8854016,
    lng: 9.7547533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:40:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 161,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:40:15Z',
    altitude: 27,
    lat: 55.8853783,
    lng: 9.7547966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:40:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 310,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:40:28Z',
    altitude: 26,
    lat: 55.8854233,
    lng: 9.7545416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:40:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 289,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:40:36Z',
    altitude: 27,
    lat: 55.8854433,
    lng: 9.754435,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:41:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 129,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:41:13Z',
    altitude: 28,
    lat: 55.88545,
    lng: 9.7546883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:41:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 106,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:41:37Z',
    altitude: 31,
    lat: 55.8855183,
    lng: 9.754595,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:42:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 128,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:42:09Z',
    altitude: 34,
    lat: 55.8854166,
    lng: 9.7547016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:42:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 102,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.22,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:42:11Z',
    altitude: 34,
    lat: 55.88541,
    lng: 9.7547333,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:42:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 128,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.22,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:42:13Z',
    altitude: 35,
    lat: 55.88541,
    lng: 9.7547533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:42:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 67,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.16,
      batteryVoltage: 4.05,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:42:16Z',
    altitude: 36,
    lat: 55.8854216,
    lng: 9.7548533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:42:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 321,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:42:21Z',
    altitude: 35,
    lat: 55.88545,
    lng: 9.7548833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:42:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 244,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:42:32Z',
    altitude: 35,
    lat: 55.8853933,
    lng: 9.7547933,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:43:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 164,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:43:04Z',
    altitude: 36,
    lat: 55.8854983,
    lng: 9.7548483,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:44:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 348,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:44:25Z',
    altitude: 33,
    lat: 55.8855733,
    lng: 9.7548766,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:44:34Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 231,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:44:34Z',
    altitude: 32,
    lat: 55.8855133,
    lng: 9.7547633,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:44:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 261,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:44:39Z',
    altitude: 33,
    lat: 55.8854933,
    lng: 9.754575,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:45:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 139,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:45:05Z',
    altitude: 31,
    lat: 55.885555,
    lng: 9.75473,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:45:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 100,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:45:09Z',
    altitude: 30,
    lat: 55.885525,
    lng: 9.754885,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:45:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 82,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:45:37Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:46:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 39.293032621176714,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:46:13Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:47:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 39.293032621176714,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:47:47Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:50:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 188,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:50:27Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:50:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:50:42Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:51:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 181,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:51:36Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:51:49Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 291,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:51:49Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:51:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 295,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:51:52Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:51:53Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 284,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:51:53Z',
    altitude: 37.125,
    lat: 55.885451585000155,
    lng: 9.755396692499371,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:51:58Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 268,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.08,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:51:58Z',
    altitude: 42,
    lat: 55.885445,
    lng: 9.7554082,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 279,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:52:00Z',
    altitude: 42,
    lat: 55.885465,
    lng: 9.7553616,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 305,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T07:52:01Z',
    altitude: 43,
    lat: 55.8854899,
    lng: 9.75535,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 296,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.18,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T07:52:03Z',
    altitude: 42,
    lat: 55.8855133,
    lng: 9.7553132,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 284,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.17,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T07:52:06Z',
    altitude: 42,
    lat: 55.88555,
    lng: 9.7552066,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 295,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.8,
      externalVoltage: 11.94,
      batteryVoltage: 4.04,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:52:08Z',
    altitude: 42,
    lat: 55.885555,
    lng: 9.75517,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 256,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 11.88,
      batteryVoltage: 4.05,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:52:11Z',
    altitude: 43,
    lat: 55.885465,
    lng: 9.7553848,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 269,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.19,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:52:13Z',
    altitude: 43,
    lat: 55.8854566,
    lng: 9.7553033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 298,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T07:52:17Z',
    altitude: 42,
    lat: 55.8854833,
    lng: 9.755175,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:20Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.888890027999878,
      heading: 311,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.888890027999878,
    },
    serverTime: '2024-09-02T07:52:20Z',
    altitude: 41,
    lat: 55.885535,
    lng: 9.75507,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 300,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T07:52:26Z',
    altitude: 37,
    lat: 55.88562,
    lng: 9.7549366,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:30Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 302,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:52:30Z',
    altitude: 35,
    lat: 55.8855649,
    lng: 9.75514,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 290,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:52:31Z',
    altitude: 35,
    lat: 55.8855416,
    lng: 9.755115,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 279,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:52:32Z',
    altitude: 34,
    lat: 55.8855016,
    lng: 9.7551583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:41Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 136,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:52:41Z',
    altitude: 31,
    lat: 55.88535,
    lng: 9.7555966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:44Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 126,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:52:44Z',
    altitude: 28.8,
    lat: 55.88526829999999,
    lng: 9.75593598,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 112,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:52:46Z',
    altitude: 28.8,
    lat: 55.88526829999999,
    lng: 9.75593598,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:49Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 96,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:52:49Z',
    altitude: 28.8,
    lat: 55.88526829999999,
    lng: 9.75593598,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 79,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.21,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:52:52Z',
    altitude: 28.8,
    lat: 55.88526829999999,
    lng: 9.75593598,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:52:53Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 93,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.21,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:52:53Z',
    altitude: 28.8,
    lat: 55.88526829999999,
    lng: 9.75593598,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:53:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 135,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:53:09Z',
    altitude: 32,
    lat: 55.885295,
    lng: 9.7556049,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:53:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 145,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:53:13Z',
    altitude: 32,
    lat: 55.8852783,
    lng: 9.7554166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:53:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 75,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:53:37Z',
    altitude: 37,
    lat: 55.8851383,
    lng: 9.7555133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:53:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 80,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:53:48Z',
    altitude: 37,
    lat: 55.88514,
    lng: 9.7557166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:54:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 122,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:54:11Z',
    altitude: 38,
    lat: 55.8850983,
    lng: 9.755655,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:54:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 99,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:54:15Z',
    altitude: 39,
    lat: 55.885075,
    lng: 9.7557583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:54:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 319,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:54:25Z',
    altitude: 38,
    lat: 55.8851,
    lng: 9.75558,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:54:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 330,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:54:27Z',
    altitude: 38,
    lat: 55.885125,
    lng: 9.7555533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:54:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 317,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.16,
      batteryVoltage: 4.05,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:54:28Z',
    altitude: 38,
    lat: 55.88514,
    lng: 9.7555416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:54:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 344,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:54:52Z',
    altitude: 35,
    lat: 55.8852766,
    lng: 9.7553132,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:55:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 137,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:55:22Z',
    altitude: 35,
    lat: 55.8852283,
    lng: 9.7552366,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:57:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 271,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:57:19Z',
    altitude: 106,
    lat: 55.8844416,
    lng: 9.7551883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:57:23Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 276,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:57:23Z',
    altitude: 91,
    lat: 55.8844533,
    lng: 9.7550199,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:57:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 234,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:57:25Z',
    altitude: 74,
    lat: 55.88441,
    lng: 9.7548983,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:57:40Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 194,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:57:40Z',
    altitude: 40,
    lat: 55.8841533,
    lng: 9.7547183,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:57:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 174,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:57:46Z',
    altitude: 38,
    lat: 55.8839899,
    lng: 9.75474,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:57:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 154,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T07:57:47Z',
    altitude: 36,
    lat: 55.8839483,
    lng: 9.754785,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:58:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 6.388889789581299,
      heading: 187,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.5,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 6.388889789581299,
    },
    serverTime: '2024-09-02T07:58:17Z',
    altitude: 53,
    lat: 55.8835283,
    lng: 9.7547733,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:58:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 198,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:58:18Z',
    altitude: 31,
    lat: 55.8835249,
    lng: 9.7545483,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:58:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 154,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T07:58:39Z',
    altitude: 0,
    lat: 55.8832266,
    lng: 9.75416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:58:41Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 140,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T07:58:41Z',
    altitude: 0,
    lat: 55.8832083,
    lng: 9.75416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:58:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 18,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T07:58:47Z',
    altitude: 7,
    lat: 55.8832399,
    lng: 9.7543083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:58:53Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 29,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T07:58:53Z',
    altitude: 26,
    lat: 55.8833233,
    lng: 9.7545699,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:59:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 243,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:59:29Z',
    altitude: 14.25,
    lat: 55.88390457500002,
    lng: 9.754444983333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:59:30Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 89.9999958290871,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:59:30Z',
    altitude: 14.25,
    lat: 55.88390457500002,
    lng: 9.754444983333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:59:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 291,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:59:31Z',
    altitude: 14.25,
    lat: 55.88390457500002,
    lng: 9.754444983333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T07:59:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 259,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T07:59:32Z',
    altitude: 14.25,
    lat: 55.88390457500002,
    lng: 9.754444983333334,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 4.722219944000244,
      heading: 108,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 0.4,
      externalVoltage: 12.18,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 4.722219944000244,
    },
    serverTime: '2024-09-02T08:00:10Z',
    altitude: 34,
    lat: 55.8833866,
    lng: 9.7542816,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.611109972000122,
      heading: 126,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.611109972000122,
    },
    serverTime: '2024-09-02T08:00:12Z',
    altitude: 21,
    lat: 55.8833216,
    lng: 9.7543016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 139,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T08:00:14Z',
    altitude: 24,
    lat: 55.8832866,
    lng: 9.754405,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 114,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 0,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.23,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:00:17Z',
    altitude: 28,
    lat: 55.8832066,
    lng: 9.7545,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 86,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:00:18Z',
    altitude: 26,
    lat: 55.8832033,
    lng: 9.7545099,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 167,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T08:00:31Z',
    altitude: 48,
    lat: 55.8829316,
    lng: 9.7547149,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:34Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 106,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:00:34Z',
    altitude: 50.75,
    lat: 55.882884974999996,
    lng: 9.754808225000001,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 88,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.06,
      batteryVoltage: 4.04,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:00:35Z',
    altitude: 50.75,
    lat: 55.882884974999996,
    lng: 9.754808225000001,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 98,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.14,
      batteryVoltage: 4.05,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:00:36Z',
    altitude: 50.75,
    lat: 55.882884974999996,
    lng: 9.754808225000001,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:00:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 88,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:00:39Z',
    altitude: 50.75,
    lat: 55.882884974999996,
    lng: 9.754808225000001,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 121,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:01:00Z',
    altitude: 65,
    lat: 55.88298,
    lng: 9.7548533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 317,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:01:14Z',
    altitude: 81,
    lat: 55.8830933,
    lng: 9.754655,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 297,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:01:16Z',
    altitude: 81,
    lat: 55.8830916,
    lng: 9.7545383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 281,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:01:19Z',
    altitude: 81,
    lat: 55.88308,
    lng: 9.7543899,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 299,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 11.95,
      batteryVoltage: 4.05,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:01:24Z',
    altitude: 78,
    lat: 55.8830633,
    lng: 9.7543466,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:28Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 318,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.22,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:01:28Z',
    altitude: 75,
    lat: 55.883145,
    lng: 9.75436,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:01:43Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 99,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:01:43Z',
    altitude: 71,
    lat: 55.8832249,
    lng: 9.7544948,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:02:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 76,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:02:03Z',
    altitude: 66,
    lat: 55.883325,
    lng: 9.75459,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:03:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0.5555559992790222,
      heading: 279,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 9,
      ignition: false,
      movement: true,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0.5555559992790222,
    },
    serverTime: '2024-09-02T08:03:27Z',
    altitude: 57,
    lat: 55.88333,
    lng: 9.7528433,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:03:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 151,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:03:52Z',
    altitude: 57,
    lat: 55.8833383,
    lng: 9.7526966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:04:27Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 117,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:04:27Z',
    altitude: 58,
    lat: 55.8833333,
    lng: 9.7526099,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:04:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 117,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:04:32Z',
    altitude: 59,
    lat: 55.8833716,
    lng: 9.7526349,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:04:44Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.388890027999878,
      heading: 328,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 3.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.388890027999878,
    },
    serverTime: '2024-09-02T08:04:44Z',
    altitude: 59,
    lat: 55.8834883,
    lng: 9.7525516,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 79,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:05:05Z',
    altitude: 57,
    lat: 55.883135,
    lng: 9.7530233,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 10,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:05:29Z',
    altitude: 56,
    lat: 55.8832249,
    lng: 9.7535399,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 93,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:05:35Z',
    altitude: 57,
    lat: 55.8832133,
    lng: 9.7538132,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 79,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:05:52Z',
    altitude: 60,
    lat: 55.8831533,
    lng: 9.7543266,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 58,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:05:56Z',
    altitude: 61,
    lat: 55.883125,
    lng: 9.7544966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 70,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:05:57Z',
    altitude: 61,
    lat: 55.8831216,
    lng: 9.75455,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:05:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 94,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:05:59Z',
    altitude: 61,
    lat: 55.8831133,
    lng: 9.75465,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 329,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:06:01Z',
    altitude: 62.25,
    lat: 55.88314245000001,
    lng: 9.754744125,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 314,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.2,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:06:05Z',
    altitude: 62.25,
    lat: 55.88314245000001,
    lng: 9.754744125,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 296,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.17,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:06:06Z',
    altitude: 62.25,
    lat: 55.88314245000001,
    lng: 9.754744125,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 284,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.15,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:06:07Z',
    altitude: 62.25,
    lat: 55.88314245000001,
    lng: 9.754744125,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 327,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:06:15Z',
    altitude: 64,
    lat: 55.8830716,
    lng: 9.7549566,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 110,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:06:33Z',
    altitude: 62,
    lat: 55.882945,
    lng: 9.755705,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 4.166669845581055,
      heading: 99,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.22,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 4.166669845581055,
    },
    serverTime: '2024-09-02T08:06:36Z',
    altitude: 63,
    lat: 55.88288,
    lng: 9.7560266,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:38Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 4.166669845581055,
      heading: 112,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 4.166669845581055,
    },
    serverTime: '2024-09-02T08:06:38Z',
    altitude: 63,
    lat: 55.8828466,
    lng: 9.7561983,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:44Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 94,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:06:44Z',
    altitude: 63,
    lat: 55.88281,
    lng: 9.7565233,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:06:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 120,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:06:50Z',
    altitude: 63,
    lat: 55.8828266,
    lng: 9.7566666,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:07:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 138,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T08:07:42Z',
    altitude: 63,
    lat: 55.882595,
    lng: 9.75672,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:07:49Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 124,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:07:49Z',
    altitude: 62,
    lat: 55.88253,
    lng: 9.7568383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:07:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 290,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:07:55Z',
    altitude: 61,
    lat: 55.8825783,
    lng: 9.7565133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:07:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 306,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:07:56Z',
    altitude: 61,
    lat: 55.8826016,
    lng: 9.7564183,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:07:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 292,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.17,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:07:57Z',
    altitude: 61,
    lat: 55.8826183,
    lng: 9.7563248,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:07:58Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 282,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.23,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:07:58Z',
    altitude: 61,
    lat: 55.8826266,
    lng: 9.7562366,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 307,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.22,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:08:00Z',
    altitude: 60,
    lat: 55.8826583,
    lng: 9.756115,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 283,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:08:08Z',
    altitude: 60,
    lat: 55.882655,
    lng: 9.7559033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 273,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:08:10Z',
    altitude: 59,
    lat: 55.8826633,
    lng: 9.7558083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 283,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T08:08:14Z',
    altitude: 57,
    lat: 55.88269,
    lng: 9.7555383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 282,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:08:19Z',
    altitude: 56,
    lat: 55.882745,
    lng: 9.7552799,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:23Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 294,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T08:08:23Z',
    altitude: 54,
    lat: 55.8827933,
    lng: 9.7550283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 298,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:08:29Z',
    altitude: 50.6,
    lat: 55.882857520000016,
    lng: 9.754624645000202,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 162.04972528038365,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.22,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:08:31Z',
    altitude: 50.6,
    lat: 55.882857520000016,
    lng: 9.754624645000202,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:45Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 162.04972528038365,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:08:45Z',
    altitude: 50.6,
    lat: 55.882857520000016,
    lng: 9.754624645000202,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 92,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.18,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:08:48Z',
    altitude: 50.6,
    lat: 55.882857520000016,
    lng: 9.754624645000202,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:08:49Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 110,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:08:49Z',
    altitude: 50.6,
    lat: 55.882857520000016,
    lng: 9.754624645000202,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:09:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 141,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 0.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:09:12Z',
    altitude: 39,
    lat: 55.88279,
    lng: 9.7539733,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:09:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 96,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:09:37Z',
    altitude: 42,
    lat: 55.8828533,
    lng: 9.7535566,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 107,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:10:01Z',
    altitude: 43,
    lat: 55.88296,
    lng: 9.7540699,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 77,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.26,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:10:03Z',
    altitude: 44,
    lat: 55.8829466,
    lng: 9.7542583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 126,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:10:24Z',
    altitude: 44,
    lat: 55.8828983,
    lng: 9.7547183,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 64,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:10:35Z',
    altitude: 44,
    lat: 55.883025,
    lng: 9.7551233,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 332,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:10:48Z',
    altitude: 44,
    lat: 55.8830633,
    lng: 9.7551216,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:49Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.611109972000122,
      heading: 315,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.611109972000122,
    },
    serverTime: '2024-09-02T08:10:49Z',
    altitude: 44,
    lat: 55.8831166,
    lng: 9.7550833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:10:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 281,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:10:52Z',
    altitude: 44,
    lat: 55.8831016,
    lng: 9.7550683,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:11:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 79,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:11:09Z',
    altitude: 38,
    lat: 55.882885,
    lng: 9.7557583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:11:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 111,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 5,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:11:42Z',
    altitude: 37,
    lat: 55.8827766,
    lng: 9.7564416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 128,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:12:08Z',
    altitude: 41,
    lat: 55.8827816,
    lng: 9.7558583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 181,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:12:12Z',
    altitude: 42,
    lat: 55.882735,
    lng: 9.7556315,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 298,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:12:26Z',
    altitude: 38,
    lat: 55.8828,
    lng: 9.7551033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 268,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:12:35Z',
    altitude: 39,
    lat: 55.8827816,
    lng: 9.7547116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 273,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:12:42Z',
    altitude: 39,
    lat: 55.8828316,
    lng: 9.7544533,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:49Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 305,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.25,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T08:12:49Z',
    altitude: 37,
    lat: 55.88296,
    lng: 9.7539415,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 318,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:12:50Z',
    altitude: 37,
    lat: 55.8829716,
    lng: 9.7539166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 302,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:12:56Z',
    altitude: 35,
    lat: 55.8830216,
    lng: 9.7536083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:12:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 282,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:12:57Z',
    altitude: 35,
    lat: 55.8830133,
    lng: 9.7535716,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 258,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:13:02Z',
    altitude: 33,
    lat: 55.882970775000004,
    lng: 9.7533337,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 269,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:13:03Z',
    altitude: 33,
    lat: 55.882970775000004,
    lng: 9.7533337,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 282,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.21,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:13:05Z',
    altitude: 33,
    lat: 55.882970775000004,
    lng: 9.7533337,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 269,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:13:06Z',
    altitude: 33,
    lat: 55.882970775000004,
    lng: 9.7533337,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:11Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 305,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:13:11Z',
    altitude: 34,
    lat: 55.8829383,
    lng: 9.753195,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 108,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:13:31Z',
    altitude: 32,
    lat: 55.8830899,
    lng: 9.7532833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 128,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T08:13:35Z',
    altitude: 32,
    lat: 55.8830283,
    lng: 9.7534783,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 117,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T08:13:36Z',
    altitude: 32,
    lat: 55.88302,
    lng: 9.7535166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 120,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:13:42Z',
    altitude: 33,
    lat: 55.8830233,
    lng: 9.7538083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:43Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 91,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.25,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:13:43Z',
    altitude: 33,
    lat: 55.8830316,
    lng: 9.753855,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:13:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 129,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.4,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:13:47Z',
    altitude: 33,
    lat: 55.8829866,
    lng: 9.7539798,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 111,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:14:14Z',
    altitude: 34,
    lat: 55.8829316,
    lng: 9.7543633,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 96,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.03,
      batteryVoltage: 4.05,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:14:21Z',
    altitude: 35,
    lat: 55.8828633,
    lng: 9.7550199,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 73,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:14:24Z',
    altitude: 35,
    lat: 55.882875,
    lng: 9.7552565,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:29Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 332,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.22,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:14:29Z',
    altitude: 36,
    lat: 55.8829216,
    lng: 9.7554283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 349,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:14:33Z',
    altitude: 36.75,
    lat: 55.882997025,
    lng: 9.755349525,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 334,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:14:35Z',
    altitude: 36.75,
    lat: 55.882997025,
    lng: 9.755349525,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 322,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.22,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:14:36Z',
    altitude: 36.75,
    lat: 55.882997025,
    lng: 9.755349525,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:14:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 292,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:14:37Z',
    altitude: 36.75,
    lat: 55.882997025,
    lng: 9.755349525,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:15:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 63,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:15:00Z',
    altitude: 36,
    lat: 55.8830966,
    lng: 9.7560383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:15:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 109,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:15:25Z',
    altitude: 33,
    lat: 55.8829366,
    lng: 9.7564016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:15:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 70,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T08:15:57Z',
    altitude: 39,
    lat: 55.8828566,
    lng: 9.7559799,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:15:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 30,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.27,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:15:59Z',
    altitude: 40,
    lat: 55.8829,
    lng: 9.7557265,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 257,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:16:06Z',
    altitude: 40,
    lat: 55.8828483,
    lng: 9.7554133,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 244,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.2,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T08:16:09Z',
    altitude: 39,
    lat: 55.8828266,
    lng: 9.7551566,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 234,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:16:10Z',
    altitude: 39,
    lat: 55.8828116,
    lng: 9.7550933,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 302,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:16:14Z',
    altitude: 39,
    lat: 55.8828166,
    lng: 9.75494,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 320,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.24,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:16:18Z',
    altitude: 38,
    lat: 55.8829016,
    lng: 9.7547616,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:23Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 269,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:16:23Z',
    altitude: 37,
    lat: 55.8829283,
    lng: 9.7546016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:25Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 289,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:16:25Z',
    altitude: 36,
    lat: 55.8829366,
    lng: 9.75445,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 303,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T08:16:26Z',
    altitude: 36,
    lat: 55.8829583,
    lng: 9.7543883,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 243,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:16:32Z',
    altitude: 34,
    lat: 55.8829849,
    lng: 9.75419,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:16:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 278,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.27,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:16:37Z',
    altitude: 30,
    lat: 55.8829866,
    lng: 9.7539033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:17:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 205,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:17:03Z',
    altitude: 23,
    lat: 55.8830066,
    lng: 9.75321,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:17:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 169,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:17:05Z',
    altitude: 23,
    lat: 55.8829866,
    lng: 9.7531499,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:17:09Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 145,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:17:09Z',
    altitude: 24,
    lat: 55.8828966,
    lng: 9.75311,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:17:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 125,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:17:37Z',
    altitude: 27,
    lat: 55.8828133,
    lng: 9.75298,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:17:40Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 108,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:17:40Z',
    altitude: 27,
    lat: 55.8827866,
    lng: 9.7530833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:18:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 91,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:18:06Z',
    altitude: 26,
    lat: 55.8827616,
    lng: 9.7534216,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:18:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 112,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:18:07Z',
    altitude: 26,
    lat: 55.8827383,
    lng: 9.7534783,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:18:37Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 34,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:18:37Z',
    altitude: 26,
    lat: 55.8828533,
    lng: 9.7536933,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:20:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 46,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:20:33Z',
    altitude: 25,
    lat: 55.8826466,
    lng: 9.755265,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:20:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 288,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T08:20:48Z',
    altitude: 25,
    lat: 55.8826133,
    lng: 9.7552,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:20:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 260,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:20:55Z',
    altitude: 24,
    lat: 55.8826816,
    lng: 9.7547966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:20:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 293,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 3.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:20:59Z',
    altitude: 22.75,
    lat: 55.88270433750732,
    lng: 9.754465387493937,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:21:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 255.37289667046306,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:21:03Z',
    altitude: 22.75,
    lat: 55.88270433750732,
    lng: 9.754465387493937,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:21:10Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 255.37289667046306,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:21:10Z',
    altitude: 22.75,
    lat: 55.88270433750732,
    lng: 9.754465387493937,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:21:31Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 123,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:21:31Z',
    altitude: 22.75,
    lat: 55.88270433750732,
    lng: 9.754465387493937,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:21:38Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 161,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:21:38Z',
    altitude: 20,
    lat: 55.882585,
    lng: 9.7541916,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:21:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 184,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.23,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:21:39Z',
    altitude: 20,
    lat: 55.8825783,
    lng: 9.754115,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:21:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 293,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.3,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:21:48Z',
    altitude: 22,
    lat: 55.8825783,
    lng: 9.7537866,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:22:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 336,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:22:55Z',
    altitude: 22,
    lat: 55.8829,
    lng: 9.75274,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:23:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 57,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:23:21Z',
    altitude: 21,
    lat: 55.8829283,
    lng: 9.7530132,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:23:26Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 92,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:23:26Z',
    altitude: 20,
    lat: 55.88292,
    lng: 9.7533999,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:23:41Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 65,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:23:41Z',
    altitude: 21,
    lat: 55.8829399,
    lng: 9.75403,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:24:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 40,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:24:07Z',
    altitude: 21,
    lat: 55.8827716,
    lng: 9.7546582,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:24:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 160,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:24:48Z',
    altitude: 25,
    lat: 55.8826566,
    lng: 9.75414,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:25:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 133,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:25:24Z',
    altitude: 26,
    lat: 55.8826983,
    lng: 9.7534016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 68,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:26:22Z',
    altitude: 23,
    lat: 55.8829083,
    lng: 9.75341,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:38Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 308,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:26:38Z',
    altitude: 22,
    lat: 55.88276827999999,
    lng: 9.75418492,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 278,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.25,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:26:42Z',
    altitude: 22,
    lat: 55.88276827999999,
    lng: 9.75418492,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:43Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 289,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:26:43Z',
    altitude: 22,
    lat: 55.88276827999999,
    lng: 9.75418492,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 327,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.22,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:26:46Z',
    altitude: 22,
    lat: 55.88276827999999,
    lng: 9.75418492,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 295,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:26:47Z',
    altitude: 22,
    lat: 55.88276827999999,
    lng: 9.75418492,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.611109972000122,
      heading: 310,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.26,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.611109972000122,
    },
    serverTime: '2024-09-02T08:26:50Z',
    altitude: 22,
    lat: 55.8828583,
    lng: 9.7540365,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:26:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 298,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:26:52Z',
    altitude: 22,
    lat: 55.8828666,
    lng: 9.7540283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:27:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 136,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:27:07Z',
    altitude: 20,
    lat: 55.8828,
    lng: 9.7544433,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:27:12Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 146,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.14,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:27:12Z',
    altitude: 19,
    lat: 55.8827266,
    lng: 9.754625,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:27:35Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 147,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:27:35Z',
    altitude: 21,
    lat: 55.8825833,
    lng: 9.7542683,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:27:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 162,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:27:39Z',
    altitude: 22,
    lat: 55.8825183,
    lng: 9.75419,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:27:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 236,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:27:57Z',
    altitude: 25,
    lat: 55.8825283,
    lng: 9.7538015,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:28:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 349,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.24,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:28:07Z',
    altitude: 27,
    lat: 55.8826683,
    lng: 9.7534116,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:28:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 324,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T08:28:59Z',
    altitude: 23,
    lat: 55.8828233,
    lng: 9.7528266,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:29:07Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 182,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T08:29:07Z',
    altitude: 22,
    lat: 55.88281,
    lng: 9.7527966,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:29:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 2,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T08:29:56Z',
    altitude: 19,
    lat: 55.8829416,
    lng: 9.7527983,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:29:58Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 29,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T08:29:58Z',
    altitude: 19,
    lat: 55.8829783,
    lng: 9.7528066,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:30:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 6,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:30:00Z',
    altitude: 19,
    lat: 55.8830066,
    lng: 9.7528165,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:35:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 4.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:35:00Z',
    altitude: 19,
    lat: 55.8830066,
    lng: 9.7528165,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:40:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:40:01Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:45:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5.2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:45:02Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:50:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5.4,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:50:03Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T08:55:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T08:55:03Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:00:03Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:00:03Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:05:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 6.2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:05:04Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:10:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 6.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:10:04Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:15:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 6.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:15:05Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:20:05Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 7.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:20:05Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:25:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 7.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:25:06Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:30:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 3,
      ignition: false,
      movement: true,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 7.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:30:06Z',
    altitude: 0,
    lat: 55.88296027272727,
    lng: 9.753682390909093,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:32:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 242,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:32:02Z',
    altitude: 16,
    lat: 55.883215,
    lng: 9.7549416,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:33:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 220,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:33:47Z',
    altitude: 22,
    lat: 55.8831933,
    lng: 9.7550833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:33:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 280,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:33:56Z',
    altitude: 23,
    lat: 55.8831716,
    lng: 9.754885,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:34:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 233,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T09:34:39Z',
    altitude: 22,
    lat: 55.8831633,
    lng: 9.7548283,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:34:42Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 245,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:34:42Z',
    altitude: 23,
    lat: 55.8831333,
    lng: 9.7547833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:34:52Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 229,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.8,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T09:34:52Z',
    altitude: 23,
    lat: 55.88304,
    lng: 9.7546666,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:34:55Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 197,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T09:34:55Z',
    altitude: 24,
    lat: 55.882965,
    lng: 9.7547583,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:35:08Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 44,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T09:35:08Z',
    altitude: 24,
    lat: 55.8830633,
    lng: 9.755165,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:35:13Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.0555601119995117,
      heading: 33,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 5.7,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.0555601119995117,
    },
    serverTime: '2024-09-02T09:35:13Z',
    altitude: 24,
    lat: 55.8832816,
    lng: 9.7555349,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:35:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 31,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.9,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:35:15Z',
    altitude: 24,
    lat: 55.8833083,
    lng: 9.755545,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:35:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 302,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.6,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T09:35:21Z',
    altitude: 25,
    lat: 55.8833149,
    lng: 9.7553216,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:35:43Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 172,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.5,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T09:35:43Z',
    altitude: 26,
    lat: 55.88307,
    lng: 9.7552833,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:36:32Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 220,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:36:32Z',
    altitude: 30,
    lat: 55.8830899,
    lng: 9.7548083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:36:33Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 232,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T09:36:33Z',
    altitude: 31,
    lat: 55.8830766,
    lng: 9.754755,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:36:36Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 219,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.32,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:36:36Z',
    altitude: 31,
    lat: 55.88307,
    lng: 9.7547633,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:36:47Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 44,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:36:47Z',
    altitude: 34,
    lat: 55.8831716,
    lng: 9.7550733,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:36:56Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 82,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:36:56Z',
    altitude: 36,
    lat: 55.8831316,
    lng: 9.7552299,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:37:16Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 233,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:37:16Z',
    altitude: 40,
    lat: 55.8830683,
    lng: 9.7549783,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:37:17Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 245,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T09:37:17Z',
    altitude: 41,
    lat: 55.8830366,
    lng: 9.7548383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:37:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.333329916000366,
      heading: 230,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.333329916000366,
    },
    serverTime: '2024-09-02T09:37:18Z',
    altitude: 41,
    lat: 55.8829999,
    lng: 9.7547433,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:37:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.777780055999756,
      heading: 242,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.777780055999756,
    },
    serverTime: '2024-09-02T09:37:24Z',
    altitude: 43,
    lat: 55.882895,
    lng: 9.7543383,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:37:40Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 246,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 11.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:37:40Z',
    altitude: 44,
    lat: 55.8828983,
    lng: 9.754225,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:37:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 168,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:37:59Z',
    altitude: 48.5,
    lat: 55.882817750000456,
    lng: 9.754549099999458,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:38:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 161,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.25,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:38:01Z',
    altitude: 48.5,
    lat: 55.882817750000456,
    lng: 9.754549099999458,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:38:44Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 71.5922293705019,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:38:44Z',
    altitude: 48.5,
    lat: 55.882817750000456,
    lng: 9.754549099999458,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:39:15Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 244,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 4,
      ignition: false,
      movement: true,
      gsmSignalStrength: 1,
      cellID: 0,
      hdop: 2.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:39:15Z',
    altitude: 48.5,
    lat: 55.882817750000456,
    lng: 9.754549099999458,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:40:14Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 347,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:40:14Z',
    altitude: 55,
    lat: 55.883275,
    lng: 9.7551516,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:40:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 3.888890027999878,
      heading: 1,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 3.888890027999878,
    },
    serverTime: '2024-09-02T09:40:57Z',
    altitude: 85,
    lat: 55.8838183,
    lng: 9.7550016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:40:59Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 8.055560111999512,
      heading: 20,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 8.055560111999512,
    },
    serverTime: '2024-09-02T09:40:59Z',
    altitude: 76,
    lat: 55.8839133,
    lng: 9.7550083,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:00Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 4.166669845581055,
      heading: 14,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.22,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 4.166669845581055,
    },
    serverTime: '2024-09-02T09:41:00Z',
    altitude: 77,
    lat: 55.88394,
    lng: 9.7550166,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.111109972000122,
      heading: 7,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.09,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.111109972000122,
    },
    serverTime: '2024-09-02T09:41:02Z',
    altitude: 76,
    lat: 55.8839899,
    lng: 9.7550199,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 116,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:41:21Z',
    altitude: 46,
    lat: 55.8839583,
    lng: 9.7554033,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 128,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T09:41:22Z',
    altitude: 46,
    lat: 55.8839466,
    lng: 9.7554649,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:24Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 104,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.9,
      externalVoltage: 12.23,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T09:41:24Z',
    altitude: 46,
    lat: 55.8839633,
    lng: 9.7555816,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:46Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.5,
      heading: 299,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.5,
    },
    serverTime: '2024-09-02T09:41:46Z',
    altitude: 42,
    lat: 55.884035,
    lng: 9.7555932,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:41:48Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 300,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2.3,
      externalVoltage: 12.27,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T09:41:48Z',
    altitude: 40,
    lat: 55.8840583,
    lng: 9.75539,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:42:06Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 109,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T09:42:06Z',
    altitude: 37,
    lat: 55.8840433,
    lng: 9.755855,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:43:53Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 108,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: false,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:43:53Z',
    altitude: 36,
    lat: 55.8839233,
    lng: 9.757,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:44:02Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 108,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 6,
      ignition: false,
      movement: true,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 1.7,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:44:02Z',
    altitude: 36,
    lat: 55.8839233,
    lng: 9.757,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:47:18Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 266,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:47:18Z',
    altitude: 33,
    lat: 55.8839583,
    lng: 9.7566016,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:47:22Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 2.222219944000244,
      heading: 279,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 3,
      cellID: 0,
      hdop: 0.7,
      externalVoltage: 12.25,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 2.222219944000244,
    },
    serverTime: '2024-09-02T09:47:22Z',
    altitude: 29,
    lat: 55.8838016,
    lng: 9.75638,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:47:39Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 263,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T09:47:39Z',
    altitude: 22,
    lat: 55.8840866,
    lng: 9.7558899,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:48:01Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 229,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:48:01Z',
    altitude: 22,
    lat: 55.8840783,
    lng: 9.755155,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:48:19Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.6666699647903442,
      heading: 246,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.28,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 1.6666699647903442,
    },
    serverTime: '2024-09-02T09:48:19Z',
    altitude: 22,
    lat: 55.8840533,
    lng: 9.7546616,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:48:21Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 1.9444400072097778,
      heading: 225,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: true,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.3,
      externalVoltage: 12.25,
      batteryVoltage: 4.06,
      batteryCurrent: 0,
      speed: 1.9444400072097778,
    },
    serverTime: '2024-09-02T09:48:21Z',
    altitude: 22,
    lat: 55.884025,
    lng: 9.7545766,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T09:49:50Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 256,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 8,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.1,
      externalVoltage: 12.29,
      batteryVoltage: 4.07,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T09:49:50Z',
    altitude: 20.5,
    lat: 55.8844033,
    lng: 9.753435,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T10:49:57Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 7,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 1.6,
      externalVoltage: 0,
      batteryVoltage: 3.78,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T10:49:57Z',
    altitude: 20.5,
    lat: 55.8844033,
    lng: 9.753435,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
  {
    logTime: '2024-09-02T11:50:04Z',
    gpsId: '13',
    velocity: {
      groundSpeed: 0,
      heading: 0,
    },
    variables: {
      harshAccelerationDigital: false,
      harshBrakingDigital: false,
      harshCorneringDigital: false,
      satelliteCount: 5,
      ignition: false,
      movement: false,
      gsmSignalStrength: 2,
      cellID: 0,
      hdop: 2,
      externalVoltage: 0,
      batteryVoltage: 3.5,
      batteryCurrent: 0,
      speed: 0,
    },
    serverTime: '2024-09-02T11:50:04Z',
    altitude: 20.5,
    lat: 55.8844033,
    lng: 9.753435,
    orgId: '7brqXhTGkYzWwWEOoEzm',
  },
];
