import SpinLoader from './index';

interface IProps {
  height?: string;
}

function ImageLoader({ height = '300px' }: IProps) {
  return (
    <div
      style={{
        width: '100%',
        height,
        backgroundColor: '#c0c0c0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SpinLoader />
    </div>
  );
}

export default ImageLoader;
