import React, { useEffect, useState } from 'react';
import { functions } from '../../../firebase';

export default function usePipedriveOrgDeals(id: number | null) {
  const [loading, setLoading] = useState(false);
  const [deals, setDeals] = useState<any[]>([]);
  useEffect(() => {
    const getOrgs = async () => {
      try {
        setLoading(true);
        if (!id) {
          return;
        }
        const functionRef = functions.httpsCallable('getPipedriveOrgDeals');
        const response: any = await functionRef({ id });

        const errorCode = response?.data?.httpErrorCode?.status;
        if (errorCode) {
          console.error({ errorCode });
        }

        const d = response?.data?.data?.map((d: any) => ({
          title: d.title,
          stage: response.data.related_objects.stage[d.stage_id]?.name,
        }));

        setDeals(d);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getOrgs();

    return () => {};
  }, [id]);
  return { loadingDeals: loading, deals };
}
