import { Unit } from '../../../components/SelectUnit';
import TableEditCancel from '../../../components/Table/TableEditCancel';
import { t } from 'i18next';

export default function columns(
  editingKey: string,
  isEditing: (record: any) => boolean,
  save: (key: string, amount: number) => void,
  edit: (record: any) => void,
  cancel: () => void,
  orgUnit: Unit
) {
  return [
    {
      title: t('cleanlog.table.time'),
      dataIndex: 'createTime',
      editable: false,
    },
    {
      title: t('cleanlog.table.initials'),
      dataIndex: 'initials',
      editable: true,
    },
    {
      // TODO: use actual unit here
      title: t('cleanlog.table.amount', { unit: orgUnit }),
      dataIndex: 'amount',
      editable: true,
    },
    {
      title: t('cleanlog.table.action'),
      dataIndex: 'operation',
      render: (_: any, record: any) => (
        <TableEditCancel
          record={record}
          editingKey={editingKey}
          isEditing={isEditing}
          save={save}
          edit={edit}
          cancel={cancel}
        />
      ),
    },
  ];
}
