import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { Responsible } from '../../Views/Issues/IssueDetail';

export const ISSUES_FETCH_SUCCEEDED = 'ISSUES_FETCH_SUCCEEDED';
export const ISSUES_FETCH_FAILED = 'ISSUES_FETCH_FAILED';
export const ISSUES_FETCH_REQUESTED = 'ISSUES_FETCH_REQUESTED';

export const ISSUE_FETCH_SUCCEEDED = 'ISSUE_FETCH_SUCCEEDED';
export const ISSUE_FETCH_FAILED = 'ISSUE_FETCH_FAILED';
export const ISSUE_FETCH_REQUESTED = 'ISSUE_FETCH_REQUESTED';

export const ISSUE_UPDATE_SUCCEEDED = 'ISSUE_UPDATE_SUCCEEDED';
export const ISSUE_UPDATE_FAILED = 'ISSUE_UPDATE_FAILED';
export const ISSUE_UPDATE_REQUESTED = 'ISSUE_UPDATE_REQUESTED';

export const ISSUE_CREATE_REQUESTED = 'ISSUE_CREATE_REQUESTED';

export const ISSUE_DELETE_REQUESTED = 'ISSUE_DELETE_REQUESTED';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';

export const ISSUE_CALENDAR_CHANGE = 'ISSUE_CALENDAR_CHANGE';

export const ISSUES_COMPLETED_FETCH_SUCCEEDED = 'ISSUES_COMPLETED_FETCH_SUCCEEDED';
export const ISSUES_COMPLETED_FETCH_FAILED = 'ISSUES_COMPLETED_FETCH_FAILED';
export const ISSUES_COMPLETED_FETCH_REQUESTED = 'ISSUES_COMPLETED_FETCH_REQUESTED';

export enum TAB_STATE {
  INCOMPLETED = 'incompleted',
  COMPLETED = 'completed',
}

export interface Issue {
  id: string;
  image: { thumbnail: string };
  createTime: { toDate: () => Date };
  completeTime: { toDate: () => Date };
  description: string;
  responsible: Responsible;
  assignee?: {
    id: string;
    name: string;
    assignTime: Date;
  };
  files?: string[];
  tags: string[];
  displayTags: string[];
}

export interface IssuesState {
  issues: Issue[];
  currentIssue: Issue & {
    thumbs: any[];
    tags: string[];
  };
  loading: boolean;
  currentTab: TAB_STATE;
  loadingCompleted: boolean;
  issuesCompleted: Issue[];
  calendarDate: Date;
  calendarMode: CalendarMode;
}

interface FetchIssueLoading {
  type:
    | typeof ISSUES_FETCH_REQUESTED
    | typeof ISSUES_COMPLETED_FETCH_REQUESTED
    | typeof ISSUE_FETCH_REQUESTED
    | typeof ISSUES_FETCH_FAILED
    | typeof ISSUE_FETCH_FAILED
    | typeof ISSUES_COMPLETED_FETCH_FAILED;
  payload: {};
}
interface FetchIssueSuccess {
  type: typeof ISSUE_FETCH_SUCCEEDED;
  payload: { data: any };
}
interface FetchIssuesSuccess {
  type: typeof ISSUES_FETCH_SUCCEEDED;
  payload: { data: any[] };
}

interface FetchIssuesCompletedSuccess {
  type: typeof ISSUES_COMPLETED_FETCH_SUCCEEDED;
  payload: { data: any[] };
}
interface SetCurrentTab {
  type: typeof SET_CURRENT_TAB;
  payload: { key: TAB_STATE };
}

interface IssueCalendarChange {
  type: typeof ISSUE_CALENDAR_CHANGE;
  payload: { date: Date; mode: CalendarMode };
}

export type IssuesActionTypes =
  | FetchIssueLoading
  | FetchIssueSuccess
  | FetchIssuesSuccess
  | FetchIssuesCompletedSuccess
  | SetCurrentTab
  | IssueCalendarChange;
