import { createContext, useContext, useReducer } from 'react';
import { initialState, OfferAction, offerReducer, OfferState } from './offerReducer';
import {
  aqSensorDataPrice,
  aqSensorPrice,
  coverageM2PrLiter,
  coverageM2PrLiterSmall,
  dustMeasurementPrice,
  gpsSensorDataPrice,
  gpsSensorPrice,
  krPrM2Large,
  krPrM2Small,
  limitM2,
  pricePrLiterLarge,
  pricePrLiterSmall,
  tabletPrice,
  weeksPrYear,
} from './offerConfigs';
import { AppOffer } from 'hooks/useAppOffers';

interface OfferContextProps {
  state: OfferState;
  dispatch: React.Dispatch<OfferAction>;
}

const OfferContext = createContext<OfferContextProps | undefined>(undefined);

export const OfferProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(offerReducer, initialState);

  return <OfferContext.Provider value={{ state, dispatch }}>{children}</OfferContext.Provider>;
};

export const useOfferContext = () => {
  const context = useContext(OfferContext);
  if (!context) {
    throw new Error('useOfferContext must be used within an OfferProvider');
  }
  return context;
};

function isLarge(m2: number) {
  return m2 >= limitM2;
}

export function getLiterPrice(m2: number) {
  const literPrice = isLarge(m2) ? pricePrLiterLarge : pricePrLiterSmall;
  const { litersPrYear, litersPrMonth } = getLiters(m2);
  return {
    yearlyLiterPrice: litersPrYear * literPrice,
    monthlyLiterPrice: litersPrMonth * literPrice,
  };
}

export function getLiters(m2: number) {
  const litersPrMonth =
    ((m2 / (isLarge(m2) ? coverageM2PrLiter : coverageM2PrLiterSmall)) * weeksPrYear) / 12;
  return {
    litersPrYear: litersPrMonth * 12,
    litersPrMonth: litersPrMonth,
  };
}

export function getSubscriptionPrice(m2: number) {
  const monthlyPrice = m2 >= limitM2 ? m2 * krPrM2Large : m2 * krPrM2Small;
  return {
    yearlySubscriptionPrice: monthlyPrice * 12,
    monthlySubscriptionPrice: monthlyPrice,
  };
}

export function getDiscount(price: number, discount: number) {
  return price * (1 - discount / 100);
}

export function getPrice(m2: number) {
  return m2 >= limitM2 ? pricePrLiterLarge : pricePrLiterSmall;
}

export function getAdditionalServicesSubscriptionFixedPrice({
  sensors,
  gps,
  tablets,
  dustMeasurements,
}: OfferState | AppOffer['offer']) {
  const sensorPriceTotal = sensors * aqSensorPrice;
  const gpsPriceTotal = gps * gpsSensorPrice;
  const tabletPriceTotal = tablets * tabletPrice;
  const dustMeasurementPriceTotal = dustMeasurements * dustMeasurementPrice;
  return {
    yearly: sensorPriceTotal + gpsPriceTotal + tabletPriceTotal + dustMeasurementPriceTotal,
    monthly: (sensorPriceTotal + gpsPriceTotal + tabletPriceTotal + dustMeasurementPriceTotal) / 12,
  };
}
export function getAdditionalServicesSubscriptionVariablePrice({
  sensors,
  gps,
}: OfferState | AppOffer['offer']) {
  const sensorPriceTotal = sensors * aqSensorDataPrice;
  const gpsPriceTotal = gps * gpsSensorDataPrice;

  return {
    yearlyData: sensorPriceTotal + gpsPriceTotal,
    monthlyData: (sensorPriceTotal + gpsPriceTotal) / 12,
  };
}

export function getDiscountFactor(discount: number) {
  return (100 - discount) / 100;
}
