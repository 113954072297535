// import { Timestamp } from "@google-cloud/firestore";

export const HOURTRACKERS_FETCH_SUCCEEDED = 'HOURTRACKERS_FETCH_SUCCEEDED';
export const HOURTRACKERS_FETCH_FAILED = 'HOURTRACKERS_FETCH_FAILED';
export const HOURTRACKERS_FETCH_REQUESTED = 'HOURTRACKERS_FETCH_REQUESTED';

export const HOURTRACKER_CREATE_SUCCEEDED = 'HOURTRACKER_CREATE_SUCCEEDED';
export const HOURTRACKER_CREATE_FAILED = 'HOURTRACKER_CREATE_FAILED';
export const HOURTRACKER_CREATE_REQUESTED = 'HOURTRACKER_CREATE_REQUESTED';

export const HOURTRACKER_UPDATE_SUCCEEDED = 'HOURTRACKER_UPDATE_SUCCEEDED';
export const HOURTRACKER_UPDATE_FAILED = 'HOURTRACKER_UPDATE_FAILED';
export const HOURTRACKER_UPDATE_REQUESTED = 'HOURTRACKER_UPDATE_REQUESTED';

export interface HourTracker {
  amount: number;
  createTime: any; //Timestamp;
  initials: string;
  product: {
    id: string;
    title: string;
  };
}

export interface HourTrackerState {
  list: HourTracker[];
  map: { [id: string]: HourTracker };
  loading: boolean;
}

interface FetchHourTrackers {
  type: typeof HOURTRACKERS_FETCH_REQUESTED;
  payload: { orgId: string };
}
export interface FetchHourTrackersSuccess {
  type: typeof HOURTRACKERS_FETCH_SUCCEEDED;
  payload: { data: HourTracker[] };
}
interface FetchHourTrackersFailed {
  type: typeof HOURTRACKERS_FETCH_FAILED;
  payload: {};
}

export type HourTrackersActionTypes =
  | FetchHourTrackers
  | FetchHourTrackersSuccess
  | FetchHourTrackersFailed;
