import React from 'react';
import { Layout } from 'antd';
const { Footer } = Layout;

const year = new Date().getFullYear();

function FooterBar() {
  return <Footer style={{ textAlign: 'center' }}>AKS X © {year}</Footer>;
}

export default FooterBar;
