import { DirectoryState, DBDirectoryShape } from './types';
import {
  DIRECTORIES_FETCH_REQUESTED,
  DIRECTORIES_FETCH_SUCCEEDED,
  DIRECTORIES_FETCH_FAILED,
  DirectoryActionTypes,
} from './types';

const initialState: {
  directories: DBDirectoryShape[];
  loading: boolean;
} = {
  directories: [],
  loading: true,
};

export default function directories(
  state = initialState,
  action: DirectoryActionTypes
): DirectoryState {
  switch (action.type) {
    case DIRECTORIES_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case DIRECTORIES_FETCH_SUCCEEDED:
      return {
        ...state,
        directories: action.payload,
        loading: false,
      };
    case DIRECTORIES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
