import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import type { InventoryItem } from '../types';

interface CheckInDialogProps {
  item: InventoryItem;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onCheckIn: (updatedItem: InventoryItem) => void;
}

export default function CheckInDialog({ item, open, onOpenChange, onCheckIn }: CheckInDialogProps) {
  const handleCheckIn = () => {
    const updatedItem: InventoryItem = {
      ...item,
      status: 'Available',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    };
    onCheckIn(updatedItem);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Check In Equipment</DialogTitle>
          <DialogDescription>Confirm that you want to check in this equipment.</DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <p>Equipment: {item.name}</p>
          <p>Checked Out To: {item.checkedOutTo}</p>
          <p>Check Out Date: {new Date(item.checkOutDate!).toLocaleDateString()}</p>
          <p>Expected Return Date: {new Date(item.expectedReturnDate!).toLocaleDateString()}</p>
          <Button onClick={handleCheckIn}>Confirm Check In</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
