import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../../firebase';
import * as actionType from './types';
import { InventoryType } from '../types';
import { fetchInventorySimcardSuccess } from './actions';

// FOOBOT
function* fetchInventorySimcards() {
  try {
    yield call(
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`inventory`).where('type', '==', InventoryType.SIM_CARDS),
      { successActionCreator: fetchInventorySimcardSuccess }
    );
  } catch (e: any) {
    yield put({
      type: actionType.SIM_CARDS_INVENTORY_FETCH_FAILED,
      message: e.message,
    });
  }
}

export function* fetchInventorySimcardsSaga() {
  // @ts-ignore
  yield takeLatest(actionType.SIM_CARDS_INVENTORY_FETCH_REQUESTED, fetchInventorySimcards);
}
