import './polyfills';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './globals.css';
import * as serviceWorker from './serviceWorker';

import 'moment/locale/da.js';
import './firebase';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import './languages/i18n';

import 'dayjs/locale/da'; // import locale
import { StyleProvider } from '@ant-design/cssinjs';
import { ConfigProvider } from 'antd';
import { StyleGuide } from 'styles/StyleGuide';
import { Toaster } from 'components/ui/sonner';
import { TooltipProvider } from './components/ui/tooltip';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://a708328a937d431c9f6bb28b3a9d623c@o1213658.ingest.sentry.io/6352999',
    environment: process.env.NODE_ENV,
    debug: true,
    // turning deprecated warnings off
    ignoreErrors: [/^Warning/],
    integrations: [
      new CaptureConsole({
        levels: ['error'],
      }),
      // new Sentry.BrowserTracing({
      //     // See docs for support of different versions of variation of react router
      //     // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      //     routingInstrumentation: Sentry.reactRouterV6Instrumentation(
      //         React.useEffect,
      //         useLocation,
      //         useNavigationType,
      //         createRoutesFromChildren,
      //         matchRoutes,
      //     ),
      // }),
      // new Sentry.Replay(),
    ],
  });
}
console.log({ ENV: process.env.NODE_ENV });

const rootEl = document.getElementById('root');

const root = createRoot(rootEl!);
root.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>} showDialog>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: StyleGuide.palette.infoDark,
          colorLink: StyleGuide.palette.info,
          colorLinkHover: StyleGuide.palette.infoLight,
          // colorError: StyleGuide.palette.redish,
          // colorSuccess: StyleGuide.palette.success,
        },
        components: {
          Button: {
            colorPrimary: StyleGuide.palette.infoDark,
            defaultShadow: 'none',
            primaryShadow: 'none',
            defaultBorderColor: StyleGuide.palette.grey200,
            algorithm: true, // Enable algorithm
          },
          Input: {
            // colorPrimary: "#eb2f96",
            algorithm: true, // Enable algorithm
          },
          Layout: {
            bodyBg: StyleGuide.palette.grey50,
            siderBg: StyleGuide.palette.grey0,
            headerBg: StyleGuide.palette.infoDark,
            headerHeight: 48,
            triggerBg: StyleGuide.palette.infoLight,
            triggerColor: StyleGuide.palette.infoDark,
            footerBg: 'transparent',
            // footerColor: "black",
          },
          Select: {
            optionSelectedBg: StyleGuide.palette.infoLight,
            algorithm: true, // Enable algorithm
          },
          Menu: {
            colorPrimary: StyleGuide.palette.infoLight,
            itemColor: StyleGuide.palette.infoDark,
            itemActiveBg: StyleGuide.palette.infoLight,
            itemSelectedBg: StyleGuide.palette.infoLight,
            itemSelectedColor: StyleGuide.palette.infoDark,
            algorithm: true, // Enable algorithm
            itemBg: StyleGuide.palette.grey0,
            subMenuItemBg: StyleGuide.palette.grey100,
          },
          Calendar: {
            itemActiveBg: StyleGuide.palette.infoLighter,
          },
          Badge: {
            colorPrimary: '#1677ff', //StyleGuide.palette.info,
            algorithm: true, // Enable algorithm
          },
        },
        // layout: {
        //     Sider: {
        //         colorPrimary: "#eb2f96",
        //         algorithm: true, // Enable algorithm
        //     },
        // },
        // token: {
        //     // Seed Token
        //     colorPrimary: "#00b96b",

        //     // Alias Token
        //     // colorBgContainer: "#f6ffed",
        // },
      }}
    >
      <StyleProvider hashPriority="high">
        <TooltipProvider>
          <App />
        </TooltipProvider>
        <Toaster />
      </StyleProvider>
    </ConfigProvider>
  </Sentry.ErrorBoundary>
);
serviceWorker.unregister();
