import { CaretRightFilled } from '@ant-design/icons';
import React, { useState } from 'react';
import { decimalFormat } from './utils';
import { Colors } from 'Layout/Colors';
import { StyleGuide } from '../../styles/StyleGuide';

export default function CategoryBlock({
  id,
  isPartialCompleted,
  allCompleted,
  summedForCategory,
  children,
}: {
  id: string;
  isPartialCompleted: boolean;
  allCompleted: boolean;
  summedForCategory: { [unit: string]: number }[];
  children: React.ReactNode;
}) {
  const [expanded, setExpanded] = useState(true);

  // sum by key
  const summedForCategoryKeys = getSummed(summedForCategory);

  const values = Object.entries(summedForCategoryKeys)?.map(([unit, value]: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <div>
          {decimalFormat.format(value)} {unit}
        </div>
      </div>
    );
  });

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: 8,
          backgroundColor: getCompletionColor(isPartialCompleted, allCompleted),
          borderRadius: 8,
          cursor: 'pointer',
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{
              display: 'flex',
              transition: 'all 0.3s',
              transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
              transformOrigin: 'center',
            }}
          >
            <CaretRightFilled />
          </div>
          <div style={{ fontSize: 24 }}>{id}</div>
        </div>
        <div style={{ fontSize: 24 }}>{values}</div>
      </div>
      {expanded && children}
    </div>
  );
}

export function getSummed(summedForCategory: { [unit: string]: number }[]) {
  const summedForCategoryKeys = summedForCategory.reduce((acc: any, item: any) => {
    Object.keys(item).forEach((key) => {
      if (!acc[key]) {
        acc[key] = 0;
      }
      acc[key] += item[key];
    });
    return acc;
  }, {});
  return summedForCategoryKeys;
}

export function getCompletionColor(isPartialCompleted: boolean, allCompleted: boolean) {
  if (isPartialCompleted) {
    return StyleGuide.palette.statusHardWarningLight;
  }
  if (allCompleted) {
    return StyleGuide.palette.statusSuccessLight;
  }
  return StyleGuide.palette.statusErrorLight;
}
