import React, { useState, CSSProperties } from 'react';
import ReactPlayer from 'react-player';
import { Row, Col, Typography, Button, Checkbox, Image, Divider } from 'antd';
import { Colors } from '../../../Layout/Colors';
import { CheckOutlined, CheckSquareOutlined, CheckCircleOutlined } from '@ant-design/icons';
import floors from '../assets/Floors.jpg';
import equipment from '../assets/equipment.png';
import mix from '../assets/mix.png';
import Schema from './Schema';

const { Title, Paragraph } = Typography;

export default function Step2({ next }: { next: () => void }) {
  const [startedVideo, setStartedVideo] = useState(false);
  return (
    <Row>
      <Col md={24}>
        <Title style={styles.body}>Sådan anvendes støvbinder</Title>
      </Col>
      <Col xs={24} md={12}>
        <div style={styles.wrapper}>
          <Image src={floors} preview={false} />
          {/* <Image src={equipment} preview={false} />
                    <Image src={mix} preview={false} /> */}
        </div>
      </Col>
      <Col xs={24} md={12}>
        <Title level={4} style={styles.title}>
          Gulvtyper
        </Title>
        <Paragraph style={styles.body}>
          <ul style={{ listStyleType: 'none' }}>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Et produkt til alle vaskbare gulve
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Kan anvendes på trægulv blot det ikke er nylagt og ellers er forbehandlet efter
              producentens anvisninger.
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              aks Støvbinder har en høj plejeeffekt ikke mindst på "åbne" gulve som beton, træ og
              linolium.
            </li>
          </ul>
        </Paragraph>
      </Col>
      <Col xs={24} md={24} style={styles.body}>
        <Divider />
      </Col>

      <Col xs={24} md={12}>
        <div style={styles.wrapper}>
          <Image src={equipment} preview={false} height={240} />
        </div>
      </Col>
      <Col xs={24} md={12}>
        <Title level={4} style={styles.title}>
          Udstyr
        </Title>
        <Paragraph style={styles.body}>
          <ul style={{ listStyleType: 'none' }}>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Kan bruges med moppe
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Kan bruges med gulvvaskemaskine
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Udstyret skal blot være rengjort fra tidligere rengøringsmidler og skyllet igennem med
              rent vand
            </li>
          </ul>
        </Paragraph>
      </Col>
      <Col xs={24} md={24} style={styles.body}>
        <Divider />
      </Col>
      <Col xs={24} md={12}>
        <div style={{ ...styles.wrapper, paddingBottom: 32 }}>
          <Schema />

          {/* <Image src={equipment} preview={false} />
                    <Image src={mix} preview={false} /> */}
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            paddingBottom: 24,
          }}
        >
          <div>
            <Title level={4} style={styles.title}>
              Blandingsforhold
            </Title>
            <Paragraph style={styles.body}>
              <ul style={{ listStyleType: 'none' }}>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  Udfra gulvtypen finder du blandingsforholdet
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  Skulle der ved en fejl bruges for meget støvbinder kan dette løses ved at
                  vaskegulvet med rent vand
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  Se illustration for eksempel for på bladingsforhold
                </li>
              </ul>
            </Paragraph>
            <div style={{ marginBottom: 40 }} />
            <Divider>Eksempel: 2% opløsning</Divider>
            <table style={{ width: '100%' }}>
              <tr>
                <td colSpan={2}>
                  <Image src={mix} preview={false} height={240} />
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: '50%',
                    textAlign: 'right',
                    paddingRight: 60,
                  }}
                >
                  <div>5 liter</div>
                </td>
                <td
                  style={{
                    width: '50%',
                    textAlign: 'left',
                    paddingLeft: 25,
                  }}
                >
                  <div>100 milliter</div>
                </td>
              </tr>
            </table>
          </div>
          <div>
            <Button type="primary" block onClick={next}>
              {'Næste'}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const styles: { [key: string]: CSSProperties } = {
  wrapper: {
    position: 'relative',
    padding: 24,
  },
  player: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'none',
  },
  title: {
    textAlign: 'left',
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  body: {
    textAlign: 'left',
    paddingLeft: 24,
    paddingRight: 24,
  },
  listItem: { margin: 0, marginBottom: 16, display: 'flex', alignItems: 'center' },
  icon: {
    fontSize: 18,
    marginRight: 8,
    color: Colors.info,
  },
};
