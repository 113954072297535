import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import keyBy from 'lodash.keyby';
import AuditScheduleList from './AuditScheduleList';
import { useAppSelector } from '../../hooks';
import { fetchAuditTemplates } from '../../modules/auditTemplates/actions';
import { fetchAuditSchedules, addAuditSchedule } from '../../modules/auditSchedule/actions';
import { fetchGroupAuditTemplates } from 'modules/groupAuditTemplates/actions';

function AuditScheduleListContainer() {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { orgId } = useParams<{ orgId: string }>();
  const fromDate = useAppSelector((state) => state.auditSchedules.calendarDateStart);
  const toDate = useAppSelector((state) => state.auditSchedules.calendarDateEnd);
  const groupId = useAppSelector((state) => state.orgs.currentOrg?.groupId);
  const user = useAppSelector((state) => state.user.details);
  const { auditTemplatesByKey, auditSchedules, loading } = useAppSelector((state) => ({
    auditTemplatesByKey: keyBy(state.auditTemplates.auditTemplates, (template) => template.id),
    auditSchedules: state.auditSchedules.auditSchedules,
    loading: state.auditTemplates.loading || state.auditSchedules.loading,
  }));

  useEffect(() => {
    dispatch(fetchAuditTemplates({ orgId }));
  }, [orgId, dispatch]);
  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroupAuditTemplates({ groupId }));
    }
  }, [groupId, dispatch]);
  useEffect(() => {
    console.log({ orgId, toDate, fromDate });
    dispatch(
      fetchAuditSchedules({
        orgId,
        // adding an extra week in each end to display sourinding weeks
        toDate, //: addWeeks(new Date(toDate), 1),
        fromDate, //: subWeeks(new Date(fromDate), 1),
      })
    );
  }, [orgId, dispatch, toDate, fromDate]);

  const addAuditScheduleFunc = (data: any) => {
    dispatch(
      addAuditSchedule({
        orgId,
        groupId,
        data: {
          ...data,
          createBy: { id: user.uid, name: user?.displayName || user?.email },
        },
      })
    );
  };

  return (
    <AuditScheduleList
      auditTemplatesByKey={auditTemplatesByKey}
      auditSchedules={auditSchedules}
      loading={loading}
      orgId={orgId}
      // initialDate={initDate}
      addAuditSchedule={addAuditScheduleFunc}
    />
  );
}

export default AuditScheduleListContainer;
