import { Badge } from 'components/ui/badge';
import React from 'react';
import { getStatusColor, getStatusIcon } from './components/AuditMatrix';

const status = [
  { value: 'passed', label: 'Godkendt' },
  { value: 'observation', label: 'Observation' },
  { value: 'deviation', label: 'Afvigelse' },
  { value: 'deviation-resolved', label: 'Afvigelse løst' },
  { value: 'not-audited', label: 'Ikke auditeret' },
];

export default function Legend() {
  return (
    <div className="flex gap-2 mb-8">
      {status.map(({ value, label }) => {
        const colorCls = getStatusColor(value);
        const icon = getStatusIcon(value);
        return (
          <Badge className={colorCls}>
            {icon} <span className="ml-1">{label}</span>
          </Badge>
        );
      })}
    </div>
  );
}
