import keyBy from 'lodash.keyby';
import {
  PUMP_INVENTORY_FETCH_REQUESTED,
  PUMP_INVENTORY_FETCH_FAILED,
  PUMP_INVENTORY_FETCH_SUCCEEDED,
} from './pumps/types';
import {
  FOOBOT_INVENTORY_FETCH_REQUESTED,
  FOOBOT_INVENTORY_FETCH_FAILED,
  FOOBOT_INVENTORY_FETCH_SUCCEEDED,
} from './foobot/types';
import {
  ESP_AQ_INVENTORY_FETCH_REQUESTED,
  ESP_AQ_INVENTORY_FETCH_FAILED,
  ESP_AQ_INVENTORY_FETCH_SUCCEEDED,
} from './esp_aq/types';
import {
  GPS_INVENTORY_FETCH_REQUESTED,
  GPS_INVENTORY_FETCH_FAILED,
  GPS_INVENTORY_FETCH_SUCCEEDED,
} from './gps/types';
import {
  InventoryActionTypes,
  InventoryState,
  InventoryContextForType,
  InventoryType,
} from './types';
import {
  IPAD_INVENTORY_FETCH_REQUESTED,
  IPAD_INVENTORY_FETCH_SUCCEEDED,
  IPAD_INVENTORY_FETCH_FAILED,
} from './ipad/types';
import {
  SIM_CARDS_INVENTORY_FETCH_FAILED,
  SIM_CARDS_INVENTORY_FETCH_REQUESTED,
  SIM_CARDS_INVENTORY_FETCH_SUCCEEDED,
} from './simcards/types';

const initialState: InventoryState = {
  loading: true,
  ipadIds: [],
  gpsIds: [],
  foobotIds: [],
  espAqIds: [],
  pumpIds: [],
  simcardIds: [],
  inventoryMap: {},
};

export default function pumps(state = initialState, action: InventoryActionTypes): InventoryState {
  switch (action.type) {
    case IPAD_INVENTORY_FETCH_REQUESTED:
    case GPS_INVENTORY_FETCH_REQUESTED:
    case FOOBOT_INVENTORY_FETCH_REQUESTED:
    case ESP_AQ_INVENTORY_FETCH_REQUESTED:
    case PUMP_INVENTORY_FETCH_REQUESTED:
    case SIM_CARDS_INVENTORY_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case IPAD_INVENTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        ipadIds: action.payload.map(({ id }) => id),
        inventoryMap: {
          ...state.inventoryMap,
          ...keyBy(action.payload, 'id'),
        } as {
          [id: string]: InventoryContextForType<InventoryType>;
        },
      };
    case GPS_INVENTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        gpsIds: action.payload.map(({ id }) => id),
        inventoryMap: {
          ...state.inventoryMap,
          ...keyBy(action.payload, 'id'),
        } as {
          [id: string]: InventoryContextForType<InventoryType>;
        },
      };
    case FOOBOT_INVENTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        foobotIds: action.payload.map(({ id }) => id),
        inventoryMap: {
          ...state.inventoryMap,
          ...keyBy(action.payload, 'id'),
        } as {
          [id: string]: InventoryContextForType<InventoryType>;
        },
      };
    case ESP_AQ_INVENTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        espAqIds: action.payload.map(({ id }) => id),
        inventoryMap: {
          ...state.inventoryMap,
          ...keyBy(action.payload, 'id'),
        } as {
          [id: string]: InventoryContextForType<InventoryType>;
        },
      };
    case PUMP_INVENTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        pumpIds: action.payload.map(({ id }) => id),
        inventoryMap: {
          ...state.inventoryMap,
          ...keyBy(action.payload, 'id'),
        } as {
          [id: string]: InventoryContextForType<InventoryType>;
        },
      };
    case SIM_CARDS_INVENTORY_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        simcardIds: action.payload.map(({ id }) => id),
        inventoryMap: {
          ...state.inventoryMap,
          ...keyBy(action.payload, 'id'),
        } as {
          [id: string]: InventoryContextForType<InventoryType>;
        },
      };
    case IPAD_INVENTORY_FETCH_FAILED:
    case GPS_INVENTORY_FETCH_FAILED:
    case FOOBOT_INVENTORY_FETCH_FAILED:
    case ESP_AQ_INVENTORY_FETCH_FAILED:
    case PUMP_INVENTORY_FETCH_FAILED:
    case SIM_CARDS_INVENTORY_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
