import { Layout, Row, Col, List } from 'antd';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';

const { Content } = Layout;

function ActivityList({ activity }: any) {
  const renderItem = (item: any) => (
    <List.Item style={{ borderBottom: '1px solid #c0c0c0' }}>
      <List.Item.Meta avatar={item.icon} title={item.title} description={item.description} />
      <div>{item.time}</div>
    </List.Item>
  );

  return (
    <Layout>
      <HeaderBar />
      <Content style={{ margin: '50px 24px 0' }}>
        <Row gutter={16}>
          <Col md={24} xs={24}>
            <List itemLayout="horizontal" dataSource={activity} renderItem={renderItem} />
          </Col>
        </Row>
      </Content>
      <FooterBar />
    </Layout>
  );
}

export default ActivityList;
