export type OrderStatus = (typeof OrderStatusEnum)[keyof typeof OrderStatusEnum];
export const enum OrderStatusEnum {
  AWAITING = 'AWAITING',
  PROCESSING = 'PROCESSING',
  READY_FOR_SHIPPING = 'READY_FOR_SHIPPING',
  IN_TRANSIT = 'IN_TRANSIT',
  COMPLETED = 'COMPLETED',
  VOIDED = 'VOIDED',
}

export interface Product {
  id: string;
  name: string;
  quantity: number;
  unit?: string;
}

export interface AvailableProduct {
  id: string;
  name: string;
  availableUnits: string[]; // Array of possible units
  defaultUnit?: string; // Default unit to use
}

export interface OrderHistory extends Order {
  change: { type: 'status' | 'orderEmail'; from: OrderStatusEnum; to: OrderStatusEnum };
}

export interface DBOrder {
  id: string;
  orgId: string;
  companyName: string;
  products: Product[];
  delivery: { date: Date; address: string; zip: string; city: string; att: string };
  status: OrderStatus;
  contact: { name: string };
  createdBy: { id: string; displayName: string };
  createTime: Date;
  updateTime: Date;
  // history?: OrderHistory[]; // Made optional since it will be loaded separately
}
export interface Order {
  id: string;
  orgId: string;
  companyName: string;
  products: Product[];
  deliveryDate: Date;
  status: OrderStatus;
  deliveryAddress?: string;
  nextExpectedDeliveryDate?: Date;
  deliveryZip?: string;
  deliveryCity?: string;
  deliveryAtt?: string;
  contactPerson?: string;
  createdBy: { id: string; displayName: string };
  createTime: Date;
  updateTime?: Date;
  // history?: OrderHistory[]; // Made optional since it will be loaded separately
}

export const orderStatuses = [
  // OrderStatusEnum.AWAITING,
  OrderStatusEnum.PROCESSING,
  OrderStatusEnum.READY_FOR_SHIPPING,
  OrderStatusEnum.IN_TRANSIT,
  OrderStatusEnum.COMPLETED,
  // OrderStatusEnum.VOIDED,
];
