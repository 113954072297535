import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import 'firebase/compat/functions';
// Leave out Storage
import 'firebase/compat/storage';
import uuidV4 from 'uuid/v4';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { Region } from './general/enums';
import { isDev } from 'config';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';

const config = {
  apiKey: 'AIzaSyCXO6NkN0zdMJx127OuR1ZrNnmJgUj3hPk',
  authDomain: 'akstotal-v2.firebaseapp.com',
  databaseURL: 'https://akstotal-v2.firebaseio.com',
  projectId: 'akstotal-v2',
  storageBucket: 'akstotal-v2.appspot.com',
  messagingSenderId: '549087283432',
  appId: '1:549087283432:web:4b1d667d61b8a877f0a15d',
  measurementId: 'G-LLXQV5XPC3',
};
if (isDev) {
  // @ts-ignore
  // window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
const app = firebase.initializeApp(config);
const firestore = app.firestore();
const functions = app.functions(Region.EU_WEST_3);
const storage = app.storage();
if (isDev) {
  functions.useEmulator('127.0.0.1', 5001);
  app.auth().useEmulator('http://127.0.0.1:9099');
  app.firestore().useEmulator('127.0.0.1', 8080);
  app.storage().useEmulator('127.0.0.1', 9199);
}
// initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LfXTPkqAAAAABq6K0mp_L2Nk07PQcjPZlMiNaOI'),
//   isTokenAutoRefreshEnabled: true,
// });

export const realtimedb = app.database('https://aks2tal-sensors.europe-west1.firebasedatabase.app');

app.storage().ref().constructor.prototype.putFiles = function (
  files: any,
  orgId: string,
  contextId: string,
  fileContext: any,
  subContextId: string
) {
  console.log('UPLOAD:', orgId, contextId, fileContext, subContextId);
  var ref = this;
  return Promise.all(
    files.map(function (file: any) {
      const fileId = uuidV4();
      return ref.child(fileId).put(file, {
        customMetadata: { orgId, contextId, fileContext, subContextId },
      });
    })
  );
};

export const reduxSagaFirebase = new ReduxSagaFirebase(app);

export default app;
export { firestore, functions, storage };
