import React from 'react';
import { useSelector } from 'react-redux';
import { Roles } from '../modules/orgs/interfaces';
import { AppState } from '../store';

export function AdminRoleOnly({ orgId, children }: any) {
  const id = useSelector((state: AppState) => state.orgs.currentOrg?.id);
  const uid = useSelector((state: AppState) => state.user.details.uid);
  // @ts-ignore
  const org = useSelector((state: AppState) => state.orgs.orgMap?.[id || orgId]);
  const member = org?.members?.[uid];
  if (member?.role === Roles.ADMIN) {
    return children;
  }
  return null;
}

const admins = [
  'APjQ0ab0pPZFCiRvcWu1TGwoKBE2',
  'FKvVHjpDZwyyO40GoTAZ2s1DvjCk',
  '4BESrD32e6MhNat2TolxXFFH3il1',
  '6Wn1HX0MFTmBxEN6I1HwdtEMSCe8', // Test account
];
const michael = 'pLJE7dQkMTYMoB7mRvPx70utopV2';
const henning = 'S7S6HNQpw9SWprZfQ3pUSWNPp4A3';
const charlotte = '7k7PPZBo6aRsvUhMkCRYwE89CGI3';
const allanM = 'xCoOswPjxUMmV3GkQqE6qVSrT5f2';
const nielsTest = 'O3hQgV5PMlVx3Xlk36o3ITRyNOZl';
const hans = 'PjlAiulngohn04OFyJkFbY9a0Dz1';

export function isAks2talOnly(userId: string) {
  return [...admins, michael, henning, charlotte, allanM, nielsTest].includes(userId);
}
export function Aks2talOnly({ children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if (isAks2talOnly(uid)) {
    return children;
  }
  return null;
}

export default function AdminOnly({ children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if (admins.includes(uid)) {
    return children;
  }
  return null;
}

export function AdminAndMichael({ children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if ([...admins, michael].includes(uid)) {
    return children;
  }
  return null;
}
export function AdminAndCharlotte({ children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if ([...admins, charlotte].includes(uid)) {
    return children;
  }
  return null;
}
export function AdminAndMichaelOrCurrentUser({ userId, children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if (uid === userId) {
    return children;
  }
  if ([...admins, michael].includes(uid)) {
    return children;
  }
  return null;
}

export function AdminAndMichaelAndHenning({ children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if ([...admins, michael, henning].includes(uid)) {
    return children;
  }
  return null;
}
export function AdminHenningHans({ children }: any) {
  const uid = useSelector((state: AppState) => state.user.details.uid);
  if ([...admins, hans, henning].includes(uid)) {
    return children;
  }
  return null;
}

export function isAdmin(uid: string) {
  return admins.includes(uid);
}
export function isAdminAndMichael(uid: string) {
  return [...admins, michael].includes(uid);
}

export function useCanChangeNotifications(userId: string) {
  const members = useSelector((state: AppState) => state.orgs.currentOrg?.members);
  const uid = useSelector((state: AppState) => state.user.details.uid);
  console.log({ members });
  // const org = useSelector((state: AppState) => state.orgs.orgMap?.[id]);
  const member = members?.[uid];
  if (member?.role === Roles.ADMIN) {
    return true;
  }
  if (member?.role === Roles.SALES) {
    return true;
  }
  if (member?.role === Roles.DEPARTMENT_EXECUTIVE) {
    return true;
  }
  if (uid === userId) {
    return true;
  }
  if (isAdmin(uid)) {
    return true;
  }
  return false;
}
export function useCanChangeMemberSettings(userId: string) {
  const members = useSelector((state: AppState) => state.orgs.currentOrg?.members);
  const uid = useSelector((state: AppState) => state.user.details.uid);
  console.log({ members });
  // const org = useSelector((state: AppState) => state.orgs.orgMap?.[id]);
  const member = members?.[uid];
  if (member?.role === Roles.ADMIN) {
    return true;
  }
  if (member?.role === Roles.DEPARTMENT_EXECUTIVE) {
    return true;
  }
  if (uid === userId) {
    return true;
  }
  if (isAdmin(uid)) {
    return true;
  }
  return false;
}
