import { EventApi } from '@fullcalendar/core';
import { isAfter, isBefore, subDays } from 'date-fns';
import { AuditSchedule } from 'modules/auditSchedule/types';

function getTaskStatus(item: AuditSchedule) {
  const isDelayed = item.dueDate.toDate() < new Date();
  const startDate = subDays(item.dueDate.toDate(), item.startDaysBeforeDueDate || 0);
  if (item.isCompleted && item.hasOnHoldTasks) {
    return 'warning';
  }
  if (item.isCompleted && item.needsResolution) {
    return 'hardWarning';
  }
  if (item.isCompleted && item.state === 'VOIDED') {
    return 'voided';
  }
  if (item.isCompleted) {
    return 'success';
  }
  if (isDelayed) {
    return 'error';
  }

  if (isAfter(new Date(), startDate) && isBefore(new Date(), item.dueDate.toDate())) {
    return 'inprogress';
  }
  return 'processing';
}

export function convertAuditScheduleToEventApi(
  auditSchedule: AuditSchedule[] = []
): Partial<EventApi>[] {
  return auditSchedule.map((schedule) => {
    const status = getTaskStatus(schedule);
    return {
      id: schedule.id,
      title: schedule.templateTitle,
      start: schedule.startDaysBeforeDueDate
        ? subDays(schedule.dueDate.toDate(), schedule.startDaysBeforeDueDate)
        : schedule.dueDate.toDate(),
      end: schedule.dueDate.toDate(),
      allDay: false,
      classNames: ['audit-event'],
      // status,
      backgroundColor: status, // Sets background color
      borderColor: 'transparent', // Sets border color
      color: 'red', // Sets text color
      // classNames: ["bg-red-500"],
    };
  });
}
