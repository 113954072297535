import { HourLogsActionTypes, HourLogState } from './types';
import { HOURLOGS_FETCH_REQUESTED, HOURLOGS_FETCH_SUCCEEDED, HOURLOGS_FETCH_FAILED } from './types';

const initialState: HourLogState = {
  logs: [],
  loading: false,
};

export default function hourLogs(state = initialState, action: HourLogsActionTypes): HourLogState {
  switch (action.type) {
    case HOURLOGS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case HOURLOGS_FETCH_SUCCEEDED:
      return {
        ...state,
        logs: action.payload.data,
        loading: false,
      };
    case HOURLOGS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
