import { FilesActionTypes, FilesState } from './types';
import {
  FILES_FETCH_REQUESTED,
  FILES_FETCH_SUCCEEDED,
  FILES_FETCH_FAILED,
  AUDIT_FILES_FETCH_REQUESTED,
  AUDIT_FILES_FETCH_SUCCEEDED,
  AUDIT_FILES_FETCH_FAILED,
} from './types';

const initialState: FilesState = {
  files: [],
  loading: false,
};

export default function user(state = initialState, action: FilesActionTypes) {
  switch (action.type) {
    case FILES_FETCH_REQUESTED:
    case AUDIT_FILES_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case FILES_FETCH_SUCCEEDED:
    case AUDIT_FILES_FETCH_SUCCEEDED:
      return {
        ...state,
        files: action.payload.data,
        loading: false,
      };
    case FILES_FETCH_FAILED:
    case AUDIT_FILES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
