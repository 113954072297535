import {
  AUDIT_TEMPLATES_FETCH_REQUESTED,
  AUDIT_TEMPLATES_FETCH_SUCCEEDED,
  AUDIT_TEMPLATES_FETCH_FAILED,
  AUDIT_TEMPLATE_FETCH_REQUESTED,
  AUDIT_TEMPLATE_FETCH_SUCCEEDED,
  AUDIT_TEMPLATE_FETCH_FAILED,
  // SET_CURRENT_TAB,
  AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED,
  AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED,
  AuditTemplateActionTypes,
  AuditTemplateState,
  AUDIT_TEMPLATE_SYNC_SUCCEEDED,
  AUDIT_TEMPLATE_CATEGORIES,
} from './types';

const initialState: AuditTemplateState = {
  auditTemplates: [],
  currentAuditTemplate: {
    id: '',
    title: '',
    note: '',
    guide: null,
    createTime: new Date(),
    // thumbs: [],
    // tags: [],
  },
  currentAuditTemplateTasks: [],
  auditTemplateSync: null,
  loading: false,
  auditTemplateCategoriesMap: {},
  // currentTab: "incompleted",
};

export default function auditTemplate(
  state = initialState,
  action: AuditTemplateActionTypes
): AuditTemplateState {
  switch (action.type) {
    case AUDIT_TEMPLATES_FETCH_REQUESTED:
    case AUDIT_TEMPLATE_TASKS_FETCH_REQUESTED:
    case AUDIT_TEMPLATE_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case AUDIT_TEMPLATES_FETCH_SUCCEEDED:
      return {
        ...state,
        auditTemplates: action.payload.data,
        loading: false,
      };
    case AUDIT_TEMPLATES_FETCH_FAILED:
    case AUDIT_TEMPLATE_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    case AUDIT_TEMPLATE_FETCH_SUCCEEDED:
      return {
        ...state,
        currentAuditTemplate: action.payload.data,
        loading: false,
      };
    case AUDIT_TEMPLATE_TASKS_FETCH_SUCCEEDED:
      return {
        ...state,
        currentAuditTemplateTasks: action.payload.data,
      };
    case AUDIT_TEMPLATE_SYNC_SUCCEEDED:
      return {
        ...state,
        auditTemplateSync: action.payload,
      };
    case AUDIT_TEMPLATE_CATEGORIES:
      return {
        ...state,
        auditTemplateCategoriesMap: action.payload,
      };
    default:
      return state;
  }
}
