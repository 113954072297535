import { useState } from 'react';
import { Layout, Row, Col, DatePicker, Button, Flex } from 'antd';
import moment from 'moment';
import HeaderBar from '../../../Layout/HeaderBar';
import SideNav from '../../../Layout/SideNav';
import Loader from '../../../components/Loader';
import GoogleMap from '../../../components/GoogleMap';
import AdminOnly from '../../../components/AdminOnly';
import dayjs, { Dayjs } from 'dayjs';
import TripCard from './TripCard';
import { Link } from 'react-router-dom';
import { LinkOutlined } from '@ant-design/icons';

const { Content } = Layout;

function DateFooter() {
  return (
    <div
      style={{
        lineHeight: 1.5,
        fontSize: 12,
        color: 'grey',
        paddingTop: 4,
        paddingBottom: 4,
      }}
    >
      Vi viser de seneste 100 dage med målinger, for at se flere kontakt os.
    </div>
  );
}

interface Props {
  title: string;
  data: any[];
  centerMap: { lat: number; lng: number };
  loading: boolean;
  selectedDate: Date;
  onDateChange: (date: Dayjs | null, dateString: string) => void;
  measures: string[];
  boundary?: { north: number; south: number; east: number; west: number };
  trips: any[];
}

function SensorDetails({
  title,
  data,
  centerMap,
  loading,
  selectedDate,
  onDateChange,
  measures,
  trips,
}: // boundary,
Props) {
  const [toggleGrid, setToggleGrid] = useState(false);
  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '24px 24px 0' }}>
          <Row gutter={16}>
            <Col md={24} xs={24} style={{}}>
              <Flex justify="space-between" align="center">
                <h1>{title}</h1>
                <Flex vertical align="end" gap="middle">
                  <a href={`https://wiki.teltonika-gps.com/view/FMT100_LED_status`} target="_blank">
                    <LinkOutlined /> Sensor information
                  </a>
                </Flex>
              </Flex>
            </Col>
            <Col md={14} xs={24} style={{ minHeight: '600px' }}>
              <div style={{ paddingBottom: 16 }}>
                <DatePicker
                  defaultValue={dayjs(selectedDate)}
                  onChange={onDateChange}
                  allowClear={false}
                  disabled={loading}
                  cellRender={(current) => {
                    // casting to Dayjs if number
                    const cDate = typeof current === 'number' ? dayjs(current) : current;

                    // TODO: add measure data here
                    const hasMeasurements = measures.includes(cDate.format('YYYY-MM-DD'));
                    const style = hasMeasurements
                      ? {
                          border: '1px solid #1890ff',
                          borderRadius: '50%',
                        }
                      : {};

                    return (
                      <div className="ant-picker-cell-inner" style={style}>
                        {cDate.date()}
                      </div>
                    );
                  }}
                  renderExtraFooter={() => <DateFooter />}
                />
              </div>
              {/* // @ts-ignore */}
              {/* {data[0] &&
                                `Køre tid: ${moment
                                    .utc(data[0].logTime.toDate())
                                    .add(2, "hours")
                                    .format("HH:mm:ss")}`}
                            {data[1] &&
                                ` - ${moment
                                    .utc(data[data.length - 1].logTime.toDate())
                                    .add(2, "hours")
                                    .format("HH:mm:ss")}`} */}
              {loading ? (
                <Loader />
              ) : (
                <>
                  {/* <AdminOnly>
                                        <div style={{}}>
                                            <Button
                                                onClick={() =>
                                                    setToggleGrid(!toggleGrid)
                                                }>
                                                Toggle grid
                                            </Button>
                                        </div>
                                    </AdminOnly> */}
                  {data.length > 0 && (
                    // @ts-ignore
                    <GoogleMap
                      data={data}
                      centerMap={centerMap}
                      selectedDate={selectedDate}
                      toggleGrid={toggleGrid}
                      // boundary={boundary}
                    />
                  )}
                </>
              )}
              {!loading && data.length === 0 && <div>Ingen data</div>}
            </Col>
            <Col md={10}>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div style={{ paddingLeft: 8, paddingTop: 46 }}>
                    <TripCard trips={trips} />
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

export default SensorDetails;
