import React, { useEffect } from 'react';
import { Form, Button, Select, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import { useAppSelector } from '../../../hooks';
import { Product } from '../../../modules/products/types';
import Message, { Type } from '../../../components/Notification/Message';
import { OrderStatus } from '../../../modules/orders/interfaces';
import { Unit } from '../../../components/SelectUnit';

const FormItem = Form.Item;
const Option = Select.Option;

const formItemLayout = {
  wrapperCol: { span: 24 },
};

function AddGroupOrderForm(props: { groupId: string }) {
  console.log({ props });
  const userId = useAppSelector((state) => state.user.details.uid);
  const products = useAppSelector((state) =>
    Object.keys(state.groups.groupsMap[props.groupId].storage).map(
      (productId) => state.products.productsMap[productId]
    )
  );

  console.log({ products });

  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => {
    // form.validateFields();
  }, []);
  // To disabled submit button at the beginning.

  const handleSubmit = async (values: any) => {
    try {
      console.log({ values });

      await firebase.firestore().collection('orders').add({
        groupId: props.groupId,
        productId: values.productId,
        userId,
        amount: values.amount,
        createTime: new Date(),
        status: OrderStatus.awaiting,
      });

      form.resetFields();
      return Message({
        key: 'addGroupOrder',
        message: 'Bestilling tilføjet',
        type: Type.SUCCESS,
      });
    } catch (error) {
      console.error(error);
      return Message({
        key: 'addGroupOrder',
        message: 'Noget gik galt prøv venligst igen',
        type: Type.ERROR,
      });
    }
    // props.addOrder(values);
  };

  return (
    <Form key="addOrgOrderForm" form={form} layout="inline" onFinish={handleSubmit}>
      <FormItem
        {...formItemLayout}
        name="amount"
        rules={[{ required: true, message: 'Please enter an amount!' }]}
      >
        <InputNumber placeholder={t('orders.amount_placeholder', { unit: Unit.LITER })} min={0} />
      </FormItem>
      <FormItem
        {...formItemLayout}
        style={{ minWidth: '150px' }}
        name="productId"
        rules={[{ required: true, message: 'Please select a product!' }]}
      >
        <Select placeholder={t('orders.product_placeholder')} style={{ width: 200 }}>
          {products.map((product: Product) => (
            <Option key={product.id} value={product.id}>
              {product.title}
            </Option>
          ))}
        </Select>
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit">
          {t('orders.submit')}
        </Button>
      </FormItem>
    </Form>
  );
}

export default AddGroupOrderForm;
