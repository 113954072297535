import { Avatar, Card, Divider, List, Tag, Tooltip, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useAppSelector } from '../../hooks';
import { Responsible } from './IssueDetail';
import placerholderImage from '../../assets/images/placeholder-video.png';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../Layout/Colors';
import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import NoImage from 'assets/icons/NoImage';
import { StyleGuide } from 'styles/StyleGuide';

export const responsibleMap = {
  external: 'Leverandør',
  internal: 'Interne',
};

interface BaseProps {
  id: string;
  image: { thumbnail: string };
  description: string;
  responsible: Responsible;
  tags: string[];
}
interface Props extends BaseProps {
  createTime: { toDate: () => Date };
  hasAssignee: boolean;
  assignee?: any;
  files?: any[];
}

export function IssueItem({
  id,
  // image,
  createTime,
  description,
  hasAssignee,
  assignee,
  files,
  // @ts-ignore
  orgId,
  // completeTime,
  responsible,
  tags,
}: Props) {
  console.log({ assignee, responsible });
  const history = useHistory();
  const currentOrgId = useAppSelector((state) => orgId || state.orgs.currentOrg.id);
  const { t } = useTranslation();
  const intl = useAppSelector((state) => state.user.userDoc.intl);
  const [image, setImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  // const assignee = useAppSelector(state => state.orgs.currentOrg?.members);
  const imageStyle = {
    height: '80px',
    width: '80px',
    background: `url(${image || placerholderImage}) no-repeat center center`,
    backgroundSize: 'cover',
  };
  console.log({ files });
  useEffect(() => {
    if (!files || files.length <= 0) return;

    setLoadingImage(true);
    firebase
      .storage()
      // @ts-ignore
      .ref(`orgs/${currentOrgId}/issues/thumb_${files[0]}`)
      // .child("images/stars.jpg")
      .getDownloadURL()
      .then((url) => {
        console.log({ url });
        setImage(url);
      })
      .catch((error) => {
        // Handle any errors
        console.warn(error);
        if (error?.code === 'storage/object-not-found') {
          console.log('ERRRO2');
          firebase
            .storage()
            // @ts-ignore
            .ref(`thumb_${files[0]}`)
            // .child("images/stars.jpg")
            .getDownloadURL()
            .then((url) => {
              console.log({ url });
              setImage(url);
            })
            .catch((error) => {
              // Handle any errors
              console.warn(error);
            });
        }
      })
      .finally(() => setLoadingImage(false));
  }, [id, currentOrgId]);

  return (
    <Card
      onClick={() => history.push(`/orgs/${currentOrgId}/issues/${id}`)}
      style={{ borderLeft: hasAssignee ? `5px solid ${Colors.warning}` : 'none' }}
      bodyStyle={{
        padding: '4px 4px',
        paddingLeft: hasAssignee ? 8 - 5 : 8,
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {image ? (
            <Avatar shape="square" size={64} src={image} />
          ) : (
            <Avatar
              shape="square"
              size={64}
              style={{
                backgroundColor: StyleGuide.palette.grey200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              icon={<NoImage color={StyleGuide.palette.grey500} />}
            />
          )}
          <div style={{ paddingLeft: 8 }}>
            <div>
              <Typography.Text strong>{description}</Typography.Text>
            </div>

            <Typography.Text type="secondary">
              <div
                style={{
                  whiteSpace: 'pre-line',
                  // fontSize: 14,
                  // lineHeight: 1.4,
                }}
              >
                {`${t('issues.overview.errortitle', {
                  relativeTime: createTime?.toDate?.(),
                  dayOfWeek: createTime?.toDate?.(),
                  dayOfMonth: createTime?.toDate?.(),
                  month: createTime?.toDate?.(),
                  time: createTime?.toDate?.(),
                })}`}
                {responsible && (
                  <span>
                    {' '}
                    - ({t('issues.overview.solvedby')}: {responsibleMap[responsible]})
                  </span>
                )}
              </div>
            </Typography.Text>
          </div>
        </div>
        <div>
          <RenderTags tags={tags} />
          {tags?.length > 0 && <Divider type="vertical" />}
          {assignee && (
            <Tooltip title={`Tildelt: ${assignee?.name}`}>
              <Avatar style={{ textTransform: 'capitalize' }} size={32}>
                {assignee?.name?.charAt?.(0)}
              </Avatar>
            </Tooltip>
          )}
          {assignee && <Divider type="vertical" />}
          <Link to={`/orgs/${currentOrgId}/issues/${id}`}>{t('issues.overview.errordetail')}</Link>
        </div>
      </div>
    </Card>
  );

  // return (
  //     <List.Item
  //         style={{
  //             height: "80px",
  //             backgroundColor: "#fff",
  //             padding: 0,
  //             borderRight: hasAssignee ? `5px solid ${Colors.warning}` : "none",
  //         }}
  //         actions={[
  //             assignee && (
  //                 <Tooltip title={`Tildelt: ${assignee?.name}`}>
  //                     <Avatar style={{ textTransform: "capitalize" }}>
  //                         {assignee?.name?.charAt?.(0)}
  //                     </Avatar>
  //                 </Tooltip>
  //             ),
  //             <Link to={`/orgs/${currentOrgId}/issues/${id}`}>
  //                 {t("issues.overview.errordetail")}
  //             </Link>,
  //         ]}>
  //         <List.Item.Meta
  //             style={{ alignItems: "center" }}
  //             avatar={<div style={imageStyle} />}
  //             title={description}
  //             description={
  //                 <span>
  //                     {`${t("issues.overview.errortitle", {
  //                         date: createTime?.toDate?.(),
  //                     })}`}
  //                     {responsible && (
  //                         <span>
  //                             {" "}
  //                             - ({t("issues.overview.solvedby")}:{" "}
  //                             {responsibleMap[responsible]})
  //                         </span>
  //                     )}
  //                 </span>
  //             }
  //         />
  //     </List.Item>
  // );
}
export function RenderTags({ tags = [] }: { tags: string[] }) {
  const [tag1, tag2, ...rest] = tags;

  const moreTags = rest.length > 0 && (
    <Tooltip
      title={rest.map((tag) => (
        <div>
          <Tag key={tag} color="blue">
            {tag}
          </Tag>
        </div>
      ))}
    >
      <Tag key={'more'} color="blue">
        {rest.length}+
      </Tag>
    </Tooltip>
  );

  const display = [tag1, tag2].filter(Boolean).map((tag) => {
    return (
      <Tooltip title={tag}>
        <Tag key={tag} color="blue">
          {tag.length > 10 ? `${tag.substring(0, 8)}...` : tag}
        </Tag>
      </Tooltip>
    );
  });
  return (
    <>
      {display}
      {moreTags}
    </>
  );
}
interface CompleteProps extends BaseProps {
  completeTime: { toDate: () => Date };
  assignee?: any;
  files?: any[];
}
export function IssueItemCompleted({
  id,
  files,
  description,
  completeTime,
  responsible,
  assignee,
  tags,
}: CompleteProps) {
  const history = useHistory();
  const orgId = useAppSelector((state) => state.orgs.currentOrg.id);
  const { t } = useTranslation();
  const intl = useAppSelector((state) => state.user.userDoc.intl);
  const [image, setImage] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const imageStyle = {
    borderLeft: '5px solid #00D86D',
    height: '80px',
    width: '80px',
    background: `url(${image || placerholderImage}) no-repeat center center`,
    backgroundSize: 'cover',
  };
  useEffect(() => {
    if (files && files?.length <= 0) return;

    setLoadingImage(true);
    firebase
      .storage()
      // @ts-ignore
      .ref(`orgs/${orgId}/issues/thumb_${files[0]}`)
      // .child("images/stars.jpg")
      .getDownloadURL()
      .then((url) => {
        console.log({ url });
        setImage(url);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
        if (error?.code === 'storage/object-not-found') {
          console.log('ERRRO2');
          firebase
            .storage()
            // @ts-ignore
            .ref(`thumb_${files[0]}`)
            // .child("images/stars.jpg")
            .getDownloadURL()
            .then((url) => {
              console.log({ url });
              setImage(url);
            })
            .catch((error) => {
              // Handle any errors
              console.error(error);
            });
        }
      })
      .finally(() => setLoadingImage(false));
  }, [id, orgId]);
  return (
    <Card
      onClick={() => history.push(`/orgs/${orgId}/issues/${id}`)}
      style={{ borderLeft: completeTime ? `5px solid ${Colors.done}` : 'none' }}
      bodyStyle={{ padding: 4, cursor: 'pointer' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar shape="square" size={64} src={image || placerholderImage} />
          <div style={{ paddingLeft: 8 }}>
            <div>
              <Typography.Text strong>{description}</Typography.Text>
            </div>

            <Typography.Text type="secondary">
              <span>
                {`${t('issues.overview.fixedtitle', {
                  date: completeTime?.toDate?.(),
                })}`}
                {responsible && (
                  <span>
                    {' '}
                    - ({t('issues.overview.solvedby')}: {responsibleMap[responsible]})
                  </span>
                )}
              </span>
            </Typography.Text>
          </div>
        </div>
        <div>
          <RenderTags tags={tags} />
          {tags?.length > 0 && <Divider type="vertical" />}
          {assignee && (
            <Tooltip title={`Tildelt: ${assignee?.name}`}>
              <Avatar style={{ textTransform: 'capitalize' }} size={32}>
                {assignee?.name?.charAt?.(0)}
              </Avatar>
            </Tooltip>
          )}
          {assignee && <Divider type="vertical" />}
          <Link to={`/orgs/${orgId}/issues/${id}`}>{t('issues.overview.errordetail')}</Link>
        </div>
      </div>
    </Card>
  );
  // return (
  //     <List.Item
  //         style={{
  //             height: "80px",
  //             backgroundColor: "#fff",
  //             padding: 0,
  //         }}
  //         actions={[
  //             assignee && (
  //                 <Tooltip title={`Tildelt: ${assignee?.name}`}>
  //                     <Avatar style={{ textTransform: "capitalize" }}>
  //                         {assignee?.name?.charAt?.(0)}
  //                     </Avatar>
  //                 </Tooltip>
  //             ),
  //             <Link to={`/orgs/${orgId}/issues/${id}`}>
  //                 {t("issues.overview.errordetail")}
  //             </Link>,
  //         ]}>
  //         <List.Item.Meta
  //             style={{ alignItems: "center" }}
  //             avatar={<div style={imageStyle} />}
  //             title={description}
  //             description={
  //                 <span>
  //                     {`${t("issues.overview.fixedtitle", {
  //                         date: completeTime?.toDate?.(),
  //                     })}`}
  //                     {responsible && (
  //                         <span>
  //                             {" "}
  //                             - ({t("issues.overview.solvedby")}:{" "}
  //                             {responsibleMap[responsible]})
  //                         </span>
  //                     )}
  //                 </span>
  //             }
  //         />
  //     </List.Item>
  // );
}
