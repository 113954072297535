import { call, put, takeLatest } from 'redux-saga/effects';
import keyBy from 'lodash.keyby';
import firebase, { reduxSagaFirebase } from '../../firebase';
import {
  fetchAuditSchedulesSuccess,
  fetchAuditScheduleSuccess,
  fetchCompleteAuditSchedulesSuccess,
  fetchInCompleteAuditSchedulesSuccess,
  fetchOverdueAuditSchedulesSuccess,
} from './actions';
import {
  AUDIT_SCHEDULES_FETCH_FAILED,
  AUDIT_SCHEDULES_FETCH_REQUESTED,
  AUDIT_SCHEDULE_FETCH_FAILED,
  AUDIT_SCHEDULE_FETCH_REQUESTED,
  AUDIT_SCHEDULE_UPDATE_FAILED,
  AUDIT_SCHEDULE_UPDATE_REQUESTED,
  AUDIT_SCHEDULE_TASK_UPDATE_FAILED,
  AUDIT_SCHEDULE_TASK_UPDATE_REQUESTED,
  AUDIT_SCHEDULE_ADD_SUCCEEDED,
  AUDIT_SCHEDULE_ADD_FAILED,
  AUDIT_SCHEDULE_ADD_REQUESTED,
  AUDIT_SCHEDULE_DELETE_SUCCEEDED,
  AUDIT_SCHEDULE_DELETE_FAILED,
  AUDIT_SCHEDULE_DELETE_REQUESTED,
  INCOMPLETE_AUDIT_SCHEDULES_FETCH_FAILED,
  INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  COMPLETE_AUDIT_SCHEDULES_FETCH_FAILED,
  COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  AuditSchedule,
  AuditSourceType,
  AuditScheduleTask,
} from './types';
import { addAuditSchedules } from './api';
// import { RecurringOptions } from "../../../functions/src/enums";
import { message } from 'antd';
import Message, { Type } from '../../components/Notification/Message';
import { addWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { AuditGuide } from 'modules/auditTemplates/types';
import { getFirstExtendDate } from 'Views/AuditSchedule/scheduleHelpers';
import { AuditTask } from 'hooks/useAuditTasks';
import { QUICK_TASK } from 'general/enums';
import uuidV4 from 'uuid/v4';
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchAuditSchedules({ payload }: any) {
  const { orgId, fromDate, toDate } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/auditSchedules`)
        .where('dueDate', '>=', fromDate)
        .where('dueDate', '<=', toDate)
        .orderBy('dueDate', 'asc'),
      { successActionCreator: fetchAuditSchedulesSuccess }
    );
  } catch (e: any) {
    yield put({ type: AUDIT_SCHEDULES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchAuditSchedulesSaga() {
  yield takeLatest(AUDIT_SCHEDULES_FETCH_REQUESTED, fetchAuditSchedules);
}
function* fetchInCompleteAuditSchedules({ payload }: any) {
  const { orgId } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/auditSchedules`)
        .where('isCompleted', '==', false)
        .where('dueDate', '>', new Date())
        .where('dueDate', '>=', startOfWeek(new Date()))
        .where('dueDate', '<=', endOfWeek(addWeeks(new Date(), 1)))
        .orderBy('dueDate', 'asc')
        .limit(20),
      { successActionCreator: fetchInCompleteAuditSchedulesSuccess }
    );
  } catch (e: any) {
    yield put({ type: INCOMPLETE_AUDIT_SCHEDULES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchInCompleteAuditSchedulesSaga() {
  yield takeLatest(INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED, fetchInCompleteAuditSchedules);
}
function* fetchOverdueAuditSchedules({ payload }: any) {
  const { orgId } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/auditSchedules`)
        .where('isCompleted', '==', false)
        .where('dueDate', '<', new Date())
        .orderBy('dueDate', 'asc')
        .limit(20),
      { successActionCreator: fetchOverdueAuditSchedulesSuccess }
    );
  } catch (e: any) {
    yield put({ type: INCOMPLETE_AUDIT_SCHEDULES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchOverdueAuditSchedulesSaga() {
  yield takeLatest(OVERDUE_AUDIT_SCHEDULES_FETCH_REQUESTED, fetchOverdueAuditSchedules);
}
function* fetchCompleteAuditSchedules({ payload }: any) {
  const { orgId, limit } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection(`orgs/${orgId}/auditSchedules`)
        .where('isCompleted', '==', true)
        .orderBy('dueDate', 'desc')
        .limit(limit),
      { successActionCreator: fetchCompleteAuditSchedulesSuccess }
    );
  } catch (e: any) {
    yield put({ type: COMPLETE_AUDIT_SCHEDULES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchCompleteAuditSchedulesSaga() {
  yield takeLatest(COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED, fetchCompleteAuditSchedules);
}

// FETCH ISSUE
function* fetchAuditSchedule({ payload }: any) {
  const { orgId, auditScheduleId } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncDocument,
      `orgs/${orgId}/auditSchedules/${auditScheduleId}`,
      { successActionCreator: fetchAuditScheduleSuccess }
    );
  } catch (e: any) {
    yield put({ type: AUDIT_SCHEDULE_FETCH_FAILED, message: e.message });
  }
}

export function* fetchAuditScheduleSaga() {
  yield takeLatest(AUDIT_SCHEDULE_FETCH_REQUESTED, fetchAuditSchedule);
}
function* updateAuditScheduleTask({ payload }: any) {
  const { orgId, auditScheduleId, tasks } = payload;
  const messageKey = 'updateAuditTask';
  try {
    Message({
      type: Type.LOADING,
      key: messageKey,
      message: `Loading...`,
      // description: `${payload.title} blev oprettet og kan nu søges frem i listen her`,
    });
    yield call(
      reduxSagaFirebase.firestore.setDocument,
      `orgs/${orgId}/auditSchedules/${auditScheduleId}`,
      { tasks },
      { merge: true }
    );
    Message({
      key: messageKey,
      message: `Alt er gemt`,
    });
  } catch (e: any) {
    yield put({ type: AUDIT_SCHEDULE_TASK_UPDATE_FAILED, message: e.message });
    Message({
      key: messageKey,
      type: Type.ERROR,
      message: `Der skete en fejl`,
      description: `data blev ikke gemt, prøv venligst igen`,
    });
  }
}

export function* updateAuditScheduleTaskSaga() {
  yield takeLatest(AUDIT_SCHEDULE_TASK_UPDATE_REQUESTED, updateAuditScheduleTask);
}

function* updateAuditSchedule({ payload }: any) {
  const { orgId, auditScheduleId, data } = payload;
  try {
    Message({
      key: 'updateAudit',
      message: 'Loading...',
      // description: "Sensoren blev tilføjet korrekt",
      type: Type.LOADING,
    });
    yield call(
      reduxSagaFirebase.firestore.updateDocument,
      `orgs/${orgId}/auditSchedules/${auditScheduleId}`,
      { ...data }
    );
    if (data.isCompleted) {
      Message({
        key: 'updateAudit',
        message: 'Opgave afsluttet',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.SUCCESS,
      });
    } else {
      Message({
        key: 'updateAudit',
        message: 'Opgave afslutning annulleret',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.SUCCESS,
      });
    }
  } catch (e: any) {
    yield put({ type: AUDIT_SCHEDULE_UPDATE_FAILED, message: e.message });
  }
}

export function* updateAuditScheduleSaga() {
  yield takeLatest(AUDIT_SCHEDULE_UPDATE_REQUESTED, updateAuditSchedule);
}

interface IAddPayload {
  payload: {
    groupId?: string; // used if a group audit template is used
    orgId: string;
    data: {
      // templateTitle: string;
      // templateNote: string;
      dueDate: Date;
      taskTitle?: string;
      // guide: AuditGuide | null;
      templateId: string;
      recurringOption: any; //RecurringOptions;
      startDaysBeforeDueDate?: number;
      notifyDaysAfterDueDate?: number;
      responsible: AuditSchedule['responsible'];
      auditSourceType?: AuditSchedule['auditSourceType'];
      createBy: { id: string; name: string };
      repeatRule?: string;
      // auditTemplateCategory: string | null;
    };
    auditId?: string;
  };
}

function* addAuditSchedule({ payload }: IAddPayload) {
  const { orgId, data, auditId, groupId } = payload;
  try {
    console.log({ data });

    const nextDueDate = auditId
      ? getFirstExtendDate(data.recurringOption, data.dueDate)
      : data.dueDate;
    message.loading('Opretter Audits');
    let tasks: AuditScheduleTask[];
    if (data.templateId === QUICK_TASK.OPTION) {
      tasks = [
        {
          id: uuidV4(),
          description: data.taskTitle || '', // IF QUICK TASK
          // @ts-ignore
          createTime: new Date(), // ignore that it thinks it is a firestore timestamp
          order: 1,
          customFields: [],
        },
      ];
    } else if (data.auditSourceType === AuditSourceType.GROUP_TEMPLATE) {
      // @ts-ignore
      const tasksQuery = yield call(
        reduxSagaFirebase.firestore.getCollection,
        `groups/${groupId}/groupAuditTemplates/${data.templateId}/tasks`
      );
      tasks = tasksQuery.docs.map((task: any) => {
        const taskData = task.data();
        return {
          id: task.id,
          description: taskData.description,
          createTime: taskData.createTime?.toDate() || null,
          order: taskData?.order ?? null,
          customFields: taskData.customFields || [],
        };
      });
    } else {
      // @ts-ignore
      const tasksQuery = yield call(
        reduxSagaFirebase.firestore.getCollection,
        `orgs/${orgId}/auditTemplates/${data.templateId}/tasks`
      );
      tasks = tasksQuery.docs.map((task: any) => {
        const taskData = task.data();
        return {
          id: task.id,
          description: taskData.description,
          createTime: taskData.createTime?.toDate() || null,
          order: taskData?.order ?? null,
          customFields: taskData.customFields || [],
        };
      });
    }

    const tasksByKey = keyBy(tasks, (task) => task.id);
    yield call(addAuditSchedules, {
      // templateTitle: data.templateTitle,
      // templateNote: data.templateNote,
      taskTitle: data.taskTitle,
      groupId,
      orgId,
      // guide: data.guide || null,
      dueDate: nextDueDate,
      templateId: data.templateId,
      recurringOption: data.recurringOption,
      tasks: tasksByKey,
      startDaysBeforeDueDate: data.startDaysBeforeDueDate,
      notifyDaysAfterDueDate: data.notifyDaysAfterDueDate,
      responsible: data.responsible,
      auditSourceType: data.auditSourceType,
      createBy: data.createBy,
      repeatRule: data.repeatRule,
      // auditTemplateCategory: data.auditTemplateCategory,
    });
    if (auditId) {
      // Set auditId to false
      yield call(
        reduxSagaFirebase.firestore.setDocument,
        `orgs/${orgId}/auditSchedules/${auditId}`,
        { lastRecurring: false },
        { merge: true }
      );
    }
    message.success('Audit(s) oprettet');
    yield put({ type: AUDIT_SCHEDULE_ADD_SUCCEEDED, payload: { auditId } });
  } catch (e: any) {
    message.error('Noget gik galt');
    yield put({ type: AUDIT_SCHEDULE_ADD_FAILED, message: e.message });
    console.error(e);
  }
}

export function* addAuditScheduleSaga() {
  // @ts-ignore
  yield takeLatest(AUDIT_SCHEDULE_ADD_REQUESTED, addAuditSchedule);
}

function* deleteAuditSchedule({ payload }: any) {
  const { orgId, auditScheduleId } = payload;
  try {
    yield call(
      reduxSagaFirebase.firestore.deleteDocument,
      `orgs/${orgId}/auditSchedules/${auditScheduleId}`
    );
    yield put({ type: AUDIT_SCHEDULE_DELETE_SUCCEEDED });
  } catch (e: any) {
    yield put({ type: AUDIT_SCHEDULE_DELETE_FAILED, message: e.message });
  }
}

export function* deleteAuditScheduleSaga() {
  // @ts-ignore
  yield takeLatest(AUDIT_SCHEDULE_DELETE_REQUESTED, deleteAuditSchedule);
}
