import React, { useState } from 'react';
import { Button } from 'antd';
import { useCanChangeNotifications } from '../../../components/AdminOnly';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { openOrgNotificationDrawer } from 'modules/drawer/actions';
import { useAppSelector } from 'hooks';
import { BellOutlined } from '@ant-design/icons';

interface Props {
  record: any;

  // onUpdateNotifications: (e: CheckboxChangeEvent) => void;
}

export default function NotificationSettings({ record }: Props) {
  const dispatch = useDispatch();
  const orgId = useAppSelector((state) => state.orgs.currentOrg.id);
  // const [visible, setvisible] = useState(false);
  const { t } = useTranslation();
  // const isDisabled = isAdminAndMichael(uid) ? false : uid !== record?.uid;
  const allowedToEditNotifications = useCanChangeNotifications(record?.uid);
  // const onVisibleChange = () => (isDisabled ? setvisible(false) : setvisible(!visible));
  // const notifications = [
  //     {
  //         label: t("access.notifications_missing_logs"),
  //         value: "sensorLogError",
  //     },
  //     {
  //         label: t("access.notifications_low_storage"),
  //         value: "storageAlert",
  //     },
  //     {
  //         label: t("access.notifications_issue_fixed"),
  //         value: "completedIssue",
  //     },
  //     {
  //         label: t("access.notifications_issue_created"),
  //         value: "newIssue",
  //     },
  //     {
  //         label: t("access.notifications_sensor_high"),
  //         value: "sensorLogPeak",
  //     },
  //     {
  //         label: t("access.notifications_clean_log_alert"),
  //         value: "cleanLogAlert",
  //     },
  //     {
  //         label: t("access.notifications_audit_deadline_alert"),
  //         value: "auditDeadline",
  //     },
  //     {
  //         label: t("access.notifications_gsm_plant_alert"),
  //         value: "gsmPlantAlert",
  //     },
  //     {
  //         label: t("access.notifications_gps_logs_alert"),
  //         value: "gpsLogsMissing",
  //     },
  // ];

  return (
    // <Popover
    //     placement="left"
    //     overlayStyle={{ maxWidth: 600 }}
    //     content={
    //         <Row>
    //             {notifications.map((n: any) => {
    //                 const checked = record?.notifications?.[n.value] === true;
    //                 return (
    //                     <Col span={12} key={n.value}>
    //                         <Checkbox
    //                             name={n.value}
    //                             checked={checked}
    //                             onChange={onUpdateNotifications}>
    //                             {n.label}
    //                         </Checkbox>
    //                     </Col>
    //                 );
    //             })}
    //             <AdminOnly>
    //                 <Divider />
    //                 {t("access.email_notifications_headline")}
    //                 <Col span={24}>
    //                     <EmailDigestNotifications userId={record.uid} />
    //                 </Col>
    //             </AdminOnly>
    //         </Row>
    //     }
    //     style={{ width: 500 }}
    //     title="Notifikationer"
    //     trigger="click"
    //     open={visible}
    //     onOpenChange={onVisibleChange}>
    <Button
      // type="primary"
      // ghost
      style={{ border: 'none' }}
      icon={<BellOutlined />}
      disabled={!allowedToEditNotifications}
      onClick={() => dispatch(openOrgNotificationDrawer({ orgId, userId: record?.uid }))}
    >
      {/* {t("access.notification_edit")} */}
    </Button>
    // </Popover>
  );
}
