import React, { useState, CSSProperties } from 'react';
import ReactPlayer from 'react-player';
import { Row, Col, Typography, Button, Checkbox } from 'antd';
import { Colors } from '../../../Layout/Colors';
import { CheckOutlined, CheckSquareOutlined, CheckCircleOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

export default function Step3({ next }: { next: () => void }) {
  const [startedVideo, setStartedVideo] = useState(false);
  return (
    <Row>
      <Col md={24}>
        <Title style={styles.body}>100% naturligt produkt</Title>
      </Col>
      <Col xs={24} md={12}>
        <div style={styles.wrapper}>
          {/* @ts-ignore */}
          <ReactPlayer
            // height={"70%"}
            onStart={() => {}} //setStartedVideo(true)}
            width={'100%'}
            style={styles.player}
            controls
            url="https://storage.googleapis.com/akstotal-v2.appspot.com/certification/find-datasheet.mp4"
            onEnded={() => setStartedVideo(true)}
          />
        </div>
        {/* <div style={styles.wrapper}>
                <ReactPlayer
                    width={"75%"}
                    url="https://www.youtube.com/watch?v=ZzCdu611SFg"
                    onEnded={() => alert("Ended")}
                /> */}
      </Col>
      <Col xs={24} md={12} style={{ paddingTop: 24 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
            paddingBottom: 24,
          }}
        >
          <div>
            <Title level={4} style={styles.body}>
              Se hvor du kan finde produkt databladet
            </Title>
            <Paragraph style={styles.body}>
              Se i videoen til venstre hvor du kan finde produktets datablad. Det vil altid være
              tilgængeligt i aks2tal app'en
              {/* Ved ikke om
                    der er plads, men det kunne være fint om bruger også kendte til de
                    gængse fordele. Fx. kun et produkt til alle vaskbare gulve, mindre
                    støv i luftveje og på overfalder osv. */}
            </Paragraph>
            <Title level={4} style={styles.body}>
              Produktet er ikke farligt
            </Title>
            <Paragraph style={styles.body}>
              <ul style={{ listStyleType: 'none' }}>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  aks støvbinder er et 100% biologisk nedbrydeligt produkt. og er PH-neutral
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  aks støvbinder er uden duft, farve og konserveringsmiddel.
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  aks støvbinder skummer ikke
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  aks støvbinder har en normal vaskeeffekt.
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  aks støvbinder har en høj plejeeffekt ikke mindst på "åbne" gulve som beton, træ
                  og linolium.
                </li>
                <li style={styles.listItem}>
                  <CheckCircleOutlined style={styles.icon} />
                  aks støvbinder er 100% vandopløseligt og uden mærkningspligt
                </li>
              </ul>
            </Paragraph>
          </div>
          <div>
            <Button type="primary" block onClick={next} disabled={!startedVideo}>
              {startedVideo ? 'Næste' : 'Se video for at forsætte'}
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

const styles: { [key: string]: CSSProperties } = {
  wrapper: {
    position: 'relative',
    padding: 24,
  },
  player: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'none',
  },
  body: {
    textAlign: 'left',
    paddingLeft: 24,
    paddingRight: 24,
  },
  listItem: { margin: 0, marginBottom: 16, display: 'flex', alignItems: 'center' },
  icon: {
    fontSize: 18,
    marginRight: 8,
    color: Colors.info,
  },
};
