import React, { FormEvent, useState } from 'react';
import { Layout, Row, Col, Button, Input, Breadcrumb } from 'antd';
import SideNav from '../../../Layout/SideNav';
import HeaderBar from '../../../Layout/HeaderBar';
import FooterBar from '../../../Layout/FooterBar';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import BlurOverlayContainer from '../../../components/Overlay/BlurOverlayContainer';
import { FeatureIds } from '../../../modules/featureControl/featuresConfig';
import { AuditTemplate } from '../../../modules/auditTemplates/types';
import AuditTemplateItem from './AuditTemplateItem';
import { useAppSelector } from 'hooks';
import { Link } from 'react-router-dom';
import AksBreadcrumbs from 'components/Breadcrumbs';

const { Content } = Layout;
interface Props {
  groupId: string;
  loading: boolean;
  auditTemplates: AuditTemplate[];
  addAuditTemplate: (title: string) => void;
}
function AuditTemplateList(props: Props) {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    props.addAuditTemplate(title);
    setTitle('');
  };

  const { groupId } = props;

  return (
    <>
      {/* <SideNav /> */}
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.AUDITS}>
          <Content style={{ margin: '50px 24px 0' }}>
            <Row gutter={16}>
              <Col md={24} xs={24}>
                <AksBreadcrumbs />
              </Col>
              <Col md={24} xs={24}>
                <h1>{t('audit.templates.headline')}</h1>

                {props.loading ? (
                  <LoadingOutlined />
                ) : (
                  props.auditTemplates.map((item) => (
                    <AuditTemplateItem
                      key={`${groupId}-${item.id}`}
                      groupId={groupId}
                      item={item}
                    />
                  ))
                )}
              </Col>
              <Col md={24} xs={24}>
                <form onSubmit={onSubmit} style={{ marginTop: '50px' }}>
                  <Input
                    size="large"
                    placeholder={t('audit.templates.addplaceholder')}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                  <div style={{ marginTop: 4 }} />
                  <Button size="large" type="primary" style={{ width: '100%' }} htmlType="submit">
                    {t('audit.templates.submit')}
                  </Button>
                </form>
              </Col>
            </Row>
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default AuditTemplateList;
