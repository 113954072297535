import React, { createContext, useContext, useReducer, ReactNode } from 'react';

export interface OfferDrawerState {
  offerId: string | null;
  orgId: string | null;
}

export type OfferDrawerAction =
  | {
      type: 'SET_OFFER_ID';
      payload: { offerId: string; orgId: string | null };
    }
  | { type: 'CLOSE_DRAWER' };

export const initialState: OfferDrawerState = {
  offerId: null,
  orgId: null,
};

export function offerDrawerReducer(
  state: OfferDrawerState,
  action: OfferDrawerAction
): OfferDrawerState {
  switch (action.type) {
    case 'SET_OFFER_ID':
      return {
        ...state,
        offerId: action.payload.offerId,
        orgId: action.payload.orgId,
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        offerId: null,
        orgId: null,
      };

    default:
      throw new Error('Unknown action type');
  }
}
