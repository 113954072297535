import { RequestState } from 'interfaces/request';
import {
  AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED,
  AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED,
  AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED,
  AuditActionTypes,
  AuditState,
  COMPLETE_AUDIT_LIMIT,
} from './types';
import {
  AUDIT_FETCH_REQUESTED,
  AUDIT_FETCH_SUCCEEDED,
  AUDIT_FETCH_FAILED,
  SET_CURRENT_TAB,
} from './types';

export const COMPLETE_AUDIT_LIMIT_NUMBER = 20;

const initialState: AuditState = {
  audits: [],
  currentAudit: {
    thumbs: [],
    tags: [],
  },
  loading: false,
  currentTab: 'incompleted',
  completeAuditLimit: COMPLETE_AUDIT_LIMIT_NUMBER,
  auditBaseCategoriesRequestState: RequestState.INITIAL,
  auditBaseCategories: { fields: {} },
};

export default function audits(state = initialState, action: AuditActionTypes): AuditState {
  switch (action.type) {
    case AUDIT_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case AUDIT_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    case AUDIT_FETCH_SUCCEEDED:
      return {
        ...state,
        currentAudit: action.payload.data,
        loading: false,
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload.key,
      };
    case COMPLETE_AUDIT_LIMIT:
      return {
        ...state,
        completeAuditLimit: action.payload.limit,
      };

    case AUDIT_BASE_CATEGORY_CONFIG_FETCH_REQUESTED:
      return {
        ...state,
        auditBaseCategoriesRequestState: RequestState.PENDING,
      };
    case AUDIT_BASE_CATEGORY_CONFIG_FETCH_SUCCEEDED:
      return {
        ...state,
        auditBaseCategories: action.payload,
        auditBaseCategoriesRequestState: RequestState.RESOLVED,
      };
    case AUDIT_BASE_CATEGORY_CONFIG_FETCH_FAILED:
      return {
        ...state,
        auditBaseCategoriesRequestState: RequestState.REJECTED,
      };
    default:
      return state;
  }
}
