import React, { Dispatch, useState, useRef, useMemo } from 'react';
import { Table, Input, Button, Avatar, Tooltip, Badge, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { Link, useLocation } from 'react-router-dom';
import { DeleteCompany } from './index';
import { SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { useTranslation } from 'react-i18next';
import {
  InventoryContextForType,
  InventoryType,
  InventoryStatus,
  InventoryStatusMap,
} from '../../../modules/inventory/types';
import { getDateString } from '../../../general/dates';
import id from 'date-fns/esm/locale/id/index.js';

const colors = ['#f56a00', '#87d068', '#1890ff', '#fde3cf', '#f56a00', '#87d068'];

function getColor(index: number) {
  const i = colors.length % index;
  console.log({ i });

  return colors[index];
}

interface IProps {
  data: InventoryContextForType<InventoryType.ESP_AQ>[];
  inventoryTagsMap: { [id: string]: { id: string; title: string } };
  setVisible: Dispatch<string>;
}

function TableList(props: IProps) {
  const intl = useSelector((state: AppState) => state.user.userDoc.intl);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const { t } = useTranslation();

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: (event: string | string[]) => void;
      selectedKeys: string[];
      confirm: () => void;
      clearFilters: () => void;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={t('home.table.searchPlaceholder')}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 200, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {t('home.table.search')}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t('home.table.searchReset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: string, record: any) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // @ts-ignore
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text: string, _: any) => {
      if (searchedColumn === dataIndex) {
        return (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        );
      }
      return text;
    },
  });

  const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    // this.setState({
    //     searchText: selectedKeys[0],
    //     searchedColumn: dataIndex,
    // });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const columns = [
    {
      title: t('Status'),
      dataIndex: 'isActive',
      key: 'isActive',
      width: '5%',
      sorter: (a: any, b: any) => a.isActive - b.isActive,
      render: (isActive: boolean, row: any) => {
        console.log({ isActive, row });

        if (isActive) {
          return (
            <Tooltip title={`Sensor er aktiv - sidst set ${row.lastSeen}`}>
              <Badge status="success" />
            </Tooltip>
          );
        }
        return (
          <Tooltip title={`Sensor er ikke aktiv - sidst set ${row.lastSeen}`}>
            <Badge status="error" />
          </Tooltip>
        );
      },
    },
    {
      title: t('inventory.ipad.serial'),
      dataIndex: 'deviceId',
      key: 'deviceId',
      ...getColumnSearchProps('deviceId'),
    },
    // {
    //     title: t("inventory.ipad.name"),
    //     dataIndex: "name",
    //     key: "name",
    //     width: "20%",
    //     ...getColumnSearchProps("name"),
    // },
    {
      title: t('inventory.ipad.sim'),
      dataIndex: 'sim',
      key: 'sim',
      width: '20%',
      render: (sim: string, row: any) => {
        if (row?.simcard?.iccid) {
          return (
            <Link to={`/inventory/simcards?iccid=${row.simcard.iccid}`}>{row.simcard.iccid}</Link>
          );
        }
        return sim;
      },
    },
    {
      title: t('inventory.ipad.org'),
      dataIndex: 'orgTitle',
      key: 'orgTitle',
      width: '20%',
      render: (orgTitle: string, row: any) => {
        return <Link to={`/orgs/${row.orgId}/sensors`}>{orgTitle}</Link>;
      },
    },
    {
      title: t('inventory.ipad.status'),
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      onFilter: (value: any, record: any) => record.status === value,
      filters: [
        {
          text: InventoryStatusMap[InventoryStatus.NEEDS_ORDER],
          value: InventoryStatus.NEEDS_ORDER,
        },
        {
          text: InventoryStatusMap[InventoryStatus.ORDERED],
          value: InventoryStatus.ORDERED,
        },
        {
          text: InventoryStatusMap[InventoryStatus.IN_STORAGE],
          value: InventoryStatus.IN_STORAGE,
        },
        {
          text: InventoryStatusMap[InventoryStatus.CONFIG],
          value: InventoryStatus.CONFIG,
        },
        {
          text: InventoryStatusMap[InventoryStatus.READY],
          value: InventoryStatus.READY,
        },
        {
          text: InventoryStatusMap[InventoryStatus.ASSIGNED],
          value: InventoryStatus.ASSIGNED,
        },
        {
          text: InventoryStatusMap[InventoryStatus.RETRIEVE],
          value: InventoryStatus.RETRIEVE,
        },
      ],
      render: (status: InventoryStatus) => {
        switch (status) {
          case InventoryStatus.NEEDS_ORDER:
            return <Tag color="red">{InventoryStatusMap[status]}</Tag>;

          case InventoryStatus.ORDERED:
            return <Tag color="gold">{InventoryStatusMap[status]}</Tag>;

          case InventoryStatus.IN_STORAGE:
            return <Tag color="orange">{InventoryStatusMap[status]}</Tag>;

          case InventoryStatus.CONFIG:
            return <Tag color="blue-inverse">{InventoryStatusMap[status]}</Tag>;

          case InventoryStatus.READY:
            return <Tag color="blue">{InventoryStatusMap[status]}</Tag>;

          case InventoryStatus.ASSIGNED:
            return <Tag color="green">{InventoryStatusMap[status]}</Tag>;

          case InventoryStatus.RETRIEVE:
            return <Tag color="cyan">{InventoryStatusMap[status]}</Tag>;

          default:
            return <Tag color="orange">{InventoryStatusMap[status]}</Tag>;
        }
      },
    },
    {
      title: t('Tags'),
      dataIndex: 'tags',
      key: 'tags',
      width: '20%',
      onFilter: (value: any, record: any) => record.tags?.includes(value),
      filters: props.inventoryTagsMap
        ? Object.entries(props.inventoryTagsMap).map(([key, value]) => ({
            text: value.title,
            value: key,
          }))
        : [],
      render: (tags: string[]) => {
        return tags
          ? tags.map((tag: string) => <Tag>{props.inventoryTagsMap[tag]?.title}</Tag>)
          : null;
      },
    },
    {
      title: t('Note'),
      dataIndex: 'note',
      key: 'note',
      width: '10%',
      render: (note: string) => <Tooltip title={note}>{note}</Tooltip>,
    },
    {
      title: t('inventory.ipad.updatetime'),
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: '20%',
      render: (updateTime: Date) => getDateString(updateTime, intl),
    },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => <a onClick={() => props.setVisible(id)}>Ret</a>,
    },
  ]; //.filter(i => (!isAdmin(uid) ? i.key !== "members" && i.key !== "status" : i));
  // @ts-ignore
  return <Table columns={columns} rowKey="id" dataSource={props.data} />;
}

export default TableList;
