import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { functions } from '../../../firebase';
import { AutoComplete, Avatar, Button, Card, Input, List, Tooltip } from 'antd';
import { getInitials } from '../../../general/initials';
import reducer, { initialState } from '../pipedriveDealsReducer';
import debounce from 'lodash.debounce';
import { StyleGuide } from '../../../styles/StyleGuide';
//import PipeModal from "../Orgs/Modal";
import { getOwner } from '../helpers';
import { FireOutlined } from '@ant-design/icons';
import groupBy from 'lodash.groupby';

function formatDealData(result: any) {
  return (
    result.data?.map((d: any) => ({
      id: d.id,
      title: d.title,
      stageId: d.stage_id,
      stage: result.related_objects.stage[d.stage_id].name,
      orgName: d.org_name,
      contactName: d.person_name,
      nextActivitySubject: d.next_activity_subject,
      notesCount: d.notes_count,
      activityCount: d.activities_count,
      ownerName: d.owner_name,
    })) || []
  );
}

export default function SearchDeals() {
  const [loading, setLoading] = useState(false);
  const [cardView, setCardView] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const getDeals = async () => {
      try {
        setLoading(true);
        const functionRef = functions.httpsCallable('getPipedriveDeals');
        const response: any = await functionRef({
          start: 0,
          limit: 100,
        });

        const errorCode = response?.data?.httpErrorCode?.status;
        if (errorCode) {
          console.log({ errorCode });
        }
        console.log({ getDeals: response });

        dispatch({
          type: 'SET_DATA',
          // @ts-ignore
          payload: {
            data: formatDealData(response.data),
            pagination: response.data.additional_data.pagination,
            stages: groupBy(
              Object.entries(response.data.related_objects.stage).map(([_, value]) => value),
              'pipeline_id'
            ),
            pipelines: response.data.related_objects.pipeline,
          },
        }); //(response?.data?.data?.items || []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getDeals();

    return () => {};
  }, []);

  const onSearch = async (searchTerm: string) => {
    try {
      console.log({ onSearch: searchTerm });

      setLoading(true);
      const functionRef = functions.httpsCallable('searchPipedriveDeals');
      const response: any = await functionRef({ searchTerm });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        console.log({ errorCode });
      }
      console.log({ onSearch: response });

      dispatch({
        type: 'SET_SEARCH_RESULTS',
        payload:
          response?.data?.data?.items.map((i: any) => ({
            label: i?.item?.title,
            value: i?.item?.id,
          })) || [],
      });

      console.log({ response });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const debounceSearch = useMemo(() => {
    return debounce(onSearch, 500);
  }, []);

  const onLoadMore = async ({ limit, start }: { limit: number; start: number }) => {
    try {
      setLoading(true);
      const functionRef = functions.httpsCallable('getPipedriveDeals');
      const response: any = await functionRef({
        start,
        limit,
      });

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        console.log({ errorCode });
      }
      console.log({ onLoadMore: response });
      dispatch({
        type: 'SET_DATA_MORE',
        payload: {
          data: formatDealData(response.data),
          pagination: response.data.additional_data.pagination,
        },
      }); //(response?.data?.data?.items || []);
      console.log({ response });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleSearch = (value: string) => {
  //     dispatch({ type: "SET_SEARCH_TERM", payload: value });
  // };

  const onSelect = (value: any) => {
    console.log('onSelect', value);
    dispatch({ type: 'SET_ID', payload: value });
  };

  const loadMore =
    state.pagination.more_items_in_collection && !loading ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button
          onClick={() =>
            onLoadMore({
              start: state.pagination.next_start,
              limit: state.pagination.limit,
            })
          }
        >
          Indlæs flere
        </Button>
      </div>
    ) : null;

  console.log({ pipelines: state.pipelines });

  return (
    <>
      {/* <Button onClick={() => setCardView(!cardView)}>
                Skift til {cardView ? "List" : "Card"} view
            </Button> */}
      <AutoComplete
        // popupMatchSelectWidth={252}
        style={{ width: '100%' }}
        options={state.searchResults}
        onSelect={onSelect}
        onSearch={debounceSearch}
        size="large"
      >
        <Input.Search size="large" placeholder="Søg efter salgsdeals" enterButton />
      </AutoComplete>

      <div style={{ marginTop: 16 }} />

      {cardView ? (
        Object.entries(state.stages)?.map(([id, list]) => {
          return (
            <div>
              <div>{state.pipelines[id]?.name}</div>
              <div style={{ display: 'flex' }}>
                {list.map((stage: any) => {
                  console.log({ stage });
                  const dealsInStage = state.data.filter((d) => d.stageId === stage.id);
                  return (
                    <div style={{ width: 300 }}>
                      <div>{stage.name}</div>
                      <List
                        bordered
                        style={{ marginRight: 16 }}
                        // grid={{ gutter: 16, column: 4 }}
                        dataSource={dealsInStage}
                        renderItem={(item) => <List.Item>{item.title}</List.Item>}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      ) : (
        <List
          // size="small"
          bordered
          loading={loading}
          loadMore={loadMore}
          dataSource={state.data}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  item.nextActivitySubject && (
                    <div>
                      <Tooltip title={item.nextActivitySubject}>
                        <FireOutlined />
                      </Tooltip>
                    </div>
                  ),
                  <div>{item.stage}</div>,
                  <Tooltip placement={'left'} title={item.ownerName}>
                    <Avatar
                      // size={24}
                      style={
                        {
                          // backgroundColor: owner.bgColor,
                          // color: owner.color,
                        }
                      }
                    >
                      {getInitials(item.ownerName)}
                    </Avatar>
                  </Tooltip>,
                ].filter(Boolean)}
              >
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => dispatch({ type: 'SET_ID', payload: item.id })}
                >
                  {item?.title}
                </span>{' '}
                {item.orgName && (
                  <span style={{ color: StyleGuide.palette.grey600 }}>- {item.orgName} </span>
                )}
                {item.contactName && (
                  <span style={{ color: StyleGuide.palette.grey600 }}>- {item.contactName}</span>
                )}
              </List.Item>
            );
          }}
        />
      )}
      {/* <PipeModal
            stage

contactName
nextActivitySubject
                id={state.id}
                onClose={() => dispatch({ type: "SET_ID", payload: null })}
            /> */}
    </>
  );
}
