'use client';

import type { ColumnDef } from '@tanstack/react-table';
import { ArrowUpDown, MoreHorizontal } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Badge } from 'components/ui/badge';
import { QRCodeSVG } from 'qrcode.react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import type { InventoryItem } from '../types';

// Helper function for category colors
export const getCategoryColor = (category: string) => {
  const colors = {
    Sikkerhedsudstyr: 'bg-red-500 hover:bg-red-600',
    Elværktøj: 'bg-yellow-500 hover:bg-yellow-600',
    Håndværktøj: 'bg-green-500 hover:bg-green-600',
    Stiger: 'bg-blue-500 hover:bg-blue-600',
    // Add more categories and colors as needed
  };
  // @ts-ignore
  return colors[category] || 'bg-gray-500 hover:bg-gray-600';
};

// Action handlers interface
interface ActionHandlers {
  onView: (item: InventoryItem) => void;
  onEdit: (item: InventoryItem) => void;
  onDelete: (id: string) => void;
  onCheckOut?: (item: InventoryItem) => void;
  onCheckIn?: (item: InventoryItem) => void;
  onScheduleMaintenance?: (item: InventoryItem) => void;
  onQrCodeClick: (id: string) => void;
}

export const getInventoryColumns = (actionHandlers: ActionHandlers): ColumnDef<InventoryItem>[] => [
  {
    id: 'qrCode',
    header: 'QR Code',
    cell: ({ row }) => {
      const item = row.original;
      return (
        <Button
          variant="ghost"
          className="p-0"
          onClick={() => actionHandlers.onQrCodeClick(item.id)}
        >
          <QRCodeSVG value={item.id} size={24} />
        </Button>
      );
    },
  },
  {
    accessorKey: 'id',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          ID
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => <div className="font-mono">{row.getValue('id')}</div>,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Navn
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'category',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Kategori
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
    cell: ({ row }) => {
      const category = row.getValue('category') as string;
      return <Badge className={getCategoryColor(category)}>{category}</Badge>;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'location',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Site
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  // {
  //   accessorKey: 'lastMaintenance',
  //   header: ({ column }) => {
  //     return (
  //       <Button
  //         variant="ghost"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
  //       >
  //         Last Maintenance
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </Button>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const value = row.getValue('lastMaintenance') as string | null;
  //     return value ? new Date(value).toLocaleDateString() : 'N/A';
  //   },
  // },
  // {
  //   accessorKey: 'nextMaintenance',
  //   header: ({ column }) => {
  //     return (
  //       <Button
  //         variant="ghost"
  //         onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
  //       >
  //         Next Maintenance
  //         <ArrowUpDown className="ml-2 h-4 w-4" />
  //       </Button>
  //     );
  //   },
  //   cell: ({ row }) => {
  //     const value = row.getValue('nextMaintenance') as string | null;
  //     return value ? new Date(value).toLocaleDateString() : 'Not Scheduled';
  //   },
  // },
  {
    id: 'actions',
    cell: ({ row }) => {
      const item = row.original;

      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                window.location.href = `/equipment/${item.id}`;
              }}
            >
              Se detaljer
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => actionHandlers.onEdit(item)}>Edit</DropdownMenuItem>
            {/* {item.status === 'Available' && actionHandlers.onCheckOut && (
              <DropdownMenuItem onClick={() => actionHandlers.onCheckOut!(item)}>
                Check Out
              </DropdownMenuItem>
            )}
            {item.status === 'In Use' && actionHandlers.onCheckIn && (
              <DropdownMenuItem onClick={() => actionHandlers.onCheckIn!(item)}>
                Check In
              </DropdownMenuItem>
            )}
            {actionHandlers.onScheduleMaintenance && (
              <DropdownMenuItem onClick={() => actionHandlers.onScheduleMaintenance!(item)}>
                Schedule Maintenance
              </DropdownMenuItem>
            )} */}
            <DropdownMenuItem
              className="text-destructive focus:text-destructive"
              onClick={() => actionHandlers.onDelete(item.id)}
            >
              Slet
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];
