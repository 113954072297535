import React from 'react';
import ActivityList from './List';
import useActivity from './useActivity';
import { getActivity } from '../../general/Activity/helpers';

function ActivityListContainer() {
  const activity = useActivity();
  const mappedActivity = activity.map(getActivity);
  return <ActivityList activity={mappedActivity} />;
}

export default ActivityListContainer;
