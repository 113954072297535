import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

export interface AppProducts {
  id: string;
  name: string;
  defaultPrice: number;
}
export default function useAppProducts() {
  const [appProducts, setAppProducts] = useState<AppProducts[]>([]);

  useEffect(() => {
    let unsubscribe: any = null;
    unsubscribe = firebase
      .firestore()
      .collection(`appProducts`)
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: AppProducts[] = query.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as AppProducts)
          );
          setAppProducts(list);
        }
      });
    return unsubscribe;
  }, []);
  return {
    appProducts,
  };
}
