import React from 'react';
import SideNav from '../../Layout/SideNav';
import { Layout, Space } from 'antd';
import HeaderBar from '../../Layout/HeaderBar';
import { Content } from 'antd/lib/layout/layout';
import FooterBar from '../../Layout/FooterBar';
import BillingTable from './Table';
import ModalBilling from './Modal';
import useAppProducts from '../../hooks/useAppProducts';
import useAppBillings from '../../hooks/useOrgAppBillings';

export default function Billing() {
  const { appProducts } = useAppProducts();
  const { appBilling } = useAppBillings();
  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '50px 24px 0' }}>
          <BillingTable appBilling={appBilling} />
          <Space />
          <ModalBilling appProducts={appProducts} />
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}
