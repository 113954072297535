import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInventoryGPS } from '../../../modules/inventory/gps/actions';
import { AppState } from '../../../store';
import { GPSItem } from '../../../modules/inventory/gps/types';

function sortByIdentifier(a: GPSItem, b: GPSItem) {
  return a.identifier.localeCompare(b.identifier);
}

function useInventoryGPS() {
  const dispatch = useDispatch();
  const gps = useSelector((state: AppState) =>
    state.inventory.gpsIds
      .map((id) => state.inventory.inventoryMap[id] as GPSItem)
      .sort(sortByIdentifier)
  ) as GPSItem[];

  useEffect(() => {
    dispatch(fetchInventoryGPS());
  }, [dispatch]);
  return { gps };
}

export default useInventoryGPS;
