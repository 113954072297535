import React, { useState } from 'react';
import { Card, List, Space, Typography } from 'antd';
import { useParams } from 'react-router';
import { CheckCircleTwoTone, ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Layout/Colors';
import { filterOlderAuditsByCompleteTime, filterRecentAuditsByCompleteTime } from './auditFilter';
import useHasOnHoldAuditsByGroup from 'modules/auditSchedule/useHasOnHoldAuditsByGroup';
import { Member } from 'modules/orgs/interfaces';
import PopoverTaskDetails from 'Views/Dashboard/PopoverTaskDetails';
import AuditItem from 'Views/Dashboard/AuditItem';
import { sortOnOrderAndCreateTime } from 'hooks/useAuditTasks';
import { AuditScheduleTask } from 'modules/auditSchedule/types';

export default function AuditOnHoldWidget({
  orgMap,
  groupId,
  orgIds,
}: {
  orgMap: { [id: string]: { title: string; members: Member } };
  groupId: string;
  orgIds: string[];
}) {
  const { t } = useTranslation();
  const [activeTab, setactiveTab] = useState('tab1');
  const { orgId } = useParams<{ orgId: string }>();
  const { audits, isLoading } = useHasOnHoldAuditsByGroup(groupId, orgIds);
  /**
   * TODO: this on a task level
   */
  const auditsLast7Days = audits.filter(filterRecentAuditsByCompleteTime).length;
  const auditsBefore7Days = audits.filter(filterOlderAuditsByCompleteTime).length;
  if (isLoading) {
    return (
      <Card
        title={t('dashboard.auditsWithIssues.title')}
        headStyle={{}}
        style={{
          width: '100%',
          height: 467,
          backgroundColor: '#fff',
          color: '#001628',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '370px',
          }}
        >
          <LoadingOutlined style={{ fontSize: 50 }} />
        </div>
      </Card>
    );
  }
  const tabList = [
    {
      key: 'tab1',
      tab: `Seneste 7 dage ${auditsLast7Days > 0 ? `(${auditsLast7Days})` : ''}`,
    },
    {
      key: 'tab2',
      tab: `Over 7 dage ${auditsBefore7Days > 0 ? `(${auditsBefore7Days})` : ''}`,
    },
  ];

  const list = audits
    .map((audit) => ({
      id: audit.id,
      title: audit.templateTitle,
      completeTime: audit.completeTime,
      tasks: sortOnOrderAndCreateTime(
        Object.keys(audit.tasks)
          .filter((t) => audit.tasks[t].isOnHold)
          .map((t) => audit.tasks[t])
      ) as AuditScheduleTask[],
      orgId: audit.orgId,
    }))
    .filter(
      activeTab === 'tab1' ? filterRecentAuditsByCompleteTime : filterOlderAuditsByCompleteTime
    );
  return (
    <Card
      title={
        <Space
          style={{
            display: 'flex',
            paddingLeft: 24,
            paddingRight: 16,
            paddingTop: 0,
            paddingBottom: 16,
          }}
        >
          <ClockCircleOutlined style={{ display: 'flex', fontSize: 24, color: Colors.warning }} />
          {t('dashboard.auditsOnHold.title')}
        </Space>
      }
      // headStyle={{ color: Colors.warning }}
      style={{
        width: '100%',
        backgroundColor: '#fff',
      }}
      tabList={tabList}
      onTabChange={setactiveTab}
      activeTabKey={activeTab}
      tabProps={{
        size: 'small',
        style: { paddingLeft: 24, borderTop: '1px solid #f0f0f0' },
      }}
      headStyle={{ padding: 0, color: '#001628' }}
      bodyStyle={{
        height: 370,
        overflowY: 'scroll',
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div style={{ color: '#001628' }}>
        <List
          itemLayout="horizontal"
          dataSource={list}
          locale={{
            emptyText: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 48,
                }}
              >
                <CheckCircleTwoTone twoToneColor={Colors.done} style={{ fontSize: 64 }} />
                <Typography.Title level={5}>{t('dashboard.auditsOnHold.empty')}</Typography.Title>
              </div>
            ),
          }}
          renderItem={(item) => {
            return (
              <PopoverTaskDetails
                key={item.id}
                title="dashboard.auditsOnHold.title"
                color={Colors.warning}
                tasks={item.tasks}
              >
                <div>
                  <AuditItem
                    key={item.id}
                    taskCount={item.tasks.length}
                    color={Colors.warning}
                    linkPath={`/orgs/${item.orgId}/audit/audits/${item.id}`}
                    title={item.title}
                    subTitle={orgMap?.[item.orgId]?.title}
                    description={
                      <>
                        {t('dashboard.auditsOnHold.listItemDescription')}{' '}
                        {t('general.dateToNow', {
                          date: item.completeTime?.toDate(),
                        })}
                      </>
                    }
                  />
                </div>
              </PopoverTaskDetails>
            );
          }}
        />
      </div>
    </Card>
  );
}
