// Translate status

import { OrderStatus } from './types';

export const translateStatus = (status: OrderStatus) => {
  switch (status) {
    case 'AWAITING':
      return 'orders.status_awaiting';
    case 'PROCESSING':
      return 'orders.status_processing';
    case 'READY_FOR_SHIPPING':
      return 'orders.status_ready_for_shipping';
    case 'IN_TRANSIT':
      return 'orders.status_shipped';
    case 'COMPLETED':
      return 'orders.status_completed';
    case 'VOIDED':
      return 'orders.status_cancel';
    default:
      return status;
  }
};

export const getStatusColor = (status: OrderStatus) => {
  switch (status) {
    case 'AWAITING':
      return 'bg-yellow-500 hover:bg-yellow-600';
    case 'PROCESSING':
      return 'bg-blue-500 hover:bg-blue-600';
    case 'READY_FOR_SHIPPING':
      return 'bg-indigo-500 hover:bg-indigo-600';
    case 'IN_TRANSIT':
      return 'bg-purple-500 hover:bg-purple-600';
    case 'COMPLETED':
      return 'bg-green-500 hover:bg-green-600';
    case 'VOIDED':
      return 'bg-red-500 hover:bg-red-600';
    default:
      return 'bg-gray-500 hover:bg-gray-600';
  }
};
