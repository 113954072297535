import React from 'react';
import TableEditCancel from '../../../components/Table/TableEditCancel';
import NotificationSettings from './NotificationSettings';
import { useTranslation } from 'react-i18next';
import { displayAdminEmail } from '../../../general/admin';
import { Roles } from 'modules/orgs/interfaces';

export function RoleText({ role }: { role: Roles }) {
  const { t } = useTranslation();
  switch (role) {
    case Roles.EXTERNAL:
      return <span>{t('access.role_external')}</span>;

    case Roles.CLEANER:
      return <span>{t('access.role_cleaner')}</span>;

    case Roles.DEPARTMENT_EXECUTIVE:
      return <span>{t('access.role_executive')}</span>;

    case Roles.SALES:
      return <span>{t('access.role_sales')}</span>;

    case Roles.ADMIN:
      return <span>{t('access.role_admin')}</span>;

    default:
      return <span>{t('access.role_unknown')}</span>;
  }
}
function Phone({ phone }: { phone: string }) {
  const { t } = useTranslation();
  const isEmpty = !phone;
  return (
    <div style={isEmpty ? { color: 'lightgrey' } : {}}>
      {phone || t('access.table_phone_empty')}
    </div>
  );
}

export default function columns(
  editingKey: string,
  isEditing: (record: any) => boolean,
  save: (key: string, amount: number) => void,
  edit: (record: any) => void,
  cancel: () => void,
  deleteAction: (key: string) => void,
  // onUpdateNotifications: (e: CheckboxChangeEvent, user: any) => void,
  titles: any
) {
  return [
    {
      title: titles.email,
      dataIndex: 'email',
      render: (email: string) => displayAdminEmail(email),
      // width: 300,
      editable: false,
    },
    {
      title: titles.name,
      dataIndex: 'name',
      // width: 300,
      editable: true,
    },
    {
      title: titles.role,
      dataIndex: 'role',
      render: (_: any, record: any) => <RoleText role={record.role} />,
      // width: 200,
      editable: true,
    },
    {
      title: titles.phone,
      dataIndex: 'phone',
      render: (_: any, record: any) => <Phone phone={record.phone} />,
      // width: 200,
      editable: false,
    },
    {
      title: titles.notifications,
      dataIndex: 'notifications',
      render: (_: any, record: any) => {
        return (
          <NotificationSettings
            record={record}
            // onUpdateNotifications={e => onUpdateNotifications(e, record)}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      // width: 100,
      render: (_: any, record: any) => (
        <TableEditCancel
          record={record}
          editingKey={editingKey}
          isEditing={isEditing}
          save={save}
          edit={edit}
          cancel={cancel}
          deleteAction={deleteAction}
        />
      ),
    },
  ];
}
