import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../firebase';
import { fetchDirectoriesSuccess } from './actions';
import { DIRECTORY_UPDATE_REQUESTED } from './types';
import {
  DIRECTORIES_FETCH_FAILED,
  DIRECTORY_UPDATE_FAILED,
  DIRECTORIES_FETCH_REQUESTED,
  DIRECTORY_ADD_SUCCEEDED,
  DIRECTORY_ADD_FAILED,
  DIRECTORY_ADD_REQUESTED,
} from './types';

function* fetchDirectories({ payload }: { payload: { orgId: string } }) {
  const { orgId } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection(`directories`)
        .where('isDeleted', '==', false)
        .orderBy('createTime', 'desc'),
      { successActionCreator: fetchDirectoriesSuccess }
    );
  } catch (e: any) {
    yield put({ type: DIRECTORIES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchDirectoriesSaga() {
  // @ts-ignore
  yield takeLatest(DIRECTORIES_FETCH_REQUESTED, fetchDirectories);
}

function* updateDirectory({
  payload,
}: {
  payload: { orgId: string; directoryId: string; data: { title: string } };
}) {
  const { orgId, directoryId, data } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.updateDocument,
      `orgs/${orgId}/directories/${directoryId}`,
      data
    );
  } catch (e: any) {
    yield put({ type: DIRECTORY_UPDATE_FAILED, message: e.message });
  }
}

export function* updateDirectorySaga() {
  // @ts-ignore
  yield takeLatest(DIRECTORY_UPDATE_REQUESTED, updateDirectory);
}

function* addDirectory({
  payload,
}: {
  payload: { orgId: string; title: string; directoryId?: string };
}) {
  console.log({ payload });

  try {
    const { orgId, title, directoryId } = payload;
    yield call(reduxSagaFirebase.firestore.addDocument, `orgs/${orgId}/directories`, {
      title,
      isDeleted: false,
      createTime: new Date(),
      parentDirectoryId: directoryId || null,
    });
    yield put({ type: DIRECTORY_ADD_SUCCEEDED });
  } catch (e: any) {
    yield put({ type: DIRECTORY_ADD_FAILED, message: e.message });
  }
}

export function* addDirectorySaga() {
  // @ts-ignore
  yield takeLatest(DIRECTORY_ADD_REQUESTED, addDirectory);
}
