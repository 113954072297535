import React from 'react';
import { Input, Form, Select } from 'antd';
import { statuses } from '../../../modules/orders/status';
import { Status } from './columns';

const Option = Select.Option;

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  const inputNode =
    inputType === 'select' ? (
      <Select placeholder="Vælg produkt">
        {Object.keys(statuses).map((status) => (
          <Option key={status} value={status}>
            <Status status={status} />
          </Option>
        ))}
      </Select>
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Venligst udfyld ${title}`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
