import React from 'react';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { Button } from 'antd';
import {
  getAdditionalServicesSubscriptionFixedPrice,
  getAdditionalServicesSubscriptionVariablePrice,
  getDiscount,
  getDiscountFactor,
  getLiters,
  getSubscriptionPrice,
} from '../Agreements/Context/OfferContext';
import {
  aqSensorDataPrice,
  aqSensorPrice,
  deliveryOptionMap,
  dustMeasurementPrice,
  gpsSensorDataPrice,
  gpsSensorPrice,
  limitM2,
  pricePrLiterLarge,
  pricePrLiterSmall,
  sweepingGranulesLiterPrice,
  year1Discount,
  year2Discount,
  year3Discount,
} from '../Agreements/Context/offerConfigs';
import { AppOffer } from 'hooks/useAppOffers';
import { DownloadOutlined } from '@ant-design/icons';
import { PriceType } from '../Agreements/Context/offerReducer';

export default function OfferPDF({ appOffer }: { appOffer: AppOffer }) {
  const state = appOffer.offer;
  const hasMeasurements = state.dustMeasurements > 0;
  const hasSensor = state.sensors > 0;
  const hasGps = state.gps > 0;
  const measurementCount = state.dustMeasurements;
  const sensorCount = state.sensors;
  const gpsCount = state.gps;
  const finalPrice = state.finalPrice;
  const finalLiter = state.finalLiter;
  const deliveryOption = state.deliveryOption;
  const { litersPrYear } = getLiters(state.m2);
  const discount = state.discount || 0;
  const { monthlySubscriptionPrice } = getSubscriptionPrice(state.m2);
  const isSubscription = state.priceType === PriceType.SUBSCRIPTION;
  const isCustom = state.priceType === PriceType.CUSTOM;

  const { monthly } = getAdditionalServicesSubscriptionFixedPrice(state);
  const { monthlyData } = getAdditionalServicesSubscriptionVariablePrice(state);

  const year1 = monthlySubscriptionPrice * year1Discount + monthly + monthlyData;

  const year2 = monthlySubscriptionPrice * year2Discount + monthly / (2 * 12) + monthlyData;

  const year3 = monthlySubscriptionPrice * year3Discount + monthly / (3 * 12) + monthlyData;

  const discountedLiterPrice = getDiscount(
    state.m2 >= limitM2 ? pricePrLiterLarge : pricePrLiterSmall,
    discount
  );

  const offerReceiverAtt = appOffer.contact.att;
  const offerReceiverCompany = appOffer.title;

  const discountedSweepingGranulesLiterPrice =
    sweepingGranulesLiterPrice * getDiscountFactor(state.discountGranules || 0);
  const sweepingGranulesLitersPrYear = state.sweepingGranules;

  return (
    <div>
      <Button
        onClick={() =>
          getPDF({
            refId: appOffer.refId,
            timestamp: format(appOffer.updateTime, 'dd-MM-yyyy'),
            m2: state.m2,
            hasMeasurements,
            hasSensor,
            hasGps,
            litersPrYear,
            discount,
            pricePrLiter: pricePrLiterLarge,
            monthlyPriceYearOne: year1,
            monthlyPriceYearTwo: year2,
            monthlyPriceYearThree: year3,
            literDiscountPrice: discountedLiterPrice,
            isSubscription,
            isCustom,
            offerReceiverAtt,
            offerReceiverCompany,
            sweepingGranulesLiterPrice: discountedSweepingGranulesLiterPrice,
            sweepingGranulesLitersPrYear,
            note: appOffer.offer.note,
            measurementCount,
            sensorCount,
            gpsCount,
            finalPrice,
            finalLiter,
            deliveryOption,
          })
        }
      >
        <DownloadOutlined />
        (PDF)
      </Button>
    </div>
  );
}

function filterArrayItem(text: string, flag: boolean) {
  if (flag) {
    return [text];
  }
  return [];
}

export function getCurrencyFormat(value: number) {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    maximumFractionDigits: 0,
  }).format(value);
}
export function getCurrencyLiterPriceFormat(value: number) {
  return new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    maximumFractionDigits: 1,
  }).format(value);
}
function getLiterFormat(value: number) {
  return new Intl.NumberFormat('da-DK', {
    maximumFractionDigits: 0,
  }).format(value);
}
function getM2Format(value: number) {
  return new Intl.NumberFormat('da-DK', {
    maximumFractionDigits: 0,
  }).format(value);
}

// PDF JS stuff

function getPDF({
  refId,
  timestamp,
  m2,
  hasMeasurements,
  hasSensor,
  hasGps,
  litersPrYear,
  discount,
  pricePrLiter,
  monthlyPriceYearOne,
  monthlyPriceYearTwo,
  monthlyPriceYearThree,
  literDiscountPrice,
  dustResult = '40%-80%',
  isSubscription,
  isCustom,
  offerReceiverAtt,
  offerReceiverCompany,
  sweepingGranulesLiterPrice,
  sweepingGranulesLitersPrYear,
  note,
  measurementCount,
  sensorCount,
  gpsCount,
  finalPrice,
  finalLiter,
  deliveryOption,
}: {
  refId: string;
  timestamp: string;
  m2: number;
  hasMeasurements: boolean;
  hasSensor: boolean;
  hasGps: boolean;
  monthlyPriceYearOne: number;
  monthlyPriceYearTwo: number;
  monthlyPriceYearThree: number;
  literDiscountPrice: number;
  pricePrLiter: number;
  discount: number;
  litersPrYear: number;
  dustResult?: string;
  isSubscription?: boolean;
  isCustom?: boolean;
  offerReceiverAtt: string;
  offerReceiverCompany: string;
  sweepingGranulesLiterPrice: number;
  sweepingGranulesLitersPrYear: number;
  note: string | null;
  measurementCount: number;
  sensorCount: number;
  gpsCount: number;
  finalPrice?: number | null;
  finalLiter?: number | null;
  deliveryOption?: number;
}) {
  const doc = new jsPDF();

  const margin = 20;
  const lineHeight = 5;
  const maxWidth = doc.internal.pageSize.width - margin * 2;
  const pageHeight = doc.internal.pageSize.height;
  const bottomMargin = 20;
  console.log({ pageW: doc.internal.pageSize.width, maxWidth });
  const oneTimeFee =
    measurementCount * dustMeasurementPrice +
    sensorCount * aqSensorPrice +
    gpsCount * gpsSensorPrice;
  const yearlyFee = sensorCount * aqSensorDataPrice + gpsCount * gpsSensorDataPrice;

  let y = margin + 20;
  doc.addImage(require('../../assets/logos/aks_logo.png'), 'PNG', margin, 10, 40, 10);
  doc.setFontSize(10);
  doc.setFont('helvetica');
  doc.text(`Ref: ${refId}`, maxWidth - 20, 15);
  doc.text(`${timestamp}`, maxWidth + 3, 20);
  function addHeader(text: string, fontSize = 16) {
    checkPageOverflow(lineHeight + 5);
    doc.setFontSize(fontSize);
    doc.setFont('helvetica', 'bold');
    doc.text(text, margin, y);
    y += lineHeight + 5;
  }
  function addSignature() {
    y += 20;
    checkPageOverflow(lineHeight + 5);
    doc.line(margin, y, maxWidth / 2, y);
    doc.text('Navn:', margin, y + 5);
    doc.text('Dato:', margin, y + 10);
    doc.line(margin * 2 + maxWidth / 2, y, maxWidth + margin, y);
    doc.text('Navn:', margin * 2 + maxWidth / 2, y + 5);
    doc.text('Dato:', margin * 2 + maxWidth / 2, y + 10);
    y += lineHeight + 5;
  }

  function addParagraph(text: string) {
    const lines = doc
      .setFont('helvetica')
      .setFontSize(12)
      .splitTextToSize(text, maxWidth - margin);
    console.log({ margin, lines, y, maxWidth });

    checkPageOverflow(lines.length * lineHeight + 5);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(lines, margin, y);
    y += lines.length * lineHeight + 5;
  }

  function addList(items: string[]) {
    items.forEach((item) => {
      const lines = doc
        .setFont('helvetica')
        .setFontSize(12)
        .splitTextToSize(item, maxWidth - margin);
      checkPageOverflow(lines.length * lineHeight);
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text('•', margin, y);
      doc.text(lines, margin + 10, y);
      y += lines.length * lineHeight;
    });
    y += 5;
  }

  function checkPageOverflow(contentHeight: number) {
    if (y + contentHeight > pageHeight - bottomMargin) {
      doc.addPage();
      y = margin;
    }
  }

  const monthlyPrice = isCustom ? (finalPrice as number) : literDiscountPrice * (litersPrYear / 12);
  const literPrice = isCustom
    ? (finalPrice as number) / (finalLiter as number)
    : getDiscount(pricePrLiter, discount);
  const finalLitersPrYear = isCustom ? (finalLiter as number) * 12 : litersPrYear;
  addHeader(`Tilbud til ${offerReceiverAtt}`, 24);
  addParagraph(`Som aftalt tilbud på aks2tal støvbinder løsning til ${offerReceiverCompany}.`);
  addHeader(`Forudsætninger`);
  addParagraph(
    `Vi tager udgangspunkt i at I har ca. ${getM2Format(
      m2
    )} m2, hos ${offerReceiverCompany}. Til brug for støvreduktionen vil I skulle bruge ${
      sweepingGranulesLitersPrYear > 0
        ? 'henholdsvis AKS Støvbinder Feje Granulat (blå pose), og '
        : ''
    }AKS Støvbinder med ekstra skridsikring.`
  );
  addHeader(`Vores tilbud til jer:`);
  if (isSubscription) {
    addParagraph(
      `Hvis vi indgår en 3-årig aftale, med ${dustResult} tilbyder vi en pris på ${
        discount ? `med ${discount}% rabat` : ''
      }:`
    );
    addList([
      `År 1: ${getCurrencyFormat(
        monthlyPriceYearOne * getDiscountFactor(discount)
      )} pr. måned + moms.`,
      `År 2: ${getCurrencyFormat(
        monthlyPriceYearTwo * getDiscountFactor(discount)
      )} pr. måned + moms.`,
      `År 3: ${getCurrencyFormat(
        monthlyPriceYearThree * getDiscountFactor(discount)
      )} pr. måned + moms.`,
      ...filterArrayItem(
        `${`Fejegranulat ${getCurrencyFormat(
          sweepingGranulesLitersPrYear * sweepingGranulesLiterPrice
        )} pr. år`}`,
        sweepingGranulesLitersPrYear > 0
      ),
    ]);
    addParagraph(
      `Dette inkluderer produkter,${hasSensor ? ` ${sensorCount} sensor(er),` : ''}${
        hasGps ? ` ${gpsCount} GPS(er),` : ''
      }${
        hasMeasurements ? ` ${measurementCount} certificerede målinger (før og efter),` : ''
      } app til registrering af forbrug og fragt.`
    );
  } else {
    // addParagraph(`Vi tilbyder en liter pris:`);
    addList([
      `${getCurrencyFormat(literPrice)} pr. liter AKS Støvbinder med Ekstra Skridsikring ${
        discount ? `med ${discount}% rabat` : ''
      }:`,
      `Det vil give en pris på ${getCurrencyFormat(
        monthlyPrice
      )} pr. måned + moms for en reduktion på ${dustResult}. ${
        hasMeasurements
          ? 'Vi garanterer en reduktion på mindst 40%, (målt på støv peaks/aktivitet). dette dokumenteres i vores rapport med certificerede målinger.'
          : ''
      }`,
      ...filterArrayItem(
        `${getCurrencyLiterPriceFormat(
          sweepingGranulesLiterPrice
        )} pr. liter AKS Støvbinder Fejegranulat`,
        sweepingGranulesLiterPrice > 0
      ),
      ...filterArrayItem(
        `Certificerede målinger: ${measurementCount} stk á ${getCurrencyFormat(
          dustMeasurementPrice
        )} for før og efter måling`,
        hasMeasurements
      ),
      ...(hasSensor
        ? [
            `Sensor: ${sensorCount} stk á ${getCurrencyFormat(aqSensorPrice)} engangsbeløb`,
            `Data for sensor + rapporter: ${sensorCount} á ${getCurrencyFormat(
              aqSensorDataPrice
            )} pr. år.`,
          ]
        : []),
      ...(hasGps
        ? [
            `GPS til gulvvaskemaskine: ${gpsCount} stk á ${getCurrencyFormat(
              gpsSensorPrice
            )} engangsbeløb`,
            `Data for GPS: ${gpsCount} stk á ${getCurrencyFormat(gpsSensorDataPrice)} pr. år.`,
          ]
        : []),

      'App til registrering af forbrug: Gratis',
    ]);
    addParagraph(
      `Samlet ex. moms: ${
        oneTimeFee > 0 ? `Engangsbeløb på ${getCurrencyFormat(oneTimeFee)} (sensor/målinger), ` : ''
      }${
        yearlyFee > 0 ? `årligt beløb på ${getCurrencyFormat(yearlyFee)} (sensor data), og ` : ''
      }${getCurrencyFormat(monthlyPrice)} pr. måned (støvbinder)${
        sweepingGranulesLiterPrice > 0
          ? ` og ${getCurrencyFormat(
              sweepingGranulesLitersPrYear * sweepingGranulesLiterPrice
            )} pr. år (fejegranulat)`
          : ''
      }`
    );
  }
  addParagraph(
    `Forbrug estimeres til ${getLiterFormat(
      finalLitersPrYear
    )} liter pr. år af AKS Støvbinder med Ekstra Skridsikring${
      sweepingGranulesLitersPrYear > 0
        ? ` og ${getLiterFormat(
            sweepingGranulesLitersPrYear
          )} liter pr. år af AKS Støvbinder Fejegranulat`
        : ''
    }`
  );
  addParagraph(
    `Produkter leveres: ${
      deliveryOption
        ? //@ts-ignore
          deliveryOptionMap[deliveryOption]
        : deliveryOptionMap[1]
    }`
  );

  addHeader('Anbefalet løsning til jer:');
  addList([
    'Brug AKS Støvbinder Ekstra Skridsikring i områder med gulvvaskemaskine.',
    ...filterArrayItem(
      'Brug AKS Støvbinder Fejegranulat i områder hvor der ikke vaskes.',
      sweepingGranulesLitersPrYear > 0
    ),
    ...filterArrayItem('GPS på gulvvaskemaskinen for at registrere kørsel og forbrug.', hasGps),

    ...filterArrayItem(
      'Sensor i området med automatisk sortering for at kontrollere støvniveauet.',
      hasSensor
    ),
  ]);
  if (hasMeasurements) {
    addHeader('Procedurer:');
    addList([
      'Dag 1: Før-målinger og foto af gulve/området, instruktion af personale.',
      'Efter 30 dage med AKS Støvbinder: Efter-målinger og foto af gulve/området, gennemgang af rapport.',
    ]);
  }

  addHeader('Hvordan virker det:');
  addList([
    'Skift dit normale gulvvaskemiddel ud med aks2tal Støvbinder (100% biologisk nedbrydelig, ingen mærkningspligtige stoffer og 100% vandopløselig). Vi informerer jeres medarbejdere og sørger for dokumentation.',
    `En medfølgende app til dokumentation af rengøringen ${
      hasSensor
        ? 'og kan benytte vores IoT-løsning til måling af indeklimaet (støv PM10, PM2.5, temperatur, decibel, relativfugtighed, CO2).'
        : ''
    }`,
  ]);

  addHeader('Vores erfaring:');
  addList([
    'aks2tal kan reducere støv og partikler i luften med typisk mere end 60%-80%',
    'Reduktion af patogene bakterier og vira.',
    'Bedre arbejdsmiljø, færre tekniske fejl/service og mindre tid brugt på højderengøring.',
    'Løsningen kræver ingen installationer og erstatter jeres nuværende gulvvaskemiddel.',
  ]);

  // if (hasMeasurements || hasSensor || hasGps) {
  //     addHeader("Priser:");
  //     addList([
  //         // "AKS Støvbinder med Ekstra Skridsikring normalpris: 109 kr. pr. liter",
  //         // ...filterArrayItem(
  //         //     "AKS Støvbinder Fejegranualt normalpris: 15 kr. pr. liter",
  //         //     sweepingGranulesLitersPrYear > 0,
  //         // ),
  //     ]);
  // }

  addHeader('Om aks2tal:');
  addParagraph(
    'aks2tal leverer indeklimaløsninger til logistikbranchen, fødevarelager, kommuner, byggebranchen, foderstofvirksomheder og dækker i alt 120 mio. m² i Danmark.'
  );

  addParagraph(
    'Vedhæftet er en kort introduktion til vores app. Jeg kontakter dig i næste uge for at høre om du har spørgsmål.'
  );
  if (note) {
    addHeader('Yderligere bemærkninger:');
    addParagraph(note);
  }
  // addParagraph("Med venlig hilsen, [Dit navn] aks2tal");
  addSignature();

  doc.save(
    `Tilbud-${offerReceiverCompany.split(' ').join('-')}-${format(new Date(), 'yyyy-MM-dd')}.pdf`
  );
}
