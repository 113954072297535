import { addMonths, endOfWeek, startOfWeek, subMonths } from 'date-fns';
import { firestore } from '../../firebase';
import { useEffect, useState } from 'react';
import { AuditSchedule } from './types';

export default function useCompeltedAuditsByGroup(
  groupId: string,
  currentDate: Date,
  orgIds?: string[]
) {
  const [audits, setIssues] = useState<(AuditSchedule & { orgId: string })[]>([]);
  const [isLoading, setisLoading] = useState(true);
  const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const endOfWeekDate = endOfWeek(currentDate, { weekStartsOn: 1 });
  useEffect(() => {
    let query = firestore.collectionGroup('auditSchedules');
    let unsubscribe: any = null;
    if (!groupId || !currentDate) return;
    try {
      console.log(groupId);
      query = query
        .where('isCompleted', '==', true)
        .where('dueDate', '<=', addMonths(endOfWeekDate, 1))
        .where('dueDate', '>=', subMonths(startOfWeekDate, 1))
        .where('groupId', '==', groupId);
      if (orgIds && orgIds?.length > 0) {
        query = query.where('orgId', 'in', orgIds);
      }

      query = query.orderBy('dueDate', 'desc').limit(100);

      unsubscribe = query.onSnapshot(
        (querySnapshot) => {
          console.log('LOADING....');
          if (!querySnapshot.empty) {
            const list = querySnapshot.docs.map(
              (doc) =>
                ({
                  id: doc.id,
                  ...doc.data(),
                  orgId: doc.ref.parent.parent?.id,
                } as AuditSchedule & { orgId: string })
            );
            setIssues(list);
          } else {
            setIssues([]);
          }
          setisLoading(false);
        },
        (error) => {
          console.error(error);
        }
      );
    } catch (error) {
      console.error(error);
    }
    return () => unsubscribe?.();
  }, [groupId, orgIds, currentDate]);

  return { audits, isLoading };
}
