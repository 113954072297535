import React, { useEffect, useState } from 'react';
import { Card, Modal, Spin } from 'antd';
import { StyleGuide } from '../../../styles/StyleGuide';
import usePipedriveOrg from './usePipedriveOrg';
import usePipedriveOrgDeals from './usePipedriveOrgDeals';

const itemStyle = { display: 'flex', justifyContent: 'space-between' };

export default function PipeModal({ id, onClose }: { id: number | null; onClose: VoidFunction }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loadingOrg, data } = usePipedriveOrg(id);
  const { loadingDeals, deals } = usePipedriveOrgDeals(id);

  useEffect(() => {
    if (id) {
      setIsModalOpen(true);
    }

    return () => {};
  }, [id]);

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <>
      <Modal
        title={data?.name}
        open={isModalOpen}
        // onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        {loadingOrg ? (
          <Spin />
        ) : (
          <>
            <div style={itemStyle}>
              <p>Adresse:</p>
              <p>{data.address}</p>
            </div>
            <div style={itemStyle}>
              <p>Ejer:</p>
              <p>{data.ownerName}</p>
            </div>
            <div style={itemStyle}>
              <p>Salgsprocesser igang:</p>
              <p>{data.openDeals}</p>
            </div>

            <div style={itemStyle}>
              <p>Seneste aktivitet:</p>
              {data.lastActivity && (
                <p style={{ textAlign: 'right' }}>
                  <div>{data.lastActivity.subject}</div>
                  <div style={{ color: StyleGuide.palette.grey700 }}>
                    {data.lastActivity.dueDate}
                    {data.lastActivity.dueTime ? ' - ' + data.lastActivity.dueTime : ''}
                  </div>
                </p>
              )}
            </div>
            <div style={itemStyle}>
              <p>Næste aktivitet:</p>
              {data.nextActivity && (
                <p style={{ textAlign: 'right' }}>
                  <div>{data.nextActivity.subject}</div>

                  <div style={{ color: StyleGuide.palette.grey700 }}>
                    {data.nextActivity.dueDate}
                    {data.nextActivity.dueTime ? ' - ' + data.nextActivity.dueTime : ''}
                  </div>
                </p>
              )}
            </div>
            {loadingDeals ? (
              <Spin />
            ) : (
              <>
                <p style={{ marginBottom: 8 }}>Processer igang:</p>
                {deals?.map((d) => (
                  <div
                    style={{
                      padding: 16,
                      border: `1px solid ${StyleGuide.palette.grey200}`,
                      borderRadius: 16,
                    }}
                  >
                    <b style={{ margin: 0 }}>{d.title}</b>
                    <div style={itemStyle}>
                      <p style={{ margin: 0 }}>Status:</p>
                      <p
                        style={{
                          textAlign: 'right',
                          margin: 0,
                        }}
                      >
                        <div>{d.stage}</div>
                      </p>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
}
