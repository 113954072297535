import { firestore } from '../firebase';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';

export default function useTemplateCount(orgId: string, templateId: string) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const getCount = async () => {
      const coll = query(collection(firestore, `orgs/${orgId}/auditTemplates/${templateId}/tasks`));
      const snapshot = await getCountFromServer(coll);
      setCount(snapshot.data().count);
    };
    if (orgId && templateId) {
      getCount();
    }

    return () => {};
  }, [orgId, templateId]);

  return count;
}
