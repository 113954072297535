import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Button, Tooltip } from 'antd';
import { CaretDownFilled } from '@ant-design/icons';
import Headway from '../components/Headway';
import { getAuth } from '../general/auth';
import MenuHeader from './MenuHeader';
import NotificationCenter from '../components/Notification/NotificationCenter';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../hooks';
import { useIsLaptop } from '../hooks/useIsMobile';
import { PageHeader } from '@ant-design/pro-layout';
import { StyleGuide } from 'styles/StyleGuide';
import { useParams } from 'react-router';
import HeaderTitle from './HeaderTitle';
import AdminOnly, { Aks2talOnly } from 'components/AdminOnly';
import RapportContainer from './RapportContainer';

interface IProps {
  backLink?: string;
  backText?: string;
}

function HeaderBar({ backLink, backText }: IProps) {
  const canView = useAppSelector((state) => getAuth(state.orgs.currentOrg, state.user.details.uid));
  const { orgId } = useParams<{ orgId?: string }>();
  const hasGroups = useAppSelector((state) => state.groups.groups.length > 0);
  const orgTitle = useAppSelector((state) => state.orgs.currentOrg.title);
  const history = useHistory();
  const { t } = useTranslation();
  const isLaptop = useIsLaptop();
  return (
    <PageHeader
      className="site-page-header"
      ghost={false}
      style={{
        backgroundColor: StyleGuide.palette.grey0,
        borderBottom: `1px solid ${StyleGuide.palette.grey200}`,
      }}
      onBack={backLink ? () => history.push(backLink) : undefined}
      title={<HeaderTitle backText={backText} />}
      subTitle={backText && orgTitle}
      extra={
        <>
          {isLaptop && (
            <>
              <RapportContainer buttonType="link" />
              <Aks2talOnly>
                <Link
                  style={{
                    border: `1px solid ${StyleGuide.palette.infoLight}`,
                    borderRadius: 16,
                    padding: '6px 15px',
                  }}
                  to="/agreements"
                >
                  {t('header.agreements')}
                </Link>
              </Aks2talOnly>
              {hasGroups ? (
                <Link
                  style={{
                    border: `1px solid ${StyleGuide.palette.infoLight}`,
                    borderRadius: 16,
                    padding: '6px 15px',
                  }}
                  to="/groups"
                >
                  {t('header.groups')}
                </Link>
              ) : null}

              <Link
                style={{
                  border: `1px solid ${StyleGuide.palette.infoLight}`,
                  borderRadius: 16,
                  // backgroundColor: StyleGuide.palette.infoLight,
                  padding: '6px 15px',
                }}
                to="/depts"
              >
                {t('header.depts')}
              </Link>
              <Link
                style={{
                  border: `1px solid ${StyleGuide.palette.infoLight}`,
                  borderRadius: 16,

                  padding: '6px 15px',
                }}
                to="/issues"
              >
                {t('header.issuesOverview')}
              </Link>
            </>
          )}
          {/* // <AdminAndMichael> */}
          <NotificationCenter />
          {/* // </AdminAndMichael>, */}
          <Headway />
          <Dropdown overlay={<MenuHeader />}>
            <Button
              type="link"
              style={{
                border: `1px solid ${StyleGuide.palette.infoLight}`,
                borderRadius: 16,
              }}
            >
              {t('header.menu')} <CaretDownFilled />
            </Button>
          </Dropdown>
        </>
      }
    />
  );
}

export default HeaderBar;
