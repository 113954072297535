import React from 'react';
import { Upload, message } from 'antd';
import firebase from 'firebase/compat/app';
import { UploadContext } from '../../../general/constants';
import { InboxOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

interface IUploadReportProps {
  context: UploadContext;
  contextId?: string;
}

function UploadReport(props: IUploadReportProps) {
  const { orgId } = useParams<{ orgId: string }>();
  const { t } = useTranslation();
  return (
    <Dragger
      name="file"
      multiple
      fileList={[]}
      // action: "",
      // customRequest={() => {
      //     console.log("UPLOADING");
      // }}
      onChange={(info: any) => {
        const { status } = info.file;
        if (status !== 'uploading') {
          console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      }}
      customRequest={({ onSuccess, onError, file }: any): any => {
        // self.setState({
        //     uploading: true,
        // });
        message.loading(`Uploader fil`);
        firebase
          .storage()
          .ref('orgs/' + orgId + '/reports')
          // @ts-ignore
          .putFiles(
            [file],
            orgId,
            file.name, // TODO: remove (make optional)
            props.context,
            props.contextId || null // TODO: remove (make optional)
          )
          .then(function (metadatas: any) {
            // Get an array of file metadata
            const stats = {
              files: metadatas.map((file: any) => file.metadata.name),
            };
            console.log({ stats });

            // message.loading(`Uploader fil: ${file.metadata.name}`);

            // firebase.firestore().collection(`orgs/${'test'}/issues`).doc('issueId').set(stats)
            // self.setState({
            //     uploading: false,
            // });
            message.success('Fil uploadet');
            onSuccess();
          })
          .catch(function (error: Error) {
            console.log(error);
            onError();
            // self.setState({
            //     uploading: false,
            // });
          });
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">{t('reports.upload_title')}</p>
      <p className="ant-upload-hint">{t('reports.upload_detail')}</p>
    </Dragger>
  );
}
export default UploadReport;
