import React from 'react';
import LogoLoader from './LogoLoader';

export default function PageLoader() {
  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <LogoLoader />
    </div>
  );
}
