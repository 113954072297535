import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message, Tag } from 'antd';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { post } from '../../../general/api';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import { InventoryContextForType, InventoryType } from '../../../modules/inventory/types';
import Message, { Type } from '../../../components/Notification/Message';
import TextArea from 'antd/lib/input/TextArea';
import { InventoryTagsMap } from 'Views/SensorOverview/useInventoryTags';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  wrapperCol: { span: 24 },
};

export default function AddForm({ inventoryTagsMap }: { inventoryTagsMap: InventoryTagsMap }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const orgs = useSelector((state: AppState) =>
    state.orgs.orgs
      .map(({ id, title }: { id: string; title: string }) => ({ id, title }))
      .sort((a: any, b: any) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1))
  );
  const orgMap = useSelector((state: AppState) => state.orgs.orgMap);

  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const addInventoryItem = async (
    values: Partial<InventoryContextForType<InventoryType.SIM_CARDS>>
  ) => {
    // remove key as it will not be store like this

    try {
      Message({
        key: 'addInventory',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      await firebase
        .firestore()
        .collection('inventory')
        .add({
          ...values,
          type: InventoryType.SIM_CARDS,
          createTime: new Date(),
          updateTime: new Date(),
        });
      onClose();
      form.resetFields();
      Message({
        key: 'addInventory',
        message: `Alt blev tilføjet korrekt`,
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'addInventory',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke oprette Simkort, prøv venligst igen`,
      });
    }
  };

  const onClose = () => setVisible(false);

  const options = Object.entries(inventoryTagsMap).map(([id, { title }]) => ({
    label: title,
    value: id,
  }));

  return (
    <>
      <Button size="large" type="primary" onClick={() => setVisible(true)}>
        {
          // TODO: maybe translate?
          'Tilføj Simkort'
        }
      </Button>
      <Modal
        title="Tilføj Simkort"
        open={!!visible}
        confirmLoading={false}
        onCancel={onClose}
        footer={[]}
      >
        <Form form={form} onFinish={addInventoryItem}>
          <FormItem
            {...formItemLayout}
            name="iccid"
            rules={[{ required: true, message: 'Please input device id!' }]}
          >
            <Input type="text" placeholder={t('ICCID')} />
          </FormItem>

          <FormItem {...formItemLayout} name="tags" rules={[{ required: true }]}>
            <Select
              placeholder="Vælg udbyder og priser"
              mode="multiple"
              style={{ width: '100%' }}
              options={options}
            />
          </FormItem>
          <FormItem
            {...formItemLayout}
            name="note"
            rules={[{ required: false, message: 'Please input device id!' }]}
          >
            <TextArea placeholder={t('Note')} />
          </FormItem>
          <FormItem>
            <Button type="primary" block htmlType="submit" loading={false}>
              {'Tilføj'}
            </Button>
          </FormItem>
        </Form>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </Modal>
    </>
  );
}
