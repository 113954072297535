import { useState } from 'react';
import { Layout, Row, Col, Select, Calendar, Spin, Badge, Alert, Typography } from 'antd';
import SideNav from '../../../Layout/SideNav';
import HeaderBar from '../../../Layout/HeaderBar';
import FooterBar from '../../../Layout/FooterBar';
import { FeatureIds } from '../../../modules/featureControl/featuresConfig';
import BlurOverlayContainer from '../../../components/Overlay/BlurOverlayContainer';
import { Issue } from '../../../modules/issues/types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CalendarProps } from 'antd/lib';
import dayjs, { Dayjs } from 'dayjs';
import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import { format, isSameDay } from 'date-fns';
import { setIssueCalendar } from 'modules/issues/actions';
import { getLocaleAntd } from 'general/intl';
import { StyleGuide } from 'styles/StyleGuide';
import Item from './Item';
import MonthCountRender from './MonthCountRender';
import { useTranslation } from 'react-i18next';
import IssuesSearch from '../IssuesSearch';

const { Content } = Layout;

interface Props {
  // maybe change this
  initialDate: Date;
  orgId: string;
  loading: boolean;
  issues: Issue[];
  options: { value: string; label: string }[];
}

function Overview(props: Props) {
  const { t } = useTranslation();
  const intl = useAppSelector((state) => state.user.userDoc.intl);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(dayjs(props.initialDate));

  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const filteredIssues = props.issues.filter((issue) => {
    if (categoryFilter.length === 0) {
      return true;
    }
    return categoryFilter.some((e) => issue.tags.includes(e));
  });
  const locale = getLocaleAntd(intl);
  const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
    setValue(value);
    dispatch(setIssueCalendar({ date: value.toDate(), mode }));
  };

  const onSelect = (value: Dayjs) => {
    setValue(value);
  };

  const dateCellRender = (value: Dayjs) => {
    const issuesThisDay = filteredIssues.filter((issue) =>
      isSameDay(value.toDate(), issue.completeTime?.toDate())
    );
    return (
      <>
        <div style={{ position: 'absolute', top: 4, left: 4 }}>
          {issuesThisDay.length ? (
            <Badge color={StyleGuide.palette.success} count={issuesThisDay.length} />
          ) : null}
        </div>
        <ul className="events" style={{ marginTop: 0 }}>
          {issuesThisDay.map((item: any) => {
            return <Item key={item.id} id={item.id} orgId={props.orgId} issue={item} />;
          })}
        </ul>
      </>
    );
  };

  const monthCellRender = (value: Dayjs) => {
    return (
      <MonthCountRender
        key={format(value.toDate(), 'dd-MM-yyyy')}
        date={value.toDate()}
        orgId={props.orgId}
        categoryFilter={categoryFilter}
      />
    );
  };

  const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    if (info.type === 'month') return monthCellRender(current);
    return info.originNode;
  };

  const handleChange = (value: string[]) => {
    setCategoryFilter(value);
  };

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <Alert
          style={{ paddingLeft: 24 }}
          type="info"
          message={t('issues.overview.banner')}
          banner
        />
        <BlurOverlayContainer featureKey={FeatureIds.ISSUES}>
          <Content style={{ margin: '24px 24px 0' }}>
            <Row gutter={16}>
              <Col md={8} xs={24}>
                <Typography.Title level={3} style={{ margin: 0 }}>
                  {t('issues.overview.pageTitle')}
                </Typography.Title>
              </Col>
              <Col md={10} xs={24}>
                <IssuesSearch searchParams={{ filter_by: 'isCompleted:true' }} />
              </Col>
              <Col md={6} xs={24}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Søg kategorier"
                  value={categoryFilter}
                  onChange={handleChange}
                  options={props.options}
                />
                <div style={{ paddingTop: 24 }} />
              </Col>

              <Col md={24} xs={24}>
                <Spin spinning={props.loading}>
                  <Calendar
                    className="audit-calendar"
                    value={value}
                    // dateCellRender={dateCellRender}
                    onSelect={onSelect}
                    cellRender={cellRender}
                    onPanelChange={onPanelChange}
                    // monthCellRender={monthCellRender}
                    locale={locale}
                  />
                </Spin>
              </Col>
            </Row>
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default Overview;
