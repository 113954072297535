import { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { QRCodeSVG } from 'qrcode.react';
import {
  ArrowLeft,
  Calendar,
  CheckCircle,
  Clock,
  Download,
  MapPin,
  Package,
  PenToolIcon as Tool,
  User,
  AlertCircle,
  CheckSquare,
  XCircle,
  ClipboardList,
  CircleCheckBig,
} from 'lucide-react';
import type { InventoryItem } from './types';
import type { ActivityLog } from './types';
import type { Task } from './types';
import { getActivityLogs, getTasks } from './data';
import { Link } from 'react-router-dom';
import { getCategoryColor } from './components/inventory-table-columns';

interface EquipmentDetailsProps {
  equipment: InventoryItem;
}

const getActivityIcon = (type: ActivityLog['type']) => {
  switch (type) {
    case 'maintenance':
      return <Tool className="h-5 w-5 text-blue-500" />;
    case 'checkout':
      return <Clock className="h-5 w-5 text-yellow-500" />;
    case 'checkin':
      return <CircleCheckBig className="h-5 w-5 text-green-500" />;
    case 'update':
      return <AlertCircle className="h-5 w-5 text-purple-500" />;
    default:
      return <AlertCircle className="h-5 w-5 text-gray-500" />;
  }
};

const getTaskStatusIcon = (status: Task['status']) => {
  switch (status) {
    case 'completed':
      return <CircleCheckBig className="h-5 w-5 text-green-500" />;
    case 'in-progress':
      return <ClipboardList className="h-5 w-5 text-blue-500" />;
    case 'pending':
      return <Clock className="h-5 w-5 text-yellow-500" />;
    case 'cancelled':
      return <XCircle className="h-5 w-5 text-red-500" />;
    default:
      return <AlertCircle className="h-5 w-5 text-gray-500" />;
  }
};

export default function EquipmentDetails({ equipment }: EquipmentDetailsProps) {
  const [activityLogs, setActivityLogs] = useState<ActivityLog[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState({
    activity: true,
    tasks: true,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const logs = await getActivityLogs(equipment.id);
        setActivityLogs(logs);
        setLoading((prev) => ({ ...prev, activity: false }));

        const equipmentTasks = await getTasks(equipment.id);
        setTasks(equipmentTasks);
        setLoading((prev) => ({ ...prev, tasks: false }));
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading({ activity: false, tasks: false });
      }
    }

    fetchData();
  }, [equipment.id]);

  const handleDownloadQR = () => {
    const svg = document.getElementById('equipment-qr-code');
    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = `qr-code-${equipment.id}.png`;
        downloadLink.href = pngFile;
        downloadLink.click();
      };
      img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-2">
        <Link to="/equipment-list" className="inline-flex">
          <Button variant="outline" size="sm">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Tilbage til udstyrsoversigt
          </Button>
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 space-y-6">
          <Card>
            <CardHeader className="pb-2">
              <div className="flex justify-between items-start">
                <div>
                  <CardTitle className="text-2xl">{equipment.name}</CardTitle>
                  <CardDescription>
                    <span className="ml-2 text-sm">
                      ID: <span className="font-mono">{equipment.id}</span>
                    </span>
                  </CardDescription>
                </div>
                <Badge className={getCategoryColor(equipment.category)}>{equipment.category}</Badge>
                {/* <Badge
                  variant={
                    equipment.status === 'Available'
                      ? 'outline'
                      : equipment.status === 'In Use'
                      ? 'secondary'
                      : 'destructive'
                  }
                >
                  {equipment.status}
                </Badge> */}
              </div>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Package className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Serial Number</p>
                      <p className="font-mono">{equipment.serialNumber}</p>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <MapPin className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Location</p>
                      <p>{equipment.location || 'Not specified'}</p>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <Tool className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Manufacturer</p>
                      <p>{equipment.manufacturer || 'Not specified'}</p>
                    </div>
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Purchase Date</p>
                      <p>
                        {equipment.purchaseDate
                          ? new Date(equipment.purchaseDate).toLocaleDateString()
                          : 'Not specified'}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Last Maintenance</p>
                      <p>
                        {equipment.lastMaintenance
                          ? new Date(equipment.lastMaintenance).toLocaleDateString()
                          : 'Not recorded'}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <Calendar className="h-4 w-4 text-muted-foreground" />
                    <div>
                      <p className="text-xs text-muted-foreground">Next Maintenance</p>
                      <p>
                        {equipment.nextMaintenance
                          ? new Date(equipment.nextMaintenance).toLocaleDateString()
                          : 'Not scheduled'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {equipment.status === 'In Use' && (
                <div className="mt-4 p-3 bg-muted rounded-md">
                  <h3 className="font-medium mb-2">Currently Checked Out</h3>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="flex items-center gap-2">
                      <User className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <p className="text-xs text-muted-foreground">Checked Out To</p>
                        <p>{equipment.checkedOutTo}</p>
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <p className="text-xs text-muted-foreground">Check Out Date</p>
                        <p>
                          {equipment.checkOutDate
                            ? new Date(equipment.checkOutDate).toLocaleDateString()
                            : 'N/A'}
                        </p>
                      </div>
                    </div>

                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <p className="text-xs text-muted-foreground">Expected Return</p>
                        <p>
                          {equipment.expectedReturnDate
                            ? new Date(equipment.expectedReturnDate).toLocaleDateString()
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-4">
                <h3 className="font-medium mb-2">Description</h3>
                <p className="text-sm">{equipment.description || 'No description provided.'}</p>
              </div>
            </CardContent>
          </Card>

          {/* <Tabs defaultValue="activity">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="activity">Aktivitet</TabsTrigger>
              <TabsTrigger value="tasks">Linked Tasks</TabsTrigger>
            </TabsList>

            <TabsContent value="activity" className="mt-4">
              <Card>
                <CardHeader>
                  <CardTitle>Activity History</CardTitle>
                  <CardDescription>Recent activity and history for this equipment</CardDescription>
                </CardHeader>
                <CardContent>
                  {loading.activity ? (
                    <div className="py-8 text-center">
                      <p className="text-muted-foreground">Loading activity history...</p>
                    </div>
                  ) : activityLogs.length === 0 ? (
                    <div className="py-8 text-center">
                      <p className="text-muted-foreground">
                        No activity history found for this equipment.
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      {activityLogs.map((log) => (
                        <div
                          key={log.id}
                          className="flex items-start gap-3 pb-4 border-b last:border-0"
                        >
                          <div className="mt-0.5">{getActivityIcon(log.type)}</div>
                          <div className="flex-1">
                            <div className="flex justify-between">
                              <p className="font-medium">{log.description}</p>
                              <p className="text-sm text-muted-foreground">
                                {new Date(log.date).toLocaleDateString()}
                              </p>
                            </div>
                            <p className="text-sm text-muted-foreground">By {log.user}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="tasks" className="mt-4"> */}
          <Card>
            <CardHeader>
              <CardTitle>Opgaver</CardTitle>
              <CardDescription>Opgaver relateret til dette udstyr</CardDescription>
            </CardHeader>
            <CardContent>
              {loading.tasks ? (
                <div className="py-8 text-center">
                  <p className="text-muted-foreground">Loading tasks...</p>
                </div>
              ) : tasks.length === 0 ? (
                <div className="py-8 text-center">
                  <p className="text-muted-foreground">No tasks found for this equipment.</p>
                </div>
              ) : (
                <div className="space-y-4">
                  {tasks
                    .sort((a, b) => new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime())
                    .map((task) => (
                      <div
                        key={task.id}
                        className="flex items-start gap-3 pb-4 border-b last:border-0"
                      >
                        <div className="mt-0.5">{getTaskStatusIcon(task.status)}</div>
                        <div className="flex-1">
                          <div className="flex justify-between">
                            <p className="font-medium">{task.title}</p>
                            <Badge
                              variant={
                                task.status === 'completed'
                                  ? 'outline'
                                  : task.status === 'in-progress'
                                  ? 'secondary'
                                  : task.status === 'pending'
                                  ? 'default'
                                  : 'destructive'
                              }
                            >
                              {task.status}
                            </Badge>
                          </div>
                          <p className="text-sm mt-1">{task.description}</p>
                          <div className="flex justify-between mt-2">
                            <p className="text-sm text-muted-foreground">
                              Tildelt: {task.assignedTo}
                            </p>
                            <p className="text-sm text-muted-foreground">
                              {task.status === 'completed'
                                ? `Completed: ${new Date(task.completedDate!).toLocaleDateString()}`
                                : `Due: ${new Date(task.dueDate).toLocaleDateString()}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </CardContent>
          </Card>
          {/* </TabsContent>
          </Tabs> */}
        </div>

        <div>
          <Card>
            <CardHeader>
              <CardTitle>Billede af udstyr</CardTitle>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
              <div className="w-full aspect-square relative rounded-md overflow-hidden bg-muted mb-4">
                <img
                  src={equipment.image || '/placeholder.svg?height=300&width=300'}
                  alt={equipment.name}
                  className="object-cover"
                />
              </div>
            </CardContent>
          </Card>

          <Card className="mt-6">
            <CardHeader>
              <CardTitle>QR Code</CardTitle>
              <CardDescription>Kan skannes og komme direkte til denne side</CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col items-center">
              <QRCodeSVG
                id="equipment-qr-code"
                value={`${typeof window !== 'undefined' ? window.location.origin : ''}/equipment/${
                  equipment.id
                }`}
                size={200}
              />
              <Button variant="outline" className="mt-4" onClick={handleDownloadQR}>
                <Download className="mr-2 h-4 w-4" />
                Download QR Code
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
