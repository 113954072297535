import type React from 'react';
import { useState, useEffect } from 'react';
import { Calendar, Check, ChevronsUpDown, Trash } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from 'components/ui/sheet';
import { Label } from 'components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { cn } from 'lib/utils';

const responsiblePersons = [
  { value: '6Wn1HX0MFTmBxEN6I1HwdtEMSCe8', label: 'Chris Bagger' },
  { value: '6Wn1HX0MFTmBxEN6I1HwdtEMSCe8', label: 'Jens Nissen' },
  { value: '6Wn1HX0MFTmBxEN6I1HwdtEMSCe8', label: 'Leif Sørensen' },
  // { value: 'alice-williams', label: 'Alice Williams' },
  // { value: 'charlie-brown', label: 'Charlie Brown' },
];

export default function ResponsiblePicker({
  value,
  onChange,
  disabled,
}: {
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          size="sm"
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value
            ? responsiblePersons.find((person) => person.value === value)?.label
            : 'Vælg ansvarlig...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full min-w-[300px] p-0">
        <Command>
          <CommandInput placeholder="Søg efter person..." />
          <CommandList>
            <CommandEmpty>Ingen match</CommandEmpty>
            <CommandGroup>
              {responsiblePersons.map((person) => (
                <CommandItem
                  key={person.value}
                  value={person.value}
                  onSelect={() => {
                    onChange(person.value);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === person.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                  {person.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
