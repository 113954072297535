import { Product, OrgProduct } from './types';
import {
  PRODUCTS_FETCH_REQUESTED,
  PRODUCTS_FETCH_SUCCEEDED,
  PRODUCTS_CREATE_REQUESTED,
  PRODUCTS_CREATE_SUCCEEDED,
  PRODUCTS_UPDATE_REQUESTED,
  PRODUCTS_UPDATE_SUCCEEDED,
  PRODUCTS_DELETE_REQUESTED,
  PRODUCTS_DELETE_SUCCEEDED,
  ORG_PRODUCTS_FETCH_REQUESTED,
  ORG_PRODUCTS_FETCH_SUCCEEDED,
  ORG_PRODUCTS_CREATE_REQUESTED,
  ORG_PRODUCTS_CREATE_SUCCEEDED,
  ORG_PRODUCTS_UPDATE_REQUESTED,
  ORG_PRODUCTS_UPDATE_SUCCEEDED,
  ORG_PRODUCTS_DELETE_REQUESTED,
  ORG_PRODUCTS_DELETE_SUCCEEDED,
} from './types';

export function fetchProducts() {
  return {
    type: PRODUCTS_FETCH_REQUESTED,
    payload: {},
  };
}

export function fetchProductsSuccess(
  // @ts-ignore
  productsList: firebase.firestore.QuerySnapshot
): { type: string; payload: { products: Product[] } } {
  const products = productsList.docs.map(
    // @ts-ignore
    (product: firebase.firestore.DocumentData) => ({
      ...product.data(),
      id: product.id,
    })
  );
  return {
    type: PRODUCTS_FETCH_SUCCEEDED,
    payload: {
      products,
    },
  };
}

export function addProduct(title: string) {
  return {
    type: PRODUCTS_CREATE_REQUESTED,
    payload: {
      title,
    },
  };
}

export function addProductSuccess() {
  return {
    type: PRODUCTS_CREATE_SUCCEEDED,
    payload: {},
  };
}

export function updateProduct({ orderId, data }: { orderId: string; data: Partial<Product> }) {
  return {
    type: PRODUCTS_UPDATE_REQUESTED,
    payload: {
      orderId,
      data,
    },
  };
}

export function updateProductSuccess() {
  return {
    type: PRODUCTS_UPDATE_SUCCEEDED,
    payload: {},
  };
}

export function deleteProduct(orderId: string) {
  return {
    type: PRODUCTS_DELETE_REQUESTED,
    payload: {
      orderId,
    },
  };
}

export function deleteProductSuccess() {
  return {
    type: PRODUCTS_DELETE_SUCCEEDED,
    payload: {},
  };
}

export function fetchOrgProducts(orgId: string) {
  return {
    type: ORG_PRODUCTS_FETCH_REQUESTED,
    payload: {
      orgId,
    },
  };
}

export function fetchOrgProductsSuccess(
  // @ts-ignore
  productsList: firebase.firestore.QuerySnapshot
): { type: string; payload: { products: OrgProduct[] } } {
  const products = productsList.docs.map(
    // @ts-ignore
    (product: firebase.firestore.DocumentData) => ({
      ...product.data(),
      id: product.id,
    })
  );
  return {
    type: ORG_PRODUCTS_FETCH_SUCCEEDED,
    payload: {
      products,
    },
  };
}

export function addOrgProduct(orgId: string, productId: string, title: string) {
  return {
    type: ORG_PRODUCTS_CREATE_REQUESTED,
    payload: {
      orgId,
      productId,
      title,
    },
  };
}

export function addOrgProductSuccess() {
  return {
    type: ORG_PRODUCTS_CREATE_SUCCEEDED,
    payload: {},
  };
}

export function updateOrgProduct({
  orgId,
  productId,
  data,
}: {
  orgId: string;
  productId: string;
  data: Partial<OrgProduct>;
}) {
  return {
    type: ORG_PRODUCTS_UPDATE_REQUESTED,
    payload: {
      orgId,
      productId,
      data,
    },
  };
}

export function updateOrgProductSuccess() {
  return {
    type: ORG_PRODUCTS_UPDATE_SUCCEEDED,
    payload: {},
  };
}

export function deleteOrgProduct(orgId: string, productId: string) {
  return {
    type: ORG_PRODUCTS_DELETE_REQUESTED,
    payload: {
      orgId,
      productId,
    },
  };
}

export function deleteOrgProductSuccess() {
  return {
    type: ORG_PRODUCTS_DELETE_SUCCEEDED,
    payload: {},
  };
}
