import React, { useState } from 'react';
import { Upload, message, Button } from 'antd';
import firebase from 'firebase/compat/app';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UploadContext } from '../../../general/constants';
import useIsReadonlyPath from 'hooks/useIsReadonlyPath';

// const { Dragger } = Upload;

interface IUploadAuditTemplateFileProps {
  context: UploadContext;
  contextId?: string;
}

function UploadAuditTemplateFile(props: IUploadAuditTemplateFileProps) {
  const isReadOnly = useIsReadonlyPath();
  const { orgId } = useParams<{ orgId: string }>();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const uploadField = ({ onSuccess, onError, file }: any): any => {
    // self.setState({
    //     uploading: true,
    // });
    setLoading(true);

    firebase
      .storage()
      .ref('orgs/' + orgId + '/auditTemplates')
      // @ts-ignore
      .putFiles(
        [file],
        orgId,
        file.name, // TODO: remove (make optional)
        props.context,
        props.contextId || null // TODO: remove (make optional)
      )
      .then(function (metadatas: any) {
        // Get an array of file metadata
        const stats = {
          files: metadatas.map((file: any) => file.metadata.name),
        };

        // firebase.firestore().collection(`orgs/${'test'}/issues`).doc('issueId').set(stats)
        // self.setState({
        //     uploading: false,
        // });
        message.success(t('Fil uploadet'));
        setLoading(false);
        onSuccess();
      })
      .catch(function (error: Error) {
        console.log(error);
        setLoading(false);
        onError();
        // self.setState({
        //     uploading: false,
        // });
      });
  };

  const handleChange = (info: any) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(t(`${info.file.name} file uploaded successfully.`));
    } else if (status === 'error') {
      message.error(t(`${info.file.name} file upload failed.`));
    }
  };

  return (
    <Upload
      name="file"
      showUploadList={false}
      // multiple
      // action: "",
      // customRequest={() => {
      //     console.log("UPLOADING");
      // }}
      onChange={handleChange}
      customRequest={uploadField}
    >
      <Button disabled={isReadOnly} icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>
        {t('Upload instruktion')}
      </Button>
    </Upload>
  );
}
export default UploadAuditTemplateFile;
