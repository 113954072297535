import React from 'react';
import { Card, Statistic } from 'antd';
import { useTranslation } from 'react-i18next';

export default function OperationCard({ isLoading, data }: { isLoading: boolean; data: any }) {
  const { t } = useTranslation();
  return (
    <Card loading={isLoading} title={<div>{t('gsm.operation.card_title')}</div>} bordered={false}>
      {data?.pumpe_total && (
        <div>
          <Statistic
            title={t('gsm.operation.pump_title')}
            value={data?.pumpe_total}
            groupSeparator="."
            // prefix={<LikeOutlined />}
            suffix={<div style={{ fontSize: 16 }}>{t('gsm.operation.minutes')}</div>}
          />
        </div>
      )}
      {data?.luft_timer && (
        <div>
          <Statistic
            title={t('gsm.operation.air_title')}
            value={data.luft_timer}
            groupSeparator="."
            // prefix={<LikeOutlined />}
            suffix={<div style={{ fontSize: 16 }}>{t('gsm.operation.minutes')}</div>}
          />
        </div>
      )}
      {/* <div>
                <Statistic
                    title={t("gsm.operation.compressor_title")}
                    value={data?.kompressor_drift_tid}
                    // prefix={<LikeOutlined />}
                    suffix={
                        <div style={{ fontSize: 16 }}>{t("gsm.operation.minutes")}</div>
                    }
                />
            </div> */}
    </Card>
  );
}
