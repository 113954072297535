import { AuditMatrix } from './components/AuditMatrix';
import Legend from './legend';

export default function AuditOverview() {
  return (
    <main className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Audit oversigt</h1>
      <Legend />
      <AuditMatrix />
    </main>
  );
}
