import { useState } from 'react';
import firebase from 'firebase/compat/app';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import confirmModal from 'general/confirmModal';
import Message, { Type } from 'components/Notification/Message';

export default function useDeleteTemplate({
  orgId,
  templateId,
}: {
  orgId: string;
  templateId: string;
}) {
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const confirmDeleteItem = () => {
    confirmModal({
      title: t('audit.templates.confirmDelete.title'),
      onOk: async () => {
        try {
          setloading(true);
          if (!orgId || !templateId) {
            throw new Error(
              `[AUDIT_TEMPLATE_DELETE]: Missing orgId=${orgId} or templateId=${templateId}`
            );
          }
          await firebase
            .firestore()
            .collection(`orgs/${orgId}/auditTemplates`)
            .doc(templateId)
            .delete();
        } catch (error) {
          Message({
            key: 'deleteAuditTemplate',
            type: Type.ERROR,
            message: `Der skete en fejl`,
          });
          console.error(error);
        } finally {
          setloading(false);
        }
      },
    });
  };
  return {
    loading,
    confirmDeleteItem,
  };
}
