import React from 'react';
import { Col, Card, Dropdown } from 'antd';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import { FolderOutlined, MenuOutlined } from '@ant-design/icons';
import { DirectoryMenu } from './DirectoryMenu';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';

const { Paragraph } = Typography;
const { Meta } = Card;

interface IDirectoryProps {
  id: string;
  orgId: string;
  title: string;
  // @ts-ignore
  createTime: firebase.firestore.Timestamp;
  path: string;
  contentType: string;
  files?: string[];
  updateDirectory: ({
    orgId,
    directoryId,
    data: { title },
  }: {
    orgId: string;
    directoryId: string;
    data: { title?: string; isDeleted?: boolean };
  }) => void;
}

// @ts-ignore
export function Directory({
  id,
  orgId,
  title,
  createTime,
  updateDirectory,
  files,
}: // path,
IDirectoryProps) {
  const { t } = useTranslation();
  const intl = useSelector((state: AppState) => state.user.userDoc.intl);
  const cardCover = (
    <Link to={`/orgs/${orgId}/reports/${id}`} style={{ textAlign: 'center' }}>
      <FolderOutlined
        style={{ marginTop: 20, fontSize: 100 }}
        // @ts-ignore
        theme="twoTone"
      />
      <div>
        {t('reports.folder_files')}: {files?.length ?? 0}
      </div>
    </Link>
  );

  const cardExtra = (
    <Dropdown
      overlay={<DirectoryMenu directoryId={id} orgId={orgId} updateDirectory={updateDirectory} />}
    >
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {t('reports.folder_menu')} <MenuOutlined />
      </a>
    </Dropdown>
  );

  const cardTitle = (
    <Paragraph
      ellipsis
      // @ts-ignore
      editable={{
        onChange: (string: string) => {
          updateDirectory({
            orgId,
            directoryId: id,
            data: { title: string },
          });
        },
      }}
    >
      {title}
    </Paragraph>
  );
  const cardDescription = `${t('reports.folder_createtime', {
    date: createTime?.toDate?.(),
  })}`;
  return (
    <Col key={id} md={6} xs={24}>
      <Card hoverable cover={cardCover} extra={cardExtra}>
        <Meta title={cardTitle} description={cardDescription} />
      </Card>
    </Col>
  );
}
