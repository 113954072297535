import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Select } from 'antd';
// import SideNav from "../../Layout/SideNav";
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
// import SensorWidget from "./SensorWidget";
// import IssuesWidget from "./IssuesWidget";
// import StorageWidget from "./StorageWidget";
// import { FeatureIds } from "../../modules/featureControl/featuresConfig";
// import BlurOverlayContainer from "../../components/Overlay/BlurOverlayContainer";
import AuditWithIssuesWidget from './AuditWithIssuesWidget';
import AuditOverdueWidget from './AuditOverdueWidget';
import AuditOnHoldWidget from './AuditOnHoldWidget';
import useOrgsByGroup from 'hooks/useOrgsByGroup';
import AuditCompletedTimeline from './AuditCompletedTimeline';
// import { useParams } from "react-router";
import useQueryParams from 'hooks/useQueryParams';
import AdminOnly from 'components/AdminOnly';
import AuditWidgetUpcoming from './AuditWidgetUpcoming';
import useOrgsBySubGroup from 'hooks/useOrgsBySubGroup';
import RapportContainer from 'Layout/RapportContainer';

const { Content } = Layout;

export default function DashboardSubGroup() {
  const query = useQueryParams();
  const subGroupId = query.get('subGroupId');
  //"RuXfdQ77eGHqAsQSHK5m"; // TEST group "3Ms1n6TSIErIIct17LTa"; //RGS group
  const [orgIds, setOrgIds] = useState<string[]>([]);
  const { orgs, isLoading: orgsLoading } = useOrgsBySubGroup(subGroupId);
  const options = orgs.map((org) => ({ label: org.title, value: org.id }));
  // const features = useSelector((state: AppState) => state.features.featuresMap);
  const orgMap = orgs.reduce((acc, org) => {
    return {
      ...acc,
      [org.id]: {
        title: org.title,
        members: org.members,
      },
    };
  }, {});
  useEffect(() => {
    if (!orgsLoading) {
      setOrgIds(orgs.map((org) => org.id));
    }
  }, [orgsLoading]);
  if (!subGroupId) return null;
  if (orgIds.length < 1) return null;
  return (
    <>
      <Layout>
        <HeaderBar />

        <Content style={{ margin: '50px 24px 0' }}>
          <Row gutter={[16, 16]}>
            <Col lg={16} md={24} sm={24}></Col>
            <Col lg={8} md={24} sm={24}>
              <div style={{ display: 'flex', gap: 8 }}>
                <div style={{ flexGrow: 1 }}>
                  <Select
                    mode={'multiple'}
                    style={{ width: '100%' }}
                    value={orgIds}
                    options={options}
                    onChange={(newValue: string[]) => {
                      setOrgIds(newValue);
                    }}
                    placeholder={'Vælg afdelinger...'}
                    maxTagCount={'responsive'}
                  />
                </div>
                <RapportContainer buttonType="default" />
              </div>
            </Col>
            <Col lg={6} md={24} sm={24}>
              {/* <SensorWidget /> */}
              <AuditWidgetUpcoming
                orgMap={orgMap}
                // subGroupId={subGroupId}
                orgIds={orgIds}
              />
            </Col>
            <Col lg={6} md={24} sm={24}>
              <AuditWithIssuesWidget orgMap={orgMap} orgIds={orgIds} />
            </Col>
            <Col lg={6} md={24} sm={24}>
              <AuditOnHoldWidget orgMap={orgMap} orgIds={orgIds} />
            </Col>
            <Col lg={6} md={24} sm={24}>
              <AuditOverdueWidget orgIds={orgIds} orgMap={orgMap} />
            </Col>
            <Col lg={24} md={24} sm={24}>
              <AuditCompletedTimeline orgIds={orgIds} orgMap={orgMap} />
            </Col>
            {/* <Col lg={12} md={24} sm={24}>
                            <IssuesWidget />
                        </Col> */}
            {/* <Col lg={8} md={24} sm={24}></Col> */}

            {/* {features?.cleanLogs?.isDisplayed !== false && (
                            <Col lg={12} md={24} sm={24}>
                                <StorageWidget />
                            </Col>
                        )} */}
          </Row>
        </Content>

        <FooterBar />
      </Layout>
    </>
  );
}
