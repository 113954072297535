import { OrdersTable } from './components/orders-table';
import { Layout } from 'antd';
import HeaderBar from 'Layout/HeaderBar';
import FooterBar from 'Layout/FooterBar';
import { useOrders } from './hooks/use-orders';
import Loader from 'components/Loader';

export default function Orders() {
  return (
    <Layout>
      <HeaderBar />
      <Layout.Content style={{ margin: '24px 24px 0' }}>
        <OrdersContainer />
      </Layout.Content>
      <FooterBar />
    </Layout>
  );
}

function OrdersContainer() {
  const { orders, loading } = useOrders();
  if (loading) {
    return (
      <div className="flex flex-col h-full justify-center items-center gap-2">
        <Loader size={48} />
        <p className="text-lg ml-4">Loader ordre...</p>
      </div>
    );
  }
  return <OrdersTable orders={orders} />;
}
// function OrderListContainer(props: any) {
//   useEffect(() => {
//     // props.fetchOrgs(props.uid);
//     props.fetchOrders();

//     return () => {};
//   }, []);

//   return (
//     <OrderList
//       {...props}
//       loading={props.loading}
//       addOrder={({ initials, amount, productId }: any) =>
//         props.addOrder({ initials, amount, productId })
//       }
//       updateOrder={({ orderId, data }: any) => props.updateOrder({ orderId, data })}
//     />
//   );
// }

// function mapOrders(orders: any, orgs: any) {
//   return orders.map((order: any) => ({
//     ...order,
//     org: orgs[order.orgId] && orgs[order.orgId].title,
//   }));
// }

// function mapStateToProps(state: AppState) {
//   return {
//     orders: mapOrders(state.orders.list, state.orgs.orgMap),
//     loading: state.orders.loading,
//     uid: state.user.details.uid,
//   };
// }

// const mapActionsToProps = {
//   fetchOrgs,
//   fetchOrders,
//   updateOrder,
//   addOrder,
//   deleteOrder,
// };

// const withConnect = connect(mapStateToProps, mapActionsToProps);

// export default compose(withConnect, withRouter)(OrderListContainer);
