import React, { useState } from 'react';
import {
  startOfWeek,
  add,
  format,
  getYear,
  getWeek,
  subWeeks,
  isWithinInterval,
  startOfDay,
  endOfDay,
  subDays,
  isSameDay,
  differenceInCalendarDays,
  endOfWeek,
  isBefore,
  isAfter,
} from 'date-fns';
import { Table, List, Tag, Avatar, Button, Card, Typography, Space, Tooltip } from 'antd';
import { CheckCircleOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Colors } from '../../Layout/Colors';
import { useTranslation } from 'react-i18next';
import { groupBy, isEqual } from 'lodash';
import { getInitials } from 'general/initials';
import { Link, useParams } from 'react-router-dom';
import useCompletedAudits from 'modules/auditSchedule/useCompletedAudits';
import { useAppSelector } from 'hooks';

interface Task {
  key: string;
  content: string;
  dueDate: Date;
  startDaysBeforeDueDate?: number;
  needsResolution?: boolean;
  hasOnHoldTasks?: boolean;
}

interface Person {
  key: string;
  name: string;
  tasks: Task[];
}

function getTaskColor(task: Task) {
  if (task.hasOnHoldTasks) {
    return Colors.warning;
  }
  if (task.needsResolution) {
    return Colors.hardWarning;
  }
  return Colors.done;
}

const AuditCompletedTimeline = () => {
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  // Initialize with current date
  const [currentDate, setCurrentDate] = useState(subWeeks(new Date(), 1));
  const { audits, isLoading } = useCompletedAudits(orgId, currentDate);
  const startOfWeekDate = startOfWeek(currentDate, { weekStartsOn: 1 });
  const weekDays = Array.from({ length: 7 }).map((_, index) =>
    add(startOfWeekDate, { days: index })
  );
  const members = useAppSelector((state) => state.orgs.orgMap[orgId]?.members);
  const year = getYear(currentDate);
  const weekNumber = getWeek(currentDate, { weekStartsOn: 1 });

  const navigateWeeks = (amount: number) => {
    setCurrentDate(add(currentDate, { weeks: amount }));
  };

  const auditsByAuditor = groupBy(audits, 'auditor');

  // Define table columns
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 250,
      onCell: (_: Task[], __: any) => {
        return {
          style: {
            zIndex: 100,
          },
        };
      },
      render: (text: string, _: Person) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar alt={text}>{getInitials(text)}</Avatar>
            <div style={{ marginLeft: 8 }}>{text}</div>
          </div>
        );
      },
    },
    ...weekDays.map((day, index) => ({
      title: t('general.dateOfWeekday', { date: day }),
      dataIndex: `day${index + 1}`,
      key: `day${index + 1}`,
      width: 150,
      onCell: (_: Task[], __: any) => {
        return {
          // colSpan: index === 1 ? 1 : 1,
          style: {
            width: 150,
            verticalAlign: 'top',
            padding: 0,
            zIndex: weekDays.length - index,
          },
        };
      },
      render: (_: Task[], r: any) => {
        return {
          colSpan: index === 1 ? 1 : 1,
          children: (
            <div
              key={r.id}
              style={{
                display: 'flex',
                flexDirection: 'column',
                // width: 150,
                marginRight: -1,
              }}
            >
              {r?.tasks

                // ?.filter(
                //     (task: any) =>
                //         task.key === "oWQzD89ijl450eWVmcaw" ||
                //         task.key === "a9uZ0mZUN9CMYlOagQsY" ||
                //         task.key === "nKmKMqg1nroD9ZxW7i4X",
                // )
                .map((task: any) => {
                  const taskStartDate = subDays(task.dueDate, task.startDaysBeforeDueDate || 0);
                  const startDate = startOfDay(taskStartDate);
                  const endDate = endOfDay(task.dueDate);
                  const shouldDisplay = isWithinInterval(day, {
                    start: startDate,
                    end: endDate,
                  });
                  const isStartDateToday = isSameDay(startDate, day);
                  const isDueDateToday = isSameDay(endDate, day);
                  if (!shouldDisplay) {
                    //return null;
                    return (
                      <Tag
                        key={task.key}
                        style={{
                          visibility: 'hidden',
                          marginBottom: 8,
                        }}
                      >
                        hidden
                      </Tag>
                    );
                  }
                  const hasMultipleDays = !isSameDay(startDate, endDate);
                  const taskTooltip = hasMultipleDays ? (
                    <div>
                      <div>{task.content}</div>
                      {t('general.dateTime', {
                        date: taskStartDate,
                      })}{' '}
                      -{' '}
                      {t('general.dateTime', {
                        date: task.dueDate,
                      })}
                    </div>
                  ) : (
                    task.content
                  );
                  return (
                    <Tooltip key={task.key} title={taskTooltip} placement="top">
                      <Link to={`/orgs/${task.orgId}/audit/audits/${task.key}`}>
                        <Tag
                          color={getTaskColor(task)}
                          key={task.key}
                          style={{
                            borderRadius: 0,
                            borderTopLeftRadius: isStartDateToday ? 4 : 0,
                            borderBottomLeftRadius: isStartDateToday ? 4 : 0,
                            borderTopRightRadius: isDueDateToday ? 4 : 0,
                            borderBottomRightRadius: isDueDateToday ? 4 : 0,
                            // position: "absolute",
                            marginTop: 4,
                            marginBottom: 4,
                            // marginBottom: 8,
                            width: `calc(${isDueDateToday ? 100 : 100}%)`,
                            textAlign: isDueDateToday ? 'right' : 'left',
                          }}
                        >
                          <span
                            style={{
                              visibility: isStartDateToday || isDueDateToday ? 'visible' : 'hidden',
                            }}
                          >
                            {task.content}
                          </span>
                        </Tag>
                      </Link>
                    </Tooltip>
                  );
                })}
            </div>
          ),
        };
      },
    })),
  ];

  // Dummy data for table rows (replace with actual data)
  const data: Person[] = Object.entries(auditsByAuditor).map(([key, value]) => {
    // @ts-ignore
    const member = members[key];
    return {
      key: key,
      name: member?.name || member?.email || 'Unknown',
      tasks: value
        .map((audit: any) => {
          return {
            key: audit.id,
            orgId: audit.orgId,
            content: audit.templateTitle,
            dueDate: audit.dueDate.toDate(),
            startDaysBeforeDueDate: audit.startDaysBeforeDueDate,
            needsResolution: audit.needsResolution,
            hasOnHoldTasks: audit.hasOnHoldTasks,
          };
        })
        .sort((a: any, b: any) => {
          // sort by key
          const startDateA = startOfDay(subDays(a.dueDate, a.startDaysBeforeDueDate || 0));
          const startDateB = startOfDay(subDays(b.dueDate, b.startDaysBeforeDueDate || 0));
          if (isBefore(startDateA, startDateB)) return -1;
          if (isAfter(startDateA, startDateB)) return 1;
          return 0;
        }),
    };
  });

  return (
    <Card>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Space
          style={{
            display: 'flex',
            paddingLeft: 0,
            paddingRight: 16,
            paddingTop: 0,
            paddingBottom: 0,
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          <CheckCircleOutlined style={{ display: 'flex', fontSize: 36, color: Colors.done }} />
          {/* <Typography.Title level={5} style={{margin:0}}> */}
          {t('dashboard.auditsCompleted.title')}
          {/* </Typography.Title> */}
        </Space>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            shape="circle"
            size="large"
            icon={<LeftOutlined />}
            onClick={() => navigateWeeks(-1)}
          />

          <div
            style={{
              textAlign: 'center',
              marginLeft: 8,
              marginRight: 8,
              display: 'flex',
              flexDirection: 'column',
              width: 80,
            }}
          >
            <Typography.Text strong style={{ fontSize: 16 }}>
              Uge {weekNumber}
            </Typography.Text>
            <Typography.Text style={{ lineHeight: 1 }} type="secondary">
              ({year})
            </Typography.Text>
          </div>
          <Button
            shape="circle"
            size="large"
            icon={<RightOutlined />}
            onClick={() => navigateWeeks(1)}
          />
        </div>
      </div>
      <Table
        dataSource={data}
        // @ts-ignore
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content' }}
        bordered
      />
    </Card>
  );
};

export default AuditCompletedTimeline;
