'use client';

import * as React from 'react';

import { Progress } from 'components/ui/progress';

export function ProgressBar({ progress }: { progress: number }) {
  //   const [p, setP] = React.useState(progress);

  //   React.useEffect(() => {
  //     const timer = setTimeout(() => setP(progress), 500);
  //     return () => clearTimeout(timer);
  //   }, [progress]);

  return <Progress value={progress} />;
}
