import React, { useState } from 'react';
import useTemplateHasChanges from './useTemplateHasChanges';
import { useParams } from 'react-router';
import { Alert, Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import SyncAuditTemplate from './SyncAuditTemplate';
import { StyleGuide } from 'styles/StyleGuide';
import { CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAppSelector } from 'hooks';

export default function HasChangesContainer() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { orgId, auditTemplateId } = useParams<{
    orgId: string;
    auditTemplateId: string;
  }>();
  const { hasChanges, changesByType /*details*/ } = useTemplateHasChanges({
    orgId,
    auditTemplateId,
  });
  const m = useAppSelector((state) => state.auditTemplates.auditTemplateCategoriesMap);
  console.log({ hasChanges, changesByType });

  if (!hasChanges) return null;
  return (
    <Alert
      message={
        <div style={{ position: 'relative' }}>
          {t('audit.templates.templateHasChanges')}
          <Button
            style={{
              marginLeft: -8,
              cursor: 'pointer',
            }}
            type="link"
            size="small"
            role="button"
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? 'Skjul' : 'Se'} detaljer om ændringer
            <CaretDownOutlined />
          </Button>
          <div
            style={{
              margin: 0,
              padding: expanded ? 8 : 0,
              borderRadius: 4,
              width: 'max-content',
              overflow: 'hidden',
              transition: 'all 0.3s',
              maxHeight: expanded ? 100 : 0,
              fontSize: 12,
              color: StyleGuide.palette.grey700,
              backgroundColor: StyleGuide.palette.infoLight,
            }}
          >
            <div>
              {changesByType.title.hasChanges && (
                <Details
                  title="Opgave titlen blev ændret"
                  before={changesByType.title.before}
                  after={changesByType.title.after}
                />
              )}
              {changesByType.tasks.hasChanges && <div>Opgavelisten blev ændret</div>}
              {changesByType.note.hasChanges && (
                <Details
                  title="Opgave beskrivelsen blev ændret"
                  before={changesByType.note.before}
                  after={changesByType.note.after}
                />
              )}
              {changesByType.guide.hasChanges && (
                <Details
                  title="Opgave instruktionen blev ændret"
                  before={changesByType.guide.before}
                  after={changesByType.guide.after}
                />
              )}
              {changesByType.category.hasChanges && (
                <Details
                  key="category"
                  title="Opgave kategorien blev ændret"
                  before={m[changesByType.category.before as string]?.name}
                  after={m[changesByType.category.after as string]?.name}
                />
              )}
              {changesByType.type.hasChanges && (
                <Details
                  title="Opgave typen blev ændret"
                  before={changesByType.type.before}
                  after={changesByType.type.after}
                />
              )}
            </div>
            {/* <AdminOnly>
                            <DiffJson differences={details} />
                        </AdminOnly> */}
          </div>
        </div>
      }
      type="info"
      showIcon
      closable={false}
      action={
        // <div style={{ display: "flex" }}>
        /* <Button
                        style={{
                            cursor: "pointer",
                        }}
                        role="button"
                        onClick={() => setExpanded(!expanded)}>
                        <CaretDownOutlined /> Se ændringer
                    </Button> */
        <SyncAuditTemplate />
        // </div>
      }
    />
  );
}

function Details({
  title,
  before,
  after,
}: {
  title: string;
  before?: string | null;
  after?: string | null;
}) {
  const style: React.CSSProperties = {
    margin: 0,
    // width: "max-content",
    // whiteSpace: "pre-wrap",
    color: 'red',
    padding: 8,
    borderRadius: 4,
    backgroundColor: '#ffecec',
    textDecoration: 'line-through',
    minHeight: 24,
    minWidth: 200,
  };

  const styleUpdate: React.CSSProperties = {
    margin: 0,
    padding: 8,
    borderRadius: 4,
    // width: "max-content",
    // whiteSpace: "pre-wrap",
    color: 'green', // Green for added, black for unchanged
    backgroundColor: '#eaffea',
    minHeight: 24,
    minWidth: 200,
  };
  return (
    <Tooltip
      overlayInnerStyle={{
        width: 'max-content',
        whiteSpace: 'pre-wrap',
      }}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <div>
            Nuværende opgaver
            <div style={style}>{before}</div>
          </div>
          <div>
            Ændres til ved synk.
            <div style={styleUpdate}>{after}</div>
          </div>
        </div>
      }
      style={{
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
      }}
    >
      <div>
        {title} <QuestionCircleOutlined />
      </div>
    </Tooltip>
  );
}
