import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Modal, message, Tag } from 'antd';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { post } from '../../../general/api';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import {
  InventoryItem,
  InventoryStatus,
  InventoryStatusMap,
  InventoryContextForType,
  InventoryType,
} from '../../../modules/inventory/types';
import Message, { Type } from '../../../components/Notification/Message';

const FormItem = Form.Item;
const Option = Select.Option;
const formItemLayout = {
  wrapperCol: { span: 24 },
};

export default function EditForm({ id, onClose }: { id: string; onClose: () => void }) {
  const [form] = Form.useForm();
  const ipad = useSelector((state: AppState) => state.inventory.inventoryMap[id]);
  const orgs = useSelector((state: AppState) =>
    state.orgs.orgs
      .map(({ id, title }: { id: string; title: string }) => ({ id, title }))
      .sort((a: any, b: any) => (a?.title?.toLowerCase() > b?.title?.toLowerCase() ? 1 : -1))
  );
  const orgMap = useSelector((state: AppState) => state.orgs.orgMap);

  const initialValues = ipad;
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const updateInventoryItem = async (
    values: Partial<InventoryContextForType<InventoryType.IPAD>>
  ) => {
    // remove key as it will not be store like this
    const orgId = values.orgId;
    if (!orgId || !id) return alert('something went wrong');
    delete values.orgId;
    try {
      Message({
        key: 'editInventory',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      await firebase
        .firestore()
        .collection('inventory')
        .doc(id)
        .set(
          {
            ...values,
            org: {
              id: orgId,
              // @ts-ignore
              title: orgMap[orgId]?.title,
            },
            updateTime: new Date(),
          },
          { merge: true }
        );
      onClose();
      Message({
        key: 'editInventory',
        message: `Alt blev opdateret korrekt`,
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'editInventory',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke opdatere iPad, prøv venligst igen`,
      });
    }
  };

  return (
    <>
      <Form
        form={form}
        onFinish={(values) => updateInventoryItem(values)}
        initialValues={initialValues}
      >
        <FormItem
          {...formItemLayout}
          name="serial"
          rules={[{ required: true, message: 'Please input serial!' }]}
        >
          <Input type="text" placeholder={t('inventory.ipad.serial')} />
        </FormItem>
        <FormItem
          {...formItemLayout}
          name="name"
          rules={[{ required: true, message: 'Please input name!' }]}
        >
          <Input type="text" placeholder={t('inventory.ipad.name')} />
        </FormItem>
        <FormItem
          {...formItemLayout}
          name="sim"
          rules={[{ required: true, message: 'Please input name!' }]}
        >
          <Input type="text" placeholder={t('inventory.ipad.sim')} />
        </FormItem>

        <FormItem
          {...formItemLayout}
          style={{ minWidth: '150px' }}
          name="status"
          rules={[{ required: true, message: 'Please select status!' }]}
        >
          <Select placeholder={t('inventory.ipad.status')}>
            <Option value={InventoryStatus.NEEDS_ORDER}>
              <Tag color="red">{InventoryStatusMap[InventoryStatus.NEEDS_ORDER]}</Tag>
            </Option>
            <Option value={InventoryStatus.ORDERED}>
              <Tag color="gold">{InventoryStatusMap[InventoryStatus.ORDERED]}</Tag>
            </Option>
            <Option value={InventoryStatus.IN_STORAGE}>
              <Tag color="orange">{InventoryStatusMap[InventoryStatus.IN_STORAGE]}</Tag>
            </Option>
            <Option value={InventoryStatus.READY}>
              <Tag color="blue">{InventoryStatusMap[InventoryStatus.READY]}</Tag>
            </Option>
            <Option value={InventoryStatus.ASSIGNED}>
              <Tag color="green">{InventoryStatusMap[InventoryStatus.ASSIGNED]}</Tag>
            </Option>
            <Option value={InventoryStatus.RETRIEVE}>
              <Tag color="cyan">{InventoryStatusMap[InventoryStatus.RETRIEVE]}</Tag>
            </Option>
          </Select>
        </FormItem>
        <FormItem
          {...formItemLayout}
          style={{ minWidth: '150px' }}
          name="orgId"
          rules={[{ required: true, message: 'Please select company!' }]}
        >
          <Select
            showSearch
            placeholder="Vælg firma"
            optionFilterProp="children"
            filterOption={(input, option) =>
              // @ts-ignore
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {orgs.map((org: { id: string; title: string }) => (
              <Option value={org.id}>{org.title}</Option>
            ))}
          </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" block htmlType="submit" loading={false}>
            {'Gem'}
          </Button>
        </FormItem>
      </Form>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </>
  );
}
