import { Flex, Table } from 'antd';
import Triangle from './Triangle';
import { useTranslation } from 'react-i18next';

export default function SensorLegend() {
  const { t } = useTranslation();
  return (
    <Flex gap="middle" vertical style={{ padding: 24 }}>
      <h1>{t('faq.sensor.headline')}</h1>
      <Content />
    </Flex>
  );
}

function Content() {
  const { t } = useTranslation();
  const columns = [
    {
      title: t('faq.sensor.table.header.color'),
      dataIndex: 'color',
      key: 'color',
      width: 200,
    },
    {
      title: t('faq.sensor.table.header.explain'),
      dataIndex: 'explainer',
      key: 'explainer',
      width: 200,
    },
    {
      title: t('faq.sensor.table.header.todo'),
      dataIndex: 'todo',
      key: 'todo',
      width: 200,
    },
  ];
  const dataSource = [
    {
      key: '5',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-solid-grey" />
          {t('faq.sensor.table.grey.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.grey.explain'),
          }}
        />
      ),
      todo: <div dangerouslySetInnerHTML={{ __html: t('faq.sensor.table.grey.todo') }} />,
    },
    {
      key: '7',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-solid-white" />
          {t('faq.sensor.table.white.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.white.explain'),
          }}
        />
      ),
      todo: <div dangerouslySetInnerHTML={{ __html: t('faq.sensor.table.white.todo') }} />,
    },
    {
      key: '4',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-solid-yellow" />
          {t('faq.sensor.table.yellow.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.yellow.explain'),
          }}
        />
      ),
      todo: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.yellow.todo'),
          }}
        />
      ),
    },
    {
      key: '6',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-solid-blue" />
          {t('faq.sensor.table.blue.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.blue.explain'),
          }}
        />
      ),
      todo: <div dangerouslySetInnerHTML={{ __html: t('faq.sensor.table.blue.todo') }} />,
    },
    {
      key: '2',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-solid-green" />
          {t('faq.sensor.table.green.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.green.explain'),
          }}
        />
      ),
      todo: <div dangerouslySetInnerHTML={{ __html: t('faq.sensor.table.green.todo') }} />,
    },
    {
      key: '3',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-solid-red" />
          {t('faq.sensor.table.red.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.red.explain'),
          }}
        />
      ),
      todo: <div dangerouslySetInnerHTML={{ __html: t('faq.sensor.table.red.todo') }} />,
    },
    {
      key: '0',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-pulsing-red" />
          {t('faq.sensor.table.redblink.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.redblink.explain'),
          }}
        />
      ),
      todo: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.redblink.todo'),
          }}
        />
      ),
    },
    {
      key: '1',
      color: (
        <Flex gap="small" align="center">
          <Triangle className="triangle-pulsing-green" />
          {t('faq.sensor.table.greenblink.color')}
        </Flex>
      ),
      explainer: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.greenblink.explain'),
          }}
        />
      ),
      todo: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('faq.sensor.table.greenblink.todo'),
          }}
        />
      ),
    },
  ];
  return <Table dataSource={dataSource} columns={columns} pagination={false} />;
}
