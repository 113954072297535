import { useAudit } from './audit-context';
import { Button } from 'components/ui/button';
import SplitScreenModal from './create-audit-modal';
import AuditTable from './audits-table';

export function FinishedAudits() {
  const { setStep } = useAudit();

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Audits og Tilsyn</h2>
        <Button onClick={() => setStep('location')}>Start ny</Button>
      </div>
      <AuditTable />
      <SplitScreenModal />
    </div>
  );
}
