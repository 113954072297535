import { IOrg, Roles } from '../modules/orgs/interfaces';

export function getAuth(org: IOrg, userId: string) {
  if (org.members) {
    return org.members[userId] && org.members[userId].role === Roles.ADMIN;
  }
  return false;
}
export function getAuthExecutiveOrAbove(org: IOrg, userId: string) {
  if (org.members) {
    return (
      org.members[userId] &&
      (org.members[userId].role === Roles.ADMIN ||
        org.members[userId].role === Roles.DEPARTMENT_EXECUTIVE ||
        org.members[userId].role === Roles.SALES)
    );
  }
  return false;
}
