import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { getLiters, useOfferContext } from '../Agreements/Context/OfferContext';
import { useTranslation } from 'react-i18next';
import Message, { Type } from 'components/Notification/Message';
import firebase from 'firebase/compat/app';
import { StyleGuide } from 'styles/StyleGuide';
import { useAppSelector } from 'hooks';
import { OfferStatus } from '../Agreements/Context/offerReducer';

import { AppOffer } from 'hooks/useAppOffers';
import { updateOffer } from 'Views/Agreements/Offers/OffersTable';
import ModalOffer from './Modal';
import { getContainersPerYear } from './Estimated';
import { minPricePrLiter } from 'Views/Agreements/Context/offerConfigs';

const FormItem = Form.Item;

const formItemLayout = {
  wrapperCol: { span: 24 },
};

export default function SaveOffer() {
  const { t } = useTranslation();
  const { state, dispatch } = useOfferContext();
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const { litersPrMonth } = getLiters(state.m2);
  const user = useAppSelector((state) => ({
    displayName: state.user.userDoc.displayName,
    email: state.user.userDoc.email,
    uid: state.user.details.uid,
  }));

  const [visible, setVisible] = useState(false);
  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const isPriceTooLow =
    state.finalPrice && state.finalPrice / (state.finalLiter || litersPrMonth) < minPricePrLiter;

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const onFinish = async (values: { title: string; contactAtt: string }) => {
    // remove key as it will not be store like this
    handleOffer(values.title, values.contactAtt);
  };

  async function handleOffer(title?: string, contactAtt?: string) {
    try {
      if (!user.email || !user.uid) {
        throw new Error('User not found');
      }

      setloading(true);
      Message({
        key: 'addAppOffer',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });

      const commonData = {
        isDeleted: false,
        contact: {
          att: state.contactAtt,
        },
        title: state.title,
        offer: {
          m2: state.m2,
          sensors: state.sensors,
          gps: state.gps,
          tablets: state.tablets,
          dustMeasurements: state.dustMeasurements,
          airCleaner: state.airCleaner,
          priceType: state.priceType,
          discount: state.discount,
          // granualt
          sweepingGranules: state.sweepingGranules,
          discountGranules: state.discountGranules,

          // overwrite price
          finalPrice: state.finalPrice || null,
          finalLiter: state.finalLiter || null,

          // delivery option
          deliveryOption: state.deliveryOption,

          // note
          note: state.note,
          internalNote: state.internalNote,
        },
        updateTime: new Date(),
      } as Partial<AppOffer>;

      if (state.offerId) {
        await updateOffer(state.offerId, commonData);
      } else {
        if (!title || !contactAtt) {
          throw new Error('Title or contact is missing');
        }
        await firebase
          .firestore()
          .collection('appOffers')
          .add({
            ...commonData,
            createdBy: {
              email: user.email,
              uid: user.uid,
              displayName: user.displayName || 'intet navn',
            },
            status: OfferStatus.DRAFT,
            createTime: new Date(),
            title: title,
            contact: {
              att: contactAtt,
            },
          });
      }
      onClose();
      form.resetFields();

      Message({
        key: 'addAppOffer',
        message: `Alt blev gemt korrekt`,
      });
      dispatch({ type: 'RESET' });
    } catch (error) {
      console.error(error);
      Message({
        key: 'addAppOffer',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke oprette tilbud, prøv venligst igen`,
      });
    } finally {
      setloading(false);
    }
  }

  const noPriceType = state.priceType === null;
  return (
    <>
      <Tooltip title={noPriceType ? 'Vælg liter eller abonnements pris for at gemme tilbud' : ''}>
        <Button
          disabled={noPriceType}
          type="primary"
          block
          onClick={() => {
            if (isPriceTooLow && state.finalPrice) {
              const pricePrLiter = state.finalPrice / (state.finalLiter || litersPrMonth);
              return alert(
                `Literprisen er for lav, prisen er ${pricePrLiter.toFixed(0)} DKK pr. liter`
              );
            }
            if (state.offerId) {
              return handleOffer();
            }

            setVisible(true);
          }}
        >
          Gem tilbud
        </Button>
      </Tooltip>
      <ModalOffer visible={visible} onClose={onClose} form={form} onFinish={onFinish} />
    </>
  );
}
