import React, { Dispatch, useState, useEffect } from 'react';
import QrScanner from './QrScanner';
import QrCode from './QrCode';
import { AppState } from '../../store';
import { fetchCheckins, fetchCheckouts } from '../../modules/checkin/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { isToday, format } from 'date-fns';
import { fetchOrg } from '../../modules/orgs/actions';
import { CHECKIN_TYPES, DBCheckinShape } from '../../modules/checkin/types';
import { Button, Card, Row, Col } from 'antd';
import { geolocated } from 'react-geolocated';
import AdminOnly from '../../components/AdminOnly';
import { CheckCircleFilled, ExceptionOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

function isCheckedIn(checkins: DBCheckinShape[], setCheckingIn: Dispatch<CHECKIN_TYPES | null>) {
  if (
    checkins &&
    isToday(checkins[0] && checkins[0].createTime && checkins[0].createTime.toDate())
  ) {
    return (
      <Card>
        <div style={{ textAlign: 'center', margin: 20 }}>
          <CheckCircleFilled style={{ fontSize: 100, color: 'limegreen' }} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>Checked in:</div>
          {checkins[0] &&
            checkins[0].createTime &&
            format(checkins[0].createTime.toDate(), 'MMM dd HH:mm')}
        </div>
      </Card>
    );
  }
  return (
    <Card>
      <div style={{ textAlign: 'center', margin: 20 }}>
        <ExceptionOutlined style={{ fontSize: 100, color: 'gray' }} />
      </div>
      <Button type="primary" block onClick={() => setCheckingIn(CHECKIN_TYPES.CHECK_IN)}>
        Check in
      </Button>
    </Card>
  );
}

function isCheckedOut(
  checkins: DBCheckinShape[],
  checkouts: DBCheckinShape[],
  setCheckingIn: Dispatch<CHECKIN_TYPES | null>
) {
  if (
    checkouts &&
    isToday(checkouts[0] && checkouts[0].createTime && checkouts[0].createTime.toDate())
  ) {
    return (
      <Card>
        <div style={{ textAlign: 'center', margin: 20 }}>
          <CheckCircleFilled style={{ fontSize: 100, color: 'limegreen' }} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <div>Checked out:</div>
          {checkouts[0] &&
            checkouts[0].createTime &&
            format(checkouts[0].createTime.toDate(), 'MMM dd HH:mm')}
        </div>
      </Card>
    );
  }
  if (isToday(checkins[0] && checkins[0].createTime && checkins[0].createTime.toDate())) {
    return (
      <Card>
        <div style={{ textAlign: 'center', margin: 20 }}>
          <ExclamationCircleOutlined style={{ fontSize: 100, color: 'gray' }} />
        </div>
        <Button type="primary" block onClick={() => setCheckingIn(CHECKIN_TYPES.CHECK_OUT)}>
          Check out
        </Button>
      </Card>
    );
  }
  return (
    <Card>
      <div style={{ textAlign: 'center', margin: 20 }}>
        <ExclamationCircleOutlined style={{ fontSize: 100, color: 'gray' }} />
      </div>

      <Button type="primary" block disabled onClick={() => setCheckingIn(CHECKIN_TYPES.CHECK_OUT)}>
        Check out
      </Button>
      <div style={{ textAlign: 'center' }}>Du kan ikke tjekke ud før check-in</div>
    </Card>
  );
}

function CheckinContainer({
  fetchCheckins,
  fetchCheckouts,
  fetchOrg,
  match,
  uid,
  orgId,
  checkins,
  checkouts,
  isLoading,
  isGeolocationAvailable,
  isGeolocationEnabled,
  coords,
}: any) {
  const [checkingIn, setCheckingIn] = useState<CHECKIN_TYPES | null>(null);
  useEffect(() => {
    const { orgId } = match.params;
    fetchOrg({ orgId });
    return () => {};
  }, [orgId]);
  useEffect(() => {
    const { orgId } = match.params;

    fetchCheckins({ orgId, uid, type: CHECKIN_TYPES.CHECK_IN });
    fetchCheckouts({ orgId, uid, type: CHECKIN_TYPES.CHECK_OUT });
    return () => {};
  }, [orgId, uid]);

  if (!isGeolocationAvailable) {
    return <div>Your browser does not support Geolocation</div>;
  }
  if (!isGeolocationEnabled) {
    return <div>Geolocation is not enabled</div>;
  }
  if (isLoading) {
    return <div>Loading..</div>;
  }
  if (
    checkingIn === CHECKIN_TYPES.CHECK_IN &&
    !isToday(checkins[0] && checkins[0].createTime && checkins[0].createTime.toDate())
  ) {
    return (
      <div>
        {/* @ts-ignore */}
        <QrScanner type={checkingIn} coordindates={coords} />
      </div>
    );
  }
  if (
    checkingIn === CHECKIN_TYPES.CHECK_OUT &&
    !isToday(checkouts[0] && checkouts[0].createTime && checkouts[0].createTime.toDate())
  ) {
    return (
      <div>
        {/* @ts-ignore */}
        <QrScanner type={checkingIn} coordindates={coords} />
      </div>
    );
  }

  return (
    <>
      <Row gutter={16}>
        <Col span={12}>{isCheckedIn(checkins, setCheckingIn)}</Col>
        <Col span={12}>{isCheckedOut(checkins, checkouts, setCheckingIn)}</Col>
      </Row>
      <AdminOnly>
        <div style={{ display: 'flex', width: 300, flexDirection: 'row' }}>
          <QrCode orgId={orgId} value={true} />
          <QrCode orgId={orgId} value={false} />
        </div>
      </AdminOnly>
      Seneste checkin:
      {checkins.map((checkin: DBCheckinShape) => (
        <>
          <div>{checkin.displayName}</div>
          <div>{checkin.createTime.toDate().toString()}</div>
        </>
      ))}
      Seneste checkout:
      {checkouts.map((checkin: DBCheckinShape) => (
        <>
          <div>{checkin.displayName}</div>
          <div>{checkin.createTime.toDate().toString()}</div>
        </>
      ))}
    </>
  );
}

function mapStateToProps(state: AppState) {
  return {
    // @ts-ignore
    uid: state.user.details.uid,
    checkins: state.checkins.checkins,
    checkouts: state.checkins.checkouts,
    isLoading: state.checkins.loading,
    orgId: state.orgs.currentOrg.id,
  };
}

const mapActionsToProps = {
  fetchCheckins: fetchCheckins,
  fetchCheckouts: fetchCheckouts,
  fetchOrg: fetchOrg,
};
const withConnect = connect(mapStateToProps, mapActionsToProps);
const withGeolocation = geolocated({
  positionOptions: {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity,
  },
});

export default compose(withConnect, withRouter, withGeolocation)(CheckinContainer);
