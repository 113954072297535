import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../firebase';
import { fetchCheckinsSuccess, fetchCheckoutsSuccess } from './actions';
import { CHECKOUT_FETCH_REQUESTED, CHECKOUT_ADD_REQUESTED } from './types';
import {
  CHECKIN_ADD_REQUESTED,
  CHECKIN_TYPES,
  CHECKOUT_FETCH_FAILED,
  CHECKOUT_ADD_FAILED,
} from './types';
import { CHECKIN_FETCH_FAILED, CHECKIN_FETCH_REQUESTED, CHECKIN_ADD_FAILED } from './types';

function* fetchCheckins({
  payload,
}: {
  payload: { orgId: string; uid: string; type: CHECKIN_TYPES.CHECK_IN };
}) {
  const { orgId, uid, type } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection(`checkins`)
        .where('userId', '==', uid)
        .where('type', '==', type)
        .orderBy('createTime', 'desc')
        .limit(1),
      { successActionCreator: fetchCheckinsSuccess }
    );
  } catch (e: any) {
    yield put({ type: CHECKIN_FETCH_FAILED, message: e.message });
  }
}

export function* fetchCheckinsSaga() {
  // @ts-ignore
  yield takeLatest(CHECKIN_FETCH_REQUESTED, fetchCheckins);
}

function* fetchCheckouts({
  payload,
}: {
  payload: { orgId: string; uid: string; type: CHECKIN_TYPES.CHECK_OUT };
}) {
  const { orgId, uid, type } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection(`checkins`)
        .where('userId', '==', uid)
        .where('type', '==', type)
        .orderBy('createTime', 'desc')
        .limit(1),
      { successActionCreator: fetchCheckoutsSuccess }
    );
  } catch (e: any) {
    yield put({ type: CHECKOUT_FETCH_FAILED, message: e.message });
  }
}

export function* fetchCheckoutsSaga() {
  // @ts-ignore
  yield takeLatest(CHECKOUT_FETCH_REQUESTED, fetchCheckouts);
}

function* addCheckin({ payload }: { payload: { orgId: string; data: { createTime: Date } } }) {
  const { orgId, data } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.addDocument,
      `orgs/${orgId}/checkins`,
      data
    );
  } catch (e: any) {
    yield put({ type: CHECKIN_ADD_FAILED, message: e.message });
  }
}

export function* addCheckinsSaga() {
  // @ts-ignore
  yield takeLatest(CHECKIN_ADD_REQUESTED, addCheckin);
}

function* addCheckout({ payload }: { payload: { orgId: string; data: { createTime: Date } } }) {
  const { orgId, data } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.addDocument,
      `orgs/${orgId}/checkins`,
      data
    );
  } catch (e: any) {
    yield put({ type: CHECKOUT_ADD_FAILED, message: e.message });
  }
}

export function* addCheckoutsSaga() {
  // @ts-ignore
  yield takeLatest(CHECKOUT_ADD_REQUESTED, addCheckout);
}
