import { useState } from 'react';
import { Modal, Form, Button, Input } from 'antd';
import UserOutlined from '@ant-design/icons';
import { post } from '../../../general/api';
import Message, { Type } from 'components/Notification/Message';

export default function GroupAccessModal({
  groupId,
  title,
  open,
  handleClose,
}: {
  groupId: string;
  title: string;
  open: boolean;
  handleClose: () => void;
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      setLoading(true);
      const response = await post('addMemberToGroup', { groupId, email });
      if (response.status === 404) {
        return Message({
          key: 'addMemberToGroup',
          message: 'Brugeren blev ikke fundet, venligst tilføj til en afdeling først',
          type: Type.ERROR,
        });
      }
      if (response.status === 403) {
        return Message({
          key: 'addMemberToGroup',
          message: 'Du har ikke adgang',
          type: Type.ERROR,
        });
      }
      if (response.status === 400) {
        return Message({
          key: 'addMemberToGroup',
          message: 'Noget gik galt prøv venligst igen',
          type: Type.ERROR,
        });
      }
      if (response.status === 200) {
        handleClose();
        return Message({
          key: 'addMemberToGroup',
          message: 'Brugeren blev tilføjet korrekt',
          type: Type.SUCCESS,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleCancel = () => {
    console.log('Clicked cancel button');
    handleClose();
  };
  return (
    <Modal title={`Adgang til ${title}`} open={open} footer={[]} onCancel={handleCancel}>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="email"
          rules={[
            { type: 'email', message: 'Indtast en gyldig e-mail' },
            { required: true, message: 'Udfyld e-mail!' },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="E-mail"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            Tilføj
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
