import { useState } from 'react';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import confirmModal from 'general/confirmModal';
import Message, { Type } from 'components/Notification/Message';

export default function useDeleteTemplateTask({
  orgId,
  templateId,
  taskId,
}: {
  orgId: string;
  templateId: string;
  taskId: string;
}) {
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const confirmDeleteItem = () => {
    confirmModal({
      title: t('general.confirmDelete'),
      onOk: async () => {
        try {
          setloading(true);
          if (!orgId || !templateId || !taskId) {
            throw new Error(
              `[AUDIT_TEMPLATE_DELETE]: Missing orgId=${orgId} or templateId=${templateId}`
            );
          }
          await firebase
            .firestore()
            .collection(`orgs/${orgId}/auditTemplates/${templateId}/tasks`)
            .doc(taskId)
            .delete();
        } catch (error) {
          Message({
            key: 'deleteAuditTemplateTask',
            type: Type.ERROR,
            message: `Der skete en fejl`,
          });
          console.error(error);
        } finally {
          setloading(false);
        }
      },
    });
  };
  return {
    loading,
    confirmDeleteItem,
  };
}
