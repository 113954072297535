import { all } from 'redux-saga/effects';

import {
  fetchOrgsSaga,
  fetchOrgSaga,
  addOrgSaga,
  deleteOrgSaga,
  // addMemberSaga,
  updateMembersSaga,
  removeMemberSaga,
  updateOrgSaga,
} from './modules/orgs/saga';
import {
  fetchIssuesSaga,
  fetchIssueSaga,
  updateIssueSaga /*addIssueSaga, deleteIssueSaga*/,
  fetchCompletedIssuesSaga,
} from './modules/issues/saga';
import {
  fetchAuditBaseCategoriesSaga,
  // fetchAuditsSaga,
  fetchAuditSaga,
  updateAuditSaga /*addIssueSaga, deleteIssueSaga*/,
} from './modules/audits/saga';
import {
  fetchFilesSaga,
  deleteFileSaga,
  fetchAuditFilesSaga,
  deleteAuditFileSaga,
} from './modules/files/saga';
import {
  fetchSensorLogsSaga,
  fetchSensorsSaga,
  fetchSensorSaga,
  addSensorSaga,
  fetchSensorMeasuresSaga,
} from './modules/sensors/saga';
import { loginSaga, signOutSaga, sendPasswordResetEmailSaga } from './modules/user/saga';
import { fetchCleanLogsSaga, addCleanLogSaga, updateCleanLogSaga } from './modules/cleanLogs/saga';
import {
  fetchAuditTemplatesSaga,
  fetchAuditTemplateSaga,
  /*addAuditTemplate, deleteAuditTemplate, */ updateAuditTemplateSaga,
  fetchAuditTemplateTasksSaga,
  addAuditTemplateTaskSaga,
  deleteAuditTemplateTaskSaga,
  updateAuditTemplateTaskSaga,
  addAuditTemplateSaga,
  removeAuditTemplateFileSaga,
} from './modules/auditTemplates/saga';
import {
  fetchGroupAuditTemplatesSaga,
  fetchGroupAuditTemplateSaga,
  /*addGroupAuditTemplate, deleteGroupAuditTemplate, */ updateGroupAuditTemplateSaga,
  fetchGroupAuditTemplateTasksSaga,
  addGroupAuditTemplateTaskSaga,
  deleteGroupAuditTemplateTaskSaga,
  updateGroupAuditTemplateTaskSaga,
  addGroupAuditTemplateSaga,
  removeGroupAuditTemplateFileSaga,
} from './modules/groupAuditTemplates/saga';
import {
  fetchAuditSchedulesSaga,
  addAuditScheduleSaga,
  deleteAuditScheduleSaga,
  fetchAuditScheduleSaga,
  updateAuditScheduleSaga,
  updateAuditScheduleTaskSaga,
  fetchInCompleteAuditSchedulesSaga,
  fetchCompleteAuditSchedulesSaga,
  fetchOverdueAuditSchedulesSaga,
} from './modules/auditSchedule/saga';
import {
  fetchCategoriesSaga,
  addCategoriesSaga,
  deleteCategorySaga,
} from './modules/categories/saga';
import {
  fetchOrdersSaga,
  fetchOrgOrdersSaga,
  updateOrderSaga,
  addOrderSaga,
  deleteOrderSaga,
} from './modules/orders/saga';
import {
  fetchProductsSaga,
  updateProductSaga,
  addProductSaga,
  deleteProductSaga,
  fetchOrgProductsSaga,
  addOrgProductSaga,
  deleteOrgProductSaga,
  updateOrgProductSaga,
} from './modules/products/saga';
import { fetchTasksSaga } from './modules/tasks/saga';
import { fetchReportsSaga, updateReportsSaga } from './modules/reports/saga';
import { fetchInventoryGPSSaga } from './modules/inventory/gps/saga';
import { fetchInventoryIpadsSaga } from './modules/inventory/ipad/saga';

import {
  fetchDirectoriesSaga,
  updateDirectorySaga,
  addDirectorySaga,
} from './modules/directories/saga';
import {
  fetchCheckinsSaga,
  addCheckinsSaga,
  fetchCheckoutsSaga,
  addCheckoutsSaga,
} from './modules/checkin/saga';
import { fetchInventoryFoobotSaga } from './modules/inventory/foobot/saga';
import { fetchInventoryEspAqSaga } from './modules/inventory/esp_aq/saga';
import { fetchInvoicesSaga, fetchInvoiceSaga } from './modules/invoices/saga';
import { fetchInventoryPumpsSaga } from './modules/inventory/pumps/saga';
import { fetchFeaturesSaga, updateFeatureSaga } from './modules/featureControl/saga';
import { fetchHourTrackersSaga } from './modules/hourTracker/saga';
import { fetchHourLogsSaga } from './modules/hourLogs/saga';
import { fetchInventorySimcardsSaga } from 'modules/inventory/simcards/saga';

export default function* rootSaga() {
  yield all([
    // ORGS
    fetchOrgsSaga(),
    fetchOrgSaga(),
    addOrgSaga(),
    deleteOrgSaga(),
    updateOrgSaga(),

    // ISSUES:
    fetchIssuesSaga(),
    fetchIssueSaga(),
    updateIssueSaga(),
    fetchCompletedIssuesSaga(),

    // FILES:
    fetchFilesSaga(),
    deleteFileSaga(),
    fetchAuditFilesSaga(),
    deleteAuditFileSaga(),

    // SENSORS:
    fetchSensorLogsSaga(),
    fetchSensorsSaga(),
    fetchSensorSaga(),
    addSensorSaga(),

    // SENSOR MEASURES
    fetchSensorMeasuresSaga(),

    // USER:
    loginSaga(),
    signOutSaga(),
    sendPasswordResetEmailSaga(),

    // MEMBERS:
    // addMemberSaga(),
    updateMembersSaga(),
    removeMemberSaga(),

    // CLEAN LOGS:
    fetchCleanLogsSaga(),
    addCleanLogSaga(),
    updateCleanLogSaga(),

    // AUDIT TEMPLATES
    fetchAuditTemplatesSaga(),
    fetchAuditTemplateSaga(),
    fetchAuditTemplateTasksSaga(),
    addAuditTemplateTaskSaga(),
    deleteAuditTemplateTaskSaga(),
    updateAuditTemplateTaskSaga(),
    updateAuditTemplateSaga(),
    removeAuditTemplateFileSaga(),
    addAuditTemplateSaga(),
    // GROUP AUDIT TEMPLATES
    fetchGroupAuditTemplatesSaga(),
    fetchGroupAuditTemplateSaga(),
    fetchGroupAuditTemplateTasksSaga(),
    addGroupAuditTemplateTaskSaga(),
    deleteGroupAuditTemplateTaskSaga(),
    updateGroupAuditTemplateTaskSaga(),
    updateGroupAuditTemplateSaga(),
    removeGroupAuditTemplateFileSaga(),
    addGroupAuditTemplateSaga(),

    // AUDIT SCHEDULES
    fetchAuditScheduleSaga(),
    fetchAuditSchedulesSaga(),
    // INCOMPLETE AUDITS
    fetchInCompleteAuditSchedulesSaga(),
    fetchOverdueAuditSchedulesSaga(),
    // COMPLETE AUDITS
    fetchCompleteAuditSchedulesSaga(),
    addAuditScheduleSaga(),
    deleteAuditScheduleSaga(),
    updateAuditScheduleSaga(),
    updateAuditScheduleTaskSaga(),

    // AUDITS
    // fetchAuditsSaga(),
    fetchAuditSaga(),
    updateAuditSaga(),
    fetchAuditBaseCategoriesSaga(),

    // CATEGORIES
    fetchCategoriesSaga(),
    addCategoriesSaga(),
    deleteCategorySaga(),

    // ORDERS
    fetchOrdersSaga(),
    fetchOrgOrdersSaga(),
    updateOrderSaga(),
    addOrderSaga(),
    deleteOrderSaga(),

    // PRODUCTS
    fetchProductsSaga(),
    updateProductSaga(),
    addProductSaga(),
    deleteProductSaga(),

    fetchOrgProductsSaga(),
    addOrgProductSaga(),
    deleteOrgProductSaga(),
    updateOrgProductSaga(),

    // TASKS
    fetchTasksSaga(),

    // REPORTS
    fetchReportsSaga(),
    updateReportsSaga(),

    // Checkin
    fetchCheckinsSaga(),
    addCheckinsSaga(),
    fetchCheckoutsSaga(),
    addCheckoutsSaga(),

    // Directries
    fetchDirectoriesSaga(),
    updateDirectorySaga(),
    addDirectorySaga(),

    // inventory
    fetchInventoryIpadsSaga(),
    fetchInventoryGPSSaga(),
    fetchInventoryFoobotSaga(),
    fetchInventoryEspAqSaga(),
    fetchInventoryPumpsSaga(),
    fetchInventorySimcardsSaga(),

    // invoices
    fetchInvoicesSaga(),
    fetchInvoiceSaga(),
    // feature controls
    fetchFeaturesSaga(),
    updateFeatureSaga(),
    // hour trackers
    fetchHourTrackersSaga(),
    // hour logs
    fetchHourLogsSaga(),
  ]);
}
