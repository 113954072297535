import { useParams } from 'react-router';
import { OrdersTable } from '../Orders/components/orders-table';
import { useOrdersById } from '../Orders/hooks/use-orders-byId';
import SideNav from 'Layout/SideNav';
import { Layout } from 'antd';
import HeaderBar from 'Layout/HeaderBar';
import FooterBar from 'Layout/FooterBar';
import BlurOverlayContainer from 'components/Overlay/BlurOverlayContainer';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';
import Loader from 'components/Loader';

function OrderListContainer() {
  const { orgId } = useParams<{ orgId: string }>();
  const { orders, loading } = useOrdersById(orgId);

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.ORDERS}>
          <Layout.Content style={{ margin: '50px 24px 0' }}>
            {loading ? <Loader /> : <OrdersTable orders={orders} orgId={orgId} />}
          </Layout.Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default OrderListContainer;
