import firebase from 'firebase/compat/app';
import { RequestState } from 'interfaces/request';
import { AuditScheduleTask } from 'modules/auditSchedule/types';
import { AuditTemplateTask } from 'modules/auditTemplates/types';
import { useEffect, useState } from 'react';

export interface AuditTask {
  id: string;
  createTime: { toDate: () => Date };
  description: string;
  order: number;
}
export default function useAuditTasks({
  orgId,
  auditTemplateId,
}: {
  orgId: string;
  auditTemplateId: string;
}) {
  const [request, setRequest] = useState<RequestState>(RequestState.INITIAL);
  const [tasks, setTasks] = useState<AuditTemplateTask[]>([]);

  useEffect(() => {
    let unsubscribe: any = null;
    if (!orgId || !auditTemplateId) return;
    unsubscribe = firebase
      .firestore()
      .collection(`orgs`)
      .doc(orgId)
      .collection(`auditTemplates`)
      .doc(auditTemplateId)
      .collection(`tasks`)
      .onSnapshot(
        (query) => {
          if (!query.empty) {
            const list: AuditTemplateTask[] = query.docs.map(
              (doc) =>
                ({
                  id: doc.id,
                  ...doc.data(),
                } as AuditTemplateTask)
            );
            setTasks(list);
            setRequest(RequestState.RESOLVED);
          } else {
            setTasks([]);
            setRequest(RequestState.RESOLVED);
          }
        },
        (error) => {
          console.error(error);
          setRequest(RequestState.REJECTED);
        },
        () => {
          console.log('completed');
        }
      );
    return unsubscribe;
  }, []);
  return {
    auditTasks: sortOnOrderAndCreateTime(tasks),
    loading: request === RequestState.PENDING || request === RequestState.INITIAL,
    hasError: request === RequestState.REJECTED,
  };
}

export function sortOnOrderAndCreateTime(arr: (AuditTemplateTask | AuditScheduleTask)[]) {
  return arr.sort((a, b) => {
    // Use order for sorting if both are defined
    if (a.order !== undefined && b.order !== undefined) {
      if (a.order !== b.order) {
        return a.order - b.order;
      }
    }

    // If a.order is not defined but b.order is, then b comes first
    if (a.order === undefined && b.order !== undefined) {
      return 1;
    }

    // If b.order is not defined but a.order is, then a comes first
    if (b.order === undefined && a.order !== undefined) {
      return -1;
    }

    // If orders are equal or both are undefined, use createTime
    return (
      // ensure createTime is a Date object
      new Date(a.createTime?.toDate?.() || a.createTime).getTime() -
      new Date(b.createTime?.toDate?.() || b.createTime).getTime()
    );
  });
}
