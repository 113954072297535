import type React from 'react';
import { useState, useEffect } from 'react';
import { Calendar, Check, ChevronsUpDown, Trash } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';

import { cn } from 'lib/utils';
import { format } from 'date-fns';
import { Calendar as CalendarComponent } from 'components/ui/calendar';
import locale from 'date-fns/locale/da';

export default function AuditDeadlinePicker({
  deadline,
  setDeadline,
  disabled,
}: {
  deadline?: Date;
  setDeadline: (date: Date | null) => void;
  disabled?: boolean;
}) {
  const handleSelect = (date: Date | undefined) => {
    setDeadline(date ?? null);
  };
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          size={'sm'}
          variant={'outline'}
          className={cn(
            'w-full justify-start text-left font-normal',
            !deadline && 'text-muted-foreground'
          )}
        >
          <Calendar className="mr-0 h-4 w-4" />
          {deadline ? format(deadline, 'PPP', { locale }) : <span>Vælg dato</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <CalendarComponent
          mode="single"
          selected={deadline}
          onSelect={handleSelect}
          locale={locale}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
}
