import React, { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import AnimatedNumber from '../../../components/AnimatedNumber';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { realtimedb } from '../../../firebase';

export default function Realtime({ deviceId }: { deviceId: string }) {
  console.log({ deviceId });
  const [stats, setStats] = useState<any>({});
  const isMobile = useIsMobile();
  useEffect(() => {
    let listener: any = null;
    const ref = realtimedb.ref(`/${deviceId}`);
    if (deviceId) {
      listener = ref.on(
        'value',
        (snapshot) => {
          const data = snapshot.val();
          console.log('RealtimeAQ');
          setStats(data);
          console.log({ data });
        },
        (error) => {
          console.error(error);
        }
      );
    }
    return () => {
      console.log('RealtimeAQ unmount');
      console.log({ listener });

      ref?.off('value', listener);
    };
  }, [deviceId]);
  if (isMobile || !stats?.co2) return null;
  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '8px 16px 0px 16px',
        borderRadius: 8,
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 4,
          }}
        >
          Seneste værdier
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <IconNumber icon="raphael:temp" number={stats?.temperature} />

        <IconNumber icon="carbon:humidity" number={stats?.humidity} />

        {stats?.co2 > 0 && <IconNumber icon="ic:baseline-co2" number={stats?.co2} />}

        <IconNumber icon="carbon:cough" number={stats?.pm_2_5} explainer="PM2.5" />

        <IconNumber icon="carbon:cough" number={stats?.pm_10} explainer="PM10" />

        <IconNumber icon="carbon:light" number={stats?.lightIntensity} />
        <IconNumber icon="ph:ear-bold" number={stats?.decibel} />
      </div>
    </div>
  );
}

function IconNumber({
  icon,
  number = 0,
  explainer,
}: {
  icon: string;
  number: number;
  explainer?: string;
}) {
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'center', marginRight: 8 }}>
        <Icon color="#c0c0c0" style={{ width: 24, height: 24, marginRight: 6 }} icon={icon} />
        <AnimatedNumber num={number} />
      </div>
      {explainer && <p style={{ fontSize: 10, color: '#c0c0c0' }}>{explainer}</p>}
    </div>
  );
}
