import * as React from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';

interface LocationComboboxProps {
  value: string;
  onChange: (value: string) => void;
  existingLocations: string[];
}

export default function LocationCombobox({
  value,
  onChange,
  existingLocations,
}: LocationComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');

  // Combine existing locations with the current input value if it's not already in the list
  const locations = React.useMemo(() => {
    const allLocations = [...existingLocations];
    if (inputValue && !allLocations.includes(inputValue)) {
      allLocations.push(inputValue);
    }
    return allLocations.sort();
  }, [existingLocations, inputValue]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {value || 'Select location...'}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput
            placeholder="Search location..."
            value={inputValue}
            onValueChange={setInputValue}
          />
          <CommandList>
            <CommandEmpty>
              {inputValue ? (
                <div className="py-3 px-4 text-sm">
                  <p>No locations found. Press enter to add "{inputValue}"</p>
                  <Button
                    variant="ghost"
                    className="mt-2 w-full justify-start"
                    onClick={() => {
                      onChange(inputValue);
                      setOpen(false);
                    }}
                  >
                    Add "{inputValue}"
                  </Button>
                </div>
              ) : (
                'No locations found.'
              )}
            </CommandEmpty>
            <CommandGroup>
              {locations.map((location) => (
                <CommandItem
                  key={location}
                  value={location}
                  onSelect={(currentValue) => {
                    onChange(currentValue);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn('mr-2 h-4 w-4', value === location ? 'opacity-100' : 'opacity-0')}
                  />
                  {location}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
