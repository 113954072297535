import { useSelector } from 'react-redux';
import { AppState } from '../store';
import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

export interface CleanLogsOptions {
  id: string;
  title: string;
}
export interface CleanLogsOptionsFormatted {
  value: string;
  label: string;
}

export default function useCleanLogsOptions() {
  const [loading, setloading] = useState(true);
  const [options, setOptions] = useState<CleanLogsOptions[]>([]);
  const orgId = useSelector((state: AppState) => state.orgs.currentOrg?.id);

  useEffect(() => {
    let unsubscribe: any = null;
    if (orgId) {
      unsubscribe = firebase
        .firestore()
        .collection(`orgs`)
        .doc(orgId)
        .collection('cleanLogsOptions')
        .onSnapshot(
          (query) => {
            if (!query.empty) {
              const newOptions = query.docs.map((d) => ({
                id: d.id,
                ...d.data(),
              })) as CleanLogsOptions[];
              console.log({ newOptions });
              setOptions(newOptions);
            } else {
              setOptions([]);
            }
            setloading(false);
          },
          (error) => {
            console.error(error);
            setloading(false);
          }
        );
    }
    return () => unsubscribe?.();
  }, [orgId]);
  return {
    loading,
    options: options.map(({ id, title }) => ({ value: id, label: title })),
  };
}
