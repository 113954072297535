import { Layout } from 'antd';
import HeaderBar from 'Layout/HeaderBar';
import FooterBar from 'Layout/FooterBar';
import LevelChart from './Graph';

const { Content } = Layout;

const devices = [
  { id: '87001', name: 'DSV Højlager' },
  { id: '87002', name: 'DSV Port 99' },
  { id: '85001', name: 'Stena grenå (Koldhal)' },
  { id: '85002', name: 'Stena grenå (SIS)' },
];

function LevelSensorOverviewContainer() {
  return (
    <Layout>
      <HeaderBar />
      <Content
        style={{
          margin: '24px 24px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-2">
          {devices.map((d) => (
            <div className="flex w-full flex-wrap">
              <div className="flex flex-col space-y-1.5">
                <h2 className="text-2xl font-semibold">{d.name}</h2>
                {/* <p className="text-sm text-muted-foreground">
                  Device ID: <span className="font-semibold">{d.id}</span>
                </p> */}
              </div>
              <LevelChart key={d.id} id={d.id} />
            </div>
          ))}
        </div>
      </Content>
      <FooterBar />
    </Layout>
  );
}

export default LevelSensorOverviewContainer;
