import { InventoryItem, InventoryType } from '../types';

export const FOOBOT_INVENTORY_FETCH_REQUESTED = 'FOOBOT_INVENTORY_FETCH_REQUESTED';
export const FOOBOT_INVENTORY_FETCH_SUCCEEDED = 'FOOBOT_INVENTORY_FETCH_SUCCEEDED';
export const FOOBOT_INVENTORY_FETCH_FAILED = 'FOOBOT_INVENTORY_FETCH_FAILED';

export interface FetchFoobotInventorySuccess {
  type: typeof FOOBOT_INVENTORY_FETCH_SUCCEEDED;
  payload: FoobotItem[];
}
export interface FetchFoobotInventory {
  type: typeof FOOBOT_INVENTORY_FETCH_REQUESTED;
  payload: {};
}
export interface FetchFoobotInventoryFailed {
  type: typeof FOOBOT_INVENTORY_FETCH_FAILED;
  payload: {};
}

export interface FoobotItem extends InventoryItem {
  deviceId: string;
  type: InventoryType.FOOBOT;
  orgId?: string;
  orgTitle?: string;
}
