import React from 'react';
import FeatureControl from './index';
import { useAppSelector } from '../../hooks';

function FeatureControlContainer() {
  const { loading } = useAppSelector((state) => ({
    loading: state.orgs.loading,
  }));
  return (
    <React.Fragment>
      <FeatureControl loading={loading} />
    </React.Fragment>
  );
}

export default FeatureControlContainer;
