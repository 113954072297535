import { ModalType } from './reducer';
import {
  OPEN_MODAL,
  CLOSE_MODAL,
  AdvancedTaskModalContentContext,
  AdvancedGroupTaskModalContentContext,
} from './types';

export function openModal({
  type,
  context,
}: {
  type: ModalType;
  context: AdvancedTaskModalContentContext | AdvancedGroupTaskModalContentContext;
}) {
  return {
    type: OPEN_MODAL,
    payload: {
      type,
      context,
    },
  };
}
export function closeModal() {
  return {
    type: CLOSE_MODAL,
    payload: {},
  };
}
