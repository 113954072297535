import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import firebase from '../../firebase';
import { useEffect, useState } from 'react';
import { NotificationType } from 'general/enums';

export default function useNotifications() {
  const [notifications, setnotifications] = useState<any[]>([]);
  const userId = useSelector((state: AppState) => state.user.details.uid);
  const markAsSeen = (notificationId: string) =>
    firebase
      .firestore()
      .collection(`users/${userId}/notifications`)
      .doc(notificationId)
      .update({ seen: true });
  useEffect(() => {
    let unsubscribe: any = null;
    unsubscribe = firebase
      .firestore()
      .collection(`users/${userId}/notifications`)
      .where('seen', '==', false)
      .orderBy('createTime', 'desc')
      .limit(100)
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setnotifications(list);
        }
      });
    return unsubscribe;
  }, [userId]);

  return { notifications, markAsSeen };
}

export interface Notification {
  id: string;
  type: NotificationType;
  createTime: FirebaseFirestore.Timestamp;
  title: string;
  message: string;
  context: {
    orgId: string;
    orgTitle: string;
    sensorId?: string;
    issueId?: string;
    auditId?: string;
  };
}
