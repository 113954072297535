import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import {
  addOrgProduct,
  deleteOrgProduct,
  fetchOrgProducts,
  fetchProducts,
} from '../modules/products/actions';

function useProducts() {
  const products = useSelector((state: AppState) => state.products.list);
  const orgProducts = useSelector((state: AppState) => state.products.orgProductsMap);

  const dispatch = useDispatch();

  const dispatchFetchProducts = () => dispatch(fetchProducts());

  const dispatchFetchOrgProducts = (orgId: string) => dispatch(fetchOrgProducts(orgId));

  const dispatchAddOrgProduct = (orgId: string, productId: string, productTitle: string) =>
    dispatch(addOrgProduct(orgId, productId, productTitle));

  const dispatchDeleteOrgProduct = (orgId: string, productId: string) =>
    dispatch(deleteOrgProduct(orgId, productId));

  return {
    products,
    orgProducts,
    dispatchFetchProducts,
    dispatchFetchOrgProducts,
    dispatchAddOrgProduct,
    dispatchDeleteOrgProduct,
  };
}

export default useProducts;
