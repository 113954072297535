import { AuditSchedule } from 'modules/auditSchedule/types';

/**
 *
 * @param arr StartDate is parsed by selector
 * @returns
 */
export function sortOnStartAndDueDate(arr: (AuditSchedule & { startDate: Date | null })[]) {
  /**
   * Sort only be dueDate now not startDate first then due date
   */
  return arr.sort((a, b) => {
    // const aStartDateTime = a.startDate?.getTime();
    // const bStartDateTime = b.startDate?.getTime();
    const aDueDateTime = a.dueDate?.toDate().getTime();
    const bDueDateTime = b.dueDate?.toDate().getTime();

    // // If startDate of task 'a' is before dueDate of task 'b'
    // if (aStartDateTime !== undefined && aStartDateTime < bDueDateTime) {
    //     return -1;
    // }

    // // If startDate of task 'b' is before dueDate of task 'a'
    // if (bStartDateTime !== undefined && bStartDateTime < aDueDateTime) {
    //     return 1;
    // }

    // Else sort based on dueDate
    if (aDueDateTime > bDueDateTime) {
      return 1;
    } else if (aDueDateTime < bDueDateTime) {
      return -1;
    } else {
      return 0;
    }
  });
}
