import { useEffect, useState } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';
import { firestore } from '../../../firebase';
import { collection, getCountFromServer, query, where } from 'firebase/firestore';
import { Badge } from 'antd';
import { StyleGuide } from 'styles/StyleGuide';
import { useTranslation } from 'react-i18next';

interface Props {
  date: Date;
  orgId: string;
  categoryFilter: string[];
}

export default function MonthCountRender(props: Props) {
  const { t } = useTranslation();
  const [count, setcount] = useState(0);

  useEffect(() => {
    const getCount = async () => {
      try {
        const fromDate = startOfMonth(props.date);
        const toDate = endOfMonth(props.date);
        let coll = null;
        if (props.categoryFilter.length > 0) {
          coll = query(
            collection(firestore, `orgs/${props.orgId}/issues`),
            where('isCompleted', '==', true),
            where('completeTime', '>=', fromDate),
            where('completeTime', '<=', toDate),
            where('tags', 'array-contains-any', props.categoryFilter)
          );
        } else {
          coll = query(
            collection(firestore, `orgs/${props.orgId}/issues`),
            where('isCompleted', '==', true),
            where('completeTime', '>=', fromDate),
            where('completeTime', '<=', toDate)
          );
        }
        const snapshot = await getCountFromServer(coll);
        setcount(snapshot.data().count);
      } catch (err: any) {
        console.error(err);
      }
    };

    if (props.orgId && props.date) {
      getCount();
    }

    return () => {};
  }, [JSON.stringify(props.categoryFilter)]);
  if (count > 0) {
    return (
      <div className="notes-month" style={{ paddingLeft: 8 }}>
        <Badge count={count} color={StyleGuide.palette.success} />
        <span style={{ paddingLeft: 8, color: StyleGuide.palette.successDark }}>
          {t('issues.overview.monthCountText')}
        </span>
      </div>
    );
  }
  return null;
}
