import { useEffect } from 'react';
import { useParams } from 'react-router';
import InventoryView from './index';
import Loader from '../../../components/Loader';

import { IOrg } from '../../../modules/orgs/interfaces';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store';
import { fetchOrgs } from '../../../modules/orgs/actions';
import useInventorySimcards from './useInventorySimcards';
import useDeviceStates from '../../SensorOverview/useDeviceStates';
import useSensorStatus from '../../SensorOverview/useSensorStatus';
import { InventoryType } from 'modules/inventory/types';
import useInventoryTags from 'Views/SensorOverview/useInventoryTags';

interface IProps {
  loading: boolean;
  uid: string;
  email: string;
  orgs: IOrg[];
  addOrg: (uid: string, email: string, title: string) => void;
  updateOrg: ({ orgId, data }: { orgId: string; data: { isDeleted: boolean } }) => void;
}

function SimcardInventoryContainer() {
  const loading = false;

  const { simcards } = useInventorySimcards();
  const { inventoryTagsMap } = useInventoryTags(InventoryType.SIM_CARDS);

  const uid = useSelector((state: AppState) => state.user.details.uid);
  const hasOrgs = useSelector((state: AppState) => state.orgs.orgs?.length > 0);
  // useEffect(() => {
  //     if (!hasOrgs) {
  //         disptach(fetchOrgs(uid));
  //     }
  //     return () => {};
  // }, [uid]);

  if (loading) {
    return <Loader />;
  }

  return <InventoryView simcards={simcards} inventoryTagsMap={inventoryTagsMap} />;
}

export default SimcardInventoryContainer;
