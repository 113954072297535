import { functions } from '../../firebase';

const masterList = [
  {
    id: 'task1',
    label: 'Ledelsessystem',
    subTasks: [{ id: '1', label: 'Er der kendskab til ledelsessystem?', description: '' }],
    category: 'Registreringer/ledelsessystem',
  },
  {
    id: 'task2',
    label: 'Registereringer',
    subTasks: [
      { id: '2', label: 'Er der kendskab til registreringer' },
      { id: '3', label: 'Er der åbne registreringer? (Bortset fra førnævnte)' },
    ],
    category: 'Registreringer/ledelsessystem',
  },
  {
    id: 'task3',
    label: 'Kortlægning',
    subTasks: [{ id: '4', label: 'Hvor findes den? Og er miljøkortlægningen opdateret?' }],
    category: 'Miljø',
  },
  {
    id: 'task4',
    label: 'Oplæring/instruktion',
    subTasks: [
      { id: '5', label: 'Oplæring af nye medarbejdere (fastansatte/timelønnet)' },
      {
        id: '6',
        label:
          'Har medarbejdere den fornødne uddannelse? Tjekkes Site Manager er forpligtet til at afhjælpe afvigelser og observationer inden aftalt deadline. Som udgangspunkt 1 måned efter dato for auditrapport fx kørekort?',
      },
      { id: '7', label: 'Adgang til brugsanvisning til alle maskiner?' },
    ],
    category: 'Arbejdsmiljø',
  },
  {
    id: 'task5',
    label: 'Indkøb kemi (Würth)',
    subTasks: [{ id: '8', label: 'Er der kendskab til liste over kemiprodukter "Bruttoliste"?' }],
    category: 'Kemikalier',
  },
  {
    id: 'task6',
    label: 'Værnemidler',
    subTasks: [{ id: '9', label: 'Kendes procedure 1.17? Personlige værnemidler' }],
    category: 'Udstyr',
  },
  {
    id: 'task7',
    label: 'SDS+APV',
    subTasks: [
      { id: '10', label: 'Findes de nødvendige værnemidler?' },
      { id: '11', label: 'Er der adgang til sikkerhedsdatablade?' },
      { id: '12', label: 'Er der adgang til kemisk APV?' },
    ],
    category: 'APV',
  },
  {
    id: 'task8',
    label: 'APV',
    subTasks: [
      { id: '13', label: 'Kan APV for medarbejdere findes?' },
      { id: '14', label: 'Findes der handleplaner som der ikke er fulgt op på?' },
    ],
    category: 'APV',
  },
  {
    id: 'task9',
    label: 'Beredskabsplan',
    subTasks: [
      { id: '15', label: 'Er beredskabsplan opdateret?' },
      { id: '16', label: 'Er brandslukkere efterset?' },
      {
        id: '17',
        label:
          'Er sikkerhedsoversigt (kort over brandslukkere, mødested osv.) opdateret? Findes skilt over mødested?',
      },
    ],
    category: 'Beredskabsplan',
  },
  {
    id: 'task10',
    label: 'Eftersyn af maskiner og tilbehør',
    subTasks: [{ id: '18', label: 'Er maskiner og andet kørende materiel efterset?' }],
    category: 'Maskiner',
  },
  {
    id: 'task11',
    label: 'Miljømål',
    subTasks: [
      { id: '19', label: 'Er der kendskab til de 4 miljømål?' },
      { id: '20', label: 'Er der opmærksomhed på at nyttiggøre vand' },
      { id: '21', label: 'Er der tiltag indenfor besparelse af el/diesel?' },
      { id: '22', label: 'Er der maskiner som med fordel kan omskiftes til el?' },
    ],
    category: 'Miljømål',
  },
];

export type MasterList = typeof masterList;

async function getRequiredData(): Promise<{
  masterList: MasterList;
  locations: { id: string; title: string }[];
}> {
  // fake api call
  const functionRef = functions.httpsCallable('internalAudit');
  const response: any = await functionRef({
    type: 'REQUIRED_DATA',
  });

  return {
    masterList: response?.data?.masterList,
    locations: response?.data?.locations,
  };
}

type CreatePayload = {
  // auditDate: Date;
  locationId: string;
  taskIds: string[];
};

async function createInternalAudit(payload: CreatePayload): Promise<{
  id: string;
}> {
  const functionRef = functions.httpsCallable('internalAudit');
  const response: any = await functionRef({
    type: 'CREATE',
    ...payload,
  });

  return {
    id: response?.data.id,
  };
}

type CompleteAudit = {
  auditId: string;
};

async function completeInternalAudit(payload: CompleteAudit): Promise<{
  id: string;
}> {
  const functionRef = functions.httpsCallable('internalAudit');
  const response: any = await functionRef({
    type: 'COMPLETE_AUDIT',
    ...payload,
  });

  return {
    id: response?.data.id,
  };
}

export default {
  getRequiredData,
  createInternalAudit,
  completeInternalAudit,
};
