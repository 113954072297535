import { call, put, takeLatest } from 'redux-saga/effects';
import firebase, { reduxSagaFirebase } from '../../../firebase';
import { fetchInventoryIpadsSuccess } from './actions';
import * as actionType from './types';
import { InventoryType } from '../types';

// iPads
function* fetchInventoryIpads() {
  try {
    yield call(
      reduxSagaFirebase.firestore.syncCollection,
      firebase.firestore().collection(`inventory`).where('type', '==', InventoryType.IPAD),
      { successActionCreator: fetchInventoryIpadsSuccess }
    );
  } catch (e: any) {
    yield put({ type: actionType.IPAD_INVENTORY_FETCH_FAILED, message: e.message });
  }
}

export function* fetchInventoryIpadsSaga() {
  // @ts-ignore
  yield takeLatest(actionType.IPAD_INVENTORY_FETCH_REQUESTED, fetchInventoryIpads);
}
