import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Modal, Row, Select, Space, Typography } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';
import Estimated from './Estimated';
import SubscriptionCard from './SubscriptionCard';
import LiterPriceCard from './LiterPriceCard';
import './calc.css';
import { getLiterPrice, getLiters, useOfferContext } from '../Agreements/Context/OfferContext';
import SaveOffer from './SaveOffer';
import { StyleGuide } from 'styles/StyleGuide';
import { fakeAPICall } from 'general/helpers';
import Loader from 'components/Loader';
import LiterPriceCardGranules from './LiterPriceCardGranules';
import ModalOffer from './Modal';
import CustomOfferCard from './CustomOfferCard';
import { deliveryOptionMap } from 'Views/Agreements/Context/offerConfigs';

export default function Calculator() {
  const { state, dispatch } = useOfferContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [rowHover, setRowHover] = useState<number | null>(null);

  const [form2] = Form.useForm();

  const [visible, setVisible] = useState(false);
  const onClose = () => {
    form2.resetFields();
    setVisible(false);
  };

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    form.validateFields();
    return () => {};
  }, []);

  const onFinishEdit = async (values: { title: string; contactAtt: string }) => {
    // remove key as it will not be store like this
    dispatch({
      type: 'UPDATE_MULTIPLE',
      payload: {
        title: values.title,
        contactAtt: values.contactAtt,
      },
    });
    onClose();
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const { litersPrMonth } = getLiters(Number(values.m2));
    const { monthlyLiterPrice } = getLiterPrice(Number(state.m2));
    await fakeAPICall(1000);
    dispatch({
      type: 'UPDATE_MULTIPLE',
      payload: {
        m2: Number(values.m2),
        sensors: Number(values.sensors),
        gps: Number(values.gps),
        tablets: Number(values.tablets),
        sweepingGranules: Number(values.sweepingGranules),
        dustMeasurements: Number(values.dustMeasurements),
        airCleaner: values.airCleaners?.length > 0 ? values.airCleaners[0] : null,
        finalLiter: +litersPrMonth.toFixed(0),
        finalPrice: +monthlyLiterPrice.toFixed(0),
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue(state);
  }, [state.displayOfferForm]);

  if (!state.displayOfferForm) {
    return null;
  }

  // track if the form is dirty from last submit
  console.log({ state });

  const offerTitle = [state.title, state.contactAtt].filter(Boolean).join(' - ');

  return (
    <>
      <Row gutter={48}>
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 16,
              borderBottom: `1px solid ${StyleGuide.palette.grey200}`,
              marginBottom: 24,
              paddingBottom: 16,
            }}
          >
            <Button
              onClick={() => {
                dispatch({ type: 'RESET' });
                // history.back();
              }}
            >
              <ArrowLeftOutlined />
              Tilbage
            </Button>

            <h1 style={{ margin: 0 }}>{offerTitle || 'Nyt tilbud'}</h1>
            {offerTitle && (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  form2.setFieldsValue(state);
                  setVisible(true);
                }}
              />
            )}
          </div>
        </Col>
        <Col span={8}>
          <h4 style={{ marginTop: 0 }}>Tilbudsberegner</h4>
          <Form layout="vertical" form={form} onFinish={onFinish} initialValues={state}>
            <Form.Item label="Total kvadratmeter (m2)" name="m2" required>
              <Input type="number" />
            </Form.Item>

            <h4>Sensorer & hardware</h4>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Form.Item label="Sensors (indeklima)" name="sensors" required>
                <Input type="number" />
              </Form.Item>
              <div style={{ width: 16 }} />
              <Form.Item label="GPS sensorer" name="gps" required>
                <Input type="number" />
              </Form.Item>
              <div style={{ width: 16 }} />
              <Form.Item label="Tablets" name="tablets" required>
                <Input type="number" />
              </Form.Item>
            </div>

            <Form.Item
              label="Antal certificerede støvmålinger (før og efter = 1)"
              name="dustMeasurements"
              required
            >
              <Input type="number" />
            </Form.Item>
            <h4>Fejegranulat</h4>
            <Form.Item
              label="Liter fejegranulat pr. år (leveres af en omgang)"
              name="sweepingGranules"
              required
            >
              <Input type="number" />
            </Form.Item>
            <h4>Levering</h4>
            <Form.Item label="Hvor ofte leveres produkterne" name="deliveryOption">
              <Select
                options={[
                  {
                    value: 1,
                    label: <span>{deliveryOptionMap[1]}</span>,
                  },
                  {
                    value: 2,
                    label: <span>{deliveryOptionMap[2]}</span>,
                  },
                  {
                    value: 3,
                    label: <span>{deliveryOptionMap[3]}</span>,
                  },
                  {
                    value: 4,
                    label: <span>{deliveryOptionMap[4]}</span>,
                  },
                  {
                    value: 5,
                    label: <span>{deliveryOptionMap[5]}</span>,
                  },
                  {
                    value: 6,
                    label: <span>{deliveryOptionMap[6]}</span>,
                  },
                ]}
              />
              {/* <Select<Input.TextArea
                                rows={3}
                                onBlur={e =>
                                    dispatch({
                                        type: "SET_DELIVERY_OPTION",
                                        payload: e.target.value,
                                    })
                                }
                            /> */}
            </Form.Item>
            <h4>Yderligere bemærkninger</h4>
            <Form.Item label="Tilføjes i bunden af tilbudet" name="note">
              <Input.TextArea
                rows={3}
                onBlur={(e) =>
                  dispatch({
                    type: 'SET_NOTE',
                    payload: e.target.value,
                  })
                }
              />
            </Form.Item>
            <h4>Intern note til tilbud (vises IKKE i tilbuddet)</h4>
            <Form.Item
              label="Bruges til at holde styr på tilbud og aftaler med kunden"
              name="internalNote"
            >
              <Input.TextArea
                rows={3}
                onBlur={(e) =>
                  dispatch({
                    type: 'SET_INTERNAL_NOTE',
                    payload: e.target.value,
                  })
                }
              />
            </Form.Item>
            <h4>Luftrenser besparelser pr/md</h4>
            <Form.List name="airCleaners">
              {(fields, { add, remove }) => (
                <div style={{ width: '100%' }}>
                  {fields.map(({ key, name, ...restField }) => (
                    <div key={key}>
                      <div
                        style={{
                          position: 'absolute',
                          right: 24,
                          marginTop: -42,
                        }}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            remove(name);
                            dispatch({
                              type: 'SET_AIR_CLEANER',
                              payload: null,
                            });
                          }}
                        />
                      </div>
                      <Space
                        style={{
                          flexGrow: 1,
                        }}
                      >
                        <Form.Item
                          {...restField}
                          label="Antal luftrensere"
                          name={[name, 'amount']}
                          rules={[
                            {
                              required: true,
                              message: 'Mangler antal luftrensere',
                            },
                          ]}
                        >
                          <Input placeholder="Antal luftrensere" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Leasing stk. pr/md"
                          name={[name, 'leasing']}
                          rules={[
                            {
                              required: true,
                              message: 'Mangler pris for leasing',
                            },
                          ]}
                        >
                          <Input placeholder="Pris for leasing pr/md" />
                        </Form.Item>
                      </Space>
                      <Space
                        style={{
                          flexGrow: 1,
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'power']}
                          label="Elforbrug pr. stk. pr/md"
                          rules={[
                            {
                              required: true,
                              message: 'Mangler elforbrug pr/md pr luftrenser',
                            },
                          ]}
                        >
                          <Input placeholder="Elforbrug pr/md pr luftrenser" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label="Omkostning for nuværende rengøringprodukter"
                          name={[name, 'additionalProducts']}
                          rules={[
                            {
                              required: true,
                              message: 'Mangler antal',
                            },
                          ]}
                        >
                          <Input placeholder="Omkostning for nuværende rengøringprodukter" />
                        </Form.Item>
                      </Space>
                    </div>
                  ))}
                  <Form.Item>
                    {fields.length < 1 && (
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Tilføj luftrenser besparelsesberegning
                      </Button>
                    )}
                  </Form.Item>
                </div>
              )}
            </Form.List>
            <Form.Item>
              <Button type="primary" block htmlType="submit">
                Udregn / opdater
              </Button>
            </Form.Item>
          </Form>
          <Divider />
          <SaveOffer />

          {/* <AdminOnly>
                    DEBUGGER
                    <pre>{JSON.stringify(state, null, 2)}</pre>
                </AdminOnly> */}
        </Col>
        <Col span={16}>
          {loading && (
            <div style={overlayStyle}>
              <Loader size={48} />
            </div>
          )}
          {!loading && state.m2 < 1 && (
            <div style={overlayStyle}>
              <Typography.Title>Vælg antal m2</Typography.Title>
            </div>
          )}
          <CustomOfferCard setRowHover={setRowHover} rowHover={rowHover} />
          <div style={{ height: 24 }}></div>
          <SubscriptionCard setRowHover={setRowHover} rowHover={rowHover} />

          <div style={{ height: 24 }}></div>
          <LiterPriceCard setRowHover={setRowHover} rowHover={rowHover} />
          {state.sweepingGranules > 0 && (
            <>
              <div style={{ height: 24 }}></div>
              <LiterPriceCardGranules />
            </>
          )}

          <div style={{ height: 24 }}></div>
          <Estimated />
        </Col>
      </Row>
      <ModalOffer
        form={form2}
        onFinish={onFinishEdit}
        visible={visible}
        onClose={onClose}
        error={error}
        setError={setError}
      />
    </>
  );
}

export function getHighlight(selectedPrice: number | null, rowHover: number | null, key: number) {
  if (
    rowHover === key ||
    selectedPrice === key
    // these are to match the liter prices
  ) {
    return {};
  }
  if (selectedPrice !== key && selectedPrice !== null) {
    return {
      opacity: 0.2,
    };
  }
  if (rowHover !== 1 && rowHover !== null) {
    return {
      opacity: 0.2,
    };
  }
  return {};
}

const overlayStyle: CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  zIndex: 1000,
  background: 'rgba(255,255,255,0.8)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
