import usePlants from 'hooks/useSensorPlants';
import { Col, Row } from 'antd';
import { useAppSelector } from 'hooks';
import { DeviceType, SensorType } from 'general/enums';
import { PlantCard } from './PlantCard';
import { MobilePlantCard } from './MobilePlantCard';

const testOrgIds = [
  'orgs/BcCBFd1E6q3sll1TROjo/sensors/O3qIENuB224hq4lLiFVD',
  'orgs/BcCBFd1E6q3sll1TROjo/sensors/k0M8YpZnbvhuX8lQWU8k',
  'orgs/zzth0GnROgClx0zXF65o/sensors/Xyb3nWTcCgz0seNZPvuu',
  'orgs/BcCBFd1E6q3sll1TROjo/sensors/9AV9fFfYvyKt2XzlVmPZ',
];

export default function Container() {
  const { plants, loading, error } = usePlants();

  const orgMap = useAppSelector((state) => state.orgs.orgMap);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Something went wrong...</div>;
  }

  const testPlants = plants.filter((plant) => testOrgIds.includes(plant.ref));
  const mobilePlants = plants.filter((plant) => plant.deviceType === DeviceType.MOBILE_PLANT);
  const stationaryPlants = plants.filter(
    (plant) => plant.type === SensorType.GSM_PLANT && !testOrgIds.includes(plant.ref)
  );
  return (
    <Row gutter={8}>
      <Col span={24} style={{ marginBottom: 24 }}>
        <h2>Stationære Anlæg: {stationaryPlants.length}</h2>
      </Col>
      {stationaryPlants.map((plant) => {
        const orgId = plant.ref.split('/')[1];
        const org = orgMap[orgId];
        const lastSeen = plant.lastSeen?._seconds ? plant.lastSeen._seconds * 1000 : null;

        return (
          <Col span={8} style={{ marginBottom: 24 }}>
            {plant.type === SensorType.GSM_PLANT && (
              <PlantCard plant={plant} lastSeen={lastSeen} org={org} />
            )}
            {plant.deviceType === DeviceType.MOBILE_PLANT && (
              <MobilePlantCard plant={plant} lastSeen={lastSeen} org={org} />
            )}
          </Col>
        );
      })}
      <Col span={24} style={{ marginBottom: 24 }}>
        <h2>Mobile Anlæg: {mobilePlants.length}</h2>
      </Col>
      {mobilePlants.map((plant) => {
        const orgId = plant.ref.split('/')[1];
        const org = orgMap[orgId];
        const lastSeen = plant.lastSeen?._seconds ? plant.lastSeen._seconds * 1000 : null;

        return (
          <Col span={8} style={{ marginBottom: 24 }}>
            {plant.type === SensorType.GSM_PLANT && (
              <PlantCard plant={plant} lastSeen={lastSeen} org={org} />
            )}
            {plant.deviceType === DeviceType.MOBILE_PLANT && (
              <MobilePlantCard plant={plant} lastSeen={lastSeen} org={org} />
            )}
          </Col>
        );
      })}
      <Col span={24} style={{ marginBottom: 24 }}>
        <h2>Test App Anlæg: {testPlants.length}</h2>
      </Col>
      {testPlants.map((plant) => {
        const orgId = plant.ref.split('/')[1];
        const org = orgMap[orgId];
        const lastSeen = plant.lastSeen?._seconds ? plant.lastSeen._seconds * 1000 : null;

        return (
          <Col span={8} style={{ marginBottom: 24 }}>
            {plant.type === SensorType.GSM_PLANT && (
              <PlantCard plant={plant} lastSeen={lastSeen} org={org} />
            )}
            {plant.deviceType === DeviceType.MOBILE_PLANT && (
              <MobilePlantCard plant={plant} lastSeen={lastSeen} org={org} />
            )}
          </Col>
        );
      })}
    </Row>
  );
}
