import React, { useEffect } from 'react';
// import { useFirestoreConnect } from "react-redux-firebase";
import { Layout, Row, Col, Table, Card, Badge, Statistic, Tooltip } from 'antd';
import SideNav from '../../../Layout/SideNav';
// import { getGsmPlantData, getSensor } from "../../../modules/sensors/queries";
import HeaderBar from '../../../Layout/HeaderBar';
import { Content } from 'antd/lib/layout/layout';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store';
import { useParams } from 'react-router';
import { fetchSensor } from '../../../modules/sensors/actions';
import useLatestGsmPlantLog, {
  useBaseGsmPlantSchema,
  useGsmPlantLog,
} from './useLatestGsmPlantLog';
import { addDays, format, subDays } from 'date-fns';
import UsageChart from './UsageChart';
import UsageCard from './UsageCard';
import AlertCard from './AlertCard';
import OperationCard from './OperationCard';
import { InfoCircleOutlined } from '@ant-design/icons';
import AdminOnly from 'components/AdminOnly';
import { TFunction, useTranslation } from 'react-i18next';
import AlertLogsContainer from './AlertLogsContainer';
import { useAppSelector } from '../../../hooks';

/**
 * GSM plant schema:
 *  Module
    Timestamp
    // ALARMS
    Nodtryk (BM VM WORD96)
    Alarm (BM DM58)
    Binder Mangel (BM DI8)
    Noedtryk (BM DI4)
    Termo fejl (BM DI5)
    Vand mangel (BM VM DWORD32)
    // mængde
    Mix 1/1000 (BM VM DWORD100)
    Bindermiddel total L. (BM VM DWORD50)
    Vand 1000L (BM VM DWORD104)
    Vand 1L (BM VM DWORD108)
    // tid
    Pumpe drift tid (BM VM WORD56)
    Varme ON total tid (BM VM DWORD92)
    Temp Ude (BM VM WORD90)
    Kompressor drift tid
    // what type is this?
    Service  tryk pumpe
    Service  kompressor
 */

export default function GSMPlantContainer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { orgId, sensorId } = useParams<{ orgId: string; sensorId: string }>();
  // using to temp map to "rapport" = "1" as some are named differently
  const rapportMapping = useAppSelector((state) => state.sensors.currentSensor?.rapportMapping);

  console.log({ rapportMapping });

  const { isLoading, data } = useLatestGsmPlantLog({
    deviceId: sensorId,
    rapportMapping,
  });
  const { isLoading: loadingLogs, data: dataLogs } = useGsmPlantLog({
    deviceId: sensorId,
    rapportMapping,
  });
  // TODO: move schema to DB
  // const { baseSchema } = useBaseGsmPlantSchema();
  useEffect(() => {
    dispatch(fetchSensor({ orgId, sensorId }));
    return () => {};
  }, [orgId, sensorId, dispatch]);

  const source = mapToSchemaArray(dataLogs, schemaMapping, ignoreKeys, t);

  const missingKeysFromLog = Object.keys(data)
    .filter((key) => !schemaMapping[key])
    .filter((key) => !ignoreKeys.includes(key))
    .sort((a, b) =>
      a.localeCompare(b, 'da', {
        sensitivity: 'base',
        numeric: true,
      })
    );

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '50px 24px 0' }}>
          <Row gutter={16}>
            <Col md={12} xs={24} style={{ marginBottom: 20 }}>
              {/* {!phoneNumber && (
                                <>
                                    <AlertCard isLoading={isLoading} data={data} />
                                    <div style={{ marginTop: 20 }} />
                                </>
                            )} */}
              {/* {phoneNumber && ( */}
              {/* <> */}
              <AlertLogsContainer deviceId={sensorId} />
              <div style={{ marginTop: 20 }} />
              {/* </> */}
              {/* )} */}
              <Card bordered={false}>
                <UsageChart
                  // sensorId={sensorId}
                  isLoading={loadingLogs}
                  data={dataLogs.slice(0, 8)}
                />
              </Card>
            </Col>
            <Col md={12} xs={24}>
              <UsageCard isLoading={isLoading} data={data} />
              <div style={{ marginTop: 20 }} />
              <OperationCard isLoading={isLoading} data={data} />
              <div style={{ marginTop: 20 }} />

              {/* {columns && <Table dataSource={source} columns={columns} />} */}
            </Col>
            <Col md={24} xs={24}>
              {/* Write table of schemed */}
              <h1>Log skema</h1>
              {/* <pre>{JSON.stringify(schemed, null, 2)}</pre> */}
              <Card>
                <Table dataSource={source.dataSource} columns={source.columns} pagination={false} />
                <AdminOnly>
                  <pre>
                    Anlæg logs som mangler forklaring:
                    {JSON.stringify(missingKeysFromLog, null, 2)}
                  </pre>
                  {/* <pre>{JSON.stringify(dataLogs[0], null, 2)}</pre> */}
                </AdminOnly>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
}

type SchemaMapping = {
  [key: string]: { title: string; explainer: string };
};

// TODO: make title and explainer translatable keys
const schemaMapping: SchemaMapping = {
  alarm: {
    title: 'Alarmstatus',
    explainer: `Alarm for støvbinder fejl
1 = der er en alarm om støvbinder fejl
0 = der er IKKE en alarm om støvbinder fejl`,
  },
  binder_mangel: { title: 'Binder mangel', explainer: 'Binder shortage' },
  binde_total_l: {
    title: 'Total Støvbinder forbrug',
    explainer: 'Total usage of binder',
  },
  binder_fejl: { title: 'Binderfejl', explainer: 'Binder error' },
  binder_total_l: { title: 'Total binder i L', explainer: 'Total binder in liters' },
  createTime: { title: 'Oprettelsestid', explainer: 'Creation time' },
  errors: { title: 'Fejlliste', explainer: 'List of errors' },
  luft_timer: { title: 'Luft timer', explainer: 'Air timer' },
  module: { title: 'Modulnavn', explainer: 'Module name' },
  mix_1_1000: { title: 'Blanding 1 pr 1000', explainer: 'Mix 1 per 1000' },
  mix: { title: 'Blandingsmængde', explainer: 'Mix quantity' },
  max_flow: { title: 'Maksimal flow', explainer: 'Maximum flow' },
  min_flow: { title: 'Minimum flow', explainer: 'Minimum flow' },
  noedtryk: { title: 'Nødstop', explainer: 'Emergency stop' },
  orgId: { title: 'Organisations ID', explainer: 'Organization identifier' },
  pumpe_total: { title: 'Total pumpe', explainer: 'Total pump' },
  rapport: { title: 'Rapportstatus', explainer: 'Report status' },
  sensorId: { title: 'Sensor ID', explainer: 'Sensor identifier' },
  timestamp: { title: 'Tidsstempel', explainer: 'Timestamp' },
  termo_fejl: { title: 'Termofejl', explainer: 'Thermo error' },
  vand_total_1000_l: {
    title: 'Total vand i 1000 L',
    explainer: 'Total water in 1000 liters',
  },
  vand_fejl: { title: 'Vandfejl', explainer: 'Water error' },
  vand_total_l: { title: 'Total vand i L', explainer: 'Total water in liters' },
};

function mapToSchemaArray(
  data: { [key: string]: any }[],
  mapping: SchemaMapping,
  ignoreKeys: string[] = [],
  t: TFunction<'translation', undefined>
): { columns: { title: string; dataIndex: string; key: string }[]; dataSource: any[] } {
  const columns: {
    title: string;
    dataIndex: string;
    key: string;
    fixed?: 'left';
    render?: any;
    width?: number;
  }[] = [
    {
      title: '',
      dataIndex: 'Field',
      key: 'Field',
      fixed: 'left',
      render: (key: any) => {
        console.log({ key });
        return (
          <div>
            {schemaMapping[key]?.title}{' '}
            <Tooltip
              title={schemaMapping[key]?.explainer}
              overlayInnerStyle={{
                whiteSpace: 'pre-wrap',
              }}
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const dataSource: any[] = [];

  // Gather unique timestamps and add them as columns
  // @ts-ignore
  const timestamps = [...new Set(data.map((item) => item.timestamp))];
  for (const timestamp of timestamps) {
    console.log({ timestamp });
    const date = new Date(timestamp);
    columns.push({
      title: t('date.exactFormat', {
        relativeTime: date,
        dayOfWeek: date,
        dayOfMonth: date,
        month: date,
        time: date,
      }),
      dataIndex: timestamp,
      key: timestamp,

      // render: (v: any) => {
      //     console.log({ v });

      //     return v;
      // },
    });
  }

  // Gather fields and their corresponding values for each timestamp
  const orderedKeys = Object.keys(mapping).filter(
    (key) => !ignoreKeys.includes(key) && key !== 'timestamp'
  );

  for (const key of orderedKeys) {
    if (mapping.hasOwnProperty(key)) {
      console.log({ key });

      const row: any = { Field: key };
      for (const timestamp of timestamps) {
        const item = data.find((d) => d.timestamp === timestamp);
        console.log({ [key]: item });

        row[timestamp] = item ? item[key] : '';
      }
      const hasKey = data.find((d) => d[key]);
      console.log({ [key]: hasKey, key, data });
      console.log({});
      if (hasKey) {
        dataSource.push(row);
      }
    }
  }

  return { columns, dataSource };
}

// function mapToSchemaArray(
//     data: { [key: string]: any }[],
//     mapping: SchemaMapping,
//     ignoreKeys: string[] = [],
// ): { columns: { title: string; dataIndex: string; key: string }[]; dataSource: any[] } {
//     const columns: { title: string; dataIndex: string; key: string }[] = [];
//     const dataSource: any[] = [];

//     // Gather columns from mapping in the defined order
//     const orderedKeys = Object.keys(mapping).filter(key => !ignoreKeys.includes(key));
//     for (const key of orderedKeys) {
//         if (mapping.hasOwnProperty(key)) {
//             columns.push({ title: mapping[key].title, dataIndex: key, key: key });
//         }
//     }

//     // Gather data rows in the defined order
//     for (const item of data) {
//         const row: any = {};
//         for (const key of orderedKeys) {
//             if (mapping.hasOwnProperty(key)) {
//                 row[key] = item[key] !== undefined ? item[key] : "";
//             }
//         }
//         dataSource.push(row);
//     }

//     return { columns, dataSource };
// }

const ignoreKeys = ['id', 'sensorId', 'orgId', 'errors', 'module', 'createTime'];
