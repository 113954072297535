'use client';

import type React from 'react';

import { useEffect, useRef } from 'react';
import { X, Download } from 'lucide-react';
import { Button } from 'components/ui/button';
import { Attachment } from './audit-context';

interface LightboxProps {
  attachment: Attachment;
  onClose: () => void;
}

export function Lightbox({ attachment, onClose }: LightboxProps) {
  const lightboxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.body.style.overflow = 'hidden';

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.body.style.overflow = 'auto';
    };
  }, [onClose]);

  const handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === lightboxRef.current) {
      onClose();
    }
  };

  const renderMedia = () => {
    if (attachment.type.startsWith('image/')) {
      return (
        <img
          src={attachment.download || '/placeholder.svg'}
          alt={attachment.fileName}
          className="max-h-[80vh] max-w-full object-contain"
        />
      );
    } else if (attachment.type.startsWith('video/')) {
      return (
        <video src={attachment.download} controls autoPlay className="max-h-[80vh] max-w-full" />
      );
    }
    return null;
  };

  return (
    <div
      ref={lightboxRef}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 p-4"
      onClick={handleBackdropClick}
    >
      <div className="relative flex max-w-5xl flex-col items-center">
        <div className="absolute -top-12 right-0 flex gap-2">
          <a
            href={attachment.download}
            download={attachment.fileName}
            className="rounded-full"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              size="icon"
              variant="outline"
              className="h-10 w-10 rounded-full bg-background backdrop-blur-sm hover:bg-background"
            >
              <Download className="h-5 w-5" />
              <span className="sr-only">Download</span>
            </Button>
          </a>
          <Button
            size="icon"
            variant="outline"
            className="h-10 w-10 rounded-full bg-background backdrop-blur-sm hover:bg-background"
            onClick={onClose}
          >
            <X className="h-5 w-5" />
            <span className="sr-only">Luk</span>
          </Button>
        </div>

        <div className="rounded-lg bg-transparent p-1">{renderMedia()}</div>

        <div className="mt-4 text-center">
          <h2 className="text-xl font-medium text-white">{attachment.fileName}</h2>
          {/* <p className="text-sm text-gray-300">{attachment.}</p> */}
        </div>
      </div>
    </div>
  );
}
