export enum Space {
  mini = 8,
  small = 16,
  base = 24,
  medium = 32,
  large = 40,
  xlarge = 48,
}

export const spacing = {
  mini: Space.mini,
  small: Space.small,
  base: Space.base,
  medium: Space.medium,
  large: Space.large,
  xlarge: Space.xlarge,
};
