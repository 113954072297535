import {
  ORDERS_FETCH_REQUESTED,
  ORDERS_FETCH_SUCCEEDED,
  ORG_ORDERS_FETCH_REQUESTED,
  ORG_ORDERS_FETCH_SUCCEEDED,
  ORDERS_CREATE_REQUESTED,
  ORDERS_CREATE_SUCCEEDED,
  ORDERS_UPDATE_REQUESTED,
  ORDERS_UPDATE_SUCCEEDED,
  ORDERS_DELETE_REQUESTED,
  ORDERS_DELETE_SUCCEEDED,
} from './types';

export function fetchOrders() {
  return {
    type: ORDERS_FETCH_REQUESTED,
    payload: {},
  };
}

export function fetchOrdersSuccess(ordersList) {
  const orders = ordersList.docs.map((order) => ({
    ...order.data(),
    id: order.id,
  }));
  return {
    type: ORDERS_FETCH_SUCCEEDED,
    payload: {
      orders,
    },
  };
}

export function fetchOrgOrders(orgId) {
  return {
    type: ORG_ORDERS_FETCH_REQUESTED,
    payload: {
      orgId,
    },
  };
}

export function fetchOrgOrdersSuccess(ordersList) {
  const orders = ordersList.docs.map((order) => ({
    ...order.data(),
    id: order.id,
  }));
  return {
    type: ORG_ORDERS_FETCH_SUCCEEDED,
    payload: {
      orders,
    },
  };
}

export function addOrder(orgId, productId, userId, amount) {
  return {
    type: ORDERS_CREATE_REQUESTED,
    payload: {
      orgId,
      productId,
      userId,
      amount,
    },
  };
}

export function addOrderSuccess() {
  return {
    type: ORDERS_CREATE_SUCCEEDED,
    payload: {},
  };
}
export function updateOrder({ orderId, data }) {
  return {
    type: ORDERS_UPDATE_REQUESTED,
    payload: {
      orderId,
      data,
    },
  };
}

export function updateOrderSuccess() {
  return {
    type: ORDERS_UPDATE_SUCCEEDED,
    payload: {},
  };
}

export function deleteOrder(orderId) {
  return {
    type: ORDERS_DELETE_REQUESTED,
    payload: {
      orderId,
    },
  };
}

export function deleteOrderSuccess() {
  return {
    type: ORDERS_DELETE_SUCCEEDED,
    payload: {},
  };
}
