import useGPSOverview from 'hooks/useGPSOverview';
import { Col, Row } from 'antd';
import { useAppSelector } from 'hooks';
import { MobileGPSCard } from './MobileGPSCard';

const testGPSRefs = [
  'orgs/zzth0GnROgClx0zXF65o/sensors/8Z7ltkp6qMiV0e50hPAq',
  'orgs/6JhIGU82eIemMwGLzmLB/sensors/UpgYfmTRIH3bqdvwkQfJ',
  'orgs/IQmi9gwrFXJTGTv6l5DI/sensors/xJtG6T1s3Dgo8zpcD5sn',
  'orgs/jQSkaNqCnrlOa7eJHi1E/sensors/gLBYrLG0hFQGAZtDUiRN',
];

export default function Container() {
  const { gps, loading, error } = useGPSOverview();
  console.log({
    gps,
    loading,
  });

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Something went wrong...</div>;
  }

  const testGps = gps.filter((gps) => testGPSRefs.includes(gps.ref));

  const stationaryGps = gps.filter((gps) => !testGPSRefs.includes(gps.ref));

  return (
    <Row gutter={8}>
      <Col span={24} style={{ marginBottom: 24 }}>
        <h2>GPS'er: {stationaryGps.length}</h2>
      </Col>
      {stationaryGps.map((gps) => {
        return (
          <Col span={8} style={{ marginBottom: 24 }}>
            <MobileGPSCard gps={gps} lastSeen={gps.lastSeen} org={gps.org} orgId={gps.orgId} />
          </Col>
        );
      })}

      <Col span={24} style={{ marginBottom: 24 }}>
        <h2>Test / DEMO GPS'er: {testGps.length}</h2>
      </Col>
      {testGps.map((gps) => {
        return (
          <Col span={8} style={{ marginBottom: 24 }}>
            <MobileGPSCard gps={gps} lastSeen={gps.lastSeen} org={gps.org} orgId={gps.orgId} />
          </Col>
        );
      })}
    </Row>
  );
}
