import { RequestState } from '../../interfaces/request';
import { IOrg, Member } from '../orgs/interfaces';

export enum ActionType {
  GET_GROUPS_PENDING = 'GET_GROUPS_PENDING',
  GET_GROUPS_RESOLVED = 'GET_GROUPS_RESOLVED',
  GET_GROUPS_REJECTED = 'GET_GROUPS_REJECTED',
}

export interface GroupStorage {
  [productId: string]: {
    unit: 'l' | 'ml';
    storage: number;
    storageAlert: number;
  };
}

export interface DBGroupShape {
  id: string;
  title: string;
  count: number;
  storage: GroupStorage;
  members: Omit<Member, 'role'>;
}

export interface GroupState {
  groups: DBGroupShape[];
  groupsMap: { [id: string]: DBGroupShape };
  state: RequestState;
}

export const FETCH_GROUPS_REQUESTED = 'FETCH_GROUPS_REQUESTED';
export const FETCH_GROUPS_SUCCEEDED = 'FETCH_GROUPS_SUCCEEDED';
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED';

interface FetchGroupsSuccess {
  type: typeof FETCH_GROUPS_REQUESTED;
  payload: { orgId: string };
}
interface FetchGroups {
  type: typeof FETCH_GROUPS_SUCCEEDED;
  payload: DBGroupShape[];
}
interface FetchGroupsFailed {
  type: typeof FETCH_GROUPS_FAILED;
  payload: {};
}

export type GroupActionTypes = FetchGroups | FetchGroupsSuccess | FetchGroupsFailed;
