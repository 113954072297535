import React, { useState, CSSProperties } from 'react';
import ReactPlayer from 'react-player';
import { Row, Col, Typography, Button, Checkbox, Image, Divider, Input, message } from 'antd';
import { Colors } from '../../../Layout/Colors';
import {
  CheckCircleOutlined,
  DeleteRowOutlined,
  PlusCircleOutlined,
  UserDeleteOutlined,
} from '@ant-design/icons';
import certificate from '../assets/certificate_.jpg';

const { Title, Paragraph } = Typography;
// @ts-ignore
export default function Step5({ done }: { done: (names: string[]) => Promise<void> }) {
  const [loading, setloading] = useState(false);

  const [names, setNames] = useState<string[]>(['']);

  const isDisabled = names.filter(Boolean).length < 1;
  return (
    <Row>
      <Col md={24}>
        <Title style={styles.body}>Certificeret</Title>
      </Col>
      <Col xs={24} md={12}>
        <div style={styles.wrapper}>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              zIndex: 1,
              top: '51%',
              left: '8.5%',
            }}
          >
            {names?.[0] || 'Dit navn vil blive vist her'}
          </div>
          <Image src={certificate} preview={false} />
          {/* <Image src={equipment} preview={false} />
                    <Image src={mix} preview={false} /> */}
        </div>
      </Col>
      <Col xs={24} md={12}>
        <Title level={4} style={styles.title}>
          Jeg har hermed forstået følgende
        </Title>
        <Paragraph style={styles.body}>
          <ul style={{ listStyleType: 'none' }}>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Formål med aks Støvbinder og hvordan det virker
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Anvendelsesområde
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Brugsanvisning
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Deklaration
            </li>
            <li style={styles.listItem}>
              <CheckCircleOutlined style={styles.icon} />
              Registrering i app
            </li>
          </ul>
        </Paragraph>
        {names.map((name, index) => (
          <div key={index} style={{ display: 'flex' }}>
            <Input
              placeholder="Dit navn"
              value={name}
              onChange={(e) => {
                const newNames = names.map((name, i) => (i === index ? e.target.value : name));
                console.log({ newNames });

                setNames(newNames);
              }}
            />
            {index !== 0 && (
              <Button
                icon={<UserDeleteOutlined style={{ color: 'red' }} />}
                onClick={() => setNames(names.filter((_, i) => i !== index))}
              />
            )}
          </div>
        ))}
        <div style={{ marginBottom: 8 }} />
        <Button type="text" icon={<PlusCircleOutlined />} onClick={() => setNames([...names, ''])}>
          Tilføj navn
        </Button>
        <div style={{ marginBottom: 16 }} />
        <Button
          type="primary"
          block
          loading={loading}
          onClick={async () => {
            setloading(true);
            await done(names);
            setloading(false);
          }}
          disabled={Boolean(isDisabled)}
        >
          {isDisabled ? 'Udfyld navn for at afslutte' : 'Jeg har forstået og afslut'}
        </Button>
      </Col>
    </Row>
  );
}

const styles: { [key: string]: CSSProperties } = {
  wrapper: {
    position: 'relative',
    padding: 24,
  },
  player: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'none',
  },
  title: {
    textAlign: 'left',
    paddingTop: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  body: {
    textAlign: 'left',
    paddingLeft: 24,
    paddingRight: 24,
  },
  listItem: { margin: 0, marginBottom: 16, display: 'flex', alignItems: 'center' },
  icon: {
    fontSize: 18,
    marginRight: 8,
    color: Colors.info,
  },
};
