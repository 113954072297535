import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import SensorList from './SensorList';
import { fetchSensors } from '../../modules/sensors/actions';
import { useAppSelector } from '../../hooks';
function SensorListContainer() {
  const { orgId } = useParams<{ orgId: string }>();
  const dispatch = useDispatch();

  const { sensors, loading } = useAppSelector((state) => ({
    sensors: state.sensors.sensors,
    loading: state.sensors.loading,
  }));
  useEffect(() => {
    dispatch(fetchSensors({ orgId }));
    return () => {};
  }, []);

  return <SensorList sensors={sensors} loading={loading} orgId={orgId} />;
}

export default SensorListContainer;
