import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import AuditTemplateDetail from './AuditTemplateDetail';
import { useAppSelector } from '../../../hooks';
import {
  fetchGroupAuditTemplate,
  fetchGroupAuditTemplateTasks,
} from 'modules/groupAuditTemplates/actions';

function AuditTemplateDetailContainerReadOnly() {
  const dispatch = useDispatch();
  const { orgId, auditTemplateId } = useParams<{
    orgId: string;
    auditTemplateId: string;
  }>();

  const groupId = useAppSelector((state) => state.orgs.currentOrg.groupId);
  const { currentAuditTemplate, loading } = useAppSelector((state) => ({
    currentAuditTemplate: state.groupAuditTemplates.currentGroupAuditTemplate,

    loading: state.groupAuditTemplates.loading,
  }));
  const currentAuditTemplateTasks = useAppSelector(
    (state) => state.groupAuditTemplates.currentGroupAuditTemplateTasks
  );
  console.log({ groupId });

  useEffect(() => {
    if (!groupId) return;
    dispatch(fetchGroupAuditTemplate({ groupId, auditTemplateId }));
    dispatch(fetchGroupAuditTemplateTasks({ groupId, auditTemplateId }));
  }, [groupId, auditTemplateId, dispatch]);

  const dispatchAddAuditTemplateTask = (title: string, order: number) => {
    console.log({ title, order });

    // dispatch(addAuditTemplateTask({ orgId, auditTemplateId, title, order }));
  };

  const dispatchUpdateAuditTemplate = (_: string) => {
    // dispatch(updateAuditTemplate({ orgId, auditTemplateId, title }));
  };
  const dispatchRemoveAuditTemplateFile = () => {
    // dispatch(removeAuditTemplateFile({ orgId, auditTemplateId, guide: null }));
  };
  console.log({ ReadOnly: currentAuditTemplate, groupId });

  return (
    <AuditTemplateDetail
      id={currentAuditTemplate.id}
      templateTitle={currentAuditTemplate.title}
      guide={currentAuditTemplate.guide}
      createTime={currentAuditTemplate.createTime}
      note={currentAuditTemplate.note}
      auditTemplateCategory={currentAuditTemplate?.auditTemplateCategory ?? null}
      auditTemplateType={currentAuditTemplate?.auditTemplateType ?? null}
      tasks={currentAuditTemplateTasks}
      loading={loading}
      orgId={orgId}
      addAuditTemplateTask={dispatchAddAuditTemplateTask}
      updateAuditTemplate={dispatchUpdateAuditTemplate}
      removeAuditTemplateFile={dispatchRemoveAuditTemplateFile}
      isReadOnly={Boolean(groupId)}
    />
  );
}

export default AuditTemplateDetailContainerReadOnly;
