import { useEffect, useState } from 'react';
import { Input, Button, Modal, Form, Checkbox, Row, Col, InputNumber, Select } from 'antd';
import useProducts from '../../hooks/useProducts';
import { useDispatch, useSelector } from 'react-redux';
import { addOrg } from '../../modules/orgs/actions';
import { AppState } from '../../store';
import { Unit } from '../../components/SelectUnit/index';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import useQueryParams from '../../hooks/useQueryParams';

export default function AddOrg() {
  const [form] = Form.useForm();
  const query = useQueryParams();
  const groupId = query.get('groupId');
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const [visible, setvisible] = useState(false);
  const uid = useSelector((state: AppState) => state.user.details.uid);
  const email = useSelector((state: AppState) => state.user.details.email);
  const { products, dispatchFetchProducts } = useProducts();

  useEffect(() => {
    dispatchFetchProducts();
    form.validateFields();
    return () => {};
  }, []);
  const showModal = () => setvisible(true);

  const handleCancel = () => {
    setvisible(false);
  };

  const handleSubmit = (values: any) => {
    const { title, area, storageAlert, unit } = values;
    const productsToSubmit = products.filter(({ id }: { id: string }) =>
      values?.products?.includes(id)
    );

    dispatch(
      addOrg({
        uid,
        email,
        title,
        area,
        products: productsToSubmit,
        storageAlert,
        unit,
        groupId: groupId || null,
      })
    );

    form.resetFields();
    handleCancel();
  };
  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <>
      <Button size="large" type="primary" onClick={showModal}>
        {t('home.addcompany')}
      </Button>
      <Modal
        title={t('org.addForm.header')}
        open={visible}
        // onOk={e => handleOk(e, email)}
        onCancel={handleCancel}
        width={isMobile ? '100%' : '50%'}
        centered
        footer={[]}
      >
        <Form
          {...formLayout}
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            dataDocuments: ['A', 'B'],
            // unit: Unit.LITER,
          }}
        >
          <Form.Item
            label={t('org.addForm.nameLabel')}
            name="title"
            rules={[{ required: true, message: 'Venligst udfyld navn!' }]}
          >
            <Input placeholder={t('org.addForm.namePlaceholder')} />
          </Form.Item>
          <Form.Item
            label={t('org.addForm.areaLabel')}
            name="area"
            rules={[{ required: true, message: 'Venligst udfyld antal m2!' }]}
          >
            <InputNumber min={1} placeholder={t('org.addForm.areaPlaceholder')} />
          </Form.Item>
          <Form.Item
            label={t('org.addForm.storageLabel')}
            name="storageAlert"
            rules={[
              {
                required: true,
                message: 'Venligst udfyld lav behandling!',
              },
            ]}
          >
            <InputNumber min={1} placeholder={t('org.addForm.storagePlaceholder')} />
          </Form.Item>
          <Form.Item
            name="unit"
            label={t('org.addForm.unitLabel')}
            hasFeedback
            rules={[{ required: true, message: 'Venligst vælg enhed!' }]}
          >
            <Select placeholder={t('org.addForm.unitPlaceholder')} style={{ width: '100%' }}>
              <Select.Option value={Unit.LITER}>{t('units.liter')}</Select.Option>
              <Select.Option value={Unit.MILILITER}>{t('units.milliliter')}</Select.Option>
              <Select.Option value={Unit.PIECE}>{t('units.stk')}</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="products" label={t('org.addForm.products')}>
            <Checkbox.Group>
              <Row>
                {products.map(({ id, title }) => (
                  <Col span={24} key={id}>
                    <Checkbox value={id} style={{ lineHeight: '32px' }}>
                      {title}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>

          {/* <Input
                    placeholder="Firmanavn"
                    value={title}
                    onChange={e => setTitle(e.target.value)}
                /> */}
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" htmlType="submit" block>
              {t('org.addForm.add')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
