import { Intl } from './enums';
import { useSelector } from 'react-redux';
import { AppState } from '../store';
import { enUS, da, de, sv } from 'date-fns/locale';
import da_DK from 'antd/es/date-picker/locale/da_DK';
import en_US from 'antd/es/date-picker/locale/en_US';
import de_DE from 'antd/es/date-picker/locale/de_DE';
import sv_SE from 'antd/es/date-picker/locale/sv_SE';

export function getLocale(intl: Intl) {
  switch (intl) {
    case Intl.da_DK:
      return da;

    case Intl.en_US:
      return enUS;

    case Intl.de_DE:
      return de;

    case Intl.se_SE:
      return sv;

    default:
      return da;
  }
}

export function mapIntlToi18n(intl: Intl) {
  switch (intl) {
    case Intl.da_DK:
      return 'da';

    case Intl.en_US:
      return 'en';

    case Intl.de_DE:
      return 'de';

    case Intl.se_SE:
      return 'sv';

    default:
      return 'da';
  }
}

export function getLocaleAntd(intl: Intl) {
  switch (intl) {
    case Intl.da_DK:
      return da_DK;

    case Intl.en_US:
      return en_US;

    case Intl.de_DE:
      return de_DE;

    case Intl.se_SE:
      return sv_SE;

    default:
      return da_DK;
  }
}
