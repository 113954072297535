import { Breadcrumb } from 'antd';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

export default function AksBreadcrumbs() {
  const { groupId, auditTemplateId } = useParams<{
    groupId?: string;
    auditTemplateId?: string;
  }>();
  const { t } = useTranslation();
  const group = useAppSelector((state) => (groupId ? state.groups.groupsMap[groupId] : null));
  const template = useAppSelector((state) =>
    auditTemplateId ? state.groupAuditTemplates.currentGroupAuditTemplate : null
  );

  if (group && auditTemplateId) {
    return (
      <Breadcrumb
        items={[
          {
            title: <Link to="/groups">{t('header.groups')}</Link>,
          },
          { title: group.title },
          {
            title: (
              <Link to={`/groups/${groupId}/audits/templates`}>{t('sidenav.auditTemplate')}</Link>
            ),
          },
          { title: template?.title },
        ]}
      />
    );
  }
  if (group) {
    return (
      <Breadcrumb
        items={[
          {
            title: <Link to="/groups">{t('header.groups')}</Link>,
          },
          { title: group.title },
          { title: t('sidenav.auditTemplate') },
        ]}
      />
    );
  }

  return null;
}
