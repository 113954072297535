import { Drawer, Col, Row, Checkbox, Tooltip, Card } from 'antd';
import { AppState } from '../../store';
import { useSelector, useDispatch } from 'react-redux';
import { closeOrgNotificationDrawer } from '../../modules/drawer/actions';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { AdminAndMichaelOrCurrentUser, isAdminAndMichael } from 'components/AdminOnly';
import firebase from 'firebase/compat/app';
import EmailDigestNotifications from './components/EmailDigestNotifications';
import { MailOutlined, MobileOutlined } from '@ant-design/icons';
import { CheckboxProps } from 'antd/lib';

const emailPostFix = 'Email';

function NotificationsDrawer() {
  const context = useSelector((state: AppState) => state.drawer.orgNotifications);
  const currentUserId = useSelector((state: AppState) => state.user.details.uid);
  const org = useAppSelector((state) => state.orgs.currentOrg);
  const { t } = useTranslation();
  const features = useSelector((state: AppState) => state.features.featuresMap);
  const dispatch = useDispatch();

  const closeProfile = () => {
    dispatch(closeOrgNotificationDrawer());
  };

  const issueNotifications = [
    {
      label: t('access.notifications_issue_created'),
      value: 'newIssue',
    },
    {
      label: t('access.notifications_issue_fixed'),
      value: 'completedIssue',
    },
    {
      label: t('access.notifications_assigned_to_you'),
      value: 'assigneedToYou',
      disabled: true,
      pushChecked: true,
    },
  ];

  const auditNotifications = [
    {
      label: t('access.notifications_audit_deadline_today'),
      value: 'auditDeadlineToday',
    },
    {
      label: t('access.notifications_audit_deadline_alert'),
      value: 'auditDeadline',
    },
    {
      explainer: t('access.notifications_audit_days_after_deadline_alert_explainer'),
      label: t('access.notifications_audit_days_after_deadline_alert'),
      value: 'auditDaysAfterDeadline',
    },
    {
      // explainer: t(
      //     "access.notifications_audit_completed_with_issues_explainer",
      // ),
      label: t('access.notifications_audit_completed_with_issues'),
      value: 'auditCompletedWithIssues',
    },
  ];

  const cleanLogNotifications = [
    {
      label: t('access.notifications_clean_log_alert'),
      value: 'cleanLogAlert',
    },
  ];

  const sensorNotifications = [
    {
      label: t('access.notifications_sensor_high'),
      value: 'sensorLogPeak',
    },
    {
      label: t('access.notifications_missing_logs'),
      value: 'sensorLogError',
    },
    {
      label: t('access.notifications_gsm_plant_alert'),
      value: 'gsmPlantAlert',
    },
    {
      label: t('access.notifications_gps_logs_alert'),
      value: 'gpsLogsMissing',
    },
    {
      label: t('access.notifications_sms_perkolat_alerts'),
      value: 'smsPerkolatAlerts',
    },
  ];

  const storageNotifications = [
    {
      label: t('access.notifications_low_storage'),
      value: 'storageAlert',
    },
  ];

  // const notifications = [
  //     {
  //         label: t("access.notifications_issue_fixed"),
  //         value: "completedIssue",
  //     },
  //     {
  //         label: t("access.notifications_issue_created"),
  //         value: "newIssue",
  //     },
  //     {
  //         label: t("access.notifications_sensor_high"),
  //         value: "sensorLogPeak",
  //     },
  //     {
  //         label: t("access.notifications_clean_log_alert"),
  //         value: "cleanLogAlert",
  //     },
  //     {
  //         label: t("access.notifications_audit_deadline_alert"),
  //         value: "auditDeadline",
  //     },
  // ];

  const onUpdateNotifications = async (
    e: CheckboxChangeEvent,
    user: any,
    // TODO: find a better way to deal with this than a bulk flag
    bulkEvent: boolean = false
  ) => {
    // determine if global switch should be turned on/off

    const auditAlertGlobal = setGlobalAuditDealineSwitch(
      org,
      e.target.name as string,
      e.target.checked,
      bulkEvent
    );
    // determine if global switch should be turned on/off
    const cleanLogAlertGlobal = setGlobalCleanLogSwitch(
      org,
      e.target.name as string,
      e.target.checked,
      bulkEvent
    );
    // determine if global switch should be turned on/off
    const auditTodayGlobal = setGlobalAuditTodaySwitch(
      org,
      e.target.name as string,
      e.target.checked,
      bulkEvent
    );

    if (context?.orgId) {
      try {
        await firebase
          .firestore()
          .collection(`orgs`)
          .doc(context?.orgId)
          .set(
            {
              members: {
                [user.uid]: {
                  notifications: {
                    // @ts-ignore
                    [e.target.name]: e.target.checked,
                  },
                },
              },
              notifications: {
                ...cleanLogAlertGlobal,
                ...auditAlertGlobal,
                ...auditTodayGlobal,
              },
            },
            { merge: true }
          );
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      <Drawer
        key={`${context?.orgId}-${context?.userId}`}
        title={t('access.drawer.notifications.header', {
          name:
            // @ts-ignore
            org?.members?.[context?.userId]?.name ||
            // @ts-ignore
            org?.members?.[context?.userId]?.email,
        })}
        width={'70%'}
        onClose={closeProfile}
        open={Boolean(context?.userId)}
        bodyStyle={{ paddingBottom: 80 }}
      >
        <Row gutter={[16, 16]}>
          {features?.errorReporting?.isDisplayed !== false && (
            <Col span={12}>
              <Card title={t('sidenav.issuesTitle')} style={{ height: '100%' }}>
                {/* <h3 style={{ paddingBottom: 8 }}>
                                    {t("sidenav.issuesTitle")}
                                </h3> */}
                <Row>
                  {issueNotifications.map((n: any) => (
                    <NotificationCheckbox
                      key={n.value}
                      context={context}
                      org={org}
                      n={n}
                      disabled={n.disabled}
                      pushChecked={n.pushChecked}
                      onUpdateNotifications={onUpdateNotifications}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
          )}
          {features?.audit?.isDisplayed !== false && (
            <Col span={12}>
              <Card title={t('sidenav.audit')}>
                <Row>
                  <Col span={24}>
                    {auditNotifications.map((n: any) => (
                      <Tooltip title={n.explainer || ''}>
                        <Row>
                          <NotificationCheckbox
                            context={context}
                            org={org}
                            n={n}
                            onUpdateNotifications={onUpdateNotifications}
                          />
                        </Row>
                      </Tooltip>
                    ))}
                  </Col>
                </Row>
              </Card>
            </Col>
          )}
          {features?.cleanLogs?.isDisplayed !== false && (
            <Col span={12}>
              <Card title={t('sidenav.cleanlog')}>
                <Row>
                  {cleanLogNotifications.map((n: any) => (
                    <NotificationCheckbox
                      context={context}
                      org={org}
                      n={n}
                      onUpdateNotifications={onUpdateNotifications}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
          )}
          {features?.cleanLogs?.isDisplayed !== false && (
            <Col span={12}>
              <Card title={t('cleanlog.headline', { amount: '' })}>
                <Row>
                  {storageNotifications.map((n: any) => (
                    <NotificationCheckbox
                      context={context}
                      org={org}
                      n={n}
                      onUpdateNotifications={onUpdateNotifications}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
          )}
          {features?.sensors?.isDisplayed !== false && (
            <Col span={12}>
              <Card title={t('sidenav.sensors')}>
                <Row>
                  {sensorNotifications.map((n: any) => (
                    <NotificationCheckbox
                      context={context}
                      org={org}
                      n={n}
                      onUpdateNotifications={onUpdateNotifications}
                    />
                  ))}
                </Row>
              </Card>
            </Col>
          )}
          <AdminAndMichaelOrCurrentUser userId={context?.userId}>
            <Col span={24}>
              <Card title={t('profile.dailydigest')}>
                <Row>{context?.userId && <EmailDigestNotifications userId={context.userId} />}</Row>
              </Card>
            </Col>
          </AdminAndMichaelOrCurrentUser>
        </Row>
      </Drawer>
    </>
  );
}
export default NotificationsDrawer;

function NotificationCheckbox({
  context,
  org,
  n,
  onUpdateNotifications,
  disabled,
  // used for disabled checkboxes
  pushChecked,
}: any) {
  const { t } = useTranslation();
  if (!context?.userId) {
    console.error(`Notification drawer: user not defined`);
    return null;
  }
  const checked = org?.members[context?.userId]?.notifications?.[n.value] === true;
  const checkedEmail =
    org?.members[context?.userId]?.notifications?.[n.value + emailPostFix] === true;
  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    // fake event
    const fakeEvent = {
      target: {
        name: n.value,
        checked: e.target.checked,
      },
    } as CheckboxChangeEvent;
    // fake event (email)
    const fakeEventEmail = {
      target: {
        name: n.value + emailPostFix,
        checked: e.target.checked,
      },
    } as CheckboxChangeEvent;
    console.log({ e });
    onUpdateNotifications(fakeEvent, org?.members[context?.userId], true);
    onUpdateNotifications(fakeEventEmail, org?.members[context?.userId], true);

    // setCheckedList(e.target.checked ? plainOptions : []);
  };
  const checkAll = checkedEmail && checked;
  const indeterminate = ![checkedEmail, checked].every((v) => !v) && !checkAll;

  return (
    <>
      <Col
        span={18}
        key={n.value}
        style={{
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
          disabled={disabled}
        >
          {n.label}
        </Checkbox>
      </Col>
      <Col span={6} key={'indiviual'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Tooltip title={t('access.notifications_mobile')}>
            <Checkbox
              key="mobileNotification"
              name={n.value}
              checked={disabled && pushChecked ? true : checked}
              disabled={disabled}
              onChange={(e) => onUpdateNotifications(e, org?.members[context?.userId])}
            >
              <MobileOutlined />
            </Checkbox>
          </Tooltip>
          <Tooltip title={t('access.notifications_email')}>
            <Checkbox
              key="emailNotification"
              name={n.value + emailPostFix}
              checked={checkedEmail}
              disabled={disabled}
              onChange={(e) => onUpdateNotifications(e, org?.members[context?.userId])}
            >
              <MailOutlined />
            </Checkbox>
          </Tooltip>
        </div>
      </Col>
    </>
  );
}

function setGlobalAuditDealineSwitch(
  org: any,
  name: string,
  checked: boolean,
  bulkEvent: boolean = false
): { auditDeadlineAlert: boolean } {
  const notificationKeys = ['auditDeadline', 'auditDeadlineEmail'];
  if (notificationKeys.includes(name)) {
    let settingsInit: any[] = [];
    // set shouldTurnOffAuditAlert to true if there is exactly one true of either one of the keys
    Object.keys(org.members).forEach((id) => {
      notificationKeys.forEach((key) => {
        settingsInit.push(org.members[id]?.notifications?.[key]);
      });
    });

    // get only the ones that are true / active
    const filteredNotifications = settingsInit.filter(Boolean);

    const shouldTurnOff = filteredNotifications.length === (bulkEvent ? 2 : 1) && checked === false;

    const shouldTurnOn = filteredNotifications.length === 0 && checked === true;

    if (shouldTurnOff) {
      return {
        auditDeadlineAlert: false,
      };
    }
    if (shouldTurnOn) {
      return {
        auditDeadlineAlert: true,
      };
    }
  }
  return {
    auditDeadlineAlert: org?.notifications?.auditDeadlineAlert || false,
  };
}
function setGlobalCleanLogSwitch(
  org: any,
  name: string,
  checked: boolean,
  bulkEvent: boolean = false
): { cleanLogAlertDays: false | number } {
  const notificationKeys = ['cleanLogAlert', 'cleanLogAlertEmail'];
  if (notificationKeys.includes(name)) {
    let settingsInit: any[] = [];
    // set shouldTurnOffAuditAlert to true if there is exactly one true of either one of the keys
    Object.keys(org.members).forEach((id) => {
      notificationKeys.forEach((key) => {
        settingsInit.push(org.members[id]?.notifications?.[key]);
      });
    });

    // get only the ones that are true / active
    const filteredNotifications = settingsInit.filter(Boolean);

    const shouldTurnOff = filteredNotifications.length === (bulkEvent ? 2 : 1) && checked === false;

    const shouldTurnOn = filteredNotifications.length === 0 && checked === true;

    if (shouldTurnOff) {
      return {
        cleanLogAlertDays: false,
      };
    }
    if (shouldTurnOn) {
      return {
        // default days
        cleanLogAlertDays: 7,
      };
    }
  }
  return {
    cleanLogAlertDays: org?.notifications?.cleanLogAlertDays || false,
  };
}
function setGlobalAuditTodaySwitch(
  org: any,
  name: string,
  checked: boolean,
  bulkEvent: boolean = false
): { auditDeadlineToday: boolean } {
  const notificationKeys = ['auditDeadlineToday', 'auditDeadlineTodayEmail'];
  if (notificationKeys.includes(name)) {
    let settingsInit: any[] = [];
    // set shouldTurnOffAuditAlert to true if there is exactly one true of either one of the keys
    Object.keys(org.members).forEach((id) => {
      notificationKeys.forEach((key) => {
        settingsInit.push(org.members[id]?.notifications?.[key]);
      });
    });

    // get only the ones that are true / active
    const filteredNotifications = settingsInit.filter(Boolean);

    const shouldTurnOff = filteredNotifications.length === (bulkEvent ? 2 : 1) && checked === false;

    const shouldTurnOn = filteredNotifications.length === 0 && checked === true;

    if (shouldTurnOff) {
      return {
        auditDeadlineToday: false,
      };
    }
    if (shouldTurnOn) {
      return {
        // default days
        auditDeadlineToday: true,
      };
    }
  }
  return {
    auditDeadlineToday: org?.notifications?.auditDeadlineToday || false,
  };
}
