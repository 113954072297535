import React from 'react';
import { useLocation } from 'react-router';

export function useQuery() {
  const { search } = useLocation();
  const ref = new URLSearchParams(search);
  return React.useMemo(
    () => ({ email: ref.get('email'), password: ref.get('password') }),
    [search]
  );
}
