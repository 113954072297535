import { useState } from 'react';
import { Plus } from 'lucide-react';
import { Button } from 'components/ui/button';
import InventoryList from './inventory-list';
import AddItemDialog from './add-item-dialog';
import type { InventoryItem } from '../types';

interface InventoryDashboardProps {
  selectedCategory?: string;
}

export default function InventoryDashboard({ selectedCategory = 'all' }: InventoryDashboardProps) {
  const [items, setItems] = useState<InventoryItem[]>([
    {
      id: 'EQ-HELM-001',
      serialNumber: 'SH2023-001',
      name: 'Sikkerhedshjelm',
      category: 'Sikkerhedsudstyr',
      status: 'Available',
      description: 'Standard safety helmet for construction sites',
      dateAdded: '2023-10-15',
      lastMaintenance: '2024-01-20',
      nextMaintenance: null,
      location: 'RGS Gadstrup',
      manufacturer: 'SafetyFirst',
      purchaseDate: '2023-05-10',
      image: '/placeholder.svg?height=100&width=100',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    },
    {
      id: 'EQ-DRILL-001',
      serialNumber: 'DR2023-001',
      name: 'Boremaskine',
      category: 'Elværktøj',
      status: 'Available',
      description: 'Cordless power drill with battery pack',
      dateAdded: '2023-09-05',
      lastMaintenance: '2024-02-10',
      nextMaintenance: null,
      location: 'RGS Gadstrup',
      manufacturer: 'DeWalt',
      purchaseDate: '2023-04-15',
      image:
        'https://c8.alamy.com/comp/C994KC/studio-photography-of-a-portable-electric-drill-in-white-back-C994KC.jpg',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    },
    {
      id: 'EQ-LADD-001',
      serialNumber: 'LD2023-001',
      name: 'Skydestige',
      category: 'Stiger',
      status: 'In Use',
      description: '28-foot extension ladder',
      dateAdded: '2023-11-20',
      lastMaintenance: '2024-01-15',
      nextMaintenance: null,
      location: 'RGS Vemmelev',
      manufacturer: 'Werner',
      purchaseDate: '2023-06-22',
      image: '/placeholder.svg?height=100&width=100',
      checkedOutTo: 'John Doe',
      checkOutDate: '2024-03-01',
      expectedReturnDate: '2024-03-15',
    },
    {
      id: 'EQ-HAMM-001',
      serialNumber: 'HM2023-001',
      name: 'Hammer',
      category: 'Håndværktøj',
      status: 'Available',
      description: 'Standard claw hammer',
      dateAdded: '2023-08-12',
      lastMaintenance: null,
      nextMaintenance: null,
      location: 'RGS Gadstrup',
      manufacturer: 'Stanley',
      purchaseDate: '2023-03-05',
      image: '/placeholder.svg?height=100&width=100',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    },
    {
      id: 'EQ-SAW-001',
      serialNumber: 'SW2023-001',
      name: 'Rundsav',
      category: 'Elværktøj',
      status: 'Maintenance',
      description: '7-1/4 inch circular saw',
      dateAdded: '2023-07-30',
      lastMaintenance: '2024-02-15',
      nextMaintenance: '2024-05-15',
      location: 'RGS Gadstrup',
      manufacturer: 'Makita',
      purchaseDate: '2023-02-18',
      image: '/placeholder.svg?height=100&width=100',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    },
    {
      id: 'EQ-VEST-001',
      serialNumber: 'SV2023-001',
      name: 'Sikkerhedsvest',
      category: 'Sikkerhedsudstyr',
      status: 'Available',
      description: 'High-visibility safety vest',
      dateAdded: '2023-10-01',
      lastMaintenance: null,
      nextMaintenance: null,
      location: 'RGS Gadstrup',
      manufacturer: '3M',
      purchaseDate: '2023-05-15',
      image: '/placeholder.svg?height=100&width=100',
      checkedOutTo: null,
      checkOutDate: null,
      expectedReturnDate: null,
    },
  ]);

  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);

  // @ts-ignore
  const categories = [...new Set(items.map((item) => item.category))];
  // @ts-ignore
  const locations = [...new Set(items.map((item) => item.location).filter(Boolean))];

  // Filter items by selected category
  const filteredItems =
    selectedCategory === 'all' ? items : items.filter((item) => item.category === selectedCategory);

  const generateUniqueId = (category: string, _: string) => {
    // Create a prefix based on the category and item name
    const prefix = `EQ-${category.substring(0, 4).toUpperCase()}`;

    // Find existing items with the same prefix
    const existingIds = items.filter((item) => item.id.startsWith(prefix)).map((item) => item.id);

    // Find the highest number suffix
    let highestNum = 0;
    existingIds.forEach((id) => {
      const numPart = id.split('-')[2];
      if (numPart) {
        const num = Number.parseInt(numPart);
        if (!isNaN(num) && num > highestNum) {
          highestNum = num;
        }
      }
    });

    // Create new ID with incremented number
    return `${prefix}-${String(highestNum + 1).padStart(3, '0')}`;
  };

  const generateSerialNumber = (category: string) => {
    // Create a prefix based on the category
    const prefix = `${category.substring(0, 2).toUpperCase()}${new Date().getFullYear()}`;

    // Find existing items with the same prefix
    const existingSerials = items
      .filter((item) => item.serialNumber.startsWith(prefix))
      .map((item) => item.serialNumber);

    // Find the highest number suffix
    let highestNum = 0;
    existingSerials.forEach((serial) => {
      const numPart = serial.split('-')[1];
      if (numPart) {
        const num = Number.parseInt(numPart);
        if (!isNaN(num) && num > highestNum) {
          highestNum = num;
        }
      }
    });

    // Create new serial number with incremented number
    return `${prefix}-${String(highestNum + 1).padStart(3, '0')}`;
  };

  const addItem = (newItem: Omit<InventoryItem, 'id' | 'serialNumber'>) => {
    const id = generateUniqueId(newItem.category, newItem.name);
    const serialNumber = generateSerialNumber(newItem.category);
    setItems([...items, { ...newItem, id, serialNumber }]);
    setIsAddDialogOpen(false);
  };

  const deleteItem = (id: string) => {
    setItems(items.filter((item) => item.id !== id));
  };

  const updateItem = (updatedItem: InventoryItem) => {
    setItems(items.map((item) => (item.id === updatedItem.id ? updatedItem : item)));
  };

  return (
    <div className="space-y-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">
            {selectedCategory === 'all' ? 'Alt Udstyr' : selectedCategory}
          </h1>
          <p className="text-muted-foreground">
            {selectedCategory === 'all'
              ? 'Spor og administrer dit udstyr med unikke identifikatorer.'
              : `Se alt ${selectedCategory} items`}
          </p>
        </div>
        <Button onClick={() => setIsAddDialogOpen(true)}>
          <Plus className="mr-2 h-4 w-4" /> Add Item
        </Button>
      </div>

      <InventoryList items={filteredItems} onDelete={deleteItem} onUpdate={updateItem} />

      <AddItemDialog
        open={isAddDialogOpen}
        onOpenChange={setIsAddDialogOpen}
        onAdd={addItem}
        existingCategories={categories}
        existingLocations={locations}
      />
    </div>
  );
}
