import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { useParams } from 'react-router';
import { useAppSelector } from 'hooks';

export type IssueNote = {
  id: string;
  text: string;
  createTime: Date;
};

export default function useIssueNotes() {
  const { orgId, issueId } = useParams<{ orgId: string; issueId: string }>();
  const [notes, setNotes] = useState<IssueNote[]>([]);
  const [isLoading, setisLoading] = useState<boolean>(true);
  const userId = useAppSelector((state) => state.user.details.uid);
  const displayName = useAppSelector(
    (state) =>
      state.orgs.orgMap?.[orgId]?.members?.[userId]?.name ||
      state.orgs.orgMap?.[orgId]?.members?.[userId]?.email ||
      'Anonym'
  );

  const addNote = async (text: string) => {
    try {
      if (!orgId || !issueId || !userId) return;
      await firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('issues')
        .doc(issueId)
        .collection('notes')
        .add({
          text,
          createTime: new Date(),
          createBy: {
            id: userId,
            displayName,
          },
        });
    } catch (error) {
      console.error('Error adding note', error);
    }
  };
  const updateNote = async (noteId: string, text: string) => {
    try {
      if (!orgId || !issueId || !noteId || !userId) return;
      await firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('issues')
        .doc(issueId)
        .collection('notes')
        .doc(noteId)
        .set(
          {
            text,
            updateTime: new Date(),
            lastUpdateBy: {
              id: userId,
              displayName,
            },
          },
          { merge: true }
        );
    } catch (error) {
      console.error('Error editing note', error);
    }
  };
  const deleteNote = async (noteId: string) => {
    try {
      if (!orgId || !issueId || !noteId) return;
      await firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('issues')
        .doc(issueId)
        .collection('notes')
        .doc(noteId)
        .delete();
    } catch (error) {
      console.error('Error editing note', error);
    }
  };

  useEffect(() => {
    if (!orgId || !issueId) return;
    const query = firebase
      .firestore()
      .collection('orgs')
      .doc(orgId)
      .collection('issues')
      .doc(issueId)
      .collection('notes')
      .orderBy('createTime', 'desc');

    const sub = query.onSnapshot(
      (snap) => {
        if (!snap.empty) {
          const data = snap.docs.map((doc) => ({
            id: doc.id,
            text: doc.data()?.text,
            createTime: doc.data()?.createTime.toDate(),
            updateTime: doc.data()?.updateTime?.toDate(),
            createBy: doc.data()?.createBy,
            lastUpdateBy: doc.data()?.lastUpdateBy,
          })) as IssueNote[];
          setNotes(data);
        } else {
          setNotes([]);
        }
        setisLoading(false);
      },
      (error) => {
        console.error('Error fetching activity', error);
      }
    );

    return () => {
      sub?.();
    };
  }, []);

  return { notes, addNote, updateNote, deleteNote, isLoading };
}
