import React, { useState } from 'react';
import { Form, Input, Button, Select, InputNumber, message, Row, Col } from 'antd';

import { post } from '../../../general/api';
import { useParams } from 'react-router';
import Message from '../../../components/Notification/Message';
import { Type } from '../../../components/Notification/Message';
import { SensorType } from '../../../general/enums';

const { Option } = Select;

const AddFormGpsPlant = ({ onClose }: { onClose: () => void }) => {
  const { orgId } = useParams<{ orgId: string }>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    Message({
      key: 'addSensor',
      message: 'Loading...',
      // description: "Sensoren blev tilføjet korrekt",
      type: Type.LOADING,
    });
    const payload = {
      orgId,
      title: values.title,
      deviceId: values.deviceId,
      location: [values.locationLat, values.locationLng],
      // bottomLeftBoundary: [values.blLat, values.blLng],
      // bottomRightBoundary: [values.brLat, values.brLng],
      // topLeftBoundary: [values.tlLat, values.tlLng],
      // topRightBoundary: [values.trLat, values.trLng],
    };

    const response = await post('setupSensorSimtrackingGPS', payload);
    setIsLoading(false);
    if (response.status === 200) {
      form.resetFields();
      Message({
        key: 'addSensor',
        message: 'Sensor tilføjet',
        description: 'Sensoren blev tilføjet korrekt',
      });
      onClose();
    } else {
      Message({
        key: 'addSensor',
        message: 'Fejl!',
        description: 'Noget gik galt, prøv venligst igen',
      });
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item name="title" label="Sensor navn" rules={[{ required: true }]}>
        <Input placeholder="Sensor navn" />
      </Form.Item>
      <Form.Item name="deviceId" label="Sensor id" rules={[{ required: true }]}>
        <Input placeholder="Sensor id" />
      </Form.Item>
      <Row gutter={6}>
        <Col md={12} xs={12}>
          <Form.Item name="locationLat" label="Location" rules={[{ required: true }]}>
            <InputNumber placeholder="Location latitude" />
          </Form.Item>
        </Col>
        <Col md={12} xs={12}>
          <Form.Item name="locationLng" label="." rules={[{ required: true }]}>
            <InputNumber placeholder="Location longitude" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Tilføj
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddFormGpsPlant;
