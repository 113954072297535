import React from 'react';
import Loader from 'components/Loader';
import logo from 'assets/images/aks_wings_logo_dark.png';

export default function LogoLoader() {
  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <img src={logo} width={150} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <Loader size={48} />
      </div>
    </div>
  );
}
