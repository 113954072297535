import { useSelector, useDispatch } from 'react-redux';
import { fetchDirectories } from '../../modules/directories/actions';
import { AppState } from '../../store';
import { useEffect } from 'react';

function useDirectories(parentDirectoryId?: string) {
  const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
  const directories = useSelector((state: AppState) =>
    state.directories.directories.filter((d) =>
      parentDirectoryId ? d.parentDirectoryId === parentDirectoryId : !d.parentDirectoryId
    )
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDirectories({ orgId }));
  }, [orgId, dispatch]);
  console.log({ directories, orgId });

  return { directories, orgId };
}

export default useDirectories;
