import React, { useState } from 'react';
import { Form, Input, InputNumber, message, Select, List } from 'antd';
import CategoryList from './CategoryList';
import ProductList from './ProductList';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import AdminOnly from '../../components/AdminOnly';
import { OrgStatusText } from '../../modules/orgs/interfaces';

const FormItem = Form.Item;
const Option = Select.Option;

function SettingsForm(props: any) {
  const { t } = useTranslation();
  const [storageAlert, setStorageAlert] = useState(props.storageAlert);

  const onChange = (value: number) => {
    props.updateOrg({ unit: value });
    message.success('Indstillinger gemt');
  };

  const onChangeStatus = (value: number) => {
    props.updateOrg({ status: Number(value) });
    message.success('Indstillinger gemt');
  };

  // Only show error after a field is touched.
  return (
    <div>
      <div>
        <label>
          {t('settings.storage_label')}:
          <InputNumber
            min={0}
            // prefix={
            //     <UserOutlined
            //         style={{ color: "rgba(0,0,0,.25)", width: "100%" }}
            //     />
            // }
            placeholder={t('settings.storage_placeholder')}
            value={storageAlert}
            onChange={(value) => setStorageAlert(value)}
            onBlur={() => {
              props.updateOrg({
                storageAlert: storageAlert,
              });
              message.success('Indstillinger gemt');
            }}
          />
        </label>
      </div>
      <div>
        <label>
          {t('settings.unit_label')}:
          <Select
            disabled
            placeholder="Vælg enhed"
            value={props.unit}
            onChange={onChange}
            style={{ width: '100%' }}
          >
            <Option value="l">Liter (l)</Option>
            <Option value="ml">Mililiter (ml)</Option>
            <Option value="stk">Stk.</Option>
          </Select>
        </label>
      </div>
      <AdminOnly>
        <label>
          {t('settings.status_label')}:
          <Select
            placeholder="Vælg status"
            value={props.status}
            onChange={onChangeStatus}
            style={{ width: '100%' }}
          >
            {Object.entries(OrgStatusText).map(([key, value]) => (
              <Option value={Number(key)}>{t(value)}</Option>
            ))}
          </Select>
        </label>
      </AdminOnly>
      <CategoryList />
      <ProductList />
    </div>
  );
}

export default SettingsForm;
