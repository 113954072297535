import { StyleGuide } from '../../styles/StyleGuide';

export const pipeDriveUsers = {
  19628341: {
    name: 'Charlotte',
    email: 'charlotte@aks2tal.com',
    bgColor: StyleGuide.palette.infoDark,
    color: StyleGuide.palette.infoLight,
  },

  19628352: {
    name: 'Michael',
    email: 'michael@aks2tal.com',
    color: StyleGuide.palette.infoDark,
    bgColor: StyleGuide.palette.infoLight,
  },

  19579930: {
    name: 'Niels Schmidt',
    email: 'info@aks2tal.com',
    phone: '+4541121565',
    color: StyleGuide.palette.infoDark,
    bgColor: StyleGuide.palette.infoLight,
  },
};

export function getOwner(id: string) {
  // @ts-ignore
  const owner = pipeDriveUsers[id];
  return owner;
}
