import React, { useState } from 'react';
import { Layout } from 'antd';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import Loader from '../../components/Loader';
import { useIsMobile } from '../../hooks/useIsMobile';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';
import BlurOverlayContainer from '../../components/Overlay/BlurOverlayContainer';
import { useTranslation } from 'react-i18next';
import Wizard from './components/Wizard';

const { Content } = Layout;

function Certification(props: any) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [product, setProduct] = useState(null);

  return (
    <>
      <SideNav />
      <Layout style={{ backgroundColor: '#fcfcfc' }}>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.CERTIFICATION}>
          <Content style={{ margin: '50px 24px 0' }}>
            {props.loading ? (
              <Loader />
            ) : (
              <React.Fragment>
                <Wizard />
              </React.Fragment>
            )}
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default Certification;
