import { Alert } from 'antd';
import React, { useState } from 'react';
import firebase from 'firebase/compat/app';
import { useAppSelector } from 'hooks';
import { useTranslation } from 'react-i18next';
import Message, { Type } from 'components/Notification/Message';

export default function AuditTemplateInfo() {
  const { t } = useTranslation();
  const uid = useAppSelector((state) => state.user.details.uid);
  const visible = useAppSelector((state) => {
    if (state.user.onboarding?.auditTemplateInfo === true) {
      return false;
    }
    return true;
  });

  const handleClose = async () => {
    try {
      if (!uid) throw new Error('uid is not defined');
      await firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .collection('onboarding')
        .doc('onboarding')
        .set({ auditTemplateInfo: true }, { merge: true });
    } catch (e) {
      console.error(e);
      Message({
        key: 'AuditTemplateInfo',
        type: Type.ERROR,
        message: `Der skete en fejl`,
      });
    }
  };

  if (!visible) return null;
  return (
    <Alert
      message={t('audit.templates.templateInfo')}
      type="info"
      showIcon
      closable
      afterClose={handleClose}
    />
  );
}
