import { AuditExecution } from './audit-execution';
import { useAudit } from './audit-context';
import AuditSummary from './audit-summary';

export function AuditSystem() {
  const { step } = useAudit();

  return (
    <div className="container mx-auto p-4 mb-8 space-y-8">
      {/* <h1 className="text-3xl font-bold mb-4">Internt Audit System</h1> */}
      <div className="space-y-8">
        {step !== 'summary' && <AuditExecution />}
        {step === 'summary' && <AuditSummary />}
      </div>
    </div>
  );
}
