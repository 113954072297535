'use client';

import { useEffect } from 'react';
import { fetchOrgProducts } from 'modules/products/actions';
import { useAppDispatch, useAppSelector } from 'hooks';

// Mock product data
// const productData: AvailableProduct[] = [
//   {
//     id: 'PROD-1',
//     title: 'AKS Støvbinder - ekstraskridsikring',
//     availableUnits: ['5 liter', '220 liter', '1000 liter'],
//     defaultUnit: '220 liter',
//   },
//   {
//     id: 'PROD-2',
//     title: 'AKS Støvbinder - fejegranulat',
//     availableUnits: ['25 liter', '1000 liter'],
//     defaultUnit: '25 liter',
//   },
// ];

export function useProducts(orgId: string) {
  const dispatch = useAppDispatch();
  const products = useAppSelector((state) => state.products.orgProducts);
  // console.log({ products });

  // const [products, setProducts] = useState<AvailableProduct[]>([]);

  useEffect(() => {
    if (!orgId) return;
    // In a real app, this would be an API call
    dispatch(fetchOrgProducts(orgId));
    // setProducts(productData);
  }, [orgId]);

  console.log({ products });

  return {
    products:
      products.map((p) => ({
        ...p,
        defaultUnit: p.availableUnits?.[0],
      })) || [],
    loadingProducts: false,
  };
}
