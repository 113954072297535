import {
  CheckinState,
  DBCheckinShape,
  CHECKOUT_FETCH_REQUESTED,
  CHECKOUT_FETCH_SUCCEEDED,
  CHECKOUT_FETCH_FAILED,
} from './types';
import {
  CHECKIN_FETCH_REQUESTED,
  CHECKIN_FETCH_SUCCEEDED,
  CHECKIN_FETCH_FAILED,
  CheckinActionTypes,
} from './types';

const initialState: {
  checkins: DBCheckinShape[];
  checkouts: DBCheckinShape[];
  loading: boolean;
} = {
  checkins: [],
  checkouts: [],
  loading: true,
};

export default function checkins(state = initialState, action: CheckinActionTypes): CheckinState {
  switch (action.type) {
    case CHECKIN_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CHECKIN_FETCH_SUCCEEDED:
      return {
        ...state,
        checkins: action.payload,
        loading: false,
      };
    case CHECKIN_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    case CHECKOUT_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case CHECKOUT_FETCH_SUCCEEDED:
      return {
        ...state,
        checkouts: action.payload,
        loading: false,
      };
    case CHECKOUT_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
