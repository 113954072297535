import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import AuditTemplateList from './AuditTemplateList';
import { useAppSelector } from '../../../hooks';
import { fetchAuditTemplates, addAuditTemplate } from '../../../modules/auditTemplates/actions';
import { AuditTemplate } from '../../../modules/auditTemplates/types';
import { fetchGroupAuditTemplates } from 'modules/groupAuditTemplates/actions';

function AuditTemplateListContainer() {
  const dispatch = useDispatch();
  const { orgId } = useParams<{ orgId: string }>();
  const groupId = useAppSelector((state) => state.orgs.currentOrg.groupId);
  const { auditTemplates, loading } = useAppSelector((state) => ({
    auditTemplates: state.auditTemplates.auditTemplates?.sort(
      (a: AuditTemplate, b: AuditTemplate) => {
        const aTitle = a?.title?.toLowerCase();
        const bTitle = b?.title?.toLowerCase();
        if (aTitle > bTitle) return 1;
        if (aTitle < bTitle) return -1;
        return 0;
      }
    ),
    loading: state.auditTemplates.loading,
  }));
  const { groupAuditTemplates, groupLoading } = useAppSelector((state) => ({
    groupAuditTemplates: state.groupAuditTemplates.groupAuditTemplates?.sort(
      (a: AuditTemplate, b: AuditTemplate) => {
        const aTitle = a?.title?.toLowerCase();
        const bTitle = b?.title?.toLowerCase();
        if (aTitle > bTitle) return 1;
        if (aTitle < bTitle) return -1;
        return 0;
      }
    ),
    groupLoading: state.groupAuditTemplates.loading,
  }));

  useEffect(() => {
    dispatch(fetchAuditTemplates({ orgId }));

    return () => {};
  }, [orgId, dispatch]);
  useEffect(() => {
    if (groupId) {
      dispatch(fetchGroupAuditTemplates({ groupId }));
    }

    return () => {};
  }, [groupId, dispatch]);

  const dispatchAddAuditTemplate = (title: string) => {
    dispatch(addAuditTemplate({ orgId, title }));
  };

  return (
    <AuditTemplateList
      loading={loading}
      groupLoading={groupLoading}
      groupAuditTemplates={groupAuditTemplates}
      auditTemplates={auditTemplates}
      orgId={orgId}
      addAuditTemplate={dispatchAddAuditTemplate}
    />
  );
}

export default AuditTemplateListContainer;
