import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export default function useGetDownloadUrl(filePath: string) {
  const [loading, setloading] = useState(true);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  useEffect(() => {
    try {
      setloading(true);
      if (!filePath) throw new Error(`No filePath provided: filePath=${filePath}`);
      firebase
        .storage()
        // @ts-ignore
        .ref(filePath)
        // .child("images/stars.jpg")
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'
          setFileUrl(url);

          // This can be downloaded directly:
          // var xhr = new XMLHttpRequest();
          // xhr.responseType = "blob";
          // xhr.onload = event => {
          //     var blob = xhr.response;
          // };
          // xhr.open("GET", url);
          // xhr.send();

          // // Or inserted into an <img> element
          // var img = document.getElementById("myimg");
          // img.setAttribute("src", url);
        })
        .catch(() => {
          // Handle any errors
          // console.error({ error });
        })
        .finally(() => setloading(false));
    } catch (error) {
      console.error({ error });
    }
  }, [filePath]);
  return { loading, fileUrl };
}
