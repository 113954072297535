import {
  HOURLOG_CREATE_REQUESTED,
  HOURLOGS_FETCH_REQUESTED,
  HOURLOGS_FETCH_SUCCEEDED,
  HOURLOG_UPDATE_REQUESTED,
} from './types';

export function fetchHourLogs({ orgId }: { orgId: string }) {
  return {
    type: HOURLOGS_FETCH_REQUESTED,
    payload: { orgId },
  };
}
// @ts-ignore
export function fetchHourLogsSuccess(cleanLogs: firebase.firestore.QuerySnapshot) {
  const logs = cleanLogs.docs.map((log: any) => {
    return {
      ...log.data(),
      id: log.id,
    };
  });
  return {
    type: HOURLOGS_FETCH_SUCCEEDED,
    payload: {
      data: logs,
    },
  };
}

export function addHourLog({ orgId, initials, amount, productId }: any) {
  return {
    type: HOURLOG_CREATE_REQUESTED,
    payload: {
      orgId,
      initials,
      amount,
      productId,
    },
  };
}

export function updateHourLog({ orgId, logId, data }: any) {
  return {
    type: HOURLOG_UPDATE_REQUESTED,
    payload: {
      orgId,
      logId,
      data,
    },
  };
}
