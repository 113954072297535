import React, { useState } from 'react';
import { Form, Input, Button, Select, InputNumber, message } from 'antd';
import { useParams } from 'react-router';
import { SensorType } from '../../../general/enums';
import { functions } from '../../../firebase';
import { toast } from 'sonner';

const { Option } = Select;

const initialValues = {
  type: SensorType.LEVEL_SENSOR_INTERNAL,
};

const AddFormLevelSensor = ({ onClose }: { onClose: () => void }) => {
  const { orgId } = useParams<{ orgId: string }>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: any) => {
    setIsLoading(true);
    const payload = {
      orgId,
      title: values.title,
      deviceId: values.deviceId,
    };
    const functionRef = functions.httpsCallable('settings');
    const response: any = await functionRef({
      requestType: 'SETUP_LEVEL_SENSOR_INTERNAL',
      ...payload,
    });

    const errorCode = response?.data?.httpErrorCode?.status;
    if (errorCode) {
      return toast.error('Der skete en fejl');
    }
    toast.success('Sensoren blev tilføjet korrekt');
    setIsLoading(false);
    return onClose();
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish} initialValues={initialValues}>
      <Form.Item name="title" label="Sensor navn" rules={[{ required: true }]}>
        <Input placeholder="Sensor navn" />
      </Form.Item>
      <Form.Item name="deviceId" label="Sensor id" rules={[{ required: true }]}>
        <Input placeholder="Sensor id (fx 87001)" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={isLoading}>
          Tilføj
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddFormLevelSensor;
