'use client';

import { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { format, addMonths } from 'date-fns';
import { da } from 'date-fns/locale';
import { OrderStatusEnum, type DBOrder, type Order, type Product } from '../lib/types';
import { Trash2, Plus, AlertCircle, Building2, Package, Search, CalendarIcon } from 'lucide-react';
import { ScrollArea } from 'components/ui/scroll-area';
import { useProducts } from '../hooks/use-products';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from 'components/ui/card';
import { Alert, AlertDescription } from 'components/ui/alert';
import { useCompanies } from '../hooks/use-companies';

import { Calendar } from 'components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { cn } from 'lib/utils';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'hooks/useQueryParams';
import { IOrg } from 'modules/orgs/interfaces';
import firebase from 'firebase/compat/app';
import { useAppSelector } from 'hooks';
import { productUnitsMap } from '../lib/productUnits';
import { isDev } from 'config';
import { toast } from 'sonner';

interface CreateOrderDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  orgId?: string;
}

interface ValidationErrors {
  orgId?: string;
  companyName?: string;
  deliveryDate?: string;
  nextExpectedDeliveryDate?: string;
  deliveryAddress?: string;
  deliveryZip?: string;
  deliveryCity?: string;
  deliveryAtt?: string;
  contactPerson?: string;
  products?: string;
}

export function CreateOrderDialog({ open, onOpenChange, orgId }: CreateOrderDialogProps) {
  const { t } = useTranslation();
  const searchParams = useQueryParams();
  const urlOrgId = orgId || searchParams?.get('orders.orgId');
  const userId = useAppSelector((state) => state.user.details.uid);
  const userDisplayName = useAppSelector((state) => state.user.userDoc.displayName);
  const { companies, loading: loadingCompanies, getCompanyById } = useCompanies();
  const [formData, setFormData] = useState<Omit<Order, 'createdBy' | 'createTime'>>({
    id: '',
    orgId: '',
    companyName: '',
    products: [],
    deliveryDate: new Date(),
    nextExpectedDeliveryDate: addMonths(new Date(), 1),
    deliveryAddress: '',
    deliveryZip: '',
    deliveryCity: '',
    deliveryAtt: '',
    status: OrderStatusEnum.AWAITING,
    contactPerson: '',
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedCompany, setSelectedCompany] = useState<IOrg | null>(null);
  const [companySearchTerm, setCompanySearchTerm] = useState('');

  // Filter companies based on search term
  const filteredCompanies = companySearchTerm
    ? companies.filter(
        (company) =>
          company.title.toLowerCase().includes(companySearchTerm.toLowerCase()) ||
          company.id.toLowerCase().includes(companySearchTerm.toLowerCase())
      )
    : companies;

  // Effect for initializing create mode
  useEffect(() => {
    if (open) {
      const newOrderId = `ORD-${Math.floor(Math.random() * 10000)}`;

      setFormData({
        id: newOrderId,
        orgId: '',
        companyName: '',
        products: [],
        deliveryDate: new Date(),
        nextExpectedDeliveryDate: addMonths(new Date(), 1),
        status: OrderStatusEnum.AWAITING,
        deliveryAddress: '',
        deliveryZip: '',
        deliveryCity: '',
        deliveryAtt: '',
        contactPerson: '',
      });

      // Reset state
      setErrors({});
      setTouched({});
      setFormSubmitted(false);
      setCurrentStep(1);
      setSelectedCompany(null);
    }
  }, [open]);

  // Separate effect for URL parameter handling
  useEffect(() => {
    if (open && urlOrgId && companies.length > 0) {
      const company = getCompanyById(urlOrgId);
      if (company) {
        setSelectedCompany(company);
        console.log({ company });

        setFormData((prev) => ({
          ...prev,
          orgId: company.id,
          companyName: company.title,
          deliveryAddress: company.delivery?.address,
          deliveryZip: company.delivery?.zip,
          deliveryCity: company.delivery?.city,
          deliveryAtt: company.delivery?.att,
          contactPerson: company.contact?.name,
        }));
        setCurrentStep(2);
      }
    }
  }, [open, urlOrgId, companies, getCompanyById]);

  // Validate form data
  const validateForm = (): boolean => {
    const newErrors: ValidationErrors = {};

    if (!formData.orgId) {
      newErrors.orgId = t('orders.companySelectionRequired');
    }

    if (!formData.companyName.trim()) {
      newErrors.companyName = t('orders.companyNameRequired');
    }

    if (!formData.deliveryDate) {
      newErrors.deliveryDate = t('orders.deliveryDateRequired');
    }
    if (!formData.nextExpectedDeliveryDate) {
      newErrors.nextExpectedDeliveryDate = t('orders.nextExpectedDeliveryDateRequired');
    }

    if (!formData.deliveryAddress?.trim()) {
      newErrors.deliveryAddress = t('orders.deliveryAddressRequired');
    }
    if (!formData.deliveryZip?.trim()) {
      newErrors.deliveryZip = t('orders.deliveryZipRequired');
    }
    if (!formData.deliveryCity?.trim()) {
      newErrors.deliveryCity = t('orders.deliveryCityRequired');
    }

    if (!formData.deliveryAtt?.trim()) {
      newErrors.deliveryAtt = t('orders.deliveryAttRequired');
    }

    if (!formData.contactPerson?.trim()) {
      newErrors.contactPerson = t('orders.contactPersonRequired');
    }

    if (formData.products.length === 0) {
      newErrors.products = t('orders.productsRequired');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field: keyof Order, value: string | Date) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Mark field as touched
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));

    // Clear error for this field if it exists
    if (errors[field as keyof ValidationErrors]) {
      setErrors((prev) => ({
        ...prev,
        [field]: undefined,
      }));
    }
  };

  const handleAddProduct = () => {
    if (availableProducts.length === 0) return;

    const firstProduct = availableProducts[0];
    const newProduct = {
      id: firstProduct.id,
      name: firstProduct.title,
      quantity: 1,
      unit: undefined, //firstProduct.defaultUnit,
    };

    setFormData((prev) => ({
      ...prev,
      products: [...prev.products, newProduct],
    }));

    // Clear products error if it exists
    if (errors.products) {
      setErrors((prev) => ({
        ...prev,
        products: undefined,
      }));
    }
  };

  const handleRemoveProduct = (index: number) => {
    setFormData((prev) => {
      const newProducts = [...prev.products];
      newProducts.splice(index, 1);

      // If removing the last product, set the products error
      if (newProducts.length === 0) {
        setErrors((prev) => ({
          ...prev,
          products: t('orders.productsRequired'),
        }));
      }

      return {
        ...prev,
        products: newProducts,
      };
    });
  };

  const handleProductChange = (index: number, field: keyof Product, value: string | number) => {
    setFormData((prev) => {
      const newProducts = [...prev.products];
      newProducts[index] = { ...newProducts[index], [field]: value };
      return {
        ...prev,
        products: newProducts,
      };
    });
  };

  const getProductUnits = (productId: string): { value: number; unit: string; label: string }[] => {
    const product = availableProducts.find((p) => p.id === productId);
    // @ts-ignore
    return product?.id ? productUnitsMap[product.id] : []; //product?.availableUnits ? product.availableUnits : [];
  };

  const handleSelectCompany = (company: IOrg) => {
    setSelectedCompany(company);

    // Update form data with company information
    setFormData((prev) => ({
      ...prev,
      orgId: company.id,
      companyName: company.title,
      deliveryAddress: company.delivery?.address,
      deliveryZip: company.delivery?.zip,
      deliveryCity: company.delivery?.city,
      deliveryAtt: company.delivery?.att,
      contactPerson: company.contact?.name,
    }));

    // Clear company error if it exists
    if (errors.orgId) {
      setErrors((prev) => ({
        ...prev,
        orgId: undefined,
      }));
    }
    setCurrentStep(2);
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  async function onSave(order: Omit<Order, 'createdBy' | 'createTime'>) {
    try {
      console.log('Saving order', order);
      if (!selectedCompany?.id) {
        throw new Error('No company selected');
      }
      await firebase
        .firestore()
        .collection('orders')
        .add({
          orgId: selectedCompany.id,
          companyName: order.companyName,
          products: order.products,
          delivery: {
            date: order.deliveryDate,
            nextExpectedDeliveryDate: order.nextExpectedDeliveryDate,
            address: order.deliveryAddress,
            zip: order.deliveryZip,
            city: order.deliveryCity,
            att: order.deliveryAtt,
          },
          status: order.status,
          contact: { name: order.contactPerson },
          //
          createTime: new Date(),
          updateTime: new Date(),
          createdBy: { id: userId, displayName: userDisplayName },
        } as Omit<DBOrder, 'id'>);
      toast.success('Order oprettet ');
      onOpenChange(false);
    } catch (error) {
      console.error('Error saving order', error);
    } finally {
      setFormSubmitted(false);
    }
  }

  const handleSave = () => {
    const isValid = validateForm();
    if (!isValid) return;
    setFormSubmitted(true);

    onSave(formData);
  };

  // Helper to determine if a field has an error
  const hasError = (field: keyof ValidationErrors): boolean => {
    return !!(formSubmitted || touched[field]) && !!errors[field];
  };

  // Format date with Danish locale
  const formatDate = (date: Date | string) => {
    return format(new Date(date), 'PPP', { locale: da });
  };

  // Render the company selection step
  const renderCompanySelectionStep = () => {
    return (
      <div className="space-y-2">
        <div className="relative">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder={t('orders.searchCompanies')}
            className="pl-8"
            value={companySearchTerm}
            onChange={(e) => setCompanySearchTerm(e.target.value)}
          />
        </div>

        {loadingCompanies ? (
          <div className="text-center py-8">
            <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full mx-auto mb-2"></div>
            <p className="text-muted-foreground">{t('orders.loadingCompanies')}</p>
          </div>
        ) : filteredCompanies.length === 0 ? (
          <div className="text-center py-8 text-muted-foreground">
            {t('orders.noCompaniesFound')}
          </div>
        ) : (
          <div className="grid gap-2 h-full overflow-auto pr-1">
            {filteredCompanies.map((company) => (
              <Card
                key={company.id}
                className={`shadow-none cursor-pointer transition-colors hover:bg-muted ${
                  selectedCompany?.id === company.id ? 'border-primary' : ''
                }`}
                onClick={() => handleSelectCompany(company)}
              >
                <CardContent className="p-4">
                  <div className="flex justify-between items-center">
                    <div>
                      <h3 className="font-medium">{company.title}</h3>
                      {isDev && <p className="text-xs text-muted-foreground">{company.id}</p>}
                      <p className="text-xs text-muted-foreground">
                        {company.delivery?.address} {company.delivery?.zip} {company.delivery?.city}
                      </p>
                    </div>
                    <div className="flex flex-col items-end">
                      <p className="text-sm">{company.contact?.name}</p>
                      <p className="text-xs text-muted-foreground">{company.contact?.phone}</p>
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        )}

        {hasError('orgId') && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{errors.orgId}</AlertDescription>
          </Alert>
        )}
      </div>
    );
  };

  // Render the combined order details and delivery info step
  const renderCombinedDetailsStep = () => {
    return (
      <div className="space-y-6">
        {/* Company Info Banner */}
        <Card className="bg-muted/50">
          <CardContent className="p-4">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-medium">{selectedCompany?.title}</h3>
                <p className="text-xs text-muted-foreground">
                  {selectedCompany?.delivery?.address} {selectedCompany?.delivery?.zip}{' '}
                  {selectedCompany?.delivery?.city}
                </p>
              </div>
              {!orgId && (
                <Button variant="outline" size="sm" onClick={() => setCurrentStep(1)}>
                  {t('orders.changeCompany')}
                </Button>
              )}
            </div>
          </CardContent>
        </Card>

        {/* Order Information Section */}
        <Card>
          <CardHeader>
            <CardTitle>{t('orders.products')}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            {loadingProducts ? (
              <div className="text-center py-8">
                <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full mx-auto mb-2"></div>
                <p className="text-muted-foreground">{t('orders.loadingProducts')}</p>
              </div>
            ) : formData.products.length === 0 ? (
              <div
                className={`text-center py-8 text-muted-foreground border rounded-md ${
                  hasError('products') ? 'border-destructive' : ''
                }`}
              >
                {t('orders.noProductsAdded')}
              </div>
            ) : (
              formData.products.map((product, index) => {
                // @ts-ignore
                const unit = productUnitsMap[product.id]?.find((p: any) => p.value == product.unit);
                const totalProduct = product.quantity * unit?.value;

                return (
                  <div key={index} className="grid grid-cols-12 gap-2 items-end rounded-md">
                    <div className="col-span-5 space-y-2">
                      {index === 0 && (
                        <Label htmlFor={`product-${index}`}>{t('orders.product')}</Label>
                      )}
                      <Select
                        value={product.id}
                        onValueChange={(value) => {
                          const selectedProduct = availableProducts.find((p) => p.id === value);
                          if (selectedProduct) {
                            handleProductChange(index, 'id', value);
                            handleProductChange(index, 'name', selectedProduct.title);
                          }
                        }}
                      >
                        <SelectTrigger id={`product-${index}`}>
                          <SelectValue placeholder={t('orders.selectProduct')} />
                        </SelectTrigger>
                        <SelectContent>
                          {availableProducts.map((p) => (
                            <SelectItem key={p.id} value={p.id}>
                              {p.title}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="col-span-2 space-y-2">
                      {index === 0 && (
                        <Label htmlFor={`quantity-${index}`}>{t('orders.quantity')}</Label>
                      )}
                      <Input
                        id={`quantity-${index}`}
                        type="number"
                        value={product.quantity}
                        onChange={(e) =>
                          handleProductChange(
                            index,
                            'quantity',
                            Number.parseInt(e.target.value) || 0
                          )
                        }
                      />
                    </div>
                    <div className="col-span-2 space-y-2">
                      {index === 0 && <Label htmlFor={`unit-${index}`}>{t('orders.unit')}</Label>}
                      <Select
                        value={product.unit}
                        onValueChange={(value) => {
                          console.log({ value });
                          // @ts-ignore
                          const selectedUnit = productUnitsMap[product.id].find(
                            (p: any) => p.value == value
                          );
                          console.log({ selectedUnit });

                          handleProductChange(index, 'unit', value);
                        }}
                      >
                        <SelectTrigger id={`unit-${index}`}>
                          <SelectValue placeholder={t('orders.selectUnit')} />
                        </SelectTrigger>
                        <SelectContent>
                          {getProductUnits(product.id).map((unit) => {
                            console.log({ unit });

                            return (
                              <SelectItem key={unit.value} value={'' + unit.value}>
                                {unit.label}
                              </SelectItem>
                            );
                          })}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="col-span-2 space-y-2">
                      {index === 0 && (
                        <Label htmlFor={`total-${index}`}>
                          {t('orders.total')} ({t('Liter')})
                        </Label>
                      )}
                      <Input
                        className="bg-muted"
                        id={`total-${index}`}
                        type="number"
                        value={totalProduct}
                        readOnly
                      />
                    </div>
                    <div className="col-span-1">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => handleRemoveProduct(index)}
                      >
                        <Trash2 className="h-4 w-4" />
                      </Button>
                    </div>
                  </div>
                );
              })
            )}

            {hasError('products') && <p className="text-sm text-destructive">{errors.products}</p>}

            <Button
              onClick={handleAddProduct}
              className="w-full"
              disabled={
                loadingProducts ||
                availableProducts.length === 0 ||
                availableProducts[0].id.startsWith('DEFAULT')
              }
            >
              <Plus className="mr-2 h-4 w-4" />
              {t('orders.addProduct')}
            </Button>
          </CardContent>
        </Card>

        {/* Delivery Information Section */}
        <Card>
          <CardHeader>
            <CardTitle>{t('orders.deliveryInformation')}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid grid-cols-1 gap-4">
              <div className="space-y-2">
                <Label htmlFor="companyName" className="flex">
                  {t('orders.companyName')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="companyName"
                  value={formData.companyName}
                  onChange={(e) => handleInputChange('companyName', e.target.value)}
                  className={hasError('companyName') ? 'border-destructive' : ''}
                />
                {hasError('companyName') && (
                  <p className="text-sm text-destructive">{errors.companyName}</p>
                )}
              </div>
            </div>
            <div className="space-y-2">
              <Label htmlFor="deliveryAddress" className="flex">
                {t('orders.deliveryAddress')} <span className="text-destructive ml-1">*</span>
              </Label>
              <Input
                id="deliveryAddress"
                value={formData.deliveryAddress}
                onChange={(e) => handleInputChange('deliveryAddress', e.target.value)}
                placeholder={t('orders.enterDeliveryAddress')}
                className={hasError('deliveryAddress') ? 'border-destructive' : ''}
              />
              {hasError('deliveryAddress') && (
                <p className="text-sm text-destructive">{errors.deliveryAddress}</p>
              )}
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="deliveryZip" className="flex">
                  {t('orders.deliveryZip')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="deliveryZip"
                  value={formData.deliveryZip}
                  onChange={(e) => handleInputChange('deliveryZip', e.target.value)}
                  placeholder={t('orders.enterDeliveryZip')}
                  className={hasError('deliveryZip') ? 'border-destructive' : ''}
                />
                {hasError('deliveryZip') && (
                  <p className="text-sm text-destructive">{errors.deliveryZip}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="deliveryCity" className="flex">
                  {t('orders.deliveryCity')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="deliveryCity"
                  value={formData.deliveryCity}
                  onChange={(e) => handleInputChange('deliveryCity', e.target.value)}
                  placeholder={t('orders.enterDeliveryCity')}
                  className={hasError('deliveryCity') ? 'border-destructive' : ''}
                />
                {hasError('deliveryCity') && (
                  <p className="text-sm text-destructive">{errors.deliveryCity}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="deliveryDate" className="flex">
                  {t('orders.deliveryDate')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="deliveryDate"
                      variant="outline"
                      className={cn(
                        'w-full justify-start text-left font-normal',
                        !formData.deliveryDate && 'text-muted-foreground',
                        hasError('deliveryDate') && 'border-destructive'
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {formData.deliveryDate ? (
                        formatDate(formData.deliveryDate)
                      ) : (
                        <span>{t('orders.pickDate')}</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      key="deliveryDate"
                      mode="single"
                      selected={formData.deliveryDate}
                      onSelect={(date) => {
                        if (date) {
                          handleInputChange('deliveryDate', date);
                        }
                      }}
                      initialFocus
                      locale={da}
                    />
                  </PopoverContent>
                </Popover>
                {hasError('deliveryDate') && (
                  <p className="text-sm text-destructive">{errors.deliveryDate}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="nextExpectedDeliveryDate" className="flex">
                  {t('orders.nextExpectedDeliveryDate')}{' '}
                  <span className="text-destructive ml-1">*</span>
                </Label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      id="nextExpectedDeliveryDate"
                      variant="outline"
                      className={cn(
                        'w-full justify-start text-left font-normal',
                        !formData.nextExpectedDeliveryDate && 'text-muted-foreground',
                        hasError('nextExpectedDeliveryDate') && 'border-destructive'
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {formData.nextExpectedDeliveryDate ? (
                        formatDate(formData.nextExpectedDeliveryDate)
                      ) : (
                        <span>{t('orders.pickDate')}</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      key="nextExpectedDeliveryDate"
                      mode="single"
                      selected={formData.nextExpectedDeliveryDate}
                      defaultMonth={formData.nextExpectedDeliveryDate}
                      onSelect={(date) => {
                        if (date) {
                          handleInputChange('nextExpectedDeliveryDate', date);
                        }
                      }}
                      initialFocus
                      locale={da}
                    />
                  </PopoverContent>
                </Popover>
                {hasError('nextExpectedDeliveryDate') && (
                  <p className="text-sm text-destructive">{errors.nextExpectedDeliveryDate}</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="deliveryAtt" className="flex">
                  {t('orders.deliveryAtt')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="deliveryAtt"
                  value={formData.deliveryAtt}
                  placeholder={t('orders.enterDeliveryAtt')}
                  onChange={(e) => handleInputChange('deliveryAtt', e.target.value)}
                  className={hasError('deliveryAtt') ? 'border-destructive' : ''}
                />
                {hasError('deliveryAtt') && (
                  <p className="text-sm text-destructive">{errors.deliveryAtt}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="contactPerson" className="flex">
                  {t('orders.contactPerson')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="contactPerson"
                  value={formData.contactPerson}
                  placeholder={t('orders.enterContactPerson')}
                  onChange={(e) => handleInputChange('contactPerson', e.target.value)}
                  className={hasError('contactPerson') ? 'border-destructive' : ''}
                />
                {hasError('contactPerson') && (
                  <p className="text-sm text-destructive">{errors.contactPerson}</p>
                )}
              </div>
            </div>
          </CardContent>
          <CardFooter className={`flex ${urlOrgId ? 'justify-end' : 'justify-between'}`}>
            {!urlOrgId && (
              <Button variant="outline" onClick={handlePrevStep}>
                {t('general.back')}
              </Button>
            )}
            <Button onClick={handleSave}>{t('general.create')}</Button>
          </CardFooter>
        </Card>
      </div>
    );
  };

  // Update the useProducts hook call to pass the orgId
  const { products: availableProducts, loadingProducts } = useProducts(formData.orgId);

  // Render the products step

  const renderStepIndicator = () => {
    const steps = [
      { number: 1, label: t('orders.selectCompany'), icon: Building2 },
      { number: 2, label: t('orders.orderInfo'), icon: Package },
    ];

    return (
      <div className="flex items-center justify-start px-6 py-3 border-b">
        {steps.map((step, index) => (
          <div key={step.number} className="flex items-center">
            <div
              className={`flex items-center justify-center w-8 h-8 rounded-full ${
                currentStep >= step.number
                  ? 'bg-primary text-primary-foreground'
                  : 'bg-muted text-muted-foreground'
              }`}
            >
              <step.icon className="h-4 w-4" />
            </div>
            <span
              className={`ml-2 text-sm hidden sm:inline ${
                currentStep >= step.number ? 'text-foreground font-medium' : 'text-muted-foreground'
              }`}
            >
              {step.label}
            </span>
            {index < steps.length - 1 && (
              <div
                className={`w-8 h-0.5 mx-2 ${
                  currentStep > step.number ? 'bg-primary' : 'bg-muted'
                }`}
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  // Render the appropriate content based on step
  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return renderCompanySelectionStep();

      case 2:
        return renderCombinedDetailsStep();

      default:
        return null;
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl max-h-[90vh] overflow-hidden flex flex-col p-0 gap-0">
        <DialogHeader className="px-6 pt-6 pb-2">
          <DialogTitle>{t('orders.createNewOrder')}</DialogTitle>
        </DialogHeader>

        {/* Step indicator */}
        {renderStepIndicator()}

        <div className="flex-1 overflow-hidden">
          <ScrollArea className="h-[calc(90vh-9rem)]">
            <div className="p-6 py-2">
              {formSubmitted && Object.keys(errors).length > 0 && (
                <Alert variant="destructive" className="mb-6">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>
                    {Object.entries(errors).map(([_, error]) => (
                      <div>{t(error)}</div>
                    ))}
                  </AlertDescription>
                </Alert>
              )}

              {renderContent()}
            </div>
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  );
}
