import keyBy from 'lodash.keyby';
import { useAppSelector } from '../../hooks';

export default function AuditTitle() {
  const audit = useAppSelector((state) => state.auditSchedules.currentAuditSchedule);
  const { templateId, auditTemplatesByKey } = useAppSelector((state) => ({
    templateId: state.auditSchedules.currentAuditSchedule?.templateId,
    auditTemplatesByKey: keyBy(state.auditTemplates.auditTemplates, (template) => template.id),
  }));

  return (
    <h1 className="text-xl font-bold" style={{ marginTop: '0px' }}>
      {templateId && (audit?.templateTitle || auditTemplatesByKey[templateId]?.title)}
    </h1>
  );
}
