import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router';
import { connect } from 'react-redux';
import HourLogsList from './HourLogsList';
import { fetchCleanLogs, addCleanLog, updateCleanLog } from '../../modules/cleanLogs/actions';
import { fetchOrgProducts, updateOrgProduct } from '../../modules/products/actions';
import { AppState } from '../../store';
import { fetchHourTrackers } from '../../modules/hourTracker/actions';
import useOrg from '../../hooks/useOrg';
import { fetchHourLogs } from '../../modules/hourLogs/actions';

function HourLogsListContainer(props: any) {
  const { orgId } = useParams<{ orgId: string }>();
  useEffect(() => {
    props.fetchHourLogs({ orgId });
    props.fetchHourTrackers({ orgId });
    return () => {};
  }, []);
  console.log({ logs: props.cleanLogs });

  return (
    <HourLogsList
      // {...props}
      cleanLogs={props.cleanLogs}
      orgId={orgId}
      loading={props.loading}
      // addCleanLog={({ initials, amount, productId }: any) =>
      //     props.addCleanLog({ orgId, initials, amount, productId })
      // }
      // updateCleanLog={({ logId, data }: any) =>
      //     props.updateCleanLog({ orgId, logId, data })
      // }
      hourTrackers={props.hourTrackers}
      // updateOrgProduct={({ productId, data }: any) =>
      //     props.updateOrgProduct({ orgId, productId, data })
      // }
      // removeMember={(memberId) => this.props.removeMember({ orgId, members: this.props.members, memberId })}
    />
  );
}

function mapStateToProps(state: AppState) {
  return {
    cleanLogs: state.hourLogs.logs,
    loading: state.hourTracker.loading,
    hourTrackers: state.hourTracker.list,
  };
}

const mapActionsToProps = {
  fetchHourTrackers,
  fetchHourLogs,
  // removeMember,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(HourLogsListContainer);
