import { useState, useEffect } from 'react';
import { type Order } from '../lib/types';
import firebase from 'firebase/compat/app';
import { useAppSelector } from 'hooks';

export const isDeprecatedOrder = (order: { products: Order['products'] }) => {
  if (!order?.products || order.products.length < 1) {
    return true;
  }
  return false;
  // const orderDate = new Date(order.createTime);
  // return orderDate < today;
};

export function useOrdersById(orgId?: string | null) {
  const [orders, setOrders] = useState<Order[]>([]);
  const productMap = useAppSelector((state) => state.products.productsMap);
  const orgMap = useAppSelector((state) => state.orgs.orgMap);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!orgId) return;
    setLoading(true);
    let unsubscribe: any = null;
    unsubscribe = firebase
      .firestore()
      .collection('orders')
      .where('orgId', '==', orgId)
      .orderBy('createTime', 'desc')
      .onSnapshot(
        (query) => {
          const orders = query.docs.map((doc) => {
            const data = doc.data();
            const deprecatedOrder = isDeprecatedOrder(data as { products: Order['products'] });
            return {
              // TEMPORARY FIX: remove this when migrated all orders
              isDeprecatedOrder: deprecatedOrder,
              id: doc.id,
              createTime: data.createTime.toDate(),
              products: deprecatedOrder
                ? [
                    {
                      id: data.productId,
                      name: productMap[data.productId]?.title,
                      quantity: data.amount,
                      // all old orders are in liters
                      unit: 1,
                    },
                  ]
                : data.products?.map((product: any) => ({
                    ...product,
                    quantity: product.quantity || 0,
                  })) || [],
              deliveryDate: data.delivery?.date.toDate(),
              deliveryAddress: data.delivery?.address,
              deliveryZip: data.delivery?.zip,
              deliveryCity: data.delivery?.city,
              deliveryAtt: data.delivery?.att,
              contactPerson: data.contact?.name,
              companyName: data.companyName,
              orgId: data.orgId,
              status: data.status,
              createdBy: {
                id: data.createdBy?.id,
                displayName: data.createdBy?.displayName,
              },
            };
          }) as Order[];

          setOrders(orders);

          setLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
    return unsubscribe;
  }, [orgId]);

  return {
    orders,
    loading,
  };
}
