import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { fetchInventoryEspAq } from '../../../modules/inventory/esp_aq/actions';
import { EspAqItem } from '../../../modules/inventory/esp_aq/types';

function sortByDeviceId(a: EspAqItem, b: EspAqItem) {
  return a.deviceId.localeCompare(b.deviceId);
}

function useInventoryEspAq() {
  const dispatch = useDispatch();
  const espAq = useSelector((state: AppState) =>
    state.inventory.espAqIds
      .map((id) => state.inventory.inventoryMap[id] as EspAqItem)
      .sort(sortByDeviceId)
  ) as EspAqItem[];

  useEffect(() => {
    dispatch(fetchInventoryEspAq());
  }, [dispatch]);
  return { espAq };
}

export default useInventoryEspAq;
