import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export default function useInCompleteOrgOrders(orgId: string) {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  useEffect(() => {
    if (orgId) {
      setLoading(true);
      let unsubscribe: any = null;
      unsubscribe = firebase
        .firestore()
        .collection('orders')
        .where('orgId', '==', orgId)
        .where('status', 'in', ['awaiting', 'processing', 'confirmed'])
        .orderBy('createTime', 'desc')
        .limit(1)
        .onSnapshot(
          (query) => {
            const orders = query.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            })) as any[];
            setOrders(orders);

            setLoading(false);
          },
          (error) => {
            console.error(error);
            setLoading(false);
          }
        );
      return unsubscribe;
    }
  }, [orgId]);

  return {
    orders,
    loadingOrders: loading,
  };
}
