import React, { useState } from 'react';
import { Row, Col, Layout, Breadcrumb, List, Typography, Segmented } from 'antd';
// import gps from "../../assets/images/gps-icon.jpg";
import UploadReport from './components/UploadReport';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import Loader from '../../components/Loader';
import { UploadContext } from 'general/constants';
import Report from './Report';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItems, { sortByTitle } from './ListItems';
import { AppstoreOutlined, BarsOutlined, FolderOutlined, FolderTwoTone } from '@ant-design/icons';
import AddDirectory from 'Views/Directories/AddDirectory';
import Directories from 'Views/Directories/Directories';
import { DBDirectoryShape } from 'modules/directories/types';
import { DBReportShape } from 'modules/reports/types';
import SearchInput from './Search';

const { Content } = Layout;

const Location = ({
  orgId,
  breadCrumbPath,
}: {
  orgId: string;
  breadCrumbPath: { id: string; title: string }[];
}) => {
  const { t } = useTranslation();
  const items = [
    {
      title: (
        <Link to={`/orgs/${orgId}/reports`} style={{ height: 28 }}>
          <Typography.Paragraph style={{ fontSize: 18, margin: 0 }} type={'secondary'}>
            {t('reports.folder_breadcrumb')}
          </Typography.Paragraph>
        </Link>
      ),
    },
    ...breadCrumbPath.map((bc, index) => {
      const isLast = breadCrumbPath?.length - 1 === index;
      return {
        // path: `/orgs/${orgId}/reports/${bc.id}`,
        title: (
          <Link to={`/orgs/${orgId}/reports/${bc.id}`} style={{ height: 28 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FolderOutlined style={{ color: isLast ? '#333' : 'inherit' }} />
              <div style={{ marginLeft: 4 }} />
              <Typography.Paragraph
                style={{ fontSize: 18, margin: 0 }}
                type={isLast ? undefined : 'secondary'}
              >
                {bc.title}
              </Typography.Paragraph>
            </div>
          </Link>
        ),
      };
    }),
  ];
  return (
    <Breadcrumb items={items} />
    // <Breadcrumb.Item>
    //     <Link to={`/orgs/${orgId}/reports`}>
    //         {t("reports.folder_breadcrumb")}
    //     </Link>
    // </Breadcrumb.Item>
    // {breadCrumbPath.map(bc => (
    //     <Breadcrumb.Item>
    //         <Link to={`/orgs/${orgId}/reports/${bc.id}`}>{bc.title}</Link>
    //     </Breadcrumb.Item>
    // ))}
    // </Breadcrumb>
  );
};

// function ReportListItems({ data }: { data: any[] }) {
//     console.log(data);

//     return (

//     );
// }

export enum ViewType {
  GRID = 'GRID',
  LIST = 'LIST',
}

interface Props {
  currentDirectory: DBDirectoryShape;
  breadCrumbPath: { id: string; title: string }[];
  directories: DBDirectoryShape[];
  reports: DBReportShape[];
  orgId: string;
  directoryId: string;
  loading: boolean;
  updateReport: ({
    orgId,
    reportId,
    data,
  }: {
    orgId: string;
    reportId: string;
    data: { title?: string; isDeleted?: boolean };
  }) => void;
}

function ReportList(props: Props) {
  const { directoryId } = useParams<{ directoryId: string }>();
  const [toggleView, setToggleView] = useState(ViewType.LIST);
  const directories = props.directories
    .filter((d) => d.parentDirectoryId === directoryId)
    .map((directory) => ({
      ...directory,
      orgId: props.orgId,
      directories: props.directories,
      isDirectory: true,
      title: directory.title ?? '',
    }))
    .sort(sortByTitle);
  const files = props.reports
    .map((report: any) => ({
      ...report,
      orgId: props.orgId,
      directories: props.directories,
    }))
    .sort(sortByTitle);
  const combindDirectoryFiles = [...directories, ...files];
  const reports = files.map((report: any) => (
    <Report
      key={report.id}
      orgId={report.orgId}
      {...report}
      directories={report.directories}
      // deleteOrg={() => props.deleteOrg(org.id)}
    />
  ));
  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar
        // backLink={`/orgs/${props.orgId}/reports`}
        // backText="Tilbage til rapporter"
        />
        <Content style={{ margin: '50px 24px 0' }}>
          {/* <button onClick={() => setToggleView(!toggleView)}>Toggle</button> */}
          {props.loading ? (
            <Loader />
          ) : (
            <>
              <Row>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Location
                      orgId={props.orgId}
                      breadCrumbPath={props.breadCrumbPath}
                      // directoryId={props.currentDirectory?.id}
                      // directoryTitle={props.currentDirectory?.title}
                    />
                    <AddDirectory />
                  </div>
                </Col>
                <Col span={24} style={{ marginBottom: 20 }}>
                  <UploadReport contextId={props.directoryId} context={UploadContext.REPORT} />
                </Col>
              </Row>
              <Row>
                <Col
                  span={24}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 20,
                  }}
                >
                  <div style={{ width: '100%', marginRight: 24 }}>
                    <SearchInput placeholder="Søg efter filer og mapper" />
                  </div>
                  {/* @ts-ignore */}
                  <Segmented
                    value={toggleView}
                    options={[
                      {
                        label: 'Liste',
                        value: ViewType.LIST,
                        icon: <BarsOutlined />,
                      },
                      {
                        label: 'Gitter',
                        value: ViewType.GRID,
                        icon: <AppstoreOutlined />,
                      },
                    ]}
                    onChange={(value: any) => setToggleView(value as ViewType)}
                  />
                </Col>
              </Row>
              {toggleView === ViewType.LIST ? (
                <ListItems data={combindDirectoryFiles} />
              ) : (
                <Row gutter={[16, 16]}>
                  <Directories directories={directories} orgId={props.orgId} />
                  {reports}
                </Row>
              )}
            </>
          )}
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}

export default ReportList;
