import { useEffect, useState } from 'react';
import { OrgProduct } from '../../../modules/products/types';
import firebase from 'firebase/compat/app';

export default function useOrgProducts(orgId: string) {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<OrgProduct[]>([]);
  useEffect(() => {
    if (orgId) {
      setLoading(true);
      let unsubscribe: any = null;
      unsubscribe = firebase
        .firestore()
        .collection('orgs')
        .doc(orgId)
        .collection('products')
        .onSnapshot(
          (query) => {
            if (!query.empty) {
              const group = query.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              })) as OrgProduct[];
              setProducts(group);
            }
            setLoading(false);
          },
          (error) => {
            console.error(error);
            setLoading(false);
          }
        );
      return unsubscribe;
    }
  }, [orgId]);

  return {
    products,
    loadingProducts: loading,
  };
}
