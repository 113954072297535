import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { List } from 'antd';
import CategoryInput from './CategoryInput';
import { useParams } from 'react-router';
import useCategories from '../../hooks/useCategories';
import { useTranslation } from 'react-i18next';

function CategoryList() {
  const { orgId } = useParams<{ orgId: string }>();
  const { t } = useTranslation();
  const { categories, dispatchFetchCategories, dispatchAddCategories, dispatchDeleteCategory } =
    useCategories();

  useEffect(() => {
    dispatchFetchCategories(orgId);
  }, [orgId]);

  return (
    <div>
      <label>
        {t('settings.categories_label')}:
        <List
          size="small"
          // header={<div>Header</div>}
          footer={
            <CategoryInput addCategories={(title: string) => dispatchAddCategories(orgId, title)} />
          }
          bordered
          dataSource={categories}
          renderItem={(item: any) => (
            <List.Item
              key={item.id}
              actions={[
                <a onClick={() => dispatchDeleteCategory(orgId, item.id)}>
                  {t('settings.categories_delete')}
                </a>,
              ]}
              style={{ backgroundColor: '#fff' }}
            >
              {item.title}
            </List.Item>
          )}
        />
      </label>
    </div>
  );
}

export default CategoryList;
