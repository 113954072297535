import { useState } from 'react';
import { useSpring, animated, config } from 'react-spring';

export default function AnimatedNumber({ num }: { num: number }) {
  const [flip, set] = useState(false);
  const { number } = useSpring({
    // reset: true,
    // reverse: flip,
    from: { number: 0 },
    number: num,
    delay: 100,
    config: config.molasses,
    // onRest: () => set(!flip),
  });

  return <animated.div>{number.to((n: any) => n.toFixed(0))}</animated.div>;
}
