import { Icon } from '@iconify/react';
import { Button, Modal, Space, Tooltip } from 'antd';

import { useAppSelector } from 'hooks';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StyleGuide } from 'styles/StyleGuide';
import firebase from 'firebase/compat/app';
import Message, { Type } from 'components/Notification/Message';
import { AuditSchedule, AuditScheduleTask } from 'modules/auditSchedule/types';
import { IOrg, MemberValues } from 'modules/orgs/interfaces';
import TextArea from 'antd/es/input/TextArea';
import { DiscardType } from 'modules/audits/types';
interface Props {
  orgId: string;
  auditId: string;
  taskId: string;
}

export async function discardAuditTask({
  user,
  org,
  audit,
  taskId,
  orgId,
  auditId,
  task,
  discardNote,
}: {
  user: MemberValues;
  org: IOrg;
  audit: AuditSchedule;
  taskId: string;
  orgId: string;
  auditId: string;
  task: AuditScheduleTask;
  discardNote: string;
}) {
  const payload = {
    discardType: DiscardType.AUDIT_TASK,
    isDiscarded: true,
    discardNote,
    discardTime: new Date(),
    discardedBy: {
      uid: user.uid,
      name: user.name || user.email,
    },
    org: {
      id: orgId,
      title: org.title,
    },
    subGroup: org.subGroup?.id
      ? {
          id: org.subGroup.id,
          title: org?.subGroup.title,
        }
      : null,
    audit: {
      id: auditId,
    },
    auditTemplate: {
      id: audit.templateId,
      title: audit.templateTitle,
      auditTemplateCategoryId: audit.auditTemplateCategory || null,
    },
    group: org.groupId
      ? {
          id: org.groupId,
        }
      : null,
    task: {
      id: taskId,
      description: task.description,
    },
  };
  if (!payload.audit.id || !payload.task.id || !payload.org.id) {
    throw new Error('Missing required data');
  }

  const batch = firebase.firestore().batch();

  const auditRef = firebase
    .firestore()
    .collection('orgs')
    .doc(orgId)
    .collection('auditSchedules')
    .doc(auditId);

  const discardAuditTasksRef = firebase.firestore().collection('discardedAuditTasks').doc();

  batch.update(auditRef, {
    [`tasks.${taskId}`]: firebase.firestore.FieldValue.delete(),
  });

  batch.set(discardAuditTasksRef, payload);

  return batch.commit();
}

export default function AuditTaskDiscardAction({ orgId, auditId, taskId }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [discardNote, setDiscardNote] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const task = useAppSelector((state) => state.auditSchedules.currentAuditSchedule.tasks[taskId]);
  const audit = useAppSelector((state) => state.auditSchedules.currentAuditSchedule);
  const org = useAppSelector((state) => state.orgs.orgMap[orgId]);
  const user = useAppSelector((state) => state.orgs.currentOrg.members[state.user.details.uid]);
  // const [isOnHold, setisOnHold] = useState(false);
  // const [form] = Form.useForm();

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = async () => {
    try {
      Message({
        key: 'discardTask',
        message: 'Loading...',
        type: Type.LOADING,
      });

      await discardAuditTask({
        user,
        org,
        audit,
        taskId,
        orgId,
        auditId,
        task,
        discardNote,
      });

      setOpen(false);
      Message({
        key: 'discardTask',
        message: 'Opgaven blev kasseret',
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'discardTask',
        message: 'Der skete en fejl',
        type: Type.ERROR,
      });
    }
  };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          task.isCompleted ? t('Opgaven er allerede vinget af og kan derfor ikke kasseres') : ''
        }
      >
        <Button
          type="text"
          block
          style={{ textAlign: 'left', color: StyleGuide.palette.redish }}
          disabled={task.isCompleted}
          onClick={showModal}
          icon={
            <span role="img" aria-label="pause-circle" className="anticon anticon-pause-circle">
              <Icon
                color={StyleGuide.palette.redish}
                fontSize={16}
                icon="heroicons:archive-box-x-mark" /*style={{ color: Colors.warning }}*/
              />
            </span>
          }
        >
          {t('Kassér')}
        </Button>
      </Tooltip>

      <Modal
        destroyOnClose
        title={t(`Kassér opgaven?`)}
        open={open}
        okText={t('Kassér')}
        cancelText={t('Fortryd')}
        onOk={handleOk}
        okButtonProps={{ disabled: !discardNote }}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <>
          <ol style={{ paddingInlineStart: 16 }}>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t('<strong>{{taskTitle}}</strong> vil blive markeret som kasseret', {
                  taskTitle: task.description,
                })}
              />
            </li>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t('<strong>{{taskTitle}}</strong> flyttes til kasserede listen', {
                  taskTitle: task.description,
                })}
              />
            </li>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t('Fremtidige opgaver vil ikke inkludere <strong>{{taskTitle}}</strong>', {
                  taskTitle: task.description,
                })}
              />
            </li>
            <li style={{ paddingInlineStart: 8 }}>
              <Trans
                i18nKey={t(
                  'Skabelonen vil blive opdateret og <strong>{{taskTitle}}</strong> vil ikke længere fremgå',
                  {
                    taskTitle: task.description,
                  }
                )}
              />
            </li>
          </ol>
          <TextArea
            placeholder={t('Notér hvorfor opgaven kasseres')}
            value={discardNote}
            onChange={(e) => setDiscardNote(e.target.value)}
          />
        </>
      </Modal>
    </>
  );
}
