import { useRef, useState } from 'react';
import { Layout, Row, Col, List, Button, Tabs, Input, Select, Tour } from 'antd';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import { Link } from 'react-router-dom';
import { PlusCircleOutlined } from '@ant-design/icons';
import { IssueItem, IssueItemCompleted } from './IssueItem';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';
import BlurOverlayContainer from '../../components/Overlay/BlurOverlayContainer';
import { useTranslation } from 'react-i18next';
import { Issue, TAB_STATE } from '../../modules/issues/types';
import { useAppSelector } from 'hooks';
import IssuesSearch from './IssuesSearch';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

interface Props {
  // maybe change this
  currentTab: TAB_STATE;
  orgId: string;
  loading: boolean;
  issues: Issue[];
  setCurrentTab: ({ key }: { key: TAB_STATE }) => void;
}

function IssueList(props: Props) {
  // const { refs, setOpen } = useTourRefs();
  const { t } = useTranslation();
  const [categoryFilter, setCategoryFilter] = useState<string[]>([]);
  const issues = props.issues.filter((issue) => {
    if (categoryFilter.length === 0) {
      return true;
    }
    return categoryFilter.some((e) => issue.tags.includes(e));
  });
  const options = useAppSelector((state) =>
    state.categories.list
      .sort((a, b) =>
        a.title.localeCompare(b.title, 'da', {
          sensitivity: 'base',
          numeric: true,
        })
      )
      .map((category) => ({
        value: category.id,
        label: category.title,
      }))
  );
  const { orgId } = props;
  const handleChange = (value: string[]) => {
    console.log(`selected ${value}`);
    setCategoryFilter(value);
  };
  // console.log({ ref: refs.issuesSearch });
  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.ISSUES}>
          <Content style={{ margin: '50px 24px 0' }}>
            <Row gutter={16}>
              <Col md={10} xs={12}>
                <IssuesSearch />
                {/* <Search
                                    placeholder={t("issues.search.placeholder")}
                                    onSearch={onSearch}
                                    allowClear
                                /> */}
              </Col>
              <Col md={8} xs={12}>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Søg kategorier"
                  value={categoryFilter}
                  onChange={handleChange}
                  options={options}
                />
              </Col>
              <Col md={6} xs={24}>
                <div>
                  <Link to={`/orgs/${orgId}/create-issue`}>
                    <Button type="primary" style={{ float: 'right' }}>
                      {t('issues.overview.reporterror')}
                      <PlusCircleOutlined />
                    </Button>
                  </Link>
                </div>
              </Col>
              <Col md={24} xs={24}>
                <Tabs
                  activeKey={props.currentTab}
                  onChange={(key) => props.setCurrentTab({ key: key as TAB_STATE })}
                >
                  <TabPane tab={t('issues.overview.error')} key={TAB_STATE.INCOMPLETED}>
                    <List
                      key="incompleted-list"
                      className="demo-loadmore-list"
                      loading={props.loading}
                      itemLayout="horizontal"
                      locale={{ emptyText: 'ingen data' }}
                      // loadMore={loadMore}
                      dataSource={issues}
                      renderItem={(item) => (
                        <IssueItem
                          key={item.id}
                          hasAssignee={Boolean(item?.assignee?.id)}
                          {...item}
                          tags={
                            item.displayTags ||
                            options
                              ?.filter((obj) => item.tags.includes(obj.value))
                              .map((obj) => obj.label)
                          }
                        />
                      )}
                    />
                  </TabPane>
                  <TabPane tab={t('issues.overview.fixederror')} key={TAB_STATE.COMPLETED}>
                    <List
                      key="completed-list"
                      className="demo-loadmore-list"
                      loading={props.loading}
                      itemLayout="horizontal"
                      locale={{ emptyText: 'ingen data' }}
                      // loadMore={loadMore}
                      dataSource={issues}
                      renderItem={(item) => {
                        return (
                          <IssueItemCompleted
                            key={item.id}
                            {...item}
                            tags={
                              item.displayTags ||
                              options
                                ?.filter((obj) => item.tags.includes(obj.value))
                                .map((obj) => obj.label)
                            }
                          />
                        );
                      }}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default IssueList;
