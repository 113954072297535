import React, { useState } from 'react';
import qs from 'qs';
import { Select } from 'antd';
import type { SelectProps } from 'antd';
import {
  downloadConvertedFile,
  openFileInBrowser,
  sortByTitle,
  supportedOpenInBrowserContentTypes,
} from './ListItems';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { DBDirectoryShape } from 'modules/directories/types';
import { DBReportShape } from 'modules/reports/types';
import { useHistory, useParams } from 'react-router';
import { getFullDependencyPath } from './ReportListContainer';
import { FolderTwoTone } from '@ant-design/icons';

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (
  value: string,
  callback: Function,
  data: ((DBDirectoryShape & { isDirectory: boolean }) & DBReportShape)[]
) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

  const fake = () => {
    const filtered = data
      .filter((d: any) => d.title.toLowerCase().includes(value.toLowerCase()))
      .sort((a, b) => {
        // If a is a directory and b isn't, put a before b
        if (a.isDirectory && !b.isDirectory) return -1;

        // If b is a directory and a isn't, put b before a
        if (!a.isDirectory && b.isDirectory) return 1;

        // If both are directories or both are files, sort by name
        return a?.title!.localeCompare(b?.title!);
      });

    callback(filtered);
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};

export default function SearchInput(props: any) {
  const s = useHistory();
  const { orgId } = useParams<{ orgId: string }>();
  const combined = useSelector((state: AppState) =>
    [
      ...state.directories.directories.map((directory) => ({
        ...directory,
        orgId: props.orgId,
        directories: props.directories,
        isDirectory: true,
        title: directory.title ?? '',
      })),
      ...state.reports.reports.map((report: any) => ({
        ...report,
        orgId: props.orgId,
        directories: props.directories,
      })),
    ].sort(sortByTitle)
  );

  const [data, setData] = useState<SelectProps['options']>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    fetch(newValue, setData, combined);
  };

  const handleChange = (newValue: string) => {
    const file = combined.find((d) => d.id === newValue);
    if (!file) return;
    console.log({ file });
    if (file.isDirectory) {
      return s.push(`/orgs/${orgId}/reports/${newValue}`);
    }
    // if (file.trail.length > 0) {
    //     return s.push(`/orgs/${orgId}/reports/${file.path[file.path.length - 1].id}`);
    // }
    if (supportedOpenInBrowserContentTypes.includes(file.contentType)) {
      openFileInBrowser(file.filePath, file.title, file.contentType);
    } else {
      downloadConvertedFile(file.filePath, file.title, file.contentType);
    }
  };

  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      style={{ ...props.style, width: '100%' }}
      defaultActiveFirstOption={false}
      suffixIcon={null}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.id,
        label: (
          <div>
            {d.isDirectory && (
              <FolderTwoTone
                style={{
                  marginRight: 4,
                }}
                // @ts-ignore
                theme="twoTone"
              />
            )}
            {d.title}
          </div>
        ),
      }))}
    />
  );
}
