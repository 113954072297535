import { useState, useEffect } from 'react';
import { Check, ChevronsUpDown } from 'lucide-react';
import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { useAudit } from './audit-context';

export function LocationSelection() {
  const { locations, selectedLocation, setSelectedLocation, setStep, saveToLocalStorage } =
    useAudit();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    saveToLocalStorage();
  }, [selectedLocation, saveToLocalStorage]);

  return (
    <div className="flex flex-col items-center space-y-4">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="truncate">
              {selectedLocation
                ? locations.find((location) => location.id === selectedLocation)?.title
                : 'Vælg afdeling/site...'}
            </span>
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-full p-0">
          <Command>
            <CommandInput placeholder="Søg efter afdeling..." />
            <CommandList>
              <CommandEmpty>Ingen match</CommandEmpty>
              <CommandGroup>
                {locations.map((location) => (
                  <CommandItem
                    key={location.id}
                    value={location.id}
                    onSelect={(currentValue) => {
                      setSelectedLocation(currentValue === selectedLocation ? '' : currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedLocation === location.id ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {location.title}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      {/* <Button onClick={() => setStep('tasks')} disabled={!selectedLocation}>
        Næste
      </Button> */}
    </div>
  );
}
