import { CaretRightFilled } from '@ant-design/icons';
import groupBy from 'lodash.groupby';
import { decimalFormat } from './utils';

export default function TaskBlock({ tasks }: { tasks: any }) {
  return (
    <div
      style={{
        paddingLeft: 16,
      }}
    >
      <div>
        {Object.entries(tasks).map(([_, task]: any) => {
          const fieldsByType = groupBy(task.customFields, 'unit');
          console.log({
            fieldsByType,
          });

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: 8,
                cursor: 'pointer',
              }}
            >
              <div style={{ display: 'flex' }}>
                <div>{task.description}</div>
              </div>
              <div>
                {Object.entries(fieldsByType)
                  .sort()
                  .map(([type, fields]: any) => {
                    const value = fields.reduce((acc: number, field: any) => {
                      return acc + field.value || 0;
                    }, 0);

                    return (
                      <div style={{ display: 'flex' }}>
                        <div>
                          {decimalFormat.format(value)} {type}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
