import { Form, Input, Button } from 'antd';
import { useState } from 'react';
import { post } from '../../general/api';
import Message, { Type } from 'components/Notification/Message';
import { useTranslation } from 'react-i18next';

const PasswordForm = () => {
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const { t } = useTranslation();
  const onFinish = async (values: any) => {
    try {
      setloading(true);
      Message({
        key: 'updateUser',
        message: t('Loading...'),
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      console.log('Success:', values);
      await post('updateUser', {
        uid: values.userId,
        email: values.password,
      });
      Message({
        key: 'updateUser',
        message: t('User was updated'),
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.SUCCESS,
      });
      form.resetFields();
    } catch (error) {
      console.error(error);
      Message({
        key: 'updateUser',
        type: Type.ERROR,
        message: t(`Der skete en fejl`),
        description: t(`Kunne ikke opdaterer burger, prøv venligst igen`),
      });
    } finally {
      setloading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="User Id"
        name="userId"
        rules={[{ required: true, message: t('Please input your username!') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: t('Please input your password!') }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('Submit')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PasswordForm;
