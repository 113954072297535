import { t } from 'i18next'; // Assuming react-i18next is set up
import { format } from 'date-fns';
import { RRule, Weekday } from 'rrule';

export const generateCronExpression = (recurrence: any, hour: number, min: number) => {
  const { frequency, interval, daysOfWeek, dayOfMonth, monthOfYear } = recurrence;
  let cronExpression = '';

  switch (frequency) {
    case 'Daily':
      cronExpression = `${min} ${hour} */${interval} * *`;
      break;
    case 'Weekly':
      const days = daysOfWeek.length > 0 ? daysOfWeek.join(',') : '*';
      cronExpression = `${min} ${hour} * * ${days}`;
      break;
    case 'Monthly':
      cronExpression = `${min} ${hour} ${dayOfMonth} */${interval} *`;
      break;
    case 'Yearly':
      const month = new Date(Date.parse(monthOfYear + ' 1, 2021')).getMonth() + 1;
      cronExpression = `${min} ${hour} ${dayOfMonth} ${month} */${interval}`;
      break;
    default:
      cronExpression = '* * * * *';
  }

  return cronExpression;
};

export const parseCron = (cron: string) => {
  const [minute, hour, dayOfMonth, month, dayOfWeek] = cron.split(' ');

  const translateMinute = () => {
    if (minute === '*') return t('Every minute');
    if (/^\d+$/.test(minute)) return t('At minute {{minute}}', { minute });
    return t('Custom minute schedule');
  };

  const translateHour = () => {
    if (hour === '*') return t('Every hour');
    if (/^\d+$/.test(hour)) return t('At {{hour}}:00', { hour });
    return t('Custom hour schedule');
  };

  const translateDayOfMonth = () => {
    if (dayOfMonth === '*') return t('Every day');
    if (/^\d+$/.test(dayOfMonth)) return t('On the {{day}} day of the month', { day: dayOfMonth });
    return t('Custom day schedule');
  };

  const translateMonth = () => {
    if (month === '*') return t('Every month');
    const monthNames = Array.from({ length: 12 }, (_, i) => format(new Date(2024, i), 'MMMM'));
    if (/^\d+$/.test(month)) return t('In {{month}}', { month: monthNames[+month - 1] });
    return t('Custom month schedule');
  };

  const translateDayOfWeek = () => {
    if (dayOfWeek === '*') return t('Every day of the week');
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    if (/^\d+$/.test(dayOfWeek)) return t('On {{day}}', { day: dayNames[+dayOfWeek] });
    return t('Custom weekday schedule');
  };

  return [
    translateMinute(),
    translateHour(),
    translateDayOfMonth(),
    translateMonth(),
    translateDayOfWeek(),
  ].join(', ');
};

type Frequency = 'Daily' | 'Weekly' | 'Monthly' | 'Yearly';

type State = {
  frequency: Frequency;
  interval: number;
  dayOfMonth: number; // 1..31
  monthOfYear: number; // 1..12
  date: Date; // event start date/time
  hour: number;
  min: number;
  dayRepeat: (1 | 2 | 3 | 4 | 5 | 6 | 7)[]; // 1=Monday ... 7=Sunday
  until?: Date; // optional end date
  count?: number; // optional # of occurrences
};

function toWeekday(day: 1 | 2 | 3 | 4 | 5 | 6 | 7): Weekday {
  // rrule expects 0=MO,1=TU,2=WE,3=TH,4=FR,5=SA,6=SU
  return [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU][day - 1];
}

export function createGoogleRepeatRule(state: State): RRule {
  const { frequency, interval, dayOfMonth, monthOfYear, date, hour, min, dayRepeat, until, count } =
    state;
  console.log({ state });

  const dtstart = date;
  dtstart.setHours(hour, min, 0, 0);

  // @ts-ignore
  const options: RRule.Options = {
    dtstart,
    interval,
    until,
    count,
  };

  switch (frequency) {
    case 'Daily':
      options.freq = RRule.DAILY;
      break;

    case 'Weekly':
      options.freq = RRule.WEEKLY;
      options.byweekday = dayRepeat.map(toWeekday);
      break;

    case 'Monthly':
      options.freq = RRule.MONTHLY;
      options.bymonthday = [dayOfMonth];
      break;

    case 'Yearly':
      options.freq = RRule.YEARLY;
      options.bymonth = monthOfYear;
      options.bymonthday = [dayOfMonth];
      break;
  }

  return new RRule(options);
}
