import React, { useState } from 'react';
import { useAppSelector } from '../../hooks';
import { Select, Tooltip } from 'antd';
import { collection, doc, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import Message, { Type } from 'components/Notification/Message';
import { useTranslation } from 'react-i18next';

export function AuditScheduleResponsibleSelect() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const auditScheduleId = useAppSelector((state) => state.auditSchedules.currentAuditSchedule?.id);
  const responsibleId = useAppSelector(
    (state) => state.auditSchedules.currentAuditSchedule?.responsible?.id
  );
  const members = useAppSelector((state) => state.orgs.currentOrg.members);
  const orgId = useAppSelector((state) => state.orgs.currentOrg.id);

  const onChange = async (value: string | undefined) => {
    try {
      if (!orgId || !auditScheduleId) {
        throw new Error(`orgId=${orgId} or auditScheduleId=${auditScheduleId} missing`);
      }

      setLoading(true);
      const auditRef = doc(collection(firestore, `orgs/${orgId}/auditSchedules`), auditScheduleId);
      await setDoc(
        auditRef,
        {
          responsible: value
            ? {
                id: value,
                displayName: members[value]?.name || members[value].email,
              }
            : null,
        },
        { merge: true }
      );
      Message({
        key: 'responsibility',
        message: `Den tildelte blev opdateret`,
      });
    } catch (error) {
      console.error(error);
      Message({
        key: 'responsibility',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke opdatere, prøv venligst igen`,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Tooltip title={t('audit.responsible.tooltip.assign')}>
      <div>
        <ResponsibleSelect loading={loading} responsibleId={responsibleId} onChange={onChange} />
      </div>
    </Tooltip>
  );
}

export function ResponsibleSelect({
  loading,
  responsibleId,
  onChange,
}: {
  loading?: boolean;
  responsibleId?: string;
  onChange?: (value: string | undefined) => void;
}) {
  const members = useAppSelector((state) => state.orgs.currentOrg.members);
  const options = Object.entries(members).map(([key, value]) => ({
    label: value.name || value.email,
    value: key,
  }));

  return (
    <Select
      loading={loading}
      placeholder="Vælg ansvarlig"
      value={responsibleId}
      options={options}
      onChange={onChange}
      allowClear
    />
  );
}

/**
 * Implicit passing of value and onChange in Form Item
 */
interface Props {
  value?: string;
  onChange?: (value: string | undefined) => void;
}

export function FormResponsibleSelect({ value, onChange }: Props) {
  const { t } = useTranslation();
  const members = useAppSelector((state) => state.orgs.currentOrg.members);
  const options = Object.entries(members).map(([key, value]) => ({
    label: value.name || value.email,
    value: key,
  }));
  return (
    <Select
      placeholder={t('audit.responsible.placeholder')}
      value={value}
      onChange={onChange}
      options={options}
      allowClear
    />
  );
}
