import {
  OPEN_PROFILE_DRAWER,
  DrawerActionTypes,
  CLOSE_PROFILE_DRAWER,
  OPEN_GROUP_DRAWER,
  CLOSE_GROUP_DRAWER,
  OPEN_BILLING_DRAWER,
  CLOSE_BILLING_DRAWER,
  OPEN_ORG_NOTIFICATIONS_DRAWER,
  CLOSE_ORG_NOTIFICATIONS_DRAWER,
  OPEN_ORG_MEMBER_DRAWER,
  CLOSE_ORG_MEMBER_DRAWER,
} from './types';

interface DrawerState {
  profile: boolean;
  groupId: string | null;
  billing: { orgId: string; billingId: string } | null;
  orgNotifications: { orgId: string; userId: string } | null;
  orgMember: { orgId: string; userId: string } | null;
}

const initialState: DrawerState = {
  profile: false,
  groupId: null,
  billing: null,
  orgNotifications: null,
  orgMember: null,
};

export default function drawer(state = initialState, action: DrawerActionTypes) {
  switch (action.type) {
    case OPEN_PROFILE_DRAWER:
      return {
        ...state,
        profile: true,
      };
    case CLOSE_PROFILE_DRAWER:
      return {
        ...state,
        profile: false,
      };
    case OPEN_GROUP_DRAWER:
      return {
        ...state,
        groupId: action.payload.groupId,
      };
    case CLOSE_GROUP_DRAWER:
      return {
        ...state,
        groupId: null,
      };
    case OPEN_BILLING_DRAWER:
      return {
        ...state,
        billing: {
          orgId: action.payload.orgId,
          billingId: action.payload.billingId,
        },
      };
    case CLOSE_BILLING_DRAWER:
      return {
        ...state,
        billing: null,
      };
    case OPEN_ORG_NOTIFICATIONS_DRAWER:
      return {
        ...state,
        orgNotifications: {
          orgId: action.payload.orgId,
          userId: action.payload.userId,
        },
      };
    case CLOSE_ORG_NOTIFICATIONS_DRAWER:
      return {
        ...state,
        orgNotifications: null,
      };
    case OPEN_ORG_MEMBER_DRAWER:
      return {
        ...state,
        orgMember: {
          orgId: action.payload.orgId,
          userId: action.payload.userId,
        },
      };
    case CLOSE_ORG_MEMBER_DRAWER:
      return {
        ...state,
        orgMember: null,
      };

    default:
      return state;
  }
}
