// import { Timestamp } from "@google-cloud/firestore";

export const CLEANLOGS_FETCH_SUCCEEDED = 'CLEANLOGS_FETCH_SUCCEEDED';
export const CLEANLOGS_FETCH_FAILED = 'CLEANLOGS_FETCH_FAILED';
export const CLEANLOGS_FETCH_REQUESTED = 'CLEANLOGS_FETCH_REQUESTED';

export const CLEANLOG_CREATE_SUCCEEDED = 'CLEANLOG_CREATE_SUCCEEDED';
export const CLEANLOG_CREATE_FAILED = 'CLEANLOG_CREATE_FAILED';
export const CLEANLOG_CREATE_REQUESTED = 'CLEANLOG_CREATE_REQUESTED';

export const CLEANLOG_UPDATE_SUCCEEDED = 'CLEANLOG_UPDATE_SUCCEEDED';
export const CLEANLOG_UPDATE_FAILED = 'CLEANLOG_UPDATE_FAILED';
export const CLEANLOG_UPDATE_REQUESTED = 'CLEANLOG_UPDATE_REQUESTED';

export interface CleaningLog {
  amount: number;
  createTime: any; //Timestamp;
  initials: string;
  product: {
    id: string;
    title: string;
  };
}

export interface CleaningLogState {
  logs: CleaningLog[];
  loading: boolean;
}

interface FetchCleaningLogs {
  type: typeof CLEANLOGS_FETCH_REQUESTED;
  payload: { orgId: string };
}
export interface FetchCleaningLogsSuccess {
  type: typeof CLEANLOGS_FETCH_SUCCEEDED;
  payload: { data: CleaningLog[] };
}
interface FetchCleaningLogsFailed {
  type: typeof CLEANLOGS_FETCH_FAILED;
  payload: {};
}

export type CleaningLogsActionTypes =
  | FetchCleaningLogs
  | FetchCleaningLogsSuccess
  | FetchCleaningLogsFailed;
