import {
  HOURTRACKER_CREATE_REQUESTED,
  HOURTRACKERS_FETCH_REQUESTED,
  HOURTRACKERS_FETCH_SUCCEEDED,
  HOURTRACKER_UPDATE_REQUESTED,
} from './types';

export function fetchHourTrackers({ orgId }: { orgId: string }) {
  return {
    type: HOURTRACKERS_FETCH_REQUESTED,
    payload: { orgId },
  };
}
// @ts-ignore
export function fetchHourTrackersSuccess(hourTrackers: firebase.firestore.QuerySnapshot) {
  const logs = hourTrackers.docs.map((log: any) => {
    return {
      id: log.id,
      ...log.data(),
    };
  });
  return {
    type: HOURTRACKERS_FETCH_SUCCEEDED,
    payload: {
      data: logs,
    },
  };
}

export function addHourTracker({ orgId, initials, amount, productId }: any) {
  return {
    type: HOURTRACKER_CREATE_REQUESTED,
    payload: {
      orgId,
      initials,
      amount,
      productId,
    },
  };
}

export function updateHourTracker({ orgId, logId, data }: any) {
  return {
    type: HOURTRACKER_UPDATE_REQUESTED,
    payload: {
      orgId,
      logId,
      data,
    },
  };
}
