import React, { useState } from 'react';
import { Card, List, Space, Typography } from 'antd';
import { useHistory, useParams } from 'react-router';
import useOverdueAudits from '../../modules/auditSchedule/useOverdueAudits';
import { CheckCircleTwoTone, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../Layout/Colors';
import { filterOlderAuditsByDueDate, filterRecentAuditsByDueDate } from './auditFilter';
import AuditItem from './AuditItem';

export default function AuditOverdueWidget() {
  const history = useHistory();
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  const { audits, isLoading } = useOverdueAudits(orgId);
  const [activeTab, setactiveTab] = useState('tab1');
  // const { audits: resolutions, isLoading: isLoadingMore } =
  //     useNeedsResolutionAudits(orgId);

  const auditsLast7Days = audits.filter(filterRecentAuditsByDueDate).length;
  const auditsBefore7Days = audits.filter(filterOlderAuditsByDueDate).length;
  if (isLoading) {
    return (
      <Card
        title={t(`dashboard.auditOverdue.title`)}
        headStyle={{}}
        style={{
          width: '100%',
          height: 467,
          backgroundColor: '#fff',
          color: '#001628',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '370px',
          }}
        >
          <LoadingOutlined style={{ fontSize: 50 }} />
        </div>
      </Card>
    );
  }
  console.log({ audits });
  const tabList = [
    {
      key: 'tab1',
      tab: `Seneste 7 dage ${auditsLast7Days > 0 ? `(${auditsLast7Days})` : ''}`,
    },
    {
      key: 'tab2',
      tab: `Over 7 dage ${auditsBefore7Days > 0 ? `(${auditsBefore7Days})` : ''}`,
    },
  ];
  const list = audits
    .map((audit) => ({
      id: audit.id,
      title: audit.templateTitle,
      time: t('general.dateToNow', { date: audit.dueDate.toDate() }),
      dueDate: audit.dueDate,
    }))
    .filter(activeTab === 'tab1' ? filterRecentAuditsByDueDate : filterOlderAuditsByDueDate);
  console.log({ list });
  return (
    <Card
      title={
        <Space
          style={{
            display: 'flex',
            paddingLeft: 24,
            paddingRight: 16,
            paddingTop: 0,
            paddingBottom: 16,
          }}
        >
          <CloseCircleOutlined style={{ display: 'flex', fontSize: 24, color: Colors.danger }} />
          {t(`dashboard.auditOverdue.title`)}
        </Space>
      }
      tabList={tabList}
      onTabChange={setactiveTab}
      activeTabKey={activeTab}
      tabProps={{
        size: 'small',
        style: { paddingLeft: 24, borderTop: '1px solid #f0f0f0' },
      }}
      headStyle={{ padding: 0, color: '#001628' }}
      style={{
        width: '100%',

        backgroundColor: '#fff',
      }}
      bodyStyle={{
        padding: 0,
        paddingTop: 0,
        paddingBottom: 0,
        height: 370,
        overflowY: 'scroll',
      }}
    >
      <div style={{ color: '#001628' }}>
        <List
          itemLayout="horizontal"
          dataSource={list}
          locale={{
            emptyText: (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 48,
                }}
              >
                <CheckCircleTwoTone twoToneColor={Colors.done} style={{ fontSize: 64 }} />
                <Typography.Title level={5}>{t('dashboard.auditOverdue.empty')}</Typography.Title>
              </div>
            ),
          }}
          renderItem={(item) => {
            const dueDate = item.dueDate?.toDate();
            return (
              <AuditItem
                key={item.id}
                // taskCount={0}
                color={Colors.danger}
                linkPath={`/orgs/${orgId}/audit/audits/${item.id}`}
                title={item.title}
                description={
                  <>
                    {t('dashboard.auditsTodo.deadlineFormat', {
                      dayOfWeek: dueDate,
                      dayOfMonth: dueDate,
                      month: dueDate,
                      time: dueDate,
                      relativeTime: dueDate,
                    })}
                  </>
                }
              />
            );
          }}
        />
      </div>
    </Card>
  );
}
