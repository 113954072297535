import { firestore, functions } from '../firebase';
import { useEffect, useState } from 'react';

import { RequestState } from 'interfaces/request';
import { DeviceType } from 'general/enums';
import { useAppSelector } from 'hooks';

export default function useGPSOverview() {
  const [gps, setGps] = useState<any[]>([]);
  const orgMap = useAppSelector((state) => state.orgs.orgMap);

  const [gpsLoadState, setGpstLoadState] = useState(RequestState.INITIAL);

  const getGpstData = async () => {
    try {
      setGpstLoadState(RequestState.PENDING);
      const functionRef = functions.httpsCallable('getAllGPSOverview');
      const response: any = await functionRef();

      const errorCode = response?.data?.httpErrorCode?.status;
      if (errorCode) {
        console.log({ errorCode });
      }
      setGps(
        response.data.filter((plant: any) => plant.deviceType !== DeviceType.MOBILE_PLANT)

        // For now we are filtering out mobile plants
      );
      setGpstLoadState(RequestState.RESOLVED);
    } catch (error) {
      console.error(error);
      setGpstLoadState(RequestState.REJECTED);
    }
  };
  useEffect(() => {
    getGpstData();
  }, []);

  return {
    gps: gps
      .map((gps: any) => {
        const orgId = gps.ref.split('/')[1];
        const org = orgMap[orgId];
        const lastSeen = gps.lastSeen?._seconds ? gps.lastSeen._seconds * 1000 : null;
        return { ...gps, org, orgId, lastSeen };
      })
      .sort((a: any, b: any) => a.org?.title.localeCompare(b.org?.title)),

    error: gpsLoadState === RequestState.REJECTED,
    loading: gpsLoadState === RequestState.INITIAL || gpsLoadState === RequestState.PENDING,
  };
}
