import React from 'react';
import { message, Menu } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import confirmModal from '../../general/confirmModal';

export const DirectoryMenu = ({
  updateDirectory,
  orgId,
  directoryId,
}: {
  updateDirectory: any;
  orgId: string;
  directoryId: string;
}) => {
  const { t } = useTranslation();
  const confirmDelete = () => {
    confirmModal({
      title: t('Er du sikker på du vil slette mappen?'),
      onOk: () => {
        updateDirectory({
          orgId,
          directoryId: directoryId,
          data: { isDeleted: true },
        });
        message.success(t('Mappe slettet'));
      },
    });
  };
  return (
    <Menu>
      <Menu.Item key={directoryId} onClick={confirmDelete}>
        <div style={{ color: 'red' }}>
          <DeleteOutlined /> {t('reports.folder_menu_delete')}
        </div>
      </Menu.Item>
    </Menu>
  );
};
