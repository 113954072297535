import React, { useEffect } from 'react';
import { compose } from 'redux';
import { withRouter, useParams } from 'react-router';
import { connect } from 'react-redux';
import Settings from './index';
import { fetchOrg, updateOrg } from '../../modules/orgs/actions';
import { AppState } from '../../store';

function SettingsContainer(props: any) {
  const { orgId } = useParams<{ orgId: string }>();
  useEffect(() => {
    props.fetchOrg({ orgId });
  }, [orgId]);

  return (
    <React.Fragment>
      <Settings
        loading={props.loading}
        unit={props.unit}
        status={props.status}
        storageAlert={props.storageAlert}
        updateOrg={(data: any) => props.updateOrg({ orgId, data })}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state: AppState) {
  return {
    loading: state.orgs.loading,
    unit: state.orgs.currentOrg.unit,
    status: state.orgs.currentOrg.status,
    storageAlert: state.orgs.currentOrg.storageAlert,
  };
}

const mapActionsToProps = {
  fetchOrg,
  updateOrg,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(SettingsContainer);
