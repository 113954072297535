'use client';

import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { QRCodeCanvas } from 'qrcode.react';
import {
  OrderStatusEnum,
  type DBOrder,
  type Order,
  type OrderStatus,
  type Product,
} from '../lib/types';
import { Trash2, Plus, History, AlertCircle } from 'lucide-react';
import { Badge } from 'components/ui/badge';
import { ScrollArea } from 'components/ui/scroll-area';
import { useProducts } from '../hooks/use-products';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { OrderHistoryDialog } from './order-history-dialog';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Separator } from 'components/ui/separator';
import { Alert, AlertDescription } from 'components/ui/alert';
import { Calendar } from 'components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { cn } from 'lib/utils';
import { CalendarIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { productUnitsMap } from '../lib/productUnits';
import { toast } from 'sonner';
import firebase from 'firebase/compat/app';
import { useAppSelector } from 'hooks';
import { getStatusColor, translateStatus } from '../lib/utils';
import { Aks2talOnly, isAdmin, isAks2talOnly } from 'components/AdminOnly';

interface OrderDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  order: Order | null;
  mode: 'view' | 'edit';
}

interface ValidationErrors {
  companyName?: string;
  deliveryDate?: string;
  deliveryAddress?: string;
  deliveryZip?: string;
  deliveryCity?: string;
  deliveryAtt?: string;
  contactPerson?: string;
  products?: string;
}

export function OrderDialog({ open, onOpenChange, order, mode }: OrderDialogProps) {
  const { t } = useTranslation();
  const userId = useAppSelector((state) => state.user.details.uid);
  const userDisplayName = useAppSelector((state) => state.user.userDoc.displayName);
  const isAks = isAks2talOnly(userId);
  const [formData, setFormData] = useState<Order>({
    id: '',
    orgId: '',
    companyName: '',
    products: [],
    status: OrderStatusEnum.AWAITING,
    deliveryAddress: '',
    deliveryCity: '',
    deliveryZip: '',
    deliveryDate: new Date(),
    deliveryAtt: '',
    contactPerson: '',
    createdBy: { id: '', displayName: '' },
    createTime: new Date(),
  });

  const { products: availableProducts, loadingProducts } = useProducts(formData.orgId);
  const [activeTab, setActiveTab] = useState('details');
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [touched, setTouched] = useState<Record<string, boolean>>({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Effect for handling view/edit mode
  useEffect(() => {
    if (order && open) {
      console.log({ order });

      setFormData(order);
      setActiveTab('details');
      setErrors({});
      setTouched({});
      setFormSubmitted(false);
    }
  }, [order, open]);

  const validateForm = (): boolean => {
    const newErrors: ValidationErrors = {};

    if (!formData.companyName.trim()) {
      newErrors.companyName = t('orders.companyNameRequired');
    }

    if (!formData.deliveryDate) {
      newErrors.deliveryDate = t('orders.deliveryDateRequired');
    }

    if (!formData.deliveryAddress?.trim()) {
      newErrors.deliveryAddress = t('orders.deliveryAddressRequired');
    }
    if (!formData.deliveryZip?.trim()) {
      newErrors.deliveryZip = t('orders.deliveryZipRequired');
    }
    if (!formData.deliveryCity?.trim()) {
      newErrors.deliveryCity = t('orders.deliveryCityRequired');
    }

    if (!formData.deliveryAtt?.trim()) {
      newErrors.deliveryAtt = t('orders.deliveryAttRequired');
    }

    if (!formData.contactPerson?.trim()) {
      newErrors.contactPerson = t('orders.contactPersonRequired');
    }

    if (formData.products.length === 0) {
      newErrors.products = t('orders.productsRequired');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field: keyof Order, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Mark field as touched
    setTouched((prev) => ({
      ...prev,
      [field]: true,
    }));

    // Clear error for this field if it exists
    if (errors[field as keyof ValidationErrors]) {
      setErrors((prev) => ({
        ...prev,
        [field]: undefined,
      }));
    }
  };

  const handleStatusChange = (value: OrderStatus) => {
    setFormData((prev) => ({
      ...prev,
      status: value,
    }));
  };

  const handleAddProduct = () => {
    if (availableProducts.length === 0) return;

    const firstProduct = availableProducts[0];
    const newProduct = {
      id: firstProduct.id,
      name: firstProduct.title,
      quantity: 1,
      unit: undefined, // firstProduct.defaultUnit,
    };

    setFormData((prev) => ({
      ...prev,
      products: [...prev.products, newProduct],
    }));

    // Clear products error if it exists
    if (errors.products) {
      setErrors((prev) => ({
        ...prev,
        products: undefined,
      }));
    }
  };

  const handleRemoveProduct = (index: number) => {
    setFormData((prev) => {
      const newProducts = [...prev.products];
      newProducts.splice(index, 1);

      // If removing the last product, set the products error
      if (newProducts.length === 0) {
        setErrors((prev) => ({
          ...prev,
          products: t('orders.productsRequired'),
        }));
      }

      return {
        ...prev,
        products: newProducts,
      };
    });
  };

  const handleProductChange = (index: number, field: keyof Product, value: string | number) => {
    setFormData((prev) => {
      const newProducts = [...prev.products];
      newProducts[index] = { ...newProducts[index], [field]: value };
      return {
        ...prev,
        products: newProducts,
      };
    });
  };

  const getProductUnits = (productId: string): { value: number; unit: string; label: string }[] => {
    const product = availableProducts.find((p) => p.id === productId);
    // @ts-ignore
    return product?.id ? productUnitsMap[product.id] : []; //product?.availableUnits ? product.availableUnits : [];
  };

  async function onSave(order: Order) {
    try {
      console.log('Saving order', order);
      if (!order.id) {
        throw new Error('Order ID is missing');
      }
      await firebase
        .firestore()
        .collection('orders')
        .doc(order.id)
        .set(
          {
            companyName: order.companyName,
            products: order.products,
            delivery: {
              date: order.deliveryDate,
              address: order.deliveryAddress,
              zip: order.deliveryZip,
              city: order.deliveryCity,
              att: order.deliveryAtt,
            },
            updateTime: new Date(),
            status: order.status,
            contact: { name: order.contactPerson },
          } as Omit<DBOrder, 'id'>,
          { merge: true }
        );
      onOpenChange(false);
    } catch (error) {
      console.error('Error saving order', error);
    } finally {
      setFormSubmitted(false);
    }
  }

  const handleSave = () => {
    const isValid = validateForm();
    if (!isValid) return;
    setFormSubmitted(true);

    onSave(formData);
  };

  const isReadOnly = mode === 'view';
  const isRequired = mode === 'edit';

  // Helper to determine if a field has an error
  const hasError = (field: keyof ValidationErrors): boolean => {
    return !!(formSubmitted || touched[field]) && !!errors[field];
  };

  // Format date with Danish locale
  const formatDate = (date: Date | string) => {
    return format(new Date(date), 'PPP', { locale: da });
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(t('orders.copiedToClipboard'));
  };

  const productsReadonly = isReadOnly || order?.status !== OrderStatusEnum.AWAITING;
  const readOnlyCls = isReadOnly || !isAks ? ' bg-muted cursor-copy' : '';
  const readOnlyProductCls = productsReadonly ? ' bg-muted cursor-copy' : '';

  // Render the details tab
  const renderDetailsTab = () => {
    return (
      <div className="space-y-6 py-4">
        {/* Products Section */}
        <Card>
          <CardHeader>
            <CardTitle className="flex">
              {t('orders.products')}{' '}
              {isRequired && <span className="text-destructive ml-1">*</span>}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            {loadingProducts ? (
              <div className="text-center py-8">
                <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full mx-auto mb-2"></div>
                <p className="text-muted-foreground">{t('orders.loadingProducts')}</p>
              </div>
            ) : formData.products.length === 0 ? (
              <div
                className={`text-center py-8 text-muted-foreground border rounded-md ${
                  hasError('products') ? 'border-destructive' : ''
                }`}
              >
                {t('orders.noProductsAdded')}
              </div>
            ) : (
              formData.products.map((product, index) => {
                // @ts-ignore
                const unit = productUnitsMap[product.id]?.find((p: any) => p.value == product.unit);

                return (
                  <div key={index} className="grid grid-cols-12 gap-2 items-end  rounded-md">
                    <div className={`${productsReadonly ? 'col-span-7' : 'col-span-6'} space-y-2`}>
                      {index === 0 && (
                        <Label htmlFor={`product-${index}`}>{t('orders.product')}</Label>
                      )}
                      {productsReadonly ? (
                        <Input
                          id={`product-${index}`}
                          value={product.name}
                          readOnly
                          className={readOnlyProductCls}
                        />
                      ) : (
                        <Select
                          value={product.id}
                          onValueChange={(value) => {
                            const selectedProduct = availableProducts.find((p) => p.id === value);
                            if (selectedProduct) {
                              handleProductChange(index, 'id', value);
                              handleProductChange(index, 'name', selectedProduct.title);
                              // handleProductChange(index, 'unit', selectedProduct.defaultUnit);
                            }
                          }}
                        >
                          <SelectTrigger id={`product-${index}`}>
                            <SelectValue placeholder={t('orders.selectProduct')} />
                          </SelectTrigger>
                          <SelectContent>
                            {availableProducts.map((p) => (
                              <SelectItem key={p.id} value={p.id}>
                                {p.title}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    </div>
                    <div className="col-span-2 space-y-2">
                      {index === 0 && (
                        <Label htmlFor={`quantity-${index}`}>{t('orders.quantity')}</Label>
                      )}
                      <Input
                        id={`quantity-${index}`}
                        type="number"
                        value={product.quantity}
                        onChange={(e) =>
                          handleProductChange(
                            index,
                            'quantity',
                            Number.parseInt(e.target.value) || 0
                          )
                        }
                        readOnly={productsReadonly}
                        className={readOnlyProductCls}
                      />
                    </div>
                    <div className="col-span-3 space-y-2">
                      {index === 0 && <Label htmlFor={`unit-${index}`}>{t('orders.unit')}</Label>}
                      {isReadOnly || productsReadonly ? (
                        <Input
                          id={`unit-${index}`}
                          value={unit?.label}
                          readOnly
                          className={readOnlyProductCls}
                        />
                      ) : (
                        <Select
                          value={product.unit}
                          onValueChange={(value) => handleProductChange(index, 'unit', value)}
                        >
                          <SelectTrigger id={`unit-${index}`}>
                            <SelectValue placeholder={t('orders.selectUnit')} />
                          </SelectTrigger>
                          <SelectContent>
                            {getProductUnits(product.id).map((unit) => (
                              <SelectItem key={unit.value} value={'' + unit.value}>
                                {unit.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      )}
                    </div>
                    {!isReadOnly && !productsReadonly && (
                      <div className="col-span-1">
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => handleRemoveProduct(index)}
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    )}
                  </div>
                );
              })
            )}

            {hasError('products') && <p className="text-sm text-destructive">{errors.products}</p>}

            {!isReadOnly && !productsReadonly && (
              <Button
                onClick={handleAddProduct}
                className="w-full"
                disabled={
                  loadingProducts ||
                  availableProducts.length === 0 ||
                  availableProducts[0].id.startsWith('DEFAULT')
                }
              >
                <Plus className="mr-2 h-4 w-4" />
                {t('orders.addProduct')}
              </Button>
            )}
          </CardContent>
        </Card>
        {/* Order Information Section */}
        <Card>
          <CardHeader>
            <CardTitle>{t('orders.orderInfo')}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="companyName" className="flex">
                  {t('orders.companyName')}{' '}
                  {isRequired && <span className="text-destructive ml-1">*</span>}
                </Label>
                <Input
                  id="companyName"
                  onClick={() => isReadOnly && copyToClipboard(formData.companyName)}
                  value={formData.companyName}
                  onChange={(e) => handleInputChange('companyName', e.target.value)}
                  readOnly={isReadOnly}
                  className={hasError('companyName') ? 'border-destructive' : '' + readOnlyCls}
                />
                {hasError('companyName') && (
                  <p className="text-sm text-destructive">{errors.companyName}</p>
                )}
              </div>
              <div className="space-y-2">
                <Label htmlFor="status" className="flex">
                  {t('orders.status')}
                </Label>
                {isReadOnly || !isAks || order?.status === OrderStatusEnum.COMPLETED ? (
                  <div className="h-9 flex items-center px-3 py-2 border rounded-md bg-muted text-sm">
                    <Badge
                      variant="outline"
                      className={getStatusColor(formData.status) + ' w-2 h-2 p-0 mr-2'}
                    />
                    {t(translateStatus(formData.status))}
                  </div>
                ) : (
                  <Select
                    value={formData.status}
                    onValueChange={(value) => handleStatusChange(value as OrderStatus)}
                  >
                    <SelectTrigger id="status">
                      <SelectValue placeholder={t('orders.selectOption')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value={OrderStatusEnum.AWAITING}>
                        <div className="flex items-center">
                          <Badge variant="outline" className="bg-yellow-500 w-2 h-2 p-0 mr-2" />
                          {t(translateStatus(OrderStatusEnum.AWAITING))}
                        </div>
                      </SelectItem>
                      <SelectItem value={OrderStatusEnum.PROCESSING}>
                        <div className="flex items-center">
                          <Badge variant="outline" className="bg-blue-500 w-2 h-2 p-0 mr-2" />
                          {t(translateStatus(OrderStatusEnum.PROCESSING))}
                        </div>
                      </SelectItem>
                      <SelectItem value={OrderStatusEnum.READY_FOR_SHIPPING}>
                        <div className="flex items-center">
                          <Badge variant="outline" className="bg-purple-500 w-2 h-2 p-0 mr-2" />
                          {t(translateStatus(OrderStatusEnum.READY_FOR_SHIPPING))}
                        </div>
                      </SelectItem>
                      <SelectItem value={OrderStatusEnum.IN_TRANSIT}>
                        <div className="flex items-center">
                          <Badge variant="outline" className="bg-purple-500 w-2 h-2 p-0 mr-2" />
                          {t(translateStatus(OrderStatusEnum.IN_TRANSIT))}
                        </div>
                      </SelectItem>
                      <SelectItem value={OrderStatusEnum.COMPLETED}>
                        <div className="flex items-center">
                          <Badge variant="outline" className="bg-green-500 w-2 h-2 p-0 mr-2" />
                          {t(translateStatus(OrderStatusEnum.COMPLETED))}
                        </div>
                      </SelectItem>
                      <SelectItem value={OrderStatusEnum.VOIDED}>
                        <div className="flex items-center">
                          <Badge variant="outline" className="bg-red-500 w-2 h-2 p-0 mr-2" />
                          {t(translateStatus(OrderStatusEnum.VOIDED))}
                        </div>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="createdBy">{t('orders.createdBy')}</Label>
                <Input
                  id="createdBy"
                  value={formData.createdBy.displayName}
                  readOnly
                  className={readOnlyCls}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="createdAt">{t('orders.createdAt')}</Label>
                <Input
                  id="createdAt"
                  value={formatDate(formData.createTime)}
                  readOnly
                  className={readOnlyCls}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Delivery Information Section */}
        <Card>
          <CardHeader>
            <CardTitle>{t('orders.deliveryInformation')}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div
              className="space-y-2"
              role="button"
              onClick={() =>
                isReadOnly && formData.deliveryAddress && copyToClipboard(formData.deliveryAddress)
              }
            >
              <Label htmlFor="deliveryAddress" className="flex">
                {t('orders.deliveryAddress')}{' '}
                {isRequired && <span className="text-destructive ml-1">*</span>}
              </Label>
              <Input
                id="deliveryAddress"
                value={formData.deliveryAddress}
                onChange={(e) => handleInputChange('deliveryAddress', e.target.value)}
                readOnly={isReadOnly}
                placeholder={t('orders.enterDeliveryAddress')}
                className={hasError('deliveryAddress') ? 'border-destructive' : '' + readOnlyCls}
              />
              {hasError('deliveryAddress') && (
                <p className="text-sm text-destructive">{errors.deliveryAddress}</p>
              )}
            </div>
            <div className="grid grid-cols-3 gap-4">
              <div
                className="space-y-2"
                role="button"
                onClick={() =>
                  isReadOnly && formData.deliveryZip && copyToClipboard(formData.deliveryZip)
                }
              >
                <Label htmlFor="deliveryZip" className="flex">
                  {t('orders.deliveryZip')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="deliveryZip"
                  value={formData.deliveryZip}
                  onChange={(e) => handleInputChange('deliveryZip', e.target.value)}
                  readOnly={isReadOnly}
                  placeholder={t('orders.enterDeliveryZip')}
                  className={hasError('deliveryZip') ? 'border-destructive' : '' + readOnlyCls}
                />
                {hasError('deliveryZip') && (
                  <p className="text-sm text-destructive">{errors.deliveryZip}</p>
                )}
              </div>
              <div
                className="space-y-2"
                role="button"
                onClick={() =>
                  isReadOnly && formData.deliveryCity && copyToClipboard(formData.deliveryCity)
                }
              >
                <Label htmlFor="deliveryCity" className="flex">
                  {t('orders.deliveryCity')} <span className="text-destructive ml-1">*</span>
                </Label>
                <Input
                  id="deliveryCity"
                  value={formData.deliveryCity}
                  onChange={(e) => handleInputChange('deliveryCity', e.target.value)}
                  readOnly={isReadOnly}
                  placeholder={t('orders.enterDeliveryCity')}
                  className={hasError('deliveryCity') ? 'border-destructive' : '' + readOnlyCls}
                />
                {hasError('deliveryCity') && (
                  <p className="text-sm text-destructive">{errors.deliveryCity}</p>
                )}
              </div>
              <div
                className="space-y-2"
                role="button"
                onClick={() =>
                  isReadOnly &&
                  formData.deliveryDate &&
                  copyToClipboard(formData.deliveryDate.toISOString())
                }
              >
                <Label htmlFor="deliveryDate" className="flex">
                  {t('orders.deliveryDate')}{' '}
                  {isRequired && <span className="text-destructive ml-1">*</span>}
                </Label>
                {isReadOnly ? (
                  <div
                    className={
                      'h-9 flex items-center px-3 py-2 border rounded-md bg-background text-sm' +
                      readOnlyCls
                    }
                  >
                    {formData.deliveryDate
                      ? formatDate(formData.deliveryDate)
                      : t('orders.notSpecified')}
                  </div>
                ) : (
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        id="deliveryDate"
                        variant="outline"
                        className={cn(
                          'w-full justify-start text-left font-normal',
                          !formData.deliveryDate && 'text-muted-foreground',
                          hasError('deliveryDate') && 'border-destructive'
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {formData.deliveryDate ? (
                          formatDate(formData.deliveryDate)
                        ) : (
                          <span>{t('orders.pickDate')}</span>
                        )}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={
                          formData.deliveryDate ? new Date(formData.deliveryDate) : undefined
                        }
                        onSelect={(date) => {
                          if (date) {
                            handleInputChange(
                              'deliveryDate',
                              date.toISOString().split('orders.T')[0]
                            );
                          }
                        }}
                        initialFocus
                        locale={da}
                      />
                    </PopoverContent>
                  </Popover>
                )}
                {hasError('deliveryDate') && (
                  <p className="text-sm text-destructive">{errors.deliveryDate}</p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div
                className="space-y-2"
                role="button"
                onClick={() =>
                  isReadOnly && formData.deliveryAtt && copyToClipboard(formData.deliveryAtt)
                }
              >
                <Label htmlFor="deliveryAtt" className="flex">
                  {t('orders.deliveryAtt')}{' '}
                  {isRequired && <span className="text-destructive ml-1">*</span>}
                </Label>
                <Input
                  id="deliveryAtt"
                  value={formData.deliveryAtt}
                  onChange={(e) => handleInputChange('deliveryAtt', e.target.value)}
                  readOnly={isReadOnly}
                  className={hasError('deliveryAtt') ? 'border-destructive' : '' + readOnlyCls}
                />
                {hasError('deliveryAtt') && (
                  <p className="text-sm text-destructive">{errors.deliveryAtt}</p>
                )}
              </div>
              <div
                className="space-y-2"
                role="button"
                onClick={() =>
                  isReadOnly && formData.contactPerson && copyToClipboard(formData.contactPerson)
                }
              >
                <Label htmlFor="contactPerson" className="flex">
                  {t('orders.contactPerson')}{' '}
                  {isRequired && <span className="text-destructive ml-1">*</span>}
                </Label>
                <Input
                  id="contactPerson"
                  value={formData.contactPerson}
                  onChange={(e) => handleInputChange('contactPerson', e.target.value)}
                  readOnly={isReadOnly}
                  className={hasError('contactPerson') ? 'border-destructive' : '' + readOnlyCls}
                />
                {hasError('contactPerson') && (
                  <p className="text-sm text-destructive">{errors.contactPerson}</p>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  // Render the delivery note tab
  const renderDeliveryNoteTab = () => {
    return (
      <div className="space-y-6 py-4">
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-bold">{t('orders.deliveryNote')}</h2>
          <Button
            onClick={() => {
              const printWindow = window.open('', '_blank');
              printWindow?.document.write(`
                <html>
                  <head>
                    <title>${t('orders.deliveryNote')} - ${formData.id}</title>
                    <style>
                      body { font-family: system-ui, sans-serif; line-height: 1.5; padding: 20px; }
                      .container { max-width: 800px; margin: 0 auto; }
                      .header { display: flex; justify-content: space-between; margin-bottom: 20px; }
                      .qr-code { text-align: right; }
                      table { width: 100%; border-collapse: collapse; margin: 20px 0; }
                      th, td { border: 1px solid #ddd; padding: 8px; text-align: left; }
                      th { background-color: #f2f2f2; }
                      .footer { margin-top: 40px; display: flex; justify-content: space-between; }
                      .signature-box { border-top: 1px solid #000; width: 200px; padding-top: 5px; }
                      @media print {
                        button { display: none; }
                      }
                    </style>
                  </head>
                  <body>
                    <div class="container">
                      <div class="header">
                        <div>
                          <h1>${t('orders.deliveryNote')}</h1>
                          <p>${t('orders.orderId')}: ${formData.id}</p>
                          <p>${t('orders.date')}: ${formatDate(new Date())}</p>
                        </div>
                        <div class="qr-code">
                          <img src="${document.querySelector('canvas')?.toDataURL()}" width="200" />
                        </div>
                      </div>
                      
                      <div>
                        <h2>${t('orders.companyInformation')}</h2>
                        <p>${t('orders.companyName')}: ${formData.companyName}</p>
                      </div>
                      
                      <div>
                        <h2>${t('orders.deliveryInformation')}</h2>
                        <!-- <p>${t('orders.deliveryDate')}: ${
                formData.deliveryDate ? formatDate(formData.deliveryDate) : t('orders.notSpecified')
              }</p> -->
                        <p>${t('orders.deliveryAddress')}: ${
                formData.deliveryAddress || t('orders.notSpecified')
              }, ${formData.deliveryZip} ${formData.deliveryCity}</p>
                        <p>${t('orders.deliveryAtt')}: ${
                formData.deliveryAtt || t('orders.notSpecified')
              }</p>
                        <p>${t('orders.contactPerson')}: ${
                formData.contactPerson || t('orders.notSpecified')
              }</p>
                      </div>
                      
                      <div>
                        <h2>${t('orders.products')}</h2>
                        <table>
                          <thead>
                            <tr>
                              <th>${t('orders.product')}</th>
                              <th>${t('orders.quantity')}</th>
                              <th>${t('orders.unit')}</th>
                              <th>${t('orders.total')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${formData.products
                              .map((product) => {
                                // @ts-ignore
                                const unit = productUnitsMap[product.id]?.find(
                                  (p: any) => p.value == product.unit
                                );
                                return `
                              <tr>
                                <td>${product.name}</td>
                                <td>${product.quantity}</td>
                                <td>${unit?.label}</td>
                                <td>${product.quantity * unit?.value} liter</td>
                              </tr>
                            `;
                              })
                              .join('')}
                          </tbody>
                        </table>
                      </div>
                      
                      <!-- <div class="footer">
                        <div>
                          <p>${t('orders.deliveredBy')}:</p>
                          <div class="signature-box">${t('orders.signature')}</div>
                        </div>
                        <div>
                          <p>${t('orders.receivedBy')}:</p>
                          <div class="signature-box">${t('orders.signature')}</div>
                        </div>
                      </div> -->
                    </div>
                    <script>
                      window.onload = function() { window.print(); }
                    </script>
                  </body>
                </html>
              `);
              printWindow?.document.close();
            }}
          >
            {t('orders.printDeliveryNote')}
          </Button>
        </div>

        <Card>
          <CardContent className="p-6 space-y-4">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-md font-semibold">{t('orders.orderInformation')}</h3>
                <p className="text-sm">
                  {t('orders.orderId')}: {formData.id}
                </p>
                <p className="text-sm">
                  {t('orders.status')}:{' '}
                  <Badge className={getStatusColor(formData.status)}>
                    {t(translateStatus(formData.status))}
                  </Badge>
                </p>
                <p className="text-sm">
                  {t('orders.createdAt')}: {formatDate(formData.createTime)}
                </p>
              </div>
              <div className="bg-white p-2 rounded border scale(0.5)">
                <QRCodeCanvas
                  style={{ width: 100, height: 100 }}
                  level="H"
                  value={`https://app.aks2tal.com/o/${formData.id}`}
                  size={600}
                />
              </div>
            </div>

            <Separator />

            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <h3 className="text-md font-semibold">{t('orders.companyInformation')}</h3>
                <p className="text-sm">{t('orders.companyName')}:</p>
                <p className="text-sm">{formData.companyName}</p>
              </div>
              <div>
                <h3 className="text-md font-semibold">{t('orders.deliveryInformation')}</h3>
                <p className="text-sm">
                  {t('orders.deliveryDate')}:{' '}
                  {formData.deliveryDate
                    ? formatDate(formData.deliveryDate)
                    : t('orders.notSpecified')}
                </p>
                <p className="text-sm">
                  {t('orders.deliveryAddress')}:{' '}
                  {formData.deliveryAddress || t('orders.notSpecified')}, {formData.deliveryZip}{' '}
                  {formData.deliveryCity}
                </p>
                {/* <p></p> */}
                <p className="text-sm">
                  {t('orders.deliveryAtt')}: {formData.deliveryAtt || t('orders.notSpecified')}
                </p>
              </div>
            </div>

            <Separator />

            <div>
              <h3 className="text-md font-semibold mb-2">{t('orders.products')}</h3>
              <div className="border rounded-md overflow-hidden">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>{t('orders.product')}</TableHead>
                      <TableHead>{t('orders.quantity')}</TableHead>
                      <TableHead>{t('orders.unit')}</TableHead>
                      <TableHead>{t('orders.total')}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {formData.products.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={3} className="text-center py-4 text-muted-foreground">
                          {t('orders.noProductsAdded')}
                        </TableCell>
                      </TableRow>
                    ) : (
                      formData.products.map((product, index) => {
                        // @ts-ignore
                        const unit = productUnitsMap[product.id]?.find(
                          (p: any) => p.value == product.unit
                        );
                        return (
                          <TableRow key={index}>
                            <TableCell>{product.name}</TableCell>
                            <TableCell>{product.quantity}</TableCell>
                            <TableCell>{unit?.label}</TableCell>
                            <TableCell>{product.quantity * unit?.value} liter</TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>

            {/* <Separator /> */}

            {/* <div className="grid grid-cols-2 gap-8">
              <div>
                <h3 className="text-md font-semibold mb-2">{t('orders.deliveredBy')}</h3>
                <div className="h-16 border-t border-dashed pt-1 text-muted-foreground text-sm">
                  {t('orders.signature')}
                </div>
              </div>
              <div>
                <h3 className="text-md font-semibold mb-2">{t('orders.receivedBy')}</h3>
                <div className="h-16 border-t border-dashed pt-1 text-muted-foreground text-sm">
                  {t('orders.signature')}
                </div>
              </div>
            </div> */}
          </CardContent>
        </Card>
      </div>
    );
  };

  // Render the appropriate content based on active tab
  const renderContent = () => {
    if (activeTab === 'details') {
      return renderDetailsTab();
    } else if (activeTab === 'deliverynote') {
      return renderDeliveryNoteTab();
    }
    return null;
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="max-w-4xl max-h-[90vh] overflow-hidden flex flex-col p-0 gap-0">
          <DialogHeader className="px-6 pt-4 pb-4 bg-gray-50 border ">
            <DialogTitle>
              {mode === 'edit' ? t('orders.editOrder') : t('orders.orderDetails')}
              <span className="ml-2 text-muted-foreground">#{formData.id}</span>
            </DialogTitle>
          </DialogHeader>

          <div className="flex flex-1 overflow-hidden">
            {/* Sidebar */}
            <div className="w-48 border-r bg-muted/40 flex flex-col">
              <div className="flex flex-col p-2 gap-1">
                <Button
                  variant={activeTab === 'details' ? 'secondary' : 'ghost'}
                  className="justify-start"
                  onClick={() => setActiveTab('details')}
                >
                  {t('orders.orderDetails')}
                </Button>
                <Button
                  variant={activeTab === 'deliverynote' ? 'secondary' : 'ghost'}
                  className="justify-start"
                  onClick={() => setActiveTab('deliverynote')}
                >
                  {t('orders.deliveryNote')}
                </Button>
              </div>

              <div className="mt-auto p-2">
                <Button
                  variant="outline"
                  className="w-full justify-start"
                  onClick={() => setHistoryDialogOpen(true)}
                >
                  <History className="mr-2 h-4 w-4" />
                  {t('orders.viewHistory')}
                </Button>
              </div>
            </div>

            {/* Content */}
            <div className="flex-1 overflow-hidden flex flex-col">
              <ScrollArea className="flex-1 px-6">
                {formSubmitted && Object.keys(errors).length > 0 && (
                  <Alert variant="destructive" className="mb-6">
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>
                      {Object.entries(errors).map(([_, error]) => (
                        <div>{t(error)}</div>
                      ))}
                    </AlertDescription>
                  </Alert>
                )}

                {renderContent()}
              </ScrollArea>

              {mode === 'edit' && (
                <DialogFooter className="p-6 pt-2">
                  <Button onClick={handleSave} loading={formSubmitted}>
                    {t('orders.save')}
                  </Button>
                </DialogFooter>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* Separate Order History Dialog */}
      {formData.id && (
        <OrderHistoryDialog
          open={historyDialogOpen}
          onOpenChange={setHistoryDialogOpen}
          orderId={formData.id}
        />
      )}
    </>
  );
}
