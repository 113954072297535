import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import TaskList from './TaskList';
import { fetchTasks } from '../../modules/tasks/actions';

class TaskListContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      issues: [],
    };
  }

  componentDidMount() {
    const self = this;
    const { userId } = this.props;
    this.props.fetchTasks({ userId });
  }
  render() {
    return <TaskList {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    tasks: state.tasks.tasks,
    userId: state.user.details.uid,
    loading: state.tasks.loading,
  };
}

const mapActionsToProps = {
  fetchTasks,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter)(TaskListContainer);
