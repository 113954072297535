import moment from 'moment';
import keyBy from 'lodash.keyby';
import {
  ORDERS_FETCH_REQUESTED,
  ORDERS_FETCH_SUCCEEDED,
  ORDERS_FETCH_FAILED,
  ORG_ORDERS_FETCH_REQUESTED,
  ORG_ORDERS_FETCH_SUCCEEDED,
  ORG_ORDERS_FETCH_FAILED,
} from './types';

const initialState = {
  loading: true,
  list: [],
  ordersMap: {},
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case ORDERS_FETCH_REQUESTED:
    case ORG_ORDERS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case ORDERS_FETCH_SUCCEEDED:
    case ORG_ORDERS_FETCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        list: action.payload.orders,
        ordersMap: keyBy(action.payload.orders, (order) => order.id),
      };
    case ORDERS_FETCH_FAILED:
    case ORG_ORDERS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
