import React from 'react';
import { useQRStatus, useQRStatusUpdate } from './hooks/useQRStatus';
import { useParams } from 'react-router';
import PageLoader from 'components/Loader/PageLoader';
import { OrderStatusEnum, orderStatuses } from './lib/types';
import { getStatusColor, translateStatus } from './lib/utils';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/button';
import { Badge } from 'components/ui/badge';
import { AlertCircle, CircleCheckBig } from 'lucide-react';

export default function QRStatusPage() {
  const { t } = useTranslation();
  const params = useParams<{ orderId: string }>();
  const { data, loading, error } = useQRStatus(params.orderId);
  console.log({ data });

  const {
    response,
    loading: updateLoading,
    error: updateError,
    updateQRStatus,
  } = useQRStatusUpdate();
  if (loading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }
  if (error) {
    return (
      <div className="flex flex-col justify-center h-screen items-center space-y-4">
        <AlertCircle className="text-red-500 h-24 w-24" />
        <p className="text-2xl font-bold">{error.message}</p>
      </div>
    );
  }
  if (updateError) {
    return (
      <div className="flex flex-col justify-center h-screen items-center space-y-4">
        <AlertCircle className="text-red-500 h-24 w-24" />
        <p className="text-2xl font-bold">{updateError.message}</p>
      </div>
    );
  }
  if (response?.updateTime) {
    return (
      <div className="flex flex-col justify-center h-screen items-center space-y-4">
        <CircleCheckBig className="text-green-500 h-24 w-24" />
        <p className="text-2xl font-bold">Ordre status opdateret</p>
        <p className="text-xl">
          {t('Order ID')}: {response?.orderId}
        </p>
        <p className="text-xl">
          Ordre Status er nu:{' '}
          <Badge className={getStatusColor(response?.status as OrderStatusEnum) + ' text-xl'}>
            {t(translateStatus(response?.status as OrderStatusEnum))}
          </Badge>
        </p>

        <div className="text-center">
          <p>Opdateret:</p>
          <p>{t('general.dateTimeYYYY', { date: new Date(response.updateTime * 1000) })}</p>
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col h-screen justify-between p-4">
      <div className="flex flex-col items-center space-y-4">
        <p className="text-xl">
          {t('Order ID')}: {params.orderId}
        </p>
        <p className="text-xl">
          Ordre Status:{' '}
          <Badge className={getStatusColor(data?.status as OrderStatusEnum) + ' text-xl'}>
            {t(translateStatus(data?.status as OrderStatusEnum))}
          </Badge>
        </p>
      </div>
      <div className="space-y-1">
        {data?.availableStatuses?.map((status) => (
          <div key={status}>
            <Button
              disabled={updateLoading}
              className={getStatusColor(status) + ' w-full p-4 py-8 text-xl'}
              onClick={() => updateQRStatus({ orderId: params.orderId, status })}
            >
              {t(translateStatus(status))}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
}
