import { useState } from 'react';
import { Row, Col, Layout, Segmented } from 'antd';
import UploadReport from '../Reports/components/UploadReport';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import Loader from '../../components/Loader';
import { UploadContext } from '../../general/constants';
import AddDirectory from './AddDirectory';
import Directories from './Directories';
import Reports from './Reports';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import BlurOverlayContainer from '../../components/Overlay/BlurOverlayContainer';
import { FeatureIds } from '../../modules/featureControl/featuresConfig';
import { ViewType } from '../Reports/ReportList';
import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import ListItems, { sortByTitle } from 'Views/Reports/ListItems';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { updateReport } from 'modules/reports/actions';
import useDirectoryReports from './useDirectoryReports';
import { DBDirectoryShape } from 'modules/directories/types';
import SearchInput from 'Views/Reports/Search';

const { Content } = Layout;

function DirectoryList({ directories }: { directories: DBDirectoryShape[] }) {
  const dispatch = useDispatch();
  const { orgId } = useParams<{ orgId: string }>();
  const loading = useSelector((state: AppState) => state.orgs.loading);
  const [toggleView, setToggleView] = useState(ViewType.LIST);
  const addForm = <AddDirectory />;
  const { reports } = useDirectoryReports();
  const combinedDirectoriesFiles = useSelector((state: AppState) => [
    ...state.directories.directories
      .filter((d) => !d.parentDirectoryId)
      .map((directory) => ({
        ...directory,
        orgId,
        isDirectory: true,
        title: directory.title ?? '',
      }))
      .sort(sortByTitle),
    ...reports
      .map((report) => ({
        ...report,
        orgId,
        isDirectory: false,
        title: report.title ?? '',
      }))

      .sort(sortByTitle),
  ]);

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <BlurOverlayContainer featureKey={FeatureIds.REPORTS}>
          <Content style={{ margin: '50px 24px 0' }}>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Row>
                  <Col span={24} style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      {addForm}
                    </div>
                  </Col>
                  <Col span={24} style={{ marginBottom: 20 }}>
                    <UploadReport context={UploadContext.REPORT} />
                  </Col>
                </Row>
                {/* <Row>
                                    <Col
                                        span={24}
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            marginBottom: 20,
                                        }}>
                                        <Segmented
                                            value={toggleView}
                                            options={[
                                                {
                                                    label: "Liste",
                                                    value: ViewType.LIST,
                                                    icon: <BarsOutlined />,
                                                },
                                                {
                                                    label: "Gitter",
                                                    value: ViewType.GRID,
                                                    icon: <AppstoreOutlined />,
                                                },
                                            ]}
                                            onChange={(value: any) =>
                                                setToggleView(value as ViewType)
                                            }
                                        />
                                    </Col>
                                </Row> */}
                <Row gutter={[16, 16]}>
                  <Col
                    span={24}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginBottom: 20,
                    }}
                  >
                    <div style={{ width: '100%', marginRight: 24 }}>
                      <SearchInput placeholder="Søg efter filer og mapper" />
                    </div>
                    <Segmented
                      value={toggleView}
                      options={[
                        {
                          label: 'Liste',
                          value: ViewType.LIST,
                          icon: <BarsOutlined />,
                        },
                        {
                          label: 'Gitter',
                          value: ViewType.GRID,
                          icon: <AppstoreOutlined />,
                        },
                      ]}
                      onChange={(value: any) => setToggleView(value as ViewType)}
                    />
                  </Col>
                </Row>
                {toggleView === ViewType.LIST ? (
                  <ListItems data={combinedDirectoriesFiles} />
                ) : (
                  <Row gutter={[16, 16]}>
                    <Directories directories={directories} orgId={orgId} />
                    <Reports />
                  </Row>
                )}
              </>
            )}
          </Content>
        </BlurOverlayContainer>
        <FooterBar />
      </Layout>
    </>
  );
}

export default DirectoryList;
