import React from 'react';
import useEmailDigestNotifications from './useEmailDigestNotifications';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

export default function EmailDigestNotifications({ userId }: { userId: string }) {
  const { hasDailyDigestEmail, onUpdateDailyDigestEmail } = useEmailDigestNotifications(userId);
  const { t } = useTranslation();

  const onChange = (e: CheckboxChangeEvent) => {
    onUpdateDailyDigestEmail(e.target.checked);
  };
  return (
    <Checkbox checked={hasDailyDigestEmail} onChange={onChange}>
      {t('profile.dailydigest')}
    </Checkbox>
  );
}
