import { firestore } from '../../firebase';

type FlowDevice = {
  id: string;
  docId: string;
  EE: ' 38';
  Te: '16.90';
  cm: '7.60';
  createTime: Date;
  l: '109';
  m2: '0';
};

export async function getFlowDevicesData(deviceId: string) {
  if (!deviceId) {
    return [];
  }
  const snap = await firestore
    .collection('flowDevices')
    .where('id', '==', deviceId)
    .limit(100)
    .get();
  const flowDevicesList = snap.docs.map((doc) => ({
    docId: doc.id,
    ...doc.data(),
    createTime: doc.data().createTime.toDate(),
  }));
  // @ts-ignore
  // .filter((d) => d.cm > 0);
  return flowDevicesList as FlowDevice[];
}
