import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSearchKey, setSearchKeyRequestState } from 'modules/user/actions';
import { SET_SEARCH_KEYS_FAILED, SearchKeys } from 'modules/user/types';

function getSearchKeyWithValidity(keys?: SearchKeys) {
  if (!keys) return {};
  return Object.keys(keys).reduce((newKeys, key) => {
    // @ts-ignore
    const item = keys[key];
    const state = !item || new Date(item.expireTime).getTime() < Date.now() ? 'invalid' : 'valid';
    return { ...newKeys, [key]: { ...item, state } };
  }, {});
}

export default function useSearchKeys() {
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state) => state.user.details.uid);
  useEffect(() => {
    let unsubscribe: any = null;
    if (!userId) {
      return;
    }
    unsubscribe = firebase
      .firestore()
      .collection(`users`)
      .doc(userId)
      .collection('searchKeys')
      .doc('searchKey')
      .onSnapshot(
        (snap) => {
          const data = snap?.data();
          dispatch(setSearchKey(getSearchKeyWithValidity(data)));
        },
        (error) => {
          dispatch(setSearchKeyRequestState(SET_SEARCH_KEYS_FAILED));
          console.error(error);
        }
      );

    return () => {
      unsubscribe?.();
    };
  }, [userId]);
}
// "=="
