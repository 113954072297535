import React, { PureComponent } from 'react';
import { format } from 'date-fns';
import MediaQuery from 'react-responsive';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  ReferenceLine,
  AreaChart,
  Area,
} from 'recharts';

const getThresholdByDataKey = (dataKey: string, thresholds: any) => {
  switch (dataKey) {
    case 'ugm3':
      return (thresholds && thresholds[dataKey]) || 25;
    case 'C':
      return (thresholds && thresholds[dataKey]) || 30;
    case 'pc':
      return (thresholds && thresholds[dataKey]) || 60;
    case 'ppm':
      return (thresholds && thresholds[dataKey]) || 1300;
    case 'ppb':
      return (thresholds && thresholds[dataKey]) || 300;
    case '%':
      return (thresholds && thresholds[dataKey]) || 50;

    default:
      return 'Unknown';
  }
};
const getTitleByDataKey = (dataKey: string) => {
  switch (dataKey) {
    case 'ugm3':
      return 'PM2.5 µg/m³';
    case 'C':
      return 'Temperature (°C)';
    case 'pc':
      return 'Humidity (%)';
    case 'ppm':
      return 'CO2 (ppm)';
    case 'ppb':
      return 'tVOC (ppb)';
    case '%':
      return 'Global Index (%)';

    default:
      return 'Unknown';
  }
};

const getFormattedTime = (time: number) => {
  return format(time * 1000, 'dd/MM/yy - HH:mm');
};

enum dataUnit {
  UGM3 = 1,
  TEMPERATURE = 2,
  HUMIDITY = 3,
  CO2 = 4,
  VOC = 5,
  TVOC = 6,
}

const height = 400;
const width = 650;

const CustomTooltip = ({ active, payload, thresholds }: any) => {
  if (active) {
    const title = getTitleByDataKey(payload[0]?.dataKey);
    const time = getFormattedTime(payload[0]?.payload?.time);
    const threshold = getThresholdByDataKey(payload[0]?.dataKey, thresholds);
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: '#fff',
          padding: 16,
          borderRadius: 8,
          boxShadow: '0 1px 12px rgba(0,0,0,0.06), 0 1px 8px rgba(0,0,0,0.12)',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <div>{`${title}`}</div>
          <div style={{ padding: 5 }} />
          <div style={{ fontSize: 10 }}> {time}</div>
        </div>
        <hr />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Device: </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: 10,
                width: 10,
                marginRight: 10,
                borderRadius: '50%',
                backgroundColor: payload[0]?.color,
              }}
            />{' '}
            <div>{payload[0]?.value?.toFixed(0)}</div>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>Threshold: </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                height: 10,
                width: 10,
                marginRight: 10,
                borderRadius: '50%',
                backgroundColor: '#333',
              }}
            />{' '}
            <div>{threshold}</div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

function CustomizedTick(props: any) {
  return (
    <text {...props} fontSize=".85em">
      <tspan x={props.x} dy="0.9em">
        {props.payload?.value}
      </tspan>
    </text>
  );
}

const cardStyle = {
  backgroundColor: '#fff',
  padding: 16,
  marginBottom: 8,
  borderRadius: 8,
  // @ts-ignore
  overflow: 'scroll',
};

export default class AirQuality extends PureComponent<any> {
  render() {
    const { data, units, thresholds } = this.props;
    console.log({ data });

    const allpolluThreshold = (thresholds && thresholds['%']) || 50;
    const pmThreshold = thresholds?.ugm3 || 25;
    const co2Threshold = thresholds?.ppm || 1300;
    const pcThreshold = thresholds?.pc || 60;
    const tmpThreshold = thresholds?.C || 30;
    const vocThreshold = thresholds?.ppb || 300;
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <div style={cardStyle}>
          <h2>Global Index (%)</h2>
          <LineChart
            width={width}
            height={height}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f4f4f5" />
            <XAxis dataKey="name" stroke="grey" minTickGap={20} tick={<CustomizedTick />} />
            <YAxis stroke="grey" />
            <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
            <ReferenceLine y={allpolluThreshold} label="" stroke="#333" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey={units[dataUnit.TVOC]}
              stroke="#757575"
              fill="#757575"
              dot={false}
            />
            {/* <MediaQuery query="(max-width: 768px)"> */}
            <Brush dataKey="name" />
            {/* </MediaQuery> */}
          </LineChart>
        </div>
        <div style={cardStyle}>
          <h2>PM2.5 µg/m³</h2>
          <LineChart
            width={width}
            height={height}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f4f4f5" />
            <XAxis dataKey="name" stroke="grey" minTickGap={20} tick={<CustomizedTick />} />
            <YAxis stroke="grey" />
            <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
            <ReferenceLine y={pmThreshold} label="" stroke="#333" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey={units[dataUnit.UGM3]}
              stroke="#4a148c"
              fill="#4a148c"
              dot={false}
            />
          </LineChart>
        </div>
        <div style={cardStyle}>
          <h2>Temperature (°C)</h2>
          <LineChart
            width={width}
            height={height}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f4f4f5" />
            <XAxis dataKey="name" stroke="grey" minTickGap={20} tick={<CustomizedTick />} />
            <YAxis stroke="grey" />
            <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
            <ReferenceLine y={tmpThreshold} label="" stroke="#333" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey={units[dataUnit.TEMPERATURE]}
              stroke="#ff6f00"
              fill="#ff6f00"
              dot={false}
            />
          </LineChart>
        </div>
        <div style={cardStyle}>
          <h2>Humidity (%)</h2>
          <LineChart
            width={width}
            height={height}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f4f4f5" />
            <XAxis dataKey="name" stroke="grey" minTickGap={20} tick={<CustomizedTick />} />
            <YAxis stroke="grey" />
            <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
            <ReferenceLine y={pcThreshold} label="" stroke="#333" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey={units[dataUnit.HUMIDITY]}
              stroke="#0d47a1"
              fill="#0d47a1"
              dot={false}
            />
          </LineChart>
        </div>
        <div style={cardStyle}>
          <h2>CO2 (ppm)</h2>
          <LineChart
            width={width}
            height={height}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f4f4f5" />
            <XAxis dataKey="name" stroke="grey" minTickGap={20} tick={<CustomizedTick />} />
            <YAxis stroke="grey" />
            <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
            <ReferenceLine y={co2Threshold} label="" stroke="#333" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey={units[dataUnit.CO2]}
              stroke="#1b5e20"
              fill="#1b5e20"
              dot={false}
            />
          </LineChart>
        </div>
        <div style={cardStyle}>
          <h2>tVOC (ppb)</h2>
          <LineChart
            width={width}
            height={height}
            data={data}
            syncId="anyId"
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid stroke="#f4f4f5" />
            <XAxis dataKey="name" stroke="grey" minTickGap={20} tick={<CustomizedTick />} />
            <YAxis stroke="grey" />
            <Tooltip content={<CustomTooltip thresholds={thresholds} />} />
            <ReferenceLine y={vocThreshold} label="" stroke="#333" strokeDasharray="3 3" />
            <Line
              type="monotone"
              dataKey={units[dataUnit.VOC]}
              stroke="#82ca9d"
              fill="#82ca9d"
              dot={false}
            />
          </LineChart>
        </div>
      </div>
    );
  }
}
