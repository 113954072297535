import { ReportState, DBReportShape } from './types';
import {
  REPORT_FETCH_REQUESTED,
  REPORT_FETCH_SUCCEEDED,
  REPORT_FETCH_FAILED,
  ReportActionTypes,
} from './types';

const initialState: {
  reports: DBReportShape[];
  loading: boolean;
} = {
  reports: [],
  loading: true,
};

export default function reports(state = initialState, action: ReportActionTypes): ReportState {
  switch (action.type) {
    case REPORT_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case REPORT_FETCH_SUCCEEDED:
      return {
        ...state,
        reports: action.payload,
        loading: false,
      };
    case REPORT_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
