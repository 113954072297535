import React, { useState } from 'react';
import { Select } from 'antd';
import useAuditTemplateCategories from 'modules/auditTemplateCategories/useAuditTemplateCategories';
import { useTranslation } from 'react-i18next';

export default function SearchCategory({ groupIds, categoriesFilter, ...rest }: any) {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');

  const { loading, auditTemplateCategories } = useAuditTemplateCategories({
    orgId: '_',
    groupIds,
  });

  const filteredCategories = auditTemplateCategories
    .filter((c) => (categoriesFilter ? categoriesFilter.includes(c.id) : true))
    .filter((category) => category.name.toLowerCase().includes(inputValue.toLowerCase()))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Select
      style={{ width: '100%' }}
      {...rest}
      mode={'multiple'}
      onSearch={setInputValue}
      placeholder={t('audit.reports.categoryPlaceholder')}
      size="large"
      onFocus={() => setInputValue('')}
      showSearch
      filterOption={false}
    >
      {filteredCategories.map((template: any) => (
        <Select.Option key={template.id} value={template.id}>
          {template.name}
        </Select.Option>
      ))}
    </Select>
  );
}
