import React, { useState } from 'react';
import { Table, Form } from 'antd';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { parseAmountToFloat } from '../../../general/helpers';
import { EditableCell } from './TableEditCell';
import columns from './columns';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../hooks';
import { Unit } from '../../../components/SelectUnit';

const EditableTable = ({ data, isShowingHistory, updateCleanLog, updateOrgProduct }: any) => {
  const { t } = useTranslation();
  const orgUnit = useAppSelector((state) => state.orgs.currentOrg?.unit || Unit.LITER);
  const [form] = Form.useForm();
  const isMobile = useIsMobile();
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record: any) => record.key === editingKey;
  console.log({ EditableTable: data });

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key: any, currentAmount: number) => {
    try {
      const row = await form.validateFields();

      const diffAmount = parseAmountToFloat(row.amount) - parseAmountToFloat(currentAmount);

      updateCleanLog({ logId: key, data: row });
      updateOrgProduct(diffAmount);

      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columnsToRender = columns(editingKey, isEditing, save, edit, cancel, orgUnit);

  const mergedColumns = columnsToRender
    .filter((log) => (isMobile && log.dataIndex !== 'createTime') || log)
    .map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: any) => ({
          record,
          inputType: col.dataIndex === 'amount' ? 'number' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });
  return (
    <Form form={form} component={false}>
      {data.length > 0 ? (
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          locale={{
            emptyText: isShowingHistory
              ? t('cleanlog.empty.nologs')
              : t('cleanlog.empty.nologstoday'),
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={
            isShowingHistory
              ? {
                  onChange: cancel,
                  defaultPageSize: 5,
                  showTotal: (total: number) => t('cleanlog.pagination.total', { logs: total }),
                }
              : false
          }
        />
      ) : (
        <div
          style={{
            fontSize: 20,
            textAlign: 'center',
            color: '#c0c0c0',
            padding: 12,
          }}
        >
          {isShowingHistory ? t('cleanlog.empty.nologs') : t('cleanlog.empty.nologstoday')}
        </div>
      )}
    </Form>
  );
};

export default EditableTable;
