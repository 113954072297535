import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export default function useGroupOrders(groupId: string | null) {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<any[]>([]);
  useEffect(() => {
    if (groupId) {
      setLoading(true);
      let unsubscribe: any = null;
      unsubscribe = firebase
        .firestore()
        .collection('orders')
        .where('groupId', '==', groupId)
        .orderBy('createTime', 'desc')
        .limit(5)
        .onSnapshot(
          (query) => {
            if (!query.empty) {
              const orders = query.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              })) as any[];
              setOrders(orders);
            }
            setLoading(false);
          },
          (error) => {
            console.error(error);
            setLoading(false);
          }
        );
      return unsubscribe;
    }
  }, [groupId]);

  return {
    orders,
    loadingOrders: loading,
  };
}
