import { startOfMonth, endOfMonth } from 'date-fns';
import {
  AUDIT_SCHEDULE_ADD_REQUESTED,
  AUDIT_SCHEDULE_ADD_SUCCEEDED,
  AuditScheduleActionTypes,
  AuditScheduleState,
  COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED,
  NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
} from './types';
import {
  AUDIT_SCHEDULES_FETCH_REQUESTED,
  AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  AUDIT_SCHEDULES_FETCH_FAILED,
  AUDIT_SCHEDULE_FETCH_REQUESTED,
  AUDIT_SCHEDULE_FETCH_SUCCEEDED,
  AUDIT_SCHEDULE_FETCH_FAILED,
  AUDIT_SCHEDULE_TASKS_FETCH_REQUESTED,
  AUDIT_SCHEDULE_TASKS_FETCH_SUCCEEDED,
  INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED,
  AUDIT_CALENDAR_CHANGE,
} from './types';
import keyBy from 'lodash.keyby';

const initialState: AuditScheduleState = {
  auditSchedules: [],
  auditSchedulesMap: {},
  inCompleteAuditSchedules: [],
  onHoldAuditSchedules: [],
  needsResolutionAuditSchedules: [],
  actionRequireAuditSchedules: [],
  overdueAuditSchedules: [],
  completeAuditSchedules: [],
  // @ts-ignore
  currentAuditSchedule: {
    tasks: {},
  },
  addRequestState: {},
  calendarDateStart: startOfMonth(new Date()),
  calendarDateEnd: endOfMonth(new Date()),
  calendarMode: 'dayGridMonth',
  loading: false,
  isLoadingCurrent: true,
  currentTab: 'incompleted',
};

export default function auditSchedule(
  state = initialState,
  action: AuditScheduleActionTypes
): AuditScheduleState {
  switch (action.type) {
    case AUDIT_SCHEDULES_FETCH_REQUESTED:
    case INCOMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED:
    case COMPLETE_AUDIT_SCHEDULES_FETCH_REQUESTED:
    case AUDIT_SCHEDULES_FETCH_REQUESTED:
    case AUDIT_SCHEDULE_TASKS_FETCH_REQUESTED:
    case AUDIT_SCHEDULE_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case AUDIT_SCHEDULE_ADD_REQUESTED:
      return {
        ...state,
        addRequestState: {
          ...state.addRequestState,
          [action.payload.auditId]: true,
        },
      };
    case AUDIT_SCHEDULE_ADD_SUCCEEDED:
      return {
        ...state,
        addRequestState: {
          ...state.addRequestState,
          [action.payload.auditId]: false,
        },
      };
    case AUDIT_SCHEDULES_FETCH_SUCCEEDED:
      return {
        ...state,
        auditSchedules: action.payload.data,
        auditSchedulesMap: keyBy(action.payload.data, 'id'),
        loading: false,
      };
    case INCOMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED:
      return {
        ...state,
        inCompleteAuditSchedules: action.payload.data,
        loading: false,
      };
    case ON_HOLD_AUDIT_SCHEDULES_FETCH_SUCCEEDED:
      return {
        ...state,
        onHoldAuditSchedules: action.payload.data,
        loading: false,
      };
    case NEEDS_RESOLUTION_AUDIT_SCHEDULES_FETCH_SUCCEEDED:
      return {
        ...state,
        needsResolutionAuditSchedules: action.payload.data,
        loading: false,
      };
    case OVERDUE_AUDIT_SCHEDULES_FETCH_SUCCEEDED:
      return {
        ...state,
        overdueAuditSchedules: action.payload.data,
        loading: false,
      };
    case COMPLETE_AUDIT_SCHEDULES_FETCH_SUCCEEDED:
      return {
        ...state,
        completeAuditSchedules: action.payload.data,
        loading: false,
      };
    case AUDIT_SCHEDULES_FETCH_FAILED:
    case AUDIT_SCHEDULE_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };
    case AUDIT_SCHEDULE_FETCH_SUCCEEDED:
      return {
        ...state,
        // @ts-ignore
        currentAuditSchedule: action.payload.data,
        loading: false,
        isLoadingCurrent: false,
      };
    case AUDIT_SCHEDULE_TASKS_FETCH_SUCCEEDED:
      return {
        ...state,
        // currentAuditScheduleTasks: action.payload.data,
      };
    case AUDIT_CALENDAR_CHANGE:
      return {
        ...state,
        calendarDateStart: action.payload.dateStart,
        calendarDateEnd: action.payload.dateEnd,
        calendarMode: action.payload.mode,
      };

    default:
      return state;
  }
}
