import { useSelector, useDispatch } from 'react-redux';
import { addDirectory } from '../../modules/directories/actions';
import { AppState } from '../../store';

function useAddDirectories() {
  const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
  const dispatch = useDispatch();

  const dispatchAddDirectory = async (title: string, directoryId?: string) =>
    dispatch(addDirectory({ orgId, title, directoryId }));

  return { dispatchAddDirectory };
}

export default useAddDirectories;
