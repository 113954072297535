import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';

export function useBaseGsmPlantSchema() {
  const [schema, setSchema] = useState<{}>({});
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('gsmPlantBaseSchema')
      .doc('base')
      .onSnapshot((query) => {
        if (!query.exists) return;
        const data = query.data();
        setSchema({ ...data });

        setisLoading(false);
      });
    return () => unsub();
  }, []);
  return { baseSchema: schema, isLoading };
}

export default function useLatestGsmPlantLog({
  deviceId,
  rapportMapping,
}: {
  deviceId: string;
  rapportMapping?: string;
}) {
  const [state, setstate] = useState<{}>({});
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    if (!deviceId) return;
    const rapportKey = rapportMapping || 'rapport';
    const unsub = firebase
      .firestore()
      .collection('gsmPlantLogs')
      .where('sensorId', '==', deviceId)
      .where(rapportKey, '==', '1')
      .orderBy('createTime', 'desc')
      .limit(1)
      .onSnapshot((query) => {
        if (!query.empty) {
          const [data] = query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setstate(data);
        }
        setisLoading(false);
      });
    return () => unsub();
  }, [deviceId, rapportMapping]);
  return { data: state, isLoading };
}

export function useGsmPlantLog({
  deviceId,
  rapportMapping,
}: {
  deviceId: string;
  rapportMapping?: string;
}) {
  const [state, setstate] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    if (!deviceId) return;
    const rapportKey = rapportMapping || 'rapport';
    const unsub = firebase
      .firestore()
      .collection('gsmPlantLogs')
      .where('sensorId', '==', deviceId)
      .where(rapportKey, '==', '1')
      .orderBy('createTime', 'desc')
      .limit(20)
      .onSnapshot((query) => {
        if (!query.empty) {
          const data = query.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setstate(data);
        }
        setisLoading(false);
      });
    return () => unsub();
  }, [deviceId, rapportMapping]);
  return { data: state, isLoading };
}

export function useLatestGsmPlantAlerts({ deviceId }: { deviceId: string }) {
  const [state, setstate] = useState<Schema[]>([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    if (!deviceId) return;
    const unsub = firebase
      .firestore()
      .collection('gsmPlantLogs')
      .where('sensorId', '==', deviceId)
      .where('alarm', '==', '1')
      .where('hasErrors', '==', true)
      .orderBy('createTime', 'desc')
      .limit(20)
      .onSnapshot((query) => {
        if (!query.empty) {
          const data = query.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
                createTime: doc.data().createTime.toDate(),
                resolveTime: doc.data().resolveTime?.toDate(),
              } as Schema)
          );
          setstate(data);
        }
        setisLoading(false);
      });
    return () => unsub();
  }, [deviceId]);
  return { data: state, isLoading };
}

export interface Schema {
  id: string;
  createTime: Date;
  resolveTime?: Date;
  hasErrors: boolean;
  errors: string[];
  formattedAlertsMessages: string[];
  module: string;
  timestamp: string;
  // nodtryk: number;
  alarm: number;
  binder_mangel: number;
  noedtryk: number; // this is 0 when error and 1 when no error
  termo_fejl: number;
  vand_mangel: number;
  //
  mix: number;
  binder_total_l: number;
  kfaktor: number; // k-faktor (1000 liter)
  vand_total_1000_l: number; // liter vand op til 1000
  vand_total_l: number; // liter vand op til 1000
  //
  pumpe_total: number;
  // varme_on_total_tid: number;
  // temp_ude: number;
  // kompressor_drift_tid: number;
  // service_tryk_pumpe: number;
  // service_kompressor: number;

  // handle wrong keys
  // maps to binder_total_l:
  binde_total_l?: number;
  binder_forbrug_total?: number;
  // maps to vand_total_l
  vand_total?: number;
  // maps to mix
  mix_1_1000?: number;
  // maps to noedtryk
  nodtryk?: number;
  // maps to alarm
  fejl?: number;
}
