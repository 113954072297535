import { FeatureIds } from './featuresConfig';

export interface DBFeaturesShape {
  id?: string;
  title?: string;
  isEnabled?: boolean;
  isDisplayed?: boolean;
}

export type auditTab = 'completed' | 'incompleted';

type Features = (typeof FeatureIds)[keyof typeof FeatureIds];

export interface FeaturesState extends DBFeaturesShape {
  features: DBFeaturesShape[];
  featuresMap: { [id in Features]?: DBFeaturesShape };
  loading: boolean;
}

export const FEATURES_FETCH_SUCCEEDED = 'FEATURES_FETCH_SUCCEEDED';
export const FEATURES_FETCH_FAILED = 'FEATURES_FETCH_FAILED';
export const FEATURES_FETCH_REQUESTED = 'FEATURES_FETCH_REQUESTED';

export const FEATURES_UPDATE_SUCCEEDED = 'FEATURES_UPDATE_SUCCEEDED';
export const FEATURES_UPDATE_FAILED = 'FEATURES_UPDATE_FAILED';
export const FEATURES_UPDATE_REQUESTED = 'FEATURES_UPDATE_REQUESTED';

interface FetchFeautures {
  type: typeof FEATURES_FETCH_REQUESTED;
  payload: { orgId: string };
}
interface FetchFeauturesSuccess {
  type: typeof FEATURES_FETCH_SUCCEEDED;
  payload: { data: DBFeaturesShape[] };
}
interface FetchFeauturesFailed {
  type: typeof FEATURES_FETCH_FAILED;
  payload: {};
}
export type FeaturesActionTypes = FetchFeautures | FetchFeauturesSuccess | FetchFeauturesFailed;
