import React from 'react';
import { Form, Input, Button, InputNumber } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { parseAmountToFloat } from '../../general/helpers';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import firebase from 'firebase/compat/app';
import { useSelector } from 'react-redux';
import { AppState } from '../../store';
import Message, { Type } from 'components/Notification/Message';

const FormItem = Form.Item;

function hasErrors(fieldsError: any) {
  return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

const formItemLayout = {
  wrapperCol: { span: 24 },
};

interface Props {
  hourTrackerId: string;
  currentCount: number;
  totalCount: number;
}

function AddLogForm(props: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isMobile = useIsMobile();
  const orgId = useSelector((state: AppState) => state.orgs.currentOrg.id);
  const userId = useSelector((state: AppState) => state.user.details.uid);

  const onFinish = async (values: any) => {
    try {
      if (!orgId || !userId) throw new Error('Failed');
      Message({
        key: 'addHourLog',
        message: 'Loading...',
        // description: "Sensoren blev tilføjet korrekt",
        type: Type.LOADING,
      });
      const db = firebase.firestore();
      console.log({ values });

      const batch = db.batch();
      const logRef = db.collection('orgs').doc(orgId).collection('hourLogs').doc();
      batch.set(logRef, {
        createTime: new Date(),
        count: values.hourCount,
        userId,
        hourTrackerId: props.hourTrackerId,
      });
      const hourTrackerRef = db
        .collection('orgs')
        .doc(orgId)
        .collection('hourTrackers')
        .doc(props.hourTrackerId);
      const diff =
        values.hourCount < props.currentCount
          ? values.hourCount
          : Math.abs(values.hourCount - props.currentCount);
      console.log({ hour: values, current: props.currentCount, diff });

      batch.set(
        hourTrackerRef,
        {
          updateTime: new Date(),
          currentCount: values.hourCount,
          totalCount: props.totalCount + diff,
        },
        { merge: true }
      );

      await batch.commit();

      Message({
        key: 'addHourLog',
        message: 'Timetal tilføjet',
      });
      form.resetFields();
    } catch (error) {
      console.error(error);
      Message({
        key: 'addHourLog',
        type: Type.ERROR,
        message: `Der skete en fejl`,
        description: `Kunne ikke tilføje timetæller, prøv venligst igen`,
      });
    }
  };
  const onFinishFailed = ({ values, errorFields }: any) => {
    form.scrollToField(errorFields[0].name);
    console.log({ values, errorFields });
  };
  const validateNum = async (rule: any, value: any) => {
    console.log({ rule, value });
    const num = parseAmountToFloat(value);

    if (isNaN(num) || num <= 0) {
      throw new Error('ERROORORRROROO');
    }
  };

  return (
    <Form
      form={form}
      layout={isMobile ? 'horizontal' : 'inline'}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {/* <FormItem
                name="initials"
                rules={[{ required: true, message: t("cleanlog.add.initials.require") }]}>
                <Input
                    prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                    placeholder={t("cleanlog.add.initials.placeholder")}
                />
            </FormItem> */}
      <FormItem
        {...formItemLayout}
        name="hourCount"
        rules={[
          {
            required: true,
            message: t('hourLogs.add.hourCountRequire'),
            validator: validateNum,
          },
        ]}
      >
        <InputNumber
          placeholder={t('hourLogs.add.hourCountPlaceholder')}
          min={0}
          decimalSeparator=","
          // precision={2}
        />
      </FormItem>
      <FormItem>
        <Button type="primary" htmlType="submit" block={isMobile}>
          {t('hourLogs.add.submit')}
        </Button>
      </FormItem>
    </Form>
  );
}

export default AddLogForm;
