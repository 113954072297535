export const palette = {
  // new colors
  warnLight: '#ffe69c',
  warn: '#ffca28',
  warnDark: '#f7bc07',
  infoLighter: '#F2F5FE',
  infoLight: '#DEE5F8',
  info: '#415Ba8',
  infoDark: '#212E54',
  successLight: '#CDF6D2',
  success: '#11851D',
  successDark: '#09430F',
  cyanLight: '#C7EDF5',
  cyan: '#1387A6',
  greenLight: '#C3E1DC',
  green: '#118575',
  greyLight: '#e9e9e9',
  grey: '#757575',
  amberLight: '#FFEBD6',
  amber: '#EA9946',
  amberDark: '#754D23',
  pinkLight: '#F5C7EB',
  pink: '#D71EAE',
  purpleLight: '#DBC7F5',
  purple: '#6F1ED7',
  redishLight: '#FFD2D5',
  redish: '#D71E29',
  redishDark: '#6C0F15',
  // Grey scale
  grey0: '#ffffff',
  grey50: '#fcfcfc',
  grey100: '#f9f9f9',
  grey200: '#f1f1f1',
  grey300: '#e9e9e9',
  grey400: '#CBCBCB',
  grey500: '#ADADAD',
  grey600: '#919191',
  grey700: '#757575',
  grey800: '#4c4c4c',
  grey900: '#222222',
  // status light
  statusProcessingLight: '#E3F2FD',
  statusInprogressLight: '#A0E6E6',
  statusSuccessLight: '#E8F5E9',
  statusErrorLight: '#FFEBEE',
  statusWarningLight: '#FFF3E0',
  statusHardWarningLight: '#FDE9D7',
  statusDefaultLight: '#ECEFF1',
  // status dark
  statusProcessingDark: '#90CAF9',
  statusInprogressDark: '#13c2c2',
  statusSuccessDark: '#A5D6A7',
  statusErrorDark: '#EF9A9A',
  statusWarningDark: '#FFCC80',
  statusHardWarningDark: '#D46B08',
  statusDefaultDark: '#B0BEC5',
  // states
  danger: '#ef5350',
  warning: '#FFCA28',
  hardWarning: '#D46B08',
  processing: '#1677ff',
  done: '#66BB6A',
  aks: '#009ce1',
  inprogress: '#A0E6E6',
};
