import { Badge, List } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

type Props = {
  taskCount?: number;
  color: string;
  linkPath: string;
  title: string;
  subTitle?: string;
  description: React.ReactNode;
};

export default function AuditItem({
  taskCount = 0,
  color,
  linkPath,
  title,
  subTitle,
  description,
}: Props) {
  const history = useHistory();
  return (
    <List.Item
      className="dashboard-list-item"
      style={{
        cursor: 'pointer',
        paddingLeft: 24,
        paddingRight: 24,
      }}
      actions={[taskCount > 0 && <Badge count={taskCount} color={color} />]}
      onClick={() => {
        history.push(linkPath);
      }}
    >
      <List.Item.Meta
        title={
          <div>
            <div style={{ color, whiteSpace: 'pre-line' }}>{title}</div>
            <div
              style={{
                color: 'grey',
                fontWeight: 'normal',
                fontSize: 12,
              }}
            >
              {subTitle}
            </div>
          </div>
        }
        description={
          <div>
            <div
              style={{
                fontSize: 12,
                lineHeight: 1.2,
                whiteSpace: 'pre-line',
              }}
            >
              {description}
            </div>
          </div>
        }
      />
    </List.Item>
  );
}
