import React from 'react';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, message } from 'antd';
import { useAppSelector } from '../../hooks';
import { getAuthExecutiveOrAbove } from '../../general/auth';
import { useDispatch } from 'react-redux';
import { deleteAuditSchedule } from '../../modules/auditSchedule/actions';
import confirmModal from '../../general/confirmModal';
import { useTranslation } from 'react-i18next';
import { MenuProps } from 'antd/lib';
import { isAfter } from 'date-fns';
import Message, { Type } from 'components/Notification/Message';
import firebase from 'firebase/compat/app';
import {
  getFutureAuditScheduleCollectionSameTemplateIdRecurringId,
  useFutureAuditScheduleSameTemplateIdRecurringIdCount,
} from 'hooks/useFutureAuditScheduleSameTemplateIdRecurringIdCount';
import { RecurringOptions } from 'general/enums';
import {
  getFutureAuditScheduleCollectionSameTemplateId,
  useFutureAuditScheduleSameTemplateIdCount,
} from 'hooks/useFutureAuditScheduleSameTemplateIdCount';
import { getDocs, query } from 'firebase/firestore';

const db = firebase.firestore();

async function batchDeleteAudits({ orgId, auditIds }: { orgId: string; auditIds: string[] }) {
  try {
    if (!orgId) throw new Error('orgId is required');
    if (!auditIds || auditIds.length < 1) throw new Error('auditIds is required');
    Message({
      key: 'confirmDeleteAll',
      message: 'Loading...',
      // description: "Sensoren blev tilføjet korrekt",
      type: Type.LOADING,
    });

    const batch = db.batch();
    auditIds.forEach((auditId) => {
      const auditRef = db.collection('orgs').doc(orgId).collection('auditSchedules').doc(auditId);
      batch.delete(auditRef);
    });
    await batch.commit();
    Message({
      key: 'confirmDeleteAll',
      message: 'Opgaverne blev slettet korrekt',
      // description: "Sensoren blev tilføjet korrekt",
      type: Type.SUCCESS,
    });
  } catch (error) {
    console.log(error);
    Message({
      key: 'confirmDeleteAll',
      message: 'Noget gik galt, prøv igen',
      // description: "Sensoren blev tilføjet korrekt",
      type: Type.ERROR,
    });
  }
}

export default function DeleteAuditScheduleButton({
  auditScheduleId,
}: // handleClose,
{
  auditScheduleId: string;
  // handleClose: VoidFunction;
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const audit = useAppSelector((state) =>
    state.auditSchedules.auditSchedules.find((a) => a.id === auditScheduleId)
  );
  const { canEdit, orgId } = useAppSelector((state) => ({
    canEdit: getAuthExecutiveOrAbove(state.orgs.currentOrg, state.user.details.uid),
    orgId: state.orgs.currentOrg.id,
  }));

  const { auditCount: auditFutureSameTemplateIdRecurringId } =
    useFutureAuditScheduleSameTemplateIdRecurringIdCount({
      orgId,
      templateId: audit?.templateId,
      currentDate: audit?.dueDate.toDate(),
      recurringId: audit?.recurringId,
    });
  const { auditCount: auditFutureSameTemplateId } = useFutureAuditScheduleSameTemplateIdCount({
    orgId,
    templateId: audit?.templateId,
    currentDate: audit?.dueDate.toDate(),
  });
  // console.log({ auditFutureSameTemplateIdRecurringId });

  const confirmDelete = () => {
    confirmModal({
      title: t('Er du sikker på du vil slette denne opgave?'),
      onOk: () => {
        dispatch(deleteAuditSchedule({ orgId, auditScheduleId }));
        message.info('Slettet');
      },
    });
  };
  const confirmDeleteAllFutureSameRecurring = () => {
    confirmModal({
      title: t('Er du sikker på du vil slette denne opgave?'),
      onOk: async () => {
        try {
          const templateId = audit?.templateId;
          const currentDate = audit?.dueDate.toDate();
          if (!templateId || !currentDate) {
            throw new Error(
              `Missing templateId=${templateId} or currentDate=${currentDate} for orgId=${orgId}`
            );
          }
          const q = getFutureAuditScheduleCollectionSameTemplateIdRecurringId({
            orgId,
            templateId: templateId,
            recurringId: audit?.recurringId,
            currentDate: currentDate,
          });
          const snap = await getDocs(q);
          const auditIdsToDelete = snap.docs.map((d) => d.id);
          await batchDeleteAudits({
            orgId,
            auditIds: [auditScheduleId, ...auditIdsToDelete],
          });
          // handleClose();
        } catch (error) {
          console.log(error);
        }
      },
    });
  };
  const confirmDeleteAllFuture = () => {
    confirmModal({
      title: t('Er du sikker på du vil slette denne opgave og fremtidige?'),
      onOk: async () => {
        try {
          const templateId = audit?.templateId;
          const currentDate = audit?.dueDate.toDate();
          if (!templateId || !currentDate) {
            throw new Error(
              `Missing templateId=${templateId} or currentDate=${currentDate} for orgId=${orgId}`
            );
          }
          const q = getFutureAuditScheduleCollectionSameTemplateId({
            orgId,
            templateId: templateId,
            currentDate: currentDate,
          });
          const snap = await getDocs(q);
          const auditIdsToDelete = snap.docs.map((d) => d.id);
          await batchDeleteAudits({
            orgId,
            auditIds: [auditScheduleId, ...auditIdsToDelete],
          });
          // handleClose();
        } catch (error) {
          console.log(error);
        }
      },
    });
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e);
    switch (e.key) {
      case '1':
        confirmDeleteAllFuture();
        break;
      case '2':
        confirmDeleteAllFutureSameRecurring();
        break;

      default:
        break;
    }
  };

  const items: MenuProps['items'] = [
    {
      label: `Slet ${auditFutureSameTemplateId} fremtidige "${audit?.templateTitle}"`,
      key: '1',
      icon: <DeleteOutlined />,
      danger: true,
      disabled: auditFutureSameTemplateId < 1,
    },
    {
      label: `Slet ${auditFutureSameTemplateIdRecurringId} fremtidige "${audit?.templateTitle}" med samme gentagelses ID`,
      key: '2',
      icon: <DeleteOutlined />,
      danger: true,
      disabled: auditFutureSameTemplateIdRecurringId < 1,
    },
  ];
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  if (canEdit) {
    /**
     * If the audit never repeats, then we can just show the delete button
     */
    if (audit?.recurringOption === RecurringOptions.NEVER) {
      return (
        <>
          <Button disabled={!canEdit} onClick={confirmDelete} danger>
            <DeleteOutlined style={{ color: 'red' }} />
          </Button>
        </>
      );
    }
    /**
     * If the audit is recurring, then we need to show the delete button with a dropdown
     * that allows the user to delete all future audits with the same templateId
     */
    return (
      <>
        <Dropdown.Button disabled={!canEdit} menu={menuProps} onClick={confirmDelete} danger>
          <DeleteOutlined style={{ color: 'red' }} />
        </Dropdown.Button>
      </>
    );
  }
  return null;
}
