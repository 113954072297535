import React from 'react';
import { Layout, Empty } from 'antd';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';

import InventoryNav from './InventoryNav';

const { Content } = Layout;

function InventoryView() {
  return (
    <>
      <InventoryNav />
      <Layout>
        <HeaderBar />
        <Content
          style={{
            margin: '24px 24px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Empty description={<span>Vælg i menuen</span>} />
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}

export default InventoryView;
