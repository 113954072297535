import React, { useEffect } from 'react';
// import { useFirestoreConnect } from "react-redux-firebase";
import { Layout } from 'antd';
import SideNav from '../../../Layout/SideNav';
// import { getGsmPlantData, getSensor } from "../../../modules/sensors/queries";
import HeaderBar from '../../../Layout/HeaderBar';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { fetchSensor } from '../../../modules/sensors/actions';
import { useAppSelector } from '../../../hooks';
import LevelChart from 'Views/LevelSensorOverview/Graph';

export default function LevelSensorInternalContainer() {
  const dispatch = useDispatch();
  const { orgId, sensorId } = useParams<{ orgId: string; sensorId: string }>();
  const currentSensor = useAppSelector((state) => state.sensors.currentSensor);

  useEffect(() => {
    dispatch(fetchSensor({ orgId, sensorId }));
    return () => {};
  }, [orgId, sensorId, dispatch]);

  console.log({ currentSensor });

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar />
        <div className="p-8 space-y-4">
          <h3 className="text-2xl font-bold">{currentSensor?.title}</h3>
          <LevelChart id={currentSensor?.deviceId} thresholds={currentSensor.thresholds} />
        </div>
      </Layout>
    </>
  );
}
