import { Button, Input } from 'antd';
import React, { useState } from 'react';
import './textArea.css';
import styled from 'styled-components';
import { Colors } from 'Layout/Colors';
import { useTranslation } from 'react-i18next';
import useIsReadonlyPath from 'hooks/useIsReadonlyPath';

export default function ClickEditTextArea({
  text,
  onSave,
}: {
  text: string;
  onSave: (text: string) => void;
}) {
  const isReadOnly = useIsReadonlyPath();
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    // @ts-ignore
    onSave(e.target.note.value as string);
    setEditing(false);
  };

  if (editing) {
    return (
      <form onSubmit={handleSubmit} style={{ display: 'flex' }}>
        <Input.TextArea
          name="note"
          disabled={isReadOnly}
          autoFocus
          autoSize={{ maxRows: 4 }}
          defaultValue={text}
        />
        <div style={{ marginLeft: 8 }} />
        <Button disabled={isReadOnly} type="primary" htmlType="submit">
          {t('general.save')}
        </Button>
        <div style={{ marginLeft: 8 }} />
        <Button onClick={() => setEditing(false)}>{t('general.cancel')}</Button>
      </form>
    );
  }

  return (
    <TextAreaFake hasPlaceholder={!Boolean(text)} onClick={() => setEditing(true)}>
      {text || t('audit.detail.noteTooltip')}
    </TextAreaFake>
  );
}

const TextAreaFake = styled.div<{ hasPlaceholder: boolean }>`
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px 11px;
  transition: all 0.3s;
  cursor: pointer;
  color: ${(props) => (props.hasPlaceholder ? '#c0c0c0' : 'inherit')};
  &:hover {
    cursor: pointer;
    border-color: ${Colors.info};
  }
`;
