import { CalendarMode } from 'antd/es/calendar/generateCalendar';
import {
  ISSUES_COMPLETED_FETCH_REQUESTED,
  ISSUES_COMPLETED_FETCH_SUCCEEDED,
  ISSUE_CALENDAR_CHANGE,
  TAB_STATE,
} from './types';
import {
  ISSUES_FETCH_REQUESTED,
  ISSUES_FETCH_SUCCEEDED,
  ISSUE_FETCH_REQUESTED,
  ISSUE_FETCH_SUCCEEDED,
  ISSUE_UPDATE_REQUESTED,
  ISSUE_UPDATE_SUCCEEDED,
  ISSUE_CREATE_REQUESTED,
  ISSUE_DELETE_REQUESTED,
  SET_CURRENT_TAB,
} from './types';

export function fetchIssues({ orgId }: { orgId: string }) {
  return {
    type: ISSUES_FETCH_REQUESTED,
    payload: { orgId },
  };
}

export function fetchIssuesSuccess(issuesList: any) {
  const issues = issuesList.docs.map((issue: any) => ({
    ...issue.data(),
    id: issue.id,
  }));
  return {
    type: ISSUES_FETCH_SUCCEEDED,
    payload: {
      data: issues,
    },
  };
}

export function fetchIssue({ orgId, issueId }: { orgId: string; issueId: string }) {
  return {
    type: ISSUE_FETCH_REQUESTED,
    payload: { orgId, issueId },
  };
}

export function fetchIssueSuccess(issueDoc: any) {
  const issue = { id: issueDoc.id, ...issueDoc.data() };

  return {
    type: ISSUE_FETCH_SUCCEEDED,
    payload: {
      data: issue,
    },
  };
}

export function addIssue(title: string) {
  return {
    type: ISSUE_CREATE_REQUESTED,
    payload: title,
  };
}

export function deleteIssue(id: string) {
  return {
    type: ISSUE_DELETE_REQUESTED,
    payload: id,
  };
}

export function updateIssue({
  orgId,
  issueId,
  data,
}: {
  orgId: string;
  issueId: string;
  data: any;
}) {
  return {
    type: ISSUE_UPDATE_REQUESTED,
    payload: { orgId, issueId, data },
  };
}

export function updateIssueSuccess() {
  return {
    type: ISSUE_UPDATE_SUCCEEDED,
    payload: {},
  };
}

export function setCurrentTab({ key }: { key: TAB_STATE }) {
  return {
    type: SET_CURRENT_TAB,
    payload: { key },
  };
}

export function setIssueCalendar({ date, mode }: { date: Date; mode: CalendarMode }) {
  return {
    type: ISSUE_CALENDAR_CHANGE,
    payload: { date, mode },
  };
}

export function fetchCompletedIssues({
  orgId,
  fromDate,
  toDate,
}: {
  orgId: string;
  fromDate: Date;
  toDate: Date;
}) {
  return {
    type: ISSUES_COMPLETED_FETCH_REQUESTED,
    payload: { orgId, fromDate, toDate },
  };
}

export function fetchCompletedIssuesSuccess(issuesList: any) {
  const issues = issuesList.docs.map((issue: any) => ({
    ...issue.data(),
    id: issue.id,
  }));
  return {
    type: ISSUES_COMPLETED_FETCH_SUCCEEDED,
    payload: {
      data: issues,
    },
  };
}
