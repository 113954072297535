import { Button, Card, Divider, InputNumber, Tooltip } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import {
  aqSensorDataPrice,
  aqSensorPrice,
  dustMeasurementPrice,
  gpsSensorDataPrice,
  gpsSensorPrice,
  tabletPrice,
} from '../Agreements/Context/offerConfigs';
import { getCurrencyFormat } from './Offer';
import { getHighlight } from './Calculator';
import {
  getAdditionalServicesSubscriptionFixedPrice,
  getAdditionalServicesSubscriptionVariablePrice,
  getDiscountFactor,
  getLiterPrice,
  getLiters,
  getPrice,
  useOfferContext,
} from '../Agreements/Context/OfferContext';
import { PriceType } from '../Agreements/Context/offerReducer';

export default function CustomOfferCard({
  setRowHover,
  rowHover,
}: {
  setRowHover: Dispatch<SetStateAction<number | null>>;
  rowHover: number | null;
}) {
  const { state, dispatch } = useOfferContext();
  const isMonthly = state.isMonthlyView;
  const { yearlyLiterPrice, monthlyLiterPrice } = getLiterPrice(state.m2);
  const { litersPrMonth, litersPrYear } = getLiters(state.m2);
  const { yearly, monthly } = getAdditionalServicesSubscriptionFixedPrice(state);
  const { yearlyData, monthlyData } = getAdditionalServicesSubscriptionVariablePrice(state);
  const liters = isMonthly ? litersPrMonth : litersPrYear;
  const price = isMonthly ? monthlyLiterPrice : yearlyLiterPrice;
  const oneTimeHardwareCost = yearly;
  const continousHardwareCost = isMonthly ? monthlyData : yearlyData;
  const isCustomType = state.priceType === PriceType.CUSTOM;
  const discount = state.discount;
  const finalPrice = state.finalPrice;
  const finalLiter = state.finalLiter;

  const airCleaner = {
    leasing: Number(state.airCleaner?.leasing),
    power: Number(state.airCleaner?.power),
    amount: Number(state.airCleaner?.amount),
    additionalProducts: Number(state.airCleaner?.additionalProducts),
  };
  return (
    <Card
      title={`Særtilbud`}
      extra={
        <>
          <Button
            style={{ marginLeft: 8 }}
            type={isCustomType ? 'primary' : 'default'}
            onClick={() => {
              dispatch({
                type: 'SET_PRICE_TYPE',
                payload: PriceType.CUSTOM,
              });
            }}
          >
            {isCustomType ? 'VALGT' : 'Vælg tilbuds type'}
          </Button>
        </>
      }
    >
      <table
        className="price-table"
        style={
          isCustomType || state.priceType === null ? { display: 'block' } : { display: 'none' }
        }
      >
        <thead>
          <th></th>

          <th style={{ textAlign: 'right' }}>
            {oneTimeHardwareCost || continousHardwareCost ? 'Sensorer/hardware' : null}
          </th>

          <th style={{ textAlign: 'right' }}>Liter</th>
          <th style={{ textAlign: 'right' }}>Pris</th>
        </thead>
        <tr
          key={PriceType.CUSTOM}
          onMouseEnter={() => setRowHover(PriceType.CUSTOM)}
          onMouseLeave={() => setRowHover(null)}
          style={getHighlight(state.priceType, rowHover, PriceType.CUSTOM)}
        >
          <td style={{ width: '100%' }}>
            Liter støvbinder:{' '}
            {
              // (3-5 gange - 60-80% reduktion):
            }
          </td>
          <td
            style={{
              paddingLeft: 24,
              whiteSpace: 'nowrap',
              textAlign: 'right',
            }}
          >
            <HardwareCostCell
              values={state}
              oneTimeHardwareCost={oneTimeHardwareCost}
              continousHardwareCost={continousHardwareCost}
              isMonthly={isMonthly}
            />
          </td>
          <td
            style={{
              paddingLeft: 24,
              whiteSpace: 'nowrap',
              textAlign: 'right',
            }}
          >
            <InputNumber<number>
              value={finalLiter}
              min={0}
              style={{ width: 120 }}
              suffix="Liter/md"
              // formatter={value => `${value}DKK`}
              // parser={value =>
              //     value?.replace("DKK", "") as unknown as number
              // }
              onChange={(newLiter) => {
                dispatch({
                  type: 'SET_FINAL_LITER',
                  payload: newLiter,
                });
              }}
            />
          </td>
          <td
            style={{
              paddingLeft: 24,
              whiteSpace: 'nowrap',
              textAlign: 'right',
            }}
          >
            <InputNumber<number>
              value={finalPrice}
              min={0}
              style={{ width: 120 }}
              suffix="DKK/md"
              // formatter={value => `${value}DKK`}
              // parser={value =>
              //     value?.replace("DKK", "") as unknown as number
              // }
              onChange={(newPrice) => {
                dispatch({
                  type: 'SET_FINAL_PRICE',
                  payload: newPrice,
                });
              }}
            />
          </td>

          {/* <td style={{ paddingLeft: 24, textAlign: "right" }}>
                        <Button
                            onClick={() => {
                                dispatch({
                                    type: "SET_PRICE_TYPE",
                                    payload: PriceType.CUSTOM,
                                });
                            }}>
                            Vælg
                        </Button>
                    </td> */}
        </tr>
      </table>
      {state.airCleaner && (
        <div
          style={
            isCustomType || state.priceType === null ? { display: 'block' } : { display: 'none' }
          }
        >
          <Divider style={{ margin: '16px 0' }} />
          <h4>Luftrenser omkostninger</h4>

          {/* <Card title="Besparelser - aks2tal vs luftrensere"> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Luftrenser leasing:</span>
            <span>
              {getCurrencyFormat(airCleaner.leasing * airCleaner.amount * (isMonthly ? 1 : 12))}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>El </span>
            <span>
              {getCurrencyFormat(airCleaner.power * airCleaner.amount * (isMonthly ? 1 : 12))}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Vaskeprodukter der bruges i dag: </span>
            <span>{getCurrencyFormat(airCleaner.additionalProducts * (isMonthly ? 1 : 12))}</span>
          </div>
          <Divider style={{ margin: '16px 0' }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>Udgifter i alt pr {isMonthly ? 'md' : 'år'}:</span>
            <span>
              {getCurrencyFormat(
                (airCleaner.leasing * airCleaner.amount +
                  airCleaner.power * airCleaner.amount +
                  airCleaner.additionalProducts) *
                  (isMonthly ? 1 : 12)
              )}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>aks2tal pr {isMonthly ? 'md' : 'år'}:</span>
            <span>{getCurrencyFormat(state.finalPrice || 0)}</span>
          </div>
          <Divider style={{ margin: '16px 0' }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontWeight: 700,
            }}
          >
            <span>Besparelse pr {isMonthly ? 'md' : 'år'}:</span>
            <span>
              {getCurrencyFormat(
                (airCleaner.leasing * airCleaner.amount +
                  airCleaner.power * airCleaner.amount +
                  airCleaner.additionalProducts) *
                  (isMonthly ? 1 : 12) -
                  (state.finalPrice || 0)
              )}
            </span>
          </div>
          {/* </Card> */}
        </div>
      )}
    </Card>
  );
}

function HardwareCostCell({
  values,
  oneTimeHardwareCost,
  continousHardwareCost,
  isMonthly,
}: {
  values: any;
  oneTimeHardwareCost: number;
  continousHardwareCost: number;
  isMonthly: boolean;
}) {
  if (!oneTimeHardwareCost && !continousHardwareCost) return null;
  console.log({
    gps: values.gps,
  });

  return (
    <>
      <Tooltip
        title={
          <div>
            <div>Engangsbeløb:</div>
            {Number(values.gps) ? (
              <div>GPS: {getCurrencyFormat(values.gps * gpsSensorPrice)}</div>
            ) : null}
            {Number(values.sensors) ? (
              <div>Sensor: {getCurrencyFormat(values.sensors * aqSensorPrice)}</div>
            ) : null}
            {Number(values.tablets) ? (
              <div>Tablet: {getCurrencyFormat(values.tablets * tabletPrice)}</div>
            ) : null}
            {Number(values.dustMeasurements) ? (
              <div>
                Certificeret støvmåling:{' '}
                {getCurrencyFormat(values.dustMeasurements * dustMeasurementPrice)}
              </div>
            ) : null}
          </div>
        }
      >
        {getCurrencyFormat(oneTimeHardwareCost)}
      </Tooltip>
      {'* '}+{' '}
      <Tooltip
        title={
          <div>
            {Number(values.gps) ? (
              <div>
                GPS data: {getCurrencyFormat(values.gps * gpsSensorDataPrice)}{' '}
                {isMonthly ? 'pr. md' : ' pr. år'}
              </div>
            ) : null}
            {Number(values.sensors) ? (
              <div>
                Sensor data: {getCurrencyFormat(values.sensors * aqSensorDataPrice)}{' '}
                {isMonthly ? 'pr. md' : ' pr. år'}
              </div>
            ) : null}
          </div>
        }
      >
        {getCurrencyFormat(continousHardwareCost)}*
      </Tooltip>
    </>
  );
}
