import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { AlertTriangle, Loader, LoaderCircle, Paperclip, X } from 'lucide-react';
import { Attachment } from './audit-context';
import { Lightbox } from './light-box';
import { getFileIcon, getFileIconColor } from 'lib/file-icons';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';

interface ImageUploadProps {
  attachments: Attachment[];
  onUploadAttachment: (files: File[]) => void;
  onRemoveAttachment: (attachment: Attachment) => void;
  disabled?: boolean;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  attachments = [],
  onUploadAttachment,
  onRemoveAttachment,
  disabled,
}) => {
  // const [files, setFiles] = useState<{ file: File }[]>([]);

  // useEffect(() => {
  //   setFiles([]);
  // }, [attachments]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // @ts-ignore
      // setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      onUploadAttachment(acceptedFiles);
    },
    [onUploadAttachment]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'video/*': ['.mp4'],
      'audio/*': ['.mp3'],
      'application/*': ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx'],
    },
    multiple: true,
  });

  const removeFile = (index: number) => {
    setConfirmDelete(attachments[index]);
  };

  // const filteredFiles = files.filter(
  //   (file) => !attachments.some((attachment) => attachment.fileName === file.file?.name)
  // );

  // console.log({ filteredFiles });

  const [confirmDelete, setConfirmDelete] = useState<Attachment | null>(null);

  return (
    <div className="flex w-full items-center gap-2">
      {/* Always display upload area */}

      {/* Display uploaded files with option to remove */}

      <div className="flex flex-wrap gap-2">
        <RenderFiles attachments={attachments} disabled={disabled} removeFile={removeFile} />
        {/* {filteredFiles.map((_, index) => (
          <div
            key={index}
            className="flex items-center justify-center relative w-[52px] h-[52px] border border-gray-300 rounded-lg"
          >
            <LoaderCircle strokeWidth={1} className="animate-spin h-8 w-8 text-gray-300 " />
          </div>
        ))} */}
        {!disabled && (
          <div>
            <div
              {...getRootProps()}
              className={`flex items-center justify-center bg-neutral-50 border-2 border-dashed rounded-lg p-4 cursor-pointer transition-colors ${
                isDragActive
                  ? 'border-blue-500 bg-blue-50'
                  : 'border-gray-300 hover:border-gray-400'
              }`}
            >
              <input {...getInputProps()} />
              <Paperclip className="h-4 w-4 text-gray-400" />

              {/* <span className="ml-2 text-gray-500">Upload files</span> */}
            </div>
          </div>
        )}
      </div>

      {/* {files?.length > 0 && <div className="flex flex-wrap gap-2"></div>} */}
      <ConfirmDeleteModal
        isOpen={!!confirmDelete}
        itemName={confirmDelete?.fileName}
        itemType="fil"
        onClose={() => setConfirmDelete(null)}
        onConfirm={() => {
          if (confirmDelete) {
            onRemoveAttachment(confirmDelete);
            setConfirmDelete(null);
          }
        }}
      />
    </div>
  );
};

interface ConfirmDeleteModalProps {
  isOpen: boolean;
  itemName?: string;
  itemType?: string;
  onClose: () => void;
  onConfirm: () => void;
  isDeleting?: boolean;
}

export function ConfirmDeleteModal({
  isOpen,
  itemName = 'this item',
  itemType = 'item',
  onClose,
  onConfirm,
  isDeleting = false,
}: ConfirmDeleteModalProps) {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <div className="flex items-center gap-2 text-destructive">
            <AlertTriangle className="h-5 w-5" />
            <DialogTitle>Slet {itemType}</DialogTitle>
          </div>
          <DialogDescription className="pt-2">
            Er du sikker på du slette{' '}
            <span className="font-medium text-foreground">{itemName}</span>? <br />
            Dette kan ikke gøres om.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="mt-4 flex gap-2 sm:justify-end">
          <Button variant="outline" onClick={onClose} disabled={isDeleting}>
            Fortryd
          </Button>
          <Button variant="destructive" onClick={onConfirm} disabled={isDeleting}>
            {isDeleting ? 'Sletter...' : 'Slet'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function RenderFiles({
  size,
  attachments,
  removeFile,
  disabled,
}: {
  size?: 'sm';
  attachments: Attachment[];
  removeFile?: (index: number) => void;
  disabled?: boolean;
}) {
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment | null>(null);

  const isViewable = (type: string) => {
    return type.startsWith('image/') || type.startsWith('video/');
  };

  const handleClose = () => {
    setSelectedAttachment(null);
  };

  const handleSelect = (attachment: Attachment) => {
    if (isViewable(attachment.type)) {
      setSelectedAttachment(attachment);
    } else {
      window.open(attachment.download, '_blank');
    }
  };
  console.log({ size });

  return (
    <>
      {attachments.map((fileObj, index) => {
        const File = getFileIcon(fileObj.type);
        const colorClass = getFileIconColor(fileObj.type);
        const { sizeCls, sizeIconCls } = getSizes(size);
        return (
          <div
            key={index}
            className={`flex relative cursor-pointer items-center justify-center ${sizeCls} bg-slate-50 border border-gray-100 rounded-lg`}
            onClick={() => handleSelect(fileObj)}
          >
            {File?.icon && (
              <>
                <File.icon className={`${sizeIconCls} mb-2 ${colorClass}`} />
                <span
                  className={`absolute bottom-0.5 text-xs ${colorClass}`}
                  style={{ fontSize: 8 }}
                >
                  {File.text}
                </span>
              </>
            )}
            {fileObj.type?.startsWith('image/') && (
              <img
                // @ts-ignore
                src={fileObj?.download}
                alt="Preview"
                className="w-full h-auto rounded-lg object-cover aspect-square"
              />
            )}
            {!disabled && removeFile && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeFile(index);
                }}
                className="absolute top-0 right-0 p-0.5 bg-white rounded-full shadow hover:bg-gray-100"
              >
                <X className="h-3 w-3 text-gray-600" />
              </button>
            )}
          </div>
        );
      })}
      {selectedAttachment && <Lightbox attachment={selectedAttachment} onClose={handleClose} />}
    </>
  );
}

function getSizes(size?: 'sm') {
  if (size === 'sm') {
    return {
      sizeCls: 'w-[36px] h-[36px]',
      sizeIconCls: 'h-3 w-3',
    };
  }
  return {
    sizeCls: 'w-[52px] h-[52px]',
    sizeIconCls: 'h-7 w-7',
  };
}
