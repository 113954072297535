import { call, put, takeLatest } from 'redux-saga/effects';
import { reduxSagaFirebase } from '../../firebase';
import { fetchFeaturesSuccess } from './actions';
import {
  FEATURES_FETCH_FAILED,
  FEATURES_FETCH_REQUESTED,
  FEATURES_UPDATE_FAILED,
  FEATURES_UPDATE_REQUESTED,
} from './types';
// FETCH AUDIT
function* fetchFeatures({ payload }: any) {
  const { orgId } = payload;
  try {
    yield call(
      // @ts-ignore
      reduxSagaFirebase.firestore.syncCollection,
      `orgs/${orgId}/features`,
      { successActionCreator: fetchFeaturesSuccess }
    );
  } catch (e: any) {
    yield put({ type: FEATURES_FETCH_FAILED, message: e.message });
  }
}

export function* fetchFeaturesSaga() {
  yield takeLatest(FEATURES_FETCH_REQUESTED, fetchFeatures);
}

function* updateFeature({ payload }: any) {
  const { orgId, featureId, data } = payload;
  try {
    yield call(
      reduxSagaFirebase.firestore.setDocument,
      `orgs/${orgId}/features/${featureId}`,
      data,
      { merge: true }
    );
  } catch (e: any) {
    yield put({ type: FEATURES_UPDATE_FAILED, message: e.message });
  }
}

export function* updateFeatureSaga() {
  yield takeLatest(FEATURES_UPDATE_REQUESTED, updateFeature);
}
