import { Button, Form, Input, Modal, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useOfferContext } from '../Agreements/Context/OfferContext';
import { useTranslation } from 'react-i18next';
import Message, { Type } from 'components/Notification/Message';
import firebase from 'firebase/compat/app';
import { StyleGuide } from 'styles/StyleGuide';
import { useAppSelector } from 'hooks';
import { OfferStatus } from '../Agreements/Context/offerReducer';

import { AppOffer } from 'hooks/useAppOffers';
import { updateOffer } from 'Views/Agreements/Offers/OffersTable';

const FormItem = Form.Item;

const formItemLayout = {
  wrapperCol: { span: 24 },
};

export default function ModalOffer({ visible, onClose, form, onFinish, error }: any) {
  const { t } = useTranslation();
  return (
    <Modal
      title="Gem tilbud"
      open={visible}
      // onOk={e => handleOk(e, email)}
      confirmLoading={false}
      onCancel={onClose}
      footer={[]}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <FormItem
          {...formItemLayout}
          name="title"
          label={
            <div style={{ display: 'flex' }}>
              <div>Hvem er tilbuddet til?</div>
              <div
                style={{
                  textAlign: 'right',
                  color: StyleGuide.palette.grey600,
                  marginLeft: 8,
                }}
              >
                {'- '}
                Eksempel: DSV - Kolding - Bygning 1
              </div>
            </div>
          }
          rules={[{ required: true, message: 'Venligst skriv firma navn' }]}
        >
          <Input type="text" placeholder={t('[Firma] - [by] - [bygning]')} />
        </FormItem>

        <FormItem
          {...formItemLayout}
          name="contactAtt"
          label={
            <div style={{ display: 'flex' }}>
              <div>Kontaktperson for tilbud</div>
              <div
                style={{
                  textAlign: 'right',
                  color: StyleGuide.palette.grey600,
                  marginLeft: 8,
                }}
              >
                {'- '}
                Eksempel: Jens Jensen - Lagerchef
              </div>
            </div>
          }
          rules={[
            {
              required: true,
              message: 'Venligst skriv kontaktpersons navn',
            },
          ]}
        >
          <Input type="text" placeholder={t('[Navn] - [Ansvarsområde]')} />
        </FormItem>

        <FormItem>
          <Button type="primary" block htmlType="submit" loading={false}>
            {'Tilføj'}
          </Button>
        </FormItem>
      </Form>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </Modal>
  );
}
