import { firestore } from '../firebase';
import { useEffect, useState } from 'react';
import { Org } from '../../functions/src/api/orgs';

export default function useOrgsBySubGroup(subGroupId: string | null) {
  const [orgs, setOrgs] = useState<any[]>([]);
  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    let unsubscribe: any = null;
    if (!subGroupId) return;
    unsubscribe = firestore
      .collection(`orgs`)
      .where('subGroup.id', '==', subGroupId)
      .limit(100)
      .onSnapshot((query) => {
        if (!query.empty) {
          const list: any[] = query.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              } as Org)
          );

          setOrgs(list);
        } else {
          setOrgs([]);
        }
        setisLoading(false);
      });
    return unsubscribe;
  }, [subGroupId]);

  return { orgs, isLoading };
}
