export enum RecurringOptions {
  NEVER = 'NEVER',
  // Daily
  DAILY = 'DAILY',
  // Every day of the week
  WEEK_DAILY = 'WEEK_DAILY',
  WEEKLY = 'WEEKLY',
  // Every second week
  BI_WEEKLY = 'BI_WEEKLY',
  // Every third week
  TRI_WEEKLY = 'TRI_WEEKLY',
  // Every month
  MONTHLY = 'MONTHLY',
  PRIMO_MONTH = 'PRIMO_MONTH',
  ULTIMO_MONTH = 'ULTIMO_MONTH',
  DATE_MONTHLY = 'DATE_MONTHLY',
  // Every second month
  BI_MONTHLY = 'BI_MONTHLY',
  DATE_BI_MONTHLY = 'DATE_BI_MONTHLY',
  PRIMO_BI_MONTH = 'PRIMO_BI_MONTH',
  ULTIMO_BI_MONTH = 'ULTIMO_BI_MONTH',
  // Every third month
  QUARTERLY = 'QUARTERLY',
  DATE_QUARTERLY = 'DATE_QUARTERLY',
  PRIMO_QUARTERLY = 'PRIMO_QUARTERLY',
  ULTIMO_QUARTERLY = 'ULTIMO_QUARTERLY',
  // Every fourth month
  EVERY_FOURTH_MONTHLY = 'EVERY_FOURTH_MONTHLY',
  DATE_EVERY_FOURTH_MONTHLY = 'DATE_EVERY_FOURTH_MONTHLY',
  PRIMO_EVERY_FOURTH_MONTH = 'PRIMO_EVERY_FOURTH_MONTH',
  ULTIMO_EVERY_FOURTH_MONTH = 'ULTIMO_EVERY_FOURTH_MONTH',
  // semi-yearly
  SEMI_YEARLY = 'SEMI_YEARLY',
  // yearly
  YEARLY = 'YEARLY',
  // bi-yearly
  BI_YEARLY = 'BI_YEARLY',
  CUSTOM = 'CUSTOM',
}
export enum QUICK_TASK {
  OPTION = 'quickTask',
}

export enum ActivityType {
  ACTIVITY_STORAGE_UPDATE = 'ACTIVITY_STORAGE_UPDATE',
  ACTIVITY_FOOBOT_LOG = 'ACTIVITY_FOOBOT_LOG',
  ACTIVITY_FOOBOT_LOG_ERROR = 'ACTIVITY_FOOBOT_LOG_ERROR',
  ACTIVITY_ISSUE_CREATE = 'ACTIVITY_ISSUE_CREATE',
  ACTIVITY_ISSUE_COMPLETED = 'ACTIVITY_ISSUE_COMPLETED',
  ACTIVITY_ISSUE_ASSIGNEE_CHANGED = 'ACTIVITY_ISSUE_ASSIGNEE_CHANGED',
  ACTIVITY_ISSUE_ASSIGNEE_INIT = 'ACTIVITY_ISSUE_ASSIGNEE_INIT',
  ACTIVITY_AUDIT_COMPLETED = 'ACTIVITY_AUDIT_COMPLETED',
  AUDIT_DAYS_AFTER_DEADLINE_ALERT = 'AUDIT_DAYS_AFTER_DEADLINE_ALERT',
  ACTIVITY_NO_GPS_LOGS = 'ACTIVITY_NO_GPS_LOGS',
  ACTIVITY_INDUSTRY_SMS_ALERT = 'ACTIVITY_INDUSTRY_SMS_ALERT',
  ACTIVITY_ISSUE_DOCUMENTATION_ADDED = 'ACTIVITY_ISSUE_DOCUMENTATION_ADDED',
  ACTIVITY_ISSUE_DOCUMENTATION_DELETED = 'ACTIVITY_ISSUE_DOCUMENTATION_DELETED',
  ACTIVITY_ISSUE_RESPONSIBLE_CHANGED = 'ACTIVITY_ISSUE_RESPONSIBLE_CHANGED',
  ACTIVITY_ISSUE_NOTE_ADDED = 'ACTIVITY_ISSUE_NOTE_ADDED',
  ACTIVITY_ISSUE_NOTE_CHANGED = 'ACTIVITY_ISSUE_NOTE_CHANGED',
  ACTIVITY_ISSUE_NOTE_DELETED = 'ACTIVITY_ISSUE_NOTE_DELETED',
  ACTIVITY_ISSUE_TAGS_CHANGED = 'ACTIVITY_ISSUE_TAGS_CHANGED',
  ACTIVITY_ISSUE_DESCRIPTION_CHANGED = 'ACTIVITY_ISSUE_DESCRIPTION_CHANGED',
}

export enum NotificationType {
  LOW_STORAGE = 'LOW_STORAGE',
  FOOBOT_LOG_ERROR = 'FOOBOT_LOG_ERROR',
  FOOBOT_LOG_HIGH = 'FOOBOT_LOG_HIGH',
  CLEAN_LOG_DAY_ALERT = 'CLEAN_LOG_DAY_ALERT',
  NEW_ISSUE = 'NEW_ISSUE',
  ISSUE_COMPLETED = 'ISSUE_COMPLETED',
  ISSUE_ASSIGNEE_CHANGED = 'ISSUE_ASSIGNEE_CHANGED',
  MISSING_GPS_LOGS = 'MISSING_GPS_LOGS',
  AUDIT_START_TODAY = 'AUDIT_START_TODAY',
  AUDIT_DEADLINE_TODAY = 'AUDIT_DEADLINE_TODAY',
  AUDIT_COMPLETED_WITH_ISSUES = 'AUDIT_COMPLETED_WITH_ISSUES',
  AUDIT_DEADLINE_ALERT = 'AUDIT_DEADLINE_ALERT',
  AUDIT_DAYS_AFTER_DEADLINE_ALERT = 'AUDIT_DAYS_AFTER_DEADLINE_ALERT',
  GSM_PLANT_ERRORS = 'GSM_PLANT_ERRORS',
  SMS_INDUSTRY_ALERT = 'SMS_INDUSTRY_ALERT',
  PLANT_INDUSTRY_ALERT = 'PLANT_INDUSTRY_ALERT',
  INTERNAL_AUDIT_DEVIATIONS = 'INTERNAL_AUDIT_DEVIATIONS',
}

export enum Intl {
  da_DK = 'da_DK',
  en_US = 'en_US',
  de_DE = 'de_DE',
  se_SE = 'se_SE',
}

export enum SensorType {
  FOOBOT = 'foobot',
  ESP_AQ_NEW = 'ESP_AQ_NEW',
  ESP_AQ = 'ESP_AQ',
  GSM_PLANT = 'GSM_PLANT',
  DUST_OLD = 'dust_DSM501A',
  GPS_GY_NEO = 'geolocation_GY-NEO6MV2',
  GPS_SIMTRACKING = 'GPS_SIMTRACKING',
  GSM_CUSTOM = 'GSM_CUSTOM',
  LEVEL_SENSOR_INTERNAL = 'LEVEL_SENSOR_INTERNAL',
}

// Used as device type, sensors are attached to
export enum DeviceType {
  MOBILE_PLANT = 'MOBILE_PLANT',
  LEVEL_SENSOR_INTERNAL = 'LEVEL_SENSOR_INTERNAL',
}

export enum Region {
  EU_WEST_3 = 'europe-west3',
}

export enum ContentType {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  VIDEO_MP4 = 'video/mp4',
  VIDEO_QUICKTIME = 'video/quicktime',
  VIDEO_AVI = 'video/avi',
  VIDEO_MPEG = 'video/mpeg',
  VIDEO_MKV = 'application/octet-stream',
  VIDEO_FLV = 'video/x-flv',
  VIDEO_3GPP = 'video/3gpp',
  PDF = 'application/pdf',
  CSV = 'text/csv',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PPT = 'application/vnd.ms-powerpoint',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
}
