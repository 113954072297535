export const FILES_FETCH_SUCCEEDED = 'FILES_FETCH_SUCCEEDED';
export const FILES_FETCH_FAILED = 'FILES_FETCH_FAILED';
export const FILES_FETCH_REQUESTED = 'FILES_FETCH_REQUESTED';

export const FILE_DELETE_SUCCEEDED = 'FILE_DELETE_SUCCEEDED';
export const FILE_DELETE_FAILED = 'FILE_DELETE_FAILED';
export const FILE_DELETE_REQUESTED = 'FILE_DELETE_REQUESTED';

export const AUDIT_FILES_FETCH_SUCCEEDED = 'AUDIT_FILES_FETCH_SUCCEEDED';
export const AUDIT_FILES_FETCH_FAILED = 'AUDIT_FILES_FETCH_FAILED';
export const AUDIT_FILES_FETCH_REQUESTED = 'AUDIT_FILES_FETCH_REQUESTED';

export const AUDIT_FILE_DELETE_SUCCEEDED = 'AUDIT_FILE_DELETE_SUCCEEDED';
export const AUDIT_FILE_DELETE_FAILED = 'AUDIT_FILE_DELETE_FAILED';
export const AUDIT_FILE_DELETE_REQUESTED = 'AUDIT_FILE_DELETE_REQUESTED';

export interface File {
  contentType: string;
  path: string;
  thumbnail: string;
  id: string;
}

export interface FilesState {
  files: File[];
  loading: boolean;
}

interface FetchFilesLoading {
  type:
    | typeof FILES_FETCH_REQUESTED
    | typeof AUDIT_FILES_FETCH_REQUESTED
    | typeof FILES_FETCH_FAILED
    | typeof AUDIT_FILES_FETCH_FAILED;
  payload: {};
}
interface FetchFilesSuccess {
  type: typeof FILES_FETCH_SUCCEEDED;
  payload: { data: any[] };
}
interface FetchAuditFilesSuccess {
  type: typeof AUDIT_FILES_FETCH_SUCCEEDED;
  payload: { data: any[] };
}

export type FilesActionTypes = FetchFilesLoading | FetchFilesSuccess | FetchAuditFilesSuccess;
