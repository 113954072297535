import type { InventoryItem } from '../types';
import type { ActivityLog } from '../types';
import type { Task } from '../types';

// Mock data - in a real app, this would come from a database
export const inventoryItems: InventoryItem[] = [
  {
    id: 'EQ-HELM-001',
    serialNumber: 'SH2023-001',
    name: 'Sikkerhedshjelm',
    category: 'Sikkerhedsudstyr',
    status: 'Available',
    description: 'Standard sikkerhedshjelm til byggepladser',
    dateAdded: '2023-10-15',
    lastMaintenance: '2024-01-20',
    nextMaintenance: null,
    location: 'RGS Gadstrup',
    manufacturer: 'SafetyFirst',
    purchaseDate: '2023-05-10',
    image:
      'https://res.cloudinary.com/evoleska/images/e_trim:0:white/w_275,h_275,q_auto,c_lpad,ar_1,f_auto,b_white/v1553248531/product/1703917/kask-plasma-aq-sikkerhedshjelm.jpg', //'/placeholder.svg?height=100&width=100',
    checkedOutTo: null,
    checkOutDate: null,
    expectedReturnDate: null,
  },
  {
    id: 'EQ-DRILL-001',
    serialNumber: 'DR2023-001',
    name: 'Boremaskine',
    category: 'Elværktøj',
    status: 'Available',
    description: 'Cordless power drill with battery pack',
    dateAdded: '2023-09-05',
    lastMaintenance: '2024-02-10',
    nextMaintenance: null,
    location: 'Tool Shed',
    manufacturer: 'DeWalt',
    purchaseDate: '2023-04-15',
    image: '/placeholder.svg?height=100&width=100',
    checkedOutTo: null,
    checkOutDate: null,
    expectedReturnDate: null,
  },
  {
    id: 'EQ-LADD-001',
    serialNumber: 'LD2023-001',
    name: 'Skydestige',
    category: 'Stiger',
    status: 'In Use',
    description: '28-foot extension ladder',
    dateAdded: '2023-11-20',
    lastMaintenance: '2024-01-15',
    nextMaintenance: null,
    location: 'Site B',
    manufacturer: 'Werner',
    purchaseDate: '2023-06-22',
    image: '/placeholder.svg?height=100&width=100',
    checkedOutTo: 'John Doe',
    checkOutDate: '2024-03-01',
    expectedReturnDate: '2024-03-15',
  },
  {
    id: 'EQ-HAMM-001',
    serialNumber: 'HM2023-001',
    name: 'Hammer',
    category: 'Håndværktøj',
    status: 'Available',
    description: 'Standard claw hammer',
    dateAdded: '2023-08-12',
    lastMaintenance: null,
    nextMaintenance: null,
    location: 'RGS Gadstrup',
    manufacturer: 'Stanley',
    purchaseDate: '2023-03-05',
    image: '/placeholder.svg?height=100&width=100',
    checkedOutTo: null,
    checkOutDate: null,
    expectedReturnDate: null,
  },
  {
    id: 'EQ-SAW-001',
    serialNumber: 'SW2023-001',
    name: 'Rundsav',
    category: 'Elværktøj',
    status: 'Maintenance',
    description: '7-1/4 inch circular saw',
    dateAdded: '2023-07-30',
    lastMaintenance: '2024-02-15',
    nextMaintenance: '2024-05-15',
    location: 'Repair Shop',
    manufacturer: 'Makita',
    purchaseDate: '2023-02-18',
    image: '/placeholder.svg?height=100&width=100',
    checkedOutTo: null,
    checkOutDate: null,
    expectedReturnDate: null,
  },
];

// Mock activity logs
const activityLogs: Record<string, ActivityLog[]> = {
  'EQ-HELM-001': [
    {
      id: 'act-001',
      date: '2024-02-15T10:30:00Z',
      type: 'maintenance',
      description: 'Regular inspection and cleaning',
      user: 'Jane Smith',
    },
    {
      id: 'act-002',
      date: '2024-01-20T14:15:00Z',
      type: 'checkout',
      description: 'Checked out for Site A project',
      user: 'John Doe',
    },
    {
      id: 'act-003',
      date: '2024-01-25T16:45:00Z',
      type: 'checkin',
      description: 'Returned from Site A project',
      user: 'John Doe',
    },
  ],
  'EQ-DRILL-001': [
    {
      id: 'act-004',
      date: '2024-02-10T09:00:00Z',
      type: 'maintenance',
      description: 'Battery replacement and calibration',
      user: 'Mike Johnson',
    },
    {
      id: 'act-005',
      date: '2024-01-05T11:30:00Z',
      type: 'checkout',
      description: 'Checked out for home renovation project',
      user: 'Sarah Williams',
    },
    {
      id: 'act-006',
      date: '2024-01-12T13:20:00Z',
      type: 'checkin',
      description: 'Returned from home renovation project',
      user: 'Sarah Williams',
    },
  ],
  'EQ-LADD-001': [
    {
      id: 'act-007',
      date: '2024-01-15T08:45:00Z',
      type: 'maintenance',
      description: 'Safety inspection and lubrication',
      user: 'Mike Johnson',
    },
    {
      id: 'act-008',
      date: '2024-03-01T10:00:00Z',
      type: 'checkout',
      description: 'Checked out for Site B project',
      user: 'John Doe',
    },
  ],
};

// Mock tasks
const tasks: Record<string, Task[]> = {
  'EQ-HELM-001': [
    {
      id: 'task-001',
      title: 'Månedlig inspektion',
      description: 'Tjek for skader og slid',
      status: 'completed',
      dueDate: '2024-02-15',
      completedDate: '2024-02-15',
      assignedTo: 'Jane Smith',
    },
    {
      id: 'task-002',
      title: 'Udskift rem',
      description: 'Gammel rem er slidt',
      status: 'completed',
      dueDate: '2024-01-10',
      completedDate: '2024-01-12',
      assignedTo: 'Mike Johnson',
    },
    {
      id: 'task-003',
      title: 'Rengør hjelm',
      description: 'Fjern snavs og støv',
      status: 'pending',
      dueDate: '2024-04-01',
      completedDate: null,
      assignedTo: 'Jane Smith',
    },
  ],
  'EQ-DRILL-001': [
    {
      id: 'task-004',
      title: 'Erstat batteri',
      description: 'Battery not holding charge',
      status: 'completed',
      dueDate: '2024-02-10',
      completedDate: '2024-02-10',
      assignedTo: 'Mike Johnson',
    },
    {
      id: 'task-005',
      title: 'Calibrate torque settings',
      description: 'Ensure accurate torque for precision work',
      status: 'completed',
      dueDate: '2024-02-10',
      completedDate: '2024-02-10',
      assignedTo: 'Mike Johnson',
    },
  ],
  'EQ-LADD-001': [
    {
      id: 'task-006',
      title: 'Inspect ladder rungs',
      description: 'Check for damage and stability',
      status: 'completed',
      dueDate: '2024-01-15',
      completedDate: '2024-01-15',
      assignedTo: 'Mike Johnson',
    },
    {
      id: 'task-007',
      title: 'Replace rubber feet',
      description: 'Current feet showing signs of wear',
      status: 'pending',
      dueDate: '2024-04-15',
      completedDate: null,
      assignedTo: 'Jane Smith',
    },
  ],
};

// Function to get equipment by ID
export async function getEquipmentById(id: string): Promise<InventoryItem | null> {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 500));

  return inventoryItems.find((item) => item.id === id) || null;
}

// Function to get activity logs for an equipment
export async function getActivityLogs(equipmentId: string): Promise<ActivityLog[]> {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 300));

  return activityLogs[equipmentId] || [];
}

// Function to get tasks for an equipment
export async function getTasks(equipmentId: string): Promise<Task[]> {
  // Simulate API delay
  await new Promise((resolve) => setTimeout(resolve, 300));

  return tasks[equipmentId] || [];
}
