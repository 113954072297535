'use client';

import * as React from 'react';
import { Check } from 'lucide-react';

import { cn } from 'lib/utils';
import { Button } from 'components/ui/button';
import { Badge } from 'components/ui/badge';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from 'components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { getStatusColor } from '../InternalAuditOverview/components/AuditMatrix';
import { getDisplayStatus } from './audit-summary';

const statuses = [
  {
    value: 'passed',
  },
  {
    value: 'observation',
  },
  {
    value: 'deviation',
  },
  {
    value: 'not-audited',
  },
];

export function StatusPicker({
  status = 'approved',
  onChange,
  disabled,
}: {
  status: string;
  onChange: (status: string) => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  // const [selectedStatus, setSelectedStatus] = React.useState('info');

  // React.useEffect(() => {
  //   setSelectedStatus(status);
  // }, [status]);

  const currentStatus = statuses.find((s) => s.value === status) || statuses[0];
  if (!currentStatus) {
    throw new Error('Invalid status');
  }
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn('justify-between ', getStatusColor(currentStatus.value))}
        >
          <div className="flex items-center gap-2">
            {/* <Badge className={cn('mr-2', selectedStatus.color)}> */}
            {getDisplayStatus(currentStatus.value)}
            {/* </Badge> */}
            {/* {selectedStatus.label} */}
          </div>
          <Check
            className={cn(
              'ml-2 h-4 w-4 shrink-0 opacity-50'
              //   open && 'rotate-180 transition-transform'
            )}
          />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command>
          <CommandInput placeholder="Search status..." />
          <CommandList>
            <CommandEmpty>No status found.</CommandEmpty>
            <CommandGroup>
              {statuses.map((s) => (
                <CommandItem
                  key={s.value}
                  onSelect={() => {
                    onChange(s.value);
                    setOpen(false);
                  }}
                >
                  <Badge className={cn(' justify-center', getStatusColor(s.value))}>
                    {getDisplayStatus(s.value)}
                  </Badge>
                  {/* {status.label} */}
                  <Check
                    className={cn(
                      'ml-auto h-4 w-4',
                      currentStatus.value === s.value ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
