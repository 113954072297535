import { OrderedListOutlined } from '@ant-design/icons';
import { Badge, Tooltip } from 'antd';
import useTemplateCount from 'hooks/useTemplateTaskCount';
import { useTranslation } from 'react-i18next';
import { StyleGuide } from 'styles/StyleGuide';

export default function TemplateTaskCount({
  orgId,
  templateId,
}: {
  orgId: string;
  templateId: string;
}) {
  const { t } = useTranslation();
  const count = useTemplateCount(orgId, templateId);
  if (!count) return null;
  return (
    <>
      <Tooltip title={t('audit.templates.item.tooltipTaskCount', { count })}>
        <Badge count={count} color={StyleGuide.palette.grey800} />
      </Tooltip>
      <div style={{ marginRight: 12 }} />
    </>
  );
}
