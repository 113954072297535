import { ContentType } from './enums';

export function getExtentionByMimeType(mimeType: ContentType) {
  try {
    switch (mimeType) {
      case ContentType.IMAGE_JPEG:
        return 'jpeg';
      case ContentType.IMAGE_PNG:
        return 'png';
      case ContentType.IMAGE_JPG:
        return 'jpg';

      case ContentType.PDF:
        return 'pdf';

      case ContentType.DOC:
        return 'doc';
      case ContentType.DOCX:
        return 'docx';

      case ContentType.PPT:
        return 'ppt';
      case ContentType.PPTX:
        return 'pptx';
      case ContentType.XLS:
        return 'xls';
      case ContentType.XLSX:
        return 'xlsx';
      case ContentType.CSV:
        return 'csv';

      case ContentType.VIDEO_MP4:
        return 'mp4';
      case ContentType.VIDEO_QUICKTIME:
        return 'mov';
      case ContentType.VIDEO_AVI:
        return 'avi';
      case ContentType.VIDEO_MKV:
        return 'mkv';
      case ContentType.VIDEO_MPEG:
        return 'mpeg';
      case ContentType.VIDEO_3GPP:
        return '3gp';
      case ContentType.VIDEO_FLV:
        return 'flv';

      default:
        throw new Error(`${mimeType} - Unknown file type`);
    }
  } catch (error) {
    console.error(error);
    return 'unknown';
  }
}
