import { useState } from 'react';
import { Modal, Spin } from 'antd';
import OrderModalList from './OrderModalList';

export default function OrderModal({
  orgId,
  title,
  handleClose,
}: {
  orgId: string | null;
  title: string | null;
  handleClose: () => void;
}) {
  // const [confirmLoading, setConfirmLoading] = useState(false);

  // const handleOk = () => {
  //     setConfirmLoading(true);
  //     setTimeout(() => {
  //         handleClose();
  //         setConfirmLoading(false);
  //     }, 2000);
  // };

  const handleCancel = () => {
    console.log('Clicked cancel button');
    handleClose();
  };
  return (
    <Modal
      title={`Bestillinger til ${title}`}
      open={Boolean(orgId)}
      footer={[]}
      // onOk={handleOk}
      // confirmLoading={confirmLoading}
      onCancel={handleCancel}
      width="100%"
    >
      <div>{orgId ? <OrderModalList orgId={orgId} /> : 'Something went wrong'}</div>
    </Modal>
  );
}
