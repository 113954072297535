import { Button, Card, Col, Divider, InputNumber, Radio, Row } from 'antd';
import { getHighlight } from './Calculator';
import {
  getAdditionalServicesSubscriptionFixedPrice,
  getAdditionalServicesSubscriptionVariablePrice,
  getDiscountFactor,
  getSubscriptionPrice,
  useOfferContext,
} from '../Agreements/Context/OfferContext';
import { RadioChangeEvent } from 'antd/lib';
import { year1Discount, year2Discount, year3Discount } from '../Agreements/Context/offerConfigs';
import { PriceType } from '../Agreements/Context/offerReducer';
import { getCurrencyFormat } from './Offer';
import { ArrowDownOutlined, CaretDownOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

const optionsWithDisabled = [
  { label: 'Årlig', value: 1 },
  { label: 'Månedlig', value: 2 },
];

export default function SubscriptionCard({
  setRowHover,
  rowHover,
}: {
  setRowHover: (row: number | null) => void;
  rowHover: number | null;
}) {
  const { state, dispatch } = useOfferContext();
  const { monthlySubscriptionPrice, yearlySubscriptionPrice } = getSubscriptionPrice(state.m2);

  const { yearly, monthly } = getAdditionalServicesSubscriptionFixedPrice(state);
  const { yearlyData, monthlyData } = getAdditionalServicesSubscriptionVariablePrice(state);

  const onChangeView = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio4 checked', value);
    dispatch({
      type: 'TOGGLE_VIEW',
    });
  };

  const isSubType = PriceType.SUBSCRIPTION === state.priceType;
  const isMonthly = state.isMonthlyView;
  const year1 = isMonthly
    ? monthlySubscriptionPrice * year1Discount + monthly + monthlyData
    : yearlySubscriptionPrice * year1Discount + yearly + yearlyData;

  const year2 = isMonthly
    ? monthlySubscriptionPrice * year2Discount + monthly / (2 * 12) + monthlyData
    : yearlySubscriptionPrice * year2Discount + yearly / 2 + yearlyData;

  const year3 = isMonthly
    ? monthlySubscriptionPrice * year3Discount + monthly / (3 * 12) + monthlyData
    : yearlySubscriptionPrice * year3Discount + yearly / 3 + yearlyData;

  const discount = state.discount;
  const finalPrice = state.finalPrice;

  const addedDiscount = getDiscountFactor(discount || 0);

  const airCleaner = {
    leasing: Number(state.airCleaner?.leasing),
    power: Number(state.airCleaner?.power),
    amount: Number(state.airCleaner?.amount),
    additionalProducts: Number(state.airCleaner?.additionalProducts),
  };

  return (
    <Card
      title={`Abonnement priser - pr. ${isMonthly ? 'md' : 'år'}`}
      extra={
        <>
          <Radio.Group
            options={optionsWithDisabled}
            onChange={onChangeView}
            value={state.isMonthlyView ? 2 : 1}
            optionType="button"
            buttonStyle="solid"
          />

          <Button
            style={{ marginLeft: 8 }}
            // icon={isLiterType ? <UpOutlined /> : <DownOutlined />}
            type={isSubType ? 'primary' : 'default'}
            onClick={() => {
              dispatch({
                type: 'SET_PRICE_TYPE',
                payload: PriceType.SUBSCRIPTION,
              });
            }}
          >
            {isSubType ? 'VALGT' : 'Vælg tilbuds type'}
          </Button>
        </>
      }
    >
      <Row
        gutter={24}
        style={isSubType || state.priceType === null ? { display: 'block' } : { display: 'none' }}
      >
        <Col span={24}>
          <table className="price-table">
            <thead>
              <th></th>
              <th style={{ textAlign: 'right' }}>År 1</th>
              <th style={{ textAlign: 'right' }}>År 2</th>
              <th style={{ textAlign: 'right' }}>År 3</th>
            </thead>

            <tr
              key={PriceType.SUBSCRIPTION}
              onMouseEnter={() => setRowHover(PriceType.SUBSCRIPTION)}
              onMouseLeave={() => setRowHover(null)}
              style={getHighlight(state.priceType, rowHover, PriceType.SUBSCRIPTION)}
            >
              <td style={{ width: '100%' }}>
                Pris: <br />
                (3-5 gang pr. uge - 60-80% reduktion)
                <br /> (2 gang pr. uge - 50-60% reduktion)
                <br /> (1 gang pr. uge - 40-50% reduktion)
              </td>
              <td style={{ paddingLeft: 24, textAlign: 'right' }}>{getCurrencyFormat(year1)}</td>
              <td style={{ paddingLeft: 24, textAlign: 'right' }}>{getCurrencyFormat(year2)}</td>
              <td style={{ paddingLeft: 24, textAlign: 'right' }}>{getCurrencyFormat(year3)}</td>
              <td style={{ paddingLeft: 24, textAlign: 'right' }}>
                <Button
                  onClick={() => {
                    dispatch({
                      type: 'SET_PRICE_TYPE',
                      payload: PriceType.SUBSCRIPTION,
                    });
                  }}
                >
                  Vælg
                </Button>
              </td>
            </tr>
            {/* <tr
                            key={3}
                            onMouseEnter={() => setRowHover(3)}
                            onMouseLeave={() => setRowHover(null)}
                            style={getHighlight(selectedPrice, rowHover, 3)}>
                            <td style={{ width: "100%" }}>
                                Pris (2 gang pr. uge - 50-60% reduktion):
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                {new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK",
                                    maximumFractionDigits: 0,
                                }).format(
                                    (prYear / 12) *
                                        (isMonthly ? 1 : 12) *
                                        mediumFrequencyMultuplier,
                                )}
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                {new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK",
                                    maximumFractionDigits: 0,
                                }).format(
                                    (prYear2 / 24) *
                                        (isMonthly ? 1 : 12) *
                                        mediumFrequencyMultuplier,
                                )}
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                {new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK",
                                    maximumFractionDigits: 0,
                                }).format(
                                    (prYear3 / 36) *
                                        (isMonthly ? 1 : 12) *
                                        mediumFrequencyMultuplier,
                                )}
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                <Button
                                    onClick={() => {
                                        setSelectedPrice(3);
                                    }}>
                                    Vælg
                                </Button>
                            </td>
                        </tr>
                        <tr
                            key={4}
                            onMouseEnter={() => setRowHover(4)}
                            onMouseLeave={() => setRowHover(null)}
                            style={getHighlight(selectedPrice, rowHover, 4)}>
                            <td style={{ width: "100%" }}>
                                Pris (1 gang pr. uge - 40-50% reduktion):
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                {new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK",
                                    maximumFractionDigits: 0,
                                }).format(
                                    (prYear / 12) *
                                        (isMonthly ? 1 : 12) *
                                        smallFrequencyMultuplier,
                                )}
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                {new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK",
                                    maximumFractionDigits: 0,
                                }).format(
                                    (prYear2 / 24) *
                                        (isMonthly ? 1 : 12) *
                                        smallFrequencyMultuplier,
                                )}
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                {new Intl.NumberFormat("da-DK", {
                                    style: "currency",
                                    currency: "DKK",
                                    maximumFractionDigits: 0,
                                }).format(
                                    (prYear3 / 36) *
                                        (isMonthly ? 1 : 12) *
                                        smallFrequencyMultuplier,
                                )}
                            </td>
                            <td style={{ paddingLeft: 24, textAlign: "right" }}>
                                <Button
                                    onClick={() => {
                                        setSelectedPrice(4);
                                    }}>
                                    Vælg
                                </Button>
                            </td>
                        </tr> */}
            {state.priceType && (
              <>
                <tr>
                  <td colSpan={8}>
                    <Divider style={{ margin: '8px 0' }} />
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>Støvbinder rabat til kunder over 50.000m2</td>
                  <td colSpan={2}>
                    <InputNumber<number>
                      value={discount}
                      min={0}
                      max={4}
                      formatter={(value) => `${value}%`}
                      parser={(value) => value?.replace('%', '') as unknown as number}
                      onChange={(newDiscount) => {
                        dispatch({
                          type: 'SET_DISCOUNT',
                          payload: newDiscount,
                        });
                      }}
                    />
                  </td>
                </tr>

                {discount !== null ? (
                  <>
                    <tr>
                      <td colSpan={8}>
                        <Divider style={{ margin: '8px 0' }} />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1}>Pris med {discount}% rabat:</td>
                      <td
                        colSpan={1}
                        style={{
                          textAlign: 'right',
                          fontWeight: 700,
                          textDecoration: 'underline',
                        }}
                      >
                        {getCurrencyFormat(year1 * addedDiscount)}
                      </td>
                      <td
                        colSpan={1}
                        style={{
                          textAlign: 'right',
                          fontWeight: 700,
                          textDecoration: 'underline',
                        }}
                      >
                        {getCurrencyFormat(year2 * addedDiscount)}
                      </td>
                      <td
                        colSpan={1}
                        style={{
                          textAlign: 'right',
                          fontWeight: 700,
                          textDecoration: 'underline',
                        }}
                      >
                        {getCurrencyFormat(year3 * addedDiscount)}
                      </td>
                    </tr>
                  </>
                ) : null}
              </>
            )}
          </table>
          {state?.airCleaner && (
            <>
              <Divider style={{ margin: '16px 0' }} />
              <h4>Luftrenser omkostninger</h4>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>Luftrenser leasing:</span>
                <span>
                  {getCurrencyFormat(airCleaner.leasing * airCleaner.amount * (isMonthly ? 1 : 12))}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>El </span>
                <span>
                  {getCurrencyFormat(airCleaner.power * airCleaner.amount * (isMonthly ? 1 : 12))}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>Vaskeprodukter der bruges i dag: </span>
                <span>
                  {getCurrencyFormat(airCleaner.additionalProducts * (isMonthly ? 1 : 12))}
                </span>
              </div>
              <Divider style={{ margin: '16px 0' }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>Udgifter i alt pr {isMonthly ? 'md' : 'år'}:</span>
                <span>
                  {getCurrencyFormat(
                    (airCleaner.leasing * airCleaner.amount +
                      airCleaner.power * airCleaner.amount +
                      airCleaner.additionalProducts) *
                      (isMonthly ? 1 : 12)
                  )}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <span>aks2tal pr {isMonthly ? 'md' : 'år'}:</span>
                <span>{getCurrencyFormat(year3)}</span>
              </div>
              <Divider style={{ margin: '16px 0' }} />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  fontWeight: 700,
                }}
              >
                <span>Besparelse pr {isMonthly ? 'md' : 'år'}:</span>
                <span>
                  {getCurrencyFormat(
                    (airCleaner.leasing * airCleaner.amount +
                      airCleaner.power * airCleaner.amount +
                      airCleaner.additionalProducts) *
                      (isMonthly ? 1 : 12) -
                      year3
                  )}
                </span>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
}
