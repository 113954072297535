import { Badge, Tooltip } from 'antd';
import { isAfter, subDays } from 'date-fns';

export function PlantStatus({ lastSeen }: { lastSeen: number | null }) {
  const isRecent = lastSeen ? isAfter(lastSeen, subDays(new Date(), 5)) : null;
  if (!lastSeen) {
    return (
      <Tooltip title="Ingen data fundet">
        <Badge
          styles={{
            indicator: {
              padding: 8,
            },
          }}
          status="default"
        />
      </Tooltip>
    );
  }
  if (isRecent) {
    return (
      <Tooltip title="Seneste data er mindre end 5 dage gammel">
        <Badge
          styles={{
            indicator: {
              padding: 8,
            },
          }}
          status="success"
        />
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Seneste data er mere end 5 dage gammel">
      <Badge
        styles={{
          indicator: {
            padding: 8,
          },
        }}
        // styles={{ textFontWeight: 20 }}
        status="error"
      />
    </Tooltip>
  );
}
