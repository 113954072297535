import { call, put, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
// import * as fb from "firebase";
import firebase, { reduxSagaFirebase } from '../../firebase';
import { fetchTasksSuccess } from './actions';
import { TASKS_FETCH_SUCCEEDED, TASKS_FETCH_FAILED, TASKS_FETCH_REQUESTED } from './types';

function* fetchTasks({ payload }) {
  const { userId } = payload;
  try {
    const tasks = yield call(
      reduxSagaFirebase.firestore.getCollection,
      firebase
        .firestore()
        .collection(`tasks`)
        .where('assignees', 'array-contains', userId)
        .orderBy('dueDate')
    );

    yield put(fetchTasksSuccess(tasks));
  } catch (e) {
    yield put({ type: TASKS_FETCH_FAILED, message: e.message });
  }
}

export function* fetchTasksSaga() {
  yield takeLatest(TASKS_FETCH_REQUESTED, fetchTasks);
}
