import { Button, Checkbox, Popover } from 'antd';
import { CheckboxProps } from 'antd/lib';
import SettingsIcon from 'assets/icons/SettingsIcon';
import React from 'react';

export default function PreferenceButton({
  hidden,
  sethidden,
}: {
  hidden: {
    tasks: string[];
    issues: string[];
  };
  sethidden: any;
}) {
  return (
    <Popover
      content={<PreferencePopover hidden={hidden} sethidden={sethidden} />}
      title="Skjul dele af dashboardet"
      trigger="click"
      placement="rightTop"
    >
      <Button
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        icon={<SettingsIcon width={18} height={18} />}
      />
    </Popover>
  );
}

export enum TASKS {
  PLANNED_TASKS = 'PLANNED_TASKS',
  RESOLUTION_TASKS = 'RESOLUTION_TASKS',
  ON_HOLD_TASKS = 'ON_HOLD_TASKS',
  OVERDUE_TASKS = 'OVERDUE_TASKS',
  COMPLETED_TASKS = 'COMPLETED_TASKS',
}
export enum ISSUES {
  STATS = 'ISSUES_STATS',
}

const taskOptions = [
  { label: 'Opgaver planlagt', value: TASKS.PLANNED_TASKS },
  { label: 'Opgaver der kræver handling', value: TASKS.RESOLUTION_TASKS },
  { label: 'Opgaver på hold', value: TASKS.ON_HOLD_TASKS },
  { label: 'Opgaver ikke udført til tiden', value: TASKS.OVERDUE_TASKS },
  { label: 'Opgaver afsluttet', value: TASKS.COMPLETED_TASKS },
];
const issueOptions = [{ label: 'Udestående Fejlmeldinger', value: ISSUES.STATS }];

export const allTasksChecked = [
  TASKS.ON_HOLD_TASKS,
  TASKS.OVERDUE_TASKS,
  TASKS.PLANNED_TASKS,
  TASKS.RESOLUTION_TASKS,
  TASKS.COMPLETED_TASKS,
];
export const allIssuesChecked = [ISSUES.STATS];

function PreferencePopover({
  hidden,
  sethidden,
}: {
  hidden: {
    tasks: string[];
    issues: string[];
  };
  sethidden: any;
}) {
  const onTaskChange = (checkedValues: any) => {
    sethidden({ ...hidden, tasks: checkedValues });
  };
  const onIssueChange = (checkedValues: any) => {
    sethidden({ ...hidden, issue: checkedValues });
  };

  const onTaskChangeAll: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      sethidden({ ...hidden, tasks: allTasksChecked });
    } else {
      sethidden([]);
    }
  };
  const onIssueChangeAll: CheckboxProps['onChange'] = (e) => {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      sethidden({ ...hidden, issue: allIssuesChecked });
    } else {
      sethidden([]);
    }
  };
  // check if all taskOptions are checked
  const allTasksSelected = taskOptions.every((option) => hidden.tasks.includes(option.value));
  const allIssuesSelected = issueOptions.every((option) => hidden.issues.includes(option.value));
  return (
    <div style={{ display: 'flex', gap: 24 }}>
      <div>
        <div style={{ display: 'flex' }}>
          <Checkbox onChange={onTaskChangeAll} checked={allTasksSelected} />
          <h4 style={{ paddingLeft: 8 }}>Opgaver</h4>
        </div>
        <div>
          <Checkbox.Group
            style={{ display: 'grid' }}
            options={taskOptions}
            value={hidden.tasks}
            onChange={onTaskChange}
          />
        </div>
      </div>
      <div>
        <div style={{ display: 'flex' }}>
          {issueOptions.length > 1 && (
            <Checkbox
              style={{ paddingRight: 8 }}
              onChange={onIssueChangeAll}
              checked={allIssuesSelected}
            />
          )}
          <h4>Fejlrapportering</h4>
        </div>
        <div>
          <Checkbox.Group
            style={{ display: 'grid' }}
            options={issueOptions}
            value={hidden.issues}
            onChange={onIssueChange}
          />
        </div>
      </div>
    </div>
  );
}
