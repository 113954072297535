import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import SensorDetails from './index';
import { useAppSelector } from '../../../hooks';
import { fetchSensor, fetchSensorMeasures, setDate } from '../../../modules/sensors/actions';
import useSensorLogs from './useSensorLogs';
import { Dayjs } from 'dayjs';
import useTrips from './useTrips';
import useTracks from './useTracks';

function SensorDetailsContainer() {
  const { orgId, sensorId } = useParams<{ orgId: string; sensorId: string }>();
  const dispatch = useDispatch();

  const {
    // logs,
    title,
    measures,
    loading,
    loadingCurrent,
    lat = 55.661133,
    lng = 12.36529,
    selectedDate,
  } = useAppSelector((state) => ({
    // logs: state.sensors.currentSensor.data,
    title: state.sensors.currentSensor?.title,
    measures: state.sensors.currentSensor.measures,
    loading: state.sensors.loading,
    loadingCurrent: state.sensors.currentSensor.loading,
    lat: state.sensors.currentSensor.location.latitude,
    lng: state.sensors.currentSensor.location.longitude,
    selectedDate: state.sensors.selectedDate,
  }));
  useSensorLogs({ orgId, sensorId, selectedDate });
  const { trips, isTripsLoading } = useTrips({ orgId, sensorId, date: selectedDate });
  const { tracks, isTrackLoading } = useTracks({ orgId, sensorId, date: selectedDate });
  useEffect(() => {
    dispatch(fetchSensor({ orgId, sensorId }));

    dispatch(fetchSensorMeasures({ orgId, sensorId, selectedDate }));
    return () => {};
  }, []);

  const onDateChange = (_: Dayjs | null, dateString: string) => {
    dispatch(setDate({ date: dateString }));
  };

  // TODO: MOVE THIS TO DATABASE
  // const topLeftBoundary = this.props.topLeftBoundary;
  // const topRightBoundary = this.props.topRightBoundary;
  // const bottomRightBoundary = this.props.bottomRightBoundary;
  // const bottomLeftBoundary = this.props.bottomLeftBoundary;
  // const { lat, lng, measures } = this.props;

  const measuresFiltered = measures
    ? measures.map(({ createTime }: { createTime: { toDate: () => Date } }) => {
        const date = createTime.toDate();
        date.setHours(date.getHours() + 2);
        return `${date.getFullYear()}-${
          date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
        }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
      })
    : [];
  const centerMap = { lat, lng };
  /**
   * TODO: can be removed when migrated to big query
   */
  // const filteredLogs = logs
  //     .map(({ logTime, lat, lng }: any) => {
  //         return {
  //             logTime,
  //             lat: resolveLatLng(lat),
  //             lng: resolveLatLng(lng),
  //         };
  //     })
  //     .filter(({ lat, lng }: { lat: any; lng: any }) => {
  //         if (
  //             isFinite(lat) &&
  //             isFinite(lng) &&
  //             isFloat(parseFloat(lat)) &&
  //             isFloat(parseFloat(lng))
  //         ) {
  //             return true;
  //         }
  //         return false;
  //     });
  let filteredLogs: any[] = [];
  tracks.forEach((track: any) => {
    filteredLogs.push({
      logTime: new Date(track.logTime),
      lat: track.lat,
      lng: track.lng,
    });
  });

  return (
    <SensorDetails
      title={title}
      loading={loading || loadingCurrent || isTripsLoading || isTrackLoading}
      data={filteredLogs}
      selectedDate={selectedDate}
      onDateChange={onDateChange}
      centerMap={centerMap}
      measures={measuresFiltered}
      trips={trips}
    />
  );
}

export default SensorDetailsContainer;
