import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { addCategories, deleteCategory, fetchCategories } from '../modules/categories/actions';

function useCategories() {
  const categories = useSelector((state: AppState) => state.categories.list);

  const dispatch = useDispatch();

  const dispatchFetchCategories = (orgId: string) => dispatch(fetchCategories({ orgId }));
  const dispatchAddCategories = (orgId: string, title: string) =>
    dispatch(addCategories(orgId, title));
  const dispatchDeleteCategory = (orgId: string, categoryId: string) =>
    dispatch(deleteCategory(orgId, categoryId));

  return {
    categories: (categories || []).sort((a, b) =>
      a.title.localeCompare(b.title, 'da', { sensitivity: 'base', numeric: true })
    ),
    dispatchFetchCategories,
    dispatchAddCategories,
    dispatchDeleteCategory,
  };
}

export default useCategories;
