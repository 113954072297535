import { Popconfirm, Layout, Row, Col } from 'antd';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import { IOrg } from '../../modules/orgs/interfaces';
import TableList from './Table';
import AddOrg from './AddOrg';
import { DeleteOutlined } from '@ant-design/icons';
import OrgStats from './OrgStats';
import AdminOnly from '../../components/AdminOnly';
import { useTranslation } from 'react-i18next';
import { DBGroupShape } from '../../modules/groups/types';
import { Button } from 'components/ui/button';
import { toast } from 'sonner';

const { Content } = Layout;

export function DeleteCompany({ deleteOrg }: { deleteOrg: () => void }) {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={t('home.org.delete.title')}
      onConfirm={deleteOrg}
      okText={t('home.org.delete.confirm')}
      cancelText={t('home.org.delete.cancel')}
    >
      <DeleteOutlined />
    </Popconfirm>
  );
}

interface IHomeViewProps {
  orgs: IOrg[];
  groups: DBGroupShape[];
  deleteOrg: (orgId: string) => void;
}

export type GroupedOrgs = IOrg | { id: string; subGroup: string; title: string; orgs: IOrg[] };

function groupOrgsBySubGroup(orgs: IOrg[]): GroupedOrgs[] {
  const grouped = new Map<string, { title: string; orgs: IOrg[] }>();

  // Group orgs by subGroup ID
  for (const org of orgs) {
    if (org.subGroup) {
      const key = org.subGroup.id;
      if (!grouped.has(key)) {
        grouped.set(key, { title: org.subGroup.title, orgs: [] });
      }
      // @ts-ignore
      grouped.get(key)!.orgs.push({ nested: true, ...org });
    }
  }

  const result: GroupedOrgs[] = [];

  // Add grouped organizations to result using keys from the map
  grouped.forEach((data, subGroup) => {
    result.push({ id: subGroup, subGroup, title: data.title, orgs: data.orgs });
  });

  // Add ungrouped organizations to result
  orgs.filter((org) => !org.subGroup).forEach((org) => result.push(org));

  return result.sort((a, b) => {
    if (a.title && b.title) {
      return a.title.localeCompare(b.title, 'da', {
        sensitivity: 'base',
        numeric: true,
      });
    }
    return 0;
  });
}

function HomeView({ orgs, groups, deleteOrg }: IHomeViewProps) {
  const grouped = groupOrgsBySubGroup(orgs);

  return (
    <>
      <Layout>
        <HeaderBar />
        <Content style={{ margin: '24px 24px 0' }}>
          <Row>
            <Col span={24} style={{ marginBottom: 24 }}>
              <AdminOnly>
                <OrgStats />
              </AdminOnly>
            </Col>
            <Col span={24} style={{ marginBottom: 24 }}>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  textAlign: 'right',
                }}
              >
                {/* <AdminOnly>{!isDev && <OrgSearch />}</AdminOnly> */}
                <div style={{ marginLeft: 24 }} />
                <AddOrg key="add-org-form" />
              </div>
            </Col>
          </Row>

          <TableList deleteOrg={deleteOrg} data={grouped} groups={groups} />
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}

export default HomeView;
