import React, { useEffect, useState } from 'react';
import { getEquipmentById } from '../Equipment/data';
import { useParams } from 'react-router';
import EquipmentDetails from '../Equipment/equipment-detail';
import { Layout } from 'antd';
import HeaderBar from 'Layout/HeaderBar';
import FooterBar from 'Layout/FooterBar';

const { Content } = Layout;

export default function Container() {
  const { id } = useParams<{ id: string }>();
  const [loading, setloading] = useState(true);
  const [data, setData] = useState<any>(null);
  useEffect(() => {
    const getData = async () => {
      const equipment = await getEquipmentById(id);
      setloading(false);
      setData(equipment);
    };

    getData();

    return () => {};
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout>
      <HeaderBar />
      <Content
        style={{
          margin: '24px 24px 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <EquipmentDetails equipment={data} />
      </Content>
      <FooterBar />
    </Layout>
  );
}
