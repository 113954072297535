import { ESP_AQ_INVENTORY_FETCH_REQUESTED, ESP_AQ_INVENTORY_FETCH_SUCCEEDED } from './types';
import { InventoryContextForType, InventoryType } from '../types';

export function fetchInventoryEspAq() {
  return {
    type: ESP_AQ_INVENTORY_FETCH_REQUESTED,
    payload: {},
  };
}

export function fetchInventoryEspAqSuccess(
  // @ts-ignore
  productsList: firebase.firestore.QuerySnapshot
): {
  type: string;
  payload: InventoryContextForType<InventoryType.ESP_AQ>[];
} {
  const espAq = productsList.docs.map(
    // @ts-ignore
    (doc: firebase.firestore.DocumentData) => {
      const data = doc.data();
      return {
        ...data,
        orgId: data?.org?.id,
        orgTitle: data?.org?.title,
        id: doc.id,
        updateTime: data?.updateTime?.toDate(),
      };
    }
  );
  return {
    type: ESP_AQ_INVENTORY_FETCH_SUCCEEDED,
    payload: espAq,
  };
}
