'use client';

import { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from 'components/ui/chart';
import { Button } from 'components/ui/button';
import { Slider } from 'components/ui/slider';
import { Skeleton } from 'components/ui/skeleton';
import { getFlowDevicesData } from './api'; // Assuming this is your existing utility
import { StyleGuide } from 'styles/StyleGuide';

// Type for the chart data
interface ChartData {
  timestamp: string;
  level: number;
  formattedTime: string;
  rawTimestamp: Date;
  deviceId: string;
}
interface ChartDataLiter {
  timestamp: string;
  liter: number;
  formattedTime: string;
  rawTimestamp: Date;
  deviceId: string;
}

const getColorForValue = (
  value: number,
  min: number,
  max: number,
  fromHue: number = 240, // start color (blue)
  toHue: number = 0 // end color (red)
) => {
  const ratio = (value - min) / (max - min);
  const hue = fromHue + (toHue - fromHue) * ratio;
  return `hsl(${hue}, 100%, 50%)`;
};

export default function LevelChart({
  id,
  thresholds,
}: {
  id: string;
  thresholds?: { [key: string]: number };
}) {
  const [waterLevelData, setWaterLevelData] = useState<ChartData[]>([]);
  const [waterLiterData, setWaterLiterData] = useState<ChartDataLiter[]>([]);
  const [visibleRange, setVisibleRange] = useState<[number, number]>([0, 100]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [maxLevel, setMaxLevel] = useState(100);
  const [maxLiter, setMaxLiter] = useState(100);

  useEffect(() => {
    async function fetchFlowDeviceData() {
      try {
        setLoading(true);

        // Use your existing Firestore utility function
        const flowDevicesData = await getFlowDevicesData(id);

        const data: ChartData[] = [];
        const dataLiter: ChartDataLiter[] = [];
        let highestLevel = 0;
        let highestLiter = 0;

        flowDevicesData.forEach((flowData) => {
          // Parse the cm value as a number
          const level = Number.parseFloat(flowData.cm);
          const liter = Number.parseFloat(flowData.l) + Number.parseFloat(flowData.m2) * 1000;

          // Update the highest level if needed
          if (level > highestLevel) {
            highestLevel = level;
          }
          if (liter > highestLiter) {
            highestLiter = liter;
          }

          // Convert timestamp to Date if it's not already
          const timestamp =
            flowData.createTime instanceof Date
              ? flowData.createTime
              : new Date(flowData.createTime);

          data.push({
            timestamp: timestamp.toISOString(),
            level,
            formattedTime: `${timestamp.toLocaleDateString()} ${timestamp.toLocaleTimeString()}`,
            rawTimestamp: timestamp,
            deviceId: flowData.id,
          });
          dataLiter.push({
            timestamp: timestamp.toISOString(),
            liter,
            formattedTime: `${timestamp.toLocaleDateString()} ${timestamp.toLocaleTimeString()}`,
            rawTimestamp: timestamp,
            deviceId: flowData.id,
          });
        });

        // Sort by timestamp ascending
        data.sort((a, b) => a.rawTimestamp.getTime() - b.rawTimestamp.getTime());
        dataLiter.sort((a, b) => a.rawTimestamp.getTime() - b.rawTimestamp.getTime());

        // Set max level for y-axis with some padding
        setMaxLevel(Math.ceil(highestLevel * 1.2));
        setMaxLiter(Math.ceil(highestLiter * 1.2));

        setWaterLevelData(data);
        setWaterLiterData(dataLiter);

        // Set initial visible range to show the most recent 100 readings
        if (data.length > 0) {
          const end = data.length;
          const start = Math.max(0, end - 100);
          setVisibleRange([start, end]);
        }
        if (dataLiter.length > 0) {
          const end = dataLiter.length;
          const start = Math.max(0, end - 100);
          setVisibleRange([start, end]);
        }

        setLoading(false);
      } catch (err) {
        console.error('Error fetching flow device data:', err);
        setError('Failed to load water level data');
        setLoading(false);
      }
    }

    fetchFlowDeviceData();
  }, [id]);

  // Get the visible portion of data
  const visibleData = waterLevelData.slice(visibleRange[0], visibleRange[1]);
  const visibleLiterData = waterLiterData.slice(visibleRange[0], visibleRange[1]);

  // Format date for X-axis
  const formatXAxis = (timestamp: string) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${date.getMonth() + 1} ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  };

  // Preset ranges
  const showLast24Hours = () => {
    const now = new Date();
    const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

    const endIndex = waterLevelData.length;
    const startIndex = waterLevelData.findIndex((item) => new Date(item.timestamp) >= oneDayAgo);

    setVisibleRange([startIndex !== -1 ? startIndex : 0, endIndex]);
  };

  const showLast7Days = () => {
    const now = new Date();
    const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

    const endIndex = waterLevelData.length;
    const startIndex = waterLevelData.findIndex((item) => new Date(item.timestamp) >= sevenDaysAgo);

    setVisibleRange([startIndex !== -1 ? startIndex : 0, endIndex]);
  };

  const showAll = () => setVisibleRange([0, waterLevelData.length]);

  // Handle slider change
  const handleRangeChange = (values: number[]) => {
    if (waterLevelData.length === 0) return;

    const start = Math.floor((values[0] / 100) * waterLevelData.length);
    const visibleCount = Math.min(100, waterLevelData.length);
    const end = Math.min(waterLevelData.length, start + visibleCount);
    setVisibleRange([start, end]);
  };

  if (loading) {
    return (
      <div className="w-full space-y-4">
        <Skeleton className="h-8 w-3/4" />
        <Skeleton className="h-4 w-1/2" />
        <Skeleton className="h-[400px] w-full" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full p-6 text-center border rounded-lg bg-destructive/10">
        <p className="text-destructive font-medium">{error}</p>
        <p className="mt-2">Noget gik galt, indlæs siden igen. Forsætter fejlen kontakt aks2tal</p>
      </div>
    );
  }

  return (
    <div className="w-full space-y-4">
      <div className="flex flex-wrap gap-2">
        <Button variant="outline" size="sm" onClick={showLast24Hours}>
          Seneste 24 timer
        </Button>
        <Button variant="outline" size="sm" onClick={showLast7Days}>
          Seneste 7 Dage
        </Button>
        <Button variant="outline" size="sm" onClick={showAll}>
          Alt data (100 seneste)
        </Button>
        <div className="flex items-center gap-2 ml-auto text-sm text-muted-foreground">
          <div className="flex flex-row items-center">
            <div
              className="w-3 h-3 rounded-full mr-1 inline-block"
              style={{
                backgroundColor: StyleGuide.palette.infoDark,
              }}
            />
            <span className="text-xs">Støvbinder niveau (cm)</span>
          </div>
          <div className="flex flex-row items-center">
            <div
              className="w-3 h-3 rounded-full mr-1 inline-block"
              style={{
                backgroundColor: StyleGuide.palette.processing,
              }}
            />
            <span className="text-xs">Vandforbrug (l)</span>
          </div>
          -<span>{visibleData.length} logs vist</span>
        </div>
      </div>

      <div className="px-2">
        <Slider
          defaultValue={[0]}
          max={100}
          step={1}
          onValueChange={handleRangeChange}
          className="my-4"
        />
      </div>

      <div className="flex flex-wrap rounded-lg border bg-card p-6">
        <ChartContainer
          config={{
            waterLevel: {
              label: '(cm)',
              color: StyleGuide.palette.infoDark, // Blue color for water
            },
          }}
          className="h-[300px] w-full"
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={visibleData}
              margin={{ top: 10, right: 10, left: 10, bottom: 30 }}
              accessibilityLayer
              barSize={visibleData.length > 100 ? 2 : visibleData.length > 50 ? 5 : 10}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="timestamp"
                tickLine={false}
                axisLine={false}
                tickMargin={10}
                tickFormatter={formatXAxis}
                interval={Math.floor(visibleData.length / 10)} // Show fewer ticks for readability
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                tickLine={false}
                tickCount={9}
                axisLine={false}
                tickMargin={10}
                domain={[0, maxLevel]}
                label={{ value: 'Level (cm)', angle: -90, position: 'insideLeft' }}
              />
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    labelFormatter={(_, data) => data[0]?.payload.formattedTime || ''}
                    formatter={(value, name) => {
                      if (name === 'level') {
                        return [`${value} cm`];
                      }
                      return [value, name];
                    }}
                  />
                }
              />
              <Bar
                dataKey="level"
                fill="var(--color-waterLevel)"
                radius={[4, 4, 0, 0]}
                name="level"
              />
              {/* <Bar dataKey="level">
                {visibleData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={getColorForValue(entry.level, 0, maxLevel, 240, 0)}
                  />
                ))}
              </Bar> */}
              {thresholds?.binderMin && (
                <ReferenceLine
                  y={thresholds.binderMin}
                  stroke={StyleGuide.palette.danger}
                  strokeDasharray="2 2"
                  label=""
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      </div>
      <div className="flex flex-wrap rounded-lg border bg-card p-6">
        <ChartContainer
          config={{
            waterLevel: {
              label: '(l)',
              color: StyleGuide.palette.processing, // Blue color for water
            },
          }}
          className="h-[300px] w-full"
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={visibleLiterData}
              margin={{ top: 10, right: 10, left: 10, bottom: 30 }}
              accessibilityLayer
              barSize={visibleLiterData.length > 100 ? 2 : visibleLiterData.length > 50 ? 5 : 10}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="timestamp"
                tickLine={false}
                axisLine={false}
                tickMargin={10}
                tickFormatter={formatXAxis}
                interval={Math.floor(visibleLiterData.length / 10)} // Show fewer ticks for readability
                angle={-45}
                textAnchor="end"
                height={60}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                tickMargin={10}
                domain={[0, maxLiter]}
                label={{ value: 'Liter (l)', angle: -90, position: 'insideLeft' }}
              />
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    labelFormatter={(_, data) => data[0]?.payload.formattedTime || ''}
                    formatter={(value, name) => {
                      if (name === 'liter') {
                        return [`${value} l`];
                      }
                      return [value, name];
                    }}
                  />
                }
              />
              <Bar
                dataKey="liter"
                fill="var(--color-waterLevel)"
                radius={[4, 4, 0, 0]}
                name="liter"
              />
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
      </div>

      {waterLevelData.length > 0 && (
        <div className="text-sm text-muted-foreground">
          <p>Seneste log: {waterLevelData[waterLevelData.length - 1].formattedTime}</p>
          <p>Device ID: {waterLevelData[waterLevelData.length - 1].deviceId}</p>
        </div>
      )}
    </div>
  );
}
