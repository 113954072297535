import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import Page from './Page';
import { fetchSensors } from '../../modules/sensors/actions';
import { useAppSelector } from '../../hooks';
import AdminOnly from '../../components/AdminOnly';

function SensorOverviewContainer() {
  return (
    <AdminOnly>
      <Page />
    </AdminOnly>
  );
}

export default SensorOverviewContainer;
