export interface DBDirectoryShape {
  id?: string;
  title?: string;
  // @ts-ignore
  createTime?: firebase.firestore.Timestamp;
  parentDirectoryId?: string;
  files?: string[];
}

export interface DirectoryState extends DBDirectoryShape {
  directories: DBDirectoryShape[];
  loading: boolean;
}

export const DIRECTORIES_FETCH_REQUESTED = 'DIRECTORIES_FETCH_REQUESTED';
export const DIRECTORIES_FETCH_SUCCEEDED = 'DIRECTORIES_FETCH_SUCCEEDED';
export const DIRECTORIES_FETCH_FAILED = 'DIRECTORIES_FETCH_FAILED';

export const DIRECTORY_UPDATE_REQUESTED = 'DIRECTORY_UPDATE_REQUESTED';
export const DIRECTORY_UPDATE_SUCCEEDED = 'DIRECTORY_UPDATE_SUCCEEDED';
export const DIRECTORY_UPDATE_FAILED = 'DIRECTORY_UPDATE_FAILED';

export const DIRECTORY_ADD_SUCCEEDED = 'DIRECTORY_ADD_SUCCEEDED';
export const DIRECTORY_ADD_FAILED = 'DIRECTORY_ADD_FAILED';
export const DIRECTORY_ADD_REQUESTED = 'DIRECTORY_ADD_REQUESTED';

interface FetchDirectoriesSuccess {
  type: typeof DIRECTORIES_FETCH_REQUESTED;
  payload: { orgId: string };
}
interface FetchDirectories {
  type: typeof DIRECTORIES_FETCH_SUCCEEDED;
  payload: DBDirectoryShape[];
}
interface FetchDirectoriesFailed {
  type: typeof DIRECTORIES_FETCH_FAILED;
  payload: {};
}

export type DirectoryActionTypes =
  | FetchDirectories
  | FetchDirectoriesSuccess
  | FetchDirectoriesFailed;
