'use client';

import { useState, useEffect } from 'react';
import { OrderHistory, OrderStatusEnum, type Order } from '../lib/types';
import firebase from 'firebase/compat/app';

export function useOrderHistory(orderId?: string) {
  const [history, setHistory] = useState<OrderHistory[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!orderId) return;
    setLoading(true);
    let unsubscribe: any = null;
    unsubscribe = firebase
      .firestore()
      .collection('orders')
      .doc(orderId)
      .collection('history')
      .orderBy('updateTime', 'desc')
      .onSnapshot(
        (query) => {
          const history = query.docs.map((doc) => {
            const data = doc.data();
            return {
              ...data,
              id: doc.id,
              createTime: data.createTime.toDate(),
              updateTime: data.updateTime?.toDate(),
            };
          }) as OrderHistory[];

          setHistory(history);

          setLoading(false);
        },
        (error) => {
          console.error(error);
          setLoading(false);
        }
      );
    return unsubscribe;
  }, [orderId]);

  return {
    history,
    loading,
  };
}
