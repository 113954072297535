import React from 'react';
import { compose } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import ReportList from './ReportList';
import { fetchReports, updateReport } from '../../modules/reports/actions';
import { AppState } from '../../store';
import { fetchDirectories } from '../../modules/directories/actions';
import { DBDirectoryShape } from '../../modules/directories/types';
import WrapperHOC from '../../general/Wrapper';
import { DBReportShape } from 'modules/reports/types';
interface MatchParams {
  params: string;
}

export interface IProps extends RouteComponentProps<MatchParams> {
  fetchDirectories: ({ orgId }: { orgId: string }) => void;
  fetchReports: ({ orgId }: { orgId: string }) => void;
  currentDirectory: DBDirectoryShape;
  breadCrumbPath: { id: string; title: string }[];
  directories: DBDirectoryShape[];
  reports: DBReportShape[];
  orgId: string;
  directoryId: string;
  loading: boolean;
  updateReport: ({
    orgId,
    reportId,
    data,
  }: {
    orgId: string;
    reportId: string;
    data: { title?: string; isDeleted?: boolean };
  }) => void;
}

class ReportListContainer extends React.Component<IProps> {
  componentDidMount() {
    // @ts-ignore
    const { orgId } = this.props.match.params;
    this.props.fetchReports({ orgId });
    this.props.fetchDirectories({ orgId });
  }
  render(): JSX.Element {
    // @ts-ignore
    const { orgId, directoryId } = this.props.match.params;
    const { currentDirectory } = this.props;
    // @ts-ignore
    console.log({ breadCrumbPath: this.props.breadCrumbPath });

    return (
      <ReportList
        loading={this.props.loading}
        updateReport={this.props.updateReport}
        orgId={orgId}
        directoryId={directoryId}
        currentDirectory={currentDirectory}
        breadCrumbPath={this.props.breadCrumbPath}
        directories={this.props.directories}
        reports={this.props.reports}
      />
    );
  }
}

function mapStateToProps(state: AppState, ownProps: any) {
  const { directoryId } = ownProps.match.params;
  return {
    reports: state.reports.reports.filter((report) => report.directoryId === directoryId),
    currentDirectory: state.directories.directories.find((d) => d.id === directoryId),
    directories: state.directories.directories.filter((d) => d.id !== directoryId),
    breadCrumbPath: getFullDependencyPath(state.directories.directories, directoryId),
    loading: state.reports.loading,
  };
}

export function getFullDependencyPath(
  dirs: DBDirectoryShape[],
  parentDirectoryId?: string
): { id: string; title: string }[] {
  // Find the directory with the specified ID.
  const dir = dirs.find((d) => d.id === parentDirectoryId);

  if (!dir) return []; // No directory found with the given ID.

  // If this directory has a parent, recursively get its full dependency path.
  if (dir.parentDirectoryId) {
    return [
      ...getFullDependencyPath(dirs, dir.parentDirectoryId),
      { id: dir.id as string, title: dir.title as string },
    ];
  } else {
    // If no parent, return just this directory.
    return [{ id: dir.id as string, title: dir.title as string }];
  }
}

const mapActionsToProps = {
  fetchReports,
  fetchDirectories,
  updateReport,
};

const withConnect = connect(mapStateToProps, mapActionsToProps);

export default compose(withConnect, withRouter, WrapperHOC)(ReportListContainer);
