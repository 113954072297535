import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Layout,
  Row,
  Col,
  Carousel,
  Modal,
  Radio,
  Button,
  Tag,
  Skeleton,
  Space,
  Spin,
  Card,
  Alert,
} from 'antd';
import SideNav from '../../Layout/SideNav';
import HeaderBar from '../../Layout/HeaderBar';
import FooterBar from '../../Layout/FooterBar';
import MediaQuery from 'react-responsive';
import ImageLoader from '../../components/Loader/imageLoader';
import placeholderVideo from '../../assets/images/placeholder-video.png';
import PicturesWall from '../../components/PictureWall';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import IssueStatusSection from './components/IssueStatus';
import TextArea from 'antd/lib/input/TextArea';
import useGetDownloadUrl from '../../hooks/useGetDownloadUrl';
import { use, t } from 'i18next';
import { StyleGuide } from 'styles/StyleGuide';
import { useIsMobile } from 'hooks/useIsMobile';
import IssueActivity from './IssueActivity';
import IssueNotes from './IssueNotes';
import IssueTags from './IssueTags';
import IssueDescription from './IssueDescription';

const RadioGroup = Radio.Group;
const RadioButton = Radio.Button;
const { Content } = Layout;

interface File {
  // later png/jpg etc.
  contentType: string;
  // path: string;
  id: string;
  filePath: string;
  thumbFilePath: string;
}

function Image({ thumbFilePath }: { thumbFilePath: string }) {
  const { loading, fileUrl } = useGetDownloadUrl(thumbFilePath);
  console.log({ thumbFilePath });
  return (
    <div
      className="thumbnail-issues-image"
      style={{
        width: '100%',
        height: '300px',
        background: `#c0c0c0 url(${fileUrl}) no-repeat center center`,
      }}
    />
  );
}

function renderMobile(files: File[], openModal: (state: ModalState) => void) {
  return (
    <Col md={24} xs={24}>
      <MediaQuery query="(max-width: 768px)">
        <Carousel afterChange={() => {}}>
          {files.map((file) => (
            <div
              style={{ position: 'relative' }}
              onClick={() =>
                openModal({
                  contentType: file.contentType,
                  path: file.filePath || file.id,
                })
              }
            >
              <Image thumbFilePath={file.thumbFilePath} />
            </div>
          ))}
        </Carousel>
      </MediaQuery>
    </Col>
  );
}

function ImageDesktop({ thumbFilePath }: { thumbFilePath: string }) {
  const { loading, fileUrl } = useGetDownloadUrl(thumbFilePath);
  return (
    <Spin spinning={loading}>
      <div
        className="thumbnail-issues-image"
        style={{
          width: '100%',
          height: '300px',
          borderRadius: 8,
          background: `${StyleGuide.palette.grey200} url(${fileUrl}) no-repeat center center`,
        }}
      />
    </Spin>
  );
}

function RenderDesktop({
  files,
  openModal,
}: {
  files: File[];
  openModal: (state: ModalState) => void;
}) {
  console.log({ files });
  const { t } = useTranslation();
  const renderFiles = files.map((file) => (
    <MediaQuery query="(min-device-width: 768px)">
      <Col md={8} xs={24}>
        <div
          className="thumbnail-issues"
          onClick={() =>
            openModal({
              contentType: file.contentType,
              path: file.filePath || file.id,
            })
          }
        >
          {file.contentType?.startsWith('video/') ? (
            <div
              className="thumbnail-issues-image"
              style={{
                width: '100%',
                height: '300px',
                background: `#c0c0c0 url(${placeholderVideo}) no-repeat center center`,
              }}
            />
          ) : (
            <ImageDesktop thumbFilePath={file.thumbFilePath} />
          )}
          <div className="thumbnail-overlay">
            <div>
              {file.contentType?.startsWith('video/')
                ? t('issues.edit.video')
                : t('issues.edit.image')}
            </div>
          </div>
        </div>
      </Col>
    </MediaQuery>
  ));
  // if (files.length < 1) {
  //     return <>{t("Ingen filer til opgaven")}</>;
  // }
  return <>{renderFiles}</>;
}

interface ModalState {
  contentType: string;
  path: string;
}

interface Category {
  title: string;
}
// TODO: extract type
export type Responsible = 'internal' | 'external';

function LoadingIssueWrapper({ loading, children }: { loading: boolean; children: JSX.Element }) {
  if (loading) {
    return (
      <Row>
        <Col md={24} xs={24}>
          <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Skeleton.Input block style={{ height: 300 }} />
            <Skeleton.Input />
            <Skeleton.Input block />
            <Skeleton.Input />
            <Skeleton.Input block />
            <Skeleton.Input />
            <Space>
              <Skeleton.Image />
              <Skeleton.Image />
            </Space>
          </Space>
        </Col>
      </Row>
    );
  }
  return children;
}

interface Props {
  loading: boolean;
  isCompleted: boolean;
  files: File[];
  description: string;
  tags: string[];
  categories: { [tag: string]: Category };
  documentationFiles: File[];
  orgId: string;
  issueId: string;
  comment: string;
  userId: string;
  entity?: { id: string; title: string } | null;
  responsible: Responsible;
  completeTime: { toDate: () => Date };
  updateIssue: ({
    description,
    comment,
    responsible,
    isCompleted,
    completeTime,
    completeBy,
  }: Partial<{
    description: string;
    responsible: Responsible;
    comment: string;
    isCompleted: boolean;
    completeTime: Date;
    completeBy: string;
  }>) => void;
  deleteFile: ({
    orgId,
    issueId,
    fileId,
  }: {
    orgId: string;
    issueId: string;
    fileId: string;
  }) => void;
  deleteIssue: () => void;
}

function IssueDetail(props: Props) {
  const isMobile = useIsMobile();

  const [visible, setVisible] = useState<false | ModalState>(false);
  const { t } = useTranslation();
  const openModal = (state: ModalState | false) => {
    setVisible(state);
  };

  const style = props.isCompleted
    ? {
        width: '100%',
        backgroundColor: StyleGuide.palette.successLight,
        color: StyleGuide.palette.successDark,
      }
    : {
        width: '100%',
        color: StyleGuide.palette.amber,
        borderColor: StyleGuide.palette.amber,
      };

  const activeRadio = {
    backgroundColor: StyleGuide.palette.infoDark,
    color: '#fff',
    borderColor: StyleGuide.palette.infoDark,
  };

  return (
    <>
      <SideNav />
      <Layout>
        <HeaderBar backLink={`/orgs/${props.orgId}/issues`} backText={t('issues.new.back')} />
        {props.isCompleted && (
          <Alert
            style={{ paddingLeft: 24 }}
            type="success"
            message={
              t('issues.edit.banner.completed') +
              ' ' +
              t('date.exactFormatDateTimeYear', {
                dayOfWeek: props.completeTime.toDate(),
                dayOfMonth: props.completeTime.toDate(),
                month: props.completeTime.toDate(),
                time: props.completeTime.toDate(),
                year: props.completeTime.toDate(),
              })
            }
            banner
          />
        )}
        <Content style={{ margin: '24px 24px 0' }}>
          <LoadingIssueWrapper loading={props.loading}>
            <Row gutter={8}>
              <Col md={24} xs={24}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button size="large" danger onClick={props.deleteIssue}>
                    <DeleteOutlined />
                    {t('issues.edit.delete')}
                  </Button>
                </div>
                <div style={{ marginTop: 16 }} />
              </Col>
              {renderMobile(props.files, openModal)}
              <RenderDesktop files={props.files} openModal={openModal} />

              <Col md={props.files.length === 0 ? 24 : 16} xs={24}>
                {isMobile && <div style={{ marginTop: 8 }} />}
                {props.entity && (
                  <>
                    <IssueSection
                      title={t('Fejl rapporteret på')}
                      children={
                        <div
                          style={{
                            display: 'inline-block',
                            padding: 8,
                            borderRadius: 8,
                            color: StyleGuide.palette.infoDark,
                            backgroundColor: StyleGuide.palette.infoLight,
                          }}
                        >
                          {props.entity.title}
                        </div>
                      }
                    />
                    <div style={{ marginTop: 8 }} />
                  </>
                )}
                <IssueSection
                  title={t('issues.edit.description')}
                  children={
                    <IssueDescription
                      id={props.issueId}
                      description={props.description}
                      updateDescription={props.updateIssue}
                    />
                  }
                />
                <div style={{ marginTop: 8 }} />
                <Row gutter={8}>
                  <Col md={12} xs={24}>
                    <IssueSection
                      style={{ height: '100%' }}
                      title={t('issues.edit.categories')}
                      children={<IssueTags tags={props.tags} updateIssue={props.updateIssue} />}
                    />
                  </Col>
                  <Col md={12} xs={24}>
                    {isMobile && <div style={{ marginTop: 8 }} />}
                    <IssueSection
                      title={t('issues.edit.assignee')}
                      children={<IssueStatusSection />}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md={12} xs={24}>
                <div style={{ marginTop: 8 }} />
                <IssueSection
                  title={t('issues.edit.documentation')}
                  children={
                    <PicturesWall
                      files={props.documentationFiles}
                      orgId={props.orgId}
                      contextId={props.issueId}
                      context="ISSUE_DOCUMENTATION"
                      deleteFile={(fileId: string) =>
                        props.deleteFile({
                          orgId: props.orgId,
                          issueId: props.issueId,
                          fileId,
                        })
                      }
                    />
                  }
                />
              </Col>
              <Col md={12} xs={24}>
                <div style={{ marginTop: 8 }} />
                <IssueSection
                  title={t('issues.edit.solver')}
                  style={{ height: 'calc(100% - 8px)' }}
                  children={
                    <RadioGroup
                      style={{ width: '100%' }}
                      size="large"
                      value={props.responsible}
                      onChange={(e) =>
                        props.updateIssue({
                          responsible: e.target.value,
                        })
                      }
                    >
                      <RadioButton
                        style={{
                          width: '50%',
                          textAlign: 'center',
                          ...(props.responsible === 'external' && activeRadio),
                        }}
                        value="external"
                      >
                        {t('issues.edit.solversupplier')}
                      </RadioButton>
                      <RadioButton
                        style={{
                          width: '50%',
                          textAlign: 'center',
                          ...(props.responsible === 'internal' && activeRadio),
                        }}
                        value="internal"
                      >
                        {t('issues.edit.solverinternal')}
                      </RadioButton>
                    </RadioGroup>
                  }
                />
              </Col>
              <Col md={24} xs={24}>
                <div style={{ marginTop: 8 }} />

                <IssueSection
                  title={'Noter'}
                  // action={{
                  //     label: "Tilføj note +",
                  //     onClick: () => {},
                  // }}
                  children={
                    <IssueNotes
                      comment={props.comment}
                      updateIssue={(data: any) => {
                        props.updateIssue(data);
                      }}
                    />
                  }
                />
              </Col>
              <Col md={24} xs={24}>
                <div style={{ marginTop: 8 }} />

                <IssueSection
                  title={'Aktivitet'}
                  children={
                    <div>
                      <IssueActivity />
                    </div>
                  }
                />
              </Col>
              <Col md={24} xs={24}>
                <div style={{ padding: '10px' }} />
              </Col>
              <Col md={24} xs={24}>
                <Button
                  size="large"
                  style={style}
                  onClick={() =>
                    props.updateIssue(
                      props.isCompleted
                        ? { isCompleted: !props.isCompleted }
                        : {
                            isCompleted: !props.isCompleted,
                            completeTime: new Date(),
                            completeBy: props.userId,
                          }
                    )
                  }
                >
                  <CheckCircleOutlined />
                  {props.isCompleted ? t('issues.edit.completed') : t('issues.edit.complete')}
                </Button>
              </Col>
            </Row>
          </LoadingIssueWrapper>
          <Modal
            key="issue-detail-modal"
            // width="100%"
            style={{ position: 'relative' }}
            open={Boolean(visible)}
            onCancel={() => openModal(false)}
            footer={[]}
          >
            {visible && visible.contentType?.startsWith('image/') && (
              <FullImage filePath={visible.path} />
            )}
            {visible && visible.contentType?.startsWith('video/') && (
              <video width="100%" height="100%" controls src={visible.path}></video>
            )}
          </Modal>
        </Content>
        <FooterBar />
      </Layout>
    </>
  );
}

function FullImage({ filePath }: { filePath: string }) {
  const { loading, fileUrl } = useGetDownloadUrl(filePath);
  console.log({ fileUrl, filePath });
  if (loading) return <ImageLoader />;
  if (!fileUrl) return <div>Noget gik galt</div>;
  return <img width="100%" src={fileUrl} />;
}

export default IssueDetail;

function IssueSection({
  title,
  style,
  action,
  children,
}: {
  title: string;
  style?: React.CSSProperties;
  action?: {
    label: string | React.ReactNode;
    onClick: () => void;
  };
  children: React.ReactNode;
}) {
  return (
    <div
      style={{
        ...style,
        backgroundColor: '#fff',
        padding: 16,
        borderRadius: 8,
        border: `1px solid ${StyleGuide.palette.grey100}`,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            fontSize: 14,
            marginBottom: 16,
            fontWeight: 'bold',
          }}
        >
          {title}
        </div>
        {action && (
          <div>
            <Button onClick={action?.onClick}>{action.label}</Button>
          </div>
        )}
      </div>
      <div style={{ fontSize: 18 }}>{children}</div>
    </div>
  );
}
