'use client';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import {
  QrCode,
  Calendar,
  MapPin,
  PenToolIcon as Tool,
  Package,
  LogIn,
  LogOut,
} from 'lucide-react';
import type { InventoryItem } from '../types';
import { QRCodeSVG } from 'qrcode.react';
import { Badge } from 'components/ui/badge';
import { getCategoryColor } from './inventory-table-columns';

interface ItemDetailsDialogProps {
  item: InventoryItem;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function ItemDetailsDialog({ item, open, onOpenChange }: ItemDetailsDialogProps) {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Equipment Details</DialogTitle>
          <DialogDescription>Detailed information about this equipment item.</DialogDescription>
        </DialogHeader>

        <div className="grid gap-6 py-4">
          <div className="flex flex-col md:flex-row gap-6">
            <div className="w-full md:w-1/3 aspect-square relative rounded-md overflow-hidden bg-muted">
              <img
                src={item.image || '/placeholder.svg?height=200&width=200'}
                alt={item.name}
                className="object-cover"
              />
            </div>

            <div className="w-full md:w-2/3 space-y-4">
              <div>
                <h3 className="text-2xl font-semibold">{item.name}</h3>
                <Badge className={getCategoryColor(item.category)}>{item.category}</Badge>
              </div>
              <div className="flex justify-center">
                <QRCodeSVG value={item.id} size={128} />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                <div className="flex items-center gap-2">
                  <QrCode className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Equipment ID</p>
                    <p className="font-mono">{item.id}</p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Package className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Serial Number</p>
                    <p className="font-mono">{item.serialNumber}</p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <MapPin className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Location</p>
                    <p>{item.location || 'Not specified'}</p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Tool className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Manufacturer</p>
                    <p>{item.manufacturer || 'Not specified'}</p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Purchase Date</p>
                    <p>
                      {item.purchaseDate
                        ? new Date(item.purchaseDate).toLocaleDateString()
                        : 'Not specified'}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Last Maintenance</p>
                    <p>
                      {item.lastMaintenance
                        ? new Date(item.lastMaintenance).toLocaleDateString()
                        : 'Not recorded'}
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <Calendar className="h-4 w-4 text-muted-foreground" />
                  <div>
                    <p className="text-xs text-muted-foreground">Next Maintenance</p>
                    <p>
                      {item.nextMaintenance
                        ? new Date(item.nextMaintenance).toLocaleDateString()
                        : 'Not scheduled'}
                    </p>
                  </div>
                </div>

                {item.status === 'In Use' && (
                  <>
                    <div className="flex items-center gap-2">
                      <LogOut className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <p className="text-xs text-muted-foreground">Checked Out To</p>
                        <p>{item.checkedOutTo}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <Calendar className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <p className="text-xs text-muted-foreground">Check Out Date</p>
                        <p>
                          {item.checkOutDate
                            ? new Date(item.checkOutDate).toLocaleDateString()
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <LogIn className="h-4 w-4 text-muted-foreground" />
                      <div>
                        <p className="text-xs text-muted-foreground">Expected Return Date</p>
                        <p>
                          {item.expectedReturnDate
                            ? new Date(item.expectedReturnDate).toLocaleDateString()
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div>
            <h4 className="font-medium mb-2">Description</h4>
            <p className="text-sm">{item.description || 'No description provided.'}</p>
          </div>
        </div>

        <div className="flex justify-end">
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
