import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { preparePieChartData } from './utils';

type PieChartAuditProps = {
  audits: any[];
};

const PieChartAuditStatus: React.FC<PieChartAuditProps> = ({ audits }) => {
  const data = preparePieChartData(audits);

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
      // innerRadius={0.5} // Creates a donut chart effect
      padAngle={0.7}
      cornerRadius={4}
      // colors={{ scheme: "paired" }}
      colors={{ datum: 'data.color' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#999"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: 'color' }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          translateY: 56,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: 'circle',
        },
      ]}
    />
  );
};

export default PieChartAuditStatus;
