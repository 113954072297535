import React from 'react';
import { Select } from 'antd';

export enum Unit {
  LITER = 'l',
  MILILITER = 'ml',
  PIECE = 'stk',
}

export default function SelectUnit() {
  return (
    <Select placeholder="Vælg enhed" style={{ width: '100%' }}>
      <Select.Option value={Unit.LITER}>Liter (l)</Select.Option>
      <Select.Option value={Unit.MILILITER}>Mililiter (ml)</Select.Option>
      <Select.Option value={Unit.PIECE}>Stk.</Select.Option>
    </Select>
  );
}
