import React, { useState } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import { FolderOutlined, PlusCircleOutlined } from '@ant-design/icons';
import useAddDirectories from './useAddDirectories';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const FormItem = Form.Item;

function AddDirectory() {
  const [form] = Form.useForm();
  const { directoryId } = useParams<{ directoryId: string }>();
  const [visible, setvisible] = useState(false);
  const [confirmLoading, setconfirmLoading] = useState(false);
  const { dispatchAddDirectory } = useAddDirectories();
  const { t } = useTranslation();
  const showModal = () => setvisible(true);

  const handleOk = async (values: any) => {
    setconfirmLoading(true);
    await dispatchAddDirectory(values.title, directoryId);
    setconfirmLoading(false);
    setvisible(false);
    message.success(t('reports.add_success'));
    form.resetFields();
  };

  const handleCancel = () => setvisible(false);

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t('reports.add_folder')}
        <PlusCircleOutlined />
      </Button>
      <Modal
        title={t('reports.add_folder')}
        open={visible}
        // onOk={e => handleOk(e, email)}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={
          [
            // <Button key="back" onClick={handleCancel}>
            //     Cancel
            // </Button>,
          ]
        }
      >
        <Form form={form} onFinish={handleOk}>
          <FormItem
            name="title"
            rules={[
              {
                required: true,
                message: t('Please input a title!'),
              },
            ]}
          >
            <Input
              prefix={<FolderOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder={t('reports.folder_add_placeholder')}
            />
          </FormItem>

          <FormItem>
            <Button type="primary" block htmlType="submit" loading={confirmLoading}>
              {t('reports.folder_add_submit')}
            </Button>
          </FormItem>
        </Form>
      </Modal>
    </>
  );
}

export default AddDirectory;
