import React from 'react';
import {
  EyeOutlined,
  WifiOutlined,
  WarningOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
  AimOutlined,
  UserSwitchOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import { Popover, Button, Badge, List, Tooltip, Typography } from 'antd';
import useNotifications, { Notification } from './useNotifications';
import { NotificationType } from '../../general/enums';
import { ClearOutlined } from '@ant-design/icons';
import { Colors } from '../../Layout/Colors';
import { useTranslation } from 'react-i18next';
import { StyleGuide } from 'styles/StyleGuide';
import { isToday, isWithinInterval, isYesterday, subDays } from 'date-fns';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SensorIcon from 'assets/icons/SensorIcon';
import { AlertCircle } from 'lucide-react';

function getIconByType(item: Notification) {
  switch (item.type) {
    case NotificationType.FOOBOT_LOG_HIGH:
      return {
        link: `/orgs/${item?.context?.orgId}/sensors/${item?.context?.sensorId}/aq`,
        icon: <WifiOutlined style={{ fontSize: 28, color: Colors.warning }} />,
      };
    case NotificationType.LOW_STORAGE:
      return {
        link: `/orgs/${item?.context?.orgId}/orders`,
        icon: <WarningOutlined style={{ fontSize: 28, color: Colors.danger }} />,
      };

    case NotificationType.CLEAN_LOG_DAY_ALERT:
      return {
        link: `/orgs/${item?.context?.orgId}/clean-logs`,
        icon: <ClearOutlined style={{ fontSize: 28, color: Colors.danger }} />,
      };

    case NotificationType.NEW_ISSUE:
      return {
        link: `/orgs/${item?.context?.orgId}/issues/${item?.context?.issueId}`,
        icon: <ExceptionOutlined style={{ fontSize: 28, color: Colors.danger }} />,
      };

    case NotificationType.ISSUE_COMPLETED:
      return {
        link: `/orgs/${item?.context?.orgId}/issues/${item?.context?.issueId}`,
        icon: <FileDoneOutlined style={{ fontSize: 28, color: Colors.done }} />,
      };

    case NotificationType.ISSUE_ASSIGNEE_CHANGED:
      return {
        link: `/orgs/${item?.context?.orgId}/issues/${item?.context?.issueId}`,
        icon: <UserSwitchOutlined style={{ fontSize: 28, color: Colors.info }} />,
      };

    case NotificationType.MISSING_GPS_LOGS:
      return {
        link: `/orgs/${item?.context?.orgId}/sensors/${item?.context?.sensorId}`,
        icon: <AimOutlined style={{ fontSize: 28, color: Colors.danger }} />,
      };

    case NotificationType.AUDIT_START_TODAY:
      return {
        link: `/orgs/${item?.context?.orgId}/audit/audits${
          item?.context?.auditId ? `/${item?.context?.auditId}` : ''
        }`,
        icon: (
          <FieldTimeOutlined
            style={{
              fontSize: 28,
              color: StyleGuide.palette.statusInprogressDark,
            }}
          />
        ),
      };
    case NotificationType.AUDIT_DEADLINE_TODAY:
      return {
        link: `/orgs/${item?.context?.orgId}/audit/audits${
          item?.context?.auditId ? `/${item?.context?.auditId}` : ''
        }`,
        icon: <FieldTimeOutlined style={{ fontSize: 28, color: Colors.warning }} />,
      };
    case NotificationType.AUDIT_DEADLINE_ALERT:
      return {
        link: `/orgs/${item?.context?.orgId}/audit/audits${
          item?.context?.auditId ? `/${item?.context?.auditId}` : ''
        }`,
        icon: <FieldTimeOutlined style={{ fontSize: 28, color: Colors.danger }} />,
      };
    case NotificationType.SMS_INDUSTRY_ALERT:
      return {
        link: `/orgs/${item?.context?.orgId}/sensors${
          item?.context?.sensorId ? `/${item?.context?.sensorId}/gsm-custom` : ''
        }`,
        icon: <SensorIcon color={Colors.danger} width={28} />,
      };
    case NotificationType.PLANT_INDUSTRY_ALERT:
      return {
        link: `/orgs/${item?.context?.orgId}/sensors${
          item?.context?.sensorId ? `/${item?.context?.sensorId}/gsm-plant` : ''
        }`,
        icon: <SensorIcon color={Colors.danger} width={28} />,
      };

    case NotificationType.AUDIT_COMPLETED_WITH_ISSUES:
      return {
        link: `/orgs/${item?.context?.orgId}/audit/audits${
          item?.context?.auditId ? `/${item?.context?.auditId}` : ''
        }`,
        icon: <WarningOutlined style={{ fontSize: 28, color: Colors.hardWarning }} />,
      };

    case NotificationType.AUDIT_DAYS_AFTER_DEADLINE_ALERT:
      return {
        link: `/orgs/${item?.context?.orgId}/audit/audits${
          item?.context?.auditId ? `/${item?.context?.auditId}` : ''
        }`,
        icon: <WarningOutlined style={{ fontSize: 28, color: Colors.danger }} />,
      };
    case NotificationType.INTERNAL_AUDIT_DEVIATIONS:
      return {
        link: `/orgs/${item?.context.orgId}/audit/audits`,
        icon: <AlertCircle style={{ fontSize: 28, color: Colors.danger }} />,
      };

    default:
      return {
        link: `/orgs/${item?.context?.orgId}/clean-logs`,
        icon: <WarningOutlined />,
      };
  }
}

function Content({
  notifications,
  markAsSeen,
}: {
  notifications: any[];
  markAsSeen: (notificationId: string) => void;
}) {
  const { t } = useTranslation();
  const grouped = selectGroupedNotificationIdsByTime(notifications);

  return (
    <div style={{ maxHeight: 500, maxWidth: 600, overflow: 'scroll' }}>
      {Object.keys(grouped).map((dayKey) => {
        const list = grouped[dayKey];
        if (list.length <= 0) return null;
        return (
          <>
            <div
              style={{
                padding: '4px 8px',
                backgroundColor: StyleGuide.palette.infoLighter,
                position: 'sticky',
                top: 0,
              }}
            >
              {t(`general.${dayKey}`)}
            </div>

            {list.map((n) => (
              <ListItem
                key={n.id}
                icon={getIconByType(n)}
                title={n.title}
                description={n.message}
                onClick={() => markAsSeen(n.id)}
              />
            ))}
          </>
        );
      })}
    </div>
  );
}

function ListItem({
  icon,
  title,
  description,
  onClick,
}: {
  icon: { link: string; icon: JSX.Element };
  title: string;
  description: string;
  onClick: () => void;
}) {
  return (
    <HoverItem>
      <div
        style={{
          display: 'flex',
          padding: '8px 16px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Link to={icon.link} style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
          <div>{icon.icon}</div>
          <div style={{ paddingLeft: 16 }}>
            <div
              style={{
                fontWeight: 'bold',
                fontSize: 14,
                color: StyleGuide.palette.grey900,
              }}
            >
              {title}
            </div>
            <div style={{ color: StyleGuide.palette.grey600 }}>{description}</div>
          </div>
        </Link>
        <HoverMarkSeen
          style={{ paddingLeft: 16, paddingRight: 16, cursor: 'pointer' }}
          onClick={onClick}
        >
          <Tooltip title="Markér som læst">
            <EyeOutlined style={{ fontSize: 18 }} />
          </Tooltip>
        </HoverMarkSeen>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          width: '100%',
        }}
      >
        <div
          style={{
            height: 1,
            width: '100%',
            backgroundColor: StyleGuide.palette.grey200,
          }}
        />
      </div>
    </HoverItem>
  );
}

const HoverMarkSeen = styled('div')`
  transition: opacity 0.2s;
  &:hover {
    opacity: 0.5;
  }
`;
const HoverItem = styled('div')`
  transition: background-color 0.2s;
  &:hover {
    background-color: ${StyleGuide.palette.grey100};
  }
`;

export default function NotificationCenter() {
  const { notifications, markAsSeen } = useNotifications();

  const { t } = useTranslation();
  return (
    <Popover
      style={{ padding: 0 }}
      content={() => <Content notifications={notifications} markAsSeen={markAsSeen} />}
      title=""
      trigger="click"
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ minWidth: 400 }}
    >
      <Badge count={notifications.length} offset={[-10, 2]}>
        <Button
          style={{
            border: `1px solid ${StyleGuide.palette.infoLight}`,
            borderRadius: 16,
          }}
          type="link"
        >
          {t('header.notifications')}
        </Button>
      </Badge>
    </Popover>
  );
}

export const selectGroupedNotificationIdsByTime = (notifications: any[]) => {
  const today = new Date();
  const lastweek = subDays(today, 7);

  return notifications.reduce(
    (groups: Record<string, Notification[]>, notification) => {
      if (notification) {
        const notificationDate = notification?.createTime?.toDate();
        if (isToday(notificationDate)) {
          groups.today.push(notification);
        } else if (isYesterday(notificationDate)) {
          groups.yesterday.push(notification);
        } else if (
          isWithinInterval(notificationDate, {
            start: lastweek,
            end: today,
          })
        ) {
          groups.lastweek.push(notification);
        } else {
          groups.older.push(notification);
        }
      }
      return groups;
    },
    {
      today: [],
      yesterday: [],
      lastweek: [],
      older: [],
    }
  );
};
