/**
 * Truncates a string to a specified length and adds an ellipsis (...) if the string is longer than the specified length.
 * @param str - The string to truncate.
 * @param maxLength - The maximum length of the truncated string.
 * @returns The truncated string.
 */
export function truncate(str: string | undefined, maxLength: number): string {
  if (!str) {
    return '';
  }
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + '...';
}
