'use client';

import { useState } from 'react';
import { Badge } from 'components/ui/badge';
import { Button } from 'components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Progress } from 'components/ui/progress';
import {
  AlertTriangle,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  CircleCheckBig,
  FileText,
  MessageCircleWarning,
  MessageSquare,
  MoveLeft,
  Paperclip,
  TriangleAlert,
  XCircle,
} from 'lucide-react';
import ResponsiblePicker from './responsible-picker';
import AuditDeadlinePicker from './audit-deadline-picker';
import { useHistory, useParams } from 'react-router';
import { SubTasksMap, TasksMap, useAudit } from './audit-context';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table';
import { Tooltip, TooltipTrigger, TooltipContent } from 'components/ui/tooltip';
import firebase from 'firebase/compat/app';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import api from '../InternalAudit/api';
import PageLoader from 'components/Loader/PageLoader';
import { getStatusColor, getStatusIcon } from '../InternalAuditOverview/components/AuditMatrix';

export function getDisplayStatus(status: string) {
  switch (status?.toLowerCase()) {
    case 'deviation':
      return 'Afvigelse';
    case 'observation':
      return 'Observation';
    case 'passed':
      return 'Godkendt';
    case 'not-audited':
      return 'Ikke auditeret';
    case 'deviation-resolved':
      return 'Afvigelse løst';
    default:
      return 'Ikke auditeret';
  }
}
export function getCounts(subTasksMap: SubTasksMap | null) {
  const initCounts = { deviations: 0, observations: 0, passed: 0, total: 0 };
  if (!subTasksMap) {
    return initCounts;
  }
  const counts = Object.entries(subTasksMap).reduce((acc, [_, subTask]) => {
    console.log({ subTask });

    return {
      ...acc,
      deviations: subTask.status === 'deviation' ? acc.deviations + 1 : acc.deviations,
      observations: subTask.status === 'observation' ? acc.observations + 1 : acc.observations,
      passed: subTask.status === 'passed' ? acc.passed + 1 : acc.passed,
      // we don't count not-audited tasks
      total: !subTask.status || subTask.status !== 'not-audited' ? acc.total + 1 : acc.total,
    };
  }, initCounts);
  return counts;
}
function getCountsByTask(subTasksMap: SubTasksMap | null, taskId: string) {
  const initCounts = { deviations: 0, observations: 0, passed: 0, total: 0 };
  if (!subTasksMap) {
    return initCounts;
  }
  const counts = Object.entries(subTasksMap)
    .filter(([_, task]) => task.taskId === taskId)
    .reduce((acc, [_, subTask]) => {
      return {
        ...acc,
        deviations: subTask.status === 'deviation' ? acc.deviations + 1 : acc.deviations,
        observations: subTask.status === 'observation' ? acc.observations + 1 : acc.observations,
        passed: subTask.status === 'passed' ? acc.passed + 1 : acc.passed,
        // we don't count not-audited tasks
        total: !subTask.status || subTask.status !== 'not-audited' ? acc.total + 1 : acc.total,
      };
    }, initCounts);
  return counts;
}

function validateAllSubTasksDeviation(subTasksMap: SubTasksMap | null) {
  if (!subTasksMap) {
    return false;
  }
  // check if all subtasks with state deviation has a responsible and due date
  const allDeviationSubTasks = Object.values(subTasksMap).filter(
    (subTask) => subTask.status === 'deviation'
  );
  return allDeviationSubTasks.every((subTask) => subTask.responsible && subTask.dueDate);
}

function getTasksList(tasksMap: TasksMap | null) {
  if (!tasksMap) {
    return [];
  }
  return Object.entries(tasksMap)
    .map(([_, task]) => ({
      ...task,
    }))
    .sort((a, b) => a.order - b.order);
}

export default function AuditSummary() {
  const { t } = useTranslation();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { audit, tasksMap, subTasksMap, currentTaskIndex, setCurrentTaskIndex, setStep } =
    useAudit();

  if (!audit || !tasksMap) return <PageLoader />;

  const counts = getCounts(subTasksMap);
  console.log({ counts });

  const isCompleted = audit.state === 'COMPLETED';

  const submitAuditComplete = async () => {
    const isValid = validateAllSubTasksDeviation(subTasksMap);
    if (!isValid) {
      toast.error('Alle afvigelser skal have en ansvarlig og en deadline');
      return;
    }
    setIsSubmitting(true);
    if (!isCompleted) {
      await api.completeInternalAudit({ auditId: audit.id });
      toast.success(`${counts.deviations} afvigelse(r) oprettet`);
    }
    setIsSubmitting(false);
    history.push(`/internal-audit`);
  };

  const passRate = Math.round((counts.passed / counts.total) * 100);

  const taskList = getTasksList(tasksMap);

  return (
    <div className="container mx-auto py-6 px-4 max-w-6xl">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-6 gap-4">
        <div>
          <Button size="sm" variant="secondary" onClick={() => setStep('execution')}>
            <MoveLeft size={14} strokeWidth={1} /> Tilbage
          </Button>
          <div className="mt-2" />
          <h1 className="flex gap-2 text-2xl font-bold tracking-tight items-center">
            {audit.location.title} {!isCompleted && <Badge variant="outline">Kladde</Badge>}
          </h1>
          <p className="text-muted-foreground">
            {isCompleted
              ? t('general.dateTimeYYYY', { date: audit.completeTime?.toDate() })
              : 'Igangværende audit'}
            • {'intern audit'} • Auditør: {audit.auditor.displayName}
          </p>
        </div>
        <div className="flex gap-2">
          {/* <Button variant="outline" size="sm">
            <Mail className="mr-2 h-4 w-4" />
            Share
          </Button>
          <Button variant="outline" size="sm">
            <Download className="mr-2 h-4 w-4" />
            Export
          </Button> */}
          <Button size="sm" onClick={submitAuditComplete} loading={isSubmitting}>
            <FileText className="mr-2 h-4 w-4" />
            {isCompleted ? 'Til oversigten' : 'Afslut audit & opret opgaver'}
          </Button>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-4 mb-6">
        {/* <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Total Items</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold">{auditData.summary.total}</div>
            <Progress value={100} className="h-2 mt-2" />
          </CardContent>
        </Card> */}
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Afvigelser</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-red-500">{counts.deviations}</div>
            <Progress
              value={(counts.deviations / counts.total) * 100}
              className="h-2 mt-2 bg-slate-200"
              //   indicatorClassName="bg-red-500"
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Observationer</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-amber-500">{counts.observations}</div>
            <Progress
              value={(counts.observations / counts.total) * 100}
              className="h-2 mt-2 bg-slate-200"
              //   indicatorClassName="bg-amber-500"
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Godkendt</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-green-500">{counts.passed}</div>
            <Progress
              value={(counts.passed / counts.total) * 100}
              className="h-2 mt-2 bg-slate-200"
              //   indicatorClassName="bg-amber-500"
            />
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="pb-2">
            <CardTitle className="text-sm font-medium">Godkendelsesprocent</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-3xl font-bold text-green-500">{passRate}%</div>
            <Progress
              value={passRate}
              className="h-2 mt-2 bg-slate-200"
              //   indicatorClassName="bg-green-500"
            />
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl font-bold">Opgaver fra auditering</CardTitle>
        </CardHeader>
        <CardContent>
          {/* <Tabs defaultValue="all">
              <TabsList className="mb-4">
                <TabsTrigger value="all">All</TabsTrigger>
                <TabsTrigger value="critical" className="text-red-500">
                  critical
                </TabsTrigger>
                <TabsTrigger value="major" className="text-amber-500">
                  major
                </TabsTrigger>
              </TabsList> */}
          {/* <TabsContent value="all"> */}
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="w-[600px]">Opgave</TableHead>
                  <TableHead>Status</TableHead>
                  {/* <TableHead>Sales</TableHead> */}
                  <TableHead className="text-right"></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {taskList.map((item) => (
                  <NestedTableRow key={item.id} item={item} isCompleted={isCompleted} />
                ))}
              </TableBody>
            </Table>
          </div>
          {/* <div className="rounded border">
            <div className="grid grid-cols-12 bg-muted p-3 font-medium rounded">
              <div className="col-span-5">Finding</div>
              <div className="col-span-2">Status</div>
              <div className="col-span-5 flex justify-end">Action</div>
            </div>
            {taskList.map((finding) => (
              <div key={finding.id} className="grid grid-cols-12 p-3 border-t">
                <div className="col-span-5 flex items-center gap-2">
                  {getStatusIcon(finding.status)}
                  <span>{finding.label}</span>
                </div>
                <div className="col-span-2 flex items-center">
                  <Badge className={getStatusColor(finding.status)}>
                    {getDisplayStatus(finding.status)}
                  </Badge>
                </div>
                <div className="flex flex-col items-end justify-end col-span-5 gap-1">
                  {finding.status === 'critical' && (
                    <>
                      <div className="flex items-center gap-2">
                        <TriangleAlert color="red" size={16} />
                        <div className="text-xs">Opretter opgave til </div>
                        <div className="">
                          <ResponsiblePicker />
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="text-xs">deadline</div>
                        <div>
                          <AuditDeadlinePicker />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div> */}
          {/* </TabsContent> */}
          {/* </Tabs> */}
        </CardContent>
      </Card>
    </div>
  );
}

type NestedTableRowProps = {
  item: any;
  isCompleted: boolean;
};

function NestedTableRow({ item, isCompleted }: NestedTableRowProps) {
  const [expanded, setExpanded] = useState(true);
  const { subTasksMap } = useAudit();
  const hasChildren = item.subTaskIds?.length > 0;
  const { deviations, observations, passed, total } = getCountsByTask(subTasksMap, item.id);
  return (
    <>
      <TableRow className="group bg-slate-50">
        <TableCell>
          <div className="flex items-center">
            {/* {hasChildren ? (
              <button
                onClick={() => setExpanded(!expanded)}
                className="mr-2 flex h-5 w-5 items-center justify-center rounded-sm hover:bg-muted"
              >
                {expanded ? (
                  <ChevronDown className="h-4 w-4" />
                ) : (
                  <ChevronRight className="h-4 w-4" />
                )}
              </button>
            ) : (
              <div className="mr-2 w-5" />
            )} */}
            <div className="flex gap-2">
              {/* <div className="flex items-center gap-[-10px]">
                {deviations > 0 && <XCircle className="h-5 w-5 text-red-500" />}
                {observations > 0 && <AlertTriangle className="h-5 w-5 text-amber-500" />}
                {passed > 0 && <CheckCircle className="h-5 w-5 text-green-500" />}
              </div> */}
              <span className="font-bold">{item.label}</span>
            </div>
          </div>
        </TableCell>
        <TableCell></TableCell>
        <TableCell className="flex gap-2 flex-col items-end">
          <Badge variant="outline">{item.category}</Badge>
        </TableCell>
      </TableRow>
      {hasChildren && expanded && (
        <>
          {item.subTaskIds!.map((child: any) => (
            <NestedTableRowSubTask key={child.id} id={child} isCompleted={isCompleted} />
          ))}
        </>
      )}
    </>
  );
}
function NestedTableRowSubTask({ id, isCompleted }: { id: string; isCompleted: boolean }) {
  const { audit, subTasksMap } = useAudit();
  const { auditId } = useParams<{ auditId: string }>();
  const subTask = subTasksMap![id];
  const deviationStateMap = audit.deviationState;

  const handleUpdateSubTaskResonsible = async (userId: string) => {
    try {
      if (!auditId || !id) {
        throw new Error(`Missing auditId=${auditId} or taskId=${id}`);
      }
      await firebase
        .firestore()
        .collection('internalAudits')
        .doc(auditId)
        .update({
          [`subTasksMap.${id}.responsible`]: { id: userId, displayName: 'Test' },
        });
      toast.success('Ansvarlig opdateret');
    } catch (error) {
      toast.error('Fejl ved opdatering af ansvarlig');
      console.error(error);
    }
  };
  const handleUpdateSubTaskDueDate = async (date: Date | null) => {
    try {
      if (!auditId || !id) {
        throw new Error(`Missing auditId=${auditId} or taskId=${id}`);
      }

      await firebase
        .firestore()
        .collection('internalAudits')
        .doc(auditId)
        .update({
          [`subTasksMap.${id}.dueDate`]: date,
        });
      toast.success('Deadline opdateret');
    } catch (error) {
      toast.error('Fejl ved opdatering af deadline');
      console.error(error);
    }
  };

  const hasResolvedDeviation = deviationStateMap?.[subTask.id]?.isCompleted
    ? `deviation-resolved`
    : subTask.status;

  return (
    <>
      <TableRow className="group-">
        <TableCell>
          <div className="flex">
            <div className="mr-4" />
            <div className="flex gap-3">
              <div>{subTask.label}</div>
              <div className="flex items-center gap-3">
                {subTask.description && (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex items-center gap-1 text-xs">
                        <MessageSquare className="h-4 w-4 text-slate-500" />
                      </div>
                    </TooltipTrigger>
                    <TooltipContent
                      side="top"
                      align="start"
                      // hidden={state !== 'collapsed' || isMobile}
                      // {...tooltip}
                    >
                      <span className="rounded-full  px-1.5 py-0.5 font-medium ">
                        {subTask.description}
                      </span>
                    </TooltipContent>
                  </Tooltip>
                )}
                {subTask.attachments?.length > 0 && (
                  <div className="flex items-center gap-1 text-xs">
                    <Paperclip className="h-4 w-4 text-slate-500" />
                    <span className="rounded-full bg-slate-100 px-1.5 py-0.5 font-medium text-slate-700">
                      {subTask.attachments?.length}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <Badge className={getStatusColor(hasResolvedDeviation) + ' flex gap-1'}>
            {getStatusIcon(hasResolvedDeviation)}
            {getDisplayStatus(hasResolvedDeviation)}
          </Badge>
        </TableCell>
        {/* <TableCell>{subTask.status}</TableCell> */}
        <TableCell className="flex gap-2 flex-col items-end">
          {subTask.status === 'deviation' && (
            <>
              <div className="flex items-center gap-1">
                {subTask.responsible ? (
                  <CircleCheckBig className="text-green-500" size={16} />
                ) : (
                  <TriangleAlert color="red" size={16} />
                )}
                <div className="text-xs">Opret opgave til </div>
                <div className="">
                  <ResponsiblePicker
                    disabled={isCompleted}
                    value={subTask.responsible?.id}
                    onChange={handleUpdateSubTaskResonsible}
                  />
                </div>
              </div>
              <div className="flex items-center gap-1">
                {subTask.dueDate ? (
                  <CircleCheckBig className="text-green-500" size={16} />
                ) : (
                  <TriangleAlert color="red" size={16} />
                )}
                <div className="text-xs">Deadline</div>
                <div>
                  <AuditDeadlinePicker
                    // @ts-ignore
                    deadline={subTask.dueDate?.toDate()}
                    setDeadline={handleUpdateSubTaskDueDate}
                    disabled={isCompleted}
                  />
                </div>
              </div>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
